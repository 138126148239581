import { http } from '../../httpService';

export function  getTicketList(reqBody){
    return http.post(`/api/Ticket/List`, reqBody);    
}

export function  getTicketSearch(reqBody, pageNo, pageSize){
    return http.post(`/api/Ticket/Search/${pageNo}/${pageSize}`, reqBody);    
}

export function  createTicket(reqBody){
    return http.post(`/api/Ticket/Create`, reqBody);    
}

export function  getValues(reqBody){
    return http.get(`/api/Ticket/${reqBody}/Options`);    
}
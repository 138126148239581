import React, {useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { SelectionComponent } from "../../../../components/SelectionComponent/SelectionComponent";
import {useDispatch, useSelector} from "react-redux";
import BtnRadio from "../../../../components/BtnRadio/BtnRadio";
import { UploadFile } from "../../../../components/UploadFile/UploadFile";
import { uploadFileMethod } from "../../../../services/Provider";
import { setPortableSignRadiusFilter,getListBySignsAsync,getSelectedFilters,applyPortableSignDetails,bookPortableSignAsync,setPortableSignInfo,setPortableSignLocation,getLocationNameAsync } from "../../../../services/reducers/listing-reducer";  
import { CrossSVG } from "../../../../assets/svgComponents/svg";
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng, } from "react-places-autocomplete";
import { InputGroup, FormControl, FloatingLabel } from "react-bootstrap";
import { SVGSearch } from "../../../../assets/svgComponents/svg";
import { PortableSignLocationMap } from "./PortableSignLocationMap";
import { getAddressByLatLng } from "../../../../services/api/customer-store-api";
import { useToasts } from "react-toast-notifications";
import { GetOnlyAddressFromCompoundCode } from "../../../../services/utility";
//const { longitude, latitude } = useSelector((state) => getSelectedFilters(state.listing));


export const BookPortableSignModal = ({
  show,
  setShow,
  title,
  footerText, 
  refundAmount,
  refundType,
  handlefooterBtn,  
  forSign,
  handleDetailpage,
}) => {
  const dispatch = useDispatch();
  const listBySigns = useSelector((state) => state.listing.listBySigns);
  const { media, environment, onlyFeatured, minCost, maxCost, fromDate, uptoDate, mapZoom, searchTerm, mapDistance } = useSelector((state) => getSelectedFilters(state.listing));
  const { portableSignInfo, signLocationName, portableSignLocation, latitude, longitude, locationName, listingItem } = useSelector((state) => state.listing);
  const { addToast } = useToasts();
  const [address, setAddress] = useState();
  const [landmark, setLandmark] = useState();
  const [locationOwned, setLocationOwned] = useState();
  const [locationFacingTowards, setLocationFacingTowards] = useState();
  const [file,setFile] = useState();
  const [fileAttached, setFileAttached] = useState();
  const [fileName, setFileName] = useState('');
  const [direction,setDirection] = useState();
  const [center,setCenter] = useState({});
  const [showAdditionalDetails,setShowAdditionalDetails] = useState(false);
  const [validLocation,setValidLocation] = useState(false);
  const [mapLocation,setMapLocation] = useState(null);
  const [selectedSignIds,setSelectedSignIds] = useState(null);
  const [selectedSigns,setSelectedSigns] = useState(null);

  const handelUploadFile = (e) => { 
    setFile(e.target.files[0]); 
  };

  const handleOk = async() => {
    if(selectedSigns && selectedSigns.length>0){
      const reqBody ={
        address,
        landmark,
        latitude:portableSignLocation ? portableSignLocation.lat : latitude,
        longitude:portableSignLocation ? portableSignLocation.lng : longitude,
        image:fileAttached,
        isLocationOwned:locationOwned,
        locationFacingTowards:locationFacingTowards, 
        selectedSignIds:selectedSignIds,
      }; 
      const payload = { portableDetails: reqBody, sign: forSign};
      await dispatch(applyPortableSignDetails(payload)); 
      await dispatch(setPortableSignInfo(reqBody));
      await dispatch(bookPortableSignAsync(reqBody));
      addToast("Portable sign information configured for sign booking!",{ appearance: "success"});  
      setShow(false);    
      if(selectedSigns && selectedSigns.length==1){      
        setTimeout(()=>{
          let {itemId,listingId, id} = selectedSigns[0];
          handleDetailpage(itemId,listingId, id, "sign");
        },1500);
      }
    }else{
      addToast(`Portable sign can book only the avaialbe portable sign areas!`,{ appearance: "warning"});
    }
    
    
  }

  useEffect(()=>{
    if(portableSignInfo){
      setAddress(portableSignInfo.address);
      setLandmark(portableSignInfo.landmark);
      setFileAttached(portableSignInfo.image);
      setLocationOwned(portableSignInfo.isLocationOwned);
      setLocationFacingTowards(portableSignInfo.locationFacingTowards);
    }
  },[portableSignInfo])

  useEffect(()=>{
    if(forSign && forSign.portableDetails){
      setAddress(forSign.portableDetails.address);
      setLandmark(forSign.portableDetails.landmark);
      setFileAttached(forSign.portableDetails.image);
      setLocationOwned(forSign.portableDetails.isLocationOwned);
      setLocationFacingTowards(forSign.portableDetails.locationFacingTowards);
    }
  },[forSign])

  useEffect(()=>{
    if(signLocationName && !address){
      setAddress(signLocationName);
    }
  },[signLocationName])

  useEffect(()=>{
    if(locationName && !address){
      setAddress(locationName);
    }
  },[locationName])

  useEffect(()=>{
    if(portableSignLocation){
      setCenter(portableSignLocation);            
    }else{
      setCenter({lat:latitude,lng:longitude});
    }    
  //},[portableSignLocation,latitude,longitude]);
},[]);

  // useEffect(()=>{
  //   setCenter({lat:latitude,lng:longitude}); 
  // },[]);

  const onLoad = React.useCallback(function callback() {
    setCenter({lat:latitude,lng:longitude});
  }, []);

  useEffect(() => { 
    if (file) {
      try {
        let formData = new FormData();
        formData.append("file", file);
        uploadFileMethod(formData).then((res) => {
          console.log("file upload response:",res);
          if (res.status === 200) { 
            setFileAttached(res.data.fileKey); 
            setFileName(res.data.fileName);
          }
        });
      } catch (error) {
        console.log("error", error);
      }
  }
}, [file]);

 useEffect(async()=>{
  if(center && center.lat && center.lng){
    const response = await getAddressByLatLng(center.lat,center.lng);    
    if(response && response.data){      
      const { compound_code } = response.data.plus_code;
      setAddress(GetOnlyAddressFromCompoundCode(compound_code));
    }    
  } 
 },[center])


  useEffect(async() => { 
    //dispatch(getRefundTypeOptionsAsync()) 
  },[dispatch]);

  useEffect(async()=>{
    if(mapLocation || show){      
      getListing();
    }    
  },[mapLocation]);

  useEffect(()=>{
    getListing();
  },[])

  const getListing = () => {
    let mediaTypes = [2,3,4];    
    const reqBody = {
      page: 0,
      pageSize: 10,
      orderBy: "",
      export: true,
      searchString: searchTerm,
      userLocation: "",
      costs: [],
      mediaTypes: mediaTypes,
      environmentIds: environment,
      dates: [],
      onlyFeatured: onlyFeatured,
      listingType: 1,
      providerId: "",
      latitude: mapLocation ? mapLocation.lat : (portableSignLocation ? portableSignLocation.lat : latitude),
      longitude: mapLocation ? mapLocation.lng : (portableSignLocation ? portableSignLocation.lng : longitude),
      radiusOfOperation: 1000000,
    };
    if (minCost != null && maxCost) {
      reqBody.costRange = { min: minCost, max: maxCost };
    }
    if (fromDate && uptoDate) {
      reqBody.dateRange = { fromDate, uptoDate };
    }
    dispatch(getListBySignsAsync(reqBody));
  }



  return (
    <Modal  
      className="confirmModal "
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered 
      onHide={() => setShow(false)}
    >
      
       <Modal.Header closeButton>
       <Modal.Title>{title}</Modal.Title>
       
      </Modal.Header>
      <Modal.Body className="modal-childs confirmModalInner modalbox mwidth-100">
     
      <div className="childs">                   
        {/* <InputField
            type="text"                     
            placeholder="Address"
            label="Address"
            value={address}
            inputFieldSize="sizeOfInputField"
            onChange={(e)=>setAddress(e.target.value)}
        /> */}

            <PlacesAutocomplete
                  value={address}
                  onChange={(p) => { setAddress(p); }}
                  onSelect={(p) => {  setAddress(p);
                  geocodeByAddress(p).then((results) => getLatLng(results[0])).then(({ lat, lng }) => { 
                    let loc = {lat,lng};
                    setCenter(loc);
                    setMapLocation(loc);
                    dispatch(setPortableSignLocation(loc));                    
                   // dispatch(getLocationNameAsync({ lat: lat, lng:lng, locationType:'sign'})); 
                  });
                  }}
              >
                  {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                  }) => (
                  <div> 
                      <FloatingLabel 
                          label="Search Location"
                          className={`myinput`}>
                      <FormControl
                          {...getInputProps({
                              placeholder: "Location",
                              className:"inputField form-control location-search-input",
                              id:"globalSearchBox"
                          })}
                      />
                      <InputGroup.Text
                          id="table-search"
                          className={`serchIcon`}
                          onClick={()=>{}}
                      >
                          <SVGSearch />
                      </InputGroup.Text>
                      </FloatingLabel>

                      <div className="autocomplete-dropdown-container">
                      {/* {loading && <div>Loading...</div>} */}
                      {suggestions.map((suggestion, index) => {
                          const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                           
                          const style = suggestion.active ? { backgroundColor: "#FAFAFA", cursor: "pointer", }
                          : { backgroundColor: "#FFFFFF", cursor: "pointer", };
                          return (
                          <div key={'loc_' + index} {...getSuggestionItemProps(suggestion, { className, style, })} >
                              <span>{suggestion.description}</span>
                          </div>
                          );
                      })}
                      </div>
                  </div>
                  )}
              </PlacesAutocomplete>

      </div>
      <div className="d-flex">  
        <div className="pe-4 d-flex align-items-center">
          <label className="text-xxs text-gray-900 weight-700 mb-2">  Latitude : {center.lat} </label>
        </div>
        <div className="pe-4 d-flex align-items-center">
          <label className="text-xxs text-gray-900 weight-700 mb-2"> Longitude : {center.lng} </label>
        </div>
      </div>
        <div className="d-flex justify-content-between w-100 my-4 fornSection2 mapBox">
                        <PortableSignLocationMap
                          center={center} 
                          address={address}
                          zoom={12}
                          onLocationChange={(coord)=>{                              
                            console.log("new coords",coord);
                            if(coord){
                              setMapLocation({ lat:coord.lat(), lng:coord.lng() });
                            }
                          }}
                          onMarkerDragEnd={(coord) => { 
                            if(coord){
                              setValidLocation(true);
                              const { latLng } = coord;
                              let loc = { lat:latLng.lat(), lng:latLng.lng() };                              
                              dispatch(setPortableSignLocation(loc));                              
                            }else{
                              setValidLocation(false);
                              dispatch(setPortableSignLocation(null));                              
                            }
                          }}
                          setSelectedSignsForFilter={(ids,signs)=>{ setSelectedSignIds(ids);setSelectedSigns(signs);}}                          
                          radius={listingItem.radius ? listingItem.radius : null}
                          signLocation={forSign ? {lat:forSign.latitude,lng:forSign.longitude} : null}
                          listBySigns={listBySigns}
                          selectedLocation={portableSignLocation}
                        />
        </div>

        <div className="childs d-flex justify-content-between">
            <div>
          <label className="text-xxs text-gray-900 weight-700 mb-2">IS LOCATION OWNED?</label><br/> 
            <div className="d-flex">             
              <div className="pe-4 d-flex align-items-center">
                <BtnRadio name="locationOwned" onChangeStatus={(e)=>{ setLocationOwned(e.target.checked)}} label="YES" status={locationOwned} />
              </div>
              <div className="pe-4 d-flex align-items-center">
                <BtnRadio name="locationOwned" onChangeStatus={(e)=>{setLocationOwned(!e.target.checked)}} label="No" status={!locationOwned} />
              </div> 
            </div> 
            </div>
            <div>
            <label className="text-xxs text-gray-900 weight-700 mb-2">LOCATION FACING TOWARDS?</label><br/> 
            <div className="d-flex"> 
              <div className="pe-4 d-flex align-items-center">
                <BtnRadio name="direction" onChangeStatus={(e)=>{setLocationFacingTowards("North/South")}} label="North/South" status={locationFacingTowards==="North/South"} />
              </div>
              <div className="pe-4 d-flex align-items-center">
                <BtnRadio name="direction" onChangeStatus={(e)=>{setLocationFacingTowards("West/East")}} label="West/East" status={locationFacingTowards==="West/East"} />
              </div> 
            </div>
            </div>
        </div>

        <div className="childs">
          <div className="ms-2">
            <label className="btnSwitch xxl">
            <input type="checkbox" onChange={(e)=>{setShowAdditionalDetails(e.target.checked);}}/>
            <span className="btnSwitchCheck"></span>
            <strong className="btnSwitchText sizeXl text-dark mt-1 p-0 pe-2 ms-4 ps-4">Additional Details</strong>
            </label>
          </div>
        </div>

        {showAdditionalDetails && (
          <>
            <div className="childs">                   
            <InputField
                type="text"                     
                placeholder="Instructions"
                label="Instructions"
                value={landmark}
                inputFieldSize="sizeOfInputField"
                onChange={(e)=>setLandmark(e.target.value)}
            />
            </div>

            <div className="childs">
                    <UploadFile
                      id="attachment"
                      uploadBoxSize="uploadBoxSize"
                      btnlable="Upload Image"
                      handelUploadLogo={handelUploadFile}
                      placeholder={fileName ? fileName : (fileAttached ? fileAttached : "No file attached")}
                    />
              </div>
            </>
        )}
      

        <p className="text-gray-1400 text-sm">By Submitting you agree to our <a href="http://20.219.194.183/terms-conditions" target={"_blank"} className="fw-bold text-tertiary-500">Terms & Service</a> </p>

      </Modal.Body>
       {/* { validLocation || !forSign ? ( */}
        { validLocation ? (
        <Modal.Footer
        className="popModalfooter justify-content-center"
        onClick={handleOk}>
          <span style={{ color: "#ffffff" }}>{footerText}</span>
        </Modal.Footer>
       ) : (
        <Modal.Footer
        className="popModalfooter justify-content-center">
          <span style={{ color: "#909090" }}>{footerText}</span>
        </Modal.Footer>
       )}

      
    </Modal>
  );
};

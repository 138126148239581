import React from "react";
import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SideNav from "../../../components/sideNav/SideNav";
import { FTUERoutes } from "./FTUE/FTUERoutes";
import Chat from "../Shared/Chat/Chat";
import { AuthService } from "../../../services/AuthService";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
const Provider = ({ logout, userData }) => {
  const [openNav, setOpenNav] = useState(false);
  //const [openNav, setOpenNav] = useState(false);

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="providerDashboard">
        <div
          className="sideNav"
          style={openNav ? { width: "217px" } : { width: "118px" }}
        >
          <SideNav setOpenNav={setOpenNav} openNav={openNav} logout={logout} />
        </div>
        <div className="mainSection">
          <Switch>
            <Route
              path="/provider"
              render={(props) => (
                <FTUERoutes
                  {...props}
                  // userProfile={userProfile}
                />
              )}
            />

            {/* <Route
              exact={true}
              path='*'
              render={(props) => ( <FTUERoutes {...props} />
              )}
            />    */}
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Provider;

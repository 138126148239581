import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { useFormik } from "formik";

export const TextAria = ({
  label,
  onChange,
  onBlur,
  value,
  name,
  placeholder,
}) => {
  return (
    <>
    <FloatingLabel controlId="floatingTextarea" label={label} style={{ width:'100%' }}>
      <Form.Control
        as="textarea" 
        placeholder={placeholder ? placeholder : "Leave a comment here"}
        style={{ height: "100px" }}
        name={name}
        value={value}
        onBlur={onBlur}
        className="textArea"
        onChange={onChange}
      />
    </FloatingLabel>
    </>
  );
};

import React, { useContext, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router";
import { useToasts } from "react-toast-notifications";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import { CustomSort } from "../../../../../../../components/DataTable/CustomSort";

import Grid from "../../../../../../../components/grid/Grid";
import NavBar from "../../../../../../../components/navBar";
import ProjectContext from "../../../../../../../context/ProjectContext";
import { getAddServices } from "../../../../../../../services/Provider/Configurator/Listings";
import TableActions from "../../components/TableActions";

const notiHeading = ["id", "name", "description"];
const accessorName = ["checkBox", "id", "name", "description"];
// const columns = [
//   {
//     name: "id".toUpperCase(),
//     selector: (row) => row.id,
//     sortable: true,
//   },

//   {
//     name: "name".toUpperCase(),
//     selector: (row) => row.name,
//     sortable: true,
//   },
//   {
//     name: "description".toUpperCase(),
//     selector: (row) => row.description,
//     sortable: true,
//   },
// ];

const AddServicesPage = ({
  activeTab,
  sideTabs,
  setSideTabs,
  setActiveTab,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  const [servicesdata, setServicesdata] = useState();
  const [loader, setLoader] = useState(false);
  const { handleCreateListing, createListing } = useContext(ProjectContext);
  const [selectedServices, setSelectedServices] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //   fetch service listing data
  useEffect(async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: "",
      export: true,
      searchString: filterText,
    };
    setLoader(true);
    await getAddServices(reqBody)
      .then((res) => {
        if (res.status == 201) {
          setLoader(false);
          // res.data?.records.forEach((item) => (item.isChecked = false));
          // console.log(res.data, "res service");
          res.data.records.map((elem) => (elem.isChecked = false));
          location?.isUpdateListing &&
            res?.data.records.map(
              (elem, i) => {
                //elem.id == location.data.serviceIds[i] && (elem.isChecked = true)
                location.data.serviceIds.filter(element => {
                  element == elem.id && 
                    (elem.isChecked = true)
                });
            });
          // console.log(res.data, "res.dataa");
          setServicesdata(res?.data);
          //setServicesdata
      
          
          if (location?.isUpdateListing) {
            let tempData1 = res?.data.records.filter((r) => r.isChecked);
            let tempSelectedRow = tempData1.map((item) => ({
              serviceOrChargeId: item.id,
              name: item.name,
            }));
            console.log(tempSelectedRow, "tempSelectedRow");
            setSelectedServices(() => tempSelectedRow);
          }

        }
      })
      .catch((err) => console.log(err));
  }, [filterText]);




  // handle for grid data selection
  const handleGridCheckBox = (val) => {
    let tempData = servicesdata.records;
    // console.log(val, "item");
    console.log(tempData, "item");

    // tempData?.map((item) => {
    //   if (item.id == val.id) {
    //     item.isChecked = !val.isChecked;
    //   }
    // });
    // setServicesdata(() => tempData);
  };

  // const handleChange = ({ selectedRows }) => {
  //   // console.log(selectedRows, "selectedRows");
  //   let tempRow = selectedRows.map((item) => ({
  //     serviceOrChargeId: item.id,
  //     name: item.name,
  //   }));
  //   setSelectedServices(tempRow);
  // };

  const handleWhiteBtn = () => {
    // console.log("white");
    history.push("/provider/configurator/listings");
  };

  // const filteredItems = servicesdata?.filter(
  //   (item) =>
  //     (item.name &&
  //       item.name.toLowerCase().includes(filterText.toLowerCase())) ||
  //     (item.id && item.id.toLowerCase().includes(filterText.toLowerCase()))
  // );

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <TableActions
          title="Add Services"
          isInput={true}
          onSearchChange={(e) => setFilterText(e.target.value)}
          // isFilterBtn={true}
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const selectableRowSelected = (row) => {
    // console.log(row, "row service");
    // return row.id == location.data.serviceIds;
    // if (row.id == "71ba872d-7f39-4455-3009-08da29f3bf6d") {
    //   return true;
    // }
  };

  const handleAllChecked = (row) => {
    let tempRowSelected = [...servicesdata.records];
    tempRowSelected.forEach((r) => (r.isChecked = !r.isChecked));
    setServicesdata((prev) => ({ ...prev, records: tempRowSelected }));
    // let tempData = servicesdata;
    // tempData?.records?.forEach((item) => {
    //   item.isChecked = !item.isChecked;
    // });
    // setServicesdata(tempData);
  };

  const handleChecked = (row) => {
    // console.log(row, "row");
    // console.log(servicesdata, "servicesdata");
    let tempRowSelected = [...servicesdata.records];
    tempRowSelected.forEach(
      (r) => r.id == row.id && (r.isChecked = !r.isChecked)
    );
    let tempData1 = tempRowSelected.filter((r) => r.isChecked);
    let tempRow = tempData1.map((item) => ({
      serviceOrChargeId: item.id,
      name: item.name,
    }));
    setSelectedServices(tempRow);
    setServicesdata((prev) => ({ ...prev, records: tempRowSelected }));
    // console.log(tempRow);
  };

  const handleNext = (activeTab) => {
    // let tempData1 = servicesdata.records.filter((d) => d.isChecked);
    if (location.isUpdateListing) {
      let tempData1 = servicesdata.records.filter((r) => r.isChecked);
      let tempSelectedRow = tempData1.map((item) => ({
        serviceOrChargeId: item.id,
        name: item.name,
      }));
      console.log(tempSelectedRow, "tempSelectedRow");
      setSelectedServices(() => tempSelectedRow);
    }
    if (selectedServices.length > 0) {
      selectedServices.map((item) => (item.summaryChargesType = 0));
      handleCreateListing({ ...createListing, sevices: selectedServices });
      let tempSide = sideTabs.findIndex((elem) => elem.name == activeTab);
      let tempSide2 = [...sideTabs];
      tempSide2.forEach(
        (elem) => elem.name == activeTab && (elem.isActive = true)
      );
      setSideTabs(tempSide2);
      setActiveTab(sideTabs[tempSide + 1].name);
    } else {
      addToast("Please select atleast one service from list", {
        appearance: "warning",
      });
    }
  };

  return (
    <>
      {/* {console.log(sideTabs, "sideTabs123")} */}
      {/* {console.log(createListing, "create")} */}
      <div className="w-100">
        <NavBar
          // handleWhitebtn={() => setConfirmModal(true)}
          whiteBtnText={"Cancel"}
          blueBtnText={"Next"}
          notificationSVG={<NotificationSVG />}
          navHeading={createListing?.name}
          subHeading="All Billable Item "
          subActiveHeading=" New Billable Item"
          handleBlueBtn={() => handleNext(activeTab)}
          handleWhitebtn={() => handleWhiteBtn(activeTab)}
          showButton={true}
        />
        <div className="p-20 gridSection">
          <div className="d-flex h-100 w-100">
            <div>
              <ul className=" progressbar">
                {sideTabs?.map((tab) => (
                  <li
                    className={`billable_switchSideDom_item ${
                      tab.isActive && "active"
                    }
                    ${tab.isCurrentTitle && "activeTabTitle"}
                   `}
                  >
                    {tab.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="d-flex flex-column h-100 flex-grow-1">
              {subHeaderComponentMemo}

              <div className="card customtable mt-3">
                <Grid
                  isCheckBox
                  tableHeading={notiHeading}
                  tableBody={servicesdata && servicesdata}
                  accessorName={accessorName}
                  handleAllChecked={handleAllChecked}
                  handleChecked={handleChecked}
                />
                {/* <DataTable
                  fixedHeader
                  progressPending={loader}
                  columns={columns}
                  data={filteredItems && filteredItems}
                  sortFunction={<CustomSort />}
                  allowOverflow={true}
                  // onRowClicked={handleSelectSignsRow}
                  onSelectedRowsChange={handleChange}
                  // selectableRowSelected={selectableRowSelected}
                  highlightOnHover
                  pointerOnHover
                  selectableRows
                  pagination
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddServicesPage;

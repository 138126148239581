
import React, {useState, useEffect} from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
  LoadScript,  
  StandaloneSearchBox,
  Circle   
} from "@react-google-maps/api";
import { apiKey } from "../../../../services/Constants";
import markerImg from "../../../../assets/images/markerImg.png";
import { useDispatch, useSelector } from "react-redux";
import { getDistance } from "../../../../services/utility";
import { useToasts } from "react-toast-notifications";
import { MapProviderDashboardMapCard } from "../../../../components/Maps/MapProviderDashboardMapCard";

let libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};
 
 
  
export const PortableSignLocationMap = ({ address, center,onMarkerDragEnd, zoom, readonly, radius,signLocation, listBySigns, onLocationChange, selectedLocation,setSelectedSignsForFilter}) => {

  const { portableSignInfo,signLocationName } = useSelector((state) => state.listing);
  const [map, setMap] = useState(null);
  const [portableSignLoc,setPortableSignLoc] = useState(null);
  const [portableSignInfoVisible,setPortableSignInfoVisible] = useState(false);
  const [portableSignMarker, setPortableSignMarker] = useState(null);
  const { addToast } = useToasts();
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: apiKey.GOOGLE_API_KEY,
    libraries,
  });
  const [mapPosition, setMapPosition] = useState();
  const [promptMsg, setPromptMsg] = useState(true);
  setTimeout(
    function () {
      setPromptMsg(false);
    }.bind(this),
    10000
  );
  useEffect(()=>{ 
    //console.log("new center location",center,signLocation);   
    setTimeout(()=>{      
      if(signLocation)
        setMapPosition(signLocation);
      else 
        setMapPosition(center);
    },1599);
  },[center]);

  useEffect(()=>{
    if(radius){
      //console.log("radius",radius,mapPosition);  
    }
  },[radius, mapPosition])
  
  useEffect(()=>{
      //console.log("radius");  
  },[])

  useEffect(()=>{
    //console.log("listbysings",listBySigns);
  },[listBySigns]);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);


 
  if (loadError) return "Error Loading Maps";
  if (!isLoaded) return "Loading Maps";
  return (                
            <GoogleMap        
              mapContainerStyle={mapContainerStyle}
              zoom={zoom ? zoom : 25}
              center={mapPosition}
              mapTypeId='satellite'
              onLoad={onLoad}
              onUnmount={onUnmount}
              onZoomChanged={()=> { 
                if(map){
                  let loc = map?.getCenter();
                  if(loc.lat()!=0)
                    onLocationChange(loc);
                }                
              }}
              onDragEnd={()=> onLocationChange(map?.getCenter())} 
              options={{
                streetViewControl: false,
              }}
            > 
                <Marker position={center} draggable={!readonly} 
                onDragEnd={(coord) => { 
                  let newLat = { lat:coord.latLng.lat(), lng:coord.latLng.lng()}; 
                  
                  if(listBySigns && listBySigns.records){
                    let notInCircle = true;
                    let selectedSignsIds = [];
                    let selectedSigns = [];
                    setSelectedSignsForFilter(selectedSignsIds,selectedSigns);
                    listBySigns.records.forEach(element => {
                      if(element.radius && element.radius>0){
                        var dist = getDistance({ lat: element.latitude, lng: element.longitude}, newLat);    
                        if(element.radius<dist){
                          
                        }else{
                          selectedSignsIds.push(element.itemId);
                          selectedSigns.push(element);
                          notInCircle = false;
                        }
                      }                      
                    });
                    if(notInCircle){
                      addToast(`Portable sign can book only the available portable sign areas!`,{ appearance: "warning"});
                      onMarkerDragEnd(null);
                      return;
                    }else{
                      setSelectedSignsForFilter(selectedSignsIds,selectedSigns);
                    }
                  }
                  else if(radius && signLocation){
                    
                    var dist = getDistance(signLocation, newLat);
                    //console.log('distance',signLocation,newLat, dist);
                    if(radius<dist){
                      addToast(`Portable sign can be booked only in radius of ${radius}!`,{ appearance: "warning"});
                      onMarkerDragEnd(null);
                      return;
                    }
                  } 
                  onMarkerDragEnd(coord);
                  }
                } 
                icon={markerImg}
                 onClick={(marker) => { 
                      setPortableSignMarker(marker);
                      setPortableSignInfoVisible(true); 
                  }}
                >
                
                    {portableSignInfoVisible==true && (
                        <InfoWindow
                            key={"portableSignMarker_info"}
                            visible={portableSignInfoVisible}
                            position={mapPosition}
                            marker={portableSignMarker}
                            onCloseClick={() => {
                            setPortableSignInfoVisible(false);
                            }}
                        >
                        <div className="text-start">
                         
                            { address && (
                              <>
                               <span>Portable Sign Location</span> 
                                <div> 
                                  <p style={{ paddingBottom: "2px", fontWeight:'bolder' }}>
                                    {address}
                                  </p> 
                                  <br /> 
                                </div>
                              </>
                            )}
                            { !address && (
                              <>
                               <span>Pin Sign Location</span> 
                                <div> 
                                  <p style={{ paddingBottom: "2px", fontWeight:'bolder' }}>
                                    Would you like to pin this location for portable sign?
                                  </p> 
                                  <br /> 
                                </div>
                              </> 
                            )}
                        </div>   
                        </InfoWindow>
                    )}
                    {promptMsg ? (
                  <InfoWindow
                    key={1}
                    visible={true}
                    marker={mapPosition}
                    onCloseClick={() => {
                      setPromptMsg(false);
                    }}
                  >
                    <MapProviderDashboardMapCard cardDetails={{name:"Click and drag the G to pin location"}} />
                  </InfoWindow>
                ) : null}
                </Marker> 

                {
                 listBySigns && listBySigns.records && 
                    listBySigns.records.map((signInfo,index)=>{                                                                    
                        return (<Circle
                              onClick={(event) => {
                                console.log("click");
                              }}
                              center={{ lat: signInfo.latitude, lng: signInfo.longitude }}
                              radius={signInfo.radius*1000}
                              options={{
                                strokeColor: selectedLocation && signInfo.radius && signInfo.radius>0
                                && signInfo.radius>getDistance(selectedLocation, { lat: signInfo.latitude, lng: signInfo.longitude }) ? "#3FA63F" : "#f99300",                                
                                fillOpacity:0,
                              }}
                            > 
                            </Circle>); 
                    })
                }

                 {/* {radius && (
                  <Circle
                    onClick={(event) => {
                      console.log("click");
                    }}
                    center={{ lat: signLocation.lat, lng: signLocation.lng }}
                    radius={radius*1000}
                    options={{
                      strokeColor: "#3FA63F"
                    }}
                  />
                )}       */}
            </GoogleMap>   
       
            
  );
};

import React from 'react';

const Message = (props) => {
    const getFromBetween = {
        results:[],
        string:"",
        getFromBetween:function (sub1,sub2) {
            if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
            var SP = this.string.indexOf(sub1)+sub1.length;
            var string1 = this.string.substr(0,SP);
            var string2 = this.string.substr(SP);
            var TP = string1.length + string2.indexOf(sub2);
            return this.string.substring(SP,TP);
        },
        removeFromBetween:function (sub1,sub2) {
            if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
            var removal = sub1+this.getFromBetween(sub1,sub2)+sub2;
            this.string = this.string.replace(removal,"");
        },
        getAllResults:function (sub1,sub2) {
            // first check to see if we do have both substrings
            if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return;
    
            // find one result
            var result = this.getFromBetween(sub1,sub2);
            // push it to the results array
            this.results.push(result);
            // remove the most recently found one from the string
            this.removeFromBetween(sub1,sub2);
    
            // if there's more substrings
            if(this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
                this.getAllResults(sub1,sub2);
            }
            else return;
        },
        get:function (string,sub1,sub2) {
            this.results = [];
            this.string = string;
            this.getAllResults(sub1,sub2);
            return this.results;
        }
    };
    var result = getFromBetween.get(props.data.content,"&&&","&&&");
    const downloadUrl =`https://gopinit.blob.core.windows.net/image-container/${result}`
    return <div className='row'>
        {props.data.sentById==props.targetId?<div className='col-md-1'></div>:<></>}
        
        <div className={`col-md-11 ${props.data.sentById==props.targetId?"chatbox-popup__main__from":"chatbox-popup__main__to"}`}>
            {/* <p><strong>{props.data.sentById}</strong> says:</p> */}
            {props.data.content.startsWith('&&&') && props.data.content.endsWith('&&&') ?
            <a href={downloadUrl} target="_blank" style={{color: props.data.sentById==props.targetId?'black':'white'}} rel="noreferrer">Download</a>
            :
            <p>{props.data.content}</p>
            }
        </div>
        {props.data.sentById!=props.targetId?<div className='col-md-1'></div>:<></>}
    </div>
};

export default Message;
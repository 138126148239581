import { Col, Row } from "react-bootstrap";
import { UnionSVG } from "../../../../../assets/svgComponents/table";
import Button from "../../../../../components/button/Button";
import { InputField } from "../../../../../components/InputField";
import React, { useState } from "react";

const TableActions = (props) => {
  // console.log(props);
  const [label, setLabel] = useState("Search Site ID, Name, Location...");
  const { searchInput, handleSearchInput } = props;
  console.log("props.activeTab", props.activeTab);

  return (
    <>
      <div className="tableActions_container m-0">
        <Row>
          <Col className="d-flex align-items-center">
            <ul className="tableActions_tabs mt-2">
              {props.tabs.map((item) =>
                item === props.activeTab ? (
                  <li
                    className="tableActions_tab active"
                    onClick={() => {
                      props.setActiveTab(item);
                    }}
                  >
                    {item} <hr className="tableActions_tab_hr" />
                  </li>
                ) : (
                  <li
                    className="tableActions_tab"
                    onClick={() => {
                      props.setActiveTab(item);
                      switch (item) {
                        case "LOCATIONS":
                          setLabel(`Search ID, Name, Location...`);
                          break;
                        case "SIGNS":
                          setLabel(`Search Sign ID, Media, Size Name...`);
                          break;
                        case "SERVICES":
                          setLabel(`Search Service ID, Service Name...`);
                          break;
                        case "CHARGES":
                          setLabel(`Search ID, Name...`);
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    {item}
                  </li>
                )
              )}
            </ul>
          </Col>
          {/* <Col className=" d-flex justify-content-end">
            <div className="tableAction_input_container w-50">
              <InputField
                placeholder="Search Site ID, Name, Location..."
                label={label}
                onChange={handleSearchInput}
                value={searchInput}
              />
            </div>
            <div className="navbarBtnContainer ">
              <Button
                className="whiteBgButton02 "
                onClick={() => props.setFiltersModal(true)}
              >
                Filter
                <span className="btnSvg">
                  <UnionSVG />
                </span>
              </Button>
            </div>
          </Col> */}
        </Row>
      </div>
    </>
  );
};

export default TableActions;

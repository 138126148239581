import { createContext, Component } from "react";
import React from "react"

const Context = createContext();
export class ThemeContext extends Component {
  state = {
    // color
    colorRegalBlue: "#3734A9",
    colorSnowFlake: "#FFFFFF",
    colorBrick: "#FFB141",
    colorCharcoalBlack: "#201C1D",
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default Context;

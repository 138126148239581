import { Col, Row } from "react-bootstrap";
import { UnionSVG } from "../../../../../../assets/svgComponents/table";
import BtnUnionFilter from "../../../../../../components/button/BtnUnionFilter";
import { InputField } from "../../../../../../components/InputField";
import React from "react";

const TableActions = (props) => {
  const { onSearchChange, filterText } = props;
  return (
    <>
      <div className="tableActions_container">
        <Row>
          <Col className="d-flex align-items-center" sm={8}>
            <div>{props.title}</div>
          </Col>
          <Col className=" d-flex-rm justify-content-end-rm" sm={4}>
            {props.isInput && (
              <div className="tableAction_input_container">
                <InputField                                  
                  placeholder="Search Name"
                  label="Search Name"
                  onChange={onSearchChange}
                  value={filterText}
                  inputClassName="px-5-rm"
                />
              </div>
            )}
            {props.isFilterBtn && (
              <div className="navbarBtnContainer ">
                <BtnUnionFilter>
                  Filter
                  <span className="ps-2">
                    <UnionSVG />
                  </span>
                </BtnUnionFilter>
                {/* <Button
                className="whiteBgButton02 "
                onClick={() => props.setFiltersModal(true)}
              >
                Filter
              </Button> */}
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TableActions;

import React from "react";
import { Switch, Route } from "react-router-dom";
import UserManagement from ".";
import InviteUser from "./pages/InviteUser";
import EditUser from "./pages/EditUser";
export const UserMamagementRoutes = ({ ...rest }) => {
  return (
    <Switch>
      <Route
        path={`${rest.match.url}/`}
        exact
        render={(props) => <UserManagement {...props} />}
      />
      <Route
        path={`${rest.match.url}/invite-user`}
        render={(props) => <InviteUser {...props} />}
      />
      <Route
        path={`${rest.match.url}/edit-user`}
        render={(props) => <EditUser {...props} />}
      />
    </Switch>
  );
};

export const checkPermission = (claimType, claimValue) => {
    const am= localStorage.getItem('accessModules');
    if(am!="undefined"){
        const adhj=JSON.parse(am).filter(m=>m.Type==claimType && m.Value==claimValue);
        if(adhj.length>0)
        {
            return true;
        }
        else{
            return false;
        }
    }
    else{
        return true;
    }
}
export const lastCurrentPerCalulate = (newAmount, oldAmount) =>{
    const per = ((newAmount??0-oldAmount??0)/oldAmount??0)*100;
    if(isNaN(per)){
        return 0;
    } else if (!isFinite(per)){
        return 100;
    }
    return per.toFixed(1)??0;
}
import { http } from "../httpService";

export function postNotification(reqBody) {
    return http.post(`/api/Notification`,reqBody);
}

export function getNotifications(reqBody) {
    return http.post(`/api/Notification/Index`,reqBody);
}

export function getNotificationHistoryList(reqBody) {
    return http.post(`/api/NotificationHistory/List`,reqBody);
}

export function putNotificationMardRead(id) {
    return http.put(`/api/NotificationHistory/MarkRead/${id}`);    
}

export function markAllNotificationRead() {
    return http.put(`/api/NotificationHistory/MarkAllRead`);    
}
import React from "react";
import { Form, FloatingLabel } from "react-bootstrap";

export const InputFieldText = ({
  value,
  name,
  onBlur,
  onChange,
  placeholder,
  label,
  inputFieldSize,
  disabled,
  type,
  touched,
  errors,
  onFocus,
  required,
  labelText,
}) => {
  return (
    <>
      <div className="inputContainer borderAll rounded-10 overflow-hidden pe-0 position-relative">
        <FloatingLabel
          controlId="floatingInput"
          label={label}
          className={`floatingLabel border-0 `} // ${inputFieldSize}
        >
          <Form.Control
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            className="inputField border-0"
            placeholder={placeholder}
            onBlur={onBlur}
            disabled={disabled}
            // disabled={isDisabled}
            required={required}
            //   className={`${
            //     formik.values.email.length !== 0 ? "valid " : ""
            //   } ${emailAddress && "inputError"}`}
          />
         
        </FloatingLabel>
        <span className="text-xxs text-gray-1400 position-absolute no-days-weeks">{labelText}</span>
        

        {touched && errors ? (
          <Form.Text className="text-danger">{errors}</Form.Text>
        ) : null}
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router";
import { PassThroughSVG } from "../../../../../assets/svgComponents/inventorySVG";
import { ExportCSVSVG } from "../../../../../assets/svgComponents/table";
import {
  deleteUser,
  providerList,
  userList,
  verifyProvider,
} from "../../../../../services/Provider/UserManagement/UserManagement";
// import { toast } from "react-toastify";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import { useToasts } from "react-toast-notifications";
import { ProviderConnectAccountModal } from "./ProviderConnectAccountModal";
import { checkPermission } from "../../../../../utils";

const Providers = ({
  markActiveInactive,
  searchString,
  statusFilter,
  loadExportData,
  statusValue,
}) => {
  if (statusFilter == null) statusFilter = 0;
  const { addToast } = useToasts();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const [user, setUser] = useState({});
  const [validationError, setValidationError] = useState();
  const [reason, setReason] = useState("");
  const [approveModel, handleApproveModel] = useState(false);
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState();
  const [deleteModel, setDeleteModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    setSortColumn({ column: column, sortDirection: sortDirection });
  };
  useEffect(() => {
    loadUser();
  }, [searchString, currentPage, pageSize, sortColumn, statusFilter]);

  const loadUser = async () => {
    setLoading(true);
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: searchString,
      verified: statusFilter ? statusFilter.id : 0,
    };
    // setLoader(true);
    await providerList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          setListData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEditGridCell = (item) => {
    history.push({ pathname: `${location.pathname}/edit-user`, state: item });
  };
  const handleApproveGridCell = (item) => {
    console.log(item.id);
    const reqBody = {
      providerId: item.id,
    };
    verifyProvider(reqBody)
      .then(() => {
        loadUser();
        addToast("Provider Approved successfully.", {
          appearance: "success",
        });
        // toast.success("Provider Approved successfully.");
      })
      .catch(() => {
        addToast("There is an error while changing status", {
          appearance: "error",
        });
        // toast.error("There is an error while changing status");
      });
  };
  const handleViewProvider = (item) => {
    history.push({
      pathname: `${location.pathname}/provider-details`,
      state: item,
    });
  };

  const handleApproveProvider = (data) => {
    setUser(data)
    setAction("Approve")
    handleApproveModel(true)
  }

  const handleRejectProvider = (data) => {
    setUser(data)
    setAction("Reject")
    handleApproveModel(true)
  }

  const handleApproveBtn = (verified, disapproveReason) => {

    if (verified === false) {
      if (disapproveReason == null) {
        let flag = true;
        let reqBody;
        if (action === "Approve") {
          reqBody = {
            providerId: user.id,
            verified: !verified,
            reason: reason
          };

        } else {
          if (reason === "") {
            setValidationError("Please enter reason before submit.");
            flag = false;
          }
          reqBody = {
            providerId: user.id,
            verified: verified,
            reason: reason
          };
        }

        if (flag === true) {
          verifyProvider(reqBody)
            .then(() => {
              addToast((action === "Approve" ? "Provider Approved Successfully" : "Provider Rejected Successfully"), {
                appearance: "success",
              });
              setReason("");
              handleApproveModel(false);
            })
            .catch(() => {
              addToast("Provider Approved successfully", {
                appearance: "error",
              });
            });
        }
      } else {
        handleApproveModel(false);
      }

    } else {
      handleApproveModel(false);
    }




  };

  const deleteUserCall = () => {
    deleteUser(selectedUser.id)
      .then(() => {
        loadUser();
        setDeleteModel(false);
        addToast("User deleted successfully.", {
          appearance: "success",
        });
        // toast("User successfully deleted.");
      })
      .catch(() => {
        setDeleteModel(false);
        addToast("There is an error while deleting the user.", {
          appearance: "error",
        });
        // toast("There is an error while deleting the user.");
      });
  };

  const handleDeleteGridCell = (item) => {
    // const options = {
    //   title: "Delete",
    //   message: "Do you want to delete the user?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => deleteUserCall(item),
    //     },
    //     {
    //       label: "No",
    //       onClick: () => {},
    //     },
    //   ],
    //   childrenElement: () => <div />,
    //   closeOnEscape: true,
    //   closeOnClickOutside: true,
    //   keyCodeForClose: [8, 32],
    //   willUnmount: () => {},
    //   afterClose: () => {},
    //   onClickOutside: () => {},
    //   onKeypressEscape: () => {},
    //   overlayClassName: "overlay-custom-class-name",
    // };
    // confirmAlert(options);
    setDeleteModel(true);
    setSelectedUser(item);
  };

  const handleConnectProvider = (provider) => {
    setSelectedProvider(provider);
    setShowConnectModal(true);
  }

  const columns = [
    {
      name: "",
      sortable: false,
      cell: (row) => {
        return (
          row.logo &&
          <span>
            <div className="logoContainer">
              <img src={`https://gopinit.blob.core.windows.net/image-container/${row.logo}`} alt="logo" />
            </div>
          </span>

        );
      }
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
    },
    {
      name: "Email Address",
      selector: (row) => row.emailId,
      sortable: true,
      sortField: "EmailId",
    },
    {
      name: "Contact No",
      selector: (row) => row.contactNo,
      sortable: false,
      sortField: "contactNo",
    },
    {
      name: "Tax Info",
      selector: (row) => row.taxInfo,
      sortable: false,
      sortField: "taxInfo",
    },
    {
      name: "Verified",
      cell: (row) => {
        return (
          <div>
            {row.verified === true ? (
              <div className="gridTableBody_td active">Approved</div>
            ) : row.disapproveReason == null ? (
              <div className="gridTableBody_td text-warning">Pending</div>
            ) : (
              <div className="gridTableBody_td inActive">Rejected</div>
            )}
          </div>
        );
      },
      sortable: false,
      sortField: "verified",
    },
    {
      cell: (row) => (
        <span className="gridTableBody_td_passThrough">
          <DropdownButton
            align="end"
            className="dropDownBtn"
            title={<PassThroughSVG />}
          >
            <Dropdown.Item onClick={(e) => handleViewProvider(row)}>
              View{" "}
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => handleConnectProvider(row)}>
              Connect{" "}
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => handleApproveProvider(row)}>
              Approve{" "}
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => handleRejectProvider(row)}>
              Reject{" "}
            </Dropdown.Item>
          </DropdownButton>
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const userscolumns = [
    {
      name: "",
      sortable: false,
      cell: (row) => {
        return (
          row.profilePicture &&
          <span>
            <div className="logoContainer">
              <img src={`https://gopinit.blob.core.windows.net/image-container/${row.profilePicture}`} alt="logo" />
            </div>
          </span>
        );
      }
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
    },
    {
      name: "Email Address",
      selector: (row) => row.emailId,
      sortable: true,
      sortField: "EmailId",
    },
    {
      name: "Access Type",
      selector: (row) => row.accessTypeId,
      sortable: false,
      sortField: "AccessTypeId",
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <div>
            {row.status ? (
              <div className="gridTableBody_td active">Active </div>
            ) : (
              <div className="gridTableBody_td inActive">InActive</div>
            )}
          </div>
        );
      },
      sortable: false,
      sortField: "Status",
    },
    {
      cell: (row) => (
        <span className="gridTableBody_td_passThrough">
          {checkPermission("UserManagement", "Full") && (
            <DropdownButton
              align="end"
              className="dropDownBtn"
              title={<PassThroughSVG />}
            >
              {row.status == 1 ? (
                <Dropdown.Item onClick={(e) => markActiveInactive(row, false)}>
                  Mark InActive
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={(e) => markActiveInactive(row, true)}>
                  Mark Active
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={(e) => handleEditGridCell(row)}>
                Edit{" "}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteGridCell(row)}>
                Delete
              </Dropdown.Item>
            </DropdownButton>
          )}
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <DataTable columns={userscolumns} data={data.users} />
  );
  const Export = ({ onExport }) => (
    <button className="blueBgButton" onClick={(e) => onExport(e.target.value)}>
      <span className="btnSvg">
        <ExportCSVSVG fill="#FFFFFF" />
      </span>
      Export CSV
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );

  return (
    <>
      <DataTable
        columns={columns}
        data={listData?.records}
        paginationDefaultPage={currentPage}
        pagination
        paginationServer
        paginationTotalRows={listData?.totalRecords}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={pageSize}
        selectableRows
        dense
        onSort={handleSort}
        sortServer
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        actions={actionsMemo}
      />
      {deleteModel && (
        <ConfirmModal
          title="Delete User"
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          cancelText="No"
          handleClose={() => setDeleteModel(false)}
          handleConfirm={deleteUserCall}
          width="300px"
        >
          <div className="childs">
            <p>Do you want to delete {selectedUser.name}? </p>
          </div>
        </ConfirmModal>
      )}
      <ProviderConnectAccountModal provider={selectedProvider} setShow={setShowConnectModal} show={showConnectModal} />

      {approveModel && (
        <ConfirmModal
          title={(action === "Approve" ? "Approve Provider" : "Reject Provider")}
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Ok"
          handleConfirm={() => handleApproveBtn(user.verified, user.disapproveReason)}
          handleClose={() => handleApproveModel(false)}
        >
          {(() => {
            if (user.verified) {
              return (
                <div className="childs">
                  <span style={{ color: "red" }}>This user is already approved.</span>
                </div>
              )
            } else {
              if (user.disapproveReason == null) {
                return (
                  <div className="text-center childs">
                    <p className="text-gray-1000 mb-2">Add reason to {(action === "Approve" ? "Approve Provider" : "Reject Provider")}</p>
                    <textarea
                      rows={5}
                      style={{ border: "1px solid #efefef", height: 250 }}
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                    <span style={{ color: "red" }}>{validationError}</span>
                  </div>
                )
              } else {
                return (
                  <div className="childs text-center">
                    <span style={{ color: "red" }}>This user is already rejected.</span>
                  </div>
                )
              }
            }
          })()}

        </ConfirmModal>
      )}
    </>
  );
};

export default Providers;

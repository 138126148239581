import React, { useState } from "react";
import { FloatingLabel } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { SVGCalendar } from "../../assets/svgComponents/svg";
import Calendar from 'react-calendar';


export const InputDatePicker = ({
  date,
  onChange,
  value,
  setDate,
  dateValue,
  label,
  name,
}) => {
  // const [value, onChange] = useState(new Date());

  return (
    <div>
      <FloatingLabel
        controlId="floatingInput"
        label={label ? label : "Expiry Date"}
        className="mb-3"
      >
        <DatePicker
          name={name}
          onChange={(date) => onChange(date)}
          value={value}
          calendarIcon={<SVGCalendar fill="#AFAFAF" />}
        /> 
        <Calendar className="mt-3" onChange={onChange} value={value} />
      </FloatingLabel>
    </div>
  );
};

import React from "react";
import { Card } from "react-bootstrap";

// import TableActions from "../../views/app/Provider/InventorySites/TableActions";
import TableComponents from "./child/TableComponents";
import TableFooter from "./child/TableFooter";

const Grid = (props) => {
  return (
    <>
      <div className="girdMain">
        {/* <TableActions /> */}
        <Card className="gridCard" aria-labelledby="gridCard">
          <Card.Body className="gridCardBody p-0">
            <div className="girdTable">
              <TableComponents {...props} />
            </div>
          </Card.Body>
          <TableFooter {...props} />
        </Card>
      </div>
    </>
  );
};

export default Grid;

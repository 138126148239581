import React, { useState } from "react";
import { useContext } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useHistory } from "react-router";
import { PassThroughSVG } from "../../../assets/svgComponents/inventorySVG";
import Checkbox from "../../checkbox/Checkbox";
import ProjectContext from "../../../context/ProjectContext";
import TableCol from "./TableCol";

const TableBody = (props) => {
  const { accessorName } = props;
  const [pathName, setPathName] = useState("second");
  const history = useHistory();

  // console.log(props, "props TableBody");
  if (props.loader && props.loader) {
    return null; //<div>Loading...</div>;
  } else {
    return (
      <>
        <tbody>
          {props.tableBody?.records ? (
            props.tableBody?.records.map((item, index) => (
              <tr
                onClick={() =>
                  props.handleSelectRow && props.handleSelectRow(item)
                }
                key={index}
              >
                {accessorName.map((col, idx) => (
                  <>
                    {col === "checkBox" ? (
                      <td>
                        <div>
                          <Checkbox
                            isChecked={item.isChecked}
                            checked={item.isChecked}
                            status={item.isChecked}
                            action={() => {
                              props.handleChecked(item);
                            }}
                          />
                        </div>
                      </td>
                    ) : col === "profilePic" ? (
                      props.isAvatar && (
                        <td>
                          <div className="d-flex justify-content-center">
                            <img
                              src="https://placekitten.com/300/300"
                              className="gridAvatar"
                            />
                          </div>
                        </td>
                      )
                    ) : col === "actions" ? (
                      <td>
                        <span className="gridTableBody_td_passThrough">
                          <DropdownButton
                            align="end"
                            className="dropDownBtn"
                            title={<PassThroughSVG />}
                          >
                            {props.handleViewGridCell && (
                              <Dropdown.Item
                                onClick={() => props.handleViewGridCell(item)}
                              >
                                View
                              </Dropdown.Item>
                            )}
                            {props.handleEditGridCell && (
                              <Dropdown.Item
                                onClick={(e) => props.handleEditGridCell(item)}
                              >
                                Edit{" "}
                              </Dropdown.Item>
                            )}
                            {props.handleDeleteGridCell && (
                              <Dropdown.Item
                                onClick={() => props.handleDeleteGridCell(item)}
                              >
                                Delete
                              </Dropdown.Item>
                            )}
                          </DropdownButton>
                        </span>
                      </td>
                    ) : col === "featured" ? (
                      <>
                        <td>
                          {item[accessorName[idx]] ? (
                            <div className="gridTableBody_td active">
                              Active
                            </div>
                          ) : (
                            <div className="gridTableBody_td inActive">
                              Inactive
                            </div>
                          )}
                        </td>
                      </>
                    ) : (
                      <TableCol
                        item={item}
                        accessorName={accessorName}
                        idx={idx}
                        configId={props.configId}
                      />
                    )}
                  </>
                ))}
              </tr>
            ))
          ) : (
            <h1>No data</h1>
          )}
        </tbody>
      </>
    );
  }
};

export default TableBody;

import React from "react";
import { Form } from "react-bootstrap";
import "./custom-select.css";
import Select from "react-select";

export const SelectComponent = ({
handleOnchange, 
option,
inputsize,
name,
value,
defaultValue,
size,
className,
}) => {
return (
 

<Select
    name={name}
    value={value}
    onChange={handleOnchange}
    options={option}
    className={className ? className : "customDropdown"}
    //placeholder="Select" 
    defaultValue={value}
    getOptionValue={(option) => option.id}
    getOptionLabel={(option) => option.name}
    styles={{cursor:"pointer"}}
    // menuIsOpen={true}
/>
);
};

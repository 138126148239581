import { http } from "../httpService";

export function getCustomerDashBoard(reqBody){  
  return http.get(`/api/Dashboard/GetCustomerDashBoard${reqBody}` );
}

export function getBySitesList(reqBody) {
  return http.post(`/api/Listing/Search`, reqBody);
}



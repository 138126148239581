import React from "react";
import "./assets/css/styles.css";
import "./assets/css/chatPopHead.css";
import "./assets/scss/styles.scss";
import { ProjectContext } from "./context/ProjectContext";
import { ThemeContext } from "./context/ThemeContext";
import WelcomePage from "./views/WelcomePage";
import {Provider} from 'react-redux';
import { store } from './store'
import "react-datepicker/dist/react-datepicker.css";
import { Spinner } from "react-bootstrap";

export function App() {
  return (
    <>
    <Provider store={store}>
        <ThemeContext>
          <ProjectContext>
            <WelcomePage />
          </ProjectContext>
        </ThemeContext>
      </Provider>
      <div className="loader">
            <Spinner
              animation="border"
              role="status"
              style={{
                marginTop: "20%",
                marginLeft: "50%",
                width: "50px",
                height: "50px",
                color: "#F99300",
              }}
            ></Spinner>
      </div>
    </>
  );
}

export default App


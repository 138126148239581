import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory, useLocation } from "react-router";
import * as Yup from "yup";

import {
  DeleteSVG,
  SpringSVG,
  SVGCalendar,
} from "../../../../../../../../assets/svgComponents/svg";
import {
  NotificationSVG,
  PlusSVG,
} from "../../../../../../../../assets/svgComponents/table";
import BtnWhite from "../../../../../../../../components/button/BtnWhite";
import BtnCustom from "../../../../../../../../components/button/BtnYellow";
import BtnYellow from "../../../../../../../../components/button/BtnYellow";
import { InputField } from "../../../../../../../../components/InputField";
import { SelectionComponent } from "../../../../../../../../components/SelectionComponent/SelectionComponent";
import ProjectContext from "../../../../../../../../context/ProjectContext";
import ThemeContext from "../../../../../../../../context/ThemeContext";
import ConfirmModal from "../../../../../../../../components/confirmModal/ConfirmModal";
import featureImage from "../../../../../../../../assets/images/Rectangle_1443.png";
import { GridTable } from "../../../../Inventory/components/ViewSiteInfo/GridTable";
import Pricing from "./components/Pricing";
import RefundsRule from "./components/RefundsRule";
import BookingPeriod from "./components/BookingPeriod";
import SummaryCharges from "./components/SummaryCharges";
import Subscriptions from "./components/Subscriptions";
import NavBar from "../../../../../../../../components/navBar";
import { FieldArray, Form, Formik } from "formik";
import FormikController from "../../../../../../../../components/Formik/FormikController";
import DataTable from "react-data-table-component";
import { CustomSort } from "../../../../../../../../components/DataTable/CustomSort";
import DropDownItem from "../../../../../../../../components/DataTable/DropDownItem";
import {
  createSiteListing,
  getOptionsType,
  updateCreatedListing,
  getRefundTypesWithRules,
} from "../../../../../../../../services/Provider/Configurator/Listings";
import { useToasts } from "react-toast-notifications";
import NewListingConfirmModal from "../modals/NewListingConfirmModal";
import FeaturedListingModal from "../modals/FeaturedListingModal";

import OnBoardingFeatured from "../modals/OnBoardingFeatured";
import { InputFieldText } from "../../../../../../../../components/InputField/InputFieldText";
import {
  getEnvironmentType,
  getUpdateOnBoardingStepDone,
} from "../../../../../../../../services/Provider";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import Unavailabilities from "./components/Unavailabilities";

const AllConfiguration = ({ activeTab, sideTabs, listingData }) => {
  const location = useLocation();
  const history = useHistory();
  const { addToast } = useToasts();
  const { createListing, selectedEntities, handleSelectedEntities } =
    useContext(ProjectContext);
  const [optionTypes, setOptionTypes] = useState({
    priceType: "",
    pricingApplicabilities: "",
    refundType: "",
    refundRulesType: "",
    summaryType: "",
    summaryPriceType: "",
  });
  const [availability, setAvailability] = useState([]);
  const [featuredListing, setFeaturedListing] = useState(false);
  const [registerListingModal, setRegisterListingModal] = useState(false);
  const [termsOptions, setTermsOptions] = useState(null);
  const [summaryChargesData, setSummaryChargesData] = useState();
  const [listingCreatedModal, setListingCreatedModal] = useState(false);
  const [isSignsAdded, setIsSignsAdded] = useState(false);
  const [featuredListingDetails, setFeaturedListingDetails] = useState({});
  const [saveLoader, setSaveLoader] = useState(false);
  const [featuredDate, setFeaturedDate] = useState({
    startDate: new Date(),
    endDate: null,
  });
  const [signs, setSigns] = useState([
  ]);
  const [dateRanges, setDateRanges] = useState([
    { fromDate: null, tOdate: null, signId: null, sign: null },
  ]);

  // const [featuredListing, setFeaturedListing] = useState(false);
  const [error, setError] = useState({
    minBookingPeriod: "",
    turnAroundTime: "",
    availableFor: "",
    priceType: "",
    applicability: "",
    minimumQuantity: "",
    maximumQuantity: "",
    amount: "",
    serviceOrChargeId: "",
    summaryChargesType: "",
    refundType: "",
    rulesType: "",
    term: "",
    downPaymentPercentage: "",
    unavailabilities: "",
    refundTypeMaxValue1: "",
    refundTypeMaxValue2: "",
    refundTypeMaxValue3: "",
    refundTypeMaxValue4: "",
  });

  console.log(location.data,"sdjkdh");

  const [allConfiguration, setAllConfiguration] = useState({
    minBookingPeriod: location?.data?.minBookingPeriod
      ? location.data.minBookingPeriod
      : null,
    turnAroundTime: location?.data?.turnAroundTime
      ? location.data.turnAroundTime
      : null,
    priceType: location?.data?.pricing[0]?.priceType
      ? location.data.pricing[0]?.priceType
      : 0,
    applicability: 0,
    availableFor: location?.data?.availableFor
      ? location?.data?.availableFor
      : 0,
    pricing: location?.data?.pricing
      ? location?.data?.pricing
      : [
          {
            amount: null,
            applicability: 0,
            minimumQuantity: 0,
            maximumQuantity: 0,
            priceType: location?.data?.pricing[0]?.priceType
              ? location.data.pricing[0]?.priceType
              : null,
          },
        ],
    summaryCharges: [
      {
        serviceOrChargeId: "",
        summaryChargesType: 0,
        chargesTypeId: 0,
        priceTypeId: 0,
        priceValue: 0,
      },
    ],
    rushFee: location?.data?.rushFee ? location.data.rushFee : 0,
    rushFeePriorDays : location?.data?.rushFeePriorDays ? location.data.rushFeePriorDays : 3,
    refundType: location?.data?.refundType ? location.data.refundType : 0,
    refundRules: location?.data?.refundRules
      ? location?.data?.refundRules
      : [
          {
            refundPercentage: 0,
            orderStatus: 2,
          },
          {
            refundPercentage: 0,
            orderStatus: 3,
          },
          {
            refundPercentage: 0,
            orderStatus: 4,
          },
          {
            refundPercentage: 0,
            orderStatus: 5,
          },
        ],
    subscriptionTerms: {
      term: location?.data?.subscriptionTerms?.term
        ? location?.data?.subscriptionTerms?.term
        : null,
      downPaymentPercentage: 0.0,
    },
  });
  console.log(allConfiguration, "allConfiguration");
  let tempStepCount = parseInt(localStorage.getItem("onBoardingStepDone"));

  useEffect(() => {
    if (
      listingData &&
      listingData.listingUnAvailabilities &&
      listingData.listingUnAvailabilities.length > 0
    ) {
      setDateRanges([...listingData.listingUnAvailabilities]);
    }
  }, [listingData]);

  useEffect(() => {
    //console.log(createListing, listingData, "createListing useEffect");
    let tempSer = createListing?.sevices ? createListing.sevices : [];
    tempSer.forEach((ser) => {
      var service = listingData?.summaryChargesItems.filter(
        (s) => s.serviceId != null && s.serviceId == ser.serviceOrChargeId
      );
      //console.log(service, "tempData");
      if (service != null) {
        ser.priceTypeId = service.length > 0 ? service[0].priceTypeId : 0;
        ser.chargesTypeId = service.length > 0 ? service[0].chargesTypeId : 0;
        ser.priceValue = service.length > 0 ? service[0].priceValue : 0;
      }
    });

    let tempCharge = createListing?.charges ? createListing.charges : [];
    tempCharge.forEach((crg) => {
      var charge = listingData?.summaryChargesItems.filter(
        (s) => s.chargeId != null && s.chargeId == crg.serviceOrChargeId
      );
      if (charge != null) {
        crg.priceTypeId = charge.length > 0 ? charge[0].priceTypeId : 0;
        crg.chargesTypeId = charge.length > 0 ? charge[0].chargesTypeId : 0;
        crg.priceValue = charge.length > 0 ? charge[0].priceValue : 0;
      }
    });
    let tempData = [...tempSer, ...tempCharge];
    //console.log(tempData, "tempData");
    setSummaryChargesData(tempData);
    // tempData.forEach((item) => {
    //   item.chargesTypeId = 0;
    //   item.priceTypeId = 0;
    //   item.priceValue = 0;
    // });
    sideTabs.filter((item) => item.name == "Signs" && setIsSignsAdded(true));
      if (createListing.signs) {
        let signsArr=[];
        createListing.signs.map((sign=>{
        signsArr.push({id: sign.signId, name: sign.description})
      }))
      setSigns(signsArr);
    }
    getEnvironmentType("Availabilities")
      .then((res) => {
        if (res.status === 200) {
          setAvailability(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    // console.log(tempData, "tempData");
  }, []);

  useEffect(() => {
    let tempOptions = [];
    let lenTerm =
      allConfiguration?.minBookingPeriod > 1
        ? allConfiguration.minBookingPeriod - 1
        : allConfiguration.minBookingPeriod;
    for (let i = 0; i < lenTerm; i++) {
      tempOptions = [...tempOptions, { id: i + 1, name: i + 1 }];
    }
    // console.log(tempOptions, "tempOptions");
    setTermsOptions(tempOptions);
    // return
  }, [allConfiguration.minBookingPeriod]);

  const columns = [
    {
      name: "id".toUpperCase(),
      // selector: (row) => row.id,
      cell: (row) => <div>{row.serviceOrChargeId}</div>,
      sortable: true,
    },
    {
      name: "Name".toUpperCase(),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "type",
      width: "10rem",
      cell: (row, index) => (
        <div>
          <SelectionComponent
            name="chargesTypeId"
            label="Type"
            defaultValue={row.chargesTypeId}
            option={optionTypes.summaryType}
            handleOnchange={(e) => {
              onChangeSummmary(e, index);
            }}
          />
        </div>
      ),
      allowOverflow: true,
      button: true,
    },
    {
      name: "Pricing",
      width: "20rem",
      cell: (row, index) => (
        <div className="d-flex mx-3 my-3">
          <div className="mx-2">
            <SelectionComponent
              name="priceTypeId"
              label="Price Type"
              defaultValue={row.priceTypeId}
              option={optionTypes?.summaryPriceType}
              disabled={
                summaryChargesData &&
                summaryChargesData[index].chargesTypeId == 0
              }
              handleOnchange={(e) => {
                onChangeSummmary(e, index);
              }}
            />
          </div>
          <div className="mx-2">
            <InputField
              name="priceValue"
              label="Price"
              disabled={
                summaryChargesData &&
                summaryChargesData[index].chargesTypeId == 0
              }
              onChange={(e) => onChangeSummmary(e, index)}
              value={row.priceValue}
            />
          </div>
        </div>
      ),
      allowOverflow: true,
      button: true,
    },
  ];

  const onChangeSummmary = (e, index) => {
    const { value, name } = e.target;
    let summaryData = [...summaryChargesData];
    summaryData[index][name] = value;
    setSummaryChargesData(summaryData);
  };

  // fetch pricing type data
  //getOptionsType("RefundTypes"),
  useEffect(async () => {
    const [result1, result2, result3, result4, result5, result6] =
      await Promise.all([
        getOptionsType("PriceTypes"),
        getOptionsType("PricingApplicabilities"),
        getOptionsType("RefundTypes"),
        getOptionsType("RefundRulesType"),
        getOptionsType("ChargesTypes"),
        getOptionsType("PriceTypeForSummaryCharges"),
      ]);
    try {
      if (result1.status == 200) {
        setOptionTypes((prevState) => ({
          ...prevState,
          priceType: result1.data,
        }));
      }
      if (result2.status == 200) {
        setOptionTypes((prevState) => ({
          ...prevState,
          pricingApplicabilities: result2.data,
        }));
      }
      if (result3.status == 200) {
        /* setOptionTypes((prevState) => ({
          ...prevState,
          refundType: result3.data,
        })); */
      }
      if (result4.status == 200) {
        /*   setOptionTypes((prevState) => ({
          ...prevState,
          refundRulesType: result4.data,
        })); */
        // console.log(result4.data, "result4");
      }
      if (result5.status == 200) {
        setOptionTypes((prevState) => ({
          ...prevState,
          summaryType: result5.data,
        }));
      }
      if (result6.status == 200) {
        setOptionTypes((prevState) => ({
          ...prevState,
          summaryPriceType: result6.data,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(async () => {
    await getRefundTypesWithRules()
      .then((res) => {
        if (res.status == 200) {
          setOptionTypes((prevState) => ({
            ...prevState,
            refundType: res.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // handle for delete pricing row
  const handleDeletePriceRow = (index) => {
    let tempRows = [...allConfiguration.pricing];
    tempRows.splice(index, 1);
    setAllConfiguration((prevState) => ({ ...prevState, pricing: tempRows }));
  };

  const handlePricingInput = (e, i) => {
    const { value, name } = e.target;
    let priceList = [...allConfiguration.pricing];
    if (name == "maximumQuantity") {
      console.log(value, "value");
      if (value > createListing.numberOfSign) {
        setError({
          maximumQuantity:
            "Maximum Quantity value cannot be greater than number of sign",
        });
      } else {
        priceList[i]["maximumQuantity"] = value == "" ? value : parseInt(value);
        setError({
          maximumQuantity: "",
        });
      }
    } else {
      priceList[i][name] = value == "" ? value : parseInt(value);
    }
    if (i == 0) {
    } else {
      if (
        allConfiguration.pricing[i - 1].maximumQuantity >
        priceList[i].minimumQuantity
      ) {
        priceList[i].errorMsg = true;
      } else if (
        allConfiguration.pricing[i - 1].maximumQuantity <
        priceList[i].minimumQuantity
      ) {
        priceList[i].errorMsg = false;
      }
    }
    setAllConfiguration((prevState) => ({ ...prevState, pricing: priceList }));
  };

  // handle add more field under pricing
  const handleAddMorePricing = () => {
    let tempField = [...allConfiguration.pricing];
    tempField = [
      ...tempField,
      {
        amount: 0,
        applicability: 0,
        minimumQuantity: 0,
        maximumQuantity: 0,
        priceType: 0,
      },
    ];
    setAllConfiguration((prevState) => ({ ...prevState, pricing: tempField }));
  };

  const handleRefundRuleInput = (e) => {
    const { name, value } = e.target;

    if (value === "Select Refund Type") {
      setError((prevState) => ({
        ...prevState,
        refundType: "Please select refund type",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        refundType: "",
      }));
      if (value === "0") {
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue1: "",
        }));
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue2: "",
        }));
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue3: "",
        }));
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue4: "",
        }));
      }
      setAllConfiguration((prevState) => ({
        ...prevState,
        refundType: parseInt(value),
        refundRules: [
          {
            refundPercentage: value == "0" ? "97" : "",
            orderStatus: 2,
          },
          {
            refundPercentage: value == "0" ? "50" : "",
            orderStatus: 3,
          },
          {
            refundPercentage: value == "0" ? "0" : "",
            orderStatus: 4,
          },
          {
            refundPercentage: value == "0" ? "0" : "",
            orderStatus: 5,
          },
        ],
      }));
    }

    /*
    let tempRefund = [...allConfiguration.refundType];
    tempRefund[index][name] = parseInt(value);
    setAllConfiguration((prevState) => ({
      ...prevState,
      refundRules: tempRefund,
    }));
    */
  };
  const handleRefundFromInput = (e, index) => {
    const { name, value } = e.target;
    let tempRefund = [...allConfiguration.refundRules];
    let refundValue = parseInt(value);
    if (refundValue > 97) {
      if (index == 0) {
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue1: "Refund percentage cannot be greater than 97",
        }));
      }
      if (index == 1) {
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue2: "Refund percentage cannot be greater than 97",
        }));
      }
      if (index == 2) {
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue3: "Refund percentage cannot be greater than 97",
        }));
      }
      if (index == 3) {
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue4: "Refund percentage cannot be greater than 97",
        }));
      }
    } else {
      if (index == 0) {
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue1: "",
        }));
      }
      if (index == 1) {
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue2: "",
        }));
      }
      if (index == 2) {
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue3: "",
        }));
      }
      if (index == 3) {
        setError((prevState) => ({
          ...prevState,
          refundTypeMaxValue4: "",
        }));
      }
      tempRefund[index][name] = refundValue;
      setAllConfiguration((prevState) => ({
        ...prevState,
        refundRules: tempRefund,
      }));
    }
  };

  const handleRefundToInput = (e, index) => {
    const { name, value } = e.target;
    let tempRefund = [...allConfiguration.refundRules];
    // console.log(tempRefund, "tempRefund");
    tempRefund[index][name] = parseInt(value);
    setAllConfiguration((prevState) => ({
      ...prevState,
      refundRules: tempRefund,
    }));
  };

  const handleRefundPercentInput = (e, index) => {
    const { name, value } = e.target;
    let tempRefund = [...allConfiguration.refundRules];
    // console.log(tempRefund, "tempRefund");
    tempRefund[index][name] = parseInt(value);
    setAllConfiguration((prevState) => ({
      ...prevState,
      refundRules: tempRefund,
    }));
  };

  const handleAddMoreRefundRules = (e, index) => {
    let tempField = [...allConfiguration.refundRules];
    tempField = [
      ...tempField,
      {
        refundPercentage: 0,
        daysFrom: 0,
        daysTo: 0,
      },
    ];
    setAllConfiguration((prevState) => ({
      ...prevState,
      refundRules: tempField,
    }));
  };
  const handleDeleteRefundsRuleRow = (index) => {
    let tempRows = [...allConfiguration.refundRules];
    tempRows.splice(index, 1);
    setAllConfiguration((prevState) => ({
      ...prevState,
      refundRules: tempRows,
    }));
  };
  const handleWhiteBtn = () => {
    history.push("/provider/configurator/listings");
  };

  const handleSave = async (item) => {
    if (
      !allConfiguration.minBookingPeriod ||
      !allConfiguration.turnAroundTime ||
      !allConfiguration.pricing[0].amount ||
      allConfiguration.subscriptionTerms.term == null ||
      isNaN(allConfiguration.subscriptionTerms.term)
    ) {
      setError((prevState) => ({
        ...prevState,
        minBookingPeriod: "",
        turnAroundTime: "",
        amount: "",
        term: "",
      }));
      if (
        allConfiguration.availableFor == null ||
        isNaN(allConfiguration.availableFor)
      ) {
        setError((prevState) => ({
          ...prevState,
          availableFor: "Please select available for",
        }));
      }
      if (!allConfiguration.minBookingPeriod) {
        setError((prevState) => ({
          ...prevState,
          minBookingPeriod: "Please enter minimum booking periods",
        }));
      }
      if (!allConfiguration.turnAroundTime) {
        setError((prevState) => ({
          ...prevState,
          turnAroundTime: "Please enter turn around time",
        }));
      }
      if (!allConfiguration.pricing[0].amount) {
        console.log(!allConfiguration.pricing[0].amount);
        setError((prevState) => ({
          ...prevState,
          amount: "Please enter price",
        }));
      }
      if (
        allConfiguration.refundType == null ||
        isNaN(allConfiguration.refundType)
      ) {
        setError((prevState) => ({
          ...prevState,
          refundType: "Please select refund type",
        }));
      }
      if (
        allConfiguration.subscriptionTerms.term == null ||
        isNaN(allConfiguration.subscriptionTerms.term)
      ) {
        setError((prevState) => ({
          ...prevState,
          term: "Please select subscription term",
        }));
      }
      if (dateRanges) {
        for (let index = 0; index < dateRanges.length; index++) {
          const dateRange = dateRanges[index];
          if (
            (dateRange.signId != null &&
              dateRange.signId != "" &&
              (dateRange.fromDate == null || dateRange.tOdate == null)) ||
            ((dateRange.signId == null || dateRange.signId == "") &&
              index > 0 &&
              (dateRange.fromDate == null || dateRange.tOdate == null))
          ) {
            setError((prevState) => ({
              ...prevState,
              unavailabilities:
                "Please select proper date range for unavailability",
            }));
          }
        }
      }
    } else {
      let tempConfiguration = [...allConfiguration.pricing];
      tempConfiguration.map(
        (item) => (
          (item.priceType = allConfiguration.priceType),
          (item.applicability = allConfiguration.applicability)
        )
      );
      allConfiguration.summaryCharges = summaryChargesData;
      let tempServiceIds = [];
      createListing?.sevices?.length > 0 &&
        createListing?.sevices?.forEach((item) =>
          tempServiceIds.push(item.serviceOrChargeId)
        );

      let tempChargeIds = [];
      createListing?.charges?.length > 0 &&
        createListing?.charges?.forEach((item) =>
          tempChargeIds.push(item.serviceOrChargeId)
        );
      // let tempSignIds = [];
      // createListing?.signIds?.length > 0 &&
      //   createListing?.signIds?.forEach((item) =>
      //     tempSignIds.push(item)
      //   );
      if (!location.isUpdateListing) {
        const createReqBody = {
          name: createListing.name,
          availableFor: allConfiguration.availableFor,
          siteIds: createListing?.sitesIds ? [createListing?.sitesIds] : [],
          signIds: createListing?.signIds ? [createListing?.signIds] : [],
          serviceIds: tempServiceIds,
          chargeIds: tempChargeIds,
          signs: createListing?.signs,
          minBookingPeriod: allConfiguration.minBookingPeriod,
          periodType: 1,
          turnAroundTime: allConfiguration.turnAroundTime,
          rushFee: allConfiguration.rushFee,
          rushFeePriorDays: 3,
          turnAroundTimePeriod: 0,
          refundType: allConfiguration.refundType,
          refundRules: allConfiguration.refundRules,
          subscriptionTerms: allConfiguration.subscriptionTerms,
          pricing: allConfiguration.pricing,
          summaryCharges: summaryChargesData,
          listingUnAvailabilityRequest: dateRanges,
        };

        let tempEntities = [...selectedEntities];
        tempEntities.map((item) => (item.isActive = false));
        handleSelectedEntities(tempEntities);
        if (tempStepCount == 3) {
          localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
          let bodyData = [
            {
              path: "/onBoardingStepDone",
              op: "add",
              value: tempStepCount + 1,
            },
          ];
          getUpdateOnBoardingStepDone(bodyData)
            .then((res) => {
              console.log("res", res);
            })
            .catch((err) => {
              console.log("err", err);
            });
          await createSiteListing(createReqBody)
            .then((res) => {
              if (res.status == 201) {
              }
            })
            .catch((err) => {
              console.log(err);
              setSaveLoader(false);
            });
          history.push("/provider");
        } else {
          setSaveLoader(true);
          console.log("createReqBody", createReqBody);
          await createSiteListing(createReqBody)
            .then((res) => {
              if (res.status == 201) {
                setFeaturedListingDetails((prev) => ({
                  ...prev,
                  listingId: res.data,
                  name: createListing.name,
                  price: 200,
                }));
                setSaveLoader(false);
                setListingCreatedModal(true);
              }
            })
            .catch((err) => {
              console.log(err);
              setSaveLoader(false);
            });
        }
      } else {
        const updateReqBody = {
          id: location.data.id,
          name: createListing.name,
          availableFor: allConfiguration.availableFor,
          siteIds: createListing?.sitesIds ? [createListing?.sitesIds] : [],
          signIds: createListing?.signIds ? [createListing?.signIds] : [],
          serviceIds: tempServiceIds,
          chargeIds: tempChargeIds,
          signs: createListing?.signs,
          minBookingPeriod: allConfiguration.minBookingPeriod,
          periodType: 1,
          turnAroundTime: allConfiguration.turnAroundTime,
          turnAroundTimePeriod: 0,
          rushFee: allConfiguration.rushFee,
          rushFeePriorDays: 3,
          refundType: allConfiguration.refundType,
          refundRules: allConfiguration.refundRules,
          subscriptionTerms: allConfiguration.subscriptionTerms,
          pricing: allConfiguration.pricing,
          summaryCharges: summaryChargesData,
          listingUnAvailabilityRequest: dateRanges,
        };
        console.log(updateReqBody, "updateReqBody");

        await updateCreatedListing(updateReqBody)
          .then((res) => {
            if (res.status == 201) {
              // setFeaturedListing(true);
              addToast("Listing update successfully", {
                appearance: "success",
              });
              history.push("/provider/configurator/listings");
            }
          })

          .catch((err) => {
            console.log(err);
            setSaveLoader(false);
          });
      }
    }

    // console.log(item, "item save");
    // console.log(allConfiguration, "allConfiguration save");
  };
  // setNewListingCreatedModal(true);
  // console.log(initialValues, "initialValues");

  const handleConfirmListCreatedModal = () => {
    setListingCreatedModal(false);
    setFeaturedListing(true);
  };
  const onChangeFeaturedDate = (dates) => {
    const [start, end] = dates;
    // setStartDate(start);
    // setEndDate(end);
    setFeaturedDate({
      startDate: start,
      endDate: end,
    });
  };
  const handleRegisterDateFetaured = () => {
    // alert("hello");
    if (featuredDate.endDate) {
      let tempFeaturedListingDetails = {
        listingId: featuredListingDetails.listingId,
        name: featuredListingDetails.name,
        price: featuredListingDetails.price,
        start_date: moment(featuredDate.startDate).format("DD MMMM YYYY"),
        end_date: moment(featuredDate.endDate).format("DD MMMM YYYY"),
      };
      // console.log(tempFeaturedListingDetails, "tempFeaturedListingDetails");

      history.push({
        pathname: `/provider/payment`,
        featuredListingDetails: tempFeaturedListingDetails,
      });
    } else {
      addToast("Please enter featured listing end date", {
        appearance: "warning",
      });
    }
  };

  const addNewRange = () => {
    let ranges = [
      ...dateRanges,
      { fromDate: null, tOdate: null, signId: null, sign: null },
    ];
    setDateRanges(ranges);
  };

  const deleteRange = (index) => {
    let ranges = [...dateRanges];
    ranges.splice(index, 1);
    setDateRanges(ranges);
    setError((prevState) => ({ ...prevState, unavailabilities: "" }));
  };

  const setDateRange = (range, itemIndex) => {
    if (range && range.length > 0) {
      let fromDate = range[0]
        ? moment(new Date(range[0])).format("DD-MMM-YYYY")
        : null;
      let tOdate = range[1]
        ? moment(new Date(range[1])).format("DD-MMM-YYYY")
        : null;
      let ranges = [];

      for (let index = 0; index < dateRanges.length; index++) {
        const dateRange = dateRanges[index];
        if (index == itemIndex) {
          ranges.push({ ...dateRange, fromDate: fromDate, tOdate: tOdate });
        } else {
          ranges.push({ ...dateRange });
        }
        setDateRanges(ranges);
        console.log("updated range", dateRanges);
      }
      setError((prevState) => ({ ...prevState, unavailabilities: "" }));
    }
  };

  const handleSignChange = (selectedOption, itemIndex) => {
    const { value } = selectedOption.target;
    let ranges = [];
    for (let index = 0; index < dateRanges.length; index++) {
      const dataRange = dateRanges[index];
      if (index == itemIndex) {
        ranges.push({ ...dataRange, signId: value });
      } else {
        ranges.push({ ...dataRange });
      }
      setDateRanges(ranges);
      console.log("updated range", dateRanges);
    }
    setError((prevState) => ({ ...prevState, unavailabilities: "" }));
  };

  const refundPercentageInput = {
    borderTopRightRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
  };

  return (
    <>
      {/* {console.log(featuredListingDetails, "setFeaturedListingDetails")} */}
      {console.log(createListing, "createListing")}
      {/* {console.log(allConfiguration, "allConfiguration")} */}
      <div className="w-100">
        <NavBar
          // handleWhitebtn={() => setConfirmModal(true)}
          whiteBtnText={"Cancel"}
          blueBtnText={"Save"}
          notificationSVG={<NotificationSVG />}
          navHeading={createListing.name}
          subHeading="All Billable Item "
          subActiveHeading=" New Billable Item"
          handleBlueBtn={() => handleSave(activeTab)}
          handleWhitebtn={() => handleWhiteBtn(activeTab)}
          isDisabled={saveLoader}
          isDisabledText="Saving..."
          showButton={true}
        />
        <div className="p-20 gridSection">
          <div className="d-flex w-100">
            <div>
              <ul className=" progressbar">
                {sideTabs?.map((tab) => (
                  <li
                    className={`billable_switchSideDom_item ${
                      tab.isActive && "active"
                    }
                    ${tab.isCurrentTitle && "activeTabTitle"}
                   `}
                  >
                    {tab.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-100">
              <div className="allConfig">
                <Card className="borderRadius p-4">
                  <div className="d-flex">
                    <div>
                      <InputFieldText
                        label={"Minimum Booking (in weeks)"}
                        name="minBookingPeriod"
                        type={"number"}
                        labelText="Weeks"
                        value={allConfiguration.minBookingPeriod}
                        // onBlur={() =>
                        //   setError((prevState) => ({
                        //     ...prevState,
                        //     minBookingPeriod:
                        //       "Please enter minimum booking periods ",
                        //   }))
                        // }
                        onChange={(e) => {
                          setError((prevState) => ({
                            ...prevState,
                            minBookingPeriod: "",
                          }));
                          setAllConfiguration((prev) => ({
                            ...prev,
                            minBookingPeriod: parseInt(e.target.value),
                          }));
                        }}
                      />

                      <div className="text-danger">
                        {error.minBookingPeriod}
                      </div>
                    </div>
                    <div>
                      <InputFieldText
                        label={"Turn Around"}
                        name="turnAroundTime"
                        type={"number"}
                        labelText="Days"
                        value={allConfiguration.turnAroundTime}
                        // onBlur={() =>
                        //   setError((preState) => ({
                        //     ...preState,
                        //     turnAroundTime: "Please enter turn around time",
                        //   }))
                        // }
                        onChange={(e) => {
                          setAllConfiguration((prev) => ({
                            ...prev,
                            turnAroundTime: parseInt(e.target.value),
                          }));
                          setError((prevState) => ({
                            ...prevState,
                            turnAroundTime: "",
                          }));
                        }}
                      />
                      <div className="text-danger">{error.turnAroundTime}</div>
                      {/* {!allConfiguration.turnAroundTime &&
                        error.turnAroundTime && (
                          <div className="text-danger">
                            Please enter turn around time
                          </div>
                        )} */}
                    </div>
                    <div className="inputContainer">
                      <SelectionComponent
                        label="Available For"
                        name="availableFor"
                        option={availability}
                        defaultValue={allConfiguration.availableFor}
                        // value={allConfiguration.priceType}
                        handleOnchange={(e) => {
                          e.target.value &&
                            setError((prevState) => ({
                              ...prevState,
                              availableFor: "",
                            }));
                          setAllConfiguration((prevState) => ({
                            ...prevState,
                            availableFor: e.target.value,
                          }));
                        }}
                      />
                      <div className="text-danger">{error.availableFor}</div>
                    </div>
                  </div>
                  <Row className="my-4">
                    <Col md={8} className="d-flex align-items-center title01">
                      Pricing
                    </Col>
                  </Row>
                  <Row>
                    {!isSignsAdded ? (
                      <Col>
                        <div className="d-flex">
                          <div className="inputContainer">
                            <SelectionComponent
                              label="Tier Pricing"
                              name="priceType"
                              option={optionTypes?.priceType}
                              defaultValue={
                                allConfiguration.pricing[0].priceType
                              }
                              optionDisabled={
                                createListing.signIds == null
                                  ? "Differential"
                                  : ""
                              }
                              // value={allConfiguration.priceType}
                              handleOnchange={(e) => {
                                setAllConfiguration((prevState) => ({
                                  ...prevState,
                                  priceType: e.target.value,
                                  pricing: [
                                    {
                                      amount: null,
                                      applicability: 0,
                                      minimumQuantity: 0,
                                      maximumQuantity: 0,
                                      priceType: e.target.value,
                                    },
                                  ],
                                }));
                              }}
                            />
                          </div>

                          <div className="inputContainer">
                            <SelectionComponent
                              label="Applicable During"
                              name="applicability"
                              option={optionTypes?.pricingApplicabilities}
                              defaultValue={
                                allConfiguration.pricing[0].applicability
                              }
                              handleOnchange={(e) => {
                                setAllConfiguration((prevState) => ({
                                  ...prevState,
                                  applicability: e.target.value,
                                  pricing: [
                                    {
                                      amount: null,
                                      applicability: e.target.value,
                                      minimumQuantity: 0,
                                      maximumQuantity: 0,
                                      priceType: 1,
                                    },
                                  ],
                                }));
                              }}
                            />
                          </div>
                          {
                            allConfiguration.priceType == 1 && (
                              <div className="inputContainer">
                                <InputField
                                  label={"Price per minimum booking period"}
                                  name="amount"
                                  type={"text"}
                                  value={allConfiguration.pricing[0]?.amount}
                                  onChange={(e) => {
                                    handlePricingInput(e, 0);
                                  }}
                                />
                                <div className="text-danger">
                                  {error.amount}
                                </div>
                              </div>
                            )
                            /*
                              allConfiguration.pricing.map((val, index) => (                              
                              ))
                            */
                          }
                        </div>
                      </Col>
                    ) : (
                      <Col>
                        <div className="d-flex">
                          <div className="inputContainer">
                            <SelectionComponent
                              label="Tier Pricing"
                              name="priceType"
                              option={optionTypes?.priceType}
                              //defaultValue={0}
                              defaultValue={
                                allConfiguration.pricing[0].priceType
                              }
                              handleOnchange={(e) => {
                                setAllConfiguration((prevState) => ({
                                  ...prevState,
                                  priceType: e.target.value,
                                  pricing: [
                                    {
                                      amount: null,
                                      applicability: 0,
                                      minimumQuantity: 0,
                                      maximumQuantity: 0,
                                      priceType: e.target.value,
                                    },
                                  ],
                                }));
                              }}
                            />
                          </div>
                          <div className="inputContainer">
                            <SelectionComponent
                              label="Applicable During"
                              name="applicability"
                              option={optionTypes?.pricingApplicabilities}
                              //defaultValue={0}
                              defaultValue={
                                allConfiguration.pricing[0].applicability
                              }
                              handleOnchange={(e) => {
                                setAllConfiguration((prevState) => ({
                                  ...prevState,
                                  applicability: e.target.value,
                                  pricing: [
                                    {
                                      amount: null,
                                      applicability: e.target.value,
                                      minimumQuantity: 0,
                                      maximumQuantity: 0,
                                      priceType: 0,
                                    },
                                  ],
                                }));
                              }}
                            />
                          </div>
                          {
                            allConfiguration.priceType == 1 && (
                              <div className="inputContainer">
                                <InputField
                                  label={"Price per minimum booking period"}
                                  name="amount"
                                  type={"number"}
                                  value={allConfiguration.pricing[0]?.amount}
                                  onChange={(e) => {
                                    handlePricingInput(e, 0);
                                  }}
                                />
                                <div className="text-danger">
                                  {error.amount}
                                </div>
                              </div>
                            )
                            /*
                            allConfiguration.pricing.map((val, index) => (                             
                            ))
                            */
                          }
                        </div>
                      </Col>
                    )}
                  </Row>
                  {allConfiguration.priceType == 0 && (
                    <>
                      {isSignsAdded &&
                        allConfiguration.pricing.map((val, index) => (
                          <Row className="mt-2">
                            <Col>
                              <div className="d-flex">
                                <div className="inputContainer">
                                  <InputField
                                    label={"Minimum Quantity"}
                                    name="minimumQuantity"
                                    type={"text"}
                                    value={val.minimumQuantity}
                                    onChange={(e) => {
                                      handlePricingInput(e, index);
                                    }}
                                  />
                                  {val.errorMsg && (
                                    <div className="text-danger">
                                      Please enter value greater than previous
                                      maximum quantity
                                    </div>
                                  )}
                                </div>
                                <div className="inputContainer">
                                  <InputField
                                    label={"Maximum Quantity"}
                                    name="maximumQuantity"
                                    type={"text"}
                                    value={val.maximumQuantity}
                                    onChange={(e) => {
                                      handlePricingInput(e, index);
                                    }}
                                  />
                                  <div className="text-danger">
                                    {error.maximumQuantity}
                                  </div>
                                </div>
                                <div className="inputContainer">
                                  <InputField
                                    label={"Price per minimum booking period"}
                                    name="amount"
                                    type={"text"}
                                    value={val.amount}
                                    onChange={(e) => {
                                      handlePricingInput(e, index);
                                    }}
                                  />
                                </div>
                                {index > 0 && (
                                  <span
                                    className="align-self-center cursor"
                                    onClick={() => handleDeletePriceRow(index)}
                                  >
                                    <DeleteSVG />
                                  </span>
                                )}
                              </div>
                            </Col>
                          </Row>
                        ))}
                    </>
                  )}
                  {isSignsAdded && allConfiguration.priceType == 0 && (
                    /*allConfiguration.priceType > createListing.numberOfSign  && */ <Row>
                      <Col md={12}>
                        <div className="addMore_container mt-3 mb-2 cursor">
                          <div onClick={() => handleAddMorePricing()}>
                            <span>
                              <PlusSVG fill="#F99300" />{" "}
                            </span>

                            <span>Add more</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col>
                      <div className="title01 my-4"> Summary Of Charges</div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <div className="card customtable">
                        <DataTable
                          // progressPending={loader}
                          columns={columns}
                          data={summaryChargesData}
                          allowOverflow={true}
                          fixedHeader={{
                            fixedHeader: true,
                            fixedHeaderScrollHeight: "300px",
                          }}
                        />
                      </div>
                    </Col>
                    {/* <SummaryCharges /> */}
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="title01 mt-3 mb-2">Rush Fee</div>
                    </Col>
                    <Col md={12} className="mt-3 mb-3">
                      <div className="d-flex mb-4">
                        <div className="inputContainer">
                          <InputField
                            label={"Rush Fee"}
                            name="rushFee"
                            inputClassName="refundPercentageInput"
                            type={"number"}
                            value={allConfiguration.rushFee}
                            onChange={(e) => {
                              setAllConfiguration((prev) => ({
                                ...prev,
                                rushFee: parseInt(e.target.value),
                              }));
                            }}
                          />
                        </div>
                        {/* <div className="inputContainer">
                          <InputField
                            label={"Prior Day"}
                            name="rushFeePriorDays"
                            inputClassName="refundPercentageInput"
                            type={"number"}
                            value={allConfiguration.rushFeePriorDays}
                            onChange={(e) => {
                              setAllConfiguration((prev) => ({
                                ...prev,
                                rushFeePriorDays: parseInt(e.target.value),
                              }));
                            }}
                          />
                        </div> */}
                        <div className="inputContainer">
                          Rush fee will give an option to customers to prior
                          their booking by {allConfiguration.rushFeePriorDays} days
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="title01 mb-4">Refund Rules</div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="d-flex mb-4">
                        <div className="inputContainer">
                          <SelectionComponent
                            label="Refund Type"
                            name="refundType"
                            defaultValue={allConfiguration.refundType}
                            handleOnchange={(e) => handleRefundRuleInput(e)}
                            option={optionTypes?.refundType}
                          />
                          <div className="text-danger">{error.refundType}</div>
                        </div>
                      </div>
                      <Col>
                        <div className="d-flex mb-4">
                          <div className="inputContainer">
                            <InputGroup className="mb-3">
                              <InputField
                                label={"Refund Percentage"}
                                name="refundPercentage"
                                inputClassName="refundPercentageInput"
                                type={"number"}
                                onChange={(e) => {
                                  handleRefundFromInput(e, 0);
                                }}
                                value={
                                  allConfiguration.refundType == "0"
                                    ? "97"
                                    : allConfiguration.refundRules &&
                                      allConfiguration.refundRules.length > 0
                                    ? allConfiguration.refundRules[0]
                                        .refundPercentage
                                    : "100"
                                }
                                disabled={
                                  allConfiguration.refundType == "0"
                                    ? true
                                    : false
                                }
                              />
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup>
                            <div className="text-danger">
                              {error.refundTypeMaxValue1}
                            </div>
                          </div>
                          <div className="inputContainer">
                            Should be refunded when order status is Order
                            Received.
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex mb-4">
                          <div className="inputContainer">
                            <InputGroup className="mb-3">
                              <InputField
                                label={"Refund Percentage"}
                                name="refundPercentage"
                                inputClassName="refundPercentageInput"
                                type={"number"}
                                onChange={(e) => {
                                  handleRefundFromInput(e, 1);
                                }}
                                value={
                                  allConfiguration.refundType == "0"
                                    ? "50"
                                    : allConfiguration.refundRules &&
                                      allConfiguration.refundRules.length > 1
                                    ? allConfiguration.refundRules[1]
                                        .refundPercentage
                                    : "50"
                                }
                                disabled={
                                  allConfiguration.refundType == "0"
                                    ? true
                                    : false
                                }
                              />
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup>
                            <div className="text-danger">
                              {error.refundTypeMaxValue2}
                            </div>
                          </div>
                          <div className="inputContainer">
                            Should be refunded when order status is Order
                            Processed.
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex mb-4">
                          <div className="inputContainer">
                            <InputGroup className="mb-3">
                              <InputField
                                label={"Refund Percentage"}
                                name="refundPercentage"
                                inputClassName="refundPercentageInput"
                                type={"number"}
                                onChange={(e) => {
                                  handleRefundFromInput(e, 2);
                                }}
                                value={
                                  allConfiguration.refundType == "0"
                                    ? "0"
                                    : allConfiguration.refundRules &&
                                      allConfiguration.refundRules.length > 2
                                    ? allConfiguration.refundRules[2]
                                        .refundPercentage
                                    : "0"
                                }
                                disabled={
                                  allConfiguration.refundType == "0"
                                    ? true
                                    : false
                                }
                              />
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup>
                            <div className="text-danger">
                              {error.refundTypeMaxValue3}
                            </div>
                          </div>
                          <div className="inputContainer">
                            Should be refunded when order status is Order in
                            Installation.
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex mb-4">
                          <div className="inputContainer">
                            <InputGroup className="mb-3">
                              <InputField
                                label={"Refund Percentage"}
                                name="refundPercentage"
                                inputClassName="refundPercentageInput"
                                type={"number"}
                                onChange={(e) => {
                                  handleRefundFromInput(e, 3);
                                }}
                                value={
                                  allConfiguration.refundType == "0"
                                    ? "0"
                                    : allConfiguration.refundRules &&
                                      allConfiguration.refundRules.length > 3
                                    ? allConfiguration.refundRules[3]
                                        .refundPercentage
                                    : "0"
                                }
                                disabled={
                                  allConfiguration.refundType == "0"
                                    ? true
                                    : false
                                }
                              />
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup>
                            <div className="text-danger">
                              {error.refundTypeMaxValue4}
                            </div>
                          </div>
                          <div className="inputContainer">
                            Should be refunded when order status is Order
                            Complete.
                          </div>
                        </div>
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="title01 my-3">Subscription</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="d-flex">
                        <div className="inputContainer">
                          <SelectionComponent
                            label="Term"
                            name="term"
                            type="number"
                            option={termsOptions}
                            //defaultValue={allConfiguration.minBookingPeriod === 1 && 1}
                            defaultValue={
                              allConfiguration.subscriptionTerms.term
                            }
                            handleOnchange={(e) => {
                              e.target.value &&
                                setError((prevState) => ({
                                  ...prevState,
                                  term: "",
                                }));
                              setAllConfiguration((prevState) => ({
                                ...prevState,
                                subscriptionTerms: {
                                  ...prevState.subscriptionTerms,
                                  term: parseInt(e.target.value),
                                },
                              }));
                            }}
                          />
                          <div className="text-danger">{error.term}</div>
                          {!allConfiguration.minBookingPeriod &&
                            error.minBookingPeriod && (
                              <div className="text-danger">
                                Please enter minimum booking period first
                              </div>
                            )}
                        </div>
                        {allConfiguration.subscriptionTerms.term > 1 && (
                          <div className="inputContainer">
                            <InputField
                              label="Downpayment"
                              name="downPaymentPercentage"
                              required
                              onBlur={() =>
                                setError((prevState) => ({
                                  ...prevState,
                                  downPaymentPercentage: true,
                                }))
                              }
                              onChange={(e) => {
                                setAllConfiguration((prevState) => ({
                                  ...prevState,
                                  subscriptionTerms: {
                                    ...prevState.subscriptionTerms,
                                    downPaymentPercentage: parseInt(
                                      e.target.value
                                    ),
                                  },
                                }));
                                setError((prevState) => ({
                                  ...prevState,
                                  downPaymentPercentage: false,
                                }));
                              }}
                            />
                            {!allConfiguration.subscriptionTerms
                              .downPaymentPercentage &&
                            error.downPaymentPercentage ? (
                              <div className="text-danger">
                                Please enter down payment value
                              </div>
                            ) : allConfiguration.subscriptionTerms
                                .downPaymentPercentage < 25 ? (
                              <div className="text-danger">
                                DownPayment value should be min 25 to max 99
                              </div>
                            ) : allConfiguration.subscriptionTerms
                                .downPaymentPercentage > 99 ? (
                              <div className="text-danger">
                                DownPayment value should be min 25 to max 99
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="title01 mb-4 mt-4">Unavailabilities</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {dateRanges.map((dateRange, index) => (
                        <Row style={{ marginTop: 10 }}>
                          <Col md="3">
                            <DatePicker
                              placeholderText="Select booked dates"
                              selectsRange={true}
                              startDate={
                                dateRange.fromDate
                                  ? new Date(dateRange.fromDate)
                                  : null
                              }
                              endDate={
                                dateRange.tOdate
                                  ? new Date(dateRange.tOdate)
                                  : null
                              }
                              showYearDropdown
                              isClearable={true}
                              monthsShown={2}
                              minDate={new Date()}
                              onChange={(update) => {
                                setDateRange(update, index);
                              }}
                            />
                          </Col>
                          {createListing.signIds != null && (
                            <>
                              <Col md="3">
                                <SelectionComponent
                                  option={signs}
                                  label="Sign"
                                  placeholder={"Select Sign"}
                                  handleOnchange={(option) =>
                                    handleSignChange(option, index)
                                  }
                                  defaultValue={dateRange.signId}
                                  selected={
                                    dateRange.signId != null &&
                                    dateRange.signId != ""
                                      ? createListing.signInfo.name
                                      : null
                                  }
                                />
                              </Col>
                              <Col md="3">
                                <div
                                  style={{
                                    marginTop: 15,
                                    float: "left",
                                    marginRight: 15,
                                  }}
                                >
                                  {dateRanges.length > 1 && (
                                    <span
                                      className="align-self-center cursor"
                                      onClick={() => deleteRange(index)}
                                    >
                                      <DeleteSVG />
                                    </span>
                                  )}
                                </div>
                                <div style={{ marginTop: 15 }}>
                                  {index == dateRanges.length - 1 && (
                                    <div className="addMore_container mb-4 cursor">
                                      <div onClick={addNewRange}>
                                        <span>
                                          <PlusSVG fill="#F99300" />{" "}
                                        </span>
                                        <span>Add more</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </>
                          )}
                        </Row>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-danger">
                        {error.unavailabilities}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      {listingCreatedModal && (
        <NewListingConfirmModal
          setNewListingCreatedModal={setListingCreatedModal}
          newListingCreatedModal={listingCreatedModal}
          handleConfirmListCreatedModal={() => handleConfirmListCreatedModal()}
        />
      )}
      {featuredListing && (
        <FeaturedListingModal
          setFeaturedListing={setFeaturedListing}
          featuredListing={featuredListing}
          setRegisterListingModal={setRegisterListingModal}
        />
      )}
      {/* Listing to be featured modal*/}
      {registerListingModal && (
        <OnBoardingFeatured
          setRegisterListingModal={setRegisterListingModal}
          handleRegisterDateFetaured={handleRegisterDateFetaured}
          onChangeFeaturedDate={onChangeFeaturedDate}
          featuredDate={featuredDate}
        />
      )}
    </>
  );
};

export default AllConfiguration;
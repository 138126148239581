import React, { useState } from "react";
import { Fragment } from "react";
import { FloatingLabel } from "react-bootstrap";
// import DatePicker from "react-date-picker";
import DatePicker from "react-datepicker";
import { SVGCalendar } from "../../assets/svgComponents/svg";

export const DatePickerWithFloatingLable = ({
  inputsize,
  label,
  value,
  onChange,
  minDate,
}) => {
  return (
    <div style={{ flex: 1, paddingBottom: 8 }}>
      <label style={{ fontSize: 14.0, color: "#212529" }}>{label}</label>

      <DatePicker
        // placeholderText="Select booking dates"
        selectsRange={false}
        // startDate={
        //   minDate
        // }
        // endDate={availability.toDate ? new Date(availability.toDate) : null}
        // withPortal
        showYearDropdown
        selected={value}
        // selected={value}
        isClearable={true}
        monthsShown={1}
        minDate={minDate}
        value={value}
        calendarIcon={<SVGCalendar fill="#AFAFAF" />}
        // excludeDates={minDate}
        onChange={
          onChange
          // if (update && update.length > 0 && update[0])
          //   dispatch(
          //     setAvailabilityFrom(moment(update[0]).format("DD-MMMM-YYYY"))
          //   );
          // else dispatch(setAvailabilityFrom(null));

          // if (update && update.length > 1 && update[1])
          //   dispatch(
          //     setAvailabilityTo(moment(update[1]).format("DD-MMMM-YYYY"))
          //   );
          // else dispatch(setAvailabilityTo(null));
        }
        // onSelect = {onChange}
      />
    </div>
    // <FloatingLabel
    //   className={`floatingLable col-md-5 ${inputsize}`}
    //   controlId="floatingInput"
    //   label={label}
    // >
    //   {/* <DatePicker
    //       minDate={minDate}
    //       onChange={onChange}
    //       value={value}
    //       calendarIcon={<SVGCalendar fill="#AFAFAF" />}
    //     /> */}
    // <DatePicker
    //   // placeholderText="Select booking dates"
    //   selectsRange = {false}
    //   // startDate={
    //   //   minDate
    //   // }
    //   // endDate={availability.toDate ? new Date(availability.toDate) : null}
    //   // withPortal
    //   showYearDropdown
    //   selected={value}
    //   // selected={value}
    //   isClearable={true}
    //   monthsShown={1}
    //   minDate={minDate}
    //   value = {value}
    //   calendarIcon = {<SVGCalendar fill="#AFAFAF" />}
    //   // excludeDates={minDate}
    //   onChange = {
    //     onChange
    //     // if (update && update.length > 0 && update[0])
    //     //   dispatch(
    //     //     setAvailabilityFrom(moment(update[0]).format("DD-MMMM-YYYY"))
    //     //   );
    //     // else dispatch(setAvailabilityFrom(null));

    //     // if (update && update.length > 1 && update[1])
    //     //   dispatch(
    //     //     setAvailabilityTo(moment(update[1]).format("DD-MMMM-YYYY"))
    //     //   );
    //     // else dispatch(setAvailabilityTo(null));
    //   }
    //   // onSelect = {onChange}
    // />
    // </FloatingLabel>
  );
};

import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router";

import { NotificationSVG } from "../../../../../../../../assets/svgComponents/table";
import { CustomSort } from "../../../../../../../../components/DataTable/CustomSort";
import NavBar from "../../../../../../../../components/navBar";
import ProjectContext from "../../../../../../../../context/ProjectContext";
import { getSelectSite } from "../../../../../../../../services/Provider/Configurator/Listings";
import TableActions from "../../../components/TableActions";

const columns = [
  {
    name: "id".toUpperCase(),
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "Location Name".toUpperCase(),
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "location".toUpperCase(),
    selector: (row) => row.location.googleAddress,
    sortable: true,
  },
  {
    name: "environment".toUpperCase(),
    selector: (row) => row.environment.name,
    sortable: true,
  },
];

const SelectSitesPage = ({
  activeTab,
  sideTabs,
  setActiveTab,
  setSideTabs,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [sitesdata, setSitesData] = useState();
  const [loader, setLoader] = useState(false);
  const { createListing, handleCreateListing, selectedEntities } =
    useContext(ProjectContext);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //   fetch site listing data
  useEffect(async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: "",
      export: true,
      searchString: "",
      environments: [],
      status: true,
    };
    setLoader(true);
    await getSelectSite(reqBody)
      .then((res) => {
        if (res.status == 200) {
          setLoader(false);
          // console.log(res.data.data, "res");
          setSitesData(res.data?.data?.records);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleRowClicked = (row) => {
    console.log(row);
    handleCreateListing({ selectedSites: row });
    // const updatedData = data.map((item) => {
    //   if (row.id !== item.id) {
    //     return item;
    //   }
    //   return {
    //     ...item,
    //     toggleSelected: !item.toggleSelected,
    //   };
    // });
    // setData(updatedData);
  };

  const handleWhiteBtn = () => {
    history.push("/provider/configurator/listings");
  };

  const handleSelectSitesRow = (item) => {
    if (sideTabs.length == 2) {
      handleCreateListing({ ...createListing, sitesIds: item.id });
      setActiveTab("Pricing");
    } else {
      handleCreateListing({ ...createListing, sitesIds: item.id });
      setActiveTab(sideTabs[1].name);
      // createListing.selectSiteId = item.id;
    }
  };

  const filteredItems = sitesdata?.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.id && item.id.toLowerCase().includes(filterText.toLowerCase()))
  );

  // console.log(filteredItems, "filteredItems");

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <TableActions
          title="Select Locations"
          isInput={true}
          onSearchChange={(e) => setFilterText(e.target.value)}
          // isFilterBtn={true}
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const conditionalRowStyles = [
    {
      when: (row) => row.id == location?.data.siteIds,
      style: {
        backgroundColor: "#FFF5E5",
      },
    },
  ];

  return (
    <>
      {console.log(location)}
      {/* {console.log(createListing, "listing sites")} */}
      <div className="w-100">
        <NavBar
          // handleWhitebtn={() => setConfirmModal(true)}
          whiteBtnText={"Cancel"}
          notificationSVG={<NotificationSVG />}
          navHeading={createListing.name}
          subHeading="All Billable Item "
          subActiveHeading=" New Billable Item"
          handleWhitebtn={() => handleWhiteBtn(activeTab)}
          showButton={true}
        />
        <div className="gridSection p-20">
          <div className="d-flex h-100">
            {/* {console.log(sideTabs.length == 2, "tab")} */}
            {sideTabs.length > 2 && (
              <div>
                <ul className=" progressbar">
                  {sideTabs?.map((tab) => (
                    <li
                      className={`billable_switchSideDom_item ${
                        tab.isActive && "active"
                      }
                    ${tab.isCurrentTitle && "activeTabTitle"}
                   `}
                    >
                      {tab.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="d-flex flex-column h-100 flex-grow-1">
              {subHeaderComponentMemo}
              {/* <TableActions
                title="Select Sites"
                isInput={true}
                onSearchChange={(e) => setFilterText(e.target.value)}
                // isFilterBtn={true}
              /> */}

              <div className="card customtable mt-3">
                <DataTable
                  fixedHeader
                  progressPending={loader}
                  columns={columns}
                  data={filteredItems && filteredItems}
                  sortFunction={<CustomSort />}
                  allowOverflow={true}
                  onRowClicked={handleSelectSitesRow}
                  highlightOnHover
                  pointerOnHover
                  // selectableRows
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  conditionalRowStyles={
                    location?.isUpdateListing && conditionalRowStyles
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectSitesPage;

import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Card, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import * as Yup from "yup";
import NavBar from "../../../../../../../components/navBar";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import { FloatingLableSearchBar } from "../../../../../../../components/SearchBar/FloatingLableSearchBar";
import { UploadFile } from "../../../../../../../components/UploadFile/UploadFile";
import { TextAria } from "../../../../../../../components/TextAria";
import { BtnSwitch } from "../../../../../../../components/BtnSwitch/BtnSwitch";
import { InputField } from "../../../../../../../components/InputField";
import { GoogleMap } from "@react-google-maps/api";
import { GoPinitMaps } from "../../../../../../../components/Maps/GoPinitMaps";
import { useToasts } from "react-toast-notifications";
import { DownloadSVG } from "../../../../../../../assets/svgComponents/svg";
import {
  addSite,
  getEnvironment,
  uploadFileMethod,
  getEnvironmentType,
} from "../../../../../../../services/Provider";
import { number } from "yup/lib/locale";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import ProjectContext from "../../../../../../../context/ProjectContext";
import { CreateListModal } from "../AddSitePage/CreateListModal";
import { siteDetailsUpdate } from "../../../../../../../services/Provider/Configurator/Inventory";
import { AddSitePageMap } from "../../../../../../../components/Maps/sitePage/AddSitePageMap";
import mapStyles from "./MapStyles";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { getProvider } from "../../../../../../../services/Provider";
import { UploadMultipleFiles } from "../../../../../../../components/UploadFile/UploadMultipleFiles";
import { getAllTaxation } from "../../../../../../../services/Provider/StateTaxation/StateTaxation";
import { getAddressByLatLng } from "../../../../../../../services/api/customer-store-api";
import { GetOnlyAddressFromCompoundCode } from "../../../../../../../services/utility";
import UpdatesModal from "../../../../../../../components/profile/Modal/UpdatesModal";
import { checkPermission } from "../../../../../../../utils";

export const EditSitePage = () => {
  const { handleAddSite } = useContext(ProjectContext);
  const { selectedSign, handleActiveTab } = useContext(ProjectContext);
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  const [curUser, setCurUser] = useState();

  const { selectedSite, handleSelectedSelSite } = useContext(ProjectContext);
  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const [getEnviroment, setGetEnviroment] = useState([]);
  const [uploadImage, setUploadImage] = useState([]);
  const [uploadCollatralImage, setUploadCollatralImage] = useState();
  const [environmentId, setEnvironmentId] = useState(
    selectedSite.environmentId
  );
  const [description, setDescription] = useState(
    selectedSite && selectedSite.description
  );
  const [siteConfirmModal, setSiteConfirmModal] = useState(false);
  const [availability, setAvailability] = useState([]);
  const [priceTiype, setPriceTiype] = useState([]);
  const [durability, setDurability] = useState([]);
  const [collateral, setCollateral] = useState("");
  const [selectedCollateral, setSelectedCollateral] = useState([]);
  const [upCollateral, setUpCollateral] = useState(true);
  const [imageValidate, setImageValidate] = useState();
  const [collateralValidate, setCollateralValidate] = useState();
  const [locationValidate, setLocationValidate] = useState();
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);

  const [searchBox, setSearchBox] = useState(null);
  //const [lat, setLat] = useState(43.653225);
  //const [lng, setLng] = useState(-79.383186);
  const [lat, setLat] = useState(selectedSite.location.latitude);
  const [lng, setLng] = useState(selectedSite.location.longitude);

  const [center, setCenter] = useState({ lat: lat, lng: lng });
  const [loc, setLoc] = useState(selectedSite.location.googleAddress);
  const [taxation, setTaxation] = useState([]);
  const [taxationId, setTaxationId] = useState(selectedSite.stateName);

  const handleOnCancel = () => {
    //history.goBack("/provider");
    handleActiveTab("SITE");
    history.push("/provider/configurator/inventory");
  };
  const handleSiteConfirmModal = () => {
    setSiteConfirmModal(false);
    handleAddSite(true);
    history.goBack("/provider");
  };
  useEffect(() => {
    getProvider().then((res) => {
      if (res.status == 200) {
        setCurUser(res.data);
        //console.log(res.data, 'currentUser');
      }
    });
    getEnvironment().then((res) => {
      if (res.status == 200) {
        setGetEnviroment(res.data);
        //setEnvironmentId(res.data[0].id);
      }
      // console.log("resEnvironMn", res);
    });
    setCenter({ lat: lat, lng: lng });
  }, []);

  useEffect(() => {
    getEnvironmentType("PriceTypes")
      .then((res) => {
        if (res.status) {
          setPriceTiype(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    getEnvironmentType("PeriodTypes")
      .then((res) => {
        if (res.status === 200) {
          setDurability(res.data);
        }
        // console.log("PeriodTypes", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
    getEnvironmentType("Availabilities")
      .then((res) => {
        if (res.status === 200) {
          setAvailability(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    getAllTaxation()
      .then((res) => {
        if (res.status === 200) {
          setTaxation(res.data);
          console.log("taxation data", taxation);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const handlEnvironment = (e) => {
    let id = e.target.value;
    setEnvironmentId(id);
  };

  const handleTaxation = (e) => {
    let id = e.target.value;
    setTaxationId(id);
  };

  const handleDescription = (e) => {
    let des = e.target.value;
    console.log(des, "des");
    setDescription(des);
  };

  const handelUploadImage = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const data = e.target.files[i];
      let image = new FormData();
      image.append("file", data);
      setImageValidate("");
      uploadFileMethod(image)
        .then((res) => {
          if (res.status == 200) {
            setUploadImage((UploadImage) => [...UploadImage, res.data]);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const handelUploadCollaralImage = (e) => {
    let data = e.target.files[0];
    let image = new FormData();
    image.append("file", data);

    uploadFileMethod(image)
      .then((res) => {
        if (res.status == 200) {
          setUploadCollatralImage(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const uploadMultipleProof = (e) => {
    setSelectedCollateral([...selectedCollateral, ...e.target.files]);
  };

  useEffect(() => {
    console.log("useEffect2");

    if (selectedCollateral.length > 0) {
      try {
        let formData = new FormData();
        //formData.append("file", selectedCollateral[0]);
        formData.append("file", selectedCollateral);
        uploadFileMethod(formData).then((res) => {
          if (res.status == 200) {
            setUpCollateral(false);
            setCollateral(...collateral, res.data);

            /*
            setUpProof(false)
            setProof(res.data);            
            setOrganizationDetails((organizationDetails) => ({
               ...organizationDetails,
               proof: res.data.fileKey,
            }));
            */
          }
        });
      } catch (error) {}
    }
  }, [selectedCollateral]);

  console.log("selectedSite", selectedSite);
  const formik = useFormik({
    initialValues: {
      name: selectedSite.name,
      emailId: selectedSite.emailId,
      stateName: selectedSite.stateName,
      siteLocation: {
        googleAddress: selectedSite.location.googleAddress,
        latitude: selectedSite.location.latitude,
        longitude: selectedSite.location.longitude,
        //radiusOfOperation: selectedSite.location.radiusOfOperation
      },
      //siteLocation: selectedSite.location.googleAddress,
      radious: selectedSite.location.radiusOfOperation,
      // environmentId:selectedSite.environment.name,
      // des: selectedSite.description,
    },
    validationSchema: Yup.object({
      name: Yup.string().min(5, "Too short!").required(),
      //emailId: Yup.string().email().required(),
      radious: Yup.string().label("Radious").required(),
    }),
    onSubmit: (values) => {
      let error = false;
      /*
      setImageValidate("");
      setCollateralValidate("");
      if(!uploadImage || !uploadImage.fileKey){          
          setImageValidate("Please upload Image.");
          error = true;
      }
      if(!uploadCollatralImage || !uploadCollatralImage.fileKey){
          setCollateralValidate("Please upload collateral.");
          error = true;
      }
      */

      if (values.siteLocation.googleAddress === "") {
        setLocationValidate("Please select location.");
        error = true;
      } else if (
        values.siteLocation.latitude === "" ||
        values.siteLocation.longitude === ""
      ) {
        setLocationValidate("Please select location on map.");
        error = true;
      }

      if (!error) {
        //console.log(values, "values");
        //id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        let formData = {
          id: selectedSite.id,
          name: values.name.toString().trim(),
          description: description.toString().trim(),
          emailId: "",
          stateName: taxationId,
          siteLocation: {
            googleAddress: values.siteLocation.googleAddress,
            latitude: values.siteLocation.latitude,
            longitude: values.siteLocation.longitude,
            radiusOfOperation: parseInt(values.radious, 10),
          },
          environmentId: environmentId,
          providerId: curUser.id,
        };
        /*
      image: [
        {
          id: "",
          image: uploadImage.fileKey,
        },
      ],
      collateralImage: [collateral],
      */
        if (uploadImage) {
          formData = {
            ...formData,
            image:
              uploadImage.length > 0
                ? uploadImage.map((val) => {
                    return {
                      id: "",
                      image: val.fileKey,
                    };
                  })
                : [],
          };
        } else {
          //formData = { ...formData, image: { id: selectedSite.images[0].id, image: selectedSite.images[0].image }};
        }

        if (uploadCollatralImage) {
          formData = {
            ...formData,
            collateralImage: [
              {
                collateral: uploadCollatralImage.fileKey,
                collateralInfo: uploadCollatralImage.fileName,
              },
            ],
          };
        } else {
          //formData = { ...formData, collateralImage: [{collateral:selectedSite.collaterals[0].collateral,collateralInfo:"xxx.jpg"}]};
        }
        siteDetailsUpdate(formData)
          .then((res) => {
            console.log("resaddsite", res.status);
            if (res.status == 204) {
              handleActiveTab("SITE");
              addToast("Site updated successfully.", {
                appearance: "success",
              });
            }
          })
          .catch((err) => {
            console.log("erraddsite", err);
            handleActiveTab("SITE");
            addToast("Error while updating site.", {
              appearance: "error",
            });
          });
        history.push("/provider/configurator/inventory");
        console.log("formdata", formData);
      }
    },
  });
  /*
  {
    id:"" ,
    collateral: uploadCollatralImage.fileKey
  }
  */

  // const mediaName = formik.touched.media && formik.errors.media;
  const handleToggle = (e) => {
    setShowCreateListModal(e.target.checked);
  };

  const [listingDetails, setlistingDetails] = useState({
    availableFor: "",
    pricingType: "",
    applicableDuring: "",
    price: "",
  });

  const getMyLocation = () => {
    /*
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition((position) => {
        //this.props.change("latitude", position.coords.longitude);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
    */
  };

  const onSBLoad = (searchData) => {
    console.log("searchBox: ", searchData);
    //searchBox = searchData;
    setSearchBox(searchData);
  };

  const onPlacesChanged = () => {
    const markerArray = [];
    let results = searchBox.getPlaces();
    for (let i = 0; i < results.length; i++) {
      let place = results[i].geometry.location;
      markerArray.push(place);
    }

    markerArray.map((mark, index) => setCenter(mark));
    //getMyLocation();

    //this.setState({ markers: markerArray });
    //console.log(markerArray.map(x => x.position.lat()),"lat");
    //console.log(markerArray.map(x => x.position.long()),"long");
  };

  const onMarkerDragEnd = async (coord) => {
    const { latLng } = coord;
    let lat = latLng.lat();
    let lng = latLng.lng();
    setLat(lat);
    setLng(lng);
    setCenter({ lat: lat, lng: lng });
    formik.setFieldValue("siteLocation.latitude", lat);
    formik.setFieldValue("siteLocation.longitude", lng);
    if (lat && lng) {
      const response = await getAddressByLatLng(lat, lng);
      if (response && response.data) {
        const { compound_code } = response.data.plus_code;
        let newAddress = GetOnlyAddressFromCompoundCode(compound_code);
        setLoc(newAddress);
        formik.setFieldValue("siteLocation.googleAddress", newAddress);
      }
    }

    //handleChange('siteLocation.latitude', lat);
    //handleChange('siteLocation.longitude', lng);
    /*
    this.setState(prevState => {
      const markers = [...this.state.markers];
      markers[index] = { ...markers[index], position: { lat, lng } };
      return { markers };
    });
    */

    //getMyLocation();
  };

  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <NavBar
          blueBtnText="Save"
          whiteBtnText="Cancel"
          notificationSVG={<NotificationSVG />}
          navHeading="Edit Location"
          subHeading="Configurator • All Locations "
          subActiveHeading="Edit Location"
          type="submit"
          handleWhitebtn={handleOnCancel}
          handleNotificationSVG={handleNotificationSVG}
          showButton={true}
        />
        <div className="p-20">
          <Card className="gridCard" aria-labelledby="gridCard">
            <Card.Body className="gridCardBody p-0">
              <div className="formSection">
                <p className="mt-4 mx-4">Location Details</p>
                <div className="mx-4">
                  <div className="row my-4 fornSection2">
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="name"
                        label="Name"
                        inputFieldSize="sizeOfInputField"
                      />
                      {formik.touched.name && formik.errors.name && (
                        <span className="text-danger">
                          {formik.errors.name}
                        </span>
                      )}
                    </div>
                    {/* <div className="col-md-6">
                      <InputField
                        type="text"
                        name="emailId"
                        value={formik.values.emailId}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="emailId"
                        label="Email Address"
                        inputFieldSize="sizeOfInputField"
                      />
                      {formik.touched.emailId && formik.errors.emailId && (
                        <span className="text-danger">
                          {formik.errors.emailId}
                        </span>
                      )}
                    </div> */}
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        name="radious"
                        value={formik.values.radious}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="Radious Of Opration"
                        label="Radius Of Operation"
                        inputFieldSize="sizeOfInputField"
                      />
                      {formik.touched.radious && formik.errors.radious && (
                        <span className="text-danger">
                          {formik.errors.radious}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row my-4 fornSection2">
                    <div className="col-md-6">
                      <PlacesAutocomplete
                        value={loc}
                        onChange={(p) => {
                          setLoc(p);
                          setLocationValidate("");
                        }}
                        onSelect={(p) => {
                          setLoc(p);
                          setLocationValidate("");
                          formik.setFieldValue("siteLocation.googleAddress", p);
                          geocodeByAddress(p)
                            .then((results) => getLatLng(results[0]))
                            .then(({ lat, lng }) => {
                              setLat(lat);
                              setLng(lng);
                              setCenter({ lat: lat, lng: lng });
                              formik.setFieldValue(
                                "siteLocation.latitude",
                                lat
                              );
                              formik.setFieldValue(
                                "siteLocation.longitude",
                                lng
                              );
                              console.log(
                                "Successfully got latitude and longitude",
                                { lat, lng }
                              );
                            });
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Location",
                                className:
                                  "inputField form-control location-search-input",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {/* {loading && <div>Loading...</div>} */}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#FAFAFA",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#FFFFFF",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {locationValidate && (
                        <span className="text-danger">{locationValidate}</span>
                      )}
                    </div>
                    
                  </div>
                  <div className=" mapBox">
                    <AddSitePageMap
                      center={center}
                      lat={lat}
                      lng={lng}
                      getMyLocation={() => getMyLocation()}
                      handleChange={formik.handleChange}
                      loc={loc}
                      onPlacesChanged={() => onPlacesChanged()}
                      onMarkerDragEnd={(coord) => onMarkerDragEnd(coord)}
                      readonly={false}
                    />
                  </div>
                  <div className="row my-4 fornSection2">
                    <div className="col-md-6">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Type of Location / Environment"
                      >
                        <Form.Select
                          // className="selecContainer"
                          placeholder="Type of Location / Environment"
                          onChange={(e) => handlEnvironment(e)}
                          value={environmentId}
                        >
                          {getEnviroment &&
                            getEnviroment.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </FloatingLabel>
                    </div>
                    <div className="col-md-6">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="State Taxation"
                      >
                        <Form.Select
                          // className="selecContainer"
                          placeholder="Select Taxation"
                          onChange={(e) => handleTaxation(e)}
                          value={taxationId}
                        >
                          {taxation &&
                            taxation?.map((item) => {
                              return (
                                <option value={item.stateName} key={item.id}>
                                  {item.stateName}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row fornSection2 my-4">
                    <div className="col-md-6">
                      <UploadMultipleFiles
                        id="image"
                        uploadBoxSize="sizeOfInputField"
                        btnlable="Upload Image"
                        uploadMultipleProof={handelUploadImage}
                        placeholder={"No File Attach"}
                        uploadedData={uploadImage}
                        allowedFiles="image/png, image/gif, image/jpeg"
                      />
                      {/* Images:
                      {selectedSite.images.map((img) => {
                        return(
                        <div className="">
                          <a
                            download="image.jpg"
                            href={`https://gopinit.blob.core.windows.net/image-container/${img.image}`}
                            target="_blank"
                          >
                            <img
                              src={`https://gopinit.blob.core.windows.net/image-container/${img.image}`}
                              alt="logo"
                            />
                          </a>
                        </div>
                        );
                      })} */}
                    </div>
                    <div className="col-md-6">
                      {/*   <UploadMultipleFiles
                        id="collateral"
                        uploadBoxSize="uploadBoxSize"
                        btnlable="Upload Collateral"
                        uploadMultipleProof={uploadMultipleProof}
                        placeholder={collateral ? collateral.fileName : "No File Attach"}
                      /> */}
                      <UploadFile
                        uploadBoxSize="sizeOfInputField"
                        btnlable="Collateral"
                        id="collateral"
                        handelUploadLogo={handelUploadCollaralImage}
                        placeholder={
                          uploadCollatralImage
                            ? uploadCollatralImage.fileName
                            : "No File Attach"
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="image_Section">
                      <p>Images</p>
                      <div className="imageCardContainer">
                        {selectedSite.images &&
                          selectedSite.images.map((item) => {
                            return (
                              <>
                                <div
                                  className="imageCard"
                                  style={{ height: "50px" }}
                                >
                                  <a
                                    download="image.jpg"
                                    href={`https://gopinit.blob.core.windows.net/image-container/${item.image}`}
                                    target="_blank"
                                  >
                                    <img
                                      src={`https://gopinit.blob.core.windows.net/image-container/${item.image}`}
                                      alt="img"
                                    />
                                  </a>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="collateralContainer">
                      <p>Collateral</p>
                      {selectedSite.collaterals &&
                        selectedSite.collaterals.map((item) => {
                          return (
                            <>
                              <span>
                                <a
                                  className="btn btn-light"
                                  download={item.collateralInfo}
                                  href={`https://gopinit.blob.core.windows.net/image-container/${item.collateral}`}
                                  target="_blank"
                                >
                                  <p>
                                    {/* <Collateral fill="#58B85C" /> */}
                                    <DownloadSVG fill="#58B85C" />
                                  </p>
                                </a>
                              </span>
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className="row fornSection2 my-4">
                    <div className="col-md-12 form-floating">
                      <textarea
                        type="text"
                        name="des"
                        value={description}
                        onChange={(e) => handleDescription(e)}
                        placeholder="Description"
                        label="Description"
                      />
                    </div>
                  </div>
                  {/* <div className="w-75 my-5">
                    <BtnSwitch
                      sizeLg="xl"
                      text="Add this site to listing ?"
                      action={handleToggle}
                    />
                  </div> */}
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Form>
      <CreateListModal
        show={showCreateListModal}
        setShow={setShowCreateListModal}
        availability={availability}
        durability={durability}
        priceTiype={priceTiype}
        listingDetails={listingDetails}
        setlistingDetails={setlistingDetails}
      />

      {siteConfirmModal && (
        <ConfirmModal
          title="Detail 
         Sent"
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Ok"
          cancelText="No"
          handleClose={() => setSiteConfirmModal(false)}
          handleConfirm={handleSiteConfirmModal}
          width="400px"
        >
          <div className="pt-2 pb-4">
            <p className="newListingCreated_description ms-4">
              The location is updated successfully
            </p>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

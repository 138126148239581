import { configureStore } from "@reduxjs/toolkit";
import ordersReducers from "./services/reducers/orders-reducers"
import transactionReducers from "./services/reducers/transaction-reducers";
import filterReducers from "./services/reducers/filter-reducers";
import notificationsReducers from "./services/reducers/notifications-reducers";
import customerReducer from "./services/reducers/customer-reducer";
import listingReducer from "./services/reducers/listing-reducer";
import userReducer from "./services/reducers/user-reducer";

export const store = configureStore({
    reducer: {
      orders : ordersReducers, 
      transactions : transactionReducers,
      filters : filterReducers,
      notifications : notificationsReducers,
      customer : customerReducer,
      listing : listingReducer,
      user:userReducer
    }
  })
import React from "react";
import { Col, Row } from "react-bootstrap";
import { TagSVG } from "../../../../../../../assets/svgComponents/svg";
  import { PlusSVG } from "../../../../../../../assets/svgComponents/table";

const AvailableOffers = ({availableOffers}) => {
    return ( 
        <div className="availble_offer">
            <div className="availble_offer_heading">
            Available Offers & Deals{" "}
            </div>
            {
            availableOffers && availableOffers.map((offer,index)=>(
                 
                <Row className="g-0" key={'available_offer_' + index}>
                    <Col md={1}>
                        <span>
                        <TagSVG />
                        </span>
                    </Col>
                    <Col>
                        <div className="siteDetails_label_heading">{offer.name}</div>
                        <p className="siteDetails_desc03">
                        {offer.details} {" "}
                        </p>
                    </Col>
                </Row> 
                
            ))
            }
       
            
            {/* <div className="add_more_btn">
            <PlusSVG height="12px" width="12px" fill="#F99300" /> &nbsp;3 more
            </div> */}
      </div>
    );
}
export default AvailableOffers;
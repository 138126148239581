import React from "react"

function ConfirmModal({
  setConfirmModal,
  confirm,
  title,
  body,
  header,
  yesButton,
  cancelButton,
  settingsDevicePolicy,
}) {
  return (
    <div className="confirmModal">
      <div
        className="confirmModalOver"
        onClick={() => setConfirmModal(false)}
      ></div>
      <div className="confirmModalInner">
        <div className="confirmModalTop p-4">
          <h3 className="font-weight-light">{title}</h3>
        </div>
        <div className="px-4 font-weight-light   pt-0 pb-5">
          {settingsDevicePolicy && (
            <div className="font-weight-normal mb-2">{header}</div>
          )}
          {body}
        </div>
        <div className="confirmModalBottom border-top">
          <div className="row no-gutters">
            <div className="col-md-6" onClick={() => setConfirmModal(false)}>
              <button className="btn btn-outline-dark btn-block border-0 p-2">
                <span className="text-md">{cancelButton}</span>
              </button>
            </div>
            <div className="col-md-6">
              <button
                type="button"
                className="btn btn-success btn-block border-0 p-2"
                onClick={confirm}
              >
                <span className="text-md">{yesButton}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;

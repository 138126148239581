import React from "react";

const Checkbox = ({
  status,
  text,
  action,
  disabled,
  value,
  titleClassName,
  isChecked,
  checked,
  defaultChecked,
  ...rest
}) => {
  return (
    <>
      <label className="checkBox">
        <input
          disabled={disabled}
          type="checkBox"
          defaultChecked={defaultChecked}
          onChange={action}
          {...rest}
          isChecked={isChecked}
          checked={checked}
        />
        <span
          className={`${isChecked ? "checkBoxChecked" : "checkBoxCheck"}`}
        ></span>
        {text && (
          <span
            className={`checkBoxText ms-4 px-2 checkbox_title ${titleClassName}`}
          >
            {text}
          </span>
        )}
      </label>
    </>
  );
};

export default Checkbox;

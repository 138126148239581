import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { NotificationSVG } from "../../../../../../assets/svgComponents/table";
import NavBar from "../../../../../../components/navBar";
import Review from "../SiteDetails/components/Review";
import "../SiteDetails/SiteDetails.scss";
import {
  HeartSVG,
  FullStarSVG,
  Eye,
  VectorSVG,
  ProfileSVG,
  GlobSVG,
  VideoPlayerSVG,
  SVGFavorite,
  SVGNotFavorite,
} from "../../../../../../assets/svgComponents/svg";
import SimilarListing from "../SiteDetails/components/SimilarListing";
import r1 from "../../../../../../assets/images/r1.png";
import { InputDatePicker } from "../../../../../../components/InputDatePicker/InputDatePicker";
import Availablity from "../SiteDetails/components/Availablity";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import PriceTiers from "../SiteDetails/components/PriceTiers";
import AvailableOffers from "../SiteDetails/components/AvailableOffers";
import {
  emptyMarkFavouriteResult,
  markAsUnFavoriteAsync,
  markAsFavoriteAsync,
  bookSign,
  setAvailabilityFrom,
  setAvailabilityTo,
  getSelectedFilters,
  setSearchTerm,
  getListBySitesAsync,
  GetListingAvailabilityAsync,
  getListingItemImage,
  getMinBookingPeriodInDays,
  isAvailableForBooking,
  getCanBook,
  applyPortableSignDetails,
  getListingItemImages,
} from "../../../../../../services/reducers/listing-reducer";
import { FloatingLabel } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { SVGCalendar } from "../../../../../../assets/svgComponents/svg";
import { useEffect } from "react";
import moment from "moment";
import { BookPortableSignModal } from "../../BookPortableSignModal";
import { useToasts } from "react-toast-notifications";
import { capitalize } from "../../../../../../services/utility";
import SimpleImageSlider from "react-simple-image-slider";

const SignDetails = () => {
  const { addToast } = useToasts();
  const [bookedDates, setBookedDates] = useState([]);
  const [selectedSign, setSelectedSign] = useState(null);
  const userType = localStorage.getItem("userType");
  const {
    portableSignInfo,
    listingItem,
    listingReviews,
    similarListing,
    availableOffers,
    availability,
    listingPriceType,
    portableSignsListing,
  } = useSelector((state) => state.listing);
  const { canBook, bookingDays, minimumBookingDays } = useSelector((state) =>
    getCanBook(state.listing)
  );
  const { imageUrl } = useSelector((state) =>
    getListingItemImages(state.listing)
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const handleBook = () => {
    //dispatch(bookSign(listingItem.id));
    history.push(`/sites-booking/${listingItem.id}`);
  };
  const {
    media,
    environment,
    onlyFeatured,
    minCost,
    maxCost,
    fromDate,
    uptoDate,
    searchTerm,
  } = useSelector((state) => getSelectedFilters(state.listing));
  const isAvailable = useSelector((state) =>
    isAvailableForBooking(state.listing)
  );
  const minBookingPeriod = useSelector((state) =>
    getMinBookingPeriodInDays(state.listing)
  );
  const [showBookPortableSign, setShowBookPortableSign] = useState(false);

  const { markFavouriteResult } = useSelector((state) => state.listing);

  useEffect(async () => {
    if (markFavouriteResult) {
      if (markFavouriteResult.status === 1) {
        let msg = `Sign ${markFavouriteResult.title} marked as ${markFavouriteResult.action} !`;
        addToast(msg, { appearance: "success" });
      } else {
        let msg = `Unable to marked as ${markFavouriteResult.action} !`;
        addToast(msg, { appearance: "error" });
      }
      await dispatch(emptyMarkFavouriteResult());
    }
  }, [markFavouriteResult]);

  const handleSearchTextChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
  };

  const handleSearchClick = (e) => {
    const reqBody = {
      page: 0,
      pageSize: 10,
      orderBy: "",
      export: true,
      searchString: searchTerm,
      userLocation: "",
      costs: [],
      mediaTypes: media,
      environmentIds: environment,
      dates: [],
      onlyFeatured: onlyFeatured,
      providerId: "",
      listingType: 0,
    };
    if (minCost != null && maxCost) {
      reqBody.costRange = { min: minCost, max: maxCost };
    }
    if (fromDate && uptoDate) {
      reqBody.dateRange = { fromDate, uptoDate };
    }
    dispatch(getListBySitesAsync(reqBody));
    history.push("/customer");
  };

  useEffect(async () => {
    if (availability && availability.fromDate && availability.toDate) {
      const reqBody = {
        listingId: listingItem.listingId,
        fromDate: availability.fromDate,
        toDate: availability.toDate,
      };
      dispatch(GetListingAvailabilityAsync(reqBody));
    }
  }, [dispatch, availability]);

  useEffect(() => {
    if (
      listingItem &&
      listingItem.bookedDates &&
      listingItem.bookedDates.length > 0
    ) {
      let dtArr = [];
      listingItem.bookedDates.forEach((element) => {
        dtArr.push(new Date(element));
      });
      setBookedDates(dtArr);
    } else {
      setBookedDates([]);
    }
  }, [listingItem]);

  useEffect(() => {
    if (
      minimumBookingDays > 0 &&
      portableSignInfo &&
      listingItem &&
      listingItem.isPortableSign &&
      listingItem.signs &&
      listingItem.signs.length > 0
    ) {
      listingItem.signs.forEach((sign) => {
        const payload = { portableDetails: portableSignInfo, sign: sign };
        dispatch(applyPortableSignDetails(payload));
      });
    }
  }, [minimumBookingDays]);

  useEffect(() => {
    if (bookingDays > 0 && bookingDays < minimumBookingDays) {
      addToast(`Booking period should be more than or equal to ${minimumBookingDays} days`, {
        appearance: "error",
      });
    }
  }, [bookingDays]);

  const markAsFavorite = () => {
    const reqBody = {
      siteId: null,
      signId: null,
      listingItemId: listingItem.listingItemId,
      providerId: null,
      type: "detailpage",
      title: listingItem.name,
    };
    if (listingItem.isFavorite) {
      dispatch(markAsUnFavoriteAsync(reqBody));
    } else {
      dispatch(markAsFavoriteAsync(reqBody));
    }
  };

  return (
    <>
      {listingItem && (
        <div>
          <NavBar
            navHeading="Home"
            subHeading="Home • Signs"
            subActiveHeading={listingItem.name}
            isInputField={false}
            notificationSVG={<NotificationSVG />}
            handleSearchTextChange={handleSearchTextChange}
            handleSearchClick={handleSearchClick}
            searchTerm={searchTerm}
            showButton={true}
          />
          <div className="p-20 bg-base-100">
            <div className="d-flex topsec-listing">
              <div className="siteDetails_img_container">
                {/* <img className="siteDetails_img" src={imageUrl} />  */}
                {imageUrl && (
                  <SimpleImageSlider
                    width={454}
                    height={370}
                    images={imageUrl}
                    showBullets={true}
                    showNavs={true}
                  />
                )}
              </div>
              <div className="flex-grow-1">
                <Row className="">
                  <Col
                    md={availableOffers && availableOffers.length > 0 ? 7 : 12}
                    className="toplisting"
                  >
                    <div className="px-4">
                      <div className="section">
                        <div className="d-flex justify-content-between">
                          <h4>{listingItem.listingName}</h4>
                          <span onClick={() => markAsFavorite()}>
                            {listingItem.isFavorite ? (
                              <SVGFavorite />
                            ) : (
                              <SVGNotFavorite />
                            )}
                          </span>
                        </div>
                        {listingItem.isPortableSign ? (
                          <div className="siteDetails_desc mb-2">
                            (Portable)
                          </div>
                        ) : (
                          <div className="siteDetails_desc mb-2">
                            {listingItem.googleLocation}
                          </div>
                        )}

                        <div className="siteDetails_desc">
                          <span className="pe-1">
                            <FullStarSVG />
                          </span>
                          <b>{listingItem.rating}</b> ({listingItem.reviews}{" "}
                          reviews)
                        </div>
                      </div>
                      {/* <hr /> */}
                      <div className="section">
                        <p className="siteDetails_desc02">
                          {listingItem.description}
                        </p>
                        <div className="d-flex justify-content-between mb-2">
                          <div className="siteDetails_label_heading">
                            <span className="pe-2">
                              <ProfileSVG />
                            </span>
                            <span className="pe-2">{listingItem.provider}</span>
                          </div>
                          {listingItem.environment &&
                            listingItem.environment != "" && (
                              <div className="siteDetails_label_heading">
                                <span className="pe-2">
                                  <GlobSVG />
                                </span>
                                <span className="pe-2">
                                  {listingItem.environment}
                                </span>
                              </div>
                            )}

                          <div className="siteDetails_label_heading">
                            <span className="pe-2">
                              <VideoPlayerSVG />
                            </span>
                            <span className="pe-2">
                              {listingItem.signTitle}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="light_heading">Starting</div>
                          <div className="booking_peroid">
                            <b>${listingItem.minPrice}</b>
                            <span className="px-1">/</span>{listingItem.minBookingPeriod}{" "}{listingItem.periodTypeText}
                          </div>
                        </div>
                        {/* <div className="booknow_btn_container">
                    {
                      (userType ==="Temp"?<button className='btn btn-primary btn_sunrise btn_purple'  disabled={true}> Book Now</button>:(
                          <button className={ listingItem.siteAvailable ? 'booknow_btn ' : 'btn_sunrise btn_purple ' } disabled={!listingItem.siteAvailable} onClick={handleBook}> Book Now</button>
                        )
                      )
                    }
                  </div> */}
                      </div>
                    </div>
                  </Col>
                  {availableOffers && availableOffers.length > 0 && (
                    <Col md={5}>
                      <AvailableOffers
                        availableOffers={availableOffers}
                        key="available_offers"
                      />
                    </Col>
                  )}
                </Row>
              </div>
            </div>
            {listingPriceType &&
              listingPriceType === "Differential" &&
              listingItem.priceTiers && (
                <div className="price-tiers">
                  <div className="review_heading">Price Tiers</div>
                  <PriceTiers
                    priceTiers={listingItem.priceTiers}
                    key="priceTiers"
                  />
                </div>
              )}

            <div className="py-5 availabilty">
              <div className="review_heading">Availability</div>
              <Row>
                <Col md={4}>
                  <div className="form-group">
                    <label className="dt_rang_lbl">
                      <DatePicker
                        placeholderText="Select booking dates"
                        selectsRange
                        startDate={
                          availability.fromDate
                            ? new Date(availability.fromDate)
                            : null
                        }
                        endDate={
                          availability.toDate
                            ? new Date(availability.toDate)
                            : null
                        }
                        // withPortal
                        showYearDropdown
                        isClearable={true}
                        monthsShown={2}
                        minDate={new Date()}
                        excludeDates={(portableSignsListing || listingItem.isPortableSign?null:bookedDates)}
                        onChange={(update) => {
                          if (update && update.length > 0 && update[0])
                            dispatch(
                              setAvailabilityFrom(
                                moment(update[0]).format("DD-MMMM-YYYY")
                              )
                            );
                          else dispatch(setAvailabilityFrom(null));

                          if (update && update.length > 1 && update[1])
                            dispatch(
                              setAvailabilityTo(
                                moment(update[1]).format("DD-MMMM-YYYY")
                              )
                            );
                          else dispatch(setAvailabilityTo(null));
                        }}
                      />
                      <SVGCalendar
                        fill="#AFAFAF"
                        width="20"
                        height="20"
                        className="dt_icon"
                      />
                    </label>
                  </div>
                </Col>
                <Col>Booking Period : {bookingDays} days</Col>
                <Col>Minimum Booking Period : {minBookingPeriod} days</Col>
                {(portableSignsListing || listingItem.isPortableSign) && (
                  <>
                    {/* <Col>
              <button style={{fontStyle:'italic', fontSize:'10', }} onClick={() => setShowBookPortableSign(true)}>
                Update Portable Sign Details
              </button>
              </Col> */}
                    <BookPortableSignModal
                      setShow={setShowBookPortableSign}
                      show={showBookPortableSign}
                      footerText="Submit"
                      title="Book Portable Sign"
                      handlefooterBtn={() => {
                        setShowBookPortableSign(false);
                      }}
                      key="RefundOrderModal"
                      forSign={selectedSign}
                    />
                  </>
                )}
              </Row>
              <Availablity
                handleBook={handleBook}
                canBook={canBook}
                item={listingItem}
                signs={listingItem.signs}
                services={listingItem.services}
                summaryOfCharges={listingItem.summaryChargesItems}
                handleShowPortableSignDetails={(sign) => {
                  setSelectedSign(sign);
                  setShowBookPortableSign(true);
                }}
              />
            </div>
            {similarListing &&
              similarListing.records &&
              similarListing.records.length > 0 && (
                <div className="similar-section">
                  <div className="review_heading py-3">Similar Signs</div>
                  <SimilarListing
                    currentListingId={listingItem.id}
                    similarListing={similarListing}
                    key="similar_listings"
                  />
                </div>
              )}

            {listingReviews && (
              <div>
                <div className="d-flex  my-3">
                  <div className="review_heading">Review</div>
                  <span className="review_badges badges ms-2">
                    View all {listingItem.reviews} Reviews
                  </span>
                </div>
                <Row className="">
                  {listingReviews.length > 0 &&
                    listingReviews.map((review, index) => (
                      <Col md={6} key={index + "_review_col"}>
                        <Review review={review} />
                      </Col>
                    ))}
                </Row>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SignDetails;

import React from 'react'
import { Switch, Route } from "react-router-dom";
import { AddOffer } from './AddOffer';
import { EditOfferPage } from './EditOfferPage';
import { OfferDetailsPage } from './OfferDetailsPage';
import { OfferManagement } from './OfferManagement';


export const OfferManagementRoutes = ({...rest}) => {
  return (
    <Switch>
      <Route
        path={`${rest.match.url}/`}
        exact
        render={(props) => <OfferManagement {...props} />}
      />
      <Route
        path={`${rest.match.url}/add-offer`}
        render={(props) => <AddOffer {...props} />}
      />
      <Route
        path={`${rest.match.url}/view-offer`}
        render={(props) => <OfferDetailsPage {...props} />}
      />
      <Route
        path={`${rest.match.url}/edit-offer`}
        render={(props) => <EditOfferPage {...props} />}
      />
      </Switch>
  )
}

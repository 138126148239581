/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Form, Button, Col, FloatingLabel, Row } from "react-bootstrap";
import { InputField } from "../InputField";

import { SelectionComponent } from "../SelectionComponent/SelectionComponent";
import { NotificationSVG, PlusSVG } from "../../assets/svgComponents/table";
import ConfirmModal from "../confirmModal/ConfirmModal";
import NavBar from "../navBar";

import { useHistory, useLocation } from "react-router";
import { createTicket } from "../../services/Admin/SupportRequest/SupportRequest";
import { useToasts } from "react-toast-notifications";

export const Create = ({}) => {
  const { addToast } = useToasts();
  const [editUserModal, setEditUserModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [accessTypes, setAccessType] = useState([]);
  const history = useHistory();
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  // const inputsHandler = (e) =>{
  //   console.log({[e.target.name]: e.target.value});
  //       setInputField( {[e.target.name]: e.target.value} )
  //   }

  /*

  const handleBlueBtn = () => {
    setEditUserModal(true);
  };

  */

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    const inputField = {
      subject: subject,
      content: content,
    };
    console.log(inputField, "support req");
    await createTicket(inputField)
      .then((res) => {
        setLoader(false);
        setSubject("");
        setContent("");
        addToast("Your support request is successfully submitted.", {
          appearance: "success",
        });
      })
      .catch(() => {
        setLoader(false);
        addToast("There is an error while creating support request.", {
          appearance: "warning",
        });
      });

    //setEditUserModal(true);
  };

  return (
    <>
      <div className="p-20">
        <form onSubmit={(e) => handleSubmit(e)} className="formSection">
          <Row className="pb-3">
            <Col md={6}>
              <InputField
                type="text"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Subject"
                label="Subject"
                inputFieldSize="sizeOfInputField"
              />
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={6} className="form-floating">
              {/* <FloatingLabel controlId="floatingTextarea2" label="Description" > */}
              <textarea
                placeholder="Description"
                style={{ height: "20px !important" }}
                name="content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="textContainer"
                //onChange={onChange}
              />
              {/* </FloatingLabel> */}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {(subject && content && !loader)?(
                <button className="btn btn-outline-primary" type="submit">
                Submit
              </button>
              ):(
                <button className="btn btn-outline-primary" type="submit" disabled>
                Submit
              </button>
              )}
              
            </Col>
          </Row>
        </form>
      </div>
      {/* new listing created modal */}
      {editUserModal && (
        <ConfirmModal
          // setConfirmModal={setAddSignModal}
          //   confirm={handleAddSignsModal}
          title="Invites
          Sent"
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Close"
          handleClose={() => history.goBack()}
          handleConfirm={() => {
            history.goBack();
          }}
        >
          <div className="childs">
            <p className="newListingCreated_description text-left">
              User Updated Successfully.
            </p>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

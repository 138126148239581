import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBookingDetails } from "../../../../../../../services/reducers/listing-reducer";
import { Col, Row } from "react-bootstrap";
import {
  selectService,
  selectCharge,
  selectSign,
  deselectService
} from "../../../../../../../services/reducers/listing-reducer";
import { useState } from "react";
import { To2Decimal } from "../../../../../../../services/utility";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
const Availablity = ({
  signs,
  services,
  handleBook,
  canBook,
  item,
  handleShowPortableSignDetails,
  summaryOfCharges,
  availability,
  setAvailabilityFrom,
  setAvailabilityTo,
  bookingDays
}) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const bookingDetails = useSelector((state) =>
    getBookingDetails(state.listing)
  );
  const userType = localStorage.getItem("userType");
  const { portableSignsListing, listingItem, portableSignInfo } = useSelector(
    (state) => state.listing
  );
  const [showRushFeeModel, setShowRushFeeModel] = useState();
  const [rushFeeService, setRushFeeService] = useState(false);

  useEffect(() => {
    services?.map((service) => {
      if (service.serviceType === "Included") {
        dispatch(selectService({ service: service }));
      }
    });

    summaryOfCharges?.map((charge) => {
      if (charge.chargeId != null && charge.chargesType === "Included") {
        dispatch(selectCharge({ charge: charge }));
      }
    });
  }, []);

  const handleRushFee = (service) => {
    if (availability.fromDate && availability.toDate) {
      setShowRushFeeModel(true);
      setRushFeeService(service);
    } else {
      let msg = `Please select booking dates`;
      addToast(msg, { appearance: "error" });
    }
  }

  const handleCancelBTN = () => {
    dispatch(deselectService({ service: rushFeeService }));
    dispatch(
      setAvailabilityTo(
        moment(availability.toDate, "DD-MMMM-YYYY").subtract(3, 'days')
      )
    );
    dispatch(
      setAvailabilityFrom(
        moment(availability.fromDate, "DD-MMMM-YYYY").subtract(3, 'days')
      )

    );
    setShowRushFeeModel(false);
  }

  const handleConfirmBTN = () => {
    dispatch(selectService({ service: rushFeeService }));
    dispatch(
      setAvailabilityTo(
        moment(availability.toDate, "DD-MMMM-YYYY").add(3, 'days')
      )
    );
    dispatch(
      setAvailabilityFrom(
        moment(availability.fromDate, "DD-MMMM-YYYY").add(3, 'days')
      )

    );
    setShowRushFeeModel(false);
  }

  return (
    <>
      {signs && (
        <div className="tableMainsection mt-4">
          <table className="tableContainer">
            <thead className="tableHead">
              <tr>
                <td className="bookAllFavoritesModaltableheading">SIGN</td>
                <td className="bookAllFavoritesModaltableheading">DESCRIPTION</td>
                <td className="bookAllFavoritesModaltableheading">MEDIA</td>
                <td className="bookAllFavoritesModaltableheading">LOCATION</td>
                <td className="bookAllFavoritesModaltableheading">
                  DIRECTION FACING
                </td>
                {(portableSignsListing || listingItem.isPortableSign) && (
                  <td className="bookAllFavoritesModaltableheading">Radius</td>
                )}
                <td className="bookAllFavoritesModaltableheading">
                  SELECT QUANTITY
                </td>
                <td className="bookAllFavoritesModaltableheading">PRICE</td>
              </tr>
            </thead>
            <tbody className="tableBody">
              {signs.map((sign, index) => (
                <tr key={index + "_sign_in_sd"}>
                  <td>{sign.name}</td>
                  <td>{sign.description}</td>
                  <td>{sign.mediaTypeText}</td>

                  {portableSignsListing || listingItem.isPortableSign ? (
                    <>
                      {sign.portableDetails ? (
                        <>
                          <td>
                            <button
                              className="linkButton"
                              onClick={() =>
                                handleShowPortableSignDetails(sign)
                              }
                            >
                              {sign.portableDetails.address}
                            </button>
                          </td>
                          <td>{sign.signDirectionFacingText}</td>
                          <td>{sign.radius}</td>
                        </>
                      ) : (
                        <>
                          <td>
                            <button
                              className="linkButton"
                              onClick={() =>
                                handleShowPortableSignDetails(sign)
                              }
                            >
                              {" "}
                              Go Pin It Portable Sign Location{" "}
                            </button>
                          </td>
                          <td>{sign.signDirectionFacingText}</td>
                          <td>{sign.radius}</td>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <td>{sign.specificLocation}</td>
                      <td>{sign.signDirectionFacingText}</td>
                    </>
                  )}
                  <td>
                    <div className="gridSelectBox">
                      <Form.Select
                        className="selecContainer"
                        placeholder="Select Quantity"
                        disabled={sign.available === 0}
                        value={sign.quantity}
                        onChange={(e) =>
                          dispatch(
                            selectSign({
                              signId: sign.id,
                              quantity: e.target.value,
                            })
                          )
                        }
                      >
                        <option value="0">0</option>
                        {Array.from(Array(sign.available), (e, i) => {
                          return (
                            <option
                              value={i + 1}
                              key={"sign_qty_" + sign.id + "_" + i}
                            >
                              {i + 1}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </td>
                  <td>{To2Decimal(sign.price)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot className="tableFoot">
              <tr>
                <td colSpan={portableSignsListing || listingItem.isPortableSign ? "8" : "7"}>
                  <p className="addonservices">Services</p>{" "}
                </td>
              </tr>

              {services &&
                services.map((service, index) =>
                  service.serviceType === "Included" ? (
                    <tr key={service.serviceId + "_service_" + index}>
                      <td colSpan={portableSignsListing || listingItem.isPortableSign ? "7" : "6"}>
                        <label className="checkBox">
                          <input
                            type="checkBox"
                            checked={true}
                            disabled
                            onChange={() =>
                              dispatch(selectService({ service: service }))
                            }
                          />
                          <span className={"checkBoxChecked"}></span>
                          {service.name && (
                            <span className={`checkBoxText ms-4 px-2`}>
                              {service.name}
                            </span>
                          )}
                        </label>
                        <p className="descp">
                          {service.description}
                          {"   ("}
                          {service.serviceType}
                          {")"}
                        </p>
                      </td>
                      <td align="center">{To2Decimal(service.price)} {service.priceType === "Percentage" ? "%" : ""}</td>
                    </tr>
                  ) : (
                    <tr key={service.serviceId + "_service_" + index}>
                      <td colSpan={portableSignsListing || listingItem.isPortableSign ? "7" : "6"}>
                        <label className="checkBox">
                          <input
                            type="checkBox"
                            checked={service.isChecked}
                            onChange={() =>
                              // service.serviceId === "00000000-0000-0000-0000-000000000000" ? handleRushFee(service) :
                                dispatch(selectService({ service: service }))
                            }
                          />
                          <span
                            className={`${service.isChecked
                              ? "checkBoxChecked"
                              : "checkBoxCheck"
                              }`}
                          ></span>
                          {service.name && (
                            <span className={`checkBoxText ms-4 px-2`}>
                              {service.name}
                            </span>
                          )}
                        </label>
                        <p className="descp">
                          {service.description}
                          {"   ("}
                          {service.serviceType}
                          {")"}
                        </p>
                      </td>
                      <td align="center">{To2Decimal(service.price)} {service.priceType === "Percentage" ? "%" : ""}</td>
                    </tr>
                  )
                )}

              <tr>
                <td colSpan={portableSignsListing || listingItem.isPortableSign ? "8" : "7"} align="right">
                  <p className="totaladdservice text-lg fw-bold">
                    <span className="text-xxs text-gray-1400 fw-normal">
                      Subtotal ({bookingDetails.noOfSelectedServices} services)
                    </span>{" "}
                    {To2Decimal(bookingDetails.addOnServicePrice)}
                  </p>
                </td>
              </tr>

              <tr>
                <td colSpan="7">
                  <p className="addonservices">Charges</p>{" "}
                </td>
              </tr>
              {summaryOfCharges &&
                summaryOfCharges.map((charge, index) =>
                  charge.chargeId != null ? (
                    charge.chargesType === "Included" ? (
                      <tr key={charge.chargeId + "_charge_" + index}>
                        <td colSpan={portableSignsListing || listingItem.isPortableSign ? "7" : "6"}>
                          <label className="checkBox">
                            <input
                              type="checkBox"
                              checked={true}
                              disabled
                              onChange={() =>
                                dispatch(selectCharge({ charge: charge }))
                              }
                            />
                            <span className={"checkBoxChecked"}></span>
                            {charge.charge?.name && (
                              <span className={`checkBoxText ms-4 px-2`}>
                                {charge.charge?.name}
                              </span>
                            )}
                          </label>
                          <p className="descp">
                            {charge.charge?.description}
                            {"   ("}
                            {charge.chargesType}
                            {")"}
                          </p>
                        </td>
                        <td align="center">{To2Decimal(charge.priceValue)} {charge.priceType === "Percentage" ? "%" : ""}</td>
                      </tr>
                    ) : (
                      <tr key={charge.chargeId + "_charge_" + index}>
                        <td colSpan={portableSignsListing || listingItem.isPortableSign ? "7" : "6"}>
                          <label className="checkBox">
                            <input
                              type="checkBox"
                              checked={charge.isChecked}
                              //checked={true}
                              onChange={() =>
                                dispatch(selectCharge({ charge: charge }))
                              }
                            />
                            <span className={"checkBoxChecked"}></span>
                            {charge.charge?.name && (
                              <span className={`checkBoxText ms-4 px-2`}>
                                {charge.charge?.name}
                              </span>
                            )}
                          </label>
                          <p className="descp">
                            {charge.charge?.description}
                            {"   ("}
                            {charge.chargesType}
                            {")"}
                          </p>
                        </td>
                        <td align="center">{To2Decimal(charge.priceValue)} {charge.priceType === "Percentage" ? "%" : ""}</td>
                      </tr>
                    )
                  ) : (
                    <></>
                  )
                )}

              <tr>
                <td colSpan={portableSignsListing || listingItem.isPortableSign ? "8" : "7"} align="right">
                  <p className="totaladdservice text-lg fw-bold">
                    <span className="text-xxs text-gray-1400 fw-normal">
                      Subtotal ({bookingDetails.noOfSelectedCharges} charges)
                    </span>{" "}
                    {To2Decimal(bookingDetails.addOnChargePrice)}
                  </p>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}

      <div className="d-flex justify-content-end align-items-center pt-4 ">
        <div className="booknow_btn_container">
          {userType === "Temp" ? (
            <button
              className="btn btn-primary btn_sunrise btn_purple"
              disabled={true}
            >
              {" "}
              Book
            </button>
          ) : (
            <button
              className={canBook ? "booknow_btn02 " : "btn_sunrise btn_purple"}
              disabled={!canBook}
              onClick={() => handleBook()}
            >
              {" "}
              Book
            </button>
          )}
        </div>
        <div>
          <div className="px-4 d-flex">
            <div className="booking_period">
              <span>{bookingDetails.noOfSigns} Signs</span>
              {bookingDays?<>
                  &nbsp;/ {bookingDays} days</>:<></>}
              <span>
                $
                {bookingDetails.totalAmount
                  ? (parseFloat(bookingDetails.totalAmount)-((bookingDetails.addOnServicePrice??0) +
                  (bookingDetails.addOnChargePrice??0))).toFixed(2)
                  : bookingDetails.totalAmount}
              </span>
              {(bookingDetails.noOfSelectedServices > 0 ||
                bookingDetails.noOfSelectedCharges > 0) && (
                  <p>
                    + $
                    {To2Decimal(
                      bookingDetails.addOnServicePrice +
                      bookingDetails.addOnChargePrice
                    )}{" "}
                    of additional services
                  </p>
                )}
            </div>
          </div>
        </div>
      </div>



      {showRushFeeModel && (
        <ConfirmModal
          title="Rush Fee"
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          handleClose={() => handleCancelBTN()}
          handleConfirm={() => handleConfirmBTN()}
          width="400px"
          cancelText="No"
        >
          <div className="childs">
            As you have selected Rush Fee your booking has been shifted advance by 3 days, New booking dates are:
            <span></span>
            Do you want to continue with this new booking dates?
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default Availablity;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { SelectionComponent } from "../../../../components/SelectionComponent/SelectionComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getRefundTypeOptionsAsync,
  setRefundAmount,
  setRefundType,
} from "../../../../services/reducers/orders-reducers";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import BtnRadio from "../../../../components/BtnRadio/BtnRadio";

export const OrderRefundModal = ({
  show,
  setShow,
  title,
  footerText,
  refundAmount,
  refundType,
  handlefooterBtn,
  refundAmountText,
  refundTypeText,
  status,
  setStatus,
  setRefundReason
}) => {
  const dispatch = useDispatch();
  const [showReason, setShowReason] = useState(false);

  const refundTypes = useSelector((state) => state.orders.refundTypes);
  useEffect(async () => {
    dispatch(getRefundTypeOptionsAsync());
  }, [dispatch]);

  const handleStatusChange = (value) => {
    setStatus(value);
    if (value === "Reject") setShowReason(true);
    else setShowReason(false);
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen="sm-down"
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 style={{ fontSize: "18px", fontWeight: "300" }}>{title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex pb-4">
          <div className="pe-4">
            <BtnRadio
              name="Status"
              label="Approve"
              value="Approve"
              onChangeStatus={() => {
                handleStatusChange("Approve");
              }}
              status={status == "Approve" ? true : false}
            />
          </div>
          <div className="pe-4">
            <BtnRadio
              name="Status"
              label="Reject"
              value="Reject"
              onChangeStatus={() => {
                handleStatusChange("Reject");
              }}
              status={status == "Reject" ? true : false}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          {/* <SelectionComponent
            label="Refund Type"
            inputsize="inputsize col-md-12 m-2"
            option={refundTypes}
            style={{ width: "100%" }}
            onChange={(e) => dispatch(setRefundType(e.target.value))}
          /> */}
          <span>Refund Type: {refundTypeText}</span>
        </div>
        <div className="d-flex justify-content-between">
          {/* <InputField
            type="number"
            placeholder="Refund Amount"
            label="Refund Amount"
            labelClassName={"inputsize col-md-12 m-2"}
            onChange={(e) => dispatch(setRefundAmount(e.target.value))}
          /> */}
          <span>Refund Amount: {Number(refundAmountText).toFixed(2)}</span>
        </div>
        {showReason && (
          <div className="d-flex justify-content-between">
            <InputField
              type="text"
              placeholder="Reason of rejection"
              label="Reason of Rejection"
              labelClassName={"inputsize col-md-12 m-2"}
              onChange={(e) => {
                setRefundReason(e.target.value)}}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        className="popModalfooter justify-content-center"
        onClick={handlefooterBtn}
      >
        <span style={{ color: "#ffffff" }}>{status==="Approve"?"Approve Request":"Reject Request"}</span>
      </Modal.Footer>
    </Modal>
  );
};

import React from "react";

const PriceDetails = ({ priceDetails }) => {
  let totalPrice = priceDetails?.price;
  return (
    <>
      {" "}
      <div className="priceDetailsSection flex-grow-1 p-20">
        <h6 className="">Price Details</h6>
        <div className="mt-32 pb-32 d-flex justify-content-between borderBottom">
          <span className="spantitle">1 x Featured Listing</span>
          <span className="pricebox spantitle">${priceDetails?.price}</span>
        </div>
     
        <div className="tatalAmountBox d-flex justify-content-between mt-32">
          <h6 className="fw-normal">Total Amount</h6>
          <h6 className="fw-bold">${totalPrice}</h6>
        </div>
      </div>
    </>
  );
};

export default PriceDetails;

import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";

import { MapMarkerSVG } from "../../../../../../../../../assets/svgComponents/inventorySVG";
import {
  CrossSVG,
  PlusSVG,
} from "../../../../../../../../../assets/svgComponents/svg";
import { AddressCard } from "../../../../../../../../../components/AddressCard/AddressCard";
import { AtmWithRadioBtn } from "../../../../../../../../../components/AtmCard/AtmWithRadioBtn";
import ConfirmModal from "../../../../../../../../../components/confirmModal/ConfirmModal";
import CustomRadioButton from "../../../../../../../../../components/RadioButton/CustomRadioButton";
import RadioButton from "../../../../../../../../../components/RadioButton/RadioButton";
import { SelectionComponent } from "../../../../../../../../../components/SelectionComponent/SelectionComponent";
import {
  addAddress,
  getCityList,
  getCountryList,
  getStateList,
} from "../../../../../../../../../services/Common";
import {
  getAddressList,
  makeDefoulAddress,
} from "../../../../../../../../../services/Provider";
import {
  getDefaultAddress,
  updateDefaultAddress,
} from "../../../../../../../../../services/Provider/Configurator/Listings";
import { currentUser } from "../../../../../../../../../services/Provider/UserManagement/UserManagement";

const BillingAddress = () => {
  const { addToast } = useToasts();
  const [addessList, setAddessList] = useState();
  const [user, setUser] = useState();
  const [billingAddressModal, setBillingAddressModal] = useState(false);
  const [addNewAddressModal, setAddNewAddressModal] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState();
  const [isChangeDefaultAddress, setIsChangeDefaultAddress] = useState(false);
  const [optionsList, setOptionsList] = useState({
    states: [],
    countries: [],
    cities: [],
  });

  const initialValues = {
    address: "",
    pincode: "",
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string().required("Address is required!"),
    pincode: Yup.string()
      .min(6, "Postal code should be 6 character")
      .max(6, "Postal code should be 6 character")
      .required("Postal code is required!"),
  });

  const handleSubmit = (values) => {
    let formData = {
      address1: values.address,
      address2: "",
      pincode: values.pincode,
      //   city: selectedCity && selectedCity[0].name,
      //   state: selectedState && selectedState[0].name,
      //   country: selectedCountry && selectedCountry[0].name,
    };
    // console.log(formData, "formData");
    // addAddress(formData)
    //   .then((res) => {
    //     if (res.status === 201) {
    //       setAddNewAddressModal(false);
    //       getAddressList(user && user.id)
    //         .then((res) => {
    //           if (res.status === 200) {
    //             setAddessList(res.data);
    //             setBillingAddressModal(true);
    //             setAddNewAddressModal(false);
    //           }
    //         })
    //         .catch((err) => {
    //           console.log("err", err);
    //         });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
  };

  useEffect(() => {
    currentUser()
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data);
          let id = res.data.id;

          getAddressList(id)
            .then((res) => {
              if (res.status === 200) {
                setAddessList(res.data);
                let tempAddress = res.data.filter(
                  (a) => a.isDefault == true && a
                );
                setDefaultAddress(tempAddress);
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [isChangeDefaultAddress]);

  useEffect(async () => {
    const [result1, result2, result3] = await Promise.all([
      getCountryList(),
      getStateList(1),
      getCityList(3870),
    ]);
    try {
      if (result1.status == 200) {
        setOptionsList((prev) => ({ ...prev, countries: result1.data }));
      }
      if (result2.status == 200) {
        setOptionsList((prev) => ({ ...prev, states: result2.data }));
      }
      if (result3.status == 200) {
        setOptionsList((prev) => ({ ...prev, cities: result3.data }));
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  // // default address fetch
  // useEffect(async () => {
  //   currentUser()
  //     .then((res) => {
  //       if (res.status === 200) {
  //         // setUser(res.data);
  //         let id = res.data.id;

  //         getAddressList(id)
  //           .then((res) => {
  //             if (res.status === 200) {
  //               setAddessList(res.data);
  //               console.log(res.data, "res");
  //             }
  //           })
  //           .catch((err) => {
  //             console.log("err", err);
  //           });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  //   // await getDefaultAddress().then((res) => {
  //   //   setDefaultAddress(res.data);
  //   //   console.log(res.data, "res");
  //   // });
  // }, [isChangeDefaultAddress]);

  // change default address handle
  const handleChangeDefaultAddress = async (card) => {
    await makeDefoulAddress(card.id)
      .then((res) => {
        if (res.status == 202) {
          setBillingAddressModal(false);
          setIsChangeDefaultAddress(!isChangeDefaultAddress);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const handleAddNewAddress = () => {
    setBillingAddressModal(false);
    setAddNewAddressModal(true);
  };

  const handleAddAddress = () => {};
  return (
    <>
      <div className="d-flex justify-content-between mt-3 px-4">
        <h6>Billing Address</h6>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setBillingAddressModal(true)}
        >
          <MapMarkerSVG />{" "}
          <span className="spantitle" style={{ color: "#F99300" }}>
            Change Address
          </span>
        </div>
      </div>
      <div className="mt-32 mb-32 px-4">
        {defaultAddress ? (
          defaultAddress.map((address) => (
            <span style={{ color: "#AFAFAF" }} className="">
              {address?.addressTypeName} | {address?.address1}
              <br /> {address?.city}, {address?.state}, <br />
              {address?.country}
            </span>
          ))
        ) : (
          <span className="text-gray-1400">No address found</span>
        )}
      </div>
      {billingAddressModal && (
        <ConfirmModal
          isModalHeader={true}
          title="Billing Address"
          // confirmText="Add new address"
          handleClose={() => setBillingAddressModal(false)}
          // handleConfirm={() => handleAddNewAddress()}
        >
          <div className="childs">
            {addessList.length > 0 ? (
              addessList.map((address, index) => {
                return (
                  <>
                    <AddressCard
                      address={address}
                      user={user}
                      makeDefoultAddrees={handleChangeDefaultAddress}
                      index={index}
                      isNotEditable={false}
                      // deleteAddress={deleteAddress}
                      // editAddress={editAddress}
                    />
                    {/* <Row>
                      <Col className="md-2">
                        <CustomRadioButton
                          onChange={() =>
                            handleChangeDefaultAddress(address.id)
                          }
                          isDefault={address?.isDefault}
                          value={address?.isDefault}
                        />
                      </Col>
                      <Col className="md-10">
                        <span style={{ color: "#AFAFAF" }}>
                          {address?.addressTypeName} | {address?.address1}{" "}
                          {address?.city}, {address?.state}, {address?.country}
                        </span>
                      </Col>
                    </Row> */}
                  </>
                );
              })
            ) : (
              <span style={{ color: "#AFAFAF" }}>No address found</span>
            )}
          </div>
        </ConfirmModal>
      )}
      {/* {addNewAddressModal && (
        <ConfirmModal
          isModalHeader={true}
          title="Billing Address"
          confirmText="Add new address"
          handleClose={() => setAddNewAddressModal(false)}
          // handleConfirm={() => handleAddAddress()}
        >
          <div className="addressCardContainer">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="py-4">
                <div className="confirmModal">
                  <div
                    className="confirmModalOver"
                    onClick={() => setAddNewAddressModal(false)}
                  ></div>
                  <div
                    className="confirmModalInner modalbox"
                    // style={{ maxWidth: `${width}` }}
                  >
                    <div className="confirmModalTop d-flex justify-content-between">
                      <h3 className="font-weight-light mb-1 mw-100 p-4 ps-3 confirmModalTopTitle">
                        {" "}
                        Add New Address
                      </h3>
                      <span
                        className="px-3 py-2 confirmModalTopCross"
                        onClick={() => setAddNewAddressModal(false)}
                      >
                        <CrossSVG />
                      </span>
                    </div>
                    <div className="modal-childs">
                      <div className="px-3 py-2">
                        <Field
                          type="text"
                          placeholder="Address"
                          label="Address"
                          name="address"
                          className="form-control"
                        />{" "}
                        <ErrorMessage name="address" />
                      </div>
                      <div className="px-3  py-2">
                        <Field
                          placeholder="Postal code"
                          label="Postal code"
                          name="Postal code"
                          className="form-control"
                        />{" "}
                        <ErrorMessage name="Postal code" />
                      </div>
                      <div className="px-3  py-2">
                        <SelectionComponent
                          label="Country"
                          name="Country"
                          option={optionsList.countries}
                          //   handleOnchange={handleCountryList}
                        />{" "}
                        <ErrorMessage name="Country" />
                      </div>
                      <div className="px-3  py-2">
                        <SelectionComponent
                          label="State"
                          option={optionsList.states}
                          //   handleOnchange={handleStateList}
                        />{" "}
                      </div>
                      <div className="px-3  py-2">
                        <SelectionComponent
                          label="City"
                          option={optionsList.cities}
                          //   handleOnchange={handleCityList}
                        />{" "}
                      </div>
                    </div>
                    <div className="confirmModalBottom border-top">
                      <div className="row no-gutters g-0">
                        <div className="col">
                          <button
                            className="btn btn-success btn-block border-0  w-100 confirmModalConfirmBtn"
                            type="submit"
                          >
                            <span className="text-md text-white">Add Address</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </ConfirmModal>
      )} */}
    </>
  );
};

export default BillingAddress;

import React, { useState } from "react";
import { Col, Row, Button, Form, Container } from "react-bootstrap";
import Tabs from "./Tabs/Tabs";
import { GoPinitMapsSites } from "../../../../components/Maps/GoPinitMapsSites";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import {
  getListBySitesAsync,
  getListItemDetailsAsync,
  getSimilarListingAsync,
  getListingReviewsAsync,
  getAvailableOffersForProviderAsync,
  getSelectedFilters,
  setShowOnlyFeatured,
  setLatitude,
  setLongitude,
  setMapZoom,
  setSearchSection,
  getSitesPageNumber,
  setMapDistance
} from "../../../../services/reducers/listing-reducer";
import { useDispatch, useSelector } from "react-redux";
import { ListingItemCard } from "./ListingItemCard";
import { ListingFilters } from "./ListingFilters";
import { BookPortableSignModal } from "./BookPortableSignModal";
import { getRadiusOfOperation } from "../../../../services/utility";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  InfoSVG, MarkerBlueSVG, MarkerGreenSVG, MarkerPurpleSVG, MarkerYellowSVG,
} from "../../../../assets/svgComponents/svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlane,
  faCalendarCheck,
  faBusSimple,
  faMapMarker,
  faBuilding,
  faShoppingCart,
  faPersonBiking,
  faSignsPost,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import markerImg from "../../../../assets/images/markerImg.png";
import markersInfoImg from "../../../../assets/images/markersInfo.png";

export const BySites = ({ favorite, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { listingFilterMedia, listingFilterEnvironment, locationName, listBySites } = useSelector((state) => state.listing);
  const {
    media,
    environment,
    onlyFeatured,
    minCost,
    maxCost,
    fromDate,
    uptoDate,
    latitude,
    longitude,
    mapZoom,
    searchTerm,
    mapDistance
  } = useSelector((state) => getSelectedFilters(state.listing));
  const [showMarkerInfoImg, setShowMarkerInfoImg] = useState(false);
  const [showBookPortableSign, setShowBookPortableSign] = useState(false);
  const [initPortableSignBooking, setInitPortableSignBooking] = useState(false);  
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const onFeaturedChange = (value) => {
    dispatch(setShowOnlyFeatured(value));
  };


  useEffect(() => {
    setIsLoading(false);
  }, [listBySites]);

  useEffect(() => {
    getListing();
  }, [page]);


  useEffect(async () => {
    if (page !== 1) {
      setPage(1);
    } else {
      getListing();
    }

  }, [
    dispatch,
    minCost,
    maxCost,
    fromDate,
    uptoDate,
    onlyFeatured,
    listingFilterMedia,
    listingFilterEnvironment,
    latitude,
    longitude,
    mapZoom,
    searchTerm,
  ]);

  const getListing = () => {
    if (isLoading) return;
    const reqBody = {
      page: page,
      pageSize: 10,
      orderBy: "",
      export: false,
      searchString: searchTerm,
      userLocation: "",
      costs: [],
      mediaTypes: media,
      environmentIds: environment,
      dates: [],
      onlyFeatured: onlyFeatured,
      listingType: 0,
      providerId: "",
      latitude: latitude,
      longitude: longitude,
      radiusOfOperation:  mapDistance ? mapDistance :getRadiusOfOperation(latitude, mapZoom),
    };
    if (minCost != null && maxCost) {
      reqBody.costRange = { min: minCost, max: maxCost };
    }
    if (fromDate && uptoDate) {
      reqBody.dateRange = { fromDate, uptoDate };
    }
    setIsLoading(true);
    dispatch(getListBySitesAsync(reqBody));
  };

  const handleDetailpage = (itemId, listingId, id, type, providerId) => {
    console.log('listing details:', type, id, itemId, listingId);
    dispatch(getListItemDetailsAsync(itemId));
    dispatch(getSimilarListingAsync(itemId));
    dispatch(getListingReviewsAsync(listingId));
    console.log('getting offers of site', id);
    dispatch(getAvailableOffersForProviderAsync(providerId));
    history.push("/customer/site-detail");
  };

  const handleListing = (card) => { };

  const [markars, setMarkers] = useState([]);

  const handlePortableSignSubmitBtn = () => {
    setShowBookPortableSign(false);
  };

  return (
    <>
      <Row className="home-container gridSection g-0">
        <Col id="pnl-list-by-sites" className={listBySites.totalRecords > 0 ? "home-left col-6 overflow-auto h-100 flex-grow-1 scrollOverBottom" : "home-left col-6 overflow-auto h-100 flex-grow-1 scrollOverBottomDone" }>
          <Row className="home-section1 row px-1 align-items-center g-0 listingFiltersRow">
            <ListingFilters isMedaiTypeNotAvailable={true}/>
          </Row>
          <div className="home-section2">
            <Tabs
              featured={onlyFeatured}
              tabs={rest.tabs}
              activeTab={rest.activeTab}
              setActiveTab={rest.setActiveTab}
              setFeatured={(value) => onFeaturedChange(value)}
            />
          </div>

          {listBySites.records.length > 0 ? (
            <InfiniteScroll dataLength={listBySites.records.length - 1}
              hasMore={true}
              next={() => setPage(page + 1)}
              scrollableTarget="pnl-list-by-sites"
              // loader={<h6>Loading...</h6>}
              hasChildren={listBySites.records.length > 0}
            >
              {listBySites.records.map((site, index) => (
              <ListingItemCard
                  key={site.itemId + "_site"}
                  id={site.id}
                  itemId={site.itemId}
                  listingId={site.listingId}
                  providerId={site.providerId}
                  type="site"
                  title={site.listingName}
                  subtitle={site.name}
                  text={site.description}
                  startingPrice={site.price ? parseFloat(site.price).toFixed(2) : site.price}
                  rating={site.rating}
                  reviews={site.reviews}
                  isFavorite={site.isFavorite}
                  image={site.image}
                  moreBillboards={site.moreBillboards}
                  isFeatured={site.featured}
                  formats={site.formats}
                  isProvider={false}
                  providerName={site.provider}
                  handleListing={handleListing}
                  handleDetailpage={(itemId, listingId, id, type, providerId) => { handleDetailpage(itemId, listingId, id, type, providerId); }}
                  card={site}
                  cartType={"site"}
                  minBookingPeriod={site.minBookingPeriod}
                  periodTypeText={site.periodTypeText}
                />
              ))}
            </InfiniteScroll>
          ) : (<h6>No records</h6>)}


        </Col>
        <Col className="home-right col-6 overflow-auto h-100 flex-grow-1">
        {
            showMarkerInfoImg && (
              <section className="mapMarkerInfoImg" style={{ width: 'auto !important' }}>
                <ul>
                  <li style={{height:"30px"}}>
                    <FontAwesomeIcon icon={faPlane} color="#0000ff" />
                    Airport
                  </li>
                  <li style={{height:"30px"}}>
                    <FontAwesomeIcon icon={faShoppingCart} color="#0000ff" />
                    Retail
                  </li>
                  <li style={{height:"30px"}}>
                    <FontAwesomeIcon icon={faBuilding} color="#f99300" />
                    Private Building
                  </li>
                  <li style={{height:"30px"}}>
                    <FontAwesomeIcon icon={faPersonBiking} color="#0000ff" />
                    Vehicular
                  </li>
                  <li style={{height:"30px"}}>
                    <FontAwesomeIcon icon={faBusSimple} color="#ffd700" />
                    Roadside
                  </li>
                  <li style={{height:"30px"}}>
                    <FontAwesomeIcon icon={faCalendarCheck} color="#0000ff" />
                    Event Centre
                  </li>
                  <li style={{height:"30px"}}>
                    <FontAwesomeIcon icon={faSignsPost} color="orange" />
                    Sign
                  </li>
                  <li style={{height:"30px"}}>
                    <MarkerPurpleSVG />
                    Static Billboard
                  </li>
                  <li style={{height:"30px"}}>
                    <MarkerGreenSVG />
                    Digital Billboard
                  </li>
                  <li style={{height:"30px"}}>
                    <img src={markerImg} alt="" />
                    Portable Signs
                  </li>
                  <li style={{height:"30px"}}>
                    <MarkerBlueSVG />
                    Place Based Media
                  </li>
                  <li style={{height:"30px"}}>
                    <MarkerYellowSVG />
                    Transit
                  </li>
                </ul>
              </section>
            )
          }
          <div className="mapMarkerInfo">
            <button
              className="secondaryBtn me-2"
              type="button"
              value="Book portable sign"
              // onClick={()=>{setInitPortableSignBooking(true);}}
              onClick={() => { setShowMarkerInfoImg(!showMarkerInfoImg); }}
            >
              <InfoSVG />
            </button>
          </div>
          <GoPinitMapsSites
            page="customerBySites"
            markers={listBySites.records}
            currentLocation={{ lat: latitude, lng: longitude, zoom: mapZoom }}
            setLat={(param) => dispatch(setLatitude(param))}
            setLng={(param) => dispatch(setLongitude(param))}
            initPortableSignBooking={initPortableSignBooking}
            handlePinItForPortableSign={() => { setShowBookPortableSign(true); }}
            handelSearchNewLocation={() => {
              setInitPortableSignBooking(false);
              dispatch(setSearchSection({ id: 'location', name: "Location" }));
              document.getElementById("globalSearchBox").focus();
            }}
            setZoom={(param) => { console.log('set map zoom', param); dispatch(setMapZoom(param)); }}
            setDistance={(param)=> dispatch(setMapDistance(param))}
            handleDetailpage={(itemId, listingId, id, type, providerId) => { handleDetailpage(itemId, listingId, id, type, providerId); }}
          />
        </Col>
      </Row>
    </>
  );
};

import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Container, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { useToasts } from "react-toast-notifications";

import { NotificationSVG } from "../../../../../../assets/svgComponents/table";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import Grid from "../../../../../../components/grid/Grid";
import { InputField } from "../../../../../../components/InputField";
import NavBar from "../../../../../../components/navBar";
import { getSiteList } from "../../../../../../services/Provider";
import ConfrimAddSign from "../modal/ConfrimAddSign";
import TableActions from "./TableActions";

import AddServicesPage from "../pages/NewListings/AddServicesPage";
import AllConfiguration from "../pages/NewListings/AllConfiguration";
import ProjectContext from "../../../../../../context/ProjectContext";
import AddChargesPage from "../pages/NewListings/AddChargesPage";
import SelectSitesPage from "../pages/NewListings/SelectSitesPage/SelectSitesPage";
import SelectSignsPage from "../pages/NewListings/SelectSignPage/SelectSignsPage";
import CreateListingSignsDetails from "../pages/NewListings/SelectSignPage/CreateListingSignsDetails";
import PricingPage from "../pages/NewListings/PricingPage";

const SwitchCreateListingDOM = (props) => {
  const location = useLocation();
  const history = useHistory();

  // let sideTabs = [
  //   { tab: "Sites", isActive: true },
  //   { tab: "Signs", isActive: false },
  //   { tab: "Services", isActive: false },
  //   { tab: "Charges", isActive: false },
  //   { tab: "Configurations", isActive: false },
  // ];
  const { addToast } = useToasts();
  const [activeTab, setActiveTab] = useState();
  const [sideTabs, setSideTabs] = useState();
  const [activeTabTitle, setActiveTabTitle] = useState();
  const [isSignDetails, setIsSignDetails] = useState(false);
  const [newListingCreatedModal, setNewListingCreatedModal] = useState(false);
  const [addSignModal, setAddSignModal] = useState(false);
  const [numberOfSigns, setNumberOfSigns] = useState(null);
  const [selectedSign, setSelectedSign] = useState();
  const [navBarBtn, setNavBarBtn] = useState({
    whiteBtnText: "",
    blueBtnText: "",
  });
  const [siteList, setSiteList] = useState([]);
  const [addSigns, setAddSigns] = useState(null);
  const {
    handleSelectedEntities,
    selectedEntities,
    createListing,
    handleCreateListing,
  } = useContext(ProjectContext);

  useEffect(() => { 
    let tempArr;
    let tempArr2;
    tempArr = selectedEntities.filter((item) => item.isChecked);
    // console.log(tempArr[0].name, "tempArr");
    setActiveTab(tempArr[0].name);
    // tempArr2 = tempArr.filter((item) => item.isActive && item.name);
    // console.log(tempArr2, "tempArr2");
    setSideTabs(tempArr);
    // handleSelectedEntities(tempArr);
    // console.log(tempArr, "tempArr");
    // let sideTabs
  }, []);

  // fetch site list data
  useEffect(() => {
    getSiteList()
      .then((res) => {
        // console.log(res.data.data.records);
        setSiteList(res.data.data.records);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSelectSignsRow = (item) => {
    console.log(item, "item");
    let selectSignId = item.id;
    // setActiveTabTitle(sideTabs[1]);
    setAddSignModal(true);
    createListing.selectSignId = item.id;
    createListing.selectSignSize = item.name;
    createListing.selectSignMedia = item.media;
    createListing.selectSignDimension = item.dimension;
    createListing.selectSignHeight = item.height;
    createListing.selectSignWidth = item.width;
  };

  // console.log(createListing, "createListing");
  // add signs confirm modal
  const handleAddSignsModal = () => {
    if (!addSigns == null || !addSigns == undefined || !addSigns == "") {
      createListing.addSigns = addSigns;

      // history.push("/");
      setNavBarBtn({
        whiteBtnText: "Cancel",
        blueBtnText: "Next",
      });
      setIsSignDetails(true);
      setAddSignModal(false);
      activeTabTitle("Edit Signs");
    } else {
      addToast("Please add signs", {
        appearance: "warning",
      });
    }
  };

  const handleCheckServicesRow = (id) => {
    console.log(id, "id");
  };

  // handle navbar for move next step
  const handleBlueBtn = (activeTab) => {
    if (activeTab == "Signs") {
      let tempData = [...sideTabs];
      sideTabs.forEach(
        (item, index) =>
          item.name == activeTab &&
          ((item.isActive = true), (item.isCurrentTitle = false))
      );
      setActiveTab("Services");
    } else if (activeTab == "Services") {
      let elmIndex;
      let nextTab;
      sideTabs.forEach(
        (item, index) =>
          item.name == activeTab &&
          ((item.isActive = true),
          (item.isCurrentTitle = false),
          (elmIndex = index))
      );
      nextTab = sideTabs[elmIndex + 1];
      console.log(nextTab, "nextTab");
      setActiveTab("Charges");
    } else if (activeTab == "Charges") {
      sideTabs.forEach(
        (item, index) =>
          item.name == activeTab &&
          ((item.isActive = true), (item.isCurrentTitle = false))
      );
      setActiveTab("Configuration");
    }
  };

  const handleWhiteBtn = () => {
    console.log("white");
    history.push("/provider/configurator/listings");
  };

  const renderScreen = (tab) => {
    switch (tab) {
      case "Locations":
        return (
          <>
            <SelectSitesPage
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              sideTabs={sideTabs}
            />
          </>
        );
      case "Pricing":
        return (
          <>
            <PricingPage
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              sideTabs={sideTabs}
            />
          </>
        );
      case "Signs":
        return (
          <>
            <SelectSignsPage
              setNumberOfSigns={setNumberOfSigns}
              numberOfSigns={numberOfSigns}
              isSignDetails={isSignDetails}
              handleSelectSignsRow={handleSelectSignsRow}
              handleAddSignsModal={handleAddSignsModal}
              setAddSigns={setAddSigns}
              setNavBarBtn={setNavBarBtn}
              activeTab={activeTab}
              sideTabs={sideTabs}
              setActiveTab={setActiveTab}
              selectedSign={selectedSign}
              setSelectedSign={setSelectedSign}
              setSideTabs={setSideTabs}
            />
          </>
        );
      case "EditSigns":
        return (
          <>
            <CreateListingSignsDetails
              activeTab={activeTab}
              sideTabs={sideTabs}
              setActiveTab={setActiveTab}
              numberOfSigns={numberOfSigns}
              selectedSign={selectedSign}
              setSideTabs={setSideTabs}
            />
          </>
        );

      case "Services":
        return (
          <>
            <AddServicesPage
              activeTab={activeTab}
              sideTabs={sideTabs}
              setActiveTab={setActiveTab}
              setSideTabs={setSideTabs}
            />
          </>
        );
      case "Charges":
        return (
          <>
            <AddChargesPage
              activeTab={activeTab}
              sideTabs={sideTabs}
              setActiveTab={setActiveTab}
              setSideTabs={setSideTabs}
            />
          </>
        );
      case "Configurations":
        return (
          <>
            <AllConfiguration
              listingData={ props && props.location && props.location.data ? props.location.data : null}
              setNewListingCreatedModal={setNewListingCreatedModal}
              newListingCreatedModal={newListingCreatedModal}
              activeTab={activeTab}
              sideTabs={sideTabs}
              handleBlueBtn={handleBlueBtn}
            />
          </>
        );

      default:
        return <></>;
    }
  };

  return <>{renderScreen(activeTab)}</>;
};

export default SwitchCreateListingDOM;

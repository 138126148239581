import React, { useState, useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import {
  NotificationSVG,
  UnionSVG,
} from "../../../../../assets/svgComponents/table";
import NavBar from "../../../../../components/navBar";
import { FloatingLableSearchBar } from "../../../../../components/SearchBar/FloatingLableSearchBar";
import { SelectionComponent } from "../../../../../components/SelectionComponent/SelectionComponent";
import {
  deleteUser,
  getAccessTypes,
  userList,
  updateUserStatus,
} from "../../../../../services/Provider/UserManagement/UserManagement";
import { SwitchPage } from "./SwitchPage";
import { useHistory, useLocation } from "react-router";
import { FilterModal } from "../FilterModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// import { toast } from "react-toastify";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import { useToasts } from "react-toast-notifications";
import { SelectComponent } from "../../../../../components/SelectionComponent/SelectComponent";
import { checkPermission } from "../../../../../utils";

export const AllUsers = (props) => {
  const { addToast } = useToasts();
  const tabs = ["PROVIDERS", "SYSTEM USERS"]; // ["ADVERTISERS", "PROVIDERS", "SYSTEM USERS"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [listData, setListData] = useState();
  const [searchString, setSearchString] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [statusUserFilter, setStatusUserFilter] = useState("");
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [accessTypes, setAccessType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const [showTranFilterModal, setShowTranFilterModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [deleteModel, setDeleteModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [activeInactiveModal, setActiveInactiveModal] = useState(false);

  const statusLable = [
    { id: "1", name: "Active" },
    { id: "0", name: "Inactive" },
  ];

  useEffect(async () => {
    if (props.location.state) {
      const { tabValue } = props.location.state;
      setActiveTab(tabValue);
      props.location.state = null;
    }
    const reqBody = {
      page: 0,
      pageSize: 10,
      orderBy: "",
      export: false,
      searchString: "",
      status: 0,
    };
    await getAccessTypes(reqBody)
      .then((res) => {
        if (res.status === 200) {
          setAccessType(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const ApplyBtnForTran = () => {
    setStatusFilter(statusUserFilter);
    setShowTranFilterModal(false);
  };

  const handleBlueBtn = () => {
    history.push(`${location.pathname}/invite-user`);
  };

  const setClearAllFilter = () => {
    setStatusUserFilter("");
  };

  // fetch user data for listing in grid
  useEffect(async () => {
    loadUser();
  }, [
    activeTab,
    searchString,
    statusFilter,
    currentPage,
    pageSize,
    sortColumn,
  ]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    setSortColumn({ column: column, sortDirection: sortDirection });
  };

  const loadUser = async () => {
    setLoading(true);
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: searchString,
      userType:
        activeTab === "ADVERTISERS"
          ? "Customer"
          : activeTab === "PROVIDERS"
            ? "Provider"
            : "Admin",
      status: statusFilter.id,
    };
    // setLoader(true);
    await userList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          setListData(res.data.data);
          setLoading(false);
          // setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const loadExportUser = async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: "",
      userType:
        activeTab === "ADVERTISERS"
          ? "Customer"
          : activeTab === "PROVIDERS"
            ? "Provider"
            : "Admin",
      status: statusFilter.id,
    };
    // setLoader(true);
    await userList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          downloadCSV(res.data.data);
          // setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const searchChange = (e) => {
    setSearchString(e.target.value);
  };

  const handleEditGridCell = (item) => {
    history.push({ pathname: `${location.pathname}/edit-user`, state: item });
  };

  const deleteUserCall = async () => {
    await deleteUser(selectedUser.id)
      .then(() => {
        loadUser();
        setDeleteModel(false);
        addToast("User deleted successfully.", {
          appearance: "success",
        });
        // toast.success("User successfully deleted.");
      })
      .catch(() => {
        setDeleteModel(false);
        addToast("There is an error while deleting the user.", {
          appearance: "warning",
        });
        // toast.error("There is an error while deleting the user.");
      });
  };

  const markActiveInactive = (item, active) => {
    setSelectedUser(item);
    console.log("selected user", item);
    setActiveInactiveModal(true);
  };

  const handleMarkActiveInactiveConfirm = () => {
    const reqBody = {
      userId: selectedUser.id,
      isDisabled: selectedUser.status,
    };
    console.log(reqBody);
    updateUserStatus(reqBody)
      .then(() => {
        setActiveInactiveModal(false);
        loadUser();
        addToast("User status successfully updated.", {
          appearance: "success",
        });
        loadUser();
      })
      .catch(() => {
        addToast("There is an error while updating status.", {
          appearance: "warning",
        });
        // toast.error("There is an error while updating status");
      });
  };

  const handleDeleteGridCell = (item) => {
    setDeleteModel(true);
    setSelectedUser(item);
  };

  const statusOnChange = (e, item) => {
    if (e.target.checked === true) {
      setStatusUserFilter(e.target.value);
    } else {
    }
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array.records);
    if (csv == null) return;

    const filename =
      "export-" + activeTab.toLowerCase().split("").join("") + ".csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const loadExportData = () => {
    loadExportUser();
  };

  return (
    <>
      <NavBar
        blueBtnText={activeTab === "SYSTEM USERS" ? `Send Invite` : false}
        navHeading="User Management"
        subHeading=""
        handleBlueBtn={handleBlueBtn}
        showButton={checkPermission("UserManagement", "Full")}
        notificationSVG={<NotificationSVG />}
      />
      <div className="gridSection p-20">
        <div className="tableActions_container m-0">
          <Row className="d-flex align-items-center g-0">
            <Col className="d-flex">
              <ul className="tableActions_tabs">
                {tabs.map((item) =>
                  item === activeTab ? (
                    <li
                      className="tableActions_tab active"
                      onClick={() => {
                        setActiveTab(item);
                      }}
                    >
                      {item} <hr className="tableActions_tab_hr" />
                    </li>
                  ) : (
                    <li
                      className="tableActions_tab"
                      onClick={() => {
                        setActiveTab(item);
                      }}
                    >
                      {item}
                    </li>
                  )
                )}
              </ul>
            </Col>

            <FilterModal
              show={showTranFilterModal}
              accessTypeLable={accessTypes}
              statuslable={statusLable}
              handleApplyBtn={ApplyBtnForTran}
              setShowFilterModal={setShowTranFilterModal}
              statusOnChange={statusOnChange}
              statusValue={statusUserFilter}
              setClearAllFilter={setClearAllFilter}
            />
          </Row>
        </div>
        <div className="card mt-4 overflow-hidden customtable">
          <div className="d-flex justify-content-start p-2">
            <div className="d-flex serachFilterContainer me-3 w-auto">
              <FloatingLableSearchBar
                inputFieldSize="fieldSize"
                handleOnchange={searchChange}
                label="Search Name, email"
              />
            </div>
            <div className="d-flex serachFilterContainer w-auto listingFiltersRow">
              <div className="home_filter_cntr">
                <label
                  htmlFor="cost_ddl"
                  className="m-0 textGray2 text-sm home_lbl"
                >
                  Status
                </label>
                {activeTab == "PROVIDERS" ? (
                  <SelectComponent
                    className="customSelectDrop"
                    label="Status"
                    value={statusFilter}
                    defaultValue={statusFilter}
                    handleOnchange={(e) => {
                      setStatusFilter(e);
                    }}
                    option={[
                      { id: "0", name: "All" },
                      { id: "1", name: "Approved" },
                      { id: "2", name: "Rejected" },
                      { id: "3", name: "Pending" },
                    ]}
                  ></SelectComponent>
                ) : (
                  <SelectComponent
                    className="customSelectDrop"
                    label="Status"
                    value={statusFilter}
                    defaultValue={statusFilter}
                    handleOnchange={(e) => {
                      setStatusFilter(e);
                    }}
                    option={[
                      { id: "", name: "All" },
                      { id: "1", name: "Active" },
                      { id: "0", name: "Inactive" },
                    ]}
                  ></SelectComponent>
                )}
              </div>
            </div>
          </div>

          {loader ? null : (
            // <Spinner
            //   animation="border"
            //   role="status"
            //   style={{
            //     marginTop: "20%",
            //     marginLeft: "50%",
            //     width: "50px",
            //     height: "50px",
            //     color: "#F99300",
            //   }}
            // ></Spinner>
            <SwitchPage
              activeTab={activeTab}
              userData={listData}
              handleEditGridCell={handleEditGridCell}
              handleDeleteGridCell={handleDeleteGridCell}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              pageSize={pageSize}
              handleSort={handleSort}
              markActiveInactive={markActiveInactive}
              searchString={searchString}
              statusFilter={statusFilter}
              loadExportData={loadExportData}
            />
          )}
        </div>
      </div>
      {deleteModel && (
        <ConfirmModal
          title="Delete User"
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          cancelText="No"
          handleClose={() => setDeleteModel(false)}
          handleConfirm={deleteUserCall}
          width="300px"
        >
          <div className="childs">
            <p>Are you sure you want to delete this user?</p>
          </div>
        </ConfirmModal>
      )}

      {activeInactiveModal && (
        <ConfirmModal
          title={
            selectedUser?.status === 1
              ? "Mark Inactive User"
              : "Mark Active User"
          }
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          cancelText="No"
          handleClose={() => setActiveInactiveModal(false)}
          handleConfirm={handleMarkActiveInactiveConfirm}
          width="300px"
        >
          <div className="childs">
            <p>
              Do you want to{" "}
              {selectedUser?.status === 1 ? "Inactive" : "Active"}{" "}
              {selectedUser.name}?{" "}
            </p>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

import React from "react";
import { Col, Card } from "react-bootstrap";
import {
  DashboardIcon,
  DbOrderIcon,
  DbProviderIcon,
  DbRevenueIcon,
  HelpIcon,
  LogoutIcon,
  OrderIcon,
  ProfileIcon,
  SettingsIcon,
  UserMgmtIcon,
} from "../../assets/svgComponents/inventorySVG";

export const CustomTile = ({
  heading,
  amount,
  description,
  active,
  type
}) => {
  const bgColor = active ? "#FFB141" : "#fff";
  const color = active ? "#fff" : "#000";
  return (
    <>
      <div className="dash-head d-flex justify-content-between">
        <h3 className="text-xxs">{heading}</h3>
        {type == 1 ? (
          <p>
            <DbRevenueIcon />
          </p>
        ) : null}
        {type == 5 ? (
          <p>
            <DbProviderIcon />
          </p>
        ) : null}
        {type == 6 ? (
          <p>
            <DbOrderIcon />
          </p>
        ) : null}
      </div>
      <div className="dash-body">
        <p className="font-28 weight-700">{amount}</p>
        <p className="text-xxs ">{description}</p>
      </div>
    </>
  );
};

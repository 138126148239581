import React from "react";
import { InputGroup, FormControl, FloatingLabel } from "react-bootstrap";
import { SVGSearch } from "../../assets/svgComponents/svg";

export const FloatingLableSearchBar = ({
  inputFieldSize,
  label,
  handleOnchange,
  handleSearchClick,
  searchTerm,
  searchafter,
  className,
}) => {
  return (
    <FloatingLabel
      controlId="floatingInput"
      label={label ? label : "Search"}

      className={`${inputFieldSize ? inputFieldSize : ""} myinput ${className ? className : ""}`}
    >
      <FormControl
        aria-label="Dollar amount (with dot and two decimal places)"
        placeholder="Enter to Search"
        className="serchInput"
        onChange={(e) => handleOnchange(e)}
        value={searchTerm}
      />
      <InputGroup.Text
        id="table-search"
        className={`serchIcon ${searchafter ? searchafter : ""}`}
        onClick={handleSearchClick}
      >
        <SVGSearch />
      </InputGroup.Text>
    </FloatingLabel>
  );
};

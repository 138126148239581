import { http } from "./httpService";

export function uploadFileMethod(data) {
  return http.post(`uploadfile`, data);
}

export function uploadProof(data) {
  return http.post(`uploadfile`, data);
}

export function veryfyOrganozaion(data) {
  return http.post(`api/Provider/Create`, data);
}
export function resubmitOrganizationDetails(data) {
  return http.post(`/api/Provider/Resubmit`, data);
}
export function getEnvironment() {
  return http.get(`api/Environment`);
}

export function getEnvironmentType(type) {
  return http.get(`/api/Environment/${type}/Options`);
}

export function getProviderDashBoard(reqBody){  
  return http.get(`/api/Dashboard/GetProviderDashBoard${reqBody}` );
}


export function getSiteAnalytics(reqBody){  
  return http.get(`/api/Dashboard/GetSiteAnalytics${reqBody}` );
}

export function getReviewBySite(siteId) {
  return http.get(`/api/Review/Site/${siteId}`);
}

export function addSite(data) {
  return http.post(`api/Site/Create`, data);
}

export function addSign(data) {
  return http.post(`api/Sign/postSignDetails`, data);
}

export function getSiteList() {
  let reqBody = {
    page: 0,
    pageSize: 0,
    orderBy: "string",
    export: true,
    searchString: "",
    environments: [],
    status: true,
  };
  return http.post(`/api/Site/List`,reqBody);
}
export function getDetailSiteList(reqBody) {  
  return http.post(`/api/Review/AdminSiteSignReview`,reqBody);
}

export const currentUser=()=>{
  return http.get('/api/User/CurrentUser')
}

export const defaultAddress=()=>{
  return http.get('/api/User/getDefaultAddress')
}

export const getAddressList=(id)=>{
  return http.get(`/api/User/${id}/BillingAddressDetailsList`)
}

export const getOrderList = () => {
  let reqBody = {
    page: 0,
    pageSize: 0,
    orderBy: "string",
    export: true,
    searchString: "",
    environments: [],
    status: true,
    fromDate: "2022-04-13T15:18:07.000Z",
    toDate: "2022-04-13T15:18:07.000Z",
    providerIds: [     
    ],
    statusIds: [
      0
    ],
    mediaTypeIds: [
      0
    ],
    sortBy: 0
  };

  

  return http.post('/api/Order/List',reqBody);
}

export function getLatestOrders()
{
  return http.get('/api/Order/GetTopTenProviderOrder');
}

export function getLatestPayments()
{
  return http.get('/api/Order/GetTopTenProviderPayment');
}

export function getLeastBookedSite(filters) {
  //let filters = 1;
  return http.post(`/api/Site/GetLeastBookedSite?filter=${filters}`);
}

export const getProvider=()=>{
  return http.get(`/api/Provider`)
}

export function getUpdateOnBoardingStepDone(bodyData){
  return http.patch(`/api/Provider`,bodyData)
}

export function deletecard(id){
  return http.delete(`/api/User/DeleteCard/${id}`)
}
export function makeDefoulAddress(id){
  return http.post(`/api/User/setDefaultAddress/${id}`)
}

export function makeDefoulPaymentCard(id){
  return http.post(`/api/User/SetDefaultPaymentCard/${id}`)
}

export function updatePaymentCardDetails(formData){
  return http.post(`/api/User/UpdatePaymentCardDetails`,formData)
}

export function updateAddress(id,formData){
  return http.patch(`/api/User/UpdateAddress/${id}`,formData)
}

export function removeAddress(id){
  return http.delete(`/api/User/DeleteAddress/${id}`)
}
export function verifyOTP(otp){
  return http.post(`/api/User/VerifiedContactNumber?OTP=${otp}`)
}

export function genrateOTP(otp){
  return http.post(`/api/User/GenerateOTP`)
}
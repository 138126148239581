import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import "react-confirm-alert/src/react-confirm-alert.css";
import DataTable from "react-data-table-component";
import { ExportCSVSVG} from "../../../../assets/svgComponents/table"; 
import { getOrderAsync,getOrdersAsync,setSearchString } from "../../../../services/reducers/orders-reducers";

export const TransactionsPage = (props) => {
  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.transactions.transactions);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.transactionId,
      sortable: true,
      sortField: "transactionId",
    },
    {
      name: "Date",
      selector: (row) => row.transactionDate,
      sortable: true,
      sortField: "TransactionDate",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "name",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      sortField: "Amount",
    },
    {
      name: "Transaction Type",
      selector: (row) => row.typeText,
      sortable: true,
      sortField: "TypeText",
    },
    {
      name: "Order Id",
      cell:(row) => <button onClick={()=>{rowClickHandler(row);}} id={row.id}>{row.orderId}</button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      selector: (row) => row.orderId,
      sortable: true,
      sortField: "OrderId",
    }, 
    {
      name: "User Type",
      selector: (row) => row.userType,
      sortable: true,
      sortField: "UserType",
    },
    {
      name: "Payment For",
      selector: (row) => row.paymentFor,
      sortable: true,
      sortField: "PaymentFor",
    }, 
  ];

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    setSortColumn({ column: column, sortDirection: sortDirection });
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export-transaction.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <button
      className="blueBgButton"
      onClick={(e) => onExport(e.target.value)}
    >
      <span className="btnSvg"><ExportCSVSVG fill="#FFFFFF" /></span>
      Export CSV
    </button>
  );
  const actionsMemo = useMemo(
    () => <Export onExport={() => downloadCSV(transactions.records)} />,
    []
  );

  const rowClickHandler = async(transaction)=>{
    if(transaction.orderId && props.tabChange){
      await dispatch(getOrderAsync(transaction.orderId));
      await dispatch(setSearchString(transaction.orderId));
      // await dispatch(getOrdersAsync({
      //   page: 1,
      //   pageSize: 10,
      //   orderBy: "",
      //   export: false,
      //   searchString: transaction.orderId, 
      // }));
      props.tabChange("ORDERS");
    }
  }

  return (
    transactions &&
    transactions.records && (
        <DataTable
          // title="SysUsers"
          columns={columns}
          data={transactions.records}
          progressPending={loading}
          paginationDefaultPage={currentPage}
          pagination
          paginationServer
          paginationTotalRows={transactions.totalRecords}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={pageSize}
          selectableRows
          dense
          onSort={handleSort}
          sortServer
          actions={actionsMemo}
        />
    )
  );
};

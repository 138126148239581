import Grid from "../../../../../components/grid/Grid";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { siteListings } from "../../../../../services/Provider/Configurator/Listings";
import SitesPage from "./pages/SitesPage";
import SignsPage from "./pages/SignsPage";
import ServicesPage from "./pages/ServicesPage";
import ChargesPage from "./pages/ChargesPage";

const Tabs = ["LOCATIONS", "SIGNS", "SERVICES", "CHARGES"];

const SwitchTabDom = (props) => {
  const { searchInput } = props;
  // console.log(props.servicesData, "serv char");

  // // gird action handle for services
  // const handleViewServicesGridCell = () => {
  //   props.setIsRouteChange(true);
  //   props.setGirdActionPage("viewService");
  //   history.push(`${location.pathname}/view-services`);
  //   // history.push("/inventory/addSite");
  // };
  // const handleEditServicesGridCell = (e) => {
  //   console.log('e.target.value', e.target.value)
  //   props.setIsRouteChange(true);
  //   props.setGirdActionPage("editServices");
  //   history.push(`${location.pathname}/edit-services`);
  //   // history.push("/inventory/addSite");
  // };
  // const handleDeleteServicesGridCell = () => {
  //   alert("delete sign");
  // };

  console.log('active tab',props.activeTab);
  switch (props.activeTab) {
    case Tabs[0]:
      return (
        <>
          <SitesPage
            setIsRouteChange={props.setIsRouteChange}
            setGirdActionPage={props.setGirdActionPage}
            searchInput={searchInput}
          />
        </>
      );
    case Tabs[1]:
      return (
        <>
          <SignsPage
            setIsRouteChange={props.setIsRouteChange}
            setGirdActionPage={props.setGirdActionPage}
            activeTab={props.activeTab}
            searchInput={searchInput}
          />
        </>
      );
    case Tabs[2]:
      return (
        <>
          <ServicesPage
            setIsRouteChange={props.setIsRouteChange}
            setGirdActionPage={props.setGirdActionPage}
            activeTab={props.activeTab}
            searchInput={searchInput}
          />
        </>
      );
    case Tabs[3]:
      return (
        <>
          <ChargesPage
            setIsRouteChange={props.setIsRouteChange}
            setGirdActionPage={props.setGirdActionPage}
            activeTab={props.activeTab}
            searchInput={searchInput}
          />
        </>
      );
    default:
      return <></>;
  }
};

export default SwitchTabDom;

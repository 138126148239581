import React, { useEffect, useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import { SwitchPage } from "./SwitchPage";
import NavBar from "../../../../../../../components/navBar";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import { SelectionComponent } from "../../../../../../../components/SelectionComponent/SelectionComponent";
import {
  AvailableSignServiceCharges,
  getSiteDetails,
  siteBulkStatusUpdate,
} from "../../../../../../../services/Provider/Configurator/Inventory";
import { useToasts } from "react-toast-notifications";
import UpdatesModal from "../../../../../../../components/profile/Modal/UpdatesModal";
import { checkPermission } from "../../../../../../../utils";

export const ViewSiteInfo = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const tabs = ["INFORMATION", "LISTINGS", "USAGE", "INSIGHTS"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [siteDetails, setSiteDetails] = useState();
  const [siteListingDetails, setSiteListingDetails] = useState();
  const [inactiveOfferModaShow, setInactiveOfferModaShow] = useState(false);
  const [siteStatus, setSiteStatus] = useState();
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);

  useEffect(async () => {
    if (props.location.state) {
      const { id } = props.location.state;
      console.log(id, "id");
      await getSiteDetails(id)
        .then((res) => {
          if (res.status === 200) {
            console.log("site Data", res.data);
            setSiteDetails(res.data);
            setSiteStatus(res.data.status);
          }
        })
        .catch((err) => {
          addToast(err.response.data.title, { appearance: "warning" });
          console.log(err);
        });
      await AvailableSignServiceCharges(id)
        .then((res) => {
          if (res.status === 200) {
            console.log("listing data", res.data);
            setSiteListingDetails(res.data);
          }
        })

        .catch((err) => {
          addToast(err.response.data.title, { appearance: "warning" });
          console.log(err);
        });
    }
  }, [props.location.state]);

  const handleEditDetailsBtn = () => {
    history.push("/provider/configurator/inventory/edit-site");
  };

  const makeSiteActiveInactive = () => {
    setInactiveOfferModaShow(true);
  };

  const setInactiveOffer = () => {
    setInactiveOfferModaShow(false);
    if (props.location.state) {
      const { id } = props.location.state;
      let reqBody = [
        {
          id: id,
          status: siteStatus ? 0 : 1,
        },
      ];
      siteBulkStatusUpdate(reqBody)
        .then((res) => {
          console.log("resaddsite", res.status);
          if (res.status == 202) {
            setSiteStatus(!siteStatus);
            if (siteStatus) {
              addToast("Site inactive successfully.", {
                appearance: "error",
              });
            } else {
              addToast("Site active successfully.", {
                appearance: "success",
              });
            }
          }
        })
        .catch((err) => {
          console.log("erraddsite", err);
          addToast("Error while updating site.", {
            appearance: "error",
          });
        });
    }
  };

  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <div className="AddSiteContaimer">
      <NavBar
        blueBtnText="Edit Details"
        whiteBtnText={siteStatus ? "Make Location Inactive" : "Make Location Active"}
        notificationSVG={<NotificationSVG />}
        navHeading={siteDetails?.name}
        subHeading="Configurator • Inventory"
        subActiveHeading="Location Details"
        handleBlueBtn={handleEditDetailsBtn}
        handleWhitebtn={makeSiteActiveInactive}
        handleNotificationSVG={handleNotificationSVG}
        showButton={checkPermission("Configurator", "Full")}
      />
      <div
        className={
          activeTab == "USAGE" || activeTab == "INSIGHTS"
            ? "p-20"
            : "p-20 pe-0 pb-0 gridSection"
        }
      >
        <div className="tableActions_container m-0">
          {/* <Row> */}
          <Col className="d-flex align-items-center">
            <ul className="tableActions_tabs">
              {tabs.map((item) =>
                item === activeTab ? (
                  <li
                    className="tableActions_tab active"
                    onClick={() => {
                      setActiveTab(item);
                    }}
                  >
                    {item} <hr className="tableActions_tab_hr" />
                  </li>
                ) : (
                  <li
                    className="tableActions_tab"
                    onClick={() => {
                      setActiveTab(item);
                    }}
                  >
                    {item}
                  </li>
                )
              )}
            </ul>
          </Col>
          {console.log(activeTab, "activeTab")}
          {/* {activeTab == "USAGE" || activeTab == "INSIGHTS" ? (
              <Col className="col-md-3 mt-4">
                <div className="sortingBoxContainer">
                  <SelectionComponent option={[]} />
                </div>
              </Col>
            ) : (
              ""
            )} */}
          {/* </Row> */}
        </div>

        <div className="containerSection d-flex h-100 mt-3">
          <SwitchPage
            activeTab={activeTab}
            siteDetails={siteDetails}
            siteListingDetails={siteListingDetails}
          />
        </div>
      </div>
      {inactiveOfferModaShow && (
        <ConfirmModal
          // setConfirmModal={setAddSignModal}
          //   confirm={handleAddSignsModal}
          title={siteStatus ? "Inactive Site" : "Active Site"}
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          cancelText="No"
          handleClose={() => setInactiveOfferModaShow(false)}
          handleConfirm={setInactiveOffer}
        >
          <div className="childs text-center">
            <p className="newListingCreated_description">
              Would you like to {siteStatus ? "inactive" : "active"} this site?
            </p>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </div>
  );
};

import React, { useEffect } from "react";
import { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { UploadLogo } from "../../assets/svgComponents/svg";
import http from "../../services/httpService";

export const UploadMultipleFiles = ({
  uploadBoxSize,
  btnlable,
  placeholder,
  uploadMultipleProof,
  id,
  uploadedData,
  allowedFiles
}) => {
  const [name, setName] = useState(placeholder);
  useEffect(()=>{
    if(uploadedData && uploadedData?.length>0){
      let names ="";
      let isbreak = false;
      for(let i=0; i<uploadedData.length;i++)
      {
        if(i === 0) {
          names = uploadedData[i].fileName;
        } else if (i < 2) {
          names = names+', '+uploadedData[i].fileName;
        } else {
          names = names+' and '+(uploadedData.length-2)+' more';
          isbreak=true;
        }
        if(i === (uploadedData.length-1) || isbreak){
          console.log(names)
          setName(names)
          break;
        }
      }
    }
  },[uploadedData])
  return (
    <div className={`uploadBox ${uploadBoxSize}`}>
      <span className="fileName">
        {name}
      </span>
      <label htmlFor={id} className="btn btnLable p-0">
        <div>
          <UploadLogo />
        </div>
        <p>{btnlable}</p>
      </label>
      <input
        id={id}
        type="file"
        multiple
        style={{ display: "none", padding: "0" }}
        placeholder={name}
        onChange={(e) => uploadMultipleProof(e)}
        accept={allowedFiles}
      />
    </div>
  );
};

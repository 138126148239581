import moment from "moment";
import React from "react";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import { PassThroughSVG } from "../../../../../assets/svgComponents/inventorySVG";
import StarRatings from 'react-star-ratings';
import { checkPermission } from "../../../../../utils";

export const Reviews = ({ reviews, activeTab, loadReviews, deleteReview, blockReview }) => {
  console.log(reviews, activeTab, 'reviews')
  return (
    <>
      {reviews != null && reviews.length > 0 ? (
        reviews.map((item) => {
          return (
            <div className="d-flex align-items-start p-20 justify-content-between review" style={{ borderBottom: "1px #d3d3d3 solid" }}>

              <div className="d-flex align-items-start">
                <div className="review_profile_image" style={{ height: '33px', width: '33px' }}>
                  {/* <img src={"https://gopinit.blob.core.windows.net/image-container/"+item.image} alt="profileImag" /> */}
                  <img src="https://gopinit.blob.core.windows.net/image-container/e7301e92-d1e1-420d-8283-4d952f135eaf" alt="" />
                </div>
                <div className="ms-2 flex-1">
                  <h3 className="mb-0 text-sm">{item.name}</h3>
                  <div className="d-flex align-items-center mb-3">
                    <span className="me-5 d-inline-block text-xxs text-gray-1400">{moment(item.createdOn).format("DD MMMM YYYY")}</span>
                    <StarRatings
                      rating={item.rating}
                      starRatedColor="#FFB141"
                      numberOfStars={5}
                      name='rating'
                      starDimension="16px"
                      starSpacing="2px"
                      starHoverColor="#FFB141"
                    />
                  </div>
                  <div className="text-gray-1400 text-xs">

                    {item.comment}
                  </div>
                </div>
              </div>
              <div>
                <span className="gridTableBody_td_passThrough">

                  {
                    activeTab == "BY USERS"
                      ?
                      <DropdownButton
                        align="end"
                        className="dropDownBtn"
                        title={<PassThroughSVG />}
                      >
                        <Dropdown.Item
                          onClick={(e) => loadReviews(item.listingId, 'BY SITES & SIGNS')}
                        >
                          View Site Review
                        </Dropdown.Item>
                        {checkPermission("ReviewsAndRatings", "Full") && (
                          <Dropdown.Item
                            onClick={() => deleteReview(item.id)}
                          >
                            Delete Review
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                      :
                      <DropdownButton
                        align="end"
                        className="dropDownBtn"
                        title={<PassThroughSVG />}
                      >
                        <Dropdown.Item
                          onClick={(e) => loadReviews(item.createdById, 'BY USERS')}
                        >
                          View User Activity
                        </Dropdown.Item>
                        {checkPermission("ReviewsAndRatings", "Full") && (
                          <Dropdown.Item
                            onClick={(e) => blockReview('BY SITE', item.userId)}
                          >
                            Block User
                          </Dropdown.Item>
                        )}
                        {checkPermission("ReviewsAndRatings", "Full") && (
                          <Dropdown.Item
                            onClick={() => deleteReview(item.id)}
                          >
                            Delete Review
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                  }

                </span>
              </div>
            </div>
          );
        })
      ) : (
        <div className="d-flex align-items-start p-20 justify-content-between review">
          <h4 className="pt-5 pb-5">No Reviews Available</h4>
        </div>
      )}
    </>
  );
};

import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Windows } from "react-bootstrap-icons";
import { useHistory, useLocation } from "react-router";
import { SpringSVG } from "../../../../assets/svgComponents/svg";
import { NotificationSVG } from "../../../../assets/svgComponents/table";
import ConfirmModal from "../../../../components/confirmModal/ConfirmModal";
import NavBar from "../../../../components/navBar";
import ProjectContext from "../../../../context/ProjectContext";
import {
  currentUser,
  getProvider,
  getUpdateOnBoardingStepDone,
} from "../../../../services/Provider";
import "./ftue.scss";
import { VeryfiedOrganizationModal } from "./Modal/VeryfiedOrganizationModal";

export const FTUE = () => {
  const { handleActiveTab } = useContext(ProjectContext);
  const history = useHistory();
  const location = useLocation();
  const [organizationBtn, setOrganizationBtn] = useState(true);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [verifyOrg, setVerifyOrg] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isOrgDetailsAdded, setIsOrgDetailsAdded] = useState(false);
  const [isPendingOrgVerification, setIsPendingOrgVerification] =
    useState(false);
  const [curUser, setCurUser] = useState();
  const [curStepCount, setCurStepCount] = useState();
  const [currentUserData, setCurrentUserData] = useState(0);
  const [reloadCurrentUser, setReloadCurrentUser] = useState(false);
  const [isSendDetailsAgainApi, setIsSendDetailsAgainApi] = useState(false);
  // const [stepCount, setStepCount] = useState(
  //   parseInt(localStorage.getItem("onBoardingStepDone"))
  // );
  let tempStepCount = parseInt(localStorage.getItem("onBoardingStepDone"));

  const handleConfirmModal = () => {
    // localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
    setConfirmModal(false);
    setOrganizationBtn(false);
    setVerifyOrg(true);
    // setSiteBtn(true);
  };

  const handleSiteToServe = () => {
    history.push("/provider/add-site");
  };

  // handle for sign serice charges
  const handleSignServiceCharges = () => {
    handleActiveTab("SIGNS");
    history.push("/provider/configurator/inventory");
  };

  // handle for sign service and charge skip
  const handleStepIncrease = () => {
    // alert(tempStepCount);
    localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
    let bodyData = [
      {
        path: "/onBoardingStepDone",
        op: "add",
        value: tempStepCount + 1,
      },
    ];
    setReloadCurrentUser(true);
    getUpdateOnBoardingStepDone(bodyData)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // handle for create listing
  const createListing = () => {
    history.push("/provider/configurator/listings");
  };

  // handle for create offer
  const createOffer = () => {
    history.push("/provider/configurator/offer-management/add-offer");
  };

  // handle for switch to dashboard
  const handleSwitchToDashBoard = () => {
    localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
    let bodyData = [
      {
        path: "/onBoardingStepDone",
        op: "add",
        value: tempStepCount + 1,
      },
    ];
    setReloadCurrentUser(true);
    getUpdateOnBoardingStepDone(bodyData)
      .then((res) => {
      })
      .catch((err) => {
      });
    // history.push(`/provider/dashboard`);
    window.location.reload()
  };

  useEffect(() => {
    currentUser().then((res) => {
      if (res.status == 200) {
        // res.data.provider;
        setReloadCurrentUser(false);
        setCurrentUserData(res.data);
        //console.log('currentUserData',res.data);
        setCurStepCount(
          res.data?.provider
            ? res.data.provider.onBoardingStepDone
            : currentUserData
        );             
        // setCurUser(res.data);
        res.data?.provider?.onBoardingStepDone >= 6 &&
          history.push("/provider/dashboard");
        // res.data.onBoardingStepDone == 6 && history.push("/provider/dashboard");
      }
    });
  }, [tempStepCount, reloadCurrentUser]);

  // useEffect(() => {
  //   let bodyData = [
  //     {
  //       path: "/onBoardingStepDone",
  //       op: "add",
  //       value: tempStepCount,
  //     },
  //   ];
  //   getUpdateOnBoardingStepDone(bodyData)
  //     .then((res) => {
  //       if (res.status) {
  //         console.log("res", res);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // }, [tempStepCount]);

  return (
    <>
      <div className="main_SubSection">
        <NavBar notificationSVG={<NotificationSVG />} navHeading="Account Setup" showButton={true}  />
        <div className="dashbord_container">
          <div className="details_container">
            <div className="mainHeading">
              <h1 className="">
                Welcome to GoPinIt, {currentUserData?.name}! Let's get you
                moving!
              </h1>
            </div>
            <div className="all_details_container">
              <div className="container01">
                <h6>
                  <span
                    className={
                      // curUser && curUser.onBoardingStepDone > 0
                      curStepCount && curStepCount > 0 ? "numberBox1" : ""
                    }
                  >
                    {/* {curUser && curUser.onBoardingStepDone > 0 ? "1." : "1."} */}
                    {curStepCount && curStepCount > 0 ? "1." : "1."}
                  </span>
                  &nbsp;Submit your Organization Details for verification{" "}
                  <span
                    className={`ms-2 ${
                      // curUser && curUser.onBoardingStepDone > 0
                      curStepCount && curStepCount > 0 ? "" : "displayNone"
                    }`}
                  >
                    <SpringSVG width="19" height="19" />
                  </span>
                </h6>
                <button
                  onClick={() =>
                    currentUserData?.provider
                      ? currentUserData?.provider?.verified
                        ? handleStepIncrease()
                        : currentUserData?.provider?.disapproveReason != null &&
                          currentUserData?.provider?.disapproveReason != ""
                        ? setIsOrgDetailsAdded(true)
                        : setIsPendingOrgVerification(true)
                      : setShowVerificationModal(true)
                  }
                  className={`py-2 mt-3 ${currentUserData?.provider==null?"blueBgButton":currentUserData?.provider?.verified?"blueBgButton":currentUserData?.provider?.disapproveReason != null &&
                    currentUserData?.provider?.disapproveReason != ""?"redBgButton":"secondaryBtn" } ${
                    // curUser && curUser.onBoardingStepDone == 0
                    tempStepCount == 0 ? "" : "displayNone"
                  }`}
                  // style={{
                  //   display: tempStepCount == 0 ? "show" : "none",
                  // }}                
                >
                  {currentUserData?.provider?.verified
                    ? "Go to Next Step"
                    : currentUserData?.provider?.disapproveReason != null &&
                    currentUserData?.provider?.disapproveReason != ""?"Verification Rejected":"Verify your organization"}
                </button>
              </div>
              <div className="container01">
                <h6>
                  <span
                    className={
                      curStepCount && curStepCount >= 2 ? "numberBox1" : ""
                    }
                  >
                    {curStepCount && curStepCount >= 2 ? "2." : "2."}
                  </span>
                  &nbsp;Configure Locations You Serve
                  <span
                    className={`ms-2 ${
                      curStepCount && curStepCount >= 2 ? "" : "displayNone"
                    }`}
                  >
                    <SpringSVG width="19" height="19" />
                  </span>
                </h6>
                <button
                  onClick={handleSiteToServe}
                  className={`blueBgButton py-2 mt-3 fontWeightBold ${
                    curStepCount && curStepCount == 1 ? "" : "displayNone"
                  }`}
                >
                  Add Locations
                </button>
              </div>
              <div className="container01">
                <h6>
                  <span
                    className={
                      curUser && curUser.onBoardingStepDone >= 3
                        ? "numberBox1"
                        : ""
                    }
                  >
                    {curUser && curUser.onBoardingStepDone >= 3 ? "3." : "3."}
                  </span>
                  &nbsp;Tell Users about the Signs and services you offer at
                  these locations...
                  <span
                    className={`ms-2 ${
                      curUser && curUser.onBoardingStepDone >= 3
                        ? ""
                        : "displayNone"
                    }`}
                  >
                    <SpringSVG width="19" height="19" />
                  </span>
                </h6>
                <button
                  className={`blueBgButton py-2 mt-3 ${
                    curStepCount == 2 ? "" : "displayNone"
                  }`}
                  onClick={handleSignServiceCharges}
                >
                  Add signs, services and charges
                </button>
                <button
                  className={`whiteBgButton ms-3 ${
                    curStepCount == 2 ? "" : "displayNone"
                  }`}
                  onClick={() => handleStepIncrease()}
                >
                  Skip
                </button>
              </div>
              <div className="container01">
                <h6>
                  {/* 4.Create listings and show users what you offer */}
                  <span
                    className={
                      curUser && curUser.onBoardingStepDone >= 4
                        ? "numberBox1"
                        : ""
                    }
                  >
                    {curUser && curUser.onBoardingStepDone >= 4 ? "4." : "4."}
                  </span>
                  {curUser && curUser.onBoardingStepDone >= 4
                    ? "Crete listing and show users what you offer"
                    : "Define Pricing for your Listing"}
                  <span
                    className={`ms-2 ${
                      curUser && curUser.onBoardingStepDone >= 4
                        ? ""
                        : "displayNone"
                    }`}
                  >
                    <SpringSVG width="19" height="19" />
                  </span>
                </h6>
                <button
                  className={`blueBgButton py-2 mt-3 ${
                    curStepCount && curStepCount == 3 ? "" : "displayNone"
                  }`}
                  onClick={createListing}
                >
                  Create Listing
                </button>
              </div>
              <div className="container01">
                <h6>
                  <span
                    className={
                      curUser && curUser.onBoardingStepDone >= 5
                        ? "numberBox1"
                        : ""
                    }
                  >
                    {curUser && curUser.onBoardingStepDone >= 5 ? "5." : "5."}
                  </span>
                  &nbsp;Create Offers to attract customers
                  <span
                    className={`ms-2 ${
                      curUser && curUser.onBoardingStepDone >= 5
                        ? ""
                        : "displayNone"
                    }`}
                  >
                    <SpringSVG width="19" height="19" />
                  </span>
                </h6>
                <button
                  className={`blueBgButton py-2 mt-3 ${
                    curStepCount && curStepCount == 4 ? "" : "displayNone"
                  }`}
                  onClick={createOffer}
                >
                  Create offer
                </button>
                <button
                  className={`whiteBgButton ms-3 ${
                    curStepCount == 4 ? "" : "displayNone"
                  }`}
                  onClick={() => handleStepIncrease()}
                >
                  Skip
                </button>
              </div>
              <div className="container01">
                <h6 className="mb-3">
                  {/* 6.Start Counting Cash */}
                  <span
                    className={
                      curUser && curUser.onBoardingStepDone == 5
                        ? "numberBox1"
                        : ""
                    }
                  >
                    {curUser && curUser.onBoardingStepDone >= 6 ? "6." : "6."}
                  </span>
                  &nbsp;Go Live and Start Counting Cash
                  <span
                    className={`ms-2 ${
                      curUser && curUser.onBoardingStepDone >= 6
                        ? ""
                        : "displayNone"
                    }`}
                  >
                    <SpringSVG width="19" height="19" />
                  </span>
                </h6>
                <div>
                  <button
                    onClick={() => handleSwitchToDashBoard()}
                    className={`blueBgButton py-2  ${
                      curStepCount && curStepCount == 5 ? "" : "displayNone"
                    }`}
                  >
                    Switch to Dashboard
                  </button>
                  {/* <button
                    className={`blueBgButton py-2 ms-3 ${
                      curStepCount && curStepCount == 5 ? "" : "displayNone"
                    }`}
                  >
                    Skip
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showVerificationModal && (
          <VeryfiedOrganizationModal
            isSendDetailsAgainApi={isSendDetailsAgainApi}
            setIsSendDetailsAgainApi={setIsSendDetailsAgainApi}
            setShow={setShowVerificationModal}
            setConfirmModal={setConfirmModal}
            setReloadCurrentUser={setReloadCurrentUser}
            currentUserData={currentUserData}
          />
        )}
        {confirmModal && (
          <ConfirmModal
            title="Detail 
         Sent"
            isModalHeader={true}
            isFooterBtns={false}
            confirmText="Ok"
            cancelText="No"
            handleClose={() => setConfirmModal(false)}
            handleConfirm={handleConfirmModal}
            width="400px"
          >
            <div className="childs text-center">
              <p className="newListingCreated_description">
                We will review your Organization Details
                <br />
                and notify you in a day.
              </p>
            </div>
          </ConfirmModal>
        )}
        {isOrgDetailsAdded && (
          // {}
          <ConfirmModal
            title="Rejection Reason"
            isModalHeader={true}
            isFooterBtns={true}
            cancelText="Cancel"
            confirmText="Resubmit"
            handleClose={() => setIsOrgDetailsAdded(false)}
            handleCancel={() => {
              // setShowVerificationModal(true);
              setIsOrgDetailsAdded(false);
            }}
            handleConfirm={() => {
              setIsSendDetailsAgainApi(true);
              setShowVerificationModal(true);
              setIsOrgDetailsAdded(false);
            }}
            width="400px"
          >
            <div className="pb-4">
              <p className="newListingCreated_description ms-4">
                {currentUserData?.provider?.disapproveReason}
                {/* We will review your organization details
                <br />
                and notify you in a day. */}
              </p>
            </div>
          </ConfirmModal>
        )}
        {isPendingOrgVerification && (
          // {}
          //Verify Organization
          <ConfirmModal
            title="Approval Status"
            isModalHeader={true}
            isFooterBtns={false}
            confirmText="Close"
            handleClose={() => setIsPendingOrgVerification(false)}
            handleConfirm={() => setIsPendingOrgVerification(false)}
            // cancelText="Send details again"
            width="400px"
          >
            <div className="pb-4 ">
              <p className="newListingCreated_description">
              Your organization approval is pending. Please wait for Administrator to review your details. Once approved, you will be notified.
              </p>
            </div>
          </ConfirmModal>
        )}
      </div>
    </>
  );
};

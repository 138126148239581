import React, { useEffect, useState } from "react";
import Grid from "../../../../components/grid/Grid";
import { SearchBox } from "../Orders/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsAsync } from "../../../../services/reducers/notifications-reducers";
import { getFilterOptionsAsync, getSortingOptionsAsync, getSelectedSortBy } from "../../../../services/reducers/filter-reducers";
import { ExportCSVSVG, NotificationSVG} from "../../../../assets/svgComponents/table"; 
import NavBar from "../../../../components/navBar";

export const NotificationsPage = (props) => {  
  const dispatch = useDispatch();
  
  const notifications = useSelector((state)=> state.notifications.notifications);
  const notiHeading = useSelector((state)=> state.notifications.notiHeading);
  const notiAccessorName = useSelector((state)=> state.notifications.notiAccessorName);
  const selectedSortBy = useSelector((state)=> getSelectedSortBy(state,'transactions'));
  const {page, pageSize, isExport} = useSelector((state)=> state.orders);
  const [searchString, setSearchString] = useState("");

  const getNotifications = () =>{
    let reqBody = { 
      page: page,
      pageSize: pageSize, 
      export: isExport,
      searchString: searchString, 
      sortingBy: selectedSortBy
    }
    dispatch(getNotificationsAsync(reqBody));
  }
 
  const ApplyBtnForTran = () => {
    getNotifications();
  }; 

   useEffect(async () => {
    getNotifications();
     dispatch(getFilterOptionsAsync('TransactionTypes'));
     dispatch(getSortingOptionsAsync());
   }, [dispatch]); 
  return (
    <> 
     <NavBar
        notificationSVG={<NotificationSVG />}
        navHeading="Notifications"
        subHeading=""
        blueBtnText={ `Export CSV`}
        blueBtnSVG={<ExportCSVSVG fill="#FFFFFF" />}
        showButton={true}
      />
      <div className="gridSection"> 
        <div className="tableActions_container m-0"> 
          <div className="d-flex serachFilterContainer justify-content-start"> 
                  <SearchBox handleTermChange={(term)=> {setSearchString(term);}} onSearch={()=>dispatch(getNotifications())} label="Search Notifications"></SearchBox>
                  
          </div>   
      <Grid
        tableHeading={notiHeading}
        tableBody={notifications} 
        accessorName={notiAccessorName}
      />  

</div>
        </div>

    </>
  );
};

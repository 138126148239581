import React, { useState } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import { updateUserStatus } from "../../../../../services/Provider/UserManagement/UserManagement";
// import { toast } from "react-toastify";
import { render } from "@testing-library/react";
import { useToasts } from "react-toast-notifications";
import InfiniteScroll from "react-infinite-scroll-component";
import { checkPermission } from "../../../../../utils";

const ByUsers = ({
  active,
  updateStatus,
  data,
  handleOrderCard,
  selectedOrdersCard,
  page,
  setPage,
  SetIsStatusChange
}) => {
  const { addToast } = useToasts();
  const handleBlockUserButton = (item) => {
    let status = item.status;
    const reqBody = {
      userId: item.id,
      isDisabled: item.status,
    };
    updateUserStatus(reqBody)
      .then(() => {
        SetIsStatusChange(true);
        if (status == false) {
          addToast("User has been unblocked successfully.", {
            appearance: "success",
          });
        }
        else {
          addToast("User has been blocked successfully.", {
            appearance: "success",
          });
        }
      })
      .catch(() => {
        addToast("There is an error while updating status", {
          appearance: "success",
        });
        // toast.error("There is an error while updating status");
      });
  };
  if (data != null && data.length > 0) {
    return (
      <InfiniteScroll
        dataLength={data.length > 3 ? data.length - 3 : data.length - 1}
        hasMore={true}
        next={() => {
          setPage(page + 1);
          console.log('next page');
        }}
        scrollableTarget="ReviewPage"
        //loader={<h6>Loading...</h6>}
        hasChildren={true}
      >
        {data.map((item) => (
          <div onClick={() => handleOrderCard(item.id)}>
            <div
              className={`ordercardSection ${active} ${item.id == selectedOrdersCard ? "active" : ""
                }`}
            >
              <div className="orderCardMainContaner d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="review_profile_image">
                    <img
                      src={
                        item.profilePicture
                          ? "https://gopinit.blob.core.windows.net/image-container/" + item.profilePicture
                          : "https://i.stack.imgur.com/l60Hf.png"
                      }
                      alt="profileImag"
                    />
                  </div>
                  <div className="orderCardSection_1">
                    <span className="fw-bold">{item.name}</span>
                    <p className="m-0">{item.emailId}</p>
                  </div>
                </div>
                {checkPermission("ReviewsAndRatings", "Full") && (
                  <button
                    className="blueBgButton"
                    style={{ padding: "10px" }}
                    onClick={() => handleBlockUserButton(item)}
                  >
                    {item.status ? "Block User" : "Unblock User"}
                  </button>
                )}
              </div>
              <div className="orderCardFooter d-flex borderTop  justify-content-between">
                <div className="Section_i">
                  <p className="mb-0">Rating Given</p>
                  <span>{item.totalRating}</span>
                </div>
                <div className="Section_i">
                  <p className="mb-0">Avg. Rating </p>
                  <span>{parseFloat(item.avgRating).toFixed(1)}/5.0</span>
                </div>
                <div className="Section_i">
                  <p className="mb-0">Reviews</p>
                  <span>{item.totalReview}</span>
                </div>
                <div className="Section_i">
                  <p className="mb-0">Last Received Review</p>
                  <span>
                    {moment(item.lastReviewDate).format("DD MMMM YYYY")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    );
  } else {
    return <></>;
  }
};

export default ByUsers;

import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import ProjectContext from "../../../../../../context/ProjectContext";

const TableActions = (props) => {
  // const { handleActiveTab } = useContext(ProjectContext);
  return (
    <>
      <div className="tableActions_container m-0">
        <Row>
          <Col className="d-flex align-items-center">
            <ul className="tableActions_tabs mt-2">
              {props.tabs.map((item) =>
                item === props.activeTab ? (
                  <li
                    className="tableActions_tab active"
                    // handleActiveTab
                    onClick={() => {
                      props.setActiveTab(item);
                    }}
                  >
                    {item} <hr className="tableActions_tab_hr" />
                  </li>
                ) : (
                  <li
                    className="tableActions_tab"
                    onClick={() => {
                      // handleActiveTab(item);
                      props.setActiveTab(item);
                    }}
                  >
                    {item}
                  </li>
                )
              )}
              {/* <li className="tableActions_tab">SIGNS</li>
              <li className="tableActions_tab">SERVICES</li>
              <li className="tableActions_tab">CHARGES</li> */}
            </ul>
          </Col>
          <Col className=" d-flex justify-content-end">
            {/* <div className="tableAction_input_container">
              <InputField
                placeholder="Search Site ID, Name, Location..."
                // label="Email address"
              />
            </div>
            <div className="navbarBtnContainer ">
              <Button
                className="whiteBgButton02 "
                onClick={() => props.setFiltersModal(true)}
              >
                Filter
                <span className="btnSvg">
                  <UnionSVG />
                </span>
              </Button>
            </div> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TableActions;

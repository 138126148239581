import { useFormik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  InputGroup,
  Form,
  FloatingLabel,
  Dropdown,
  Modal,
  Tooltip,
  Button,
  OverlayTrigger,
} from "react-bootstrap";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { InputField } from "../../../../../../../components/InputField";
import NavBar from "../../../../../../../components/navBar";
import { TextAria } from "../../../../../../../components/TextAria";
import { UploadFile } from "../../../../../../../components/UploadFile/UploadFile";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import { useToasts } from "react-toast-notifications";
import {
  addSign,
  getEnvironmentType,
  getProvider,
  getUpdateOnBoardingStepDone,
  uploadFileMethod,
} from "../../../../../../../services/Provider";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ProjectContext from "../../../../../../../context/ProjectContext";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import { getAllTaxation } from "../../../../../../../services/Provider/StateTaxation/StateTaxation";
import UpdatesModal from "../../../../../../../components/profile/Modal/UpdatesModal";
import { InfoSVG } from "../../../../../../../assets/svgComponents/svg";
import { checkPermission } from "../../../../../../../utils";

export const AddSignPage = () => {
  const { addToast } = useToasts();
  const { handleActiveTab } = useContext(ProjectContext);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [uploadFile, setUploadFile] = useState();
  const [dimensions, setDimensions] = useState();
  const [measureUnit, setMeasureUnit] = useState();
  const [mediaTypeArray, setMediaTypeArray] = useState();
  const [mediaTypes, setMediaTypes] = useState();
  const [size, setSize] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [addSignConfirmModal, setAddSignConfirmModal] = useState(false);
  const [taxation, setTaxation] = useState([]);
  const [taxationId, setTaxationId] = useState();
  const [curUser, setCurUser] = useState();
  let tempStepCount = parseInt(localStorage.getItem("onBoardingStepDone"));
  const [imageError, setImageError] = useState("");
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);
  const [showRadius, setShowRadius] = useState(false);
  const [radiusError, setRadiusError] = useState("");

  const history = useHistory();
  const location = useLocation();

  const handleOnSavebtn = () => {
    history.push("/provider/configurator/inventory");
  };

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      <p style={{ color: "#fff" }}>
        Radius of operation is the area serviceable by the portable sign. A
        customer can only book inside your radius of operation.
      </p>
    </Tooltip>
  );

  // console.log("curUser", curUser);

  useEffect(() => {
    getProvider().then((res) => {
      if (res.status == 200) {
        setCurUser(res.data);
      }
    });

    getEnvironmentType("MediaTypes")
      .then((res) => {
        if (res.status === 200) {
          setMediaTypeArray(res.data);
          setMediaTypes(res.data[0].id);
          console.log("mediatype", res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    getEnvironmentType("SignDimensions")
      .then((res) => {
        if (res.status === 200) {
          setDimensions(res.data);
          setMeasureUnit(res.data[0].id);
          console.log("res.data", res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    getEnvironmentType("SignSizes")
      .then((res) => {
        if (res.status === 200) {
          setSize(res.data);
          setMeasureUnit(res.data[0].id);
          console.log("res.data", res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    getAllTaxation()
      .then((res) => {
        if (res.status === 200) {
          setTaxation(res.data);
          console.log("taxation data", taxation);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const handelUploadFile = (e) => {
    let data = e.target.files[0];
    let image = new FormData();
    image.append("file", data);
    uploadFileMethod(image)
      .then((res) => {
        if (res.status == 200) {
          setUploadFile(res.data);
          setImageError("");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleUnitSelection = (e) => {
    setMeasureUnit(e.target.value);
  };

  const handleTaxation = (e) => {
    let id = e.target.value;
    setTaxationId(id);
  };

  const handleSizeSelection = (e) => {
    setSelectedSize(e.target.value);
  };

  const handleMediaSelection = (e) => {
    setMediaTypes(e.target.value);
    if (
      mediaTypeArray[parseInt(e.target.value)].name.indexOf("Portable Sign") >=
      0
    ) {
      setShowRadius(true);
    } else {
      setShowRadius(false);
    }
  };
  console.log(measureUnit, "measureUnit");
  //.matches(new RegExp("[0-9]{1}"), "Please enter number")
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      width: "",
      height: "",
      radius: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(5, "Please enter title with five or more characters")
        .required("Name is required!"),
      width: Yup.number()
        .positive("Please enter number greater than 0")
        .required("Width is required!"),
      height: Yup.number()
        .positive("Please enter number greater than 0")
        .required("Height is required!"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
      let errorFlag = false;
      let radius = 0;
      if (showRadius) {
        radius = values.radius;
        if (radius === "") {
          errorFlag = true;
          setRadiusError("Please enter radius!");
        }
      }
      if (!uploadFile) {
        errorFlag = true;
        setImageError("Please upload image!");
      }
      if (!description) {
        errorFlag = true;
        setDescriptionError("Description is required!");
      }

      if (!errorFlag) {
        let formData = {
          name: values.name,
          radius: radius,
          description: description,
          mediaType: mediaTypes,
          dimension: measureUnit,
          size: selectedSize,
          width: values.width,
          height: values.height,
          image: uploadFile.fileKey,
          stateName: taxationId,
        };
        console.log("formData", formData);
        addSign(formData)
          .then((res) => {
            if (res.status === 201) {
              if (tempStepCount == 2) {
                localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
                let bodyData = [
                  {
                    path: "/onBoardingStepDone",
                    op: "add",
                    value: tempStepCount + 1,
                  },
                ];
                getUpdateOnBoardingStepDone(bodyData)
                  .then((res) => {
                    console.log("res", res);
                  })
                  .catch((err) => {
                    console.log("err", err);
                  });
              }
              handleActiveTab("SIGNS");
              addToast("Sign added successfully", {
                appearance: "success",
              });
              // }
            }
            history.push("/provider/configurator/inventory");
          })
          .catch((err) => {
            console.log("err", err);
            handleActiveTab("SIGNS");
            addToast("Error while creating this sign.", {
              appearance: "success",
            });
          });
      }
    },
  });
  const nameError = formik.touched.name && formik.errors.name;
  const widthError = formik.touched.width && formik.errors.width;
  const heightError = formik.touched.height && formik.errors.height;
  //const radiusError = formik.touched.radius && formik.errors.radius;

  const handleDescription = (e) => {
    let des = e.target.value;
    setDescription(des);
    setDescriptionError("");
    if (!des) {
      setDescriptionError("Description is required!");
    }
  };

  const handleOnCancel = () => {
    /*  
      if (tempStepCount == 2) {
       history.push("/provider/add-service");
      } else {
        history.goBack();
      } 
     */
    handleActiveTab("SIGNS");
    history.push("/provider/configurator/inventory");

    // if (curUser.onBoardingStepDone == 2) {
    //   history.push("/provider");
    // } else {
    //   history.goBack();
    // }
  };

  const handleAddSignConfirmModal = () => {
    setAddSignConfirmModal(false);
    history.push("/provider/configurator/inventory");
  };
  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <NavBar
          blueBtnText="Save"
          whiteBtnText={"Cancel"}
          notificationSVG={<NotificationSVG />}
          navHeading="Add Sign"
          subHeading="Configurator • All Signs "
          subActiveHeading="Add Sign"
          // handleBlueBtn={handleOnSavebtn}
          type="submit"
          handleWhitebtn={handleOnCancel}
          handleNotificationSVG={handleNotificationSVG}
          showButton={true}
        />
        <div className="girdMain gridSection ">
          <Card className="gridCard" aria-labelledby="gridCard">
            <Card.Body className="gridCardBody p-0">
              <div className="formSection ">
                <p className="mt-4 mx-4">Sign Details</p>
                <div className="mx-4">
                  <div className="row my-4 fornSection2">
                    <div className="col-md-6">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Media type"
                        className="mb-3"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          // className="selecContainer"
                          onChange={(e) => handleMediaSelection(e)}
                        >
                          {mediaTypeArray &&
                            mediaTypeArray.map((item) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                        </Form.Select>
                      </FloatingLabel>
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="Sign Name"
                        label="Sign Name"
                        inputFieldSize="sizeOfInputField"
                      />
                      {nameError && (
                        <p
                          className="text-danger mt-1 ms-4"
                          style={{ fontSize: "14px" }}
                        >
                          {formik.errors.name}
                        </p>
                      )}
                    </div>
                  </div>
                  {showRadius && (
                    <div className="row my-4 fornSection2">
                      <div className="col-md-8 d-flex justify-content-between">
                        <InputField
                          type="number"
                          name="radius"
                          value={formik.values.radius}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="Radius"
                          label="Radius of Operation(in KMs)"
                          inputFieldSize="sizeOfInputField"
                          style={{ width: "520px" }}
                        />
                        {radiusError && (
                          <p
                            className="text-danger mt-1 ms-4"
                            style={{ fontSize: "14px" }}
                          >
                            {radiusError}
                          </p>
                        )}
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltip}
                        >
                          <Button
                            style={{
                              backgroundColor: "white",
                              borderColor: "white",
                            }}
                          >
                            <InfoSVG />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </div>
                  )}
                  <div className="row fornSection2">
                    <div className="col-md-6">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Unit of Measurement"
                        className="mb-3"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          // className="selecContainer"
                          onChange={(e) => handleUnitSelection(e)}
                        >
                          {dimensions &&
                            dimensions.map((item) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                        </Form.Select>
                      </FloatingLabel>
                    </div>
                    <div className="col-md-6">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Size"
                        className="mb-3"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          // className="selecContainer"
                          onChange={(e) => handleSizeSelection(e)}
                        >
                          {size &&
                            size.map((item, i) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                        </Form.Select>
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row fornSection2 my-4 ">
                    <div className="col-md-6">
                      <InputField
                        type="number"
                        name="width"
                        value={formik.values.width}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="name@example.com"
                        label="Width"
                        inputFieldSize="sizeOfInputField"
                      />
                      {widthError && (
                        <p
                          className="text-danger mt-1 ms-4"
                          style={{ fontSize: "14px" }}
                        >
                          {formik.errors.width}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="number"
                        name="height"
                        value={formik.values.height}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="name@example.com"
                        label="Height"
                        inputFieldSize="sizeOfInputField"
                      />
                      {heightError && (
                        <p
                          className="text-danger mt-1 ms-4"
                          style={{ fontSize: "14px" }}
                        >
                          {formik.errors.height}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row fornSection2 my-4">
                    <div className="col-md-12">
                      <div className="form-floating">
                        <textarea
                          type="text"
                          name="des"
                          value={description}
                          onChange={(e) => handleDescription(e)}
                          placeholder="Description"
                          label="Description"
                        />
                        {descriptionError && (
                          <p
                            className="text-danger mt-1 ms-4"
                            style={{ fontSize: "14px" }}
                          >
                            {descriptionError}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row fornSection2 my-4">
                    <div className="col-md-6">
                      <UploadFile
                        id="image"
                        uploadBoxSize="sizeOfInputField"
                        btnlable="Image"
                        handelUploadLogo={handelUploadFile}
                        placeholder={
                          uploadFile ? uploadFile.fileName : "No Image Attach"
                        }
                        allowedFiles="image/png, image/gif, image/jpeg"
                      />
                      {imageError && (
                        <p
                          className="text-danger mt-1 ms-4"
                          style={{ fontSize: "14px" }}
                        >
                          {imageError}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="State Taxation"
                      >
                        <Form.Select
                          // className="selecContainer"
                          placeholder="Select Taxation"
                          onChange={(e) => handleTaxation(e)}
                        >
                          {taxation &&
                            taxation?.map((item) => {
                              return (
                                <option value={item.stateName} key={item.id}>
                                  {item.stateName}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </FloatingLabel>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Form>

      {addSignConfirmModal && (
        <ConfirmModal
          title={`Add Sign`}
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Ok"
          handleConfirm={handleAddSignConfirmModal}
        >
          <div className="text-center">
            <p className="py-4 newListingCreated_description">
              Sign added successfully.
            </p>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

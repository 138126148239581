import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getOptions, processFilterOptions, getFiltersObject } from '../filters-base';
import { releaseOrderPayout, updateReview, addListingReview, refundOrderRequest, refundOrderAmount, getAllOrders, getOrderDetails, extendOrder, extendOrderAmount,updateOrderStatus } from "../api/orders-store-api";
import { orderPayment } from "../api/customer-store-api";

const initialState = {
    orders:{ records:[]},
    order : {},
    refundTypes : [],
    page : 0,
    pageSize : 10,
    isExport : false,
    searchString : '',
    refundAmount:0,
    refundType:0,
    extendOrderAmount:0,
    extendOrderResult:null,
    extendedOrder:null,
    extendOrderSubmitResult:null,
    extendedOrderPaymentResult:null,
    refreshOrdersOn:null,
    statusTypes:[],
    updateOrderStatusResponse:null,
    refundRequestResponse:null,
    refundAmountResponse:null,
    paymentResult:null,
    addReviewResult:null,
    orderPayoutResult:null,
}


export const getOrderPeriod = (state) =>{
    return  state.order && state.order.details && state.order.details.length>0 ? { fromDate : state.order.details[0].fromDate, toDate:state.order.details[0].toDate } : null;
}

export const getOrdersAsync = createAsyncThunk('orders/getOrdersAsync',
    async(request) => {
    const response = await getAllOrders(request);
    return response.data.data;
    }
)

export const getOrderAsync = createAsyncThunk('order/getOrderAsync',
    async(id) => {
    const response = await getOrderDetails(id);
    return response.data;
    }
)

export const getRefundTypeOptionsAsync = createAsyncThunk('filters/getRefundTypeOptionsAsync',
    async() => {
    const response = await getOptions('RefundTypes');
    return processFilterOptions(response.data);
    }
)

export const getMediaOptionsAsync = createAsyncThunk('filters/getMediaOptionsAsync',
    async() => {
    const response = await getOptions('MediaTypes');
    return processFilterOptions(response.data);
    }
)

export const getSortingOptionsAsync = createAsyncThunk('filters/getSortingOptionsAsync',
    async() => {
    const response = await getOptions('DatePriceSorting');
    return {options:processFilterOptions(response.data)};
    }
)

export const refundAmountAsync = createAsyncThunk('orders/refundAmountAsync',
    async(requestBody) => {
    const response = await refundOrderAmount(requestBody);
    return response.data;
    }
)

export const getExtendedOrderAsync = createAsyncThunk('orders/getExtendedOrderAsync',
    async(orderId) => {
    const response = await getOrderDetails(orderId);
    return response.data;
    }
)

export const extendOrderAmountAsync = createAsyncThunk('orders/extendOrderAmountAsync',
    async(reqBody, { rejectWithValue }) => {              
         
        const response = await extendOrderAmount(reqBody).catch((err)=>{  
            console.log(response,"error");
            if(response.data.status === 0)
            {
                return rejectWithValue(response.data.error);
            }
                if(err.response.data.error){
                    return rejectWithValue(err.response.data.error);
                }       
                else if(err.response.data.errors){
                    let arr = [];
                    for(var key in err.response.data.errors){
                        arr.push(err.response.data.errors[key][0])
                    }                    
                    console.log(arr);
                    return rejectWithValue(arr.join(', '));
                }else{
                    return rejectWithValue(err.response.data.title);
                }       
                console.log('error=>',err.response.data.errors);
                return rejectWithValue("unable to extend order");
            }); 
            //console.log('extendorderamount result',response);
            return response.data;
    }
)

export const extendOrderAsync = createAsyncThunk('orders/extendOrderAsync',
    async(reqBody, { rejectWithValue }) => {              
         
        const response = await extendOrder(reqBody).catch((err)=>{  
                if(err.response.data.error){
                    return rejectWithValue(err.response.data.error);
                }       
                else if(err.response.data.errors){
                    let arr = [];
                    for(var key in err.response.data.errors){
                        arr.push(err.response.data.errors[key][0])
                    }                    
                    console.log(arr);
                    return rejectWithValue(arr.join(', '));
                }else{
                    return rejectWithValue(err.response.data.title);
                }       
                console.log('error=>',err.response.data.errors);
                return rejectWithValue("unable to extend order");
            }); 
            return response.data;
    }
)

export const extendedOrderPaymentAsync = createAsyncThunk('orders/extendedOrderPaymentAsync',async(reqBody,{rejectWithValue}) => {
    const response = await orderPayment(reqBody).catch((err)=>{  
        if(err.response.data.error){
            return rejectWithValue(err.response.data.error);
        }       
        else if(err.response.data.errors){
            let arr = [];
            for(var key in err.response.data.errors){
                arr.push(err.response.data.errors[key][0])
            }                    
            console.log(arr);
            return rejectWithValue(arr.join(', '));
        }else{
            return rejectWithValue(err.response.data.title);
        }       
        console.log('error=>',err.response.data.errors);
        return rejectWithValue("unable to pay for the order");
    }); 
    return response;
})

export const getOrderStatusTypesAsync = createAsyncThunk('orders/getOrderStatusTypesAsync',
    async() => {
    const response = await getOptions('OrderStatus');
    return response.data;
    }
) 

export const updateOrderStatusAsync = createAsyncThunk('orders/updateOrderStatusAsync', 
    async(reqBody)=>{
    const response = await updateOrderStatus(reqBody);
    return response.data;
});

export const refundOrderRequestAsync = createAsyncThunk('orders/refundOrderRequestAsync', 
    async(reqBody)=>{
    const response = await refundOrderRequest(reqBody);
    return response.data;
});

export const orderPaymentAsync = createAsyncThunk('orders/orderPaymentAsync',async(reqBody,{rejectWithValue}) => {
    const response = await orderPayment(reqBody).catch((err)=>{  
        if(err.response.data.error){
            return rejectWithValue(err.response.data.error);
        }       
        else if(err.response.data.errors){
            let arr = [];
            for(var key in err.response.data.errors){
                arr.push(err.response.data.errors[key][0])
            }                    
            console.log(arr);
            return rejectWithValue(arr.join(', '));
        }else{
            return rejectWithValue(err.response.data.title);
        }       
        console.log('error=>',err.response.data.errors);
        return rejectWithValue("unable to pay for the order");
    }); 
    return response;
})

export const postReviewAsync = createAsyncThunk('orders/postReviewAsync', 
    async(params,{rejectWithValue}) => {
    const response = await addListingReview(params.listingId,{rating:params.rating,comment:params.comment})
    .catch((err)=>{  
        if(err.response.data.error){
            return rejectWithValue(err.response.data.error);
        }       
        else if(err.response.data.errors){
            let arr = [];
            for(var key in err.response.data.errors){
                arr.push(err.response.data.errors[key][0])
            }                    
            console.log(arr);
            return rejectWithValue(arr.join(', '));
        }else{
            return rejectWithValue(err.response.data.title);
        }       
        console.log('error=>',err.response.data.errors);
        return rejectWithValue("Unable to add review!");
    }); 
    return response;
});

export const updateReviewAsync = createAsyncThunk('orders/updateReviewAsync', 
    async(params,{rejectWithValue}) => {
    const response = await updateReview(params.reviewId,params.reqBody)
    .catch((err)=>{  
        if(err.response.data.error){
            return rejectWithValue(err.response.data.error);
        }       
        else if(err.response.data.errors){
            let arr = [];
            for(var key in err.response.data.errors){
                arr.push(err.response.data.errors[key][0])
            }                    
            console.log(arr);
            return rejectWithValue(arr.join(', '));
        }else{
            return rejectWithValue(err.response.data.title);
        }       
        console.log('error=>',err.response.data.errors);
        return rejectWithValue("Unable to update review!");
    }); 
    return response;
});

export const releaseOrderPayoutAsync = createAsyncThunk('orders/releaseOrderPayoutAsync', 
    async(reqBody,{rejectWithValue}) => {
    const response = await releaseOrderPayout(reqBody)
    .catch((err)=>{  
        if(err.response.data.error){
            return rejectWithValue(err.response.data.error);
        }       
        else if(err.response.data.errors){
            let arr = [];
            for(var key in err.response.data.errors){
                arr.push(err.response.data.errors[key][0])
            }                    
            console.log(arr);
            return rejectWithValue(arr.join(', '));
        }else{
            return rejectWithValue(err.response.data);
        }       
        console.log('error=>',err.response.data.errors);
        return rejectWithValue("Unable to release ordr payout!");
    }); 
    return response.data;
});

const ordersSlice = createSlice({
    name:"orders",
    initialState,
    reducers:{
        setSearchString : (state,{payload}) =>{
            state.searchString = payload;
        },
        getOrders: (state,payload)=>{
            state.orders = payload;
        },
        getOrder:(state,payload)=>{
            state.order = payload;
        },
        filterCBChanged:(state,params)=>{ 
            console.log(params);
            if(params.payload.type=="sorting"){
                state.sortingOptions = {...state.sortingOptions,options: state.sortingOptions.options.map(s=>s.id!=params.payload.id ? s : {...s,isChecked:!s.isChecked})};
            }else{
                state.checkBoxFilters =  state.checkBoxFilters.map(r=>{                                         
                    if(r.title==params.payload.type){                         
                        r.options = r.options.map(s=>s.id!=params.payload.id ? s : {...s,isChecked:!s.isChecked});                    
                    }
                    return r;
                });            
            }
        },
        clearAllCB:(state,payload)=>{
            state.checkBoxFilters =  state.checkBoxFilters.map(r=>{                
                r.options = r.options.map(s=> ({...s, isChecked:false}));
                return r;
            });
            state.sortingOptions =  {...state.sortingOptions,options:state.sortingOptions.options.map(s=> ({...s, isChecked:false}))};
        },
        setRefundAmount:(state,payload) =>{
            state.refundAmount = payload;
        },
        setRefundType:(state,payload) =>{
            state.refundType = payload;
        },
        clearExtendOrderResult:(state)=>{
            state.extendOrderResult=null;
        },
        refreshOrders:(state,params) =>{
            state.refreshOrdersOn = params.payload;
        },
        clearOrderStatusUpdateResult:(state)=>{
            state.updateOrderStatusResponse=null;
        }
    },
    extraReducers:{
        [getOrdersAsync.pending] : () => {

        },
        [getOrdersAsync.fulfilled] : (state,{payload}) => {
            if(payload.page>1 && payload.page!=state.orders.page){
                return {...state, orders: {totalRecords: payload.records.length, page:payload.page, pageSize:payload.pageSize, records:[...state.orders.records,...payload.records]}};
            }else{
                return {...state,orders: {totalRecords:payload.records.length, page:payload.page, pageSize:payload.pageSize,records:payload.records}};
            }   
        },[getOrdersAsync.rejected] : () => {
            console.log("rejected");
        },
        [getOrderAsync.pending]: () =>{
            
        },
        [getOrderAsync.fulfilled] : (state, {payload}) => {
            return {...state, order:payload, 
                paymentResult:null,
                updateOrderStatusResponse:null,
                refundRequestResponse:null,
                refundAmountResponse:null,
                extendOrderResult:null,
                addReviewResult:null,
                orderPayoutResult:null,
            };
        },
        [getOrderAsync.rejected] : (state,{payload}) => {
            console.log("get order details rejected");
        },
        [getRefundTypeOptionsAsync.pending]: () =>{
            
        },
        [getRefundTypeOptionsAsync.fulfilled] : (state, {payload}) => {
            return {...state, refundTypes:payload}
        },
        [getRefundTypeOptionsAsync.rejected] : (state,{payload}) => {
            console.log("get refund types  rejected");
        },
        [refundAmountAsync.pending] : () => {
            
        },
        [refundAmountAsync.fulfilled] : (state, {payload}) => {               
            return {...state,
                order:{...state.order,refundRequestStatus:1, refundRequestStatusText:'Approved'},
                refundAmountResponse:{message:payload.message, status:payload.status, on:new Date().toString()}};
        },
        [refundAmountAsync.rejected] : (state, {payload}) => {
            return {...state,refundAmountResponse:{message:payload.message, status:0, on:new Date().toString()}};
        },


        [extendOrderAmountAsync.pending] : () => {
            
        },
        [extendOrderAmountAsync.fulfilled] : (state,{payload}) => {
            return {...state, extendOrderAmount : payload};                     
        },[extendOrderAmountAsync.rejected] : (state,{payload}) => {
            console.log("Extend order rejected",payload);
            let result = [ payload, new Date().toString(), null];
            return {...state, extendOrderAmount:0  };
        },


        [extendOrderAsync.pending] : () => {
            
        },
        [extendOrderAsync.fulfilled] : (state,{payload}) => {
            console.log('payload',payload);            
            let result = [ "Order extended successfully!", new Date().toLocaleDateString(),payload];
            return {...state, extendOrderResult : result}            
        },[extendOrderAsync.rejected] : (state,{payload}) => {
            console.log("Extend order rejected",payload);
            let result = [ payload, new Date().toLocaleDateString(), null];
            return {...state, extendOrderResult:result  };
        },

        [getExtendedOrderAsync.pending]: () =>{
            
        },
        [getExtendedOrderAsync.fulfilled] : (state, {payload}) => {
            console.log("get extend order details", payload);
            if(payload && payload.paymentTerms && payload.paymentTerms.length>1){
                var ptSorted = payload.paymentTerms.sort((a,b)=>{ return parseInt(a.installmentNo)  - parseInt(b.installmentNo);});
                var exdOrder = {...payload, paymentTerms:ptSorted};                
                return {...state, extendedOrder:exdOrder};    
            }else
            return {...state, extendedOrder:payload}
        },
        [getExtendedOrderAsync.rejected] : (state,{payload}) => {
            console.log("get extended order details rejected");
        },

        
        [extendedOrderPaymentAsync.pending] : () => {
            
        },
        [extendedOrderPaymentAsync.fulfilled] : (state,{payload}) => {
            console.log('payload',payload);
            if(payload.status==200){
                let result = [ true, "Payment done successfully!", payload.data, new Date().toLocaleDateString()];
                return {...state, extendedOrderPaymentResult : result}
            }else{
                let result = [ false, "Unable to process payment!", payload, new Date().toLocaleDateString()];
                return {...state,extendedOrderPaymentResult:result}
            }            
        },[extendedOrderPaymentAsync.rejected] : (state,{payload}) => {
            console.log("extend order payment rejected",payload);
            let result = [ false, payload, new Date().toLocaleDateString()];
            return {...state, extendedOrderPaymentResult:result  };
        },

        [getOrderStatusTypesAsync.pending] : () =>{

        },
        [getOrderStatusTypesAsync.fulfilled]:(state,{payload}) =>{
            return {...state,statusTypes:payload};
        },
        [getOrderStatusTypesAsync.rejected]:(state,{payload}) => {

        },

        [updateOrderStatusAsync.pending] : () =>{

        },
        [updateOrderStatusAsync.fulfilled]:(state,{payload}) =>{
            let updatedRecords = state.orders.records.filter(r=>r.id==payload.id);
            if(updatedRecords && updatedRecords.length>0){
                updatedRecords[0].status = payload.status; 
                updatedRecords[0].orderStatusText = payload.orderStatusText;  
            }            
            state.order = {...state.order,status:payload.status,orderStatusText:payload.orderStatusText}; 
            state.updateOrderStatusResponse = { response:payload, on:new Date().toString(), status:1, message:'Order Status Updated!' };
        },
        [updateOrderStatusAsync.rejected]:(state,{payload}) => {
            return {...state,updateOrderStatusResponse:{ response:payload, on:new Date().toString(), status:0, message:'Unable to Update Order Status!' }};
        },

        [refundOrderRequestAsync.pending] : () => {

        },
        [refundOrderRequestAsync.fulfilled] : (state, {payload}) => {
            let updatedRecords = state.orders.records.filter(r=>r.id==payload.id);
            if(updatedRecords && updatedRecords.length>0){
                updatedRecords[0].status = payload.status; 
                updatedRecords[0].orderStatusText = payload.orderStatusText;  
            }            
            state.order = {...state.order,
                status:payload.status,
                orderStatusText:payload.orderStatusText,
                refundRequestStatus:payload.refundRequestStatus,
                refundRequestReason:payload.refundRequestReason,
                refundRequestStatusText:payload.refundRequestStatusText}; 
            state.refundRequestResponse = { response:payload, on:new Date().toString(), status:1, message:'Refund request submitted!' };            
        },
        [refundOrderRequestAsync.rejected] : (state,{payload}) => {

        },


        [orderPaymentAsync.pending] : () => {
            
        },
        [orderPaymentAsync.fulfilled] : (state,{payload}) => {
            console.log('payload',payload);
            if(payload.status==200){
                let result = [ "Payment done successfully!", new Date().toLocaleDateString()];
                return {...state,order:{orderDetails:[]}, order:null, paymentResult : payload.data}
            }else{
                return {...state,order:{orderDetails:[]}, paymentResult : payload}
            }            
        },[orderPaymentAsync.rejected] : (state,{payload}) => {
            console.log("order payment rejected",payload);
            let result = [ payload, new Date().toLocaleDateString()];
            return {...state, orderPaymentError:result  };
        },

        [postReviewAsync.pending]:() => {

        },
        [postReviewAsync.fulfilled] : (state,{payload}) =>{
            return {...state,addReviewResult:{ status:1,on:new Date().toString(), message:"Your review added successfully!" }};
        },
        [postReviewAsync.rejected] : (state,{payload}) =>{
            return {...state,addReviewResult:{ status:0,on:new Date().toString(), message:"Unable to add review!" }};
        },

        [updateReviewAsync.pending]:()=>{

        },
        [updateReviewAsync.fulfilled] : (state,{payload}) => {
            return {...state,addReviewResult:{ status:1,on:new Date().toString(), message:"Your review update successfully!" }};
        },
        [updateReviewAsync.rejected] : (state,{payload}) => {
            return {...state,addReviewResult:{ status:0,on:new Date().toString(), message:"Unable to update review!" }};
        },


        [releaseOrderPayoutAsync.pending]: ()=>{

        },
        [releaseOrderPayoutAsync.fulfilled]:(state,{payload}) => {            
            let msg = "Order paid out successfully!";
            if(payload && payload.details){
                msg = payload.details.join(',');
            }
            return {...state,orderPayoutResult:{ status:payload.status,on:new Date().toString(), message:msg }};
        },
        [releaseOrderPayoutAsync.rejected] : (state,{payload})=>{
            return {...state,orderPayoutResult:{ status:0,on:new Date().toString(), message:payload }};
        }
        
    }
});
export const { getOrders, getOrder, setRefundAmount, setRefundType, refreshOrders,setSearchString,clearOrderStatusUpdateResult  } = ordersSlice.actions;
export const { fetchOrders } = (state) => state.orders.orders;
export const { fetchOrder } = (state) => state.order.order;
export default ordersSlice.reducer;

import React, { useState, useEffect } from "react";
import { Card, Form, Image, Modal, Stack } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useHistory, useParams } from "react-router";
import { Collateral } from "../../../../../assets/svgComponents/inventorySVG";
import {
  SpringSVG,
  SVGCalendar,
} from "../../../../../assets/svgComponents/svg";
import {
  NotificationSVG,
  PlusSVG,
} from "../../../../../assets/svgComponents/table";
import { AddressCard } from "../../../../../components/AddressCard/AddressCard";
import NavBar from "../../../../../components/navBar";
import * as Yup from "yup";

import { getAddressList } from "../../../../../services/Provider";
import { LoginModal } from "../../../../../components/Modals/LoginModal";
import {
  getProviderById,
  verifyProvider,
} from "../../../../../services/Provider/UserManagement/UserManagement";
import { useToasts } from "react-toast-notifications";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import { useLocation } from "react-router";
import { checkPermission } from "../../../../../utils";
export const ProviderDetails = (props) => {
  const { addToast } = useToasts();
  const { id } = props.location.state;
  const search = useLocation().search;
  const history = useHistory();
  const [addNewAddressModal, setAddNewAddressModal] = useState(false);
  const [user, setUser] = useState({});
  const [addessList, setAddessList] = useState();
  const [approveModel, handleApproveModel] = useState(false);
  const [logo, setLogo] = useState();
  const [reasonText, setReasonText] = useState();
  const [validationError, setValidationError] = useState();
  const [reason, setReason] = useState("");
  const [action, setAction] = useState("");



  // console.log("user", user);

  const getProvider = () => {
    getProviderById(id)
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data);
          console.log(user);
          setAddessList(res.data.addresses);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProvider();
  }, [id]);

  console.log("user", user);
  useEffect(() => {
    setLogo(
      `https://gopinit.blob.core.windows.net/image-container/${user.logo}`
    );
  }, [user]);


  const handleApproveBtn = (verified, disapproveReason) => {

    if (verified === false) {
      if (disapproveReason == null) {
        let flag = true;
        let reqBody;
        if (action === "Approve") {
          reqBody = {
            providerId: user.id,
            verified: !verified,
            reason: reason
          };

        } else {
          if (reason === "") {
            setValidationError("Please enter reason before submit.");
            flag = false;
          }
          reqBody = {
            providerId: user.id,
            verified: verified,
            reason: reason
          };
        }

        if (flag === true) {
          verifyProvider(reqBody)
            .then(() => {
              getProvider();
              addToast((action === "Approve" ? "Provider Approved Successfully" : "Provider Rejected Successfully"), {
                appearance: "success",
              });
              setReason("");
              handleApproveModel(false);
            })
            .catch(() => {
              addToast("Provider Approved successfully", {
                appearance: "error",
              });
            });
        }
      } else {
        handleApproveModel(false);
      }

    } else {
      handleApproveModel(false);
    }




  };

  const lsUerType = localStorage.getItem("userType");
  let comp;
  if (lsUerType === "Temp") {
    comp = <LoginModal />;
  } else {
    comp = (
      <>
        <NavBar
          blueBtnText={"Approve"}
          whiteBtnText={"Reject"}
          notificationSVG={<NotificationSVG />}
          navHeading="User Management"
          subHeading="Provider Details"
          handleBlueBtn={() => { setValidationError(""); setAction("Approve"); handleApproveModel(true) }}
          handleWhitebtn={() => { setValidationError(""); setAction("Reject"); handleApproveModel(true) }}
          showButton={true}
        // showButton={checkPermission("UserManagement", "Full")}
        // handleNotificationSVG={handleNotificationSVG}
        />
        <div className="gridSection p-20">
          <div className="row d-flex h-100">
            <div className="col-md-3 flex-grow-1 h-100 overflow-auto">
              <div className="col-md-12 profileImageContainer bg-base-100 h-100">
                <div className="imageboxContainer w-100 border-bottom">
                  <div className="imageBox mb-3">
                    <img
                      src={logo ? logo : "https://i.stack.imgur.com/l60Hf.png"}
                      alt="profileImag"
                    />
                  </div>
                  <span className="providerName">{user && user.name}</span>
                </div>
              </div>
            </div>
            <div className="col-md-9 flex-grow-1 h-100 overflow-auto">
              <div className="col-md-12 profileInfoContainer bg-base-100 h-100 overflow-auto">
                <div className="organizatonDetailsContainer h-auto border-bottom">
                  <p className="mt-4 mb-4">Provider Details</p>
                  <Stack
                    className="horProfileInfo  mx-auto"
                    direction="horizontal"
                    gap={5}
                  >
                    <div className="row w-100 ">
                      <div className="col-md-6 mb-5">
                        <label>Name</label>
                        <p>{user && user.name}</p>
                      </div>
                      <div className="col-md-6 mb-5">
                        <label>GST NUMBER</label>
                        <p>{user && user.taxInfo}</p>
                      </div>
                      <div className="col-md-6 mb-5">
                        <label>Email Id</label>
                        <p>
                          {user && (user.emailId === null ? "-" : user.emailId)}
                        </p>
                      </div>
                      <div className="col-md-6 mb-5">
                        <label>Contact No</label>
                        <p>
                          {user &&
                            (user.contactNo === null ? "-" : user.contactNo)}
                        </p>
                      </div>
                      <div className="col-md-6 mb-5">
                        <label>Status</label>
                        <p>
                          {user && (user.verified === true ? "Approved" : user.disapproveReason == null ? "Pending" : "Rejected")}
                        </p>
                      </div>
                      {user && (user.verified === true ? "" : user.disapproveReason == null ? "" :
                        <div className="col-md-6 mb-5">
                          <label>Rejection Reason</label>
                          <p>{user && user.disapproveReason}</p>
                        </div>
                      )}

                    </div>
                  </Stack>
                  <Stack
                    className="horProfileInfo"
                    direction="horizontal"
                    gap={5}
                  >
                    <div className="row w-100">
                      <div className="col-md-6 mb-5">
                        <label>Boarding Step Done</label>
                        <p>
                          {user &&
                            (user.onBoardingStepDone === null
                              ? "-"
                              : user.onBoardingStepDone)}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label>Provider Type</label>
                        <p>
                          {user &&
                            (user.providerTypeText === null
                              ? "-"
                              : user.providerTypeText)}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label>LOGO</label>
                        <p>
                          <div className="logoContainer">
                            <a
                              download="image.jpg"
                              href={logo}
                              target="_blank"
                            >
                              <img src={logo} alt="logo" />
                            </a>
                          </div>
                        </p>
                      </div>
                      <div className="mt-2 col-md-6 mb-5">
                        <label>REGISTRATION PROOF</label>
                        <a
                          download="image.jpg"
                          href={`https://gopinit.blob.core.windows.net/image-container/${user.proof}`}
                          target="_blank"
                        >
                          <p><Collateral fill="#58B85C" /></p>
                        </a>
                      </div>
                      {/* <div className="col-md-6 mb-5">                            
                            <label>Email Id</label>
                            <p>{user && (user.emailId===null?'-':user.emailId)}</p>
                        </div>
                        <div className="col-md-6 mb-5">
                          <label>Contact No</label>
                          <p>{user && (user.contactNo===null?'-':user.contactNo)}</p>
                        </div>
                        <div className="col-md-6 mb-5">
                          <label>Verified</label>
                          <p>{user && (user.verified===false?'False':'True')}</p>
                        </div> */}
                    </div>


                  </Stack>
                  {/* <Stack className="horProfileInfo mt-4" direction="horizontal" gap={5}>
                         <div className="row w-100">
                          <div className="col-md-6 mb-5">
                            <label>Boarding Step Done</label>
                            <p>{user && (user.onBoardingStepDone===null?'-':user.onBoardingStepDone)}</p>
                          </div>
                          <div className="col-md-6 mb-5">
                            <label>Provider Type</label>
                            <p>{user && (user.providerTypeText===null?'-':user.providerTypeText)}</p>
                          </div>
                        <div className="col-md-6 mb-5">
                            <label>LOGO</label>
                            <p>
                              <div className="logoContainer">
                                <img src={logo} alt="logo" />
                              </div>
                            </p>
                          </div>
                          <div className="col-md-6 mb-5">
                            <label>REGISTRATION PROOF</label>
                            <p>
                              
                                <Collateral fill="#58B85C" />
                            
                         
                      </p>
                    </div></div>
                  </Stack> */}

                  <div style={{ display: "none" }}>
                    <div className="nameBox">
                      <p>Name</p>
                      <span>{user && user.name}</span>
                    </div>
                    <div className="gstBox">
                      <p>GST NUMBER</p>
                      <span>{user && user.taxInfo}</span>
                    </div>
                    <div className="nameBox">
                      <p>Email Id</p>
                      <span>{user && user.emailId}</span>
                    </div>
                    <div className="gstBox">
                      <p>Contact No</p>
                      <span>{user && user.contactNo}</span>
                    </div>
                    <div className="logoBox">
                      <p>LOGO</p>
                      <span>
                        <div className="logoContainer">
                          <img src={logo} alt="logo" />
                        </div>
                      </span>
                    </div>
                    <div className="proofBox">
                      <p>REGISTRATION PROOF</p>
                      <span>
                        <div className="mt-4">
                          <Collateral fill="#58B85C" />
                        </div>
                        {/* {user && user.provider.proof} */}
                        {/* <div className="logoContainer mt-4">
                      <img src={proof} alt="proof" />
                    </div> */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bilingDetailContainr border-bottom pb-3">
                  <p className="mt-4">Billing Address</p>
                  <div className="addressCardContainer">
                    {addessList &&
                      addessList.map((address, index) => {
                        return (
                          <AddressCard
                            address={address}
                            user={user}
                            index={index}
                            isNotEditable={false}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {approveModel && (
          <ConfirmModal
            title={(action === "Approve" ? "Approve Provider" : "Reject Provider")}
            isModalHeader={true}
            isFooterBtns={false}
            confirmText="Ok"
            handleConfirm={() => handleApproveBtn(user.verified, user.disapproveReason)}
            handleClose={() => handleApproveModel(false)}
          >
            {(() => {
              if (user.verified) {
                return (
                  <div className="childs">
                    <span style={{ color: "red" }}>This user is already approved.</span>
                  </div>
                )
              } else {
                if (user.disapproveReason == null) {
                  return (
                    <div className="text-center childs">
                      <p className="text-gray-1000 mb-2">Add reason to {(action === "Approve" ? "Approve Provider" : "Reject Provider")}</p>
                      <textarea
                        rows={5}
                        style={{ border: "1px solid #efefef", height: 250 }}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                      <span style={{ color: "red" }}>{validationError}</span>
                    </div>
                  )
                } else {
                  return (
                    <div className="childs text-center">
                      <span style={{ color: "red" }}>This user is already rejected.</span>
                    </div>
                  )
                }
              }
            })()}

          </ConfirmModal>
        )}
      </>
    );
  }

  return <>{comp}</>;
};

import React, { useState } from "react";
import { PlusSVG } from "../../../../../assets/svgComponents/table";
import { InputField } from "../../../../../components/InputField";
import { MultiSelection } from "../../../../../components/SelectionComponent/MultiSelection";
import { SelectionComponent } from "../../../../../components/SelectionComponent/SelectionComponent";
import { SelectIdCard } from "./SelectIdCard";

export const RenderField = ({
  selection,
  onBlur,
  onChange,
  selectedSiteId,
  selectSite,
  removeSelectedSite,
  option,
  setSelMediaList,
  name1,
  numberOfSign,
  orderValue,
  name2,
  handleNumberOfSign,
  handleOrderValue,
  selValue,
  onRemove,
  onSelect,
  totalSign,
  index

}) => {
  const [showMore2, setShowMore2] = useState(false);
console.log('selection', selection)
  switch (selection) {
    case "Order Value":
      return (
        <InputField
          type="text"
          name={name1}
          value={orderValue?orderValue.value:""}
          onBlur={onBlur}
          onChange={(e) => handleOrderValue(e,index)}
          placeholder={name1}
          label={name1}
          inputFieldSize="inputsizeSelect"

        />
      );
    case "Number Of Signs":
      return (
        <InputField
          type="text"
          name={name2}
          value={numberOfSign?numberOfSign.value:""}
          onBlur={onBlur}
          onChange={(e) => handleNumberOfSign(e,index)}
          placeholder={name2}
          label={name2}
          inputFieldSize="inputsizeSelect"
        />
      );
    case "Media":
      return (
        <MultiSelection
          options={option}
          //values={setSelMediaList}
          selValue={selValue}
          onSelect={onSelect}
          onRemove={onRemove}
        />
      );
    case "Location":
      return (
        <div className=" select_section ">
          <p className="mb-2">Select Location</p>
          <div className="d-flex justify-content-start">
            <div
              className="d-flex justify-content-start"
              style={{ width: "100%" }}
            >
              {selectedSiteId && selectedSiteId.length > 0 ? (
                selectedSiteId.slice(0, 3).map((id) => {
                  return <SelectIdCard id={id} onClick={removeSelectedSite} />;
                })
              ) : (
                <h6 style={{ color: "#808080" }}>
                  Select and add Site from the list
                </h6>
              )}
              {selectedSiteId && selectedSiteId.length > 3 ? (
                <div className="idbox me-1">
                  <h6
                    className="mt-2"
                    style={{ color: "#F99300", cursor: "pointer" }}
                    onClick={() => setShowMore2(!showMore2)}
                  >+{selectedSiteId.length - 3} more</h6>
                </div>
              ) : null}
            </div>

            <div onClick={selectSite} className="selectSiteBox">
              <PlusSVG fill="#F99300" />{" "}
              <span style={{ color: "#F99300", cursor: "pointer" }}>
                {selectedSiteId && selectedSiteId.length > 0
                  ? "Select more"
                  : "Select"}
              </span>
            </div>
          </div>
          {showMore2 ? (
            <div
              className="dropdownModal "
              style={{ right: "16%", height: "110px" }}
            >
              {selectedSiteId && selectedSiteId.length > 2
                ? selectedSiteId.slice(1, selectedSiteId.length).map((id) => {
                    return <SelectIdCard id={id} />;
                  })
                : null}
            </div>
          ) : null}
        </div>
      );
    default:
      return <div className="inputsizeSelect"></div>;
  }
};

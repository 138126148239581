import React, {useEffect, useContext} from "react";
import { Col, Row } from "react-bootstrap";
import { NotificationSVG } from "../../../../../assets/svgComponents/table";
import NavBar from "../../../../../components/navBar";
import BillingAddress from "../SitesBooking/BillingAddress";
import PriceDetails from "./PriceDetails";
import "./SitesPayment.scss";
import { emptyListingState,getBookingDetails,submitOrderAsync,prepareOrder,orderPaymentAsync,setOrderAmount} from "../../../../../services/reducers/listing-reducer";
import {useDispatch, useSelector} from "react-redux";
import BookingDetails from "../SitesBooking/BookingDetails";
import PaymentModes from "./PaymentModes";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { To2Decimal } from "../../../../../services/utility";
import ProjectContext from "../../../../../context/ProjectContext";

const SitesPayment = () => {
   
  const {handleActiveMenu} = useContext(ProjectContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const bookingDetails = useSelector((state)=> getBookingDetails(state.listing)); 
  const { billingAddress, profile, order,orderId,orderSubmitResult,orderSubmitError,subscriptionTermId,paymentCardId,paymentResult,orderPaymentError,orderAmount,paymentTermId} = useSelector((state)=> state.listing);
  const { addToast } = useToasts();

  // useEffect(async () => {  
  //   dispatch(prepareOrder()); 
  //   }, [dispatch,billingAddress]);

    // useEffect(async () => {
    //   if(orderId!=null){
    //     history.push("/customer");      
    //   }
    //   }, [orderId]);

      // useEffect(async () => {
      //   if(orderSubmitError){
      //     console.log('order submit error:', orderSubmitError);
      //     addToast(orderSubmitError[0],{ appearance: "error"});
      //   }        
      //   }, [orderSubmitError]);
          
      //   useEffect(async () => {
      //     if(orderSubmitResult){
      //       console.log(orderSubmitResult);
      //       addToast(orderSubmitResult.message,{ appearance: "success"});            
      //     }        
      //   }, [orderSubmitResult]);


        useEffect(async () => {
          if(orderPaymentError){
            console.log('order submit error:', orderPaymentError);
            addToast(orderPaymentError[0],{ appearance: "error"});
          }        
        }, [orderPaymentError]);

        useEffect(async () => {
          if(paymentResult){
            console.log(paymentResult);
            addToast("Payment Done!",{ appearance: "success"});  
            await dispatch(emptyListingState());
            handleActiveMenu("Orders");       
            history.push("/customer/orders");   
          }        
        }, [paymentResult]);


    // const handleSubmitOrder = async() => {
    //   console.log('submitting order',order);
    //   await dispatch(setOrderAmount(bookingDetails.totalAmount));
    //   dispatch(submitOrderAsync(order));
    // };

  const handlePayNow = async() => {
    const reqBody = {orderId:orderSubmitResult.data.orderId,paymentTermId:orderSubmitResult.data.paymentTermId,amount:orderSubmitResult.data.paymentTermAmount,paymentCardId:paymentCardId};
    console.log('payment order req', reqBody);
    addToast("please wait processing payment...",{ appearance: "success"});         
    dispatch(orderPaymentAsync(reqBody));                        
  };

  return (
    <>
      <NavBar
        navHeading="Payment"
        subHeading="Home • Sites • Bayward Market Square Area "
        subActiveHeading=" Payment"
        isInputField={false}
        notificationSVG={<NotificationSVG />}
        showButton={true}
        />

      <Row className="p-3">
        <Col md={5} className="  ">
          <div className="card_booking_details px-3 py-4">
            <BookingDetails data={bookingDetails}/> 
          </div>
          <div className="card_booking_details px-3 py-4 mt-2">
            <PriceDetails bookingDetails={bookingDetails}/> 
          </div>
        </Col>
        <Col className="">
        
          <div
            className="card_booking_details px-3 "
            style={{ height: "100vh" }}>

          {/* {(orderSubmitResult==null || orderSubmitResult.data==null)  && (
            <>
              <BillingAddress address={billingAddress} profile={profile}/>
              <div className="btn_blue_container d-flex align-items-end flex-column">
                <button className="btn_blue " onClick={() => handleSubmitOrder()}>
                  Submit Order
                </button>
              </div>
            </>
          )} */}
            
            
            {orderSubmitResult && orderSubmitResult.data.paymentTerms && orderSubmitResult.data.paymentTerms.map((pterm,index)=> (<div key={'pterm_' + index}>
              <PaymentModes />
              <div className="site_booking_card_heading py-2">Payment Terms</div>
                <h5 className="site_booking_card_heading05">
                  <Row className="py-1">
                    <Col>Period</Col>
                    <Col className="d-flex justify-content-end">{pterm.period}</Col>
                  </Row>
                </h5>
                <h5 className="site_booking_card_heading05">
                  <Row className="py-1">
                    <Col>Amount</Col>
                    <Col className="d-flex justify-content-end">${To2Decimal(pterm.amount)}</Col>
                  </Row>
                </h5>
                {pterm.isDownpayment && (
                 <h5 className="site_booking_card_heading05">
                  <Row className="py-1">
                    <Col>Downpayment</Col>
                    <Col className="d-flex justify-content-end text-danger"></Col>
                  </Row>
                </h5>
                )} 
                <hr /> 
                <div className="btn_blue_container d-flex align-items-end flex-column">
                  <button className="btn_blue " onClick={() => handlePayNow()}>
                    Pay Now
                  </button>
                </div>
            </div>)  )}
          
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SitesPayment;

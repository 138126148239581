import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router";

// import BtnWhite from "../../../../../components/button/BtnWhite";
// import Checkbox from "../../../../../components/checkbox/Checkbox";
// import { InputField } from "../../../../../components/InputField";
import BtnRadio from "../../BtnRadio/BtnRadio";
import BtnBlue from "../../button/BtnBlue";

const FiltersModal = () => {
  const history = useHistory();

  const handleProceed = () => {
    history.push("/provider/configurator/new-billable-item");
  };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="ps-2 modal_title">
            Filters <span className="badgeMain ms-1">Clear all</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ps-2">
          <div className="list_title pt-4 pb-3">
            <b>Status</b>
          </div>
          <div className=" pb-2">
            <div className="pe-4 pb-3">
              <BtnRadio name="Status" label="Unread" />
            </div>
            <div className="pe-4  pb-3">
              <BtnRadio name="Status" label="Read" />
            </div>
          </div>
          <div className="list_title pt-4 pb-3">
            <b>Time</b>
          </div>
          <div className=" pb-4">
            <div className="pe-4  pb-3">
              <BtnRadio name="Status" label="Last 30 days" />
            </div>
            <div className="pe-4  pb-3">
              <BtnRadio name="Status" label="Last 6 months" />
            </div>
            <div className="pe-4  pb-3">
              <BtnRadio name="Status" label="2021" />
            </div>
            <div className="pe-4  pb-3">
              <BtnRadio name="Status" label="2020" />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <BtnBlue
          // onClick={() => handleProceed()}
          >
            Apply
          </BtnBlue>
        </div>
      </Modal.Footer>
    </>
  );
};

export default FiltersModal;

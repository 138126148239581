import React from "react";
import { Table } from "react-bootstrap";
import TableBody from "./TableBody";
import TableFooter from "./TableFooter";
import TableHeader from "./TableHeader";

const TableComponents = (props) => {
  return (
    <>
      {props && props.tableHeading?.length > 0 ? (
        <>
          <Table className="m-0" responsive hover>
            <TableHeader {...props} />
            <TableBody {...props} />
          </Table>
        </>
      ) : (
        <h1>No Data</h1>
      )}
    </>
  );
};

export default TableComponents;

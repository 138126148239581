import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';


  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );


  

export const LineChart = ({graphDataTitles, graphData, bgColor, hoverBgColor}) => {
    //console.log(graphDataTitles, graphData, 'Line chart data');        
    const options = {
        responsive: true,
        plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
        },
    };
  
    
    
    const labels = graphDataTitles;
    const barData = graphData;
    /*
    const labels = [];
    const barData = [];
    const min = 100;
    const max = 1000;    
    for(let i = 1; i<=31; i++){
        labels.push(i);                
        const rand  = min + Math.random() * (max - min);          
        barData.push(rand);
    }

    */

  
    const data = {
        labels,
        datasets: [
            {
                label: 'Customer',
                data: barData,
                borderColor: '#3734A9',
                backgroundColor: '#3734A9',
                pointRadius: 6,
                pointBackgroundColor: "#fff"
            },           
        ],
    };

    return (
        <>  
            <Line options={options} data={data} className="mb-5"   />
        </>
    );
};
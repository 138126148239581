import React from "react";
import { Modal } from "react-bootstrap";
import { InputDatePicker } from "../../../../../components/InputDatePicker/InputDatePicker";
import SelectGroup from "../../../../../components/SelectGroup/SelectGroup";
import { SelectionComponent } from "../../../../../components/SelectionComponent/SelectionComponent";

export const CenterPopUpModal = ({
  show,
  setShow,
  title,
  footerText,
  datepicker,
  selection,
  handlefooterBtn,
  bodyText,
}) => {
  return (
    <Modal
      show={show}
      // size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen="sm-down"
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="w-25">
            <h5 style={{ fontSize: "18px", fontWeight: "300" }}>{title}</h5>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selection ? <SelectionComponent option={[]} /> : ""}
        {datepicker ? <InputDatePicker /> : ""}
        {bodyText ? bodyText : ""}
      </Modal.Body>
      <Modal.Footer
        className="popModalfooter justify-content-center"
        onClick={handlefooterBtn}
      >
        <span style={{ color: "#ffffff" }}>{footerText}</span>
      </Modal.Footer>
    </Modal>
  );
};

import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { CustomSort } from "../../../../../components/DataTable/CustomSort";
import { getServiceDetails } from "../../../../../services/Provider/Configurator/Inventory";
import { GridTable } from "../Inventory/components/ViewSiteInfo/GridTable";

export const DetailsPage = ({ offerDetails }) => {
  const [servicedetails, setServicedetails] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [audList, setaudList] = useState([]);
  const [conditons, setConditons] = useState([]);
  // const gridHeading = ["Service ID", "Name", "Description"];

  // const sitesBody = [
  //   "#ST21/1-3",
  //   "King Poster (170” x 35”)",
  //   "35 Bayward Market Square Area, Ottawa, Canada",
  // ];
  // const servicesAccessorName = ["serviceId", "name", "description"];
  // console.log("offerDetails", offerDetails);
  // useEffect(() => {
  //   if (offerDetails) {
  //     let serviceId =
  //       offerDetails && offerDetails.servicesIncluded[0].serviceId;
  //     getServiceDetails(serviceId)
  //       .then((res) => {
  //         // console.log('resdata', res)
  //         if (res.status === 200) {
  //           setServicedetails([res.data]);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   }
  // }, [offerDetails]);

  useEffect(() => {
    setServicedetails(offerDetails.servicesIncluded);
    setSiteList(offerDetails.sitesIncluded);
    setaudList(offerDetails.audiences);
    setConditons(offerDetails.conditions);

  }, [])
  const getSitesName = () => {
    let siteArr;
    offerDetails.sitesIncluded.map((site) => {
      siteArr.push(site.name);
    })
    return (
      siteArr.join(', ')
    )
  }

  const serviceColumns = [
    {
      name: "SERVICE ID",
      selector: (row) => row.serviceId,
      sortable: true,
    },
    {
      name: "NAME",
      selector: (row) => row.service.name,
      sortable: true,
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.service.description,
      sortable: true,
    },
  ];

  const siteColumns = [
    {
      name: "SITE ID",
      selector: (row) => row.siteId,
      sortable: true,
    },
    {
      name: "NAME",
      selector: (row) => row.site.name,
      sortable: true,
    },
    {
      name: "LOCATION",
      selector: (row) => row.site?.location?.googleAddress,
      sortable: true,
    },
    {
      name: "ENVIRONMENT",
      selector: (row) => row.site?.environment?.name,
      sortable: true,
    },
  ];
  return (
    <div className="offer_main_container px-3 mt-4">
      <div className="offer_overview_container">
        <h6 className="mt-4">Offer Overview</h6>
        <div>
          <div className="data_container">
            <p>ID/CODE</p>
            {/* <span>{offerDetails && offerDetails.id.substring(0, 5)}</span> */}
            <span>{offerDetails && offerDetails.id}</span>
          </div>
          <div className="data_container">
            <p>STATUS</p>
            <span>{offerDetails && offerDetails.status}</span>
          </div>
          <div className="data_container">
            <p>OFFER TYPE</p>
            <span>{offerDetails && offerDetails.offerTypeName}</span>
          </div>
          <div className="data_container">
            <p>START DATE</p>
            <span>{offerDetails && moment(offerDetails.startDate).format("DD-MMM-YYYY")}</span>
          </div>
          <div className="data_container">
            <p>END DATE</p>
            <span>{offerDetails && moment(offerDetails.endDate).format("DD-MMM-YYYY")}</span>
          </div>
          <div className="discription w-50">
            <h6>Description</h6>
            <p>{offerDetails && offerDetails.details}</p>
          </div>
          <div className="service_include">
            <h6>SERVICE INCLUDED</h6>

            <div className="card customtable">
              <DataTable
                // progressPending={loader}
                columns={serviceColumns}
                data={servicedetails}
                sortFunction={<CustomSort />}
                allowOverflow={true}
                fixedHeader={{
                  fixedHeader: true,
                  fixedHeaderScrollHeight: "300px",
                }}
                pagination
              />
            </div>
          </div>
        </div>
      </div>
      <div className="offer_overview_container">
        <h6 className="mt-4">Offer Condition</h6>
        <div>
          {conditons &&
            conditons.map((elm, index) => {
              // if (elm == "Site"){
              //   return <></>
              // }else{
              return (
                <div className="condition_container">
                  <p>{`CONDITION ${index + 1}`}</p>
                  {elm.conditionOnName === "Media" ?
                    (
                      <span>{`${elm.conditionOnName} ${elm.operatorName} ${offerDetails.mediaTypes.map(({
                        signMediaTypeText
                      }) => signMediaTypeText).join(', ')}`}</span>
                    ) : (elm.conditionOnName === "Site" ? (
                      <span>{`${elm.conditionOnName} ${elm.operatorName} ${offerDetails.sitesIncluded.map(({
                        site
                      }) => site.name).join(', ')}`}</span>
                    ) : (
                      <span>{`${elm.conditionOnName} ${elm.operatorName} ${elm.value}`}</span>
                    )

                    )
                  }

                </div>
              );
              // }
            })}
          <div className="service_include">
            <h6>SITES ARE</h6>
            <div className="card overflow-hidden customtable">
              <DataTable
                // progressPending={loader}
                columns={siteColumns}
                data={siteList}
                sortFunction={<CustomSort />}
                allowOverflow={true}
                fixedHeader={{
                  fixedHeader: true,
                  fixedHeaderScrollHeight: "300px",
                }}
                pagination
              />
            </div>
          </div>
        </div>
      </div>
      <div className="offer_overview_container  mt-4 border-bottom pb-4">
        <h6 className="">Audience Defination</h6>
        <div>
          {audList &&
            audList.map((elm, index) => {
              return (
                <div className="condition_container">
                  <p>{`CONDITION ${index + 1}`}</p>
                  <span>{`${elm.audienceTypeName} ${elm.operatorName} ${elm.value}`}</span>
                </div>
              );
            })}
        </div>
      </div>
      {/* <hr /> */}
      <div className="offer_overview_container mb-5 mt-4">
        <h6 className="">Usage</h6>
        {offerDetails.unlimited && <div><p>UNLIMITED</p></div>}
        {!offerDetails.unlimited && (
          <div>
            <div className="condition_container">
              <p>TOTAL NUMBER OF USES </p>
              <span>{offerDetails.unlimited ? '' : offerDetails.totalNumberOfUses}</span>
            </div>
            <div className="condition_container">
              <p>USES PER PERSON</p>
              <span>{offerDetails.unlimited ? '' : offerDetails.usesPerPerson}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { getAllTransactions } from "../../../../../services/Provider/OrderAndTransactions/OrderAndTransactions";
import { CustomSort } from "../../../../../components/DataTable/CustomSort";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
// import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const columns = [
  {
    name: "id".toUpperCase(),
    selector: (row) => row.transactionId,
    sortable: true,
    sortField: "Id",
  },
  {
    name: "DATE".toUpperCase(),
    selector: (row) => row.transactionDate,
    sortable: true,
    sortField: "TransactionDate",
  },
  {
    name: "amount".toUpperCase(),
    selector: (row) => row.amount,
    sortable: true,
    sortField: "Amount",
  },
  {
    name: "typeText".toUpperCase(),
    selector: (row) => row.typeText,
    sortable: true,
    sortField: "TypeText",
  },
  {
    name: "orderId".toUpperCase(),
    selector: (row) => row.orderId,
    sortable: true,
    sortField: "OrderId",
  },
];
export const TransactionPage = ({ setActiveTab, setSelectedOrdersCard }) => {
  const [transactionData, setTransactionData] = useState();
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const history = useHistory();
  const location = useLocation();

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    setSortColumn({ column: column, sortDirection: sortDirection });
  };

  const loadData = async () => {
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      providerId: "",
      transactionTypes: [],
      sortingBy: 0,
    };
    setLoader(true);
    await getAllTransactions(reqBody)
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data.data, "trans res");
          setTransactionData(res.data.data);
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const loadExportData = async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      providerId: "",
      transactionTypes: [],
      sortingBy: 0,
    };
    await getAllTransactions(reqBody)
      .then((res) => {
        if (res.status == 200) {
          downloadCSV(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(async () => {
    loadData();
  }, [currentPage, pageSize, sortColumn]);

  useEffect(() => {
    loadData();
  }, []);

  const handleSelectRow = (item) => {
    console.log(item.orderId);
    setActiveTab("ORDERS");
    setSelectedOrdersCard(item.orderId);
    // history.push("/provider/order&transactions");
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array.records);
    if (csv == null) return;

    const filename = "export-transactions.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <button
      className="btn btn-primary"
      onClick={(e) => onExport(e.target.value)}
    >
      Export
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );

  return (
    // <Grid
    //   loader={loader}
    //   tableHeading={tranHeading}
    //   accessorName={tranAccessorName}
    //   tableBody={transactionData}
    //   handleSelectRow={handleSelectRow}
    // />

    <div className="card mt-4">
      {transactionData && transactionData.records && (
        <DataTable
          progressPending={loader}
          columns={columns}
          data={transactionData.records}
          allowOverflow={true}
          paginationDefaultPage={currentPage}
          pagination
          paginationServer
          paginationTotalRows={transactionData.totalRecords}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={pageSize}
          selectableRows
          dense
          onSort={handleSort}
          sortServer
          fixedHeader={{
            fixedHeader: true,
            fixedHeaderScrollHeight: "300px",
          }}
          actions={actionsMemo}
        />
      )}
    </div>
  );
};

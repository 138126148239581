import React, {useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { SelectionComponent } from "../../../../components/SelectionComponent/SelectionComponent";
import {useDispatch, useSelector} from "react-redux";
import { postReviewAsync, getOrderAsync, updateReviewAsync } from "../../../../services/reducers/orders-reducers";
import { useToasts } from "react-toast-notifications";
import { Rating } from 'react-simple-star-rating'
import { TextAria } from "../../../../components/TextAria";

export const AddReviewModal = ({
  show,
  setShow,
  order
}) => {
  
  const dispatch = useDispatch();
  const [comment,setComment] = useState(null);  
  const [rating,setRating] = useState(null);  
  const [title,setTitle] = useState('Add Review');
  const [reviewId,setReviewId] = useState(null);
  const { addReviewResult } = useSelector((state)=> state.orders);
  const { addToast } = useToasts();

  useEffect( async()=>{
    if(addReviewResult){
      if(addReviewResult.status===1){        
        setShow(false);
        addToast(addReviewResult.message,{ appearance: "success"});  
        await dispatch(getOrderAsync(order.id));
      }else{
        addToast(addReviewResult.message,{ appearance: "error"});  
      }
    }
  },[addReviewResult]);

  useEffect(()=>{
    if(order && order.orderReivewResponse && order.orderReivewResponse.myReviewId){
      setRating(order.orderReivewResponse.myRating);
      setComment(order.orderReivewResponse.myComment);
      setReviewId(order.orderReivewResponse.myReviewId);
      setTitle("Edit Review");
    }else{
      setTitle("Add Review");
      setReviewId(null);
      setRating(0);
      setComment(null);
    }
  },[order])

   // Catch Rating value
   const handleRating = (rate: number) => {
    setRating(rate/20)
    // other logic
  }
  
  const handleOk = async() => {
    if(title==="Add Review"){
      await dispatch(postReviewAsync(
        {
          listingId:order.listingId,
          rating:rating,
          comment: comment
        }));
    }else{
      let reqBody= [
        {
          "path": "rating",
          "op": "add",
          "value": rating
        },
        {
          "path": "comment",
          "op": "add",
          "value": comment
        }
      ];
      await dispatch(updateReviewAsync({reviewId, reqBody}));
    }
    
  }

  
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen="sm-down"
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"> 
            <h5 style={{ fontSize: "18px", fontWeight: "300" }}>{title}</h5> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>      
      <div style={{textAlign:'center'}}> 
        <Rating onClick={handleRating} ratingValue={rating*20}  />
      </div>
      <div className="d-flex justify-content-between mt-2"> 
        <TextAria
            label={"Review"}   
            name={"Review"}             
            placeholder="write review here..."
            ClassName={"textContainer"}
            value={comment}
            onChange={(e)=>setComment(e.target.value)}
        />
      </div>
      </Modal.Body>
      <Modal.Footer
        className="popModalfooter justify-content-center"
        onClick={handleOk}      >
        <span style={{ color: "#ffffff" }}>Save</span>
      </Modal.Footer>
    </Modal>
  );
};

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
// import 'react-toastify/dist/ReactToastify.css';
import NavBar from "../../../../components/navBar";
import { NotificationSVG } from "../../../../assets/svgComponents/table";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getExtendedOrderAsync,
  extendedOrderPaymentAsync,
} from "../../../../services/reducers/orders-reducers";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import PaymentModes from "./SitesPayment/PaymentModes";
import { isMobile } from "react-device-detect";
import { To2Decimal } from "../../../../services/utility";

export const ExtendOrderPayment = ({ logout }) => {
  const history = useHistory();
  const [openNav, setOpenNav] = useState(false);
  const { orderId } = useParams();
  const { extendedOrder, extendedOrderPaymentResult } = useSelector(
    (state) => state.orders
  );
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { paymentCardId } = useSelector((state) => state.listing);

  // useEffect(() => {
  //   if (isMobile) {
  //     let url = window.location.href;
  //     url = url.replace("https", "gopinit");
  //     url = url.replace("http", "gopinit");
  //     window.location.replace(url);
  //   }
  // }, [isMobile]);

  useEffect(() => {
    if (orderId) {
      dispatch(getExtendedOrderAsync(orderId));
    }
  }, [dispatch]);

  const handlePayNow = async () => {
    const reqBody = {
      orderId: orderId,
      paymentTermId: extendedOrder.payablePaymentTerm.paymentTermId,
      amount: extendedOrder.payablePaymentTerm.paymentTermAmount,
      paymentCardId: paymentCardId,
    };
    console.log("extend payment order req", reqBody);
    addToast("please wait processing payment...", { appearance: "success" });
    dispatch(extendedOrderPaymentAsync(reqBody));
  };

  useEffect(async () => {
    if (extendedOrderPaymentResult) {
      console.log(extendedOrderPaymentResult);
      if (extendedOrderPaymentResult[0]) {
        addToast(extendedOrderPaymentResult[1], { appearance: "success" });
        history.push("/customer");
      } else {
        addToast(extendedOrderPaymentResult[1], { appearance: "error" });
      }
    }
  }, [extendedOrderPaymentResult]);

  return (
    <>
      <NavBar
        isInputField={true}
        notificationSVG={<NotificationSVG />}
        navHeading="Extend Order Payment"
        subHeading=""
        showButton={false}
      />
      <div className="home-container gridSection g-0">
        {extendedOrder && (
          <>
            <PaymentModes />
            <div className="site_booking_card_heading py-2">Payment Terms</div>
            {extendedOrder &&
              extendedOrder.paymentTerms &&
              extendedOrder.paymentTerms.map((pterm, index) => (
                <div key={"pterm_" + index}>
                  {pterm.installmentNo > 0 && (
                    <div className="site_booking_card_heading py-2">
                      Installment No. {pterm.installmentNo}
                    </div>
                  )}
                  <h5 className="site_booking_card_heading05">
                    <Row className="py-1">
                      <Col>Period</Col>
                      <Col className="d-flex justify-content-end">
                        {pterm.period}
                      </Col>
                    </Row>
                  </h5>
                  <h5 className="site_booking_card_heading05">
                    <Row className="py-1">
                      <Col>Amount</Col>
                      <Col className="d-flex justify-content-end">
                        ${To2Decimal(pterm.amount)}
                      </Col>
                    </Row>
                  </h5>
                  {pterm.isDownpayment && (
                    <h5 className="site_booking_card_heading05">
                      <Row className="py-1">
                        <Col>Downpayment</Col>
                        <Col className="d-flex justify-content-end text-danger"></Col>
                      </Row>
                    </h5>
                  )}
                </div>
              ))}

            {extendedOrder.payablePaymentTerm && (
              <>
                <hr />
                <div className="btn_blue_container d-flex align-items-end flex-column">
                  <button className="btn_blue " onClick={() => handlePayNow()}>
                    Pay Now
                  </button>
                </div>
              </>
            )}

            {!extendedOrder.payablePaymentTerm && (
              <>
                <hr />
                <div className="btn_blue_container d-flex align-items-center flex-column">
                  No pending payment!
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { Card, Form, Image, Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useHistory } from "react-router";
import { UploadPlus } from "../../assets/svgComponents/inventorySVG";
import {
  SpringSVG,
  SVGCalendar,
  DownloadSVG,
} from "../../assets/svgComponents/svg";
import { NotificationSVG, PlusSVG } from "../../assets/svgComponents/table";
import { AddressCard } from "../AddressCard/AddressCard";
import { AtmCard } from "../AtmCard/AtmCard";
import ConfirmModal from "../confirmModal/ConfirmModal";
import { InputField } from "../InputField";
import NavBar from "../navBar";
import UpdatesModal from "./Modal/UpdatesModal";
import profileImg from "../../assets/images/profileImg.jpg";
import { useToasts } from "react-toast-notifications";

import {
  defaultAddress,
  deletecard,
  genrateOTP,
  getAddressList,
  makeDefoulAddress,
  makeDefoulPaymentCard,
  removeAddress,
  updateAddress,
  verifyOTP,
} from "../../services/Provider";
import {
  getCountryList,
  getStateList,
  getCityList,
  addAddress,
  getRecentTopFivePaymentCard,
} from "../../services/Common";
import { useFormik } from "formik";
import { InputDatePicker } from "../InputDatePicker/InputDatePicker";
import AddNewCardModal from "./Modal/AddNewCardModal";
import { getImageName } from "../../services/Provider/Configurator/Inventory";
import {
  currentUser,
  userList,
} from "../../services/Provider/UserManagement/UserManagement";
import UpdateCardModal from "./Modal/UpdateCardModal";
import { UpdateAddressModal } from "./Modal/UpdateAddressModal";
import { AddNewAddressModal } from "./Modal/AddNewAddressModal";
import { LoginModal } from "../../components/Modals/LoginModal";

export const Profile = ({ title, subTitle, provider, userType }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [activeCard, setActiveCard] = useState(false);
  const [addNewAddressModal, setAddNewAddressModal] = useState(false);
  const [updateAddressModal, setUpdateAddressModal] = useState(false);
  const [addNewCardModal, setAddNewCardModal] = useState(false);
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);
  const [completeProfileModal, setCompleteProfileModal] = useState(false);
  const [countrylist, setCountrylist] = useState();
  const [statelist, setStatelist] = useState();
  const [cityList, setCityList] = useState();
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedState, setSelectedState] = useState([{}]);
  const [selectedCity, setSelectedCity] = useState([{}]);
  const [cardList, setCardList] = useState();
  const [user, setUser] = useState();
  const [addessList, setAddessList] = useState();
  const [mobileVerificationModal, setMobileVerificationModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [profileImage, setProfileImage] = useState();
  const [logo, setLogo] = useState();
  const [proof, setProof] = useState();
  const [updateCardDetails, setUpdateCardDetails] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteAddressConfirmModal, setDeleteAddressConfirmModal] =
    useState(false);
  const [selectCardId, setSelectCardId] = useState();
  const [selectAddressId, setSelectAddressId] = useState();
  const [defoultaddressConfirmModal, setDefoultaddressConfirmModal] =
    useState(false);
  const [selecltAddresscard, setSelecltAddresscard] = useState();
  const [selcardToUpdate, setSelcardToUpdate] = useState();
  const [isDefaultAdressDeleted, setIsDefaultAdressDeleted] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [refresh, setRefresh] = useState(false);

  console.log("user", user);

  useEffect(() => {
    currentUser()
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data);
          let id = res.data.id;

          getAddressList(id)
            .then((res) => {
              if (res.status === 200) {
                setAddessList(res.data);
                if (isDefaultAdressDeleted) {
                  if (res.data.length >= 1) {
                    makeDefoultAddrees(res.data[0]);
                  }
                }
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [
    defoultaddressConfirmModal,
    updateAddressModal,
    addNewAddressModal,
    mobileVerificationModal,
  ]);

  useEffect(() => {
    if (user) {
      if (user.profilePicture) {
        setProfileImage(
          `https://gopinit.blob.core.windows.net/image-container/${
            user && user.profilePicture
          }`
        );
      }
    }
    if (provider) {
      if (user?.provider?.logo) {
        setLogo(
          `https://gopinit.blob.core.windows.net/image-container/${
            user && user.provider?.logo
          }`
        );
      }
      if (user?.provider?.proof) {
        setProof(
          `https://gopinit.blob.core.windows.net/image-container/${
            user && user.provider?.proof
          }`
        );
      }
    }
  }, [user]);

  const handleOnEditbtn = () => {
    provider
      ? history.push("/provider/editProfile")
      : history.push("/customer/editProfile");
  };

  const handleAddNewAddress = () => {
    /* formik.values.address="";
    formik.values.pincode="";
    formik.errors.address="";
    formik.errors.pincode=""; */
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
    if (userType === "Provider") {
      if (user.provider?.onBoardingStepDone < 6) setCompleteProfileModal(true);
      else setAddNewAddressModal(!addNewAddressModal);
    } else setAddNewAddressModal(!addNewAddressModal);
  };

  const handleAddNewCard = () => {
    if (userType === "Provider") {
      if (user.provider?.onBoardingStepDone < 6) setCompleteProfileModal(true);
      else setAddNewCardModal(true);
    } else setAddNewCardModal(true);
  };

  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  useEffect(() => {
    getRecentTopFivePaymentCard()
      .then((res) => {
        if (res.status === 200) {
          setCardList(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [
    addNewCardModal,
    updateCardDetails,
    defoultaddressConfirmModal,
    deleteConfirmModal,
  ]);

  useEffect(() => {
    getCountryList()
      .then((res) => {
        if (res.status === 200) {
          setCountrylist(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    getStateList(1)
      .then((res) => {
        if (res.status === 200) {
          setStatelist(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    getCityList(3870)
      .then((res) => {
        if (res.status === 200) {
          setCityList(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const handleCountryList = (value) => {
    let countryId = value.target.value;
    let country = countrylist.filter((item) => item.id == countryId);
    setSelectedCountry(country);
    setSelectedState([]);
    setSelectedCity([]);

    getStateList(countryId)
      .then((res) => {
        if (res.status === 200) {
          setStatelist(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    // console.log("country", value.target.value);
  };
  const handleStateList = (value) => {
    let stateId = value.target.value;
    let state = statelist.filter((item) => item.id == stateId);
    setSelectedState(state);
    setSelectedCity([]);
    getCityList(stateId)
      .then((res) => {
        if (res.status === 200) {
          setCityList(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleCityList = (value) => {
    let cityId = value.target.value;
    let city = cityList.filter((item) => item.id == cityId);
    setSelectedCity(city);
  };
  const editAddress = (addCard) => {

    setSelecltAddresscard(addCard);
    setUpdateAddressModal(true);
    let country = {
      name: addCard.country,
    };
    setSelectedCountry([country]);
    let state = {
      name: addCard.state,
    };
    setSelectedState([state]);
    let city = {
      name: addCard.city,
    };
    setSelectedCity([city]);
  };
  const deleteAddress = (addCard) => {
    setDeleteAddressConfirmModal(true);
    setSelectAddressId(addCard);
    console.log(selectAddressId);
  };

  //const addressError = formik.touched.address && formik.errors.address;
  //const pincodeError = formik.touched.pincode && formik.errors.pincode;

  const deteletePaymentCard = (id) => {
    setSelectCardId(id);
    setDeleteConfirmModal(true);
  };

  const confirmModalDelete = () => {
    deletecard(selectCardId)
      .then((res) => {
        console.log("res", res);
        if (res.status == 202) {
          setDeleteConfirmModal(false);
          addToast("Card has been deleted successfully.", {
            appearance: "success",
          });
        } else {
          addToast("Something went wrong.", { appearance: "error" });
        }
      })
      .catch((err) => {
        console.log("err", err);
        addToast("Something went wrong.", { appearance: "error" });
      });
  };

  const confirmModalAddressDelete = () => {
    setDefoultaddressConfirmModal(!defoultaddressConfirmModal);
    if (selectAddressId.isDefault) {
      setIsDefaultAdressDeleted(true);
    }

    removeAddress(selectAddressId && selectAddressId.id)
      .then((res) => {
        console.log("res", res);
        if (res.status == 202) {
          //setDeleteConfirmModal(false);
          setDeleteAddressConfirmModal(false);
          addToast("Address has been deleted successfully.", {
            appearance: "success",
          });
        } else {
          addToast("Something went wrong.", { appearance: "error" });
        }
      })
      .catch((err) => {
        console.log("err", err);
        addToast("Something went wrong.", { appearance: "error" });
      });
  };

  const confirmModalProfileComplete = () => {
    history.push("/provider");
  };

  const makeDefoultAddrees = (card) => {
    makeDefoulAddress(card.id)
      .then((res) => {
        if (res.status == 202) {
          setIsDefaultAdressDeleted(false);
          setDefoultaddressConfirmModal(!defoultaddressConfirmModal);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const makeDefoultCard = (card) => {
    makeDefoulPaymentCard(card.id)
      .then((res) => {
        if (res.status == 200) {
          setDefoultaddressConfirmModal(!defoultaddressConfirmModal);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const editCardDetails = (card) => {
    setSelcardToUpdate(card);
    setUpdateCardDetails(true);

  };
  const handleChangePassword = () => {
    provider
      ? history.push("/provider/change-password")
      : history.push("/customer/change-password");
  };

  const lsUerType = localStorage.getItem("userType");

  const mobileVerification = () => {
    setMobileVerificationModal(true);
    console.log("mobile verify");
    genrateOTP()
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  const verifyModal = () => {
    verifyOTP(otp)
      .then((res) => {
        if (res.status == 204) {
          console.log("res", res);
          setOtp("");
          setMobileVerificationModal(false);
        } else {
          setOtpError(res.error);
        }
      })
      .catch((err) => {
        if (err.response.data.status === 0) {
          setOtpError(err.response.data.error);
        }
        console.log("err", err);
      });
  };

  const handleOTP = (e) => {
    setOtpError("");
    setOtp(e.target.value);
  };

  let comp;
  if (lsUerType === "Temp") {
    comp = <LoginModal />;
  } else {
    comp = (
      <>
        <NavBar
          blueBtnText="Edit Profile"
          whiteBtnText="Change Password"
          notificationSVG={<NotificationSVG />}
          navHeading="Profile"
          subHeading={subTitle}
          handleBlueBtn={handleOnEditbtn}
          handleWhitebtn={handleChangePassword}
          handleNotificationSVG={handleNotificationSVG}
          showButton={true}
        />
        <div className="gridSection p-20">
          <div className="row d-flex h-100">
            <div className="col-md-3 flex-grow-1 h-100 overflow-auto">
              <div className="col-md-12 profileImageContainer bg-base-100 h-100">
                <div className="imageboxContainer w-100 border-bottom">
                  <div className="imageBox mb-4">
                    <img
                      src={
                        profileImage
                          ? profileImage
                          : "https://i.stack.imgur.com/l60Hf.png"
                      }
                      alt="profileImag"
                    />
                  </div>
                  <span className="providerName">{user && user.name}</span>
                  <p className="mb-4 text-sm">{user && user.emailId}</p>
                </div>
                <div className="customerName container-fluid px-3 customerDetails">
                  <div className="row g-0">
                    <div className="col-md-12 mb-3">
                      <div className="row">
                        <div className="col-md-6">
                          <p>{`${userType} Name`}</p>
                        </div>

                        <div className="col-md-6">
                          <span>{user && user.name}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row">
                        <div className="col-md-6">
                          <p> Email Address</p>
                        </div>

                        <div className="col-md-6">
                          <span>{user && user.emailId}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row">
                        <div className="col-md-6">
                          <p>Mobile Number</p>
                        </div>

                        <div className="col-md-6">
                          <span className="mb-1 d-inline-block">
                            {user && user.contactNo}{" "}
                            {user && user.contactNoVerified && (
                              <SpringSVG width="15" height="15" />
                            )}
                          </span>
                          {user && !user.contactNoVerified && (
                            <p
                              onClick={mobileVerification}
                              style={{ cursor: "pointer", color: "#F99300" }}
                            >
                              Verify Mobile
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9 flex-grow-1 h-100 overflow-auto">
              <div className="col-md-12 profileInfoContainer bg-base-100 h-100 overflow-auto">
                {provider ? (
                  <div className="organizatonDetailsContainer border-bottom">
                    <p className="mt-4">Organization Details</p>
                    <div className="nameBox">
                      <p>Name</p>
                      <span>{user && user.provider?.name}</span>
                    </div>
                    <div className="gstBox">
                      <p>GST NUMBER</p>
                      <span>{user && user.provider?.taxInfo}</span>
                    </div>
                    <div className="logoBox">
                      <p>LOGO</p>
                      <span>
                        <div className="logoContainer">
                          <a download="image.jpg" href={logo} target="_blank">
                            {logo && <img src={logo} alt="logo" />}
                          </a>
                        </div>
                      </span>
                    </div>
                    <div className="proofBox">
                      <p>REGISTRATION PROOF</p>
                      {proof && (
                        <span>
                          <div className="mt-4">
                            <a
                              className="btn btn-light"
                              download="image.jpg"
                              href={`https://gopinit.blob.core.windows.net/image-container/${user?.provider?.proof}`}
                              target="_blank"
                            >
                              <p>
                                {/* <Collateral fill="#58B85C" /> */}
                                <DownloadSVG fill="#58B85C" />
                              </p>
                            </a>
                          </div>
                        </span>
                      )}
                    </div>
                  </div>
                ) : null}
                <div className="bilingDetailContainr border-bottom pb-3">
                  <p className="mt-4">Billing Addresses</p>
                  <div className="addressCardContainer">
                    {addessList &&
                      addessList.map((address, index) => {
                        return (
                          <AddressCard
                            address={address}
                            user={user}
                            makeDefoultAddrees={makeDefoultAddrees}
                            index={index}
                            deleteAddress={() => deleteAddress(address)}
                            editAddress={editAddress}
                          />
                        );
                      })}
                    {userType === "Provider" ? (
                      user?.provider &&
                      user.provider?.onBoardingStepDone > 4 && (
                        <div className="addressCard">
                          <div className="h-100 uploadCard d-flex justify-content-center align-items-center">
                            <div
                              className="cardBody"
                              onClick={() => handleAddNewAddress()}
                            >
                              <UploadPlus />
                              <p>Add New Address</p>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="addressCard">
                        <div className="h-100 uploadCard d-flex justify-content-center align-items-center">
                          <div
                            className="cardBody"
                            onClick={() => handleAddNewAddress()}
                          >
                            <UploadPlus />
                            <p>Add New Address</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="paymentDetailContainer pb-3">
                  <p className="mt-4 mb-3">Payment Details</p>
                  <div className="cardContainer row">
                    {cardList &&
                      cardList.map((card, index) => {
                        return (
                          <AtmCard
                            card={card}
                            editCardDetails={editCardDetails}
                            deteletePaymentCard={deteletePaymentCard}
                            index={index}
                            makeDefoultCard={makeDefoultCard}
                          />
                        );
                      })}
                    {userType === "Provider" ? (
                      user?.provider &&
                      user.provider?.onBoardingStepDone > 4 && (
                        <div className="col-md-4 ">
                          <div className="col-md-12 card1 uploadCard borderAll bg-base-100 d-flex justify-content-center align-items-center">
                            <div
                              className="cardBody"
                              onClick={() => handleAddNewCard()}
                            >
                              <UploadPlus />
                              <p>Add New Card</p>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="col-md-4 ">
                        <div className="col-md-12 card1 uploadCard borderAll bg-base-100 d-flex justify-content-center align-items-center">
                          <div
                            className="cardBody"
                            onClick={() => handleAddNewCard()}
                          >
                            <UploadPlus />
                            <p>Add New Card</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {mobileVerificationModal && (
          <ConfirmModal
            title="Verify mobile"
            isModalHeader={true}
            isFooterBtns={false}
            confirmText="Verify"
            handleClose={() => {
              setMobileVerificationModal(false);
              setOtp("");
              setOtpError("");
            }}
            handleConfirm={verifyModal}
            width="400px"
            disable={otp.length == 6 ? false : true}
          >
            <div className="childs">
              <p
                className="mb-3"
                style={{ color: "#808080" }}
              >{`Authentication code sent to ${user && user.emailId}`}</p>
              <InputField
                placeholder="OTP"
                label="OTP"
                value={otp}
                onChange={(e) => handleOTP(e)}
              />{" "}
              {otpError && (
                <p
                  className="text-danger mt-1 ms-2"
                  style={{ fontSize: "14px" }}
                >
                  {otpError}
                </p>
              )}
            </div>
          </ConfirmModal>
        )}
        {/* // add new address modal */}
        {addNewAddressModal && (
          <AddNewAddressModal
            handleCountryList={handleCountryList}
            handleStateList={handleStateList}
            handleCityList={handleCityList}
            selectedCity={selectedCity}
            selectedState={selectedState}
            selectedCountry={selectedCountry}
            selecltAddresscard={selecltAddresscard}
            setAddNewAddressModal={setAddNewAddressModal}
            countrylist={countrylist}
            statelist={statelist}
            cityList={cityList}
          />

          /*    <Form onSubmit={formik.handleSubmit}>
            <ConfirmModal
              title="Add New 
          Address"
              isModalHeader={true}
              isFooterBtns={false}
              confirmText="Save"
              handleClose={() => setAddNewAddressModal(false)}
              handleConfirm={formik.handleSubmit}
              type="submit"
            >
              <div className="childs">
                <InputField                  
                  placeholder="Address"
                  label="Address"
                  name="address"
                  value={formik.values.address}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}                  
                />{" "}
                 { formik.errors.address && (
                  <p className="text-danger" style={{ fontSize: "14px" }}>
                    {formik.errors.address}
                  </p>
                  )}                
              </div>
              <div className="childs">
                <InputField                  
                  placeholder="Postal code"
                  label="Postal code"
                  name="pincode"
                  value={formik.values.pincode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}                     
                />{" "}
                {formik.errors.pincode && (
                  <p className="text-danger" style={{ fontSize: "14px" }}>
                    {formik.errors.pincode}
                  </p>
                )}                
              </div>  
              <div className="childs">
                <SelectionComponent
                  label="Country"
                  option={countrylist}
                  handleOnchange={handleCountryList}
                />{" "}
              </div>
              <div className="childs">
                <SelectionComponent
                  label="State"
                  option={statelist}
                  handleOnchange={handleStateList}
                />{" "}
              </div>
              <div className="childs">
                <SelectionComponent
                  label="City"
                  option={cityList}
                  handleOnchange={handleCityList}
                />{" "}
              </div>          
            </ConfirmModal>
          </Form> */
        )}
        {updateAddressModal && (
          <UpdateAddressModal
            handleCountryList={handleCountryList}
            handleStateList={handleStateList}
            handleCityList={handleCityList}
            selectedCity={selectedCity}
            selectedState={selectedState}
            selectedCountry={selectedCountry}
            selecltAddresscard={selecltAddresscard}
            setUpdateAddressModal={setUpdateAddressModal}
            countrylist={countrylist}
            statelist={statelist}
            cityList={cityList}
          />
        )}

        {/* add new card */}
        {addNewCardModal && (
          <AddNewCardModal
            setShow={setAddNewCardModal}
            title={"Add New Card"}
          />
        )}

        {updateCardDetails && (
          <UpdateCardModal
            setShow={setUpdateCardDetails}
            title={"Update Card Details"}
            selcardToUpdate={selcardToUpdate}
          />
        )}

        {deleteConfirmModal && (
          <ConfirmModal
            title="Delete Card"
            isModalHeader={true}
            isFooterBtns={true}
            cancelText="No"
            confirmText="Yes"
            handleClose={() => setDeleteConfirmModal(false)}
            handleConfirm={confirmModalDelete}
            width="400px"
          >
            <div className="childs">
              <p className="newListingCreated_description ms-4 py-3">
                Are you sure you want to delete card?
              </p>
            </div>
          </ConfirmModal>
        )}

        {deleteAddressConfirmModal && (
          <ConfirmModal
            title="Delete Address"
            isModalHeader={true}
            isFooterBtns={true}
            cancelText="No"
            confirmText="Yes"
            handleClose={() => setDeleteAddressConfirmModal(false)}
            handleConfirm={confirmModalAddressDelete}
            width="400px"
          >
            <div className="childs">
              <p className="newListingCreated_description ms-4 py-3">
                Are you sure you want to delete this address?
              </p>
            </div>
          </ConfirmModal>
        )}

        {completeProfileModal && (
          <ConfirmModal
            title="Alert"
            isModalHeader={true}
            isFooterBtns={true}
            cancelText="No"
            confirmText="Yes"
            handleClose={() => setCompleteProfileModal(false)}
            handleConfirm={confirmModalProfileComplete}
            width="400px"
          >
            <div className="childs">
              <p className="newListingCreated_description ms-4 py-3">
                Please complete your organization detail.
              </p>
            </div>
          </ConfirmModal>
        )}

        {/* navbar filters modal  */}
        <Modal
          show={navbarUpdatesModal}
          onHide={() => setNavbarUpdatesModal(false)}
          dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
        >
          <UpdatesModal />
        </Modal>
      </>
    );
  }

  return <>{comp}</>;
};

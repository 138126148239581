import { http } from "../../httpService";

export function userList(reqBody) {
  return http.post(`/api/User/List`, reqBody);
}
export function providerList(reqBody) {
  return http.post(`/api/Provider/ProviderDetailsList`, reqBody);
}

export function verifyProvider(reqBody) {
  return http.post(`/api/Provider/Verify`, reqBody);
}

export function userListReview(reqBody) {
  return http.post(`/api/Review/AdminUserReview`, reqBody);
}

export function currentUser(reqBody) {
  return http.get(`/api/User/CurrentUser`, reqBody);
}

export function addInviteUser(reqBody) {
  return http.post(`/api/User/InviteUsers`, reqBody, { timeout: 100000 });
}

export function updateUser(reqBody) {
  return http.patch(`/api/User/Update`, reqBody);
}

export function updateInviteUser(id, reqBody) {
  return http.patch(`/api/User/${id}`, reqBody);
}

export function deleteUser(id) {
  return http.delete(`/api/User/${id}`);
}
export function getAccessTypes(reqBody) {
  return http.post(`/api/AccessType/Index`, reqBody);
}

export function saveAccessType(reqBody) {
  return http.post(`/api/AccessType`, reqBody);
}
export function updateAccessType(reqBody) {
  return http.post(`/api/User/BulkInsert`, reqBody);
}
export function changePassword(reqBody) {
  return http.post(`https://stsadminapi.gopinitpartner.com/api/Account/ChangePassword`, reqBody);
}

export function updateUserStatus(reqBody) {
  return http.post(`/api/User/UpdateUserStatus`, reqBody);
}

export function getProviderById(providerId) {
  return http.get(`/api/Provider/GetProviderById/${providerId}`);
}

export function getUserExistance(emailId) {
  return http.get(`/api/User/getUserExistance?emailId=${emailId}`);
}

export function getProviderConnectAccountId(providerId){
  return http.get(`/api/Provider/getConnectAccountId?providerId=${providerId}`);
}

export function saveProviderConnectAccountId(reqBody){
  return http.post(`/api/Provider/saveConnectAccountId`,reqBody);
}
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import image from "../../../../../../assets/images/r1.png";
import { CaretRight } from "../../../../../../assets/svgComponents/svg";
import { NotificationSVG } from "../../../../../../assets/svgComponents/table";
import NavBar from "../../../../../../components/navBar";
import { getReviewByListing } from "../../../../../../services/Admin/RatingAndReview";
import { getListingDetails } from "../../../../../../services/Provider/Configurator/Listings";
import { getImageUrl } from "../../../../../../services/utility";
import { Reviews } from "../../../../Admin/ReviewsAndRatings/Pages/Reviews";
import NewListingConfirmModal from "../../Listings/pages/NewListings/modals/NewListingConfirmModal";
import FeaturedListingModal from "../../Listings/pages/NewListings/modals/FeaturedListingModal";
import OnBoardingFeatured from "../../Listings/pages/NewListings/modals/OnBoardingFeatured";
import moment from "moment";
import { checkPermission } from "../../../../../../utils";

const ListingDetailsPage = (props) => {
  // console.log(props.location.state, "site details");
  const { tabs, selectedRow, isBundleInformation } = props.location.state;
  const history = useHistory();
  const { addToast } = useToasts();
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [detailsData, setDetailsData] = useState();
  const [signTabs, setSignTabs] = useState();
  const [activeSignsTabs, setActiveSignsTabs] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [listingCreatedModal, setListingCreatedModal] = useState(false);
  const [featuredListing, setFeaturedListing] = useState(false);
  const [registerListingModal, setRegisterListingModal] = useState(false);
  const [featuredDate, setFeaturedDate] = useState({
    startDate: new Date(),
    endDate: null,
  });

  const pricingColumns = [
    {
      name: "QUANTITY".toUpperCase(),
      selector: (row) =>
        row.priceTypeText === "Fixed"
          ? "-"
          : row.minimumQuantity + "-" + row.maximumQuantity,
      sortable: true,
    },
    {
      name: "PRICING".toUpperCase(),
      selector: (row) => "$ " + row.amount,
      sortable: true,
    },
  ];
  const summmaryChargesColumns = [
    {
      name: "Service / Charge".toUpperCase(),
      selector: (row) =>
        row.service ? (
          <>{row.service?.id.slice(0, 3) + "-" + row.service?.name}</>
        ) : (
          <>{row.charge?.id.slice(0, 3) + "-" + row.charge?.name}</>
        ),
      sortable: true,
    },
    {
      name: "Type".toUpperCase(),
      selector: (row) => row.chargesType,
      sortable: true,
    },
    {
      name: "PRICING".toUpperCase(),
      selector: (row) => (
        <b>
          {row.priceType === "Fixed" ? (
            <>{row.priceValue}</>
          ) : (
            <>{row.priceValue} % of bill</>
          )}
        </b>
      ),
      sortable: true,
    },
  ];
  const refundsRuleColumns = [
    {
      name: "Refund Type".toUpperCase(),
      selector: (row, i) => (
        <>
          <div className="d-flex">
            <div className="pe-5">{row.refundPercentage}</div>
            {i == 0 && (
              <div>
                {" "}
                Should be refunded when order status is Order Received.
              </div>
            )}
            {i == 1 && (
              <div>
                Should be refunded when order status is Order Processed.
              </div>
            )}
            {i == 2 && (
              <div>
                Should be refunded when order status is Order in Installation.
              </div>
            )}
            {i == 3 && (
              <div>Should be refunded when order status is Order Complete.</div>
            )}
          </div>
        </>
      ),
      sortable: true,
    },
  ];

  // fetch listing details data
  useEffect(async () => {
    let ListingItemId = "";
    if (selectedRow.itemType === "Sign") {
      ListingItemId = selectedRow.signDetails[0].itemId;
    } else {
      ListingItemId = selectedRow.siteDetails[0].itemId;
    }
    await getListingDetails(ListingItemId)
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data, "detailsData");
          let tempSignTabs = [];
          if (res.data.signs) {
            for (let i = 0; i < res.data.signs.length; i++) {
              tempSignTabs = [...tempSignTabs, `SIGN ${i + 1}`];
            }
          }
          // console.log(tempSignTabs, "tempSignTabs");
          setSignTabs(tempSignTabs);
          setDetailsData(res.data);
        }
      })
      .catch((err) => console.log(err));
    await getReviewByListing(selectedRow.listingId)
      .then((res) => {
        if (res.status === 200) {
          setReviews(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSignTabChange = (index) => {
    setActiveSignsTabs(index);
  };

  // const imageUrl = (imageId) => {
  //   getImageUrl(imageId)
  //     .then((res) => {
  //       if (res.status == 200) {
  //         return res.data;
  //         // console.log(res.data, "res");
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };
  const handleSiteViewDetailsPage = (site) => {
    history.push({
      pathname: "/provider/configurator/inventory/view-site",
      state: site,
    });
  };

  const handleConfirmListCreatedModal = () => {
    setListingCreatedModal(false);
    setFeaturedListing(true);
  };

  const onChangeFeaturedDate = (dates) => {
    const [start, end] = dates;
    // setStartDate(start);
    // setEndDate(end);
    setFeaturedDate({
      startDate: start,
      endDate: end,
    });
  };

  const handleRegisterDateFetaured = () => {
    // alert("hello");
    if (featuredDate.endDate) {
      let tempFeaturedListingDetails = {
        listingId: detailsData.listingId,
        name: detailsData.name,
        price: detailsData.price,
        start_date: moment(featuredDate.startDate).format("DD MMMM YYYY"),
        end_date: moment(featuredDate.endDate).format("DD MMMM YYYY"),
      };
      // console.log(tempFeaturedListingDetails, "tempFeaturedListingDetails");

      history.push({
        pathname: `/provider/payment`,
        featuredListingDetails: tempFeaturedListingDetails,
      });
    } else {
      addToast("Please enter featured listing end date", {
        appearance: "warning",
      });
    }
  };

  const renderScreen = (activeTab) => {
    switch (activeTab) {
      case "INFORMATION":
        return (
          <>
            {!isBundleInformation ? (
              <div className="card_container mt-4">
                <Row>
                  <Col md={2}>
                    <div className="billable_card_title">LISTING TYPE</div>
                    <div className="billable_card_discription">
                      {detailsData?.type}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="billable_card_title">AVAILABLE FOR</div>
                    <div className="billable_card_discription">
                      {detailsData?.availableForText}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <div
                    style={{ fontSize: "16px", marginBottom: "10px" }}
                    className="mt-4"
                  >
                    Pricing
                  </div>
                  <Col md={2}>
                    <div className="billable_card_title">TIER PRICING</div>
                    <div className="billable_card_discription">
                      {detailsData?.priceDetail?.priceTypeText}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="billable_card_title">APPLICABLE DURING</div>
                    <div className="billable_card_discription">
                      {detailsData?.priceDetail.applicabilityText}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="billable_card_title">Price</div>
                    <div className="billable_card_discription">
                      {detailsData?.price}
                    </div>
                  </Col>
                </Row>
                {(selectedRow.itemType === "Sign" ||
                  selectedRow.chargesDetails.length > 0 ||
                  selectedRow.servicesDetails.length > 0) && (
                  <>
                    <Row className="mt-4">
                      <div
                        style={{ fontSize: "16px", marginBottom: "10px" }}
                        className="mt-4"
                      >
                        Subscription
                      </div>
                      <Col md={2}>
                        <div className="billable_card_title">
                          NUMBERS OF TERMS
                        </div>
                        <div className="billable_card_discription">
                          {detailsData?.subscriptionTerms?.term}
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="billable_card_title">DOWNPAYMENT</div>
                        <div className="billable_card_discription">
                          {
                            detailsData?.subscriptionTerms
                              ?.downPaymentPercentage
                          }
                        </div>
                      </Col>
                    </Row>
                    {detailsData?.rushFee != null && detailsData?.rushFee != 0 && (
                      <Row className="mt-4">
                        <div
                          style={{ fontSize: "16px", marginBottom: "10px" }}
                          className="mt-4"
                        >
                          Rush Fee
                        </div>
                        <Col md={2}>
                          {/* <div className="billable_card_title">Rush Fee</div> */}
                          <div className="billable_card_discription">
                            ${detailsData?.rushFee}
                          </div>
                        </Col>
                        {/* <Col md={2}>
                    <div className="billable_card_title">DOWNPAYMENT</div>
                    <div className="billable_card_discription">
                      {detailsData?.subscriptionTerms?.downPaymentPercentage}
                    </div>
                  </Col> */}
                      </Row>
                    )}
                    <Row className="mt-4">
                      <Col md={5}>
                        <div style={{ fontSize: "16px" }} className="mt-4">
                          Summary of Charges
                        </div>
                        <div className="card  mt-3">
                          <DataTable
                            fixedHeader
                            columns={summmaryChargesColumns}
                            data={
                              detailsData?.summaryChargesItems
                                ? detailsData?.summaryChargesItems
                                : []
                            }
                            allowOverflow={true}
                            highlightOnHover
                            pointerOnHover
                            // selectableRows
                            noDataComponent={
                              <div className="p-20">No Data available</div>
                            }
                          />
                        </div>
                      </Col>
                      <Col md={5}>
                        <div style={{ fontSize: "16px" }} className="mt-4">
                          Refund Rules
                        </div>
                        <div className="card  mt-3">
                          <DataTable
                            fixedHeader
                            columns={refundsRuleColumns}
                            data={
                              detailsData?.refundRules
                                ? detailsData?.refundRules
                                : []
                            }
                            allowOverflow={true}
                            highlightOnHover
                            pointerOnHover
                            // selectableRows
                            noDataComponent={
                              <div className="p-20">No Data available</div>
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            ) : (
              <div className="card_container mt-4">
                <Row className="">
                  <Col md={2}>
                    <div className="billable_card_title">
                      MINIMUM BOOKING PERIOD
                    </div>
                    <div className="billable_card_discription">
                      {detailsData?.minBookingPeriod} Weeks
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="billable_card_title">TURN AROUND TIME</div>
                    <div className="billable_card_discription">
                      {detailsData?.turnAroundTime} Days
                    </div>
                  </Col>
                </Row>
                <div style={{ fontSize: "16px" }} className="mt-4">
                  Pricing
                </div>
                <Row className="mt-4">
                  <Col md={2}>
                    <div className="billable_card_title">TIER PRICING</div>
                    <div className="billable_card_discription">
                      {detailsData?.priceDetail?.priceTypeText}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="billable_card_title">APPLICABLE DURING</div>
                    <div className="billable_card_discription">
                      {detailsData?.priceDetail?.applicabilityText}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={6}>
                    <div className="card  mt-3">
                      <DataTable
                        fixedHeader
                        columns={pricingColumns}
                        data={
                          detailsData?.priceTiers ? detailsData?.priceTiers : []
                        }
                        allowOverflow={true}
                        highlightOnHover
                        pointerOnHover
                        noDataComponent={
                          <div className="p-20">No Data available</div>
                        }
                        // selectableRows
                      />
                    </div>
                  </Col>
                </Row>
                <div
                  style={{ fontSize: "16px", marginBottom: "10px" }}
                  className="mt-4"
                >
                  Subscription
                </div>
                <Row className="mt-4">
                  <Col md={2}>
                    <div className="billable_card_title">NUMBERS OF TERMS</div>
                    <div className="billable_card_discription">
                      {detailsData?.subscriptionTerms?.term}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="billable_card_title">DOWNPAYMENT</div>
                    <div className="billable_card_discription">
                      {detailsData?.subscriptionTerms?.downPaymentPercentage}
                    </div>
                  </Col>
                </Row>
                {detailsData?.rushFee != null && detailsData?.rushFee != 0 && (
                  <Row className="mt-4">
                    <div
                      style={{ fontSize: "16px", marginBottom: "10px" }}
                      className="mt-4"
                    >
                      Rush Fee
                    </div>
                    <Col md={2}>
                      {/* <div className="billable_card_title">Rush Fee</div> */}
                      <div className="billable_card_discription">
                        ${detailsData?.rushFee}
                      </div>
                    </Col>
                    {/* <Col md={2}>
                    <div className="billable_card_title">DOWNPAYMENT</div>
                    <div className="billable_card_discription">
                      {detailsData?.subscriptionTerms?.downPaymentPercentage}
                    </div>
                  </Col> */}
                  </Row>
                )}
                <Row className="mt-4">
                  <Col md={5}>
                    <div style={{ fontSize: "16px" }} className="mt-4">
                      Summary of Charges
                    </div>
                    <div className="card  mt-3">
                      <DataTable
                        fixedHeader
                        columns={summmaryChargesColumns}
                        data={
                          detailsData?.summaryChargesItems
                            ? detailsData?.summaryChargesItems
                            : []
                        }
                        allowOverflow={true}
                        highlightOnHover
                        pointerOnHover
                        // selectableRows
                        noDataComponent={
                          <div className="p-20">No Data available</div>
                        }
                      />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div style={{ fontSize: "16px" }} className="mt-4">
                      Refund Rules
                    </div>
                    <div className="card  mt-3">
                      <DataTable
                        fixedHeader
                        columns={refundsRuleColumns}
                        data={
                          detailsData?.refundRules
                            ? detailsData?.refundRules
                            : []
                        }
                        allowOverflow={true}
                        highlightOnHover
                        pointerOnHover
                        // selectableRows
                        noDataComponent={
                          <div className="p-20">No Data available</div>
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </>
        );
      case "LOCATIONS":
        return (
          <>
            {detailsData?.sites?.map((item) => (
              <Row className="mt-4">
                <Col md={5}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                      <img
                        src={getImageUrl(item.images[0])}
                        className="detail_image"
                      />
                      <div className="ps-2">
                        <p className="image_heading">
                          <b>
                            <span className="light pe-2">{item.id}</span>
                            {item.name}
                          </b>
                        </p>
                        <p className="qty">Qty. 3</p>
                      </div>
                    </div>
                    <div
                      className="cursor"
                      onClick={() => handleSiteViewDetailsPage(item)}
                    >
                      <CaretRight />
                    </div>
                  </div>
                  <hr />
                </Col>
              </Row>
            ))}
          </>
        );
      case "SIGNS":
        return (
          <>
            <div className="card_container mt-2">
              <div className="d-flex">
                {signTabs &&
                  signTabs.map((item, index) => (
                    <div
                      className={` billable_tab pe-4 ${
                        activeSignsTabs == index && "active"
                      }`}
                      onClick={() => handleSignTabChange(index)}
                    >
                      {item}
                    </div>
                  ))}
              </div>
              <Row className="mt-4">
                <Col md={2}>
                  <div className="billable_card_title">SIGN</div>
                  <div className="billable_card_discription">
                    {detailsData.signs[activeSignsTabs].name}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="billable_card_title">LOCATION</div>
                  <div className="billable_card_discription">
                    {detailsData.signs[activeSignsTabs].specificLocation}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="billable_card_title">DIRECTION FACING</div>
                  <div className="billable_card_discription">
                    {detailsData.signs[activeSignsTabs].signDirectionFacingText}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={2}>
                  <div className="billable_card_title">DESCRIPTION</div>
                  <div className="billable_card_discription">
                    {detailsData.signs[activeSignsTabs].description}
                  </div>
                </Col>
                {detailsData.signs[activeSignsTabs].noOfSameSigns > 0 && (
                  <Col md={2}>
                    <div className="billable_card_title">
                      No of Similar Sign Faces
                    </div>
                    <div className="billable_card_discription">
                      {detailsData.signs[activeSignsTabs].noOfSameSigns}
                    </div>
                  </Col>
                )}
              </Row>
              <Row className="mt-4">
                <Col md={2}>
                  <div className="billable_card_title mb-2">IMAGE</div>

                  <div className="imageCard" style={{ height: "100px" }}>
                    <a
                      download="image.jpg"
                      href={getImageUrl(
                        detailsData.signs[activeSignsTabs].image
                      )}
                      target="_blank"
                    >
                      <img
                        src={getImageUrl(
                          detailsData.signs[activeSignsTabs].image
                        )}
                        alt="img"
                      />
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        );
      case "REVIEWS & RATINGS":
        return (
          <>
            <Reviews
              reviews={reviews}
              activeTab={activeTab}
              // blockReview={blockReview}
              // loadReviews={loadReviews}
              // deleteReview={deleteReviewShowConfimation}
            />
          </>
        );
      default:
        return <></>;
    }
  };
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };
  let navbar = (
    <NavBar
      notificationSVG={<NotificationSVG />}
      navHeading={detailsData?.listingName}
      subHeading={`Configurator • Listing • ${detailsData?.name}`}
      subActiveHeading="Booking Details"
      searchBarlabel={"Search an item"}
      showButton={true}
      // handleBlueBtn={}
    />
  );
  if (!detailsData?.featured) {
    navbar = (
      <NavBar
        notificationSVG={<NotificationSVG />}
        navHeading={detailsData?.listingName}
        subHeading={`Configurator • Listing • ${detailsData?.name}`}
        subActiveHeading="Booking Details"
        searchBarlabel={"Search an item"}
        blueBtnText="Make it feature"
        handleBlueBtn={() => setListingCreatedModal(true)}
        showButton={checkPermission("Listings", "Full")}
      />
    );
  }
  return (
    <>
      {navbar}
      <div className="p-20">
        <div className="billable_tabs ">
          {tabs?.map((tab, index) => (
            <div
              key={index + "_sd_tab"}
              className={`billable_tab ${tab == activeTab && "active"}`}
              onClick={() => handleChangeTab(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
        {renderScreen(activeTab)}
      </div>
      {listingCreatedModal && (
        <NewListingConfirmModal
          title="Make It Feature"
          setNewListingCreatedModal={setListingCreatedModal}
          newListingCreatedModal={listingCreatedModal}
          handleConfirmListCreatedModal={() => handleConfirmListCreatedModal()}
        />
      )}
      {featuredListing && (
        <FeaturedListingModal
          setFeaturedListing={setFeaturedListing}
          featuredListing={featuredListing}
          setRegisterListingModal={setRegisterListingModal}
        />
      )}
      {/* Listing to be featured modal*/}
      {registerListingModal && (
        <OnBoardingFeatured
          setRegisterListingModal={setRegisterListingModal}
          handleRegisterDateFetaured={handleRegisterDateFetaured}
          onChangeFeaturedDate={onChangeFeaturedDate}
          featuredDate={featuredDate}
        />
      )}
    </>
  );
};

export default ListingDetailsPage;

import axios from "axios";
import { http } from "../../httpService";

export function inventorySite(reqBody) {
  return http.post(`/api/Site/DetailList`, reqBody);
}
export function deleteSite(Id) {
  return http.delete(`/api/Site/${Id}`);
}

export function inventorySigns(reqBody) {
  return http.post(`/api/Sign/List`, reqBody);
}

export function inventorySignDetails(signId) {
  return http.get(`/api/Sign/${signId}`);
}

export function signDetailsUpdate(signId,formData) {
  return http.patch(`/api/Sign/${signId}`,formData);
}

export function siteDetailsUpdate(formData) {
  return http.post(`/api/Site/Update`,formData);
}

export function siteBulkStatusUpdate(reqBody) {
  return http.post(`/api/Site/BulkStatusUpdate`,reqBody);
}

export function getSiteDetails(id){
  return http.get(`/api/Site/${id}`)
}

export function getChargeDetails(id){
  return http.get(`/api/Charge/${id}`)
}

export function inventoryServices(reqBody) {
  return http.post(`/api/Service/Index`, reqBody);
}

export function inventoryCharges(reqBody) {
  return http.post(`/api/Charge/List`, reqBody);
}

export function getEnvironment() {
  return http.get(`/api/Environment`);
}

export function updateProfile(formData){
  return http.patch(`/api/User/Update`,formData)
}

export function getOfferList(reqBody){
  return http.post(`/api/Offer/List`,reqBody)
}
export function getOfferDetails(offerId){
  return http.get(`/api/Offer/${offerId}`)
}
export function getServiceDetails(serviceId){
  return http.get(`/api/Service/${serviceId}`)
}

export function getServiceList(){
  let reqBody={
      page: 0,
      pageSize: 10,
      orderBy: "string",
      export: true,
      searchString: ""
  }
  return http.get(`api/Service/Index`,reqBody)
}
export function addService(formData){
  return http.post(`/api/Service/Create`,formData)
}

export function updateService(id,formData){
  console.log('id', id)
  console.log('formData', formData)
  return http.patch(`/api/Service/${id}`,formData)
}

export function deleteService(id){
  return http.delete(`/api/Service/${id}`)
}

export function addOffer(formData){
  return http.post(`/api/Offer/CreateOffer`,formData)
}

export function updateOffer(formData){
  return http.post(`/api/Offer/Update`,formData)
}

export function addCharges(formData){
  return http.post(`/api/Charge`,formData)
}
export function updateCharges(id,formData){
  return http.patch(`/api/Charge/${id}`,formData)
}
export function deleteCharges(id){
  return http.delete(`/api/Charge/${id}`)
}

export function deleteOffer(id){
  return http.delete(`/api/Offer/${id}`)
}

export function deleteSign(id){
  return http.delete(`/api/Sign/${id}`)
}

export function getSignDetails(id){
  return http.get(`/api/Sign/${id}`)
}

export function AvailableSignServiceCharges(id){
  return http.get(`/api/Site/${id}/AvailableSignServiceCharges`)
}

export function getListingsForSite (id){
  return http.get(`/api/Listing/Site/${id}`)
}

export function getSiteUsage (id){
  return http.post(`/api/Site/${id}/getSiteUsageListBySiteId`)
}

export function getSimilarSite (id){
  return http.get(`/api/Site/${id}/Similar`)
}
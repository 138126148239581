import {http } from "../httpService";
import axios from "axios";

export function getFavoriteSitesAndSign(reqBody) {
    return http.post(`/api/Favorite/SitesAndSign`,reqBody);
  }
  
  export function setUnfavorite(reqBody) {  
    return http.post(`/api/Favorite/Unfavorite`,reqBody);
  }
  
  export function getCustomerDashBoard(reqBody){  
    return http.get(`/api/Dashboard/GetCustomerDashBoard${reqBody}` );
  }

  export function getListByType(reqBody) {
    return http.post(`/api/Listing/Search`, reqBody);
  }
  
  export function getListBySites(reqBody) {
    return http.post(`/api/Listing/Search`, reqBody);
  }
  
  export function getListItemDetails(listingItemId) {
    return http.get(`/api/Listing/${listingItemId}/Details`);
  }

  export function getSimilarListing(request) {
    return http.post(`/api/Listing/SimilarListing`,request);
  }

  export function getListingReviews(listingId) {
    return http.get(`/api/Review/Listing/${listingId}`);
  }
  
  export function getAvailableOffersOnSite(siteId) {
    return http.get(`/api/Offer/GetAvailableofferforSite/${siteId}`);
  }

  export function getAvailableOfferForProvider(providerId) {
    return http.get(`/api/Offer/GetavailableofferforProvider/${providerId}`);
  }

  export function validateOffer(reqBody) {
    return http.post(`/api/Offer/ValidateOffer`, reqBody);
  }

  export function submitOrder(reqBody) {
    return http.post(`/api/Order/Create`, reqBody);
  }

  export function getDefaultAddress(){
    return http.get(`/api/User/getDefaultAddress`);
  }

  export function getCurrentUser(){
    return http.get(`/api/User/CurrentUser`);
  }

  export function getEnvironments(){
    return http.get(`/api/Environment`);
  }

  export function getPriceRange(){
    return http.get(`/api/Listing/PriceRange`);
  }

  export function markAsFavorite(reqBody){
    return http.post(`/api/Favorite`, reqBody);
  }

  export function markAsUnFavorite(reqBody){
    return http.post(`/api/Favorite/Unfavorite`, reqBody);
  }

  export function bookPortableSign(reqBody){
    return http.post(`/api/Order/BookPortableSign`, reqBody);
  }

  export function GetListingAvailability(reqBody){
    return http.post(`/api/Listing/GetListingAvailability`, reqBody);
  }

  export function orderPayment(reqBody) {
    return http.post(`/api/Order/OrderPayment`, reqBody);
  }

  export function getSiteListingInfo(siteId) {
    return http.get(`/api/Listing/Site/` + siteId);
  } 

  export function getSignListingInfo(signId) {
    return http.get(`/api/Listing/Sign/` + signId);
  }

  export function getCustomerTotalOrderDetails(){
    return http.get(`/api/Order/CustomerTotalOrderDetails`);
  } 

export function getAddressByLatLng(lat,lng){
  let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAKiVYj0Ytig8vYJS4ViTya12yJltyVo54`
  let axios_obj = axios.create({
   // baseURL: url 
  }); 
  return axios_obj.get(url);
}
import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { PassThroughSVG } from "../../../../../assets/svgComponents/inventorySVG";
import {
  NotificationSVG,
  PlusSVG,
  ExportCSVSVG,
} from "../../../../../assets/svgComponents/table";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import NavBar from "../../../../../components/navBar";
import { FloatingLableSearchBar } from "../../../../../components/SearchBar/FloatingLableSearchBar";
import ProjectContext from "../../../../../context/ProjectContext";
import {
  deleteOffer,
  getOfferList,
} from "../../../../../services/Provider/Configurator/Inventory";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
// import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useToasts } from "react-toast-notifications";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import UpdatesModal from "../../../../../components/profile/Modal/UpdatesModal";
import moment from "moment";
import { checkPermission } from "../../../../../utils";

export const OfferManagement = ({ searchInput }) => {
  const location = useLocation();
  const history = useHistory();
  const { addToast } = useToasts();

  const { handleSelectedOffer, selectedOffer } = useContext(ProjectContext);
  const [offerList, setOfferList] = useState([]);
  const [filterOfferList, setFilterOfferList] = useState([]);
  const tabs = ["ALL OFFERS", "ACTIVE", "INACTIVE", "SCHEDULED", "EXPIRED"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [loader, setLoader] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [deleteOfferConfirmModal, setDeleteOfferConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteAbleRow, setDeleteAbleRow] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    setSortColumn({ column: column, sortDirection: sortDirection });
  };

  const loadData = async () => {
    let statusCode = 0;
    if (activeTab == "Active") statusCode = 2;
    if (activeTab == "Inactive") statusCode = 1;
    if (activeTab == "Scheduled") statusCode = 3;
    if (activeTab == "Expired") statusCode = 4;
    setLoader(true);
    let reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: true,
      searchString: searchTerm,
      offerStatus: statusCode,
      name: "",
      code: "",
    };
    getOfferList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          setOfferList(res.data.records);
          setFilterOfferList(res.data.records);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    loadData();
  }, [currentPage, pageSize, sortColumn, searchTerm]);

  const checkboxlable = ["Commercial", "Residential", "Retail", "Transit"];
  const handleApplyBtn = () => {
    setShowFilterModal(false);
  };

  const addOfferBtn = () => {
    history.push(`${location.pathname}/add-offer`);
  };

  // const handleView = (offer) => {
  //   handleSelectedOffer(offer);
  //   history.push(`${location.pathname}/view-offer`);
  // };

  const handleEditGridCell = (offer) => {
    if (offer.status === "Expired") {
      addToast("Can't edit this offer as Expired offers are not editable", {
        appearance: "error",
      });
    } else {
      handleSelectedOffer(offer);
      history.push(`${location.pathname}/edit-offer`);
    }
  };

  const deleteCell = async (offer) => {
    //await deleteOffer(offer.id)
    setDeleteOfferConfirmModal(false);
    await deleteOffer(deleteAbleRow)
      .then(() => {
        loadData();
        addToast("Offer successfully deleted.", {
          appearance: "success",
        });
        // toast.success("Offer successfully deleted.");
      })
      .catch(() => {
        addToast("There is an error while deleting the user.", {
          appearance: "error",
        });
        // toast.error("There is an error while deleting the user.");
      });
  };

  const handleDeleteGridCell = (item) => {
    setDeleteAbleRow(item.id);
    setDeleteOfferConfirmModal(true);

    /* const options = {
      title: "Delete",
      message: "Do you want to delete this offer?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCell(item),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name",
    };
    confirmAlert(options); */
  };

  const handleViewGridCell = (offer) => {
    handleSelectedOffer(offer);
    history.push(`${location.pathname}/view-offer`);
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      sortField: "Id",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
    },
    {
      name: "DETAILS",
      selector: (row) => row.details,
      sortable: false,
    },

    {
      name: "STATUS",
      selector: (row) => row.status,
      sortable: true,
      sortField: "Status",
    },
    {
      name: "END DATE",
      cell: (row) => moment(row.endDate).format("DD MMMM YYYY"),
      sortable: true,
      sortField: "EndDate",
    },
    {
      name: "OFFER TYPE NAME",
      selector: (row) => row.offerTypeName,
      sortable: true,
      sortField: "OfferTypeName",
    },
    {
      name: "ACTION",
      cell: (row) => (
        <span className="gridTableBody_td_passThrough">
          <DropdownButton
            align="end"
            className="dropDownBtn"
            title={<PassThroughSVG />}
          >
            {/* {checkPermission("OfferManagement", "Full") && (
              <Dropdown.Item onClick={(e) => handleEditGridCell(row)}>
                Edit{" "}
              </Dropdown.Item>
            )} */}
            <Dropdown.Item onClick={() => handleViewGridCell(row)}>
              View
            </Dropdown.Item>
            {checkPermission("OfferManagement", "Full") && (
              <Dropdown.Item onClick={() => handleDeleteGridCell(row)}>
                Delete
              </Dropdown.Item>
            )}
          </DropdownButton>
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleActivesubNavTab = (tab) => {
    setActiveTab(tab);
    let actTab = tab.toLowerCase().split("").join("");

    actTab == "all offers"
      ? setFilterOfferList([...offerList])
      : setFilterOfferList(
          offerList.filter(
            (elm) =>
              elm.status == actTab.charAt(0).toUpperCase() + actTab.slice(1)
          )
        );
  };

  const handleSerchInput = (e) => {
    setSearchTerm(e.target.value);
  };

  const loadExportData = async () => {
    let statusCode = 0;
    if (activeTab == "Active") statusCode = 2;
    if (activeTab == "Inactive") statusCode = 1;
    if (activeTab == "Scheduled") statusCode = 3;
    if (activeTab == "Expired") statusCode = 4;
    let reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: true,
      searchString: searchTerm,
      offerStatus: statusCode,
      name: "",
      code: "",
    };
    getOfferList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          downloadCSV(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array.records);
    if (csv == null) return;

    const filename =
      "export-" + activeTab.toLowerCase().split("").join("") + ".csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <button className="blueBgButton" onClick={(e) => onExport(e.target.value)}>
      <span className="btnSvg">
        <ExportCSVSVG fill="#FFFFFF" />
      </span>
      Export CSV
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );

  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <>
      <NavBar
        blueBtnText="Add Offer"
        blueBtnSVG={<PlusSVG />}
        notificationSVG={<NotificationSVG />}
        navHeading="Offer Management"
        subHeading="Configurator"
        subActiveHeading="Offer Management"
        handleBlueBtn={addOfferBtn}
        handleNotificationSVG={handleNotificationSVG}
        showButton={checkPermission("OfferManagement", "Full")}
      />
      <div className="gridSection p-20">
        <div className="tableActions_container m-0">
          <Row className="g-0">
            <Col className="col-md-8">
              <ul className="tableActions_tabs mt-2">
                {tabs.map((item) =>
                  item === activeTab ? (
                    <li
                      className="tableActions_tab active"
                      onClick={() => handleActivesubNavTab(item)}
                    >
                      {item} <hr className="tableActions_tab_hr" />
                    </li>
                  ) : (
                    <li
                      className="tableActions_tab"
                      onClick={() => handleActivesubNavTab(item)}
                    >
                      {item}
                    </li>
                  )
                )}
              </ul>
            </Col>
          </Row>
        </div>
        <div className="card customtable">
          <div className="d-flex serachFilterContainer w-100 p-2">
            <FloatingLableSearchBar
              inputFieldSize="fieldSize"
              btnsearch="searchbtn"
              handleOnchange={handleSerchInput}
            />
            {/* <FloatingLableSearchBar
                  inputFieldSize="fieldSize"
                  btnsearch="serachbtn"
                /> */}
            {/* <button
                  className="whiteBgButton02 ms-3 py-0 d-flex justify-content-center align-items-center"
                 
                  onClick={() => setShowFilterModal(true)}
                >
                  Filter
                  <span className="btnSvg mx-2">
                    <UnionSVG />
                  </span>
                </button> */}
          </div>
          {console.log("filter data", filterOfferList)}
          {filterOfferList && (
            <DataTable
              progressPending={loader}
              columns={columns}
              // data={filterOfferList}
              data={filterOfferList}
              allowOverflow={true}
              paginationDefaultPage={currentPage}
              pagination
              paginationServer
              paginationTotalRows={filterOfferList.totalRecords}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationPerPage={pageSize}
              selectableRows
              dense
              onSort={handleSort}
              sortServer
              fixedHeader={{
                fixedHeader: true,
                fixedHeaderScrollHeight: "300px",
              }}
              actions={actionsMemo}
            />
          )}
        </div>
      </div>

      {deleteOfferConfirmModal && (
        <ConfirmModal
          // setConfirmModal={setAddSignModal}
          //   confirm={handleAddSignsModal}
          //title={selectedOffer.name}
          title="Delete Offer"
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          cancelText="No"
          handleClose={() => setDeleteOfferConfirmModal(false)}
          handleConfirm={() => deleteCell()}
        >
          <div className="text-center">
            <p className="py-4 newListingCreated_description">
              Would you like to delete this offer?
            </p>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <Modal.Header closeButton>
          <h5 className="modaltitle">Filters</h5>{" "}
          <button className="clearAllButton">clear All</button>
        </Modal.Header>
        <Modal.Body>
          <div className=" mt-4">
            <h5 className="mb-3 text-lg fw-bold">Location</h5>
            <FloatingLableSearchBar />
          </div>
          <div>
            <h5 className="mt-5 mb-0 text-lg fw-bold">
              Environment / Type of Site
            </h5>
            {checkboxlable.map((item) => {
              return (
                <div className="checkbox_container">
                  <Checkbox text={item} />
                </div>
              );
            })}
          </div>
          <div className="applyFilter">
            <button
              className="blueBgButton applyFilterBtn py-3 px-5"
              onClick={handleApplyBtn}
            >
              Apply
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

import React from "react";
import { Form } from "react-bootstrap";
import ConfirmModal from "../../confirmModal/ConfirmModal";
import { InputField } from "../../InputField";
import { SelectionComponent } from "../../SelectionComponent/SelectionComponent";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getCountryList,
  getStateList,
  getCityList,
  addAddress,
  getRecentTopFivePaymentCard,
} from "../../../services/Common";
import { useToasts } from "react-toast-notifications";

export const AddNewAddressModal = ({
  handleCityList,
  handleStateList,
  handleCountryList,
  selecltAddresscard,
  setAddNewAddressModal,
  selectedCountry,
  selectedState,
  selectedCity,
  cityList,
  statelist,
  countrylist,
  handleUpdatedAddress,
}) => {
  const { addToast } = useToasts();
  console.log("selectedCity", selectedCity);
  console.log("selectedCountry", selectedCountry);

  const formik = useFormik({
    initialValues: {
      address: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
      address1: "",
      address2: "",
    },
    validationSchema: Yup.object().shape({
      address: Yup.string().required("Address is required!"),
      pincode: Yup.string()
        .min(5, "Postal code should be 5 character")
        .max(6, "Postal code should be 6 character")
        .required("Postal code is required!"),
    }),

    onSubmit: (values, { resetForm }) => {
      if (
        selectedCountry == undefined ||
        selectedCountry == "" ||
        selectedCountry.length == 0
      ) {
        addToast("Please Select Country", { appearance: "error" });
      } else if (
        selectedState == undefined ||
        selectedState == "" ||
        selectedState.length == 0
      ) {
        addToast("Please Select State", { appearance: "error" });
      } else if (
        selectedCity == undefined ||
        selectedCity == "" ||
        selectedCity.length == 0
      ) {
        addToast("Please Select City", { appearance: "error" });
      } else {
        let formData = {
          address1: values.address,
          address2: "",
          pincode: values.pincode,
          city: selectedCity && selectedCity[0].name,
          state: selectedState && selectedState[0].name,
          country: selectedCountry && selectedCountry[0].name,
        };
        addAddress(formData)
          .then((res) => {
            if (res.status === 201) {
              resetForm();
              setAddNewAddressModal(false);
              addToast("Address has been added successfully.", {
                appearance: "success",
              });
            } else {
              addToast("Something went wrong.", { appearance: "error" });
            }
          })
          .catch((err) => {
            addToast("Something went wrong.", { appearance: "error" });
            console.log("err", err);
          });
      }
    },
  });

  //const addressError = formik.touched.address && formik.errors.address;
  //const pincodeError = formik.touched.pincode && formik.errors.pincode;

  return (
    <>
      <Form className="py-4" onSubmit={formik.handleSubmit}>
        <ConfirmModal
          title="Add New 
          Address"
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Save"
          handleClose={() => setAddNewAddressModal(false)}
          type="submit"
          // disable={formik.isSubmitting}
        >
          <div className="childs">
            <InputField
              placeholder="Address"
              label="Address"
              name="address"
              value={formik.values.address}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />{" "}
            {formik.errors.address && (
              <p className="text-danger mt-1" style={{ fontSize: "14px" }}>
                {formik.errors.address}
              </p>
            )}
          </div>
          <div className="childs">
            <InputField
              placeholder="Postal code"
              label="Postal code"
              name="pincode"
              value={formik.values.pincode}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />{" "}
            {formik.errors.pincode && (
              <p className="text-danger mt-1" style={{ fontSize: "14px" }}>
                {formik.errors.pincode}
              </p>
            )}
          </div>
          <div className="childs">
            <SelectionComponent
              label="Country"
              option={countrylist}
              handleOnchange={handleCountryList}
              defaultValue={"Select Country"}
              selected={selectedCountry.length > 0 && selectedCountry[0].name}
            />{" "}
          </div>
          <div className="childs">
            <SelectionComponent
              label="State"
              option={statelist}
              defaultValue={"Select State"}
              handleOnchange={handleStateList}
              selected={selectedState.length > 0 && selectedState[0].name}
            />{" "}
          </div>
          <div className="childs">
            <SelectionComponent
              label="City"
              option={cityList}
              defaultValue={"Select City"}
              handleOnchange={handleCityList}
              selected={selectedCity.length > 0 && selectedCity[0].name}
            />{" "}
          </div>
        </ConfirmModal>
      </Form>
    </>
  );
};

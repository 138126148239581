import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { PassThroughSVG } from "../../../../../../assets/svgComponents/inventorySVG";
import { Col, Dropdown, DropdownButton } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
// import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  deleteService,
  inventoryServices,
  inventoryCharges,
} from "../../../../../../services/Provider/Configurator/Inventory";
import ProjectContext from "../../../../../../context/ProjectContext";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import { useToasts } from "react-toast-notifications";
import { UnionSVG, ExportCSVSVG } from "../../../../../../assets/svgComponents/table";
import Button from "../../../../../../components/button/Button";
import { InputField } from "../../../../../../components/InputField";
import BtnToggle from "../../../../../../components/BtnToggle/BtnToggle";
import { checkPermission } from "../../../../../../utils";

const ServicesPage = ({ activeTab }) => {
  const [loader, setLoader] = useState(false);
  const { addToast } = useToasts();
  const [servicesData, setServicesData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [deleteAbleRow, setDeleteAbleRow] = useState();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const history = useHistory();
  const location = useLocation();

  const { handleSelectedSelService, selectedService } =
    useContext(ProjectContext);
  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    setSortColumn({ column: column, sortDirection: sortDirection });
  };

  const columns = [
    {
      name: "id".toUpperCase(),
      selector: (row) => row.id,
      sortable: true,
      sortField: "Id",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
    },
    {
      name: "description".toUpperCase(),
      selector: (row) => row.description,
      sortable: false,
    },
    {
      cell: (row) => (
        <span className="gridTableBody_td_passThrough">
          <DropdownButton
            align="end"
            className="dropDownBtn"
            title={<PassThroughSVG />}
          >
            <Dropdown.Item onClick={() => handleViewGridCell(row)}>
              View
            </Dropdown.Item>
            {checkPermission("Configurator", "Full") && (
              <Dropdown.Item onClick={(e) => handleEditGridCell(row)}>
                Edit{" "}
              </Dropdown.Item>
            )}
            {checkPermission("Configurator", "Full") && (
              <Dropdown.Item onClick={() => handleDeleteGridCell(row)}>
                Delete
              </Dropdown.Item>
            )}
          </DropdownButton>
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const loadData = async () => {
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: filterText,
      status: true,
    };
    setLoader(true);
    await inventoryServices(reqBody)
      .then((res) => {
        if (res.status == 201) {
          setLoader(false);
          setServicesData(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const loadExportData = async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: "",
      status: true,
    };
    await inventoryServices(reqBody)
      .then((res) => {
        if (res.status == 201) {
          downloadCSV(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  // fetch services listing data
  useEffect(async () => {
    loadData();
  }, [currentPage, pageSize, sortColumn, filterText]);

  const handleEditGridCell = (service) => {
    handleSelectedSelService(service);

    history.push("/provider/configurator/inventory/edit-services");
  };

  const deleteCell = async (item) => {
    let serviceId = item.id;
    await deleteService(serviceId)
      .then((res) => {
        loadData();
        if (res.status == 204) {
          history.push(`/provider/configurator/inventory`);
          // toast("Selected record delete");
          addToast("Selected record delete", {
            appearance: "success",
          });
        }
      })
      .catch((err) => console.log("err", err));
  };

  const handleConfirmModalDelete = async () => {
    console.log(deleteAbleRow);
    await deleteService(deleteAbleRow)
      .then(() => {
        loadData();
        addToast("Service successfully deleted.", {
          appearance: "success",
        });
        setDeleteConfirmModal(false);
      })
      .catch(() => {
        addToast("There is an error while deleting the service.", {
          appearance: "warning",
        });
      });
  };

  const handleDeleteGridCell = (item) => {
    setDeleteAbleRow(item.id);
    setDeleteConfirmModal(true);
  };

  // const handleDeleteGridCell = (item) => {
  //   const options = {
  //     title: "Delete",
  //     message: "Do you want to delete the user?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => deleteCell(item),
  //       },
  //       {
  //         label: "No",
  //         onClick: () => {},
  //       },
  //     ],
  //     childrenElement: () => <div />,
  //     closeOnEscape: true,
  //     closeOnClickOutside: true,
  //     keyCodeForClose: [8, 32],
  //     willUnmount: () => {},
  //     afterClose: () => {},
  //     onClickOutside: () => {},
  //     onKeypressEscape: () => {},
  //     overlayClassName: "overlay-custom-class-name",
  //   };
  //   confirmAlert(options);
  // };

  const handleViewGridCell = (service) => {
    handleSelectedSelService(service);
    history.push({
      pathname: `${location.pathname}/view-services`,
      state: service,
    });
  };
  // console.log('first', servicesData)

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array.records);
    if (csv == null) return;

    const filename = "export-services.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const Export = ({ onExport }) => (
    <button
      className="blueBgButton"
      onClick={(e) => onExport(e.target.value)}
    >
      <span className="btnSvg"><ExportCSVSVG fill="#FFFFFF" /></span>
      Export CSV
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );

  const filteredItems = servicesData?.records.filter(
    (item) =>
      (item && item?.id?.toLowerCase().includes(filterText.toLowerCase())) ||
      item?.name?.toLowerCase().includes(filterText.toLowerCase())
  );
  return (
    <>
      <Col className=" d-flex justify-content-start align-items-center m-2 col-6 ">
        <div className="tableAction_input_container w-50">
          <InputField
            placeholder="Search Service ID, Service Name..."
            label={"Search Service ID, Service Name..."}
            onChange={(e) => setFilterText(e.target.value)}
          // value={searchInput}
          />
        </div>
        <div className="navbarBtnContainer ">

          <div className="d-flex ms-3">
            <BtnToggle />
            <span className="ms-1 d-inline-block"> Group by Media</span>

          </div>


        </div>
      </Col>{" "}
      <DataTable
        progressPending={loader}
        columns={columns}
        data={filteredItems}
        allowOverflow={true}
        paginationDefaultPage={currentPage}
        pagination
        paginationServer
        paginationTotalRows={servicesData?.totalRecords}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={pageSize}
        //selectableRows
        dense
        onSort={handleSort}
        sortServer
        fixedHeader={{
          fixedHeader: true,
          fixedHeaderScrollHeight: "300px",
        }}
        actions={actionsMemo}
      />
      {deleteConfirmModal && (
        <ConfirmModal
          title="Delete Service"
          isModalHeader={true}
          isFooterBtns={true}
          cancelText="No"
          confirmText="Yes"
          handleClose={() => setDeleteConfirmModal(false)}
          handleConfirm={() => handleConfirmModalDelete()}
          width="400px"
        >
          <div className="childs">
            <p className="newListingCreated_description ms-4 py-3">
              Do you want to delete the service?
            </p>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default ServicesPage;

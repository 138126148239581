import { Form, useFormik, Formik, ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  LocationSVG,
  PlusSVG,
  CrossSVG,
} from "../../../../../assets/svgComponents/svg";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import {
  currentUser,
  getAddressList,
  makeDefoulAddress,
  removeAddress,
} from "../../../../../services/Provider";
import * as Yup from "yup";
import RadioButton from "../../../../../components/RadioButton/RadioButton";
import { AddressCard } from "../../../../../components/AddressCard/AddressCard";
import { InputField } from "../../../../../components/InputField";
import { SelectionComponent } from "../../../../../components/SelectionComponent/SelectionComponent";
import { UpdateAddressModal } from "../../../../../components/profile/Modal/UpdateAddressModal";
import { useDispatch, useSelector } from "react-redux";
import { updateBillingAddress } from "../../../../../services/reducers/listing-reducer";

import {
  addAddress,
  getCountryList,
  getStateList,
  getCityList,
} from "../../../../../services/Common";
import { UploadPlus } from "../../../../../assets/svgComponents/inventorySVG";
import FormikController from "../../../../../components/Formik/FormikController";
import { useToasts } from "react-toast-notifications";


const BillingAddress = ({ address, profile }) => {
  const dispatch = useDispatch();
  const [addessList, setAddessList] = useState();
  const [user, setUser] = useState();
  const [selecltAddresscard, setSelecltAddresscard] = useState();
  const [updateAddressModal, setUpdateAddressModal] = useState(false);
  const [countrylist, setCountrylist] = useState();
  const [statelist, setStatelist] = useState();
  const [cityList, setCityList] = useState();
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedState, setSelectedState] = useState([{}]);
  const [selectedCity, setSelectedCity] = useState([{}]);
  const [addNewAddressModal, setAddNewAddressModal] = useState(false);
  const [billingAddressModal, setBillingAddressModal] = useState(false);
  const [width, setWidth] = useState(null);

  const { addToast } = useToasts();

  const [defoultaddressConfirmModal, setDefoultaddressConfirmModal] =
    useState(false);
  const handleChangeAddress = () => {
    setBillingAddressModal(true);
  };
  const handleAddNewAddress = () => {
    setBillingAddressModal(false);
    setAddNewAddressModal(true);
  };

  useEffect(() => {
    getCountryList()
      .then((res) => {
        if (res.status === 200) {
          setCountrylist(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    getStateList(1)
      .then((res) => {
        if (res.status === 200) {
          setStatelist(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    getCityList(3870)
      .then((res) => {
        if (res.status === 200) {
          setCityList(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    currentUser()
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data);
          let id = res.data.id;

          getAddressList(id).then((res) => {if (res.status === 200) { setAddessList(res.data);}}).catch((err) => { console.log("err", err);});
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [defoultaddressConfirmModal]);
  const makeDefoultAddrees = (card) => {
    console.log("set it to default address", card);
    makeDefoulAddress(card.id)
      .then((res) => {
        if (res.status == 202) {
          setDefoultaddressConfirmModal(!defoultaddressConfirmModal);
          dispatch(updateBillingAddress({ address: card }));
        }
      })
      .catch((err) => console.log("err", err));
  };
  const deleteAddress = (addCard) => {
    removeAddress(addCard && addCard.id);
    setDefoultaddressConfirmModal(!defoultaddressConfirmModal);
  };
  const editAddress = (addCard) => {
    setSelecltAddresscard(addCard);
    setUpdateAddressModal(true);
    let country = {
      name: addCard.country,
    };
    setSelectedCountry([country]);
    let state = {
      name: addCard.state,
    };
    setSelectedState([state]);
    let city = {
      name: addCard.city,
    };
    setSelectedCity([city]);
  };
  const handleCountryList = (value) => {
    let countryId = value.target.value;
    let country = countrylist.filter((item) => item.id == countryId);
    setSelectedCountry(country);
    setSelectedState([]);
    setSelectedCity([]);

    getStateList(countryId)
      .then((res) => {
        if (res.status === 200) {
          setStatelist(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    // console.log("country", value.target.value);
  };
  const handleStateList = (value) => {
    let stateId = value.target.value;
    let state = statelist.filter((item) => item.id == stateId);
    setSelectedState(state);
    setSelectedCity([]);
    getCityList(stateId)
      .then((res) => {
        if (res.status === 200) {
          setCityList(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleCityList = (value) => {
    let cityId = value.target.value;
    let city = cityList.filter((item) => item.id == cityId);
    setSelectedCity(city);
  };

  const initialValues = {
    address: "",
    pincode: "",
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string().required("Address is required!"),
    pincode: Yup.string()
      .min(6, "Postal code should be 6 character")
      .max(6, "Postal code should be 6 character")
      .required("Postal code is required!"),
  });

  const onSubmit = (values) => {
    let formData = {
      address1: values.address,
      address2: "",
      pincode: values.pincode,
      city: selectedCity && selectedCity[0].name,
      state: selectedState && selectedState[0].name,
      country: selectedCountry && selectedCountry[0].name,
    };
    addAddress(formData)
      .then((res) => {
        if (res.status === 201) {
          setAddNewAddressModal(false);
          getAddressList(user && user.id)
            .then((res) => {
              if (res.status === 200) {
                setAddessList(res.data);
                setBillingAddressModal(true);
                setAddNewAddressModal(false);
                dispatch(updateBillingAddress({ address: formData }));
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //const addressError = formik.touched.address && formik.errors.address;
  //const pincodeError = formik.touched.pincode && formik.errors.pincode;
  return (
    <>
      <div className="site_booking_card_heading pt-4">
        <Row>
          <Col>Billing Address</Col>
          <Col className="d-flex justify-content-end change_address">
            <div onClick={() => handleChangeAddress()} style={{cursor:"pointer"}}>
              <span className="pe-2">
                <LocationSVG />{" "}
              </span>
              Change Address
            </div>
          </Col>
        </Row>
      </div>
      <address className="address py-3">
        {profile.name} | {profile.contactNo} <br />
        {address.address1}, {address.address2}, <br />
        {address.city}, {address.state}, <br />
        {address.country}, {address.pincode}
      </address>
      <hr />

      {billingAddressModal && (
        <ConfirmModal
          isModalHeader={true}
          title="Billing Address"
          confirmText="Close"
          handleClose={() => setBillingAddressModal(false)}
          handleConfirm={() => setBillingAddressModal(false)}
        >
          <div className="addressCardContainer">
            {addessList &&
              addessList.map((address, index) => {
                return (
                  <AddressCard
                    key={index + "_addr_crd"}
                    address={address}
                    user={user}
                    makeDefoultAddrees={makeDefoultAddrees}
                    index={index}
                    deleteAddress={deleteAddress}
                    editAddress={editAddress}
                    widthClassName={"w-100"}
                  />
                );
              })}
          </div>
          <div className="buttonBox my-4">
            <button
              className=""
              onClick={() => handleAddNewAddress()}
            >
              <span className="me-2">
              <UploadPlus />
              </span>
              <span className="text-tertiary-600 text-sm">Add New Address</span>
            </button>
          </div>
        </ConfirmModal>
      )}
      {addNewAddressModal && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="py-4">
            <div className="confirmModal">
              <div
                className="confirmModalOver"
                onClick={() => setAddNewAddressModal(false)}
              ></div>
              <div
                className="confirmModalInner modalbox"
                style={{ maxWidth: `${width}` }}
              >
                <div className="confirmModalTop d-flex justify-content-between">
                  <h3 className="font-weight-light mb-1 mw-100 p-4 ps-3 confirmModalTopTitle">
                    {" "}
                    Add New Address
                  </h3>
                  <span
                    className="px-3 py-2 confirmModalTopCross"
                    onClick={() => setAddNewAddressModal(false)}
                  >
                    <CrossSVG />
                  </span>
                </div>
                <div className="modal-childs">
                  <div className="childs">
                  <div className="floatingLabel form-floating">
                 

                            <FormikController
                              control="input"
                              type="text"
                              label="Address"
                              name="address"
                             
                            />
                    {/* <ErrorMessage name="address" className="text-danger" /> */}
                  </div></div>
                  <div className="childs">
                  <div className="floatingLabel form-floating">
                   
                          <FormikController
                              control="input"
                              type="text"
                              label="Postal code"
                              name="pincode"
                             
                            />
                 
                  </div></div>
                  <div className="childs">
                    <SelectionComponent
                      label="Country"
                      option={countrylist}
                      handleOnchange={handleCountryList}
                      name=""
                    />{" "}
                    
                  </div>
                  <div className="childs">
                    <SelectionComponent
                      label="State"
                      option={statelist}
                      handleOnchange={handleStateList}
                    />{" "}
                  </div>
                  <div className="childs">
                    <SelectionComponent
                      label="City"
                      option={cityList}
                      handleOnchange={handleCityList}
                    />{" "}
                  </div>
                </div>
                <div className="confirmModalBottom border-top">
                  <div className="row no-gutters g-0">
                    <div className="col">
                      <button
                        className="btn btn-success btn-block border-0  w-100 confirmModalConfirmBtn"
                        type="submit"
                      >
                        <span className="text-md">Add Address</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      )}
      {updateAddressModal && (
        <UpdateAddressModal
          handleCountryList={handleCountryList}
          handleStateList={handleStateList}
          handleCityList={handleCityList}
          selectedCity={selectedCity}
          selectedState={selectedState}
          selectedCountry={selectedCountry}
          selecltAddresscard={selecltAddresscard}
          setUpdateAddressModal={setUpdateAddressModal}
          countrylist={countrylist}
          statelist={statelist}
          cityList={cityList}
          handleUpdatedAddress={()=>{
            getAddressList(user && user.id).then((res) => {if (res.status === 200) { 
              // console.log('address editedddddddddd')
              // addToast("Address edited successfully",{appearance:'success'})
              setAddessList(res.data);}}).catch((err) => { console.log("err", err);});
          }}
        />
      )}
    </>
  );
};

export default BillingAddress;

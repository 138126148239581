import React, {useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { SelectionComponent } from "../../../../components/SelectionComponent/SelectionComponent";
import {useDispatch, useSelector} from "react-redux";
import { getRefundTypeOptionsAsync,setRefundAmount,setRefundType,refundOrderRequestAsync } from "../../../../services/reducers/orders-reducers";
import { useToasts } from "react-toast-notifications";

export const RefundRequestModal = ({
  show,
  setShow,
  order
}) => {
  
  const dispatch = useDispatch();
  const [refundReason,setRefundReason] = useState(null);  
  const { refundRequestResponse } = useSelector((state)=> state.orders);
  const { addToast } = useToasts();

  useEffect( async()=>{
    if(refundRequestResponse){
      if(refundRequestResponse.status===1){        
        setShow(false);
        addToast(refundRequestResponse.message,{ appearance: "success"});  
      }else{
        addToast(refundRequestResponse.message,{ appearance: "error"});  
      }
    }
  },[refundRequestResponse]);
  
  const handleOk = () => {
    dispatch(refundOrderRequestAsync({
      orderId:order.id,
      refundReason : refundReason
    }));
  }

  
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen="sm-down"
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"> 
            <h5 style={{ fontSize: "18px", fontWeight: "300" }}>Order Refund Request</h5> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>      
      <div className="childs">                   
        <InputField
            type="text"                     
            placeholder="write reason here..."
            label="Refund Reason"
            labelClassName={"inputsize col-md-12"}
            value={refundReason}
            onChange={(e)=>setRefundReason(e.target.value)}
        />
      </div>
      </Modal.Body>
      <Modal.Footer
        className="popModalfooter justify-content-center"
        onClick={handleOk}      >
        <span style={{ color: "#ffffff" }}>Submit Request</span>
      </Modal.Footer>
    </Modal>
  );
};

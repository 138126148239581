import React from "react";
import "./BtnToggle.scss";

const BtnToggle = ({
  sizeLg,
  disable,
  status,
  text,
  labelClass,
  action,
  value,
}) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <label className="btnSwitch ">
          <input
            type="checkbox"
            checked={status}
            disable={disable}
            value={value}
            onChange={action}
          />
          <span className="btnSwitchCheck"></span>
        </label>
        <div className="btnSwitchText">{text}</div>
       
      </div>
    </>
  );
};

export default BtnToggle;

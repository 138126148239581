import React from "react";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../../components/InputField";
import { SelectionComponent } from "../../../../../components/SelectionComponent/SelectionComponent";

const TableActions = () => {
  return (
    <>
      <Row>
        <Col md={4}>
          <div className="d-flex py-3 ps-3">
            <div>
              <InputField
                placeholder="Search Name, email"
                label="Search Name, email"
              />
            </div>
            <div className="ps-3">
              <SelectionComponent label="Status" option={["All"]} />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TableActions;

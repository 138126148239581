import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import BtnToggle from "../../../../../../../../../components/BtnToggle/BtnToggle";
import { InputField } from "../../../../../../../../../components/InputField";
import { SelectionComponent } from "../../../../../../../../../components/SelectionComponent/SelectionComponent";
import { UploadFile } from "../../../../../../../../../components/UploadFile/UploadFile";
import ProjectContext from "../../../../../../../../../context/ProjectContext";
import { getSelectionType } from "../../../../../../../../../services/Provider/Configurator/Listings";

const SignDetails = ({
  setSignDetail,
  signDetail,
  handleChange,
  isActiveTab,
  siteDetailtabs,
  handleUploadConfirmModal,
  repeatInformation
}) => {
  const [pricingType, setPricingType] = useState(null);
  const { createListing } = useContext(ProjectContext);    

  // fetch pricing type data
  useEffect(async () => {
    await getSelectionType("SignDirectionFacing")
      .then((res) => {
        if (res.status == 200) {
          setPricingType(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      {signDetail?.map(
        (elem, i) =>
          siteDetailtabs[i]?.isActive && (                                    
            <Row>
              <Col md={5}>
                <div className="billable_inputContainer">
                  <InputField
                    name="description"
                    placeholder="Description"
                    label="Description"
                    value={repeatInformation?signDetail[0].description:elem.description}
                    onChange={(e) => {
                      // setSignDetail();
                      handleChange(e, i);
                    }}
                    disabled={i?repeatInformation:false}
                  />
                </div>
              </Col>
              {createListing && !createListing.sitesIds && (
              <Col md={5}>
                <div className="billable_inputContainer">
                  <div className="floatingLabel form-floating">
                  <input className=" inputField form-control text-start"
                    type="button"
                    name="googleAddress"
                    placeholder="Specific Location"
                    value={repeatInformation?signDetail[0].googleAddress:elem.googleAddress}
                    onClick={() => handleUploadConfirmModal(true)}
                    disabled={i?repeatInformation:false}
                  /><label for="floatingInput">Select Location</label></div>
                  
                </div>
              </Col>
              )}
              <Col md={5}>
                <div className="billable_inputContainer">
                  <SelectionComponent
                    name="signDirectionFacing"
                    type="number"
                    placeholder="Direction Facing"
                    label="Direction Facing"
                    value={repeatInformation?signDetail[0].signDirectionFacing:elem.signDirectionFacing}
                    defaultValue={repeatInformation?signDetail[0].signDirectionFacing:elem.signDirectionFacing}
                    handleOnchange={(e) => {
                      // handleChange(e, i);
                      handleChange(e, i);
                    }}
                    option={pricingType}
                    disabled={i?repeatInformation:false}
                  />
                </div>
              </Col>
              <Col md={5}>
                <div className="billable_inputContainer">
                  <UploadFile
                    name="image"
                    btnlable="Browse Image"
                    uploadBoxSize="billable_inputUpload"
                    placeholder={
                      repeatInformation?
                      (signDetail[0]?.image
                        ? "Sign image attached"
                        : "No file attached"):
                        (signDetail[i]?.image
                          ? "Sign image attached"
                          : "No file attached")
                    }
                    handelUploadLogo={(e) => {
                      // handleChange(e, i);
                      handleChange(e, i);
                    }}
                    disabled={i?repeatInformation:false}
                    allowedFiles="image/png, image/gif, image/jpeg"
                  />
                </div>
              </Col>
            </Row>
          )
      )}
    </>
  );
};

export default SignDetails;
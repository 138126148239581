import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { NotificationSVG } from "../../../../../assets/svgComponents/table";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import NavBar from "../../../../../components/navBar";
import ProjectContext from "../../../../../context/ProjectContext";
import { getOfferDetails } from "../../../../../services/Provider/Configurator/Inventory";
import { ActiveDeactiveOffer } from "../../../../../services/Provider/Configurator/Offers";
import { SwitchTab } from "./SwitchTab";
import { useToasts } from "react-toast-notifications";
import { checkPermission } from "../../../../../utils";

export const OfferDetailsPage = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();
  const { selectedOffer } = useContext(ProjectContext);
  const [refreshPage, setRefreshPage] = useState(false);
  const tabs = ["DETAILS", "INSIGHTS"];

  // const gridHeading = ["Service ID", "Name", "Description"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [inactiveOfferModaShow, setInactiveOfferModaShow] = useState(false);
  const [offerDetails, setOfferDetails] = useState();

  // const sitesBody = [
  //   "#ST21/1-3",
  //   "King Poster (170” x 35”)",
  //   "35 Bayward Market Square Area, Ottawa, Canada",
  // ];

  // useEffect(()=>{
  //   getOfferDetails('f485be91-8a94-4088-3d23-08da072ac1c0').then(res=>{
  //     console.log('res', res)
  //     if(res.status===200){
  //       setOfferDetails(res.data)
  //     }
  //   }).catch(err=>{
  //     console.log('err', err)
  //   })
  // },[])

  const editOffer = () => {
    history.push("/provider/configurator/offer-management/edit-offer");
  };

  const makeOfferActiveInactive = () => {
    setInactiveOfferModaShow(true);
  };

  const setInactiveOffer = () => {
    let disabled = selectedOffer.status == "Inactive" ? false : true;
    let reqBody = {
      id: selectedOffer.id,
      isDisabled: disabled,
    };
    ActiveDeactiveOffer(reqBody)
      .then((res) => {
        if (res.status === 200) {
          setInactiveOfferModaShow(false);
          addToast(
            "Offer has been " +
              (disabled === true
                ? "Inactivated Successfully."
                : "Activated Successfully."),
            { appearance: "success" }
          );
          history.push("/provider/configurator/offer-management");
        }
      })
      .catch((err) => {
        addToast("Something went wrong.", { appearance: "error" });
        console.log("err", err);
      });
  };

  return (
    <div className="main_SubSection">
      <NavBar
        // blueBtnText={selectedOffer.status != "Expired"?"Edit Details":""}
        whiteBtnText={
          selectedOffer.status != "Expired"
            ? selectedOffer.status == "Inactive"
              ? "Make Offer Active"
              : "Make Offer Inactive"
            : ""
        }
        notificationSVG={<NotificationSVG />}
        navHeading={selectedOffer && selectedOffer.name}
        subHeading="Configurator • All Offers"
        subActiveHeading="Offer Details"
        // handleBlueBtn={editOffer}
        handleWhitebtn={makeOfferActiveInactive}
        showButton={checkPermission("OfferManagement", "Full")}
        />
      <div className="p-20">
        <div className="tableActions_container">
          <Row className="">
            <Col className="d-flex col-md-8 ">
              <ul className="tableActions_tabs">
                {tabs.map((item) =>
                  item === activeTab ? (
                    <li
                      className="tableActions_tab active"
                      onClick={() => {
                        setActiveTab(item);
                      }}
                    >
                      {item} <hr className="tableActions_tab_hr" />
                    </li>
                  ) : (
                    <li
                      className="tableActions_tab"
                      onClick={() => {
                        setActiveTab(item);
                      }}
                    >
                      {item}
                    </li>
                  )
                )}
              </ul>
            </Col>
          </Row>
        </div>
        <SwitchTab activeTab={activeTab} offerDetails={selectedOffer} />
      </div>
      {inactiveOfferModaShow && (
        <ConfirmModal
          // setConfirmModal={setAddSignModal}
          //   confirm={handleAddSignsModal}
          title={selectedOffer.name}
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          cancelText="No"
          handleClose={() => setInactiveOfferModaShow(false)}
          handleConfirm={setInactiveOffer}
        >
          <div className="text-center childs">
            <p className="newListingCreated_description">
              Would you like to {selectedOffer.status == "Inactive"?"Active":"Inactive"} this offer?
            </p>
          </div>
        </ConfirmModal>
      )}
    </div>
  );
};

import React from "react";
import RadioButton from "../RadioButton/RadioButton";

export const AddressCard = ({
  text,
  makeDefoultAddrees,
  activeCard,
  address,
  user,
  deleteAddress,
  index,
  editAddress,
  isNotEditable,
  widthClassName,
}) => {
  if (isNotEditable == null) isNotEditable = true;
  return (
    <>
      <div className={`addressCard ${widthClassName} ${activeCard ? "activeCard" : ""}`}>
        <div className="d-flex justify-content-between">
          <div className="checkBoxContainer" style={{ cursor: "pointer" }}>
            <RadioButton
              isDefault={address && address.isDefault}
              onChange={() => makeDefoultAddrees(address)}
              index={index}
              isAddress={true}
            />
          </div>
          {isNotEditable && (
            <div className="d-flex ">
              <p
                className="mx-2"
                style={{
                  color: "#808080",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => editAddress(address && address)}
              >
                Edit
              </p>
              <p
                style={{
                  color: "#808080",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => deleteAddress(address && address)}
              >
                Delete
              </p>
            </div>
          )}
        </div>
        <div className="addressDetailscontainer">
          <div className="userdetails">
            <p>
              {user && user.name} <span> {user && user.contactNo} </span>
            </p>
            <p>
              {address && address.address1}{" "}
              <span>{address && address.city} </span>
              {address && address.state}{" "}
            </p>
            <p>
              {address && address.country}
              <span>{address && address.pincode}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { FTUE } from ".";
import ConfirmModal from "../../../../components/confirmModal/ConfirmModal";
import { ConfiguratorRoutes } from "../ConfigratorPage/ConfiguratorRoutes";
import { Dashboard } from "../Dashboard/index";
import { Insights } from "../Dashboard/Insights";
import { OrderAndTransaction } from "../OrderAndTransaction/OrderAndTransaction";
import { UserMamagementRoutes } from "../UserManagement/Routes";
import { Profile } from "../../../../components/profile/Profile";
import { AddSupportRequest } from "../SupportRequest/Add";
import ProjectContext from "../../../../context/ProjectContext";
import {
  currentUser,
  getAddressList,
  getProvider,
} from "../../../../services/Provider";
import { EditProfile } from "../../../../components/profile/EditProfile";
import { ProfileRoutes } from "../Profile/ProfileRoutes";
import ChangePassword from "../../Admin/Settings/Pages/ChangePassword";
import { PaymentPage } from "../ConfigratorPage/Listings/pages/NewListings/PaymentPage";
import { AddSitePage } from "../ConfigratorPage/Inventory/components/AddSitePage/AddSitePage";
import { AddSignPage } from "../ConfigratorPage/Inventory/components/AddSignPage/AddSignPage";
import { AddServicePage } from "../ConfigratorPage/Inventory/components/AddServicePage/AddServicePage";
import { AddChargePage } from "../ConfigratorPage/Inventory/components/AddChargePage/AddChargePage";
import { OrdersIndexPage } from "../../Admin/Orders/OrdersIndexPage";

export const FTUERoutes = ({ ...rest }) => {
  // const {provider}=useContext(ProjectContext)

  const [siteConfirmModal, setSiteConfirmModal] = useState(false);
  const [provider, setProvider] = useState();
  const [addessList, setAddessList] = useState([]);
  const [curUser, setCurUser] = useState();
  const handleSave = () => {
    setSiteConfirmModal(true);
    console.log("save");
  };
  const handleCancel = () => {
    console.log("Cancel");
  };

  useEffect(() => {
    currentUser()
      .then((res) => {
        if (res.status === 200) {
          setProvider(res.data);
          let id = res.data.id;
          getAddressList(id)
            .then((res) => {
              if (res.status === 200) {
                setAddessList(res.data);
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [window.location.pathname]);

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${rest.match.url}/`}
          render={(props) => <FTUE {...props} />}
        />

        <Route
          path={`${rest.match.url}/dashboard`}
          render={(props) => <Dashboard {...props} />}
        />
        <Route
          path={`${rest.match.url}/add-support-request`}
          render={(props) => <AddSupportRequest {...props} />}
        />

        <Route
          path={`${rest.match.url}/insights`}
          render={(props) => <Insights {...props} />}
        />

        <Route
          path={`${rest.match.url}/orders`}
          render={(props) => (
            <OrdersIndexPage
              {...props}
              // userProfile={userProfile}
            />
          )}
        />
        <Route
          path={`${rest.match.url}/usermanagement`}
          render={(props) => (
            <UserMamagementRoutes
              {...props}
              // userProfile={userProfile}
            />
          )}
        />
        <Route
          path={`${rest.match.url}/configurator`}
          render={(props) => (
            <ConfiguratorRoutes
              {...props}
              // userProfile={userProfile}
            />
          )}
        />
        <Route
          path={`${rest.match.url}/profile`}
          render={(props) => (
            <Profile
              title="Profile"
              subTitle=""
              provider={true}
              {...props}
              // user={provider}
              addessList={addessList}
              userType={"Provider"}
            />
          )}
        />
        <Route
          path={`${rest.match.url}/editProfile`}
          render={(props) => (
            <EditProfile
              title="Profile"
              subTitle=""
              provider={true}
              {...props}
              user={provider}
            />
          )}
        />
        <Route
          path={`${rest.match.url}/change-password`}
          render={(props) => (
            <ChangePassword
              title="Profile"
              subTitle=""
              provider={true}
              {...props}
              user={provider}
            />
          )}
        />

        <Route
          path={`${rest.match.url}/add-site`}
          render={(props) => <AddSitePage {...props} provider={true} />}
        />
        <Route
          path={`${rest.match.url}/add-sign`}
          render={(props) => <AddSignPage {...props} provider={true} />}
        />
        <Route
          path={`${rest.match.url}/add-service`}
          render={(props) => <AddServicePage {...props} provider={true} />}
        />
        <Route
          path={`${rest.match.url}/add-charge`}
          render={(props) => <AddChargePage {...props} provider={true} />}
        />
        <Route
          path={`${rest.match.url}/payment`}
          render={(props) => <PaymentPage {...props} />}
        />
        {/* <Route
          exact={true}
          path='*'
          render={(props) => <Dashboard {...props} />}
        /> */}
      </Switch>
    </>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Row } from "react-bootstrap";
import { FieldArray, Formik, Form, Field } from "formik";
import * as Yup from "yup";

import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import NavBar from "../../../../../components/navBar";
import { NotificationSVG } from "../../../../../assets/svgComponents/table";
import { changePassword } from "../../../../../services/Provider/UserManagement/UserManagement";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ShowHidePassword } from "../../../../../components/InputGroup/ShowHidePassword";
import { useToasts } from "react-toast-notifications";

const ChangePassword = () => {
  const [passwordSaved, setPasswordSaved] = useState(false);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [apiRes, setApiRes] = useState("");
  const [curPwdType, setCurPwdType] = useState("password");
  const [newPwdType, setNewPwdType] = useState("password");
  const [confPwdType, setConfPwdType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [curPwd, setCurPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confPwd, setConfPwd] = useState("");
  const { addToast } = useToasts();
  const [isOldPwdVisible, setIsOldPwdVisible] = useState(false);
  const [isNewPwdVisible, setIsNewPwdVisible] = useState(false);
  const [isConfPwdVisible, setIsConfPwdVisible] = useState(false);

  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.name, e.target.value);
  };
  // const togglePassword =(input)=>{
  //   if(passwordType==="password")
  //   {
  //     setPasswordType("text")
  //     return;
  //   }
  //   setPasswordType("password")
  // }
  const ValidationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    confirmPassword: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const handleBlueBtn = () => {
    setPasswordSaved(true);
  };

  const handleConfirmModal = () => {
    setPasswordSaved(false);
    history.goBack();
  };

  const handleCancelBtn = () => {
    history.goBack();
  };




  const toggleOldPwd = () => {
    setIsOldPwdVisible(!isOldPwdVisible);
  };

  const toggleNewPwd = () => {
    setIsNewPwdVisible(!isNewPwdVisible);
  };


  const toggleConfPwd = () => {
    setIsConfPwdVisible(!isConfPwdVisible);
  };

  return (
    <>
      <div className="">
        <Formik
          initialValues={{
            userId: "",
            currentPassword: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={ValidationSchema}
          onSubmit={async (values) => {
            console.log("change password response");
            setLoader(true);
            var res = await changePassword(values)
              .then((res) => {
                console.log("change password response", res);
                if (res.status === 200) {
                  addToast("Password Changed Successfully", {
                    appearance: "success",
                  });
                  history.goBack();
                  setLoader(false);
                } else if (res.status === 400) {
                  addToast("Incorrect Old Password", {
                    appearance: "error",
                  });
                } else {
                  setApiRes(res.data);
                }
              })
              .catch((err) => {
                addToast("Incorrect Old Password", {
                  appearance: "error",
                });
              });
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <NavBar
                blueBtnText={`Update`}
                whiteBtnText="Cancel"
                notificationSVG={<NotificationSVG />}
                navHeading="Settings"
                //handleBlueBtn={handleBlueBtn}
                subHeading="Change Password"
                subActiveHeading="Change Password"
                handleWhitebtn={handleCancelBtn}
                type="submit"
                showButton={true}
              />

              <div className="p-20">
                <h6 className="text-gray-1000 fw-bold">Change Password</h6>
                <Row className="pb-3 pt-4">
                  <Col md={4} className="">
                    {/*   <ShowHidePassword
                      name="currentPassword"
                      placeholder="Old Password"
                    /> */}
                    {/* <label htmlFor="Old Password">Old Password</label> */}
                    <div className="form-floating">
                      <div className="input-group pfield">
                        <Field
                          type={!isOldPwdVisible ? "password" : "text"}
                          name={"currentPassword"}
                          htmlFor="currentPassword"
                          className="form-control inputField"
                          placeholder="Old password"
                        />
                        <button
                          type="button"
                          className="btn btn-outline-default"
                          onClick={toggleOldPwd}
                          style={{
                            backgroundColor: "white",
                            borderRight: "1px #efefef solid",
                            borderBottom: "1px #efefef solid",
                            borderTop: "1px #efefef solid",
                          }}
                        >
                          {isOldPwdVisible ? (
                            <svg
                              class="hidepass"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                fill="#BABCBE"
                              ></path>
                              <line
                                x1="13.3536"
                                y1="2.35355"
                                x2="2.35355"
                                y2="13.3536"
                                stroke="#BABCBE"
                              ></line>
                            </svg>
                          ) : (
                            <svg
                              class="showpass"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                fill="#BABCBE"
                              ></path>
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>
                    {errors.currentPassword && touched.currentPassword ? (
                      <div className="text-danger pt-1">
                        {errors.currentPassword}
                      </div>
                    ) : null}

                    {/* <FlotingLabel
                      controlId="floatingInput"
                      label={"Old Password"}
                      className={"floatingLabel form-pass formItmPasswordView"}
                    >


                        {/* <button type="button" class="eye">
                                <svg class="showpass" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                        fill="#BABCBE"></path>
                                </svg>

                                <svg class="hidepass" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                        fill="#BABCBE"></path>
                                    <line x1="13.3536" y1="2.35355" x2="2.35355" y2="13.3536" stroke="#BABCBE"></line>
                                </svg>

                        </button> */}
                    {/* <Field
                        name={"currentPassword"}
                        label="Old Password"
                        htmlFor={"currentPassword"}
                        placeholder="Old Password"
                        className="inputField form-control"
                        type="password"
                      />
                      {errors.currentPassword && touched.currentPassword ? (
                        <div className="text-danger ps-1 pt-1">
                          {errors.currentPassword}
                        </div>
                      ) : null} */}
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md={4} className="">
                    <div className="form-floating">
                      {/*  <ShowHidePassword
                      name="password"
                      placeholder="New Password"
                    /> */}
                      {/* <label htmlFor="Old Password">New Password</label> */}
                      <div className="input-group pfield">
                        <Field
                          type={!isNewPwdVisible ? "password" : "text"}
                          name={`password`}
                          htmlFor="password"
                          className="form-control inputField"
                          placeholder="New Password"
                        />
                        <button
                          type="button"
                          className="btn btn-outline-default"
                          onClick={toggleNewPwd}
                          style={{
                            backgroundColor: "white",
                            borderRight: "1px #efefef solid",
                            borderBottom: "1px #efefef solid",
                            borderTop: "1px #efefef solid",
                          }}
                        >
                          {isNewPwdVisible ? (
                            <svg
                              class="hidepass"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                fill="#BABCBE"
                              ></path>
                              <line
                                x1="13.3536"
                                y1="2.35355"
                                x2="2.35355"
                                y2="13.3536"
                                stroke="#BABCBE"
                              ></line>
                            </svg>
                          ) : (
                            <svg
                              class="showpass"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                fill="#BABCBE"
                              ></path>
                            </svg>
                          )}
                        </button>
                      </div></div>
                    {errors.password && touched.password ? (
                      <div className="text-danger pt-1">
                        {errors.password}
                      </div>
                    ) : null}
                    {/* <FloatingLabel
                      controlId="floatingInput"
                      label={"New Password"}
                      className={`floatingLabel`}
                    >
                      <Field
                        name={`password`}
                        label="New Password"
                        placeholder="New Password"
                        htmlFor={`password`}
                        className="inputField form-control"
                        type="password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-danger ps-1 pt-1">
                          {errors.password}
                        </div>
                      ) : null}
                    </FloatingLabel> */}
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md={4}>
                    {/* <ShowHidePassword
                      name="confirmPassword"
                      placeholder="Confirm Password"
                    /> */}
                    {/* <label htmlFor="Confirm Password">Confirm Password</label> */}
                    <div className="form-floating">
                      <div className="input-group pfield">
                        <Field
                          type={!isConfPwdVisible ? "password" : "text"}
                          name="confirmPassword"
                          htmlFor="password"
                          className="form-control inputField"
                          placeholder="Confirm Password"
                        />
                        <button
                          type="button"
                          className="btn btn-outline-default"
                          onClick={toggleConfPwd}
                          style={{
                            backgroundColor: "white",
                            borderRight: "1px #efefef solid",
                            borderBottom: "1px #efefef solid",
                            borderTop: "1px #efefef solid",
                          }}
                        >
                          {isConfPwdVisible ? (
                            <svg
                              class="hidepass"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                fill="#BABCBE"
                              ></path>
                              <line
                                x1="13.3536"
                                y1="2.35355"
                                x2="2.35355"
                                y2="13.3536"
                                stroke="#BABCBE"
                              ></line>
                            </svg>
                          ) : (
                            <svg
                              class="showpass"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                fill="#BABCBE"
                              ></path>
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <div className="text-danger pt-1">
                        {errors.confirmPassword}
                      </div>
                    ) : null}

                    {/* <FloatingLabel
                      controlId="floatingInput"
                      label={"Confirm Password"}
                      className={`floatingLabel`}
                    >
                      <Field
                        name={`confirmPassword`}
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        htmlFor={`confirmPassword`}
                        className="inputField form-control"
                        type="password"
                      /> 
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <div className="text-danger ps-1 pt-1">
                          {errors.confirmPassword}
                        </div>
                      ) : null}
                    </FloatingLabel>*/}
                  </Col>
                </Row>
              </div>
              {/* <div>
                <button type="submit">Submit</button>
              </div> */}
              {/* {apiRes.title ? <div className="text-danger">{apiRes.title}</div> : null} */}
            </Form>
          )}
        </Formik>
      </div>
      {/* new listing created modal */}
      {passwordSaved && (
        <ConfirmModal
          // setConfirmModal={setAddSignModal}
          //   confirm={handleAddSignsModal}
          title="Password changed"
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Close"
          handleClose={handleConfirmModal}
          handleConfirm={handleConfirmModal}
        >
          <div className="childs">
            <p className="newListingCreated_description text-left">
              Password successfully changed.
            </p>
          </div>
        </ConfirmModal>
      )}

    </>
  );
};

export default ChangePassword;

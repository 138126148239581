import React from "react";

function RadioButton2({value, onBlur, onChange, disabled, name,isDefaultAtm,index }) {
  return (
    <>
      <div className="radio-before">
        <label className="radio radio-gradient d-flex align-items-center mr-3">
          <span className="radio__input">
            <input
              type="radio"
            //   name={name  ? name:"radio"}
              id="radio1"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              disabled={disabled}
              checked={isDefaultAtm}
            />
            <span className="radio__control"></span>
          </span>
          <span className="radio__label" for="radio1">
            
            {isDefaultAtm?'Default Card':`Card ${index+1}`}
          </span>
        </label>
      </div>
    </>
  );
}

export default RadioButton2;

import axios from "axios";
// let accessToken = localStorage.getItem("accessToken")
import { AuthService } from "./AuthService";

let accessToken = window.localStorage.getItem("accessToken");
// console.log('authToken', authToken)

export const http = axios.create({
  baseURL: `https://gopinitswagger.razamerchant.com/`,
  // baseURL:`https://localhost:44377/`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: "text/plain",
    // "Content-Type": "multipart/form-data"
  },
});

http.interceptors.request.use(
  function (config) {
    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add("loading-indicator");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a 401 response interceptor
http.interceptors.response.use(
  function (response) {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  function (error) {
    document.body.classList.remove("loading-indicator");
    if (401 === error.response.status) {
      console.log("401 unauth error....");
      let authService = new AuthService();
      authService.logout();
      // return authService.renewToken().then(renewedUser => {
      //   console.log('renewed user', renewedUser);
      //  // return this._callApi(renewedUser.access_token);
      // },(err)=>{
      //   console.log('renewed user rejected',err);
      // });
      // handle error: inform user, go to login, etc
    } else {
      return Promise.reject(error);
    }
  }
);

import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { useToasts } from "react-toast-notifications";

import { NotificationSVG } from "../../../../../../../../assets/svgComponents/table";
import { AtmWithRadioBtn } from "../../../../../../../../components/AtmCard/AtmWithRadioBtn";
import NavBar from "../../../../../../../../components/navBar";
import { createListingPayment } from "../../../../../../../../services/Provider/Configurator/Listings";
import BillingAddress from "./components/BillingAddress";
import BookingDetails from "./components/BookingDetails";
import PaymentMode from "./components/PaymentMode";
import PriceDetails from "./components/PriceDetails";

export const PaymentPage = () => {
  const addToast = useToasts();
  const location = useLocation();
  const history = useHistory();
  const [cardId, setCardId] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  let tempCount = localStorage.getItem("onBoardingStepDone");
  tempCount = parseInt(tempCount);
  const handlePayNow = async () => {
    console.log(cardId[0], "cardId[0]?.id");
    if (cardId[0].id) {
      let reqBody = {
        listingId: location.featuredListingDetails.listingId,
        amount: location.featuredListingDetails.price,
        cardId: cardId[0].id,
        fromDate: location.featuredListingDetails.start_date,
        uptoDate: location.featuredListingDetails.end_date,
      };
      setSaveLoader(true);
      await createListingPayment(reqBody)
        .then((res) => {
          if (res.status == 200) {
            if (tempCount == 3) {
              history.push({
                pathname: "/provider",
                nextOnBoardinStepTo: tempCount,
              });
              setSaveLoader(false);
            } else {
              history.push({
                pathname: "/provider/configurator/listings",
              });
              setSaveLoader(false);
            }
            addToast("Featured listing Payment successful", {
              appearance: "success",
            });
          }
        })
        .catch((err) => err);
    } else {
      addToast("Please make atleast one card default", {
        appearance: "warning",
      });
    }
  };
  return (
    <div className="AddSiteContaimer">
      <NavBar
        notificationSVG={<NotificationSVG />}
        navHeading="Payment"
        subHeading="Listing"
        subActiveHeading="Payment"
        blueBtnText={`Pay now`}
        handleBlueBtn={handlePayNow}
        isDisabled={saveLoader}
        isDisabledText="Saving..."
        showButton={true}
      />
      <div className="mainsection_1 mainsection-payment">
        <div className=" gridSection p-20">
          <Row className="h-100">
            <Col className="col-md-4 d-flex flex-column overflow-auto h-100 flex-grow-1">
              <BookingDetails
                bookingDetails={location?.featuredListingDetails}
              />
              <PriceDetails priceDetails={location?.featuredListingDetails} />
            </Col>
            <Col className="col-md-8 d-flex flex-column overflow-auto h-100 flex-grow-1">
              <div className="billingAddressSection overflow-auto h-100 p-20">
                <BillingAddress />
                <div className="borderTop">&nbsp;</div>

                <PaymentMode setCardId={setCardId} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

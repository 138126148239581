import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { useToasts } from "react-toast-notifications";

import BtnBlue from "../../../../../../components/button/BtnBlue";
import BtnWhite from "../../../../../../components/button/BtnWhite";
import Checkbox from "../../../../../../components/checkbox/Checkbox";
import { InputField } from "../../../../../../components/InputField";
import { SelectionComponent } from "../../../../../../components/SelectionComponent/SelectionComponent";
import ProjectContext from "../../../../../../context/ProjectContext";
import { getEnvironmentType } from "../../../../../../services/Provider";

const NewListing = ({
  setNewListingEntities,
  newListingEntities,
  handleProceed,
  listingName,
  isTouched,
  handleSelectListing,
  onChangeListName,
  selectedEntities,
  isListingNameChar,
  setNewListingModal,
}) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="modal_heading_title">New Listing</div>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="py-3">
          <InputField
            name="listingName"
            label="Enter Listing Name"
            placeholder="Enter Listing Name"
            value={listingName}
            onChange={(e) => onChangeListName(e)}
          />
          {isTouched.name && (
            <div className="textError">Please enter listing name</div>
          )}
          {isListingNameChar && (
            <div className="textError">
              Listing name should be greater 5 char{" "}
            </div>
          )}
        </div>
        
        <div className="list_title">
          Which entities would you like to include in the Listing?
        </div>
        {selectedEntities?.map((entitie, index) => (
          <div className="checkbox_container" key={index}>
            {entitie.name == "Configurations" ? null : (
              <Checkbox
                action={() => handleSelectListing(entitie)}
                text={entitie.name}
                value={entitie.isChecked}
                defaultChecked={entitie.isChecked}
                isChecked={entitie.isChecked}
                titleClassName="checkbox_title"
              />
            )}
          </div>
        ))}
        {isTouched.entitie && (
          <div className="textError">Please select entities</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <BtnBlue onClick={() => handleProceed()}>Proceed</BtnBlue>
          <div className="ps-3">
            <BtnWhite onClick={() => setNewListingModal(false)}>
              Cancel
            </BtnWhite>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default NewListing;

import React from "react";
import { Modal } from "react-bootstrap";
import Checkbox from "../../../../components/checkbox/Checkbox";
import CustomRadioButton from "../../../../components/RadioButton/CustomRadioButton";

export const FilterModal = ({
  show,
  statuslable,
  sortByLable,
  medialable,
  handleApplyBtn,
  transactionLable,
  setShowFilterModal,
  setClearAllFilter,
  accessTypeLable,
  statusOnChange,
  statusValue
}) => {

  // console.log(statuslable, "statuslable");
  // console.log(sortByLable, "sortByLable");
  // console.log(medialable, "medialable");
  return (
    <Modal
      show={show}
      onHide={() => setShowFilterModal(false)}
      dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
    >
      <Modal.Header closeButton>
        <h5 className="modaltitle">Filters</h5>{" "}
        <button className="clearAllButton"   onClick={() => setClearAllFilter()}>clear All</button>
      </Modal.Header>
      <Modal.Body>
      {accessTypeLable ? (
          <div className="modalStatus mt-4">
            <h6>Access Type</h6>
            {accessTypeLable.map((item) => {
              return (
                <div className="mt-3">
                  <Checkbox text={item.name} />                  
                </div>
              );
            })}
          </div>
        ) : null}
        {statuslable ? (
          <div className="modalStatus mt-4">
            <h6>Status</h6>
            {statuslable.map((item) => {
              return (
                <div className="mt-3">                  
                  <CustomRadioButton  value={item.id} text={item.name} onChange={e=>statusOnChange(e,item)} isDefault={statusValue} />
                </div>
              );
            })}
          </div>
        ) : null}
        {transactionLable ? (
          <div className="modalStatus mt-4">
            <h6>Transaction Type</h6>
            {transactionLable.map((item) => {
              return (
                <div className="mt-3">
                  <Checkbox text={item.name} />
                </div>
              );
            })}
          </div>
        ) : null}
        {sortByLable ? (
          <div className="modalStatus mt-4">
            <h6>Sort By</h6>
            {sortByLable.map((item) => {
              return (
                <div className="mt-3">
                  <Checkbox text={item.name} />
                </div>
              );
            })}
          </div>
        ) : null}

        {medialable ? (
          <div className="modalStatus mt-4">
            <h6>Media</h6>
            {medialable.map((item) => {
              return (
                <div className="mt-3">
                  <Checkbox text={item.name} />
                </div>
              );
            })}
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <div className="applyFilter">
          <button
            className="blueBgButton applyFilterBtn py-3 px-5"
            onClick={handleApplyBtn}
          >
            Apply
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

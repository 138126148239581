import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useHistory, useLocation } from "react-router";
import { useToasts } from "react-toast-notifications";

import { NotificationSVG } from "../../../../../../../../../assets/svgComponents/table";
import BtnRadio from "../../../../../../../../../components/BtnRadio/BtnRadio";
import { BtnSwitch } from "../../../../../../../../../components/BtnSwitch/BtnSwitch";
import BtnToggle from "../../../../../../../../../components/BtnToggle/BtnToggle";
import ConfirmModal from "../../../../../../../../../components/confirmModal/ConfirmModal";
import { InputField } from "../../../../../../../../../components/InputField";
import { AddMap } from "../../../../../../../../../components/Maps/sitePage/AddMap";
import NavBar from "../../../../../../../../../components/navBar";
import { UploadFile } from "../../../../../../../../../components/UploadFile/UploadFile";
import ProjectContext from "../../../../../../../../../context/ProjectContext";
import { uploadFileMethod } from "../../../../../../../../../services/Provider";
import SignDetails from "./SignDetails";
import { GetOnlyAddressFromCompoundCode } from "../../../../../../../../../services/utility";
import { getAddressByLatLng } from "../../../../../../../../../services/api/customer-store-api";

const CreateListingSignsDetails = ({
  numberOfSigns,
  sideTabs,
  setActiveTab,
  selectedSign,
  activeTab,
  setSideTabs,
}) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();
  const [isActiveTab, setIsActiveTab] = useState();
  const { handleCreateListing, createListing } = useContext(ProjectContext);
  const [siteDetailtabs, setSiteDetailtabs] = useState([]);
  const [repeatInformation, setRepeatInformation] = useState(false);
  const [uploadConfirmModal, setUploadConfirmModal] = useState(false);
  const [loc, setLoc] = useState();
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [center, setCenter] = useState();
  const [searchBox, setSearchBox] = useState(null);
  const [specficLoc, setSpecficLoc] = useState({
    lat: 0,
    long: 0,
    googleAddress: "",
    locationId: "",
  });
  const [signDetail, setSignDetail] = useState([
    {
      signId: createListing.signIds,
      description: "",
      googleAddress: "",
      locationId: "",
      latitude: "",
      longitude: "",
      signDirectionFacing: 0,
      image: "",
      repeatInfoForAll: false,
      noOfSameSigns: numberOfSigns,
    },
  ]);

  // creating dynamic tab under site details
  useEffect(() => {
    let tempTab = [];
    let tempSignDetail = [];
    let repeatInformation = false;
    if (location?.data?.signs.length < numberOfSigns) {
      repeatInformation =true;
      setRepeatInformation(true);
    }
    for (let i = 0; i < numberOfSigns; i++) {
      tempTab = [
        ...tempTab,
        { name: `SIGN ${i + 1}`, isActive: false, isDisable: false },
      ];
      tempSignDetail = [
        ...tempSignDetail,
        {
          signId: createListing.signIds,
          description: location?.data?.signs[i]?.description
            ? location?.data.signs[i]?.description
            : repeatInformation
            ? location?.data.signs[0]?.description
            : "",
          googleAddress: location?.data?.signs[i]?.googleAddress
            ? location?.data.signs[i]?.googleAddress
            : repeatInformation
            ? location?.data.signs[0]?.googleAddress
            : "",
          locationId: location?.data?.signs[i]?.locationId
            ? location?.data.signs[i]?.locationId
            : repeatInformation
            ? location?.data.signs[0]?.locationId
            : "",
          latitude: location?.data?.signs[i]?.latitude
            ? location?.data.signs[i]?.latitude
            : repeatInformation
            ? location?.data.signs[0]?.latitude
            : 0,
          longitude: location?.data?.signs[i]?.longitude
            ? location?.data.signs[i]?.longitude
            : repeatInformation
            ? location?.data.signs[0]?.longitude
            : 0,
          signDirectionFacing: location?.data?.signs[i]?.signDirectionFacing
            ? location?.data.signs[i]?.signDirectionFacing
            : repeatInformation
            ? location?.data.signs[0]?.signDirectionFacing
            : 0,
          image: location?.data?.signs[i]?.image
            ? location?.data.signs[i]?.image
            : repeatInformation
            ? location?.data.signs[0]?.image
            : "",
          repeatInfoForAll: false,
          noOfSameSigns: numberOfSigns,
        },
      ];
    }
    tempTab[0].isActive = true;
    setIsActiveTab(0);
    setSiteDetailtabs(tempTab);
    setSignDetail(tempSignDetail);
    
  }, []);

  // handle for sign details tab change
  const handleSignDetailsTabs = (item) => {
    // setIsActiveTab(item);

    // console.log(item);
    if (item.name == "SITE 1") {
      let tempData = [...siteDetailtabs];
      tempData.forEach((elem) => {
        elem.name == item.name
          ? (elem.isActive = true)
          : (elem.isActive = false);
      });
      setSiteDetailtabs(tempData);
    } else {
      // setRepeatInformation(false);
      let tempData = [...siteDetailtabs];
      tempData.forEach((elem) => {
        elem.name == item.name
          ? (elem.isActive = true)
          : (elem.isActive = false);
      });
      setSiteDetailtabs(tempData);
    }
  };

  const handelUploadFile = async (e, index, tempSiteDetails) => {
    let data = e.target.files[0];
    let image = new FormData();
    image.append("file", data);
    await uploadFileMethod(image)
      .then((res) => {
        if (res.status == 200) {
          tempSiteDetails[index]["image"] = res.data.fileKey;
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    setSignDetail(tempSiteDetails);
  };

  const handleChange = (e, index) => {
    setRepeatInformation(false);
    const { name, value } = e.target;
    // console.log(e.target.value, "value");
    const tempSiteDetails = [...signDetail];

    if (name == "image") {
      handelUploadFile(e, index, tempSiteDetails);
    } else if (name == "signDirectionFacing") {
      tempSiteDetails[index]["signDirectionFacing"] = parseInt(value);
      setSignDetail(tempSiteDetails);
    } else if (name == "googleAddress") {
      tempSiteDetails[isActiveTab]["googleAddress"] = value;
      tempSiteDetails[isActiveTab]["locationId"] = value;
      tempSiteDetails[isActiveTab]["latitude"] = parseInt(value);
      tempSiteDetails[isActiveTab]["longitude"] = parseInt(value);
      setSignDetail(tempSiteDetails);
    } else {
      tempSiteDetails[index][name] = value;
      setSignDetail(tempSiteDetails);
    }
  };

  // console.log(repeatInformation, "repeatInformation");
  const handleRepeatInformation = (e) => {
    setRepeatInformation(!repeatInformation);
    // console.log(e.target.value, "val");
    let tempSiteDetails = [...signDetail];
    let tempSiteDetails2 = [];
    if (!repeatInformation) {
      tempSiteDetails.forEach((item, index) => {
        item.description = signDetail[0].description;
        item.repeatInfoForAll = true;
        item.googleAddress = signDetail[0].googleAddress;
        item.signDirectionFacing = signDetail[0].signDirectionFacing;
        item.image = signDetail[0].image;
        item.locationId = signDetail[0].locationId;
        item.latitude = signDetail[0].latitude;
        item.longitude = signDetail[0].longitude;
        item.noOfSameSigns = signDetail[0].noOfSameSigns;
      });
      console.log(tempSiteDetails, "tempSiteDetails");
      setSignDetail(tempSiteDetails);
    } else {
      setSignDetail(tempSiteDetails);
    }
  };

  const handleChangeSign = () => {
    setActiveTab("Signs");
  };

  const handleNext = (activeTab) => {
    // console.log(signDetail, "signDetail123456789");
    let tempSigndetails;
    // console.log(repeatInformation, "repeatInformation");
    // console.log(signDetail, "signDetail");
    // if (repeatInformation) {
    //   tempSigndetails = [signDetail[0]];
    // }
    let tempErrorMsg = {
      description: false,
      googleAddress: false,
      signDirectionFacing: false,
      // uploadImage: false,
    };

    signDetail.map((item) => {
      item.description == "" && (tempErrorMsg.description = true);
      if (!createListing.sitesIds) {
        item.googleAddress == "" && (tempErrorMsg.googleAddress = true);
      }
      item.signDirectionFacing == null &&
        (tempErrorMsg.signDirectionFacing = true);
      // item.uploadImage == "" && (tempErrorMsg.uploadImage = true);
    });
    if (tempErrorMsg.description) {
      addToast("Please enter description", {
        appearance: "warning",
      });
    }
    if (tempErrorMsg.googleAddress && !createListing.sitesIds) {
      addToast("Please enter specific location", {
        appearance: "warning",
      });
    }
    if (tempErrorMsg.signDirectionFacing) {
      addToast("Please enter sign direction facing", {
        appearance: "warning",
      });
    }
    // if (tempErrorMsg.uploadImage) {
    //   addToast("Please upload image", {
    //     appearance: "warning",
    //   });
    // }
    if (
      !tempErrorMsg.description &&
      !tempErrorMsg.googleAddress &&
      !tempErrorMsg.signDirectionFacing
      // !tempErrorMsg.uploadImage
    ) {
      // if (repeatInformation) {
      //   handleCreateListing({ ...createListing, signs: tempSigndetails });
      // } else {
      handleCreateListing({ ...createListing, signs: signDetail });
      // }
      if (activeTab == "EditSigns") {
        // handleCreateListing({ ...createListing, signs: signDetail });
        let tempSide = sideTabs.findIndex((elem) => elem.name == "Signs");
        let tempSide2 = [...sideTabs];
        tempSide2.forEach(
          (elem) =>
            elem.name == activeTab &&
            (elem.isActive = true)((elem.isCurrentTitle = false))
        );
        //console.log(tempSide2, "tempSide2");
        setSideTabs(tempSide2);
        setActiveTab(sideTabs[tempSide + 1].name);
        sideTabs.forEach(
          (elem, index) =>
            index == tempSide &&
            (elem.isActive = true)((elem.isCurrentTitle = false))
        );
      }
    }
  };

  const handleWhiteBtn = () => {
    history.push("/provider/configurator/listings");
  };

  const getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition((position) => {
        //this.props.change("latitude", position.coords.longitude);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };
  // useEffect(() => {
  //   getMyLocation();
  // }, [uploadConfirmModal]);
  const onPlacesChanged = () => {
    const markerArray = [];
    let results = searchBox.getPlaces();
    for (let i = 0; i < results.length; i++) {
      let place = results[i].geometry.location;
      markerArray.push(place);
    }
    markerArray.map((mark, index) => setCenter(mark));
    //getMyLocation();

    //this.setState({ markers: markerArray });
    //console.log(markerArray.map(x => x.position.lat()),"lat");
    //console.log(markerArray.map(x => x.position.long()),"long");
  };
  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    let lat = latLng.lat();
    let lng = latLng.lng();
    setLat(lat);
    setLng(lng);
    setCenter({ lat: lat, lng: lng });
    console.log("new sign coords", lat, lng);
    // formik.setFieldValue("siteLocation.latitude", lat);
    // formik.setFieldValue("siteLocation.longitude", lng);
    //handleChange('siteLocation.latitude', lat);
    //handleChange('siteLocation.longitude', lng);
    /*
    this.setState(prevState => {
      const markers = [...this.state.markers];
      markers[index] = { ...markers[index], position: { lat, lng } };
      return { markers };
    });
    */

    //getMyLocation();
  };

  useEffect(async () => {
    if (center && center.lat && center.lng) {
      const response = await getAddressByLatLng(center.lat, center.lng);
      if (response && response.data) {
        const { compound_code } = response.data.plus_code;
        setLoc(GetOnlyAddressFromCompoundCode(compound_code));
      }
    }
  }, [center]);

  const handleConfirmModal = () => {
    setRepeatInformation(false);
    let tempIndex = siteDetailtabs.findIndex((t, i) => t.isActive);
    signDetail[tempIndex].googleAddress = loc;
    signDetail[tempIndex].locationId = "";
    signDetail[tempIndex].latitude = lat;
    signDetail[tempIndex].longitude = lng;
    let tempDetails = [...signDetail];
    console.log(tempDetails, "tempDetails");

    setSpecficLoc({
      lat: lat,
      long: lng,
      locationId: "",
      googleAddress: loc,
    });
    handleUploadConfirmModal(false);
  };

  const handleUploadConfirmModal = (status) => {
    if (status == true) {
      setLoc("");
      setLat("");
      setLng("");
    }
    setUploadConfirmModal(status);
  };

  return (
    <>
      {/* {console.log(siteDetailtabs, "siteDetailtabs")}
      {console.log(signDetail, "signDetail")} */}
      {/* {console.log(createListing, "createListing")} */}
      <div className="w-100">
        <NavBar
          whiteBtnText={"Cancel"}
          notificationSVG={<NotificationSVG />}
          navHeading={createListing?.name}
          subHeading="All Billable Item "
          subActiveHeading=" New Billable Item"
          handleWhitebtn={() => handleWhiteBtn(activeTab)}
          blueBtnText={"Next"}
          handleBlueBtn={() => handleNext(activeTab)}
          showButton={true}
        />
        <div className="p-20  gridSection">
          <div className="d-flex w-100">
            <div>
              <ul className=" progressbar">
                {sideTabs?.map((tab) => (
                  <li
                    className={`billable_switchSideDom_item ${
                      tab.isActive && "active"
                    }
                    ${tab.isCurrentTitle ? "activeTabTitle" : ""}
                   `}
                  >
                    {tab.name}
                  </li>
                ))}
              </ul>
            </div>
            <Container>
              <div className="card_container">
                <Row g-0>
                  <Col>
                    <div className="billable_card_title">MEDIA</div>
                    <div className="billable_card_discription">
                      {selectedSign.mediaType ? selectedSign.mediaType : "-"}
                    </div>
                  </Col>
                  <Col>
                    <div className="billable_card_title">SIZE NAME</div>
                    <div className="billable_card_discription">
                      {selectedSign.name ? selectedSign.name : "-"}
                    </div>
                  </Col>
                  <Col>
                    <div className="billable_card_title">DIMENISIONS</div>
                    <div className="billable_card_discription">
                      {selectedSign.dimension ? selectedSign.dimension : "-"}
                    </div>
                  </Col>
                  <Col>
                    <div className="billable_card_title">WIDTH</div>
                    <div className="billable_card_discription">
                      {selectedSign.width ? selectedSign.width : "-"}
                    </div>
                  </Col>
                  <Col>
                    <div className="billable_card_title">HEIGHT</div>
                    <div className="billable_card_discription">
                      {selectedSign.height ? selectedSign.height : "-"}
                    </div>
                  </Col>
                  <Col
                    md={3}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <div
                      className="billable_card_btn cursor"
                      onClick={() => handleChangeSign()}
                    >
                      Change Sign
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="billable_tabs_heading">Sign Details</div>
              <div className="billable_tabs ">
                {siteDetailtabs?.map((tab, index) => (
                  <>
                    <div
                      className={`billable_tab ${tab.isActive && "active"}`}
                      onClick={() => handleSignDetailsTabs(tab)}
                    >
                      {tab.name}
                    </div>
                  </>
                ))}
              </div>
              {/* {siteDetailtabs?.map(
                (tab, index) =>
                  tab.isActive && ( */}
              <div>
                <SignDetails
                  setSignDetail={setSignDetail}
                  signDetail={signDetail}
                  handleChange={handleChange}
                  isActiveTab={isActiveTab}
                  siteDetailtabs={siteDetailtabs}
                  handleUploadConfirmModal={handleUploadConfirmModal}
                  repeatInformation={repeatInformation}
                />
              </div>
              {/* )
              )} */}
              {/* 
              {console.log(siteDetailtabs[0], "siteDetailtabs[0]")}
              {console.log(isActiveTab, "isActiveTab[0]")}
              {console.log(siteDetailtabs, "siteDetailtabs[0]")} */}

              <Row>
                <Col md={12}>
                  {siteDetailtabs &&
                    siteDetailtabs[0]?.isActive &&
                    signDetail.length > 1 && (
                      <div className="billable_btnToggleContainer">
                        <BtnToggle
                          action={handleRepeatInformation}
                          // value={repeatInformation}
                          status={repeatInformation}
                          text="Repeat Information for all"
                          labelClass="btnSwitchtext01"
                        />{" "}
                      </div>
                    )}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      {uploadConfirmModal && (
        <>
          <ConfirmModal
            // setConfirmModal={setAddSignModal}
            //   confirm={handleAddSignsModal}
            title="Specific Location"
            isModalHeader={true}
            isFooterBtns={true}
            confirmText="Yes"
            cancelText="No"
            handleClose={() => handleUploadConfirmModal(false)}
            handleConfirm={() => handleConfirmModal()}
          >
            <>
              <div className="childs">
                <PlacesAutocomplete
                  value={loc}
                  onChange={(p) => {
                    setLoc(p);
                  }}
                  onSelect={(p) => {
                    console.log(p, "p");
                    setLoc(p);
                    // formik.setFieldValue("siteLocation.googleAddress", p);
                    geocodeByAddress(p)
                      .then((results) => getLatLng(results[0]))
                      .then(({ lat, lng }) => {
                        setLat(lat);
                        setLng(lng);
                        setCenter({ lat: lat, lng: lng });
                        // formik.setFieldValue("siteLocation.latitude", lat);
                        // formik.setFieldValue("siteLocation.longitude", lng);
                        console.log("Successfully got latitude and longitude", {
                          lat,
                          lng,
                        });
                      });
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: "Location",
                          className:
                            "inputField form-control location-search-input",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {/* {loading && <div>Loading...</div>} */}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#FAFAFA",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#FFFFFF",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <div className="d-flex justify-content-between w-100 my-4 fornSection2 mapBox">
                <AddMap
                  center={center}
                  lat={lat}
                  lng={lng}
                  getMyLocation={() => getMyLocation()}
                  // handleChange={formik.handleChange}
                  loc={loc}
                  onPlacesChanged={() => onPlacesChanged()}
                  onMarkerDragEnd={(coord) => onMarkerDragEnd(coord)}
                />
              </div>
            </>
          </ConfirmModal>
        </>
      )}
    </>
  );
};

export default CreateListingSignsDetails;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOptions, processFilterOptions } from '../filters-base';
import { getAllTransactions } from "../api/orders-store-api";

const initialState = {
    transactions:{ records:[]},
    tranHeading : ["ID", "DATE", "AMOUNT", "TRANSACTION TYPE", "ORDER ID"],
    tranAccessorName : [ "transactionId","transactionDate","amount","typeText","orderId",],
    page : 0,
    pageSize : 10,
    export : false,
    searchString : '',
}

export const getTransactionsAsync = createAsyncThunk('transactions/getTransactionsAsync',
    async(request) => {
    const response = await getAllTransactions(request);
    return response.data.data;
    }
)

export const getTransactionTypeOptionsAsync = createAsyncThunk('filters/getMediaOptionsAsync',
    async() => {
    const response = await getOptions('TransactionTypes');
    return processFilterOptions(response.data);
    }
)

export const getSortingOptionsAsync = createAsyncThunk('filters/getSortingOptionsAsync',
    async() => {
    const response = await getOptions('DatePriceSorting');
    return {options:processFilterOptions(response.data)};
    }
)

const transactionsSlice = createSlice({
    name:"transactions",
    initialState,
    reducers:{
        getTransactions: (state,payload)=>{
            state.transactions = payload;
        },
        filterCBChanged:(state,params)=>{  
            if(params.payload.type=="sorting"){
                state.sortingOptions = {...state.sortingOptions,options: state.sortingOptions.options.map(s=>s.id!=params.payload.id ? s : {...s,isChecked:!s.isChecked})};
            }else{
                state.checkBoxFilters =  state.checkBoxFilters.map(r=>{  
                    console.log(params.payload.type,r.title);                                       
                    if(r.title==params.payload.type){                         
                        r.options = r.options.map(s=>s.id!=params.payload.id ? s : {...s,isChecked:!s.isChecked});                    
                    }
                    return r;
                });            
            }
        },
        clearAllCB:(state,payload)=>{
            state.checkBoxFilters =  state.checkBoxFilters.map(r=>{                
                r.options = r.options.map(s=> ({...s, isChecked:false}));
                return r;
            });
            state.sortingOptions =  {...state.sortingOptions,options:state.sortingOptions.options.map(s=> ({...s, isChecked:false}))};
        }, 
    },
    extraReducers:{
        [getTransactionsAsync.pending] : () => {
            console.log("pending");
        },
        [getTransactionsAsync.fulfilled] : (state,{payload}) => {
            console.log("full filled",payload);
            return {...state, transactions : payload}
        },[getTransactionsAsync.rejected] : () => {
            console.log("rejected");
        }, 
    }
});
export const { fetchTransactions } = (state) => state.transactions.transactions; 
export default transactionsSlice.reducer;

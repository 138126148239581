import React, { useContext, useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { Collateral } from "../../../../../../../assets/svgComponents/inventorySVG";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import NavBar from "../../../../../../../components/navBar";
import ProjectContext from "../../../../../../../context/ProjectContext";
import {
  deleteSign,
  getSignDetails,
} from "../../../../../../../services/Provider/Configurator/Inventory";
import UpdatesModal from "../../../../../../../components/profile/Modal/UpdatesModal";
import { checkPermission } from "../../../../../../../utils";

export const ViewSignInfo = (props) => {
  const location = useLocation();
  const [selectedSign, setSelectedSign] = useState();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);
  const { id } = props.location.state;

  useEffect(async () => {
    await getSignDetails(id)
      .then((res) => {
        if (res.status === 200) {
          console.log("site Usage Data", res.data);
          setSelectedSign(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const history = useHistory();

  const handleOnDelete = () => {
    setDeleteConfirmModal(true);
  };
  const handleOnEditbtn = () => {
    history.push(`/provider/configurator/inventory/edit-sign`);
  };

  // useEffect(()=>{
  //   let signId=selectedSign.id
  //   getSignDetails(signId).then(res=>{
  //     console.log('res', res)
  //   })
  // },[location.pathname])

  const handleDeleteSignConfirm = () => {
    let signId = selectedSign?.id;
    deleteSign(signId)
      .then((res) => {
        if (res.status == 204) {
          //handleActiveTab("SIGNS")
          setDeleteConfirmModal(false);
          history.push(`/provider/configurator/inventory`);
        }
      })
      .catch((err) => console.log("err", err));
  };
  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };
  return (
    <>
      <NavBar
        blueBtnText="Edit Details"
        whiteBtnText="Delete Sign"
        notificationSVG={<NotificationSVG />}
        navHeading={selectedSign?.name}
        subHeading="Configurator • Inventory"
        subActiveHeading="Sign Details"
        handleBlueBtn={handleOnEditbtn}
        handleWhitebtn={handleOnDelete}
        handleNotificationSVG={handleNotificationSVG}
        showButton={checkPermission("Configurator", "Full")}
      />
      <div className="p-20 gridSection">
        <Card className="gridCard" aria-labelledby="gridCard">
          <Card.Body className="gridCardBody p-0">
            <div className="formSection">
              <p className="mt-4 mb-5 mx-4">Sign Information</p>
              <div className="d-flex justify-content-between w-100 ps-4">
                <span className="w-25">
                  <h6>Id</h6>
                  <p>{selectedSign?.id.substring(0, 5)}</p>
                </span>
                <span className="w-25">
                  <h6>Name</h6>
                  <p>{selectedSign?.name}</p>
                </span>                
                <span className="w-25">
                  <h6>Media Type</h6>
                  <p>{selectedSign?.mediaTypeText}</p>
                </span>
                <span className="w-25">
                  <h6>Radius</h6>
                  <p>{selectedSign?.radius}</p>
                </span>
              </div>
              <div className="d-flex justify-content-between w-75 ps-4 pt-5 pb-1">
                <span className="w-25">
                  <h6>UNIT OF MEASUREMENT</h6>
                  <p>{selectedSign?.dimensionText}</p>
                </span>
                <span className="w-25">
                  <h6>Size</h6>
                  <p>{selectedSign?.sizeText}</p>
                </span>
                <span className="w-25">
                  <h6>WIDTH</h6>
                  <p>{selectedSign?.width}</p>
                </span>
                <span className="w-25">
                  <h6>HEIGHT</h6>
                  <p>{selectedSign?.height}</p>
                </span>
                <span className="w-25">
                  <h6>TAXATION STATE</h6>
                  <p>{selectedSign?.stateName}</p>
                </span>
              </div>

              <div className="mt-5 mx-4">
                <h6>IMAGE</h6>
                <div className="imageCardContainer">
                                  <div
                                    className="imageCard"
                                    style={{ height: "70px" }}
                                  >
                                    <a
                                      download="image.jpg"
                                      href={`https://gopinit.blob.core.windows.net/image-container/${selectedSign && selectedSign.image
                                    }`}
                                      target="_blank"
                                    >
                                      <img
                                        src={`https://gopinit.blob.core.windows.net/image-container/${selectedSign && selectedSign.image
                                      }`}
                                        alt="img"
                                      />
                                    </a>
                                  </div>
                            
                        </div>
              </div>
              <div className="mt-5 mx-4 w-75">
                <h6>DESCRIPTION</h6>
                <p style={{ fontSize: "13px" }}>{selectedSign?.description}</p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {deleteConfirmModal && (
        <ConfirmModal
          // setConfirmModal={setAddSignModal}
          //   confirm={handleAddSignsModal}
          title={selectedSign?.name}
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          cancelText="No"
          handleClose={() => setDeleteConfirmModal(false)}
          handleConfirm={handleDeleteSignConfirm}
        >
          <div className="text-center">
            <p className="py-4 newListingCreated_description">
              Would you like to delete this sign?
            </p>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

import React from "react";
// import "./BtnSwitch.scss";

export function BtnSwitch({
  sizeLg,
  disable,
  status,
  text,
  labelClass,
  action,
}) {
  // const action=(e)=>{
  //   console.log(e.target.checked,"khane");
  // }
  return (
    <div className="ms-2">
      <label className={sizeLg ? "btnSwitch xxl" : "btnSwitch m-0"}>
      <strong className="btnSwitchText sizeXl text-dark mt-1 p-0 pe-2">
          {text}
        </strong>
        <input
          type="checkbox"
          checked={status}
          disabled={disable}
          onChange={(e) => action(e)}
        />
        <span className="btnSwitchCheck"></span>
        
      </label>
    </div>
  );
}

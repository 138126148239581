import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import NavBar from "../../../../components/navBar";
import { NotificationSVG } from "../../../../assets/svgComponents/table";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getOrderAsync,
  orderPaymentAsync,
} from "../../../../services/reducers/orders-reducers";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import PaymentModes from "./SitesPayment/PaymentModes";
import { isMobile } from "react-device-detect";
import { To2Decimal } from "../../../../services/utility";

export const PayOrderInstallment = ({ logout }) => {
  const history = useHistory();
  const [openNav, setOpenNav] = useState(false);
  const { orderId } = useParams();
  const { order,paymentResult } = useSelector(
    (state) => state.orders
  );
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { paymentCardId } = useSelector((state) => state.listing);

    

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderAsync(orderId));
    }
  }, [dispatch]);

  const handlePayNow = async () => {
    const reqBody = {
      orderId: orderId,
      paymentTermId: order.payablePaymentTerm.paymentTermId,
      amount: order.payablePaymentTerm.paymentTermAmount,
      paymentCardId: paymentCardId,
    };
    console.log(" payment order req", reqBody);
    //addToast("please wait processing payment...", { appearance: "success" });
    await dispatch(orderPaymentAsync(reqBody));
  };

  useEffect(async () => {
    if (paymentResult) {
      console.log(paymentResult);
      addToast("Payment Done!",{ appearance: "success"});  
      await dispatch(getOrderAsync(orderId));
      history.push("/customer/orders");   
    }
  }, [paymentResult]);

  return (
    <>
      <NavBar
        isInputField={false}
        notificationSVG={<NotificationSVG />}
        navHeading="Order Payment"
        subHeading=""
        showButton={false}
      />
      <div className="home-container gridSection g-0">
        {order && (
          <>
            <PaymentModes />
            <div className="site_booking_card_heading py-2">Payment Terms</div>
            {order &&
              order.paymentTerms &&
              order.paymentTerms.map((pterm, index) => (
                <div key={"pterm_" + index}>
                  {pterm.installmentNo > 0 && (
                    <div className="site_booking_card_heading py-2">
                      Installment No. {pterm.installmentNo}
                    </div>
                  )}
                  <h5 className="site_booking_card_heading05">
                    <Row className="py-1">
                      <Col>Period</Col>
                      <Col className="d-flex justify-content-end">
                        {pterm.period}
                      </Col>
                    </Row>
                  </h5>
                  <h5 className="site_booking_card_heading05">
                    <Row className="py-1">
                      <Col>Amount</Col>
                      <Col className="d-flex justify-content-end">
                        ${To2Decimal(pterm.amount)}
                      </Col>
                    </Row>
                  </h5>
                  {pterm.isDownpayment && (
                    <h5 className="site_booking_card_heading05">
                      <Row className="py-1">
                        <Col>Downpayment</Col>
                        <Col className="d-flex justify-content-end text-danger"></Col>
                      </Row>
                    </h5>
                  )}
                </div>
              ))}

            {order.payablePaymentTerm && (
              <>
                <hr />
                <div className="btn_blue_container d-flex align-items-end flex-column">
                  <button className="btn_blue " onClick={() => handlePayNow()}>
                    Pay Now
                  </button>
                </div>
              </>
            )}

            {!order.payablePaymentTerm && (
              <>
                <hr />
                <div className="btn_blue_container d-flex align-items-center flex-column">
                  No pending payment!
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { FloatingLabel, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import { saveAccessType } from "../../../../../services/Provider/UserManagement/UserManagement";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { useEffect } from "react";

export const ActionType = ({
  show,
  statuslable,
  sortByLable,
  medialable,
  handleApplyBtn,
  transactionLable,
  setShowFilterModal,
  accessTypeLable,
  accessTypeAdded,
  setAccessTypeAdded,
}) => {
  const [invitesSentModal, setInvitesSentModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { addToast } = useToasts();
  const [viewEditOptions, setViewEditOptions] = useState(false);
  const [viewOptions, setViewOptions] = useState(false);
  // const Modules = [ "OrderAndTransaction", "SupportRequest", "UserManagement"]
  const Modules = [
    "Configurator",
    "Listings",
    "OfferManagement",
    "SupportRequests",
    "OrdersAndTransactions",
    "ReviewsAndRatings",
    "UserManagement",
  ];
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Access Type Name is required."),
  });
  const [moduleAccess, setModuleAccess] = useState([]);
  const setModules = (moduleName, accessType) => {
    const ma = [...moduleAccess].filter((m) => m.claimType !== moduleName);
    ma.push({ claimType: moduleName, claimValue: accessType });
    setModuleAccess(ma);
  };
  useEffect(() => {
    console.log(moduleAccess, "AccessTypes");
  }, [moduleAccess]);
  return (
    <Modal
      show={show}
      onHide={() => setShowFilterModal(false)}
      dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
    >
      <Modal.Header closeButton>
        <h5 className="modaltitle">Access Type</h5>{" "}
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name: "",
          }}
          validationSchema={ValidationSchema}
          onSubmit={async (values) => {
            values.claims = moduleAccess;
            await saveAccessType(values)
              .then((res) => {
                if (res.status === 200) {
                  setLoader(false);
                  setShowFilterModal(false);
                  addToast("Successfully Saved.", { appearance: "success" });
                } else if (res.status === 201) {
                  setLoader(false);
                  setShowFilterModal(false);
                  addToast("Successfully Saved.", { appearance: "success" });
                }
                setAccessTypeAdded(!accessTypeAdded);
              })
              .catch((err) => console.log(err));
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FloatingLabel
                controlId="floatingInput"
                label={"Name"}
                className={`floatingLabel mt-3`}
              >
                <Field
                  name={`name`}
                  label="Name"
                  placeholder="Name"
                  htmlFor={`name`}
                  className="inputField form-control"
                />
                <div className="text-danger">
                  <ErrorMessage name={`name`} />
                </div>
              </FloatingLabel>
              <div>
                {Modules.map((moa, i) => {
                  return (
                    <div key={i} className="row mt-3">
                      <div className="col-md-6">
                        <p>{moa}</p>
                      </div>
                      <div className="col-md-6">
                        <div className="radio-before">
                          <label className="radio radio-gradient d-flex align-items-center mr-3">
                            <span className="radio__input">
                              <input
                                type="radio"
                                onClick={() => setModules(moa, "Full")}
                                name={moa}
                              />
                              <span className="radio__control"></span>
                            </span>
                            <span className="radio__label" for="radio1">
                              Full
                            </span>
                          </label>
                        </div>
                        <div className="radio-before mt-2">
                          <label className="radio radio-gradient d-flex align-items-center mr-3">
                            <span className="radio__input">
                              <input
                                type="radio"
                                onClick={() => setModules(moa, "Read")}
                                name={moa}
                              />
                              <span className="radio__control"></span>
                            </span>
                            <span className="radio__label" for="radio1">
                              Read
                            </span>
                          </label>
                        </div>
                        <div className="radio-before mt-2">
                          <label className="radio radio-gradient d-flex align-items-center mr-3">
                            <span className="radio__input">
                              <input
                                type="radio"
                                onClick={() => setModules(moa, "None")}
                                name={moa}
                              />
                              <span className="radio__control"></span>
                            </span>
                            <span className="radio__label" for="radio1">
                              None
                            </span>
                          </label>
                        </div>
                      <hr style={{height: "1px"}}/>
                      </div>
                    </div>
                  );
                })}
              </div>
              <button
                className="blueBgButton applyFilterBtn py-3 px-5"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        {/* <div className="applyFilter">
          <button
            className="blueBgButton applyFilterBtn py-3 px-5"
            onClick={handleApplyBtn}
          >
            Apply
          </button>
        </div> */}
      </Modal.Footer>
      {/* new listing created modal */}
      {invitesSentModal && (
        <ConfirmModal
          // setConfirmModal={setAddSignModal}
          //   confirm={handleAddSignsModal}
          title="Access Type Saved"
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Close"
          handleClose={() => handleApplyBtn()}
          handleConfirm={() => {
            history.handleApplyBtn();
          }}
        >
          <div className="">
            <p className="py-3 ps-3 newListingCreated_description text-left">
              Access type is saved.
            </p>
          </div>
        </ConfirmModal>
      )}
    </Modal>
  );
};

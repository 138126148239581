import React, {useEffect} from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { NotificationSVG } from "../../../../../assets/svgComponents/table";
import NavBar from "../../../../../components/navBar";
import BillingAddress from "./BillingAddress";
import BookingDetails from "./BookingDetails";
import "./SitesBooking.scss";
import { getBookingDetails,getDefaultAddressAsync,getProfileAsync,getCustomerTotalOrderDetailsAsync,prepareOrder,applyOffer,setOrderAmount,submitOrderAsync } from "../../../../../services/reducers/listing-reducer";
import {useDispatch, useSelector} from "react-redux";
import PaymentDetails from "./PaymentDetails";
import { useToasts } from "react-toast-notifications";

const SitesBooking = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bookingDetails = useSelector((state)=> getBookingDetails(state.listing)); 
  const { applyOfferResult, listingItem, billingAddress, profile, order,orderId,orderSubmitResult,orderSubmitError,subscriptionTermId,paymentCardId,orderAmount,paymentTermId} = useSelector((state)=> state.listing);
  const { addToast } = useToasts();

  useEffect(()=>{
    if(applyOfferResult){
      addToast(applyOfferResult,{ appearance: "error"});
    }
  },[applyOfferResult])

    useEffect(async () => {  
    dispatch(prepareOrder()); 
    }, [dispatch,billingAddress]);

  useEffect(async () => { 
    await dispatch(getCustomerTotalOrderDetailsAsync());
    await dispatch(getDefaultAddressAsync());
    await dispatch(getProfileAsync());
  }, [dispatch]);

  useEffect(async () => {
    dispatch(prepareOrder()); 
  }, [dispatch]);

  const handelApplyOffer = (offer) => {
    dispatch(applyOffer(offer));
  }
  
  const handleProceedToPay = async() => {
      await dispatch(prepareOrder(order)); 
      await dispatch(setOrderAmount(bookingDetails.totalAmount));
      await dispatch(submitOrderAsync(order));
  };



useEffect(async () => {
  if(orderSubmitError){
    console.log('order submit error:', orderSubmitError);
    if(orderSubmitError[0].includes("Address") || orderSubmitError[0].includes("Pincode") || orderSubmitError[0].includes("City") || orderSubmitError[0].includes("State") || orderSubmitError[0].includes("Country") ){
      addToast("Billing Address is required",{ appearance: "error"});
    } else {
      addToast(orderSubmitError[0],{ appearance: "error"});
    }
  }        
  }, [orderSubmitError]);
      
  useEffect(async () => {
    if(orderSubmitResult){
      console.log(orderSubmitResult);
      addToast(orderSubmitResult.message,{ appearance: "success"}); 
      history.push("/sites-payment");           
    }        
  }, [orderSubmitResult]);
 


const handleSubmitOrder = async() => {
  console.log('submitting order',order);
  await dispatch(setOrderAmount(bookingDetails.totalAmount));
  dispatch(submitOrderAsync(order));
};


  return (
    <>
      <NavBar
        navHeading="Sites Booking"
        subHeading="Home • Sites • Bayward Market Square Area  "
        subActiveHeading="Booking Details"
        isInputField={false}
        notificationSVG={<NotificationSVG />}
        showButton={true}
        />
      <div className="p-20 gridSection">
      <Row className="h-100">
        <Col md={5} className="overflow-auto h-100 flex-grow-1 pe-0" style={{ height: "100vh" }}>
        {
              bookingDetails && (
                <div className="card_booking_details px-3 py-4 h-100">
                  <BookingDetails data={bookingDetails}/>
                  <div className="btn_sunrise_container d-flex align-items-end">
                    <button className="btn_sunrise" onClick={()=>{
                      if(listingItem.type==="Site")
                        history.push("/customer/site-detail");
                      else
                        history.push("/customer/sign-detail");
                      }}>Change Selection</button>
                  </div>
                </div>
              )
        }
        </Col>
        <Col md={7} className="overflow-auto h-100 flex-grow-1 pe-0">
          <div
            className="card_booking_details px-3 h-100 ">
            <BillingAddress address={billingAddress} profile={profile}/>
            <PaymentDetails bookingDetails={bookingDetails} applyOffer={(offer)=>handelApplyOffer(offer)}/>
            <div className="btn_blue_container d-flex align-items-end justify-content-end">
              <button className="btn_blue" onClick={() => handleProceedToPay()}>
                Proceed to Pay
              </button>
            </div>
          </div>
        </Col>
      </Row>
      </div>
    </>
  );
};
export default SitesBooking;

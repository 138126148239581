import React from "react"

export const CaretDownSVG = () => {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.1001 4.41013C4.01054 4.52996 3.83111 4.52996 3.74155 4.41013L0.795145 0.468307C0.684643 0.320472 0.790001 0.109756 0.974421 0.109756L6.86722 0.109755C7.05164 0.109755 7.157 0.320472 7.0465 0.468307L4.1001 4.41013Z"
        fill="#808080"
      />
    </svg>
  );
};
export const CaretDownSVG02 = () => {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0831683 0.326834C0.197671 0.198527 0.387573 0.193967 0.507326 0.316648L3.96486 3.85873C4.25894 4.16 4.74106 4.16 5.03514 3.85873L8.49267 0.316648C8.61243 0.193967 8.80233 0.198527 8.91683 0.326834C9.03133 0.455141 9.02708 0.658607 8.90732 0.781288L5.44979 4.32337C4.92376 4.86226 4.07624 4.86226 3.55021 4.32337L0.0926751 0.781288C-0.027078 0.658607 -0.0313343 0.455141 0.0831683 0.326834Z"
        fill="#808080"
      />
    </svg>
  );
};
export const CaretUpSVG02 = () => {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.103374 5.89247C-0.034458 5.7491 -0.034458 5.51665 0.103374 5.37328L4.75217 0.537643C5.44133 -0.179215 6.55868 -0.179214 7.24784 0.537644L11.8966 5.37328C12.0345 5.51665 12.0345 5.7491 11.8966 5.89247C11.7588 6.03584 11.5353 6.03584 11.3975 5.89247L6.7487 1.05684C6.33521 0.626724 5.6648 0.626723 5.2513 1.05684L0.602508 5.89247C0.464676 6.03584 0.241206 6.03584 0.103374 5.89247Z"
        fill="#808080"
      />
    </svg>
  );
};
export const CaretUpSVG = () => {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.74145 0.589865C3.83102 0.470045 4.01044 0.470045 4.10001 0.589865L7.0464 4.53169C7.15691 4.67953 7.05155 4.89024 6.86713 4.89024H0.97433C0.789911 4.89024 0.684553 4.67953 0.795055 4.53169L3.74145 0.589865Z"
        fill="#808080"
      />
    </svg>
  );
};
export const NotificationSVG = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.28932 9.00773L2.69055 5.07568C2.92676 2.76075 4.87632 1 7.20328 1C9.53023 1 11.4798 2.76075 11.716 5.07568L12.1172 9.00773C12.1989 9.80774 12.5441 10.5579 13.0987 11.1402C13.7661 11.841 13.2694 13 12.3017 13H8.60328H5.80328H2.10486C1.13715 13 0.64043 11.841 1.30781 11.1402C1.86241 10.5579 2.20768 9.80774 2.28932 9.00773ZM5.20328 14H2.10486C0.257952 14 -0.69005 11.788 0.583676 10.4506C0.985316 10.0289 1.23536 9.48559 1.29448 8.90622L1.69571 4.97417C1.984 2.14891 4.36335 0 7.20328 0C10.0432 0 12.4226 2.14891 12.7108 4.97417L13.1121 8.90622C13.1712 9.48559 13.4212 10.0289 13.8229 10.4506C15.0966 11.788 14.1486 14 12.3017 14H9.20328C9.20328 15.1046 8.30785 16 7.20328 16C6.09871 16 5.20328 15.1046 5.20328 14ZM6.20328 14C6.20328 14.5523 6.65099 15 7.20328 15C7.75556 15 8.20328 14.5523 8.20328 14H6.20328Z"
        fill="black"
      />
    </svg>
  );
};
export const PlusSVG = ({ fill, height, width }) => {
  return (
    <svg
      width={width ? width : "16"}
      height={height ? height : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50473 15.2811C7.50481 15.5569 7.72876 15.7806 8.00492 15.7807C8.28109 15.7808 8.50489 15.5572 8.50481 15.2814L8.50269 8.50195L15.2956 8.49888C15.5718 8.49876 15.7958 8.27507 15.7959 7.99925C15.796 7.72344 15.5722 7.49995 15.2961 7.50008L8.50238 7.50315L8.50026 0.712277C8.50017 0.436465 8.27623 0.212805 8.00006 0.212719C7.7239 0.212633 7.50009 0.436153 7.50018 0.711966L7.5023 7.5036L0.708343 7.50667C0.432179 7.50679 0.208202 7.73049 0.208077 8.0063C0.207952 8.28211 0.431726 8.5056 0.70789 8.50547L7.50261 8.5024L7.50473 15.2811Z"
        fill={fill ? fill : "white"}
      />
    </svg>
  );
};

export const ExportCSVSVG = ({ fill }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.34089 13.6501C0.216448 10.5296 0.216448 5.47033 3.34089 2.34987C6.46533 -0.770594 11.5311 -0.770594 14.6555 2.34987C17.7799 5.47033 17.7799 10.5296 14.6555 13.6501C11.5311 16.7705 6.46533 16.7705 3.34089 13.6501ZM4.17455 11.8518L8.92105 7.11131C9.09903 6.93355 9.03915 6.78877 8.78564 6.78959L7.02576 6.79318C6.11282 6.79448 6.11478 5.42651 7.02772 5.4252L10.9304 5.37701C11.3111 5.37137 11.6141 5.67328 11.6102 6.05129L11.5762 9.96791C11.5749 10.8797 10.2054 10.8818 10.2065 9.96987L10.2102 8.21234C10.211 7.95923 10.0668 7.89865 9.88812 8.07715L5.14163 12.8176C4.87463 13.0843 4.90313 13.4842 5.20736 13.7089C5.20736 13.7089 5.02765 13.5903 5.22126 13.7182C7.88342 15.4761 11.5029 15.1843 13.8473 12.8429C16.5254 10.1682 16.5254 5.83171 13.8473 3.15702C11.1692 0.482343 6.82717 0.482343 4.14908 3.15702C1.80208 5.50103 1.51192 9.12137 3.27859 11.781C3.405 11.9713 3.28158 11.7855 3.28158 11.7855C3.50239 12.0915 3.90661 12.1194 4.17455 11.8518Z"
        fill={fill ? fill : "#F99300"}
      />
    </svg>
  );
};
export const UnionSVG = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1H6C6.55228 1 7 1.44772 7 2V2.49933L7 2.50002L7 2.50072V3C7 3.55228 6.55228 4 6 4H5C4.44772 4 4 3.55228 4 3V2.50231L4.00001 2.5L4 2.49769V2C4 1.44772 4.44772 1 5 1ZM8 2V2.00002H15.5C15.7761 2.00002 16 2.22388 16 2.50002C16 2.77617 15.7761 3.00002 15.5 3.00002H8C7.99999 4.10458 7.10456 5 6 5H5C3.89543 5 3 4.10457 3 3H0.500006C0.223863 3 6.31809e-06 2.77614 6.31809e-06 2.5C5.36442e-06 2.22386 0.223863 2 0.500005 2H3C3 0.895431 3.89543 0 5 0H6C7.10457 0 8 0.89543 8 2ZM13 10C13 11.1046 12.1046 12 11 12H10C8.89544 12 8.00001 11.1046 8 10H0.5C0.223858 10 0 9.77617 0 9.50002C0 9.22388 0.223858 9.00002 0.5 9.00002H8V9C8 7.89543 8.89543 7 10 7H11C12.1046 7 13 7.89543 13 9H15.5C15.7761 9 16 9.22386 16 9.5C16 9.77614 15.7761 10 15.5 10H13ZM9 9.50002V10C9 10.5523 9.44772 11 10 11H11C11.5523 11 12 10.5523 12 10V9.50242C12 9.50161 12 9.50081 12 9.5C12 9.49919 12 9.49839 12 9.49758V9C12 8.44771 11.5523 8 11 8H10C9.44771 8 9 8.44772 9 9V9.50002Z"
        fill="#201C1D"
      />
    </svg>
  );
};

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ListGroup } from "react-bootstrap";
import { MapMarkerSVG2 } from "../../../../../../../assets/svgComponents/inventorySVG";
import { CustomListGroup } from "../../../../../../../components/ListGroup";
import {
  getListingsForSite,
  getSimilarSite,
} from "../../../../../../../services/Provider/Configurator/Inventory";
import { GridTable } from "./GridTable";
import { MiniCard } from "./MiniCard";
import { AvailableCharge } from "./tempDataForInfoPage";
import { ItemNotAvailable } from "./ItemNotAvailable";
export const ListingPage = ({ siteDetails }) => {
  const gridHeadingPrice = ["MIN. QUANTITY", "MAX. QUANTITY", "PRICING"];
  const gridHeadingCharge = ["ID", "NAME", "SERVICE", "PRICING"];
  const sitesBody = ["0-1", "$400"];
  const sitesBody1 = ["#SV1", "Installation", "Add-on", "$100"];
  const [ListingDetails, setListingDetails] = useState();
  const [similarSites, setSimilarSites] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(async () => {
    await getListingsForSite(siteDetails?.id)
      .then((res) => {
        if (res.status === 200) {
          console.log("listing data", res.data);
          setListingDetails(res.data);
        }
      })
      .catch((err) => {
        console.log(err)
      });
  }, []);

  const handleViewSimilarSite= (siteId) =>{
    history.push({ pathname: `/provider/configurator/inventory/view-site`, state: { id: siteId } });
  }

  useEffect(async () => {
    await getSimilarSite(siteDetails?.id)
      .then((res) => {
        if (res.status === 200) {
          console.log("similar site data", res.data);
          setSimilarSites(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="informationMainContainer">
      <div className="informationContainer bg-base-100 h-100 flex-grow-1 overflow-auto">
        <h6>{ListingDetails?.listingName}</h6>

        {/* <div>
          <div className="informaton_Section">
            <div className="lable1 id">
              <p className="mb-0">ITEM TYPE</p>
              <span>Site</span>
            </div>
            <div className="lable2 location">
              <p className="mb-0">AVAILABLE FOR</p>
              <span>Sale</span>
            </div>
          </div>
          <div className="informaton_Section">
            <div className="lable1 id">
              <p className="mb-0">PRICING TYPE</p>
              <span>Fixed</span>
            </div>
            <div className="lable2 location">
              <p className="mb-0">APPLICABLE DURING</p>
              <span>All Time</span>
            </div>
            <div className="lable3 location">
              <p className="mb-0">PRICE</p>
              <span>$20,0000</span>
            </div>
          </div>
        </div> */}

        {/* when data is available */}

        <div className="bookingPeriodContainer">
          <div className="informaton_Section">
            <p className="mb-0">MINIMUM BOOKING PERIOD</p>
            <span>
              {ListingDetails?.minBookingPeriod}{" "}
              {ListingDetails?.periodTypeText}
            </span>
          </div>
        </div>
        <div className="pricingContainer" style={{marginTop:0}}>
          <h6>Pricing</h6>
          <div className="informaton_Section">
            <div className="lable1 id" style={{width:'30%'}}>
              <p className="mb-0">PRICING TYPE</p>
              <span>{ListingDetails?.priceDetail?.priceTypeText}</span>
            </div>
            <div className="lable2 location">
              <p className="mb-0">APPLICABLE DURING</p>
              <span>{ListingDetails?.priceDetail?.applicabilityText}</span>
            </div>
            {ListingDetails?.priceDetail?.priceTypeText == "Fixed" ? (
              <div className="lable2 location">
                <p className="mb-0">Amount</p>
                <span>{ListingDetails?.priceTiers[0]?.amount}</span>
              </div>
            ) : (
              <div className="tableMainsection mt-4">
                <table className="tableContainer">
                  <thead className="tableHead">
                    <tr>
                      {gridHeadingPrice &&
                        gridHeadingPrice.map((item) => {
                          return (
                            <td className="bookAllFavoritesModaltableheading">
                              {item}
                            </td>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody className="tableBody">
                    <tr>
                      {ListingDetails?.priceTiers &&
                        ListingDetails?.priceTiers.map((item) => {
                          return (
                            <>
                              <td>{item.minimumQuantity}</td>
                              <td>{item.maximumQuantity}</td>
                              <td>{item.amount}</td>
                            </>
                          );
                        })}
                    </tr>
                  </tbody>
                </table>
  
              </div>
            )}
          </div>
        </div>
        <div>
          <h6>Summary of Charges</h6>
          <div className="informaton_Section">
          <div className="tableMainsection mt-4">
                <table className="tableContainer">
                  <thead className="tableHead">
                    <tr>
                      {gridHeadingCharge &&
                        gridHeadingCharge.map((item) => {
                          return (
                            <td className="bookAllFavoritesModaltableheading">
                              {item}
                            </td>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody className="tableBody">
                      {ListingDetails?.summaryChargesItems && ListingDetails?.summaryChargesItems?.chargeId?(
                        ListingDetails?.summaryChargesItems.map((item) => {
                          return (
                            <tr>
                              <td>{item.charge?.id}</td>
                                <td>{item.charge?.name}</td>
                                <td>{item.chargesType}</td>
                                <td>{item.priceValue}</td>
                            </tr>
                          );
                        })):(
                          ListingDetails?.summaryChargesItems.map((item) => {
                            return (
                              <tr>
                                <td>{item.service?.id}</td>
                                <td>{item.service?.name}</td>
                                <td>{item.chargesType}</td>
                                <td>{item.priceValue}</td>
                              </tr>
                            );
                          })
                        )}
                  </tbody>
                </table>
  
              </div>
          </div>
        </div>
        <div>
          <h6>Subscription</h6>
          <div className="informaton_Section">
            <div className="lable1 id">
              <p className="mb-0">TERM</p>
              <span>{ListingDetails?.subscriptionTerms?.term}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="informatinListContainer h-100 flex-grow-1 overflow-auto borderAll rounded-10">
        <p>Other locations following similar billable item</p>
        <div className="availbleSignContainer dashboard-rside p-0">
          {similarSites && similarSites?.records?.length > 0 ? (
            <ListGroup as="ol">
              {similarSites.records.map((c) => (
                <ListGroup.Item
                  key={c.id}
                  as="li"
                  className="d-flex justify-content-between align-items-center border-0 border-bottom"
                  variant="light"
                >
                  <CustomListGroup
                    id={c.id}
                    heading={c.name}
                    subheading={c.description}
                    image={c.image}
                    handleViewBTN={()=>handleViewSimilarSite(c.id)} link={true}
                  />
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <ItemNotAvailable title="No similar sites available"/>
          )}
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import mapStyles from "./MapStyles";
import markerImg from "../../assets/images/markerImg.png";
import bookedImg from "../../assets/images/bookedImg.png";
import idleImg from "../../assets/images/idleImg.png";
import {
  faPlane,
  faCalendarCheck,
  faBusSimple,
  faMapMarker,
  faBuilding,
  faShoppingCart,
  faPersonBiking,
  faSignsPost,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import { MapProviderDashboardMapCard } from "./MapProviderDashboardMapCard";
import { Col, Row } from "react-bootstrap";

let libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "449px",
  borderLeft: "1px solid #efefef",
};
const center = {
  lat: 26.830128,
  lng: 80.9221482,
};

const options = {
  styles: mapStyles,
};

export const SitesMap = ({ page, markers, handleDetailpage }) => {
  // console.log(process.env.NODE_ENV, "process.env.REACT_APP_GOOGLE_MAP_API_KEY");
  const [mapPosition, setMapPosition] = useState({
    lat: 26.830128,
    lng: 80.9221482,
  });
  const [selectedElement, setSelectedElement] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);
  const [activeMarker, setActiveMarker] = useState(true);

  useEffect(() => {
    if (markers && markers.length > 0) {
      setMapPosition({ lat: markers[0].lat, lng: markers[0].lng });
    }
  }, [markers]);

  const [map, setMap] = React.useState(null);
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAKiVYj0Ytig8vYJS4ViTya12yJltyVo54",
    // libraries,
  });

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const siteIcon = {
    allSite: {
      path: faPlane.icon[4],
      fillColor: "#0000ff",
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: "#ffffff",
      scale: 0.04,
    },
    // idleSite: {
    //   path: idleImg,
    //   fillColor: "#0000ff",
    //   fillOpacity: 1,
    //   strokeWeight: 1,
    //   strokeColor: "#ffffff",
    //   scale: 0.04,
    // },
    // BookedSite: {
    //   path: bookedImg,
    //   fillColor: "#f99300",
    //   fillOpacity: 1,
    //   strokeWeight: 1,
    //   strokeColor: "#ffffff",
    //   scale: 0.04,
    // },
    idleSite: idleImg,
    BookedSite: bookedImg,
    marker: markerImg,
  };

  if (loadError) return "Error Loading Maps";
  if (!isLoaded) return "Loading Maps";
  return (
    <>
    <Col className="home-right overflow-auto flex-grow-1" style={{borderTop: "1px solid grey",borderLeft: "1px solid grey",borderRight: "1px solid grey",textAlign: "center",marginTop: "10px",padding: "10px"}}>
                <div className="mapMarkerInfo">
                  Locations : 
                  {/* <img src={allSite}>All site</img> */}
                  <img src={idleImg} style={{margin:"10px"}}/>Idle location
                  <img src={bookedImg} style={{margin:"10px"}}/>Booked location
                </div>
                </Col>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={mapPosition}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          streetViewControl: false,
          mapTypeControl: true,
        }}
      >
        <>
          {markers?.map((site, index) =>
            site.lat && site.lng ? (
              <Marker
                key={index}
                position={{ lat: site.lat, lng: site.lng }}
                onClick={(marker) => {
                  setSelectedElement(index);
                  setActiveMarker(site);
                }}
                icon={(() => {
                  // if (!index) {
                  // console.log(site.status,"site");
                  // return siteIcon.marker;
                  if (site.status === "allSite") {
                    return siteIcon.allSite;
                  } else if (site.status === "Idle") {
                    return siteIcon.idleSite;
                  } else if (site.status === "Booked") {
                    return siteIcon.BookedSite;
                  } else {
                    return siteIcon.marker;
                  }
                })()}
              >
                
                {selectedElement == index ? (
                  <InfoWindow
                    key={index}
                    visible={showInfoWindow}
                    marker={activeMarker}
                    onCloseClick={() => {
                      setSelectedElement(null);
                    }}
                  >
                    <MapProviderDashboardMapCard
                      page={page}
                      cardDetails={site}
                    />
                  </InfoWindow>
                ) : null}
              </Marker>
            ) : null
          )}
        </>
      </GoogleMap>
    </>
  );
};

import React, { useEffect } from "react";
import { Col, Row, Card, InputGroup } from "react-bootstrap";
import {
  SVGAdd,
  SVGStarRating,
  SVGFavorite,
  SVGNotFavorite,
  SVGFavoriteGray,
  SVGFeatured,
  SVGMedia,
} from "../../../../assets/svgComponents/svg";
import { getImageUrl } from "../../../../services/utility";
import { useDispatch, useSelector } from "react-redux";
import {
  markAsFavoriteAsync,
  markAsUnFavoriteAsync,
} from "../../../../services/reducers/listing-reducer";
import { unfavoriteAsync } from "../../../../services/reducers/customer-reducer";
import { useToasts } from "react-toast-notifications";

export const ListingItemCard = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const markAsFavorite = (props) => {
    const reqBody = {
      siteId: null,
      signId: null,
      listingItemId: null,
      providerId: null,
      type: props.type,
      title: props.title,
    };

    if (props.type === "provider") {
      reqBody.providerId = props.id;
    } else {
      reqBody.listingItemId = props.itemId;
    }

    if (props.isFavorite) {
      if (props.cartType === "favorite") {
        dispatch(unfavoriteAsync(reqBody));
        addToast(
          `${props.type} ${props.title} marked as unfavourite successfully`,
          {
            appearance: "success",
          }
        );
      } else {
        dispatch(markAsUnFavoriteAsync(reqBody));
        addToast(
          `${props.type} ${props.title} marked as unfavourite successfully`,
          {
            appearance: "success",
          }
        );
      }
    } else {
      dispatch(markAsFavoriteAsync(reqBody));
    }
  };
  return (
    <div className="mainCard">
      <Card>
        <Row className="g-0">
          <Col
            md={3}
            onClick={() => {
              if (props.type === "provider") {
                props.handleListing(
                  props.itemId,
                  props.listingId,
                  props.id,
                  props.title
                );
              } else {
                props.handleDetailpage(
                  props.itemId,
                  props.listingId,
                  props.id,
                  props.type,
                  props.providerId
                );
              }
            }}
          >
            {props.isFeatured ? (
              <div className="featuredImg">
                <div className="featured">
                  <SVGFeatured />
                </div>
                <img
                  src={getImageUrl(props.image)}
                  alt=""
                  className="homeCardImage"
                />
              </div>
            ) : (
              <div className="featuredImg">
                <img
                  src={getImageUrl(props.image)}
                  alt=""
                  className="homeCardImage"
                />
              </div>
            )}
          </Col>
          <Col md={9}>
            <Card.Body>
              <Row>
                <Col
                  md={11}
                  lg={11}
                  onClick={() => {
                    if (props.type === "provider") {
                      props.handleListing(
                        props.itemId,
                        props.listingId,
                        props.id,
                        props.title
                      );
                    } else {
                      props.handleDetailpage(
                        props.itemId,
                        props.listingId,
                        props.id,
                        props.type,
                        props.providerId
                      );
                    }
                  }}
                >
                  <Card.Title>{props.title}</Card.Title>
                </Col>
                {props.type !== "provider" && (
                  <Col
                    md={1}
                    lg={1}
                    className="text-end"
                    onClick={() => markAsFavorite(props)}
                  >
                    {props.isFavorite ? <SVGFavorite /> : <SVGNotFavorite />}
                  </Col>
                )}
              </Row>
              <div
                className="card-text fw-bold"
                style={{ color: "#808080" }}
                onClick={() => {
                  if (props.type === "provider") {
                    props.handleListing(
                      props.itemId,
                      props.listingId,
                      props.id,
                      props.title
                    );
                  } else {
                    props.handleDetailpage(
                      props.itemId,
                      props.listingId,
                      props.id,
                      props.type,
                      props.providerId
                    );
                  }
                }}
              >
                {props.subtitle}
              </div>
              <Card.Text
                style={{ color: "#808080" }}
                onClick={() => {
                  if (props.type === "provider") {
                    props.handleListing(
                      props.itemId,
                      props.listingId,
                      props.id,
                      props.title
                    );
                  } else {
                    props.handleDetailpage(
                      props.itemId,
                      props.listingId,
                      props.id,
                      props.type,
                      props.providerId
                    );
                  }
                }}
              >
                {props.providerName ? (
                  <>
                    {props.providerName}
                    <label
                      className="fw-bold"
                      style={{ cursor: "pointer", color: "#F99300" }}
                    >
                      &nbsp;&nbsp;
                      {props.moreBillboards && <SVGAdd />}
                      &nbsp;
                      {props.moreBillboards && props.moreBillboards + "more"}
                    </label>
                  </>
                ) : (
                  <>
                    {props.formats && (
                      <>
                        <SVGMedia />
                        &nbsp;
                        {props.formats + " Formats"}
                      </>
                    )}
                  </>
                )}
              </Card.Text>
              <Row
                onClick={() => {
                  if (props.type === "provider") {
                    props.handleListing(
                      props.itemId,
                      props.listingId,
                      props.id,
                      props.title
                    );
                  } else {
                    props.handleDetailpage(
                      props.itemId,
                      props.listingId,
                      props.id,
                      props.type,
                      props.providerId
                    );
                  }
                }}
              >
                <Col md={7} lg={7}>
                  <Card.Text
                    style={{ color: "#808080" }}
                    className="d-flex align-items-center"
                  >
                    <SVGStarRating />
                    &nbsp;
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      {props.rating}
                    </label>
                    &nbsp;
                    <label>({props.reviews} Reviews)</label>
                  </Card.Text>
                </Col>
                <Col md={5} lg={5} className="startLabels text-end">
                  <label className="label1">Starting</label>&nbsp;
                  <label className="day-no">
                    ${props.startingPrice ? props.startingPrice : 0}
                  </label>
                  {props.card?.availableFor===1?<></>:
                  <label className="label2">
                    &nbsp;/ {props.minBookingPeriod} {props.periodTypeText}
                  </label>}
                </Col>
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  InputGroup,
  Form,
  FloatingLabel,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import { InputField } from "../../../../../../../components/InputField";
import NavBar from "../../../../../../../components/navBar";
import { TextAria } from "../../../../../../../components/TextAria";
import ProjectContext from "../../../../../../../context/ProjectContext";
import { addService } from "../../../../../../../services/Provider/Configurator/Inventory";
import { useToasts } from "react-toast-notifications";
import UpdatesModal from "../../../../../../../components/profile/Modal/UpdatesModal";
import { getUpdateOnBoardingStepDone } from "../../../../../../../services/Provider";
import { checkPermission } from "../../../../../../../utils";

export const AddServicePage = () => {
  const { addToast } = useToasts();
  const { handleActiveTab } = useContext(ProjectContext);
  const history = useHistory();
  let tempStepCount = parseInt(localStorage.getItem("onBoardingStepDone"));
  const [addServiceconfirmModal, setAddServiceconfirmModal] = useState(false);
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);

  const handleOnSavebtn = () => {
    history.push("/provider/configurator/inventory");
  };
  const handleOnCancel = () => {
    if (tempStepCount == 2) {
      history.push("/provider/add-charge");
    } else {
      handleActiveTab("SERVICES");
      history.push("/provider/configurator/inventory");
      //history.goBack();
    }
    // if (tempStepCount !== 2) {
    //   history.push("/provider/configurator/inventory");
    // }
  };

  const formik = useFormik({
    initialValues: {
      serviceName: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      serviceName: Yup.string()
        .min(5, "Please enter title with five or more characters")
        .required("Service name is required"),
    }),
    onSubmit: (values) => {
      //   handleUserLogin(values);

      let formData = {
        name: values.serviceName,
        description: values.description,
      };
      addService(formData)
        .then((res) => {
          if (res.status == 201) {
            if (tempStepCount == 2) {
              localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
              let bodyData = [
                {
                  path: "/onBoardingStepDone",
                  op: "add",
                  value: tempStepCount + 1,
                },
              ];
              getUpdateOnBoardingStepDone(bodyData)
                .then((res) => {
                  console.log("res", res);
                })
                .catch((err) => {
                  console.log("err", err);
                });
            }
            // if (tempStepCount == 2) {
            //   setAddServiceconfirmModal(true);
            //   if (!addServiceconfirmModal) {
            //     history.push("/provider/add-charge");
            //   }
            // } else {
            handleActiveTab("SERVICES");
            //setAddServiceconfirmModal(true);
            addToast("Service added successfully.", { appearance: "success" });
            history.push("/provider/configurator/inventory");
            // }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  });
  const serviceNameError =
    formik.touched.serviceName && formik.errors.serviceName;

  const handleAddServiceConfirmModal = () => {
    setAddServiceconfirmModal(false);
    history.push("/provider/configurator/inventory");
  };
  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <NavBar
          blueBtnText="Save"
          whiteBtnText={"Cancel"}
          notificationSVG={<NotificationSVG />}
          navHeading="Add Service"
          subHeading="Configurator • All Service "
          subActiveHeading="Add Service"
          // handleBlueBtn={handleOnSavebtn}
          handleWhitebtn={handleOnCancel}
          handleNotificationSVG={handleNotificationSVG}
          showButton={true}
        />
        <div className="girdMain gridSection">
          <Card className="gridCard" aria-labelledby="gridCard">
            <Card.Body className="gridCardBody p-0">
              <div className="formSection">
                <p className="mt-4 mx-4">Service Details</p>
                <div className="mx-4">
                  <Row className="pt-2">
                    <Col md={5}>
                      <InputField
                        type="text"
                        name="serviceName"
                        value={formik.values.serviceName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="name@example.com"
                        label="Service Name"
                        inputFieldSize="sizeOfInputField"
                      />

                      <div className="pb-4">
                        {serviceNameError && (
                          <p
                            className="text-danger mt-1 ms-2"
                            style={{ fontSize: "14px" }}
                          >
                            {formik.errors.serviceName}
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col md={5}>
                      <div className="form-floating">
                        <textarea
                          type="text"
                          name="description"
                          value={formik.values.description}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="Description"
                          label="Description"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Form>

      {addServiceconfirmModal && (
        <ConfirmModal
          title="Add Service"
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Ok"
          handleConfirm={handleAddServiceConfirmModal}
        >
          <div className="text-center childsSite has been added. Do you want to add another site">
            <p className="newListingCreated_description">
              Service added successfully.
            </p>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

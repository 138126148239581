import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import DataTable from "react-data-table-component";
import {
  deleteSign,
  deleteSite,
  inventorySigns,
} from "../../../../../../services/Provider/Configurator/Inventory";
import { PassThroughSVG } from "../../../../../../assets/svgComponents/inventorySVG";
import ProjectContext from "../../../../../../context/ProjectContext";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Col, Dropdown, DropdownButton } from "react-bootstrap";
// import { toast } from "react-toastify";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import { useToasts } from "react-toast-notifications";
import { InputField } from "../../../../../../components/InputField";
import Button from "../../../../../../components/button/Button";
import {
  UnionSVG,
  ExportCSVSVG,
} from "../../../../../../assets/svgComponents/table";
import BtnRadio from "../../../../../../components/BtnRadio/BtnRadio";
import BtnToggle from "../../../../../../components/BtnToggle/BtnToggle";
import { checkPermission } from "../../../../../../utils";

const SignsPage = ({
  activeTab,
  setIsRouteChange,
  setGirdActionPage,
  searchInput,
}) => {
  const { handleSelectedSelSign, selectedSign } = useContext(ProjectContext);
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  const [loader, setLoader] = useState(false);
  const [signsData, setSignsData] = useState();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [deleteAbleRow, setDeleteAbleRow] = useState();
  const [filterText, setFilterText] = React.useState("");
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const [groupByMedia, setGroupByMedia] = React.useState(false);

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    setSortColumn({ column: column, sortDirection: sortDirection });
  };

  const columns = [
    {
      name: "id".toUpperCase(),
      selector: (row) => row.id,
      sortable: true,
      sortField: "Id",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
    },
    {
      name: "MEDIA",
      selector: (row) => row.mediaTypeText,
      sortable: true,
      sortField: "MediaType",
    },
    {
      name: "SIZE NAME",
      selector: (row) => row.sizeText,
      sortable: true,
      sortField: "SizeText",
    },
    {
      name: "DIMENSIONS",
      selector: (row) => row.dimensionText,
      sortable: true,
      sortField: "DimensionText",
    },
    {
      name: "WIDTH",
      selector: (row) => row.width,
      sortable: true,
      sortField: "Width",
    },
    {
      name: "HEIGHT",
      selector: (row) => row.height,
      sortable: true,
      sortField: "Height",
    },
    {
      cell: (row) => (
        <span className="gridTableBody_td_passThrough">
          <DropdownButton
            align="end"
            className="dropDownBtn"
            title={<PassThroughSVG />}
          >
            {checkPermission("Configurator", "Full") && (
              <Dropdown.Item onClick={(e) => handleEditGridCell(row)}>
                Edit{" "}
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => handleViewGridCell(row)}>
              View
            </Dropdown.Item>
            {checkPermission("Configurator", "Full") && (
              <Dropdown.Item onClick={() => handleDeleteGridCell(row)}>
                Delete
              </Dropdown.Item>
            )}
          </DropdownButton>
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const loadData = async () => {
    console.log(sortColumn.column);
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: groupByMedia == true ? "MediaType" : sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: filterText,
      status: true,
    };
    setLoader(true);
    await inventorySigns(reqBody)
      .then((res) => {
        if (res.status == 200) {
          setLoader(false);
          setSignsData(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  // fetch sign listing data
  useEffect(async () => {
    loadData();
  }, [currentPage, pageSize, groupByMedia, filterText]);

  const loadExportData = async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: groupByMedia == true ? "MediaTypeText" : sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: true,
      searchString: filterText,
      status: true,
    };
    await inventorySigns(reqBody)
      .then((res) => {
        if (res.status == 200) {
          downloadCSV(res.data.data);
          //setSignsData(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleGroupByMedia = (e) => {
    if (e.target.checked) {
      setGroupByMedia(true);
    }
    else {
      setGroupByMedia(false);
    }
  };

  const handleEditGridCell = (sign) => {
    handleSelectedSelSign(sign);
    history.push(`${location.pathname}/edit-sign`);
  };

  const handleViewGridCell = (sign) => {
    handleSelectedSelSign(sign);
    history.push({ pathname: `${location.pathname}/view-sign`, state: sign });
  };

  const deleteCell = async (item) => {
    await deleteSign(item.id)
      .then(() => {
        loadData();
        // toast("Sign successfully deleted.");
        addToast("Sign successfully deleted.", {
          appearance: "success",
        });
      })
      .catch(() => {
        addToast("There is an error while deleting the sign.", {
          appearance: "error",
        });
        // toast("There is an error while deleting the sign.");
      });
  };
  const handleConfirmModalDelete = async () => {
    await deleteSign(deleteAbleRow)
      .then(() => {
        loadData();
        addToast("Sign successfully deleted.", {
          appearance: "success",
        });
        setDeleteConfirmModal(false);
      })
      .catch(() => {
        addToast("There is an error while deleting the sign.", {
          appearance: "error",
        });
      });
  };

  const handleDeleteGridCell = (item) => {
    setDeleteAbleRow(item.id);
    setDeleteConfirmModal(true);
  };

  // const handleDeleteGridCell = (item) => {
  //   const options = {
  //     title: "Delete",
  //     message: "Do you want to delete the sign?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => deleteCell(item),
  //       },
  //       {
  //         label: "No",
  //         onClick: () => {},
  //       },
  //     ],
  //     childrenElement: () => <div />,
  //     closeOnEscape: true,
  //     closeOnClickOutside: true,
  //     keyCodeForClose: [8, 32],
  //     willUnmount: () => {},
  //     afterClose: () => {},
  //     onClickOutside: () => {},
  //     onKeypressEscape: () => {},
  //     overlayClassName: "overlay-custom-class-name",
  //   };
  //   confirmAlert(options);
  // };

  // gird action handle for sites
  // const handleViewSitesGridCell = () => {
  //   props.setIsRouteChange(true);
  //   props.setGirdActionPage("viewSite");
  //   history.push(`${location.pathname}/view-site`);
  // };
  // const handleEditSitesGridCell = () => {
  //   props.setIsRouteChange(true);
  //   props.setGirdActionPage("editSite");
  //   history.push(`${location.pathname}/edit-site`);
  // };
  // const handleDeleteSitesGridCell = async (item) => {
  //   console.log(item, "delete sites");
  //   setLoader(true);
  //   await deleteSite(item.id)
  //     .then((res) => {
  //       if (res.status == 204) {
  //         addToast("Selected record delete", {
  //           appearance: "warning",
  //         });
  //         setLoader(false);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let res = array.records.map((item) => {
      return {
        Id: item.id,
        Name: item.name,
        "Media Type Text": item.mediaTypeText,
        Size: item.sizeText,
        Dimensions: item.dimensionText,
        Width: item.width,
        Height: item.height,
        Description: item.description,
        Collateral: item.collateral,
        Image: item.image,
        Provider: item.provider.name,
        State: item.stateName,
      }
    });
    let csv = convertArrayOfObjectsToCSV(res);
    if (csv == null) return;

    const filename = "export-signs.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const Export = ({ onExport }) => (
    <button className="blueBgButton" onClick={(e) => onExport(e.target.value)}>
      <span className="btnSvg">
        <ExportCSVSVG fill="#FFFFFF" />
      </span>
      Export CSV
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );

  // gird action handle for signs
  // const handleViewSignsGridCell = (sign) => {
  //   handleSelectedSelSign(sign)
  //   history.push(`${location.pathname}/view-sign`);

  // };
  // const handleEditSignsGridCell = (sign) => {
  //   handleSelectedSelSign(sign)
  //   history.push(`${location.pathname}/edit-sign`);
  // };
  // const handleDeleteSignsGridCell = (sign) => {
  //   handleSelectedSelSign(sign)
  //   setDeleteConfirmModal(true)
  // };

  // const handleDeleteSignConfirm=()=>{
  //   let signId=selectedSign.id;
  //   deleteSign(signId).then(res=>{
  //     if(res.status==204){
  //       setDeleteConfirmModal(false)
  //     }
  //   }).catch(err=>console.log('err', err))
  //   // console.log('selectedSign', selectedSign)
  // }

  return (
    <>
      <Col className="d-flex justify-content-start align-items-center col-6 p-2">
        <div className="tableAction_input_container w-50">
          <InputField
            placeholder="Search Sign ID, Sign Name..."
            label={"Search Sign ID, Sign Name..."}
            onChange={(e) => setFilterText(e.target.value)}
          // value={searchInput}
          />
        </div>
        <div className="navbarBtnContainer ms-3">
          <div className="d-flex">
            <BtnToggle action={handleGroupByMedia} />
            <span className="d-inlin-block ms-1">Group by Media</span>
          </div>

          {/* <Button
            className="whiteBgButton02 "
            // onClick={() =>setFiltersModal(true)}
          >
            Filter
            <span className="btnSvg">
              <UnionSVG />
            </span>
          </Button> */}
        </div>
      </Col>{" "}
      <DataTable
        columns={columns}
        data={signsData?.records}
        allowOverflow={true}
        paginationDefaultPage={currentPage}
        pagination
        paginationServer
        paginationTotalRows={signsData?.totalRecords}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={pageSize}
        //selectableRows
        dense
        onSort={handleSort}
        sortServer
        fixedHeader={{
          fixedHeader: true,
          fixedHeaderScrollHeight: "300px",
        }}
        actions={actionsMemo}
      />

      {deleteConfirmModal && (
        <ConfirmModal
          title="Delete Sign"
          isModalHeader={true}
          isFooterBtns={true}
          cancelText="No"
          confirmText="Yes"
          handleClose={() => setDeleteConfirmModal(false)}
          handleConfirm={() => handleConfirmModalDelete()}
          width="400px"
        >
          <div className="childs">
            <p className="newListingCreated_description ms-4 py-3">
              Are you sure you want to delete this sign?
            </p>
          </div>
        </ConfirmModal>
      )}
      {/* {deleteConfirmModal && (
        <ConfirmModal
          title={selectedSign.name}
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          cancelText="No"
          handleClose={() => setDeleteConfirmModal(false)}
          handleConfirm={handleDeleteSignConfirm}
        >
          <div className="text-center">
            <p className="py-4 newListingCreated_description">
              Would you like to delete this sign?
            </p>
          </div>
        </ConfirmModal>
      )} */}
    </>
  );
};

export default SignsPage;

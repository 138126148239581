import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { To2Decimal } from "../../../../../services/utility"; 

const PriceDetails = ({bookingDetails}) => {
 
  return (
    <>
      <div className="site_booking_card_heading py-2">Payment Details</div>
      
      {bookingDetails.bookingType=='site' && (
        <h5 className="site_booking_card_heading05">
          <Row className="py-1">
            <Col>Site Rent</Col>
            <Col className="d-flex justify-content-end">${To2Decimal(bookingDetails.applicableAmountWithoutTax)}</Col>
          </Row>
        </h5>
      )}
      
      {bookingDetails.bookingType=='sign' && (
        <h5 className="site_booking_card_heading05">
          <Row className="py-1">
            <Col>Sign Rent</Col>
            <Col className="d-flex justify-content-end">${To2Decimal(bookingDetails.signAmountWithoutTax)}</Col>
          </Row>
        </h5>
      )}

      <h5 className="site_booking_card_heading05">
        <Row className="py-1">
          <Col>Add-On Services Price</Col>
          <Col className="d-flex justify-content-end">${To2Decimal(bookingDetails.addOnServicePriceWithoutTax)}</Col>
        </Row>
      </h5>
      <h5 className="site_booking_card_heading05">
        <Row className="py-1">
          <Col>Add-On Charges Price</Col>
          <Col className="d-flex justify-content-end">${To2Decimal(bookingDetails.addOnChargePriceWithoutTax)}</Col>
        </Row>
      </h5>
      <h5 className="site_booking_card_heading05">
        <Row className="py-1">
          <Col>Offer Discount</Col>
          <Col className="d-flex justify-content-end">-${To2Decimal(bookingDetails.offerDiscount)}</Col>
        </Row>
      </h5>
      <h5 className="site_booking_card_heading05">
        <Row className="py-1">
          <Col>Total Tax</Col>
          <Col className="d-flex justify-content-end">${To2Decimal(bookingDetails.totalTax)}</Col>
        </Row>
      </h5>
      <hr />
      {/* <div className="site_booking_card_heading">
        <Row className="py-2">
          <Col>Total Amount</Col>
          <Col className="d-flex justify-content-end ">
            <b>${To2Decimal(bookingDetails.totalAmount)}</b>
          </Col>
        </Row>
      </div>
      <hr /> */}
      <div className="site_booking_card_heading">
        <Row className="py-2">
          <Col>Total Amount</Col>
          <Col className="d-flex justify-content-end ">
            <b>${To2Decimal(bookingDetails.netAmount)}</b>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PriceDetails;

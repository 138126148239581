import { Log, User, UserManager } from "oidc-client";
import { Constants } from "./Constants";
export class AuthService {

  constructor(name, year) {
    const settings = {
      authority: Constants.stsAuthority,
      client_id: Constants.clientId,
      redirect_uri: window.location.origin + "/signin-callback.html",
      silent_redirect_uri: window.location.origin + "/silent-renew.html",
      post_logout_redirect_uri: window.location.origin+"/signout-callback-oidc",
      response_type: "code",
      scope: Constants.clientScope,
    };
    this.userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;

  }
  
  getUser() {
    return this.userManager.getUser();
  }

  login() {
    localStorage.setItem("RedirectURL", window.location.href);
    return this.userManager.signinRedirect();
  }

  renewToken() {
    return this.userManager.signinSilent();
  }

  logout() {
    return this.userManager.signoutRedirect();
  }
}

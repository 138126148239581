import React, { useState, useEffect } from "react";
import { Badge, Modal } from "react-bootstrap";
import {
  ExportCSVSVG,
  NotificationSVG,
  PlusSVG,
} from "../../assets/svgComponents/table";
import Button from "../button/Button";
import { FloatingLableSearchBar } from "../SearchBar/FloatingLableSearchBar";
import { SearchBar } from "../SearchBar/SearchBar";
import {
  getNotificationHistoryList,
  markAllNotificationRead,
  putNotificationMardRead,
} from "../../services/api/notifications-api";
import UpdatesModal from "../../components/profile/Modal/UpdatesModal";

const NavBar = (props) => {
  const {
    handleBlueBtn,
    handleWhitebtn,
    type,
    searchBarlabel,
    handleSearchTextChange,
    handleSearchClick,
    searchTerm,
    showButton
  } = props;
  const [unreadNotificationCount, setUnreadNotificationCount] = useState();
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);
  useEffect(() => {
    const reqBody = {
      page: 1,
      pageSize: 10,
      orderBy: "",
      isOrderByDesc: true,
      export: false,
      searchString: "",
      fromDate: null,
      toDate: null,
    };
    getNotificationHistoryList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          console.log(res, "notification");
          //setNotifications(res.data.records);
          let unread = res.data.records.filter(
            (notification) => !notification.isRead
          );
          setUnreadNotificationCount(0);
          if (unread.length > 0) {
            setUnreadNotificationCount(unread.length);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  },[navbarUpdatesModal]);

  const handleNotification = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <>
      <div className="provider_navbar row p-20 py-0 m-0 g-0">
        <div className="navbarLeftSide col-4">
          <h3 className="pageHeading m-0">{props.navHeading}</h3>
          {props.subHeading && (
            <div className="d-flex">
              <p className="m-0 pageSubHeading">{props.subHeading}</p>
              <p className="m-0 pageSubActiveHeading">
                {props.subActiveHeading}
              </p>
            </div>
          )}
        </div>

        {props.isInputField && (
          <div className="navbarCenter col-4">
            <FloatingLableSearchBar
              label={searchBarlabel}
              handleOnchange={handleSearchTextChange}
              handleSearchClick={handleSearchClick}
              searchTerm={searchTerm}
              searchafter={"searchafter"}
            />
          </div>
        )}

        <div className="navbarRightSide col-4 d-flex justify-content-end">
          {props.whiteBtnText && showButton && (
            <div className="navbarBtnContainer">
              <Button
                className="whiteBgButton"
                type="button"
                onClick={handleWhitebtn}
                isDisabled={props.isDisabled}
              >
                {props.whiteBtnSVG && (
                  <span className="btnSvg">{props.whiteBtnSVG}</span>
                )}
                {props.whiteBtnText}
              </Button>
            </div>
          )}
          {props.blueBtnText && showButton && (
            <div className="navbarBtnContainer">
              <Button
                className="blueBgButton"
                type="submit"
                onClick={handleBlueBtn && handleBlueBtn}
                isDisabled={props.isDisabled}
              >
                {props.blueBtnSVG && (
                  <span className="btnSvg">{props.blueBtnSVG}</span>
                )}
                {props.isDisabled && props.isDisabled
                  ? props.isDisabledText
                  : props.blueBtnText}
              </Button>
            </div>
          )}
          {props.notificationSVG && (
            <div className="navbarBtnContainer">
              <span
                className="navbarNotification"
                onClick={handleNotification}
              >
                {props.notificationSVG}
                {unreadNotificationCount > 0 && (
                  <div
                    style={{
                      padding: "2px",
                      backgroundColor: "red",
                      borderRadius: "100%",
                      marginTop: "-12px",
                    }}
                  ></div>
                )}
              </span>
            </div>
          )}
        </div>
      </div>
      <Modal
          show={navbarUpdatesModal}
          onHide={() => setNavbarUpdatesModal(false)}
          dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
        >
          <UpdatesModal />
        </Modal>
    </>
  );
};

export default NavBar;

import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { getAdminDashboard } from "../../../../services/Admin/HomePage";
import NavBar from "../../../../components/navBar";
import { NotificationSVG } from "../../../../assets/svgComponents/table";
import { LineChart } from "../../../../components/Chart/LineChart";
import { PieChart } from "../../../../components/Chart/PieChart";
import { DoughnutChart } from "../../../../components/Chart/DoughnutChart";
import { CustomListGroup } from "../../../../components/ListGroup/index";
import { CustomTile } from "../../../../components/Tile/CustomTile";

import { SelectComponent } from "../../../../components/SelectionComponent/SelectComponent";
import { getTicketSearch } from "../../../../services/Admin/SupportRequest/SupportRequest";
import moment from "moment";
import { providerList } from "../../../../services/Provider/UserManagement/UserManagement";
import { PlusSVG } from "../../../../assets/svgComponents/svg";
import { useHistory, useLocation } from "react-router";
import { lastCurrentPerCalulate } from "../../../../utils";
import { faL } from "@fortawesome/free-solid-svg-icons";

export const Dashboard = () => {
  const [adminDashBoardData, setAdminDashBoardData] = useState({});
  const [customersRegisteredGraphData, setCustomersRegisteredGraphData] =
    useState([]);
  const [
    customersRegisteredGraphDataTitle,
    setCustomersRegisteredGraphDataTitle,
  ] = useState([]);
  const tabs = ["CUSTOMER AQUISITION", "CUSTOMER RETENTION"];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const GraphByItem = [
    { id: "Year", name: "Year" },
    { id: "Month", name: "Month" },
    { id: "Day", name: "Day" },
  ];

  const today = new Date();
  const curYear = today.getFullYear();
  const curMonth = today.getMonth() + 1;
  const curDate = today.getDay();
  const lastDate = daysInMonth(curMonth, curYear);
  let FromDate = curYear + "-01-01 00:00:00";
  let ToDate = curYear + "-12-31 23:59:59";
  let reqBody = "?Type=Year&FromDate=" + FromDate + "&ToDate=" + ToDate;
  const [reqQuery, setReqQuery] = useState(reqBody);
  const [supportRequestData, setSupportRequestData] = useState();
  const [providerRequestData, setProviderRequestData] = useState();
  const ref = useRef();
  const history = useHistory();
  const location = useLocation();

  const handleVerificationRequest = (providerId) => {
    history.push({ pathname: "/admin/allusers/provider-details", state: { id: providerId } });
  };
  // fetch data for by provider dashboard
  useEffect(async () => {
    getAdminDashboard(reqQuery)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data, "admin Data");
          setAdminDashBoardData(res.data);
          let temp1 = [];
          let temp2 = [];
          if (res.data.customersRegisteredGraphData != null) {
            let xvalueStart = 0;
            let xvalueEnd = 0;
            if (dashboardGraphBy === "Year") {
              xvalueEnd = 12;
            } else if (dashboardGraphBy === "Month") {
              xvalueEnd = 31;
            } else {
              xvalueStart = 0;
              xvalueEnd = 23;
            }
            for (let i = xvalueStart; i <= xvalueEnd; i++) {
              temp2.push(i);
              temp1.push("0");
            }
            res.data.customersRegisteredGraphData.map((spendData) => {
              temp1[spendData.xValue] = spendData.yValue;
              //temp1.push(salesData.yValue)
              //temp2.push(salesData.xValue)
            });
          }
          setCustomersRegisteredGraphData(temp1);
          setCustomersRegisteredGraphDataTitle(temp2);
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }, [reqQuery]);

  useEffect(async () => {
    const reqBody = {
      searchText: null,
      dateFilter: 0,
      isOpen: true,
      orderBy: {
        propertyName: "createdate",
        isDesc: true,
      },
    };
    await getTicketSearch(reqBody, 1, 5)
      .then((res) => {
        if (res.status === 200) {
          setSupportRequestData(res.data);
          console.log(res, "supportRequest");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(async () => {
    const reqBody = {
      page: 1,
      pageSize: 8,
      export: false,
      verified: 3,
    };
    await providerList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          setProviderRequestData(res.data);
          console.log("provider data", res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleShowAllSupportBtn = () => {
    history.push(`/admin/support`);
  };

  const handleShowAllProviderBtn = () => {
    history.push({ pathname: `/admin/allusers`, state: { tabValue: "PROVIDERS", statusValue: "Pending" } });
  };

  const [dashboardGraphBy, setDashboardGraphBy] = useState({ id: "Year", name: "Year" });
  const [dashboardYear, setDashboardYear] = useState({ id: curYear, name: curYear });
  const [dashboardMonth, setDashboardMonth] = useState({ id: "01", name: "01" });
  const [dashboardDay, setDashboardDay] = useState({ id: "01", name: "01" });

  const yearList = [];
  for (var y = curYear; y >= 2021; y--) {
    let obj = { id: y, name: y };
    yearList.push(obj);
  }

  const monthList = [];
  for (var m = 1; m <= 12; m++) {
    let mon = ("0" + m).slice(-2);
    let obj = { id: mon, name: mon };
    monthList.push(obj);
  }

  let temp = [];
  for (var d = 1; d <= 31; d++) {
    let day = ("0" + d).slice(-2);
    let obj = { id: day, name: day };
    temp.push(obj);
  }

  const [dashboardDayList, setDashboardDayList] = useState(temp);

  function daysInMonth(iMonth, iYear) {
    return new Date(iYear, iMonth, 0).getDate();
  }

  function DashboardGraphSelectChange(e) {
    setDashboardGraphBy(e);
    if (e.id === "Year") {
      FromDate = dashboardYear.id + "-01-01 00:00:00";
      ToDate = dashboardYear.id + "-12-31 23:59:59";
      reqBody = "?Type=Year&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else if (e.id === "Month") {
      let lastDate = daysInMonth(dashboardMonth.id, dashboardYear.id);
      FromDate = dashboardYear.id + "-" + dashboardMonth.id + "-01 00:00:00";
      ToDate =
        dashboardYear.id + "-" + dashboardMonth.id + "-" + lastDate + " 23:59:59";
      reqBody = "?Type=Month&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else {
      FromDate =
        dashboardYear.id + "-" + dashboardMonth.id + "-" + dashboardDay.id + " 00:00:00";
      ToDate =
        dashboardYear.id + "-" + dashboardMonth.id + "-" + dashboardDay.id + " 23:59:59";
      reqBody = "?Type=Day&FromDate=" + FromDate + "&ToDate=" + ToDate;
    }
    //DashboardDataChange(reqBody);
    //GetAdminDashBoardData(reqBody);
    setReqQuery(reqBody);
  }

  function DashboardYearChange(e) {
    //alert(e.target.value);
    setDashboardYear(e);
    let year = e.id;
    FromDate = year + "-01-01 00:00:00";
    ToDate = year + "-12-31 23:59:59";
    reqBody =
      "?Type=" +
      dashboardGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    //GetAdminDashBoardData(reqBody);
    setReqQuery(reqBody);
  }
  function DashboardMonthChange(e) {
    //alert(e.target.value);
    setDashboardMonth(e);
    let month = e.id;
    let lastDate = daysInMonth(e.id, dashboardYear.id);
    //setCustomerDayList([]);
    let temp = [];
    for (var d = 1; d <= lastDate; d++) {
      let day = ("0" + d).slice(-2);
      let obj = { id: day, name: day };
      temp.push(obj);
    }
    setDashboardDayList(temp);
    setDashboardDay("01");

    FromDate = dashboardYear.id + "-" + month + "-01 00:00:00";
    ToDate = dashboardYear.id + "-" + month + "-" + lastDate + " 23:59:59";
    reqBody =
      "?Type=" +
      dashboardGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    //GetAdminDashBoardData(reqBody);
    setReqQuery(reqBody);
  }

  function DashboardDayChange(e) {
    //alert(e.target.value);
    setDashboardDay(e);
    let day = e.id;
    FromDate = dashboardYear.id + "-" + dashboardMonth.id + "-" + day + " 00:00:00";
    ToDate = dashboardYear.id + "-" + dashboardMonth.id + "-" + day + " 23:59:59";
    reqBody =
      "?Type=" +
      dashboardGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    //GetAdminDashBoardData(reqBody);
    setReqQuery(reqBody);
  }

  return (
    <>
      <NavBar
        isInputField={false}
        navHeading="Dashboard"
        subHeading=""
        notificationSVG={<NotificationSVG />}
        showButton={false}
      />
      <Container fluid>
        <Row>
          <Col lg={9} md={9} className="bg-base-100 dashboard-lside">
            <Row className="pt-4">
              <Col xs={12}>
                <div className="d-flex align-items-center mb-3">
                  <h1
                    className="d-flex float-start"
                    style={{
                      color: "#AFAFAF",
                      fontSize: "12px",
                      paddingRight: "10px",
                      marginBottom: "0",
                    }}
                  >
                    PERFORMANCE
                  </h1>
                  <div className="d-flex float-start yr-summary">
                    <div className="me-1">
                      <SelectComponent
                        label=""
                        option={GraphByItem}
                        value={dashboardGraphBy}
                        defaultValue={dashboardGraphBy}
                        inputsize="form-control-sm rouned-3 sel-chart float-start"
                        handleOnchange={DashboardGraphSelectChange}
                      /></div>
                    <div className="me-1">
                      <SelectComponent
                        label=""
                        option={yearList}
                        value={dashboardYear}
                        defaultValue={dashboardYear}
                        inputsize="form-control-sm rouned-3 sel-chart float-start"
                        handleOnchange={DashboardYearChange}
                      /></div>
                    {dashboardGraphBy.id === "Month" ||
                      dashboardGraphBy.id === "Day" ? (
                      <div className="me-1">
                        <SelectComponent
                          label=""
                          option={monthList}
                          value={dashboardMonth}
                          defaultValue={dashboardMonth}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={DashboardMonthChange}
                        /></div>
                    ) : null}
                    {dashboardGraphBy.id === "Day" ? (
                      <SelectComponent
                        label=""
                        option={dashboardDayList}
                        value={dashboardDay}
                        defaultValue={dashboardDay}
                        inputsize="form-control-sm rouned-3 sel-chart float-start"
                        handleOnchange={DashboardDayChange}
                      />
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={3} className="dcard">
                <div className="dashcard">
                  <CustomTile
                    heading="Revenue"
                    amount={"$ " + adminDashBoardData.revenue}
                    description={lastCurrentPerCalulate(adminDashBoardData.revenue, adminDashBoardData.lastRevenue) + "% than last " + dashboardGraphBy.name}
                    active="true"
                    type={1}
                  />
                </div>
              </Col>
              <Col xs={3} className="dcard">
                <div className="dashcard">
                  <CustomTile
                    heading="In Escrow Funds"
                    amount={"$ " + adminDashBoardData.inEscrow}
                    description={lastCurrentPerCalulate(adminDashBoardData.inEscrow, adminDashBoardData.lastInEscrow) + "% than last " + dashboardGraphBy.name}
                    type = {2}
                  /></div>
              </Col>
              <Col xs={3} className="dcard">
                <div className="dashcard">
                  <CustomTile
                    heading="Released from Escrow"
                    amount={"$ " + adminDashBoardData.releaseFromEscrow}
                    description={lastCurrentPerCalulate(adminDashBoardData.releaseFromEscrow, adminDashBoardData.lastReleaseFromEscrow) + "% than last " + dashboardGraphBy.name}
                    type = {3}
                  /></div>
              </Col>
              <Col xs={3} className="dcard">
                <div className="dashcard">
                  <CustomTile
                    heading="New Providers"
                    amount={adminDashBoardData.newProviders}
                    description={lastCurrentPerCalulate(adminDashBoardData.newProviders, adminDashBoardData.lastNewProviders) + "% than last " + dashboardGraphBy.name}
                    type = {5}
                  />
                </div>
              </Col>
              <Col xs={3} className="dcard">
                <div className="dashcard">
                  <CustomTile
                    heading="Total Orders"
                    amount={adminDashBoardData.totalOrders}
                    description={lastCurrentPerCalulate(adminDashBoardData.totalOrders, adminDashBoardData.lastTotalOrders) + "% than last " + dashboardGraphBy.name}
                    type = {6}
                  /></div>
              </Col>
              {/* <Col xs={3} className="dcard">
                <div className="dashcard">
                  <CustomTile
                    heading="Areas Served"
                    amount={"$ " + adminDashBoardData.areaServed}
                    description={lastCurrentPerCalulate(adminDashBoardData.areaServed, adminDashBoardData.lastAreaServed) + "% than last " + dashboardGraphBy.name}
                  /></div>
              </Col> */}
            </Row>

            <Row>
              <Col xs={12} className="mt-3">
                <div className="gridSection-rm">
                  <div className="tableActions_container m-0">
                    <Row>
                      <Col className="d-flex ">
                        <ul className="tableActions_tabs">
                          {tabs.map((item, index) =>
                            item === activeTab ? (
                              <li
                                key={"db_tab_" + index}
                                className="tableActions_tab active"
                                onClick={() => {
                                  setActiveTab(item);
                                }}
                              >
                                {item} <hr className="tableActions_tab_hr" />
                              </li>
                            ) : (
                              <li
                                key={"db_tab_" + index}
                                className="tableActions_tab"
                                onClick={() => {
                                  setActiveTab(item);
                                }}
                              >
                                {item}
                              </li>
                            )
                          )}
                        </ul>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <h4>${adminDashBoardData.revenue} <small className="mark bg-success text-white rounded-pill"> {lastCurrentPerCalulate(adminDashBoardData.revenue, adminDashBoardData.lastRevenue)} % </small></h4>
                  </div>
                  <div>
                    {activeTab === "CUSTOMER AQUISITION" ? (
                      <Col>
                        <LineChart
                          graphDataTitles={customersRegisteredGraphDataTitle}
                          graphData={customersRegisteredGraphData}
                          bgColor="#3734A9"
                          hoverBgColor="#F9896B"
                        />
                      </Col>
                    ) : (
                      <Col>
                        <LineChart
                          graphDataTitles={customersRegisteredGraphDataTitle}
                          graphData={customersRegisteredGraphData}
                          bgColor="#3734A9"
                          hoverBgColor="#F9896B"
                        />
                      </Col>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={3} md={3} className="dashboard-rside p-0">
            <div className="mt-5">
              <h5 className="mb-3">Support Requests </h5>
              {supportRequestData?.records?.map((c) => (
                <ListGroup.Item
                  key={"sr_" + c.id}
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                  variant="light"
                >
                  <CustomListGroup
                    id={c.id}
                    heading={c.id}
                    subheading={new moment(c.createdate).format("DD MMM, YYYY")}
                  />
                </ListGroup.Item>
              ))}
              <div
                className="oneMore"
                style={{ textAlign: "right", cursor: "pointer" }}
                onClick={handleShowAllSupportBtn}
              >
                <PlusSVG fill="#F99300" />
                <span>
                  Show All
                </span>
              </div>
            </div>
            <div className="mt-5">
              <h5 className="mb-3">Verification Requests </h5>
              {providerRequestData?.records?.map((o) => (
                <ListGroup.Item
                  key={"vr_" + o.id}
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                  variant="light"
                >
                  <CustomListGroup
                    id={o.id}
                    heading={o.name}
                    subheading={o.emailId}
                    link={true}
                    handleViewBTN={() => handleVerificationRequest(o.id)}
                  />
                </ListGroup.Item>
              ))}
              <div
                className="oneMore d-fle align-items-center"
                style={{ textAlign: "right", cursor: "pointer" }}
                onClick={handleShowAllProviderBtn}
              >
                <PlusSVG fill="#F99300" />
                <span>
                  Show All
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

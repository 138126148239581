import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getOptions, processFilterOptions } from '../filters-base';

const initialState = {
    transactions:{ records:[]},
    page : 0,
    pageSize : 10,
    export : false,
    searchString : '',
    checkBoxFilters : [
    { title:"Transaction Types", type:'TransactionTypes', page:['transactions'], options:[] },
    { title:"Order Type", type:"OrderType", page:['transactions'], options:[{id:'Advertiser', name:'Advertiser'},{id:'Provider',name:'Provider'}] }, 
    { title:"Status", type:"OrderStatus", page:['orders'], options:[] }, 
    { title:"Media Types", type:"MediaTypes", page:['orders'], options:[] }],
    sortingOptions : {options:[]},
}
 
export const getFilterOptionsAsync = createAsyncThunk('filters/getFilterOptionsAsync',
    async(filterType) => {
    const response = await getOptions(filterType);
    return { filterType: filterType, data: processFilterOptions(response.data)};
    }
) 

export const getCustomFilterOptionsAsync = createAsyncThunk('filters/getCustomFilterOptionsAsync',
    async(payload) => {
    const {page, type, apiFunc,params} = payload;
    const response = await apiFunc(params);
    return { page, type, title:type, data: processFilterOptions(response.data.records)};
    }
)

export const getSortingOptionsAsync = createAsyncThunk('filters/getSortingOptionsAsync',
    async() => {
    const response = await getOptions('DatePriceSorting');
    return {options:processFilterOptions(response.data)};
    }
)

export const getSelectedOptions = (state,page,type) => {
    let ids = [];
     state.filters.checkBoxFilters.filter(r=>r.page.indexOf(page)>-1 && r.type===type)
     .forEach(element => {
        element.options.filter(f=>f.isChecked).forEach(opt=> ids.push(opt.id))  
     });
     return ids && ids.length>0 ? ids : null;
}

export const getSelectedSortBy = (state,page) => {
    let ids = [];
     state.filters.sortingOptions.options.filter(f=>f.isChecked).forEach(opt=> ids.push(opt.id));
     return ids && ids.length>0 ? ids[0] : 2;
}

const filtersSlice = createSlice({
    name:"filters",
    initialState,
    reducers:{ 
        filterCBChanged:(state,params)=>{  
            if(params.payload.type=="sorting"){
                //state.sortingOptions = {...state.sortingOptions,options: state.sortingOptions.options.map(s=>s.id!=params.payload.id ? s : {...s,isChecked:!s.isChecked})};
                state.sortingOptions = {...state.sortingOptions,options: state.sortingOptions.options.map(s=>s.id!=params.payload.id ? {...s,isChecked:false} : {...s,isChecked:true})};
            }else{
                state.checkBoxFilters =  state.checkBoxFilters.map(r=>{  
                    console.log(params.payload.type,r.title);                                       
                    if(r.title==params.payload.type){                         
                        r.options = r.options.map(s=>s.id!=params.payload.id ? s : {...s,isChecked:!s.isChecked});                    
                    }
                    return r;
                });            
            }
        },
        clearAllCB:(state,payload)=>{
            state.checkBoxFilters =  state.checkBoxFilters.map(r=>{                
                r.options = r.options.map(s=> ({...s, isChecked:false}));
                return r;
            });
            state.sortingOptions =  {...state.sortingOptions,options:state.sortingOptions.options.map(s=> ({...s, isChecked:false}))};
        }, 
    },
    extraReducers:{
        [getFilterOptionsAsync.pending] : () => {
            
        },
        [getFilterOptionsAsync.fulfilled] : (state, {payload}) => {    
            if(current(state).checkBoxFilters.filter(r=> r.type ===payload.filterType).length>0){
                let typeFilter = current(state).checkBoxFilters.filter(r=> r.type ===payload.filterType)[0];                 
                return {...state,checkBoxFilters:[...current(state).checkBoxFilters.filter(r=>r!=typeFilter),{...typeFilter,options:payload.data}]};            
            }
            return state;
        },
        [getFilterOptionsAsync.rejected] : () => {
            console.log("rejected filter types options")
        },
        [getCustomFilterOptionsAsync.pending] : () => {
            console.log("pending custom filter types options")
        },
        [getCustomFilterOptionsAsync.fulfilled] : (state, {payload}) => {  
            console.log('fullfilled custom filter options',payload);  
            if(current(state).checkBoxFilters.filter(r=> r.type ===payload.type).length>0){
                let typeFilter = current(state).checkBoxFilters.filter(r=> r.type ===payload.type)[0];                 
                return {...state,checkBoxFilters:[...current(state).checkBoxFilters.filter(r=>r!=typeFilter),{...typeFilter,options:payload.data}]};            
            }else if(payload && payload.data){
                return {...state,checkBoxFilters:[...current(state).checkBoxFilters,{ page:payload.page, type:payload.type, title:payload.title ,options:payload.data}]};            
            }
            return state;
        },
        [getCustomFilterOptionsAsync.rejected] : () => {
            console.log("rejected custom filter types options")
        },
        [getSortingOptionsAsync.pending] : () => {
            
        },
        [getSortingOptionsAsync.fulfilled] : (state, {payload}) => {   
            return {...state,sortingOptions:payload};            
        },
        [getSortingOptionsAsync.rejected] : () => {
            console.log("rejected sorting options")
        },
    }
});
export const { filterCBChanged,clearAllCB } = filtersSlice.actions;
export default filtersSlice.reducer;

import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Container } from "react-bootstrap";
import { MarkerBlueSVG, MarkerGreenSVG, MarkerPurpleSVG, MarkerYellowSVG, SVGBack } from "../../../../assets/svgComponents/svg";
import { Cards } from "../../../../components/Cards/Cards";
import Tabs from "./Tabs/Tabs";
import { getListByProvidersAsync, getListItemDetailsAsync, getSimilarListingAsync, getListingReviewsAsync, getAvailableOffersForProviderAsync, setFilterProviderId, getSelectedFilters, getProviderSitesAsync, setShowOnlyFeatured, setLatitude, setLongitude, setMapZoom, setMapDistance } from "../../../../services/reducers/listing-reducer";
import { useDispatch, useSelector } from "react-redux";
import { ListingItemCard } from "./ListingItemCard";
import { useHistory } from "react-router-dom";
import { ListingFilters } from "./ListingFilters";
import { GoPinitMapsSites } from "../../../../components/Maps/GoPinitMapsSites";
import { getRadiusOfOperation } from "../../../../services/utility";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  InfoSVG,
} from "../../../../assets/svgComponents/svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlane,
  faCalendarCheck,
  faBusSimple,
  faMapMarker,
  faBuilding,
  faShoppingCart,
  faPersonBiking,
  faSignsPost,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import markerImg from "../../../../assets/images/markerImg.png";
import markersInfoImg from "../../../../assets/images/markersInfo.png";

export const ByProvider = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const listByProviders = useSelector((state) => state.listing.listByProviders);
  const providerSites = useSelector((state) => state.listing.providerSites);
  const { listingFilterMedia, listingFilterEnvironment } = useSelector((state) => state.listing);
  const { media, environment, onlyFeatured, minCost, maxCost, fromDate, uptoDate, providerId, latitude, longitude, mapZoom, searchTerm, mapDistance } = useSelector((state) => getSelectedFilters(state.listing));
  const [isBackBtn, setIsBackBtn] = useState(false);
  const [showMarkerInfoImg, setShowMarkerInfoImg] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [listingType, setListingType] = useState(2);
  const [page, setPage] = useState(1);
  const [sitePage, setSitePage] = useState(1);


  useEffect(() => {
    getListing(2);
  }, [page]);


  useEffect(() => {
    if (providerId && selectedProvider)
      handleListing(null, null, providerId, selectedProvider);
  }, [sitePage]);

  useEffect(() => {
    if (listingType == 2) {
      if (page == 1) {
        getListing(listingType);
      } else {
        setPage(1);
      }
    }
    else if (listingType == 0) {
      if (sitePage == 1) {
        handleListing(null, null, providerId, selectedProvider);
      } else {
        setSitePage(1);
      }
    }
  }, [dispatch, minCost, fromDate, uptoDate, onlyFeatured, listingFilterMedia, listingFilterEnvironment, latitude, longitude, mapZoom, searchTerm]);

  const getListing = (lstType) => {
    const reqBody = {
      page: page,
      pageSize: 10,
      orderBy: "",
      export: false,
      searchString: searchTerm,
      userLocation: "",
      costs: [],
      mediaTypes: media,
      environmentIds: environment,
      dates: [],
      onlyFeatured: onlyFeatured,
      listingType: lstType,
      providerId: "",
      latitude: latitude,
      longitude: longitude,
      radiusOfOperation: mapDistance ? mapDistance : getRadiusOfOperation(latitude, mapZoom),
    };
    if (minCost != null && maxCost) {
      reqBody.costRange = { min: minCost, max: maxCost };
    }
    if (fromDate && uptoDate) {
      reqBody.dateRange = { fromDate, uptoDate };
    }
    dispatch(getListByProvidersAsync(reqBody));
  }

  const handleListing = (itemId, listingId, providerId, title) => {
    if (providerId)
      dispatch(setFilterProviderId(providerId));

    setListingType(0);
    const reqBody = {
      page: sitePage,
      pageSize: 10,
      orderBy: "",
      export: false,
      searchString: "",
      userLocation: "",
      costs: [],
      mediaTypes: media,
      environmentIds: environment,
      dates: [],
      onlyFeatured: onlyFeatured,
      listingType: 0,
      providerId: providerId,
    };
    if (minCost != null && maxCost) {
      reqBody.costRange = { min: minCost, max: maxCost };
    }
    if (fromDate && uptoDate) {
      reqBody.dateRange = { fromDate, uptoDate };
    }
    dispatch(getProviderSitesAsync(reqBody));

    setIsBackBtn(true);
    setSelectedProvider(title);
  };

  const handleDetailpage = (itemId, listingId, id, type, providerId) => {
    console.log('listing details:', type, id, itemId, listingId);
    dispatch(getListItemDetailsAsync(itemId));
    dispatch(getSimilarListingAsync(itemId));
    dispatch(getListingReviewsAsync(listingId));
    dispatch(getAvailableOffersForProviderAsync(providerId));
    history.push("/customer/site-detail");
  };

  const handleBack = () => {
    setIsBackBtn(false);
    setListingType(2);
  };

  const onFeaturedChange = (value) => {
    dispatch(setShowOnlyFeatured(value));
  };

  return (
    <>
      <Row className="home-container gridSection g-0">
        <Col id="pnl-list-by-providers" className={listByProviders.totalRecords > 0 ? "home-left col-6 overflow-auto h-100 flex-grow-1 scrollOverBottom" : "home-left col-6 overflow-auto h-100 flex-grow-1 scrollOverBottomDone"}>
          <Row className="home-section1 px-1 align-items-center g-0 listingFiltersRow ">
            <ListingFilters />
          </Row>
          <div className="home-section2">
            <Tabs
              featured={onlyFeatured}
              tabs={props.tabs}
              activeTab={props.activeTab}
              setActiveTab={props.setActiveTab}
              setFeatured={(value) => onFeaturedChange(value)}

            />
          </div>
          {isBackBtn === true ? (
            <Row style={{ marginBottom: '10px' }}>
              <Col
                md={1}
                lg={1}
                style={{ marginLeft: "20px", cursor: "pointer" }}
                onClick={handleBack}>
                <SVGBack />
              </Col>
              <Col>
                {selectedProvider}
              </Col>
            </Row>
          ) : null}

          {isBackBtn && providerSites.records.length > 0 ? (
            <InfiniteScroll dataLength={providerSites.records.length - 1}
              hasMore={true}
              next={() => setSitePage(sitePage + 1)}
              scrollableTarget="pnl-list-by-providers"
              // loader={<h6>Loading...</h6>}
              hasChildren={providerSites.records.length > 0}
            >
              {providerSites.records.map((site, index) =>
              (<ListingItemCard
                key={site.itemId + '_pvdr_' + index}
                id={site.id}
                itemId={site.itemId}
                listingId={site.listingId}
                providerId={site.providerId}
                type="providersite"
                title={site.listingName}
                subtitle={site.name}
                text={site.description}
                startingPrice={site.price ? parseFloat(site.price).toFixed(2) : site.price}
                rating={site.rating}
                reviews={site.reviews}
                isFavorite={site.isFavorite}
                image={site.image}
                moreBillboards={site.moreBillboards}
                isFeatured={site.featured}
                formats={site.formats}
                isProvider={false}
                providerName={site.provider}
                handleDetailpage={(itemId, listingId, id, type, providerId) => { handleDetailpage(itemId, listingId, id, type, providerId); }}
                card={site}
                cartType={"site"}
                minBookingPeriod={site.minBookingPeriod}
                periodTypeText={site.periodTypeText}
              />))
              }
            </InfiniteScroll>
          ) : null}


          {!isBackBtn && listByProviders.records.length > 0 ? (
            <InfiniteScroll dataLength={listByProviders.records.length - 1}
              hasMore={true}
              next={() => setPage(page + 1)}
              scrollableTarget="pnl-list-by-providers"
              // loader={<h6>Loading...</h6>}
              hasChildren={listByProviders.records.length > 0}
            >
              {listByProviders.records.map((provider, index) => (
                <ListingItemCard
                  key={provider.id + '_provider_' + index}
                  id={provider.id}
                  itemId={provider.itemId}
                  listingId={provider.listingId}
                  type="provider"
                  title={provider.provider}
                  subtitle={provider.location}
                  text={provider.description}
                  startingPrice={provider.price ? parseFloat(provider.price).toFixed(2) : provider.price}
                  rating={provider.rating}
                  reviews={provider.reviews}
                  isFavorite={provider.isFavorite}
                  image={provider.image}
                  moreBillboards={provider.moreBillboards}
                  isFeatured={provider.featured}
                  formats={provider.formats}
                  isProvider={false}
                  providerName={provider.provider}
                  handleListing={handleListing}
                  card={provider}
                  cartType={"provider"}
                  minBookingPeriod={provider.minBookingPeriod}
                  periodTypeText={provider.periodTypeText}
                />
              ))}
            </InfiniteScroll>
          ) : (<h6>No records</h6>)}



        </Col>
        <Col className="home-right col-6 overflow-auto h-100 flex-grow-1">
          {
            showMarkerInfoImg && (
              <section className="mapMarkerInfoImg" style={{ width: 'auto !important' }}>
                <ul>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faPlane} color="#0000ff" />
                    Airport
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faShoppingCart} color="#0000ff" />
                    Retail
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faBuilding} color="#f99300" />
                    Private Building
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faPersonBiking} color="#0000ff" />
                    Vehicular
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faBusSimple} color="#ffd700" />
                    Road Site
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faCalendarCheck} color="#0000ff" />
                    Event Center
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faSignsPost} color="orange" />
                    Sign
                  </li>
                  <li style={{ height: "30px" }}>
                    <MarkerPurpleSVG />
                    Static Billboard
                  </li>
                  <li style={{ height: "30px" }}>
                    <MarkerGreenSVG />
                    Digital Billboard
                  </li>
                  <li style={{ height: "30px" }}>
                    <img src={markerImg} alt="" />
                    Portable Signs
                  </li>
                  <li style={{ height: "30px" }}>
                    <MarkerBlueSVG />
                    Place Based Media
                  </li>
                  <li style={{ height: "30px" }}>
                    <MarkerYellowSVG />
                    Transit
                  </li>
                </ul>
              </section>
            )
          }
          <div className="mapMarkerInfo">
            <button
              className="secondaryBtn me-2"
              type="button"
              value="Book portable sign"
              // onClick={()=>{setInitPortableSignBooking(true);}}
              onClick={() => { setShowMarkerInfoImg(!showMarkerInfoImg); }}
            >
              <InfoSVG />
            </button>
          </div>
          <GoPinitMapsSites page="customerByProvider" markers={providerSites.records}
            currentLocation={{ lat: latitude, lng: longitude, zoom: mapZoom }}
            setLat={(param) => dispatch(setLatitude(param))}
            setLng={(param) => dispatch(setLongitude(param))}
            setZoom={(param) => dispatch(setMapZoom(param))}
            setDistance={(param) => dispatch(setMapDistance(param))}
            handleDetailpage={(itemId, listingId, id, type, providerId) => { handleDetailpage(itemId, listingId, id, type, providerId); }}
          />
        </Col>
      </Row>
    </>
  );
};

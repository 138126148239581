import React from "react";
import { InformationPage } from "./InformationPage";
import { ListingPage } from "./ListingPage";
import { UsagePage } from "./UsagePage";
import { InsightsPage } from "./InsightsPage";

export const SwitchPage = ({ activeTab, siteDetails, siteListingDetails }) => {
  switch (activeTab) {
    case "INFORMATION":
      return <InformationPage siteDetails={siteDetails} siteListingDetails={siteListingDetails}/>;
    case "LISTINGS":
      return <ListingPage siteDetails={siteDetails} />;
    case "USAGE":
      return <UsagePage siteDetails={siteDetails} />;
    case "INSIGHTS":
      return <InsightsPage siteDetails={siteDetails} />;
    default:
      return <></>;
  }
};

import React from "react";
import { Col, Row } from "react-bootstrap";
import { PassThroughSVG } from "../../../../../../../assets/svgComponents/inventorySVG";
import {
  FullStarSVG,
  HalfStarSVG,
} from "../../../../../../../assets/svgComponents/svg";
import { UnionSVG } from "../../../../../../../assets/svgComponents/table";
import reviewProfile from "../../../../../../../assets/images/r1.png";
import moment from "moment";
import { getImageUrl } from "../../../../../../../services/utility";

const Review = ({review}) => {
  return (
    <>
      <div className="review_card">
        <Row>
          <Col md={1}>
            <div>
              {
                review.profilePicture ? <img src={getImageUrl(review.profilePicture)} className="review_profile" /> : <img src={reviewProfile} className="review_profile" />
              }    
            </div>
          </Col>
          <Col md={11}>
            <div>
              <div className="d-flex justify-content-between">
                <div className="review_user_name ">{review.name}</div>
                {/* <div className="pe-3">
                  <PassThroughSVG />
                </div> */}
              </div>
              <Row>
                <Col md={6}>
                  <div className="d-flex">
                    <div className="review_posted_date d-flex align-items-center">
                      {moment(review.createdOn).format("DD MMMM YYYY")}
                    </div>
                    
                      {review.rating && review.rating.length>0 && Array.from(Array(review.rating), (e, i) => {
                          return <span className="px-2" key={i}><FullStarSVG /></span>
                      })}
                      
                  </div>
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <p className="siteDetails_desc">
                    {review.comment}
                  </p> 
                </Col>
                <Col md={1}></Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Review;

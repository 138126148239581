import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import { InputField } from "../../../../../components/InputField";
import { InputGroup } from "../../../../../components/InputGroup";
import { UploadFile } from "../../../../../components/UploadFile/UploadFile";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { CrossSVG } from "../../../../../assets/svgComponents/svg";
import {
  getUpdateOnBoardingStepDone,
  resubmitOrganizationDetails,
  uploadFileMethod,
  uploadProof,
  veryfyOrganozaion,
} from "../../../../../services/Provider";
import { UploadMultipleFiles } from "../../../../../components/UploadFile/UploadMultipleFiles";
import { useToasts } from "react-toast-notifications";

export const VeryfiedOrganizationModal = ({
  setShow,
  setConfirmModal,
  setReloadCurrentUser,
  isSendDetailsAgainApi,
  setIsSendDetailsAgainApi,
  currentUserData
}) => {
  //console.log('currentUserData',currentUserData);
  const { addToast } = useToasts();
  const [organizationDetails, setOrganizationDetails] = useState({
    emailId: "",
    taxInfo: "",
    logo: "",
    proof: "",
    providerType: 0,
  });
  const [selectedLogo, setSelectedLogo] = React.useState(null);
  const [selectedProof, setSelectedProof] = useState([]);
  const [logo, setLogo] = useState("");
  const [proof, setProof] = useState("");
  const [upLogo, setUpLogo] = useState(true);
  const [upProof, setUpProof] = useState(true);
  let tempStepCount = parseInt(localStorage.getItem("onBoardingStepDone"));
  const handelUploadLogo = (e) => {
    setSelectedLogo(e.target.files[0]);
  };

  const uploadMultipleProof = (e) => {
    setSelectedProof([...selectedProof, ...e.target.files]);
  };

  useEffect(() => {
    if (selectedLogo) {
      try {
        let formData = new FormData();
        formData.append("file", selectedLogo);
        uploadFileMethod(formData).then((res) => {
          if (res.status == 200) {
            setUpLogo(false);
            setLogo(res.data);
            // setOrganizationDetails((organizationDetails) => ({
            //   ...organizationDetails,
            //   logo: res.data.fileKey,
            // }));
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [selectedLogo]);

  useEffect(() => {
    if (selectedProof.length > 0) {
      try {
        let formData = new FormData();
        formData.append("file", selectedProof[0]);
        uploadProof(formData).then((res) => {
          if (res.status == 200) {
            setUpProof(false);
            setProof(res.data);
            // setOrganizationDetails((organizationDetails) => ({
            //   ...organizationDetails,
            //   proof: res.data.fileKey,
            // }));
          }
        });
      } catch (error) {}
    }
  }, [selectedProof]);

  const formik = useFormik({
    initialValues: {
      email: (isSendDetailsAgainApi?currentUserData?.provider?.emailId:currentUserData?.emailId),
      taxInfo: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email address is required"),
      name: Yup.string().required("Organization name is required"),
    }),
    onSubmit: async (values) => {
      let formData = {
        emailId: values.email,
        taxInfo: values.taxInfo,
        logo: logo.fileKey,
        proof: proof.fileKey,
        proofInfo: proof.fileName,
        providerType: 0,
        name: values.name,
        contactNo: "",
        onBoardingStepDone: 0,
      };
      try {
        !isSendDetailsAgainApi
          ? await veryfyOrganozaion(formData).then((res) => {
              if (res.status == 201) {
                setConfirmModal(true);
                setShow(false);
                setReloadCurrentUser(true);
                // let bodyData = [
                //   {
                //     path: "/onBoardingStepDone",
                //     op: "add",
                //     value: tempStepCount + 1,
                //   },
                // ];
                // getUpdateOnBoardingStepDone(bodyData)
                //   .then((res) => {
                //     console.log("res", res);
                //   })
                //   .catch((err) => {
                //     console.log("err", err);
                //   });
              }
            })
          : await resubmitOrganizationDetails(formData).then((res) => {
              if (res.status == 201) {
                setConfirmModal(true);
                setShow(false);
                setReloadCurrentUser(true);
                // let bodyData = [
                //   {
                //     path: "/onBoardingStepDone",
                //     op: "add",
                //     value: tempStepCount + 1,
                //   },
                // ];
                // getUpdateOnBoardingStepDone(bodyData)
                //   .then((res) => {
                //     console.log("res", res);
                //   })
                //   .catch((err) => {
                //     console.log("err", err);
                //   });
              }
            });
      } catch (error) {
        console.log(error.response.data.error, "error");
        let tmpErr = error.response.data.errors;
        addToast(tmpErr, {
          appearance: "warning",
        });
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="confirmModal">
        <div className="confirmModalOver" onClick={() => setShow(false)}></div>
        <div className="confirmModalInner">
          <div className="confirmModalTop d-flex justify-content-between">
            <h3 className="font-weight-light mb-1 mw-100 p-4 ps-3 confirmModalTopTitle">
              Organization Details
            </h3>
            <span
              className="px-3 py-2 confirmModalTopCross"
              onClick={() => setShow()}
            >
              <CrossSVG />
            </span>
          </div>
          <div className="">
            <div className="mt-4 px-3">
              <InputField
                id="name"
                name="name"
                type="text"
                placeholder="Organization Name"
                label="Organization Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                // disabled={isSendDetailsAgainApi}
              />
            </div>
            <div className="mt-4 px-3">
              <InputField
                id="email"
                name="email"
                type="email"
                placeholder="Email Address"
                label="Email Address"
                value={formik.values.email}
                onChange={formik.handleChange}
                // disabled={isSendDetailsAgainApi}
              />
            </div>
            <div className="mt-4 px-3">
              <InputField
                id="taxInfo"
                name="taxInfo"
                type="text"
                placeholder="Tax info"
                label="Tax info"
                value={formik.values.taxInfo}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mt-4 px-3">
              <UploadFile
                id="logo"
                uploadBoxSize="uploadBoxSize"
                btnlable="Upload Logo"
                handelUploadLogo={handelUploadLogo}
                placeholder={logo ? logo.fileName : "No file attached"}
                allowedFiles="image/png, image/gif, image/jpeg"
              />
            </div>
            <div className="mt-4 mb-5 px-3">
              <UploadFile
                id="Proof"
                uploadBoxSize="uploadBoxSize"
                btnlable="Upload Government ID"
                handelUploadLogo={uploadMultipleProof}
                placeholder={proof ? proof.fileName : "No file attached"}
              />
              {/* <UploadMultipleFiles
                id="proof"
                uploadBoxSize="uploadBoxSize"
                btnlable="Upload Proof"
                uploadMultipleProof={uploadMultipleProof}
                placeholder={proof ? proof.fileName : "No File Attach"}
                uploadedData = {selectedProof}
              /> */}
            </div>
          </div>
          <div className="confirmModalBottom border-top">
            <div className="row no-gutters g-0">
              <>
                <div className="col">
                  <button
                    className="btn btn-success btn-block border-0  w-100 confirmModalConfirmBtn"
                    type="submit"
                    disabled={upProof || upLogo || !formik.values.email || !formik.values.taxInfo}
                  >
                    <span className="text-md">Send</span>
                  </button>
                </div>
                {/* <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-block border-0  w-100 confirmModalCancelBtn"
                  >
                    <span className="text-md">Cancel</span>
                  </button>
                </div> */}
              </>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

import React from "react";
import {
  HeartSVG,
  FullStarSVG,
  SVGFavorite,
  SVGNotFavorite,
} from "../../../../../../../assets/svgComponents/svg";
import Slider from "react-slick";
import { PlusSVG } from "../../../../../../../assets/svgComponents/table";
import { getImageUrl } from "../../../../../../../services/utility";
import { unfavoriteAsync } from "../../../../../../../services/reducers/customer-reducer";

import {
  getListItemDetailsAsync,
  getSimilarListingAsync,
  getListingReviewsAsync,
  getAvailableOffersForProviderAsync,
  emptyListingState,
  markAsUnFavoriteAsync,
  markAsFavoriteAsync,
} from "../../../../../../../services/reducers/listing-reducer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SimilarListing = ({ similarListing, currentListingId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: 2,
    // slidesToScroll: 2,
    variableWidth: true,
    // initialSlide: 0,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const markAsFavorite = (item) => {
    const reqBody = {
      siteId: null,
      signId: null,
      listingItemId: null,
      providerId: null,
      type: item.type,
      title: item.title,
    };

    if (item.type === "provider") {
      reqBody.providerId = item.id;
    } else {
      reqBody.listingItemId = item.itemId;
    }

    if (item.isFavorite) {
      if (item.cartType === "favorite") {
        dispatch(unfavoriteAsync(reqBody));
        addToast(
          `${item.itemType} ${item.listingName} marked as unfavourite successfully`,
          {
            appearance: "success",
          }
        );
        navigateToSiteDetails(item);
      } else {
        dispatch(markAsUnFavoriteAsync(reqBody));
        addToast(
          `${item.itemType} ${item.listingName} marked as unfavourite successfully`,
          {
            appearance: "success",
          }
        );
      }
      navigateToSiteDetails(item);
    } else {
      dispatch(markAsFavoriteAsync(reqBody));
      addToast(
        `${item.itemType} ${item.listingName} marked as favourite successfully`,
        {
          appearance: "success",
        }
      );
      navigateToSiteDetails(item);
    }
  };

  const navigateToSiteDetails = (item) => {
    console.log("listing details:", item);
    dispatch(emptyListingState());
    dispatch(getListItemDetailsAsync(item.itemId));
    dispatch(getSimilarListingAsync(item.itemId));
    dispatch(getListingReviewsAsync(item.listingId));
    // console.log('getting offers of site', id);
    dispatch(getAvailableOffersForProviderAsync(item.providerId));

    // history.replace("/customer/site-detail");
  };

  return (
    <div className="w-100 m-0">
      {" "}
      <Slider {...settings}>
        {similarListing &&
          similarListing.records &&
          similarListing.records.map((item, index) => {
            if(item.id !== currentListingId){
              return (
                <div className="mainCard" key={"sl_item_" + index}>
                  <div
                    className="card"
                    key={item.itemId + "_sim_list"}
                    style={{ margin: 10 }}
                  >
                    <div className="row g-0">
                      <div className="col-md-4">
                        <div
                          className="featuredImg"
                          onClick={() => navigateToSiteDetails(item)}
                        >
                          <img
                            src={getImageUrl(item.image)}
                            className="homeCardImage"
                            alt="site pic"
                          />
                        </div>
                      </div>

                      <div className="col-md-8">
                        <div className="card-body">
                          <div className="row g-0">
                            <div
                              className="col-md-11"
                              onClick={() => navigateToSiteDetails(item)}
                            >
                              <div className="card-title h5">
                                {item.listingName}{" "}
                              </div>
                            </div>
                            <div
                              className="col-md-1 text-end"
                              onClick={() => markAsFavorite(item)}
                            >
                              <span className="">
                                {item.isFavorite ? (
                                  <SVGFavorite />
                                ) : (
                                  <SVGNotFavorite />
                                )}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="card-text fw-bold">
                            {item.location}
                          </div>
                          <div className="row">
                            <div className="col-md-8 pe-0">
                              <div className="card-text">
                                {/* by {item.provider} · 100x100 Billboards{" "} */}
                                by {item.provider} · {item.signTitle}
                              </div>
                            </div>

                            {/* <div className="col-md-4 ps-0">
                          <p className="text-tertiary-600 text-xxs fw-bold">
                            {" "}
                            <PlusSVG width={9} height={10} fill="#F99300" /> 3
                            more
                          </p>
                        </div> */}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <p className="card-text">
                                <FullStarSVG /> <b> {item.rating}</b>
                                <span className="reviews">
                                  ({item.reviews} reviews)
                                </span>{" "}
                              </p>
                            </div>
                            <div className="startLabels text-end col-md-6">
                              <label className="label1 text-xxs">
                                Starting{" "}
                              </label>
                              &nbsp;
                              <label className="day-no">${item.price}</label>
                              {item?.availableFor === 1 && (
                                <label className="label2 text-xxs">
                                  / {item.minBookingPeriod} week
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              )}
          })}
      </Slider>
    </div>
  );
};

export default SimilarListing;

import { http } from "../httpService";

export function getReviewBySign(signId) {
  return http.get(`/api/Review/Site/${signId}`);
}

export function getReviewByListing(listingId) {
  return http.get(`/api/Review/Listing/${listingId}`);
}

export function deleteBySign(id) {
  return http.delete(`/api/Review/${id}`);
}
export function getReviewByUser(userId) {
  return http.get(`/api/Review/User/${userId}`);
}
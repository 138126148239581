import { http } from "../../httpService";

export function getAllOrders(reqBody) {
  return http.post(`/api/Order/List`, reqBody);
}

export function getAllTransactions(reqBody) {
  return http.post(`/api/Order/ProviderTransactions`, reqBody);
}

export function getTransactionType(name) {
  return http.get(`/api/Order/${name}/Options`);
}

export function getOrderDetails(id) {
  return http.get(`/api/Order/Details/${id}`);
}
export function updateOrderStatus(reqBody) {
  return http.post(`/api/Order/OrderStatusForProvider`,reqBody);
}

export function cancelOrder(id) {
  return http.post(`/api/Order/${id}/CancelOrder`);
}

export function getOrderRefundAmount(id) {
  return http.get(`/api/Order/GetOrderRefundAmount/${id}`);
}

export function rejectRefund(reqBody) {
  return http.post(`/api/Order/RejectRefundRequest`, reqBody);
}
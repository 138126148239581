import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Tabs from "./Tabs/Tabs";
import { GoPinitMaps } from "../../../../components/Maps/GoPinitMaps";
import { setPortableSignLocation, getLocationNameAsync, getListBySignsAsync, getListItemDetailsAsync, getSimilarListingAsync, getListingReviewsAsync, getAvailableOffersForProviderAsync, getSelectedFilters, setShowOnlyFeatured, setLongitude, setLatitude, setMapZoom, setSearchSection, setMapDistance } from "../../../../services/reducers/listing-reducer";
import { useDispatch, useSelector } from "react-redux";
import { ListingItemCard } from "./ListingItemCard";
import { ListingFilters } from "./ListingFilters";
import { GoPinitMapsSites } from "../../../../components/Maps/GoPinitMapsSites";
import { getRadiusOfOperation } from "../../../../services/utility";
import { BookPortableSignModal } from "./BookPortableSignModal";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  InfoSVG, MarkerBlueSVG, MarkerGreenSVG, MarkerPurpleSVG, MarkerYellowSVG,
} from "../../../../assets/svgComponents/svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlane,
  faCalendarCheck,
  faBusSimple,
  faMapMarker,
  faBuilding,
  faShoppingCart,
  faPersonBiking,
  faSignsPost,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import markerImg from "../../../../assets/images/markerImg.png";
import markersInfoImg from "../../../../assets/images/markersInfo.png";

export const BySigns = ({ favorite, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const listBySigns = useSelector((state) => state.listing.listBySigns);
  const { portableSignInfo, listingFilterMedia, listingFilterEnvironment, portableSignsListing, locationName, portableSignLocation, portableSignRadiusFilter } = useSelector((state) => state.listing);
  const { media, environment, onlyFeatured, minCost, maxCost, fromDate, uptoDate, longitude, latitude, mapZoom, searchTerm, mapDistance } = useSelector((state) => getSelectedFilters(state.listing));

  const [showBookPortableSign, setShowBookPortableSign] = useState(false);
  const [showMarkerInfoImg, setShowMarkerInfoImg] = useState(false);
  const [initPortableSignBooking, setInitPortableSignBooking] = useState(false);


  const handleDetailpage = (itemId, listingId, id, type, providerId) => {
    console.log('listing details:', type, id, itemId, listingId);
    dispatch(getListItemDetailsAsync(itemId));
    dispatch(getSimilarListingAsync(itemId));
    dispatch(getListingReviewsAsync(listingId));
    dispatch(getAvailableOffersForProviderAsync(providerId));
    //history.push(`/customer/site-detail/${listingItemId}`);
    history.push(`/customer/sign-detail`);
  };


  const onFeaturedChange = (value) => {
    dispatch(setShowOnlyFeatured(value));
  };

  useEffect(() => {
    getListing();
  }, [page]);

  const getListing = () => {
    let mediaTypes = [...media];
    if (portableSignsListing) {
      if (!mediaTypes) mediaTypes = [];
      mediaTypes.push(2);
      mediaTypes.push(3);
      mediaTypes.push(4);
    }
    const reqBody = {
      page: page,
      pageSize: 10,
      orderBy: "",
      export: false,
      searchString: searchTerm,
      userLocation: "",
      costs: [],
      mediaTypes: mediaTypes,
      environmentIds: environment,
      dates: [],
      onlyFeatured: onlyFeatured,
      listingType: 1,
      providerId: "",
      latitude: portableSignInfo ? portableSignInfo.latitude : latitude,
      longitude: portableSignInfo ? portableSignInfo.longitude : longitude,
      radiusOfOperation: portableSignInfo ? (portableSignRadiusFilter ? portableSignRadiusFilter : 100000) : (mapDistance ? mapDistance : getRadiusOfOperation(latitude, mapZoom)),
    };
    if (portableSignInfo && portableSignInfo.selectedSignIds) {
      reqBody.page = 0;
      reqBody.ids = portableSignInfo.selectedSignIds;
      reqBody.mediaTypes = [2, 3, 4];
    }
    if (minCost != null && maxCost) {
      reqBody.costRange = { min: minCost, max: maxCost };
    }
    if (fromDate && uptoDate) {
      reqBody.dateRange = { fromDate, uptoDate };
    }
    dispatch(getListBySignsAsync(reqBody));
  }

  // useEffect(() => {
  //   if (portableSignsListing) {
  //     if (page !== 1) {
  //       setPage(1);
  //     } else {
  //      getListing();
  //     }
  //   }
  // }, [portableSignsListing,portableSignInfo]);

  useEffect(async () => {
    if (page !== 1) {
      setPage(1);
    } else {
      getListing();
    }
  }, [dispatch, minCost, fromDate, uptoDate, onlyFeatured, listingFilterMedia, listingFilterEnvironment, latitude, longitude, mapZoom, searchTerm, portableSignsListing, portableSignInfo]);

  const handleListing = (card) => {
  };


  const handlePortableSignSubmitBtn = () => {
    setShowBookPortableSign(false);
  };





  return (
    <>
      <Row className="home-container gridSection g-0">
        <Col id="pnl-list-by-signs" className={listBySigns.totalRecords > 0 ? "home-left col-6 overflow-auto h-100 flex-grow-1 scrollOverBottom" : "home-left col-6 overflow-auto h-100 flex-grow-1 scrollOverBottomDone"}>
          <Row className="home-section1 row px-1 align-items-center g-0 listingFiltersRow">
            <ListingFilters />
          </Row>
          <div className="home-section2">
            <Tabs
              featured={onlyFeatured}
              tabs={rest.tabs}
              activeTab={rest.activeTab}
              setActiveTab={rest.setActiveTab}
              setFeatured={(value) => onFeaturedChange(value)}
            />
          </div>

          {listBySigns.records.length > 0 ? (
            <InfiniteScroll dataLength={listBySigns.records.length - 1}
              hasMore={true}
              next={() => setPage(page + 1)}
              scrollableTarget="pnl-list-by-signs"
              // loader={<h6>Loading...</h6>}
              hasChildren={listBySigns.records.length > 0}
            >
              {listBySigns.records.map((sign, index) => (
                <ListingItemCard
                  key={sign.itemId + '_sign'}
                  id={sign.id}
                  itemId={sign.itemId}
                  listingId={sign.listingId}
                  providerId={sign.providerId}
                  type="sign"
                  title={sign.listingName}
                  //subtitle="Devlivery by 30 apr 2022"
                  subtitle={sign.name}
                  text={sign.description}
                  startingPrice={sign.price ? parseFloat(sign.price).toFixed(2) : sign.price}
                  rating={sign.rating}
                  reviews={sign.reviews}
                  isFavorite={sign.isFavorite}
                  image={sign.image}
                  moreBillboards={sign.moreBillboards}
                  isFeatured={sign.featured}
                  formats={sign.formats}
                  isProvider={false}
                  providerName={sign.provider}
                  handleListing={handleListing}
                  handleDetailpage={(itemId, listingId, id, type, providerId) => { handleDetailpage(itemId, listingId, id, type, providerId); }}
                  card={sign}
                  cartType={"sign"}
                  minBookingPeriod={sign.minBookingPeriod}
                  periodTypeText={sign.periodTypeText}
                />
              ))}
            </InfiniteScroll>
          ) : (<h6>No records</h6>)}

        </Col>
        <Col className="home-right col-6 overflow-auto h-100 flex-grow-1">
          <BookPortableSignModal
            setShow={setShowBookPortableSign}
            show={showBookPortableSign}
            footerText="Submit"
            title="Portable Sign Details"
            handlefooterBtn={handlePortableSignSubmitBtn}
            key="RefundOrderModal"
            handleDetailpage={handleDetailpage}
          />
          {true && (
            <div className="confirmbx">
              <span>Looking for a portable sign ?</span>
              <button
                className="secondaryBtn me-2"
                type="button"
                value="Book portable sign"
                // onClick={()=>{setInitPortableSignBooking(true);}}
                onClick={() => { setShowBookPortableSign(true); }}
              >
                Go Pin It
              </button>
            </div>
          )}
          {
            showMarkerInfoImg && (
              <section className="mapMarkerInfoImg" style={{ width: 'auto !important' }}>
                <ul>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faPlane} color="#0000ff" />
                    Airport
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faShoppingCart} color="#0000ff" />
                    Retail
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faBuilding} color="#f99300" />
                    Private Building
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faPersonBiking} color="#0000ff" />
                    Vehicular
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faBusSimple} color="#ffd700" />
                    Roadside
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faCalendarCheck} color="#0000ff" />
                    Event Centre
                  </li>
                  <li style={{ height: "30px" }}>
                    <FontAwesomeIcon icon={faSignsPost} color="orange" />
                    Sign
                  </li>
                  <li style={{ height: "30px" }}>
                    <MarkerPurpleSVG />
                    Static Billboard
                  </li>
                  <li style={{ height: "30px" }}>
                    <MarkerGreenSVG />
                    Digital Billboard
                  </li>
                  <li style={{ height: "30px" }}>
                    <img src={markerImg} alt="" />
                    Portable Signs
                  </li>
                  <li style={{ height: "30px" }}>
                    <MarkerBlueSVG />
                    Place Based Media
                  </li>
                  <li style={{ height: "30px" }}>
                    <MarkerYellowSVG />
                    Transit
                  </li>
                </ul>
              </section>
            )
          }
          <div className="mapMarkerInfo">
            <button
              className="secondaryBtn me-2"
              type="button"
              value="Book portable sign"
              // onClick={()=>{setInitPortableSignBooking(true);}}
              onClick={() => { setShowMarkerInfoImg(!showMarkerInfoImg); }}
            >
              <InfoSVG />
            </button>
          </div>


          <GoPinitMapsSites page="customerBySigns"
            listingType={"sign"}
            markers={listBySigns.records}
            currentLocation={{ lat: latitude, lng: longitude, zoom: mapZoom }}
            setLat={(param) => dispatch(setLatitude(param))}
            setLng={(param) => dispatch(setLongitude(param))}
            setZoom={(param) => dispatch(setMapZoom(param))}
            setDistance={(param) => dispatch(setMapDistance(param))}
            handleDetailpage={(itemId, listingId, id, type, providerId) => { handleDetailpage(itemId, listingId, id, type, providerId) }}
            initPortableSignBooking={initPortableSignBooking}
            handlePinItForPortableSign={(loc) => {
              // dispatch(setLatitude(loc.lat));
              // dispatch(setLongitude(loc.lng)); 
              // dispatch(getLocationNameAsync({ lat: loc.lat, lng:loc.lng}));  
              setShowBookPortableSign(true);
            }}
            handelSearchNewLocation={() => {
              setInitPortableSignBooking(false);
              dispatch(setSearchSection({ id: 'location', name: "Location" }));
              document.getElementById("globalSearchBox").focus();
            }}
          />
        </Col>
      </Row>
    </>
  );
};

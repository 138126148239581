import React from "react";
import { CrossSVG } from "../../../../../assets/svgComponents/svg";
import "./offermanagement.scss";


export const SelectIdCardName = ({id,name,onClick}) => {
  return (
    <div className="idbox m-1">
      <h6 className="mt-2">{name&&name.substring(0,5)}</h6>
      <div style={{ marginTop: "-4px", cursor: "pointer" }} onClick={(e)=>onClick(e,id)}>
        <CrossSVG height="13" width="13" />
      </div>
    </div>
  );
};

import React from "react";
import { Col, Row } from "react-bootstrap";
import { UploadPlus } from "../../../../../../../../../assets/svgComponents/inventorySVG";
import { AtmCard } from "../../../../../../../../../components/AtmCard/AtmCard";
import { AtmWithRadioBtn } from "../../../../../../../../../components/AtmCard/AtmWithRadioBtn";
import AddNewCardModal from "../../../../../../../../../components/profile/Modal/AddNewCardModal";
import { makeDefoulPaymentCard } from "../../../../../../../../../services/Provider";
import {
  getRecentTopFiveCard,
  updateDefaultAddress,
  updateDefaultCard,
} from "../../../../../../../../../services/Provider/Configurator/Listings";

const paymentModeTabs = [
  "Credit/Debit Card",
  // "Net Banking",
  // "Subscription Plan",
];
const PaymentMode = ({ setCardId }) => {
  const [activeTab, setActiveTab] = React.useState(paymentModeTabs[0]);
  const [cardDetails, setCardDetails] = React.useState([]);
  const [addNewCardModal, setAddNewCardModal] = React.useState(false);
  const [isCardDetailsPost, setIsCardDetailsPost] = React.useState(false);
  const [defaultCardChange, setDefaultCardChange] = React.useState(false);
  const [defoultaddressConfirmModal, setDefoultaddressConfirmModal] =
    React.useState();
  React.useEffect(async () => {
    await getRecentTopFiveCard().then((res) => {
      if (res.status == 200) {
        let tempDefaultData = res.data?.filter((d) => d.isDefault && d.id);
        setCardId(tempDefaultData);
        setCardDetails(res.data);

        setDefaultCardChange(false);
      }
    });
  }, [defoultaddressConfirmModal, isCardDetailsPost]);
  const handleChangeDefaultCard = async (id) => {
    await updateDefaultCard(id)
      .then((res) => {
        setDefaultCardChange(true);

        // if (res.status ==) {
        // }
      })
      .catch((err) => console.log(err));
  };

  const makeDefoultCard = (card) => {
    makeDefoulPaymentCard(card.id)
      .then((res) => {
        if (res.status == 200) {
          setDefoultaddressConfirmModal(!defoultaddressConfirmModal);
        }
      })
      .catch((err) => console.log("err", err));
  };
  //   const templocalStorage = localStorage.getItem("onBoardingStepDone");
  //   console.log(parseInt(templocalStorage), "templocalStorage");
  //   parseInt(tempLocalStorage) >= 3 ? tempLocalStorage : 3;
  //   let bodyData = [
  //     {
  //       path: "/onBoardingStepDone",
  //       op: "add",
  //       value: "3",
  //     },
  //   ];
  //   getUpdateOnBoardingStepDone(bodyData)
  //     .then((res) => {
  //       console.log("res", res);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // }

  const handleNewCard = () => {
    setAddNewCardModal(true);
  };
  return (
    <>
      <div className="mt-3 ">
        <h6 className="px-4">Choose Payment Mode</h6>
        <div className="paymentMethodContainer">
          <Row className="g-0">
            <Col className="col-md-3 paymentMethodSection  mt-4">
              {paymentModeTabs &&
                paymentModeTabs.map((item) => {
                  return (
                    <div
                      className={` navtab paymentmode_active mb-3 ${
                        activeTab == "Credit/Debit Card" && " active "
                      }`}
                    >
                      <p>{item}</p>
                    </div>
                  );
                })}
            </Col>
            <Col className=" ms-3 mt-4 atmCardSection">
              <div className="cardContainer row">
                {cardDetails &&
                  cardDetails.map((card, index) => {
                    return (
                      <AtmCard
                        card={card}
                        // editCardDetails={editCardDetails}
                        // deteletePaymentCard={deteletePaymentCard}
                        index={index}
                        makeDefoultCard={makeDefoultCard}
                        sectionClassName={"col-md-6"}
                        pt32ClassName={"pt-32"}
                      />
                    );
                  })}
                <div className="col-md-6 ">
                  <div className="col-md-12 card1 uploadCard borderAll bg-base-100 d-flex justify-content-center align-items-center">
                    <div className="cardBody" onClick={() => handleNewCard()}>
                      <UploadPlus />
                      <p>Add New Card</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-between">
                {cardDetails?.map((item, index) => (
                  <AtmWithRadioBtn
                    item={item}
                    index={index}
                    handleChangeDefaultCard={handleChangeDefaultCard}
                  />
                ))}
              </div>
              <div className="cardContainer_1 mt-3">
                <div
                  className="insideContainer"
                  onClick={() => handleNewCard()}
                >
                  <UploadPlus />
                  <p className="textbox">Add New Card</p>
                </div>
              </div> */}
            </Col>
          </Row>
        </div>
      </div>
      {/* add new card */}
      {addNewCardModal && (
        <AddNewCardModal
          setShow={setAddNewCardModal}
          title={"Add New Card"}
          setIsCardDetailsPost={setIsCardDetailsPost}
          isCardDetailsPost={isCardDetailsPost}
        />
      )}
    </>
  );
};

export default PaymentMode;

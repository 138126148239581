import React from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import "./input-custom.scss";

export const InputField = ({
  value,
  name,
  onBlur,
  onChange,
  placeholder,
  label,
  inputFieldSize,
  disabled,
  type,
  touched,
  errors,
  onFocus,
  required,
  min,
  max,
  message,
  inputClassName,
  labelClassName,
  defaultValue,
  style
}) => {
  return (
    <>
      <FloatingLabel
        controlId="floatingInput"
        label={label}
        className={'floatingLabel ' + (labelClassName ? labelClassName : '')} // ${inputFieldSize}
      >
        <Form.Control
          type={type}
          name={name}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          className={inputClassName?'inputField '+inputClassName:'inputField'}//
          placeholder={placeholder}
          onBlur={onBlur}
          disabled={disabled}
          // disabled={isDisabled}
          required={required}
          maxLength={max}
          style={style}

          //   className={`${
          //     formik.values.email.length !== 0 ? "valid " : ""
          //   } ${emailAddress && "inputError"}`}
        />
      </FloatingLabel>

      {touched && errors ? (
        <Form.Text className="text-danger">{errors}</Form.Text>
      ) : null}
    </>
  );
};

import React from "react";
import PlacesAutocomplete, { geocodeByAddress,
    getLatLng,} from "react-places-autocomplete";
import scriptLoader from "react-async-script-loader";
import { apiKey } from "../../../../../../../services/Constants";

function AutoCompleteSerchBox({ isScriptLoaded, isScriptLoadSucceed }) {
  const [address, setAddress] = React.useState("");

  const handleChange = (value) => {
    setAddress(value)
  }

  const handleSelect = (value) => {
    geocodeByAddress(address)
    .then(results => getLatLng(results[0]))
    .then(latLng => console.log('Success', latLng))
    .catch(error => console.error('Error', error));
  }
  console.log('address', address)

  if (isScriptLoaded && isScriptLoadSucceed) {
    return (
      <div className="floatingLabel form-floating">
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input className="form-control inputField"
                {...getInputProps({
                  placeholder: "Location...",
                })}
              />
              <div>
                {/* {loading && <div>Loading...</div>} */}
                {suggestions.map((suggestion) => {
                    console.log('first', suggestion)
                  const style = suggestion.active
                    ? { backgroundColor: "#a83232", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };

                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${apiKey.GOOGLE_API_KEY}&libraries=places`,
])(AutoCompleteSerchBox);
import { http } from "../httpService";

export function refundOrderRequest(reqBody) {
    return http.post(`/api/Order/RefundRequest`,reqBody);
}

export function refundOrderAmount(reqBody) {
    return http.post(`/api/Order/RefundOrderAmount`,reqBody);
}

export function getProvidersForFilters(reqBody) {
    return http.post(`/api/Provider/List`,reqBody);
}

export function getAllOrders(reqBody) {
    return http.post(`/api/Order/List`, reqBody);
}
  
export function getAllTransactions(reqBody) {
  return http.post(`/api/Order/ProviderTransactions`, reqBody);
}

export function getTransactionType(name) {
  return http.get(`/api/Order/${name}/Options`);
}

export function getOrderDetails(id) {
  return http.get(`/api/Order/Details/${id}`);
}

export function updateOrderStatus(reqBody) {
  return http.post(`/api/Order/OrderStatusForProvider`,reqBody);
}

export function extendOrderAmount(reqBody) {
  return http.post(`/api/Order/GetExtendOrderAmount`, reqBody);
}

export function extendOrder(reqBody) {
  return http.post(`/api/Order/ExtendOrder`, reqBody);
}

export function addListingReview(listingId,reqBody){
  return http.post(`/api/Review/Listing/${listingId}`,reqBody);
}

export function updateReview(reviewId,reqBody){
  return http.patch(`/api/Review/${reviewId}`,reqBody);
}

export function releaseOrderPayout(reqBody){
  return http.post(`/api/Payout`,reqBody);
}
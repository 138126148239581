/*global google*/
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Card, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import * as Yup from "yup";
import NavBar from "../../../../../../../components/navBar";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import { FloatingLableSearchBar } from "../../../../../../../components/SearchBar/FloatingLableSearchBar";
import { UploadFile } from "../../../../../../../components/UploadFile/UploadFile";
import { TextAria } from "../../../../../../../components/TextAria";
import { BtnSwitch } from "../../../../../../../components/BtnSwitch/BtnSwitch";
import { CreateListModal } from "./CreateListModal";
import { InputField } from "../../../../../../../components/InputField";
import { AddMap } from "../../../../../../../components/Maps/sitePage/AddMap";
import { AddSitePageMap } from "../../../../../../../components/Maps/sitePage/AddSitePageMap";
import mapStyles from "./MapStyles";
import PlacesAutocomplete from "react-places-autocomplete";
import UpdatesModal from "../../../../../../../components/profile/Modal/UpdatesModal";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { useToasts } from "react-toast-notifications";
import { GetOnlyAddressFromCompoundCode } from "../../../../../../../services/utility";

import {
  addSite,
  getEnvironment,
  uploadFileMethod,
  getEnvironmentType,
  getUpdateOnBoardingStepDone,
} from "../../../../../../../services/Provider";
import { number } from "yup/lib/locale";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import ProjectContext from "../../../../../../../context/ProjectContext";
import { getAllTaxation } from "../../../../../../../services/Provider/StateTaxation/StateTaxation";
import AutoCompleteSerchBox from "../AutoCompleteSerchBox/AutoCompleteSerchBox";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { UploadMultipleFiles } from "../../../../../../../components/UploadFile/UploadMultipleFiles";
import { getAddressByLatLng } from "../../../../../../../services/api/customer-store-api";
import { checkPermission } from "../../../../../../../utils";

export const AddSitePage = ({ provider }) => {
  const { handleActiveTab } = useContext(ProjectContext);
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  // console.log("history", history);
  // console.log("location", location);
  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const [getEnviroment, setGetEnviroment] = useState([]);
  const [uploadImage, setUploadImage] = useState([]);
  const [uploadCollatralImage, setUploadCollatralImage] = useState([]);
  const [environmentId, setEnvironmentId] = useState();
  const [description, setDescription] = useState("");
  const [siteConfirmModal, setSiteConfirmModal] = useState(false);
  const [availability, setAvailability] = useState([]);
  const [priceTiype, setPriceTiype] = useState([]);
  const [durability, setDurability] = useState([]);
  const [taxation, setTaxation] = useState([]);
  const [taxationId, setTaxationId] = useState();
  const [searchBox, setSearchBox] = useState(null);
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [center, setCenter] = useState({ lat: lat, lng: lng });
  const [loc, setLoc] = useState("");
  const [siteListing, setSiteListing] = useState(false);
  const [imageValidate, setImageValidate] = useState();
  const [collateralValidate, setCollateralValidate] = useState();
  const [locationValidate, setLocationValidate] = useState();
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);

  let tempStepCount = parseInt(localStorage.getItem("onBoardingStepDone"));
  const handleOnCancel = () => {
    provider
      ? history.goBack("/provider")
      : history.push("/provider/configurator/inventory");
  };
  const handleSiteConfirmModal = () => {
    setSiteConfirmModal(false);
    provider
      ? history.goBack("/provider")
      : history.push("/provider/configurator/inventory");
  };
  useEffect(() => {
    getEnvironment().then((res) => {
      if (res.status == 200) {
        setGetEnviroment(res.data);
        setEnvironmentId(res.data[0].id);
      }
      //console.log("resEnvironMn", res);
    });
    const getMyLocation = () => {
      const location = window.navigator && window.navigator.geolocation;
      if (location) {
        location.getCurrentPosition((position) => {
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      }
    };
    getMyLocation();
  }, []);

  useEffect(() => {
    getEnvironmentType("PriceTypes")
      .then((res) => {
        if (res.status) {
          setPriceTiype(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    getEnvironmentType("PeriodTypes")
      .then((res) => {
        if (res.status === 200) {
          setDurability(res.data);
        }
        // console.log("PeriodTypes", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
    getEnvironmentType("Availabilities")
      .then((res) => {
        if (res.status === 200) {
          setAvailability(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    getAllTaxation()
      .then((res) => {
        if (res.status === 200) {
          setTaxation(res.data);
          setTaxationId(res.data[0].stateName)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const handlEnvironment = (e) => {
    let id = e.target.value;
    setEnvironmentId(id);
  };

  const handleTaxation = (e) => {
    let id = e.target.value;
    setTaxationId(id);
  };

  const handleDescription = (e) => {
    let des = e.target.value;
    setDescription(des);
  };

  const handelUploadImage = (e) => {
    // let data = e.target.files[0];
    // let image = new FormData();
    // image.append("file", data);
    // setImageValidate("");
    // uploadFileMethod(image)
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setUploadImage(res.data);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
      for (let i = 0; i < e.target.files.length; i++) {
        const data = e.target.files[i];
        let image = new FormData();
        image.append("file", data);
        setImageValidate("");
        uploadFileMethod(image)
          .then((res) => {
            if (res.status == 200) {
              setUploadImage((UploadImage) => [
                ...UploadImage,
                res.data,
              ]);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
  };

  const handelUploadCollaralImage = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const data = e.target.files[i];
      let image = new FormData();
      image.append("file", data);
      setCollateralValidate("");
      uploadFileMethod(image)
        .then((res) => {
          if (res.status == 200) {
            setUploadCollatralImage((uploadCollatralImage) => [
              ...uploadCollatralImage,
              res.data,
            ]);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      emailId: "",
      siteLocation: {
        googleAddress: "",
        latitude: {lat},
        longitude: {lng},
        //radiusOfOperation: "",
      },
      //radious: "",
      environmentId: "",
      des: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().min(5, "Too short!").required(),
      //emailId: Yup.string().email().required(),
      // radious: Yup.string()
      //   .label('Radious')
      //   .required(),
    }),
    onSubmit: (values) => {
      try {
        let error = false;

        /*
        setImageValidate("");
        setCollateralValidate("");
        if(!uploadImage || !uploadImage.fileKey){          
            setImageValidate("Please upload Image.");
            error = true;




            
        }
        if(!uploadCollatralImage || !uploadCollatralImage.fileKey){
            setCollateralValidate("Please upload collateral.");
            error = true;
        }
      */
        if (values.siteLocation.googleAddress === "") {
          setLocationValidate("Please select location.");
          error = true;
        } else if (
          values.siteLocation.latitude === "" ||
          values.siteLocation.longitude === ""
        ) {
          setLocationValidate("Please select location on map.");
          error = true;
        }

        if (!error) {
          let formdata = {
            name: values.name.toString().trim(),
            description: description.toString().trim(),
            emailId: "",
            siteLocation: {
              googleAddress: values.siteLocation.googleAddress,
              latitude: values.siteLocation.latitude,
              longitude: values.siteLocation.longitude,
              radiusOfOperation: 0, //parseInt(values.radious, 10),
            },
            environmentId: environmentId,
            image: uploadImage.length > 0
            ? uploadImage.map((val) => {
                return val.fileKey
              })
            : [],
            stateName: taxationId,
            collateralImage:
              uploadCollatralImage.length > 0
                ? uploadCollatralImage.map((val) => {
                    return {
                      collateral: val.fileKey,
                      collateralInfo: val.fileName,
                    };
                  })
                : [],
          };

          if (siteListing) {
            formdata = {
              ...formdata,
              addSiteInListing: true,
              listingDetails: {
                minBookingPeriod: 1,
                availableFor: listingDetails.availableFor,
                pricingType: listingDetails.pricingType,
                applicableDuring: listingDetails.applicableDuring,
                price: listingDetails.price,
              },
            };
          } else {
            formdata = { ...formdata, addSiteInListing: false };
          }
          /*
        formdata = {
          name: values.name.toString().trim(),
          description: description.toString().trim(),
          emailId: values.emailId,
          siteLocation: {
            googleAddress: values.siteLocation.googleAddress,
            latitude: values.siteLocation.latitude,
            longitude: values.siteLocation.longitude,
            radiusOfOperation: parseInt(values.radious, 10),
          },
          environmentId: environmentId,
          addSiteInListing: false,
          image: [uploadImage.fileKey],
          stateName: taxationId,
          collateralImage: [
            {
              collateral: uploadCollatralImage.fileKey,
              collateralInfo: "xxx.jpg",
            },
          ],
          listingDetails: {
            availableFor: listingDetails.availableFor,
            pricingType: listingDetails.pricingType,
            applicableDuring: listingDetails.applicableDuring,
            price: listingDetails.price,
          },
        };
      */

          addSite(formdata)
            .then((res) => {
              if (res.status == 201) {
                addToast("Site has been added successfully.", {
                  appearance: "success",
                });
                //setSiteConfirmModal(true);
                localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
                let bodyData = [
                  {
                    path: "/onBoardingStepDone",
                    op: "add",
                    value: tempStepCount + 1,
                  },
                ];
                getUpdateOnBoardingStepDone(bodyData)
                  .then((res) => {})
                  .catch((err) => {
                    console.log("err", err);
                  });
                history.push("/provider/configurator/inventory");
              }
            })
            .catch((err) => {
              console.log("erraddsite", err);
              //addToast(err.response.data.error, { appearance: "error" });
            });
        }
      } catch (err) {
        console.log("error", err);
      }
    },
  });
  // const mediaName = formik.touched.media && formik.errors.media;
  useEffect(() => {}, [loc]);
  const handleToggle = (e) => {
    setShowCreateListModal(e.target.checked);
    setSiteListing(e.target.checked);
  };

  const [listingDetails, setlistingDetails] = useState({
    availableFor: "1",
    pricingType: "1",
    applicableDuring: "0",
    price: "0",
  });

  const getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition((position) => {
        //this.props.change("latitude", position.coords.longitude);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
    console.log(lat, 'lat');
    console.log(lng, 'lng');
  };

  useEffect(() => {
    getMyLocation();
  }, []);

  const onSBLoad = (searchData) => {
    //searchBox = searchData;
    setSearchBox(searchData);
  };

  const onPlacesChanged = () => {
    const markerArray = [];
    let results = searchBox.getPlaces();
    for (let i = 0; i < results.length; i++) {
      let place = results[i].geometry.location;
      markerArray.push(place);
    }

    markerArray.map((mark, index) => setCenter(mark));
    //getMyLocation();

    //this.setState({ markers: markerArray });
    //console.log(markerArray.map(x => x.position.lat()),"lat");
    //console.log(markerArray.map(x => x.position.long()),"long");
  };

  const onMarkerDragEnd = async (coord) => {
    const { latLng } = coord;
    let lat = latLng.lat();
    let lng = latLng.lng();
    setCenter({ lat: lat, lng: lng });
    formik.setFieldValue("siteLocation.latitude", lat);
    formik.setFieldValue("siteLocation.longitude", lng);

    if (lat && lng) {
      const response = await getAddressByLatLng(lat, lng);
      if (response && response.data) {
        const { compound_code } = response.data.plus_code;
        setLoc(GetOnlyAddressFromCompoundCode(compound_code));
      }
    }

    //handleChange('siteLocation.latitude', lat);
    //handleChange('siteLocation.longitude', lng);
    /*
    this.setState(prevState => {
      const markers = [...this.state.markers];
      markers[index] = { ...markers[index], position: { lat, lng } };
      return { markers };
    });
    */

    //getMyLocation();
  };
  useEffect(() => {}, [uploadCollatralImage]);
  return (
    <>
      <Form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <NavBar
          blueBtnText="Save"
          whiteBtnText="Cancel"
          notificationSVG={<NotificationSVG />}
          navHeading="Add Location"
          subHeading="Configurator • All Locations "
          subActiveHeading="Add Location"
          type="submit"
          handleWhitebtn={handleOnCancel}
          disable={formik.isSubmitting}
          handleNotificationSVG={handleNotificationSVG}
          showButton={true}
        />
        <div className="p-20">
          <div className="girdMain">
            <Card className="gridCard" aria-labelledby="gridCard">
              <Card.Body className="gridCardBody border borderRadius p-0">
                <div className="formSection">
                  <p className="mt-4 mx-4">Location Details</p>
                  <div className="mx-4 ">
                    <div className="row w-78 my-4 fornSection2">
                      <div className="col-md-6">
                        <InputField
                          type="text"
                          name="name"
                          value={formik.values.name}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="name"
                          label="Name"
                          inputFieldSize="sizeOfInputField"
                        />
                        {formik.touched.name && formik.errors.name && (
                          <span className="text-danger">
                            {formik.errors.name}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <PlacesAutocomplete
                          value={loc}
                          onChange={(p) => {
                            setLoc(p);
                            setLocationValidate("");
                          }}
                          onSelect={(p) => {
                            setLocationValidate("");
                            setLoc(p);
                            formik.setFieldValue(
                              "siteLocation.googleAddress",
                              p
                            );
                            geocodeByAddress(p)
                              .then((results) => getLatLng(results[0]))
                              .then(({ lat, lng }) => {
                                setLat(lat);
                                setLng(lng);
                                setCenter({ lat: lat, lng: lng });
                                formik.setFieldValue(
                                  "siteLocation.latitude",
                                  lat
                                );
                                formik.setFieldValue(
                                  "siteLocation.longitude",
                                  lng
                                );
                              });
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: "Location",
                                  className:
                                    "inputField form-control location-search-input",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {/* {loading && <div>Loading...</div>} */}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#FAFAFA",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#FFFFFF",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {locationValidate && (
                          <span className="text-danger">
                            {locationValidate}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row w-78 my-4 fornSection2">
                      
                      {/* <div className="col-md-6">
                        <InputField
                          type="text"
                          name="radious"
                          value={formik.values.radious}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="Radius Of Operation"
                          label="Radius Of Operation"
                          inputFieldSize="sizeOfInputField"
                        />
                        {formik.touched.radious && formik.errors.radious && (
                            <span className='text-danger'>{formik.errors.radious}</span>
                        )}                     
                      </div> */}
                    </div>

                    <div className="d-flex justify-content-between w-78 my-4 fornSection2 mapBox">
                      <AddMap
                        center={center}
                        lat={lat}
                        lng={lng}
                        getMyLocation={() => getMyLocation()}
                        handleChange={formik.handleChange}
                        loc={loc}
                        onPlacesChanged={() => onPlacesChanged()}
                        onMarkerDragEnd={(coord) => onMarkerDragEnd(coord)}
                      />
                    </div>
                    <div className="d-flex justify-content-between w-78 my-4 fornSection2">
                      <div className="col-md-6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Type of Location / Environment"
                        >
                          <Form.Select
                            className="selecContainer"
                            placeholder="Type of Location / Environment"
                            onChange={(e) => handlEnvironment(e)}
                          >
                            {getEnviroment &&
                              getEnviroment.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </FloatingLabel>
                      </div>
                      <div className="col-md-6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="State Taxation"
                        >
                          <Form.Select
                            className="selecContainer"
                            placeholder="Select Taxation"
                            onChange={(e) => handleTaxation(e)}
                          >
                            {taxation &&
                              taxation?.map((item) => {
                                return (
                                  <option value={item.stateName} key={item.id}>
                                    {item.stateName}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="row w-78 my-4 fornSection2">
                      <div className="col-md-6">
                        {/* <UploadFile
                          uploadBoxSize="sizeOfInputField"
                          btnlable="Upload image"
                          id="image"
                          handelUploadLogo={handelUploadImage}
                          placeholder={
                            uploadImage
                              ? uploadImage.fileName
                              : "No File Attach"
                          }
                          allowedFiles="image/png, image/gif, image/jpeg"
                        /> */}
                        <UploadMultipleFiles
                          id="image"
                          uploadBoxSize="sizeOfInputField"
                          btnlable="Upload Image"
                          uploadMultipleProof={handelUploadImage}
                          placeholder={"No File Attach"}
                          uploadedData={uploadImage}
                          allowedFiles="image/png, image/gif, image/jpeg"
                        />
                        {imageValidate && (
                          <span className="text-danger">{imageValidate}</span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <UploadMultipleFiles
                          id="proof"
                          uploadBoxSize="sizeOfInputField"
                          btnlable="Upload Image"
                          uploadMultipleProof={handelUploadCollaralImage}
                          placeholder={"No File Attach"}
                          uploadedData={uploadCollatralImage}
                        />
                        {/* <UploadFile
                          uploadBoxSize="sizeOfInputField"
                          btnlable="Collateral"
                          id="collateral"
                          handelUploadLogo={handelUploadCollaralImage}
                          placeholder={
                            uploadCollatralImage
                              ? uploadCollatralImage.fileName
                              : "No File Attach"
                          }
                        /> */}
                        {collateralValidate && (
                          <span className="text-danger">
                            {collateralValidate}
                          </span>
                        )}
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="form-floating">
                          <textarea
                            type="text"
                            name="des"
                            value={description}
                            onChange={(e) => handleDescription(e)}
                            placeholder="Description"
                            label="Description"
                          />
                        </div>
                          </div>
                    </div>
                    {tempStepCount > 2 && (
                      <div className="w-75 my-5">
                        <BtnSwitch
                          sizeLg="xl"
                          text="Add this location to listing ?"
                          action={handleToggle}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Form>
      <CreateListModal
        show={showCreateListModal}
        setShow={setShowCreateListModal}
        availability={availability}
        durability={durability}
        priceTiype={priceTiype}
        listingDetails={listingDetails}
        setlistingDetails={setlistingDetails}
      />

      {siteConfirmModal && (
        <ConfirmModal
          title="Detail 
         Sent"
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Ok"
          cancelText="No"
          handleClose={() => setSiteConfirmModal(false)}
          handleConfirm={handleSiteConfirmModal}
          width="400px"
        >
          <div className="childs">
            <p className="newListingCreated_description ms-4">
            Location has been added. Do you want to add another location
            </p>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { SelectionComponent } from "../../../../../../../components/SelectionComponent/SelectionComponent";
import { InputField } from "../../../../../../../components/InputField";
import { InputFieldText } from "../../../../../../../components/InputField/InputFieldText";

export const CreateListModal = ({
  show,
  setShow,
  priceTiype,
  durability,
  availability,
  listingDetails,
  setlistingDetails,
}) => {
  const [avail, setAvail] = useState("");
  const [pType, setPType] = useState("");
  const [durab, setDurab] = useState("");
  const [price, setPrice] = useState("");
  console.log(listingDetails);
  /*
  const [listingDetails, setlistingDetails] = useState({
    availableFor: "",
    pricingType: "",
    applicableDuring: "",
    price: "",
  });
  */

  const handleAvailibility = (e) => {
    //console.log('value', e)

    setlistingDetails({ ...listingDetails, availableFor: e.target.value });
  };
  const handlePriceType = (e) => {
    setlistingDetails({ ...listingDetails, pricingType: e.target.value });
  };
  const handleDurability = (e) => {
    setlistingDetails({ ...listingDetails, applicableDuring: e.target.value });
  };
  const handlePrice = (e) => {
    setlistingDetails({ ...listingDetails, price: e.target.value });
  };
  const [error, setError] = useState({
    SAError: "",
    PTError: "",
    ADError: "",
    PError: "",
  });
  const handleSubmit = () => {
    let SAError, PTError, ADError, PError;
    if (!listingDetails.availableFor) {
      SAError = "Please select Site Available For.";
    }
    if (!listingDetails.pricingType) {
      PTError = "Please select price type.";
    }
    if (!listingDetails.applicableDuring) {
      ADError = "Please select applicable during.";
    }
    if (!listingDetails.price) {
      PError = "Please enter price.";
    }
    if (
      !listingDetails.availableFor ||
      !listingDetails.pricingType ||
      !listingDetails.applicableDuring ||
      !listingDetails.price
    ) {
      setError({
        SAError: SAError,
        PTError: PTError,
        ADError: ADError,
        PError: PError,
      });
      return;
    }
    console.log("listingDetails", listingDetails);
    setShow(false);
  };
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
    >
      <Modal.Header closeButton>
        <h5 className="modaltitle">Create Listing</h5>{" "}
      </Modal.Header>
      <Modal.Body>
        <div className="mt-4">
          <SelectionComponent
            label="Location Available for"
            option={availability}
            defaultValue={listingDetails.availableFor}
            handleOnchange={handleAvailibility}
          />
          <div className="text-danger">{error.SAError}</div>
        </div>
        <div className="pricingContainer">
          <h6>Set Pricing</h6>
          <SelectionComponent
            label="Tier Pricing"
            option={priceTiype}
            defaultValue={listingDetails.pricingType}
            handleOnchange={handlePriceType}
            optionDisabled={"Differential"}
          />
          <div className="text-danger">{error.PTError}</div>
          <div className="mt-4">
            <SelectionComponent
              label="Applicable During"
              option={durability}
              defaultValue={listingDetails.applicableDuring}
              handleOnchange={handleDurability}
            />
            <div className="text-danger">{error.ADError}</div>
          </div>
          <div className="mt-4">
            <InputField
              placeholder="Price"
              label="Price"
              value={listingDetails.price}
              onChange={handlePrice}
            />
            <div className="text-danger">{error.PError}</div>
          </div>
        </div>
        <div className="applyFilter">
          <button
            className="blueBgButton applyFilterBtn py-3 px-5"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

import React, { useEffect } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { UploadLogo } from "../../assets/svgComponents/svg";
import http from "../../services/httpService";
// import { uploadLogos } from "../../services/Provider";

export const UploadFile = ({
  uploadBoxSize,
  btnlable,
  placeholder,
  handelUploadLogo,
  id,
  name,
  value,
  disabled,
  allowedFiles
}) => {
  return (
    <div className={`uploadBox w-100 ${uploadBoxSize}`}>
      <span className="fileName">
        {placeholder ? placeholder : placeholder}
      </span>
      <label className="btn btnLable p-0">
        <input
          name={name}
          id={id}
          type="file"
          style={{ display: "none", padding: "0" }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => handelUploadLogo(e)}
          disabled={disabled}
          accept={allowedFiles}
        />
        <div>
          <UploadLogo />
        </div>
        <p>{btnlable}</p>
      </label>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import "./chart.scss";


ChartJS.register(ArcElement, Tooltip, Legend);


export const PieChart = ({graphDataTitles,graphData,graphColors}) => {
    //console.log(graphDataTitles, graphData, 'pie chart data');    
    const data = {
        labels: graphDataTitles,  
        datasets: [
          {
            label: '# of Votes',
            data: graphData,
            backgroundColor:graphColors,
            borderColor: graphColors,
            borderWidth: 1,
          },
        ],
      };

      const options = {
        plugins: {
          legend: {
            display: false,
          },
        },
      };


    return (
        <>                
            <Pie data={data} options={options} />
        </>
    );
};
import React from "react";
import { Form } from "react-bootstrap";
import { GridTable } from "../../../../../Provider/ConfigratorPage/Inventory/components/ViewSiteInfo/GridTable";
import { To2Decimal } from "../../../../../../../services/utility";

const PriceTiers = ({priceTiers}) => {   
  return (
    <>
      <div className="tableMainsection mt-4">
        <table className="tableContainer">
          <thead className="tableHead">
            <tr> 
                <td className="bookAllFavoritesModaltableheading">
                    Number of Signs
                </td>
                <td className="bookAllFavoritesModaltableheading">
                    Rent Per Day
                </td>
            </tr>
          </thead>
          <tbody className="tableBody">
            {
                priceTiers.map((priceTier)=>(
                    <tr key={priceTier.id + '_priceTier'}>
                        <td>
                            {priceTier.minimumQuantity} - {priceTier.maximumQuantity}
                        </td>
                        <td>
                            {To2Decimal(priceTier.amount)}
                        </td>
                  </tr>
                ))
            }           
          </tbody>          
        </table>
      </div>
    </>
  );
};

export default PriceTiers;

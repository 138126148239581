import React from 'react'
import { DetailsPage } from './DetailsPage';
import { InsightPage } from './InsightPage';

export const SwitchTab = ({activeTab,offerDetails}) => {
  switch (activeTab) {
      case "DETAILS":
          return(
              <DetailsPage offerDetails={offerDetails}/>
          )
      case "INSIGHTS":
          return(
              <InsightPage offerDetails={offerDetails}/>
          )
          break;
  
      default:
          break;
  }
}

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { NotificationSVG, PlusSVG } from "../../assets/svgComponents/table";
import { uploadFileMethod } from "../../services/Provider";
import { updateProfile } from "../../services/Provider/Configurator/Inventory";
import { currentUser } from "../../services/Provider/UserManagement/UserManagement";
import { InputField } from "../InputField";
import NavBar from "../navBar";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";



export const EditProfile = ({ user, provider }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [cuser, setCUser] = useState(user);
  const [uploadImage, setUploadImage] = useState({
    fileKey: "",
    fileName: "",
  });
  const [image, setImage] = useState();

  useEffect(async () => {
    await currentUser()
      .then((res) => {
        if (res.status === 200) {
          setCUser(res.data);
          let picId = res.data.profilePicture;
          setUploadImage(uploadImage => ({
            ...uploadImage,
            fileKey: picId
         }));
          setImage(
            `https://gopinit.blob.core.windows.net/image-container/${picId}`
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const uploadedImage = React.useRef();
  const imageUploader = React.useRef();

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    let data = e.target.files[0];
    let image = new FormData();
    image.append("file", data);
    uploadFileMethod(image)
      .then((res) => {
        if (res.status == 200) {
          setUploadImage(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: user&&user.name,
      phoneNumber: user && user.contactNo,
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .matches(new RegExp('[0-9]{7}'),"Invalid Phone number")
        // .required("Phone number is required!"),
    }),
    onSubmit: (values) => {
      const formData = [
        {
          path: "/name",
          op: "add",
          value: values.name,
        },
        {
          path: "/contactNo",
          op: "add",
          value: values.phoneNumber,
        },
        {
          path: "/profilePicture",
          op: "add",
          value: uploadImage && uploadImage.fileKey,
        },
      ];
      updateProfile(formData)
        .then((res) => {
          if (res.status == 204) {
            // addToast("Profile Updated ")
            provider
              ? history.push("/provider/profile")
              : history.push("/customer/profile");
          }
        })
        .catch((err) => [console.log("err", err)]);
    },
  });

  const phoneNumberError = formik.touched.phoneNumber && formik.errors.phoneNumber;


  const handleOnCancel = () => {
    provider
      ? history.push("/provider/profile")
      : history.push("/customer/profile");
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit} className="w-100 h-100 d-flex flex-column">
        <NavBar
          blueBtnText="Save"
          whiteBtnText="Cancel"
          notificationSVG={<NotificationSVG />}
          navHeading="Edit Profile"
          subHeading="Profile"
          subActiveHeading="Edit Profile"
          type="submit"
          handleWhitebtn={handleOnCancel}
          showButton={true}
          // handleNotificationSVG={handleNotificationSVG}
        />
        <div className="gridSection p-20">
        <div className="row g-0 h-100">
          <div className="col-md-4 bg-base-100 px-0">
            <div className="profileImageContainer pt-5">
            <div 
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",

              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none",
                }}
              />
              <div
                style={{
                  backgroundColor: "#FFF5E5",
                  width: "160px",
                  height: "160px",
                  position: "relative",
                  top: "0",
                  left: "0",
                  borderRadius: "100px",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight:"16px",
                }}
              >
                <div
                  style={{
                    height: "145px",
                    width: "145px",
                    borderRadius: "100px",
                    position: "absolute",
                    top: "5%",
                    left: "5%",
                  }}
                >
                  <img
                    ref={uploadedImage}
                    src={image?image:'https://i.stack.imgur.com/l60Hf.png'}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "acsolute",
                      border: "none",
                      borderRadius: "100px",
                    }}
                  />
                </div>
              </div>
              <h6
                onClick={() => imageUploader.current.click()}
                style={{
                  color: "#F99300",
                  // marginTop: "110px",
                  cursor: "pointer",
                }}
              >
                {image?"Update Profile Pic":"Upload Profile Pic"}
              </h6>
            </div>
            <div className="mt-32">
              <div className="mb-32">
                <InputField
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Name"
                  label="Name"
                  inputFieldSize="sizeOfInputField"
                />
              </div>
              <div className="mb-32">
                <InputField
                  placeholder="Phone Number"
                  label="Phone Number"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  inputFieldSize="sizeOfInputField"
                />
                {phoneNumberError && (
                <p className="text-danger mt-1" style={{ fontSize: "14px" }}>
                  {formik.errors.phoneNumber}
                </p>
              )}
              </div>
            </div>
            </div>
          </div>
        </div>
        </div>
      </Form>
    </>
  );
};

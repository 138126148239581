import React from "react"

const BtnUnionFilter = (props) => {
  const { onClick } = props;
  return (
    <>
      <button className=" union_filter_Button" onClick={onClick}>
        {props.children}
      </button>
    </>
  );
};

export default BtnUnionFilter;

import React, { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { Form, Formik, useFormik } from "formik";
import { useToasts } from "react-toast-notifications";

import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import { InputField } from "../../../../../../../components/InputField";
import NavBar from "../../../../../../../components/navBar";
import { SelectionComponent } from "../../../../../../../components/SelectionComponent/SelectionComponent";
import {
  createSiteListing,
  getOptionsType,
  updateCreatedListing,
} from "../../../../../../../services/Provider/Configurator/Listings";
import FormikController from "../../../../../../../components/Formik/FormikController";
import { useContext } from "react";
import ProjectContext from "../../../../../../../context/ProjectContext";
import NewListingConfirmModal from "./modals/NewListingConfirmModal";
import FeaturedListingModal from "./modals/FeaturedListingModal";
import OnBoardingFeatured from "./modals/OnBoardingFeatured";
import { getUpdateOnBoardingStepDone } from "../../../../../../../services/Provider";
import moment from "moment";

const PricingPage = ({ sideTabs, activeTab }) => {
  const history = useHistory();
  const location = useLocation();
  const [pricingType, setPriceingType] = useState();
  const [availableFor, setAvailableFor] = useState();
  const [applicableDuringOptions, setApplicableDuringOptions] = useState();
  const { addToast } = useToasts();
  const { handleCreateListing, createListing } = useContext(ProjectContext);
  const [newListingCreatedModal, setNewListingCreatedModal] = useState(false);
  const [ListingValue, setListingValue] = useState();
  const [featuredListing, setFeaturedListing] = useState(false);
  const [registerListingModal, setRegisterListingModal] = useState(false);
  const [listingCreatedModal, setListingCreatedModal] = useState(false);
  const [featuredListingDetails, setFeaturedListingDetails] = useState({});
  const [featuredDate, setFeaturedDate] = useState({
    startDate: new Date(),
    endDate: null,
  });
  const [amount, setAmount] = useState(0);

  console.log(location, "location");
  let tempStepCount = parseInt(localStorage.getItem("onBoardingStepDone"));
  const initialValues = {
    availableFor: 1,
    applicableDuring: 0,
    pricingType: 1,
    price: (location?.data?.pricing[0]?location?.data?.pricing[0].amount:0),
  };

  const validationSchema = Yup.object({
    availableFor: Yup.number().required("Please select available for"),
    // applicableDuring: Yup.number().required("Please select applicablities"),
    // pricingType: Yup.string().required("Please select price type"),
    price: Yup.number().required("Please enter price"),
  });
  useEffect(async () => {
    const [result1, result2, result3] = await Promise.all([
      getOptionsType("PriceTypes"),
      getOptionsType("Availabilities"),
      getOptionsType("PricingApplicabilities"),
    ]);
    console.log(result1, result2, result3);
    try {
      if (result1.status == 200) {
        setPriceingType(result1.data);
      }
      if (result2.status == 200) {
        setAvailableFor(result2.data);
      }
      if (result3.status == 200) {
        setApplicableDuringOptions(result3.data);
      }
    } catch (err) {
      console.log(err);
    }
    setAmount(location?.data?.pricing[0]?.amount)
  }, []);

  const handleForimkSubmit = async (val) => {
    if (!location.isUpdateListing) {
      let reqBody = {
        name: createListing.name,
        featured: false,
        availableFor: val.availableFor,
        siteIds: [createListing.sitesIds],
        signIds: [],
        serviceIds: [],
        chargeIds: [],
        signs: [],
        minBookingPeriod: 1,
        periodType: 1,
        turnAroundTime: 1,
        turnAroundTimePeriod: 0,
        subscriptionTerms: {
          term: 1,
          downPaymentPercentage: 0.0,
        },
        pricing: [
          {
            priceType: val.pricingType,
            applicability: val.applicableDuring,
            amount: val.price,
            minimumQuantity: 0,
            maximumQuantity: 0,
          },
        ],
        summaryCharges: [],
        refundType: 0,
        refundRules: [
          {
            refundPercentage: "97",
            orderStatus: 2,
          },
          {
            refundPercentage: "50",
            orderStatus: 3,
          },
          {
            refundPercentage: "0",
            orderStatus: 4,
          },
          {
            refundPercentage: "0",
            orderStatus: 5,
          },
        ]
      };
      await createSiteListing(reqBody)
        .then((res) => {
          if (res.status == 201) {
            if (tempStepCount == 3) {
              localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
              let bodyData = [
                {
                  path: "/onBoardingStepDone",
                  op: "add",
                  value: tempStepCount + 1,
                },
              ];
              getUpdateOnBoardingStepDone(bodyData)
                .then((res) => {
                  console.log("res", res);
                })
                .catch((err) => {
                  console.log("err", err);
                });
              history.push("/provider");
            } else {
              setFeaturedListingDetails((prev) => ({
                ...prev,
                listingId: res.data,
                name: createListing.name,
                price: 200,
              }));
              setListingCreatedModal(true);
            }
          }
          // addToast("Site created successfully", {
          //   appearance: "success",
          // });
          // setNewListingCreatedModal(true);
        })
        .catch((err) => console.log(err));
    } else {
      let updateReqBody = {
        id: location.data.id,
        name: createListing.name,
        featured: false,
        availableFor: val.availableFor,
        siteIds: [createListing.sitesIds],
        signIds: [],
        serviceIds: [],
        chargeIds: [],
        signs: [],
        minBookingPeriod: 0,
        periodType: 1,
        turnAroundTime: 1,
        turnAroundTimePeriod: 0,
        subscriptionTerms: {
          term: 1,
          downPaymentPercentage: 0.0,
        },
        pricing: [
          {
            priceType: val.pricingType,
            applicability: val.applicableDuring,
            amount: val.price,
            minimumQuantity: 0,
            maximumQuantity: 0,
          },
        ],
        summaryCharges: [],
        refundType: 0,
        refundRules: [
          {
            refundPercentage: "97",
            orderStatus: 2,
          },
          {
            refundPercentage: "50",
            orderStatus: 3,
          },
          {
            refundPercentage: "0",
            orderStatus: 4,
          },
          {
            refundPercentage: "0",
            orderStatus: 5,
          },
        ]
      };
      await updateCreatedListing(updateReqBody)
        .then((res) => {
          if (res.status == 201) {
            // setFeaturedListing(true);
            addToast("Listing update successfully", {
              appearance: "success",
            });
            // history.push("/provider/configurator/listings");
            setListingCreatedModal(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // setListingValue(tempVal);
  };

  // const handleBlueBtn = () => {
  //   setNewListingCreatedModal(true);
  // };
  const handleWhiteBtn = () => {
    // console.log("white");
    history.push("/provider/configurator/listings");
  };

  const handleConfirmListCreatedModal = () => {
    setListingCreatedModal(false);
    setFeaturedListing(true);
  };

  // const handleRegisterDateFetaured = () => {
  //   // alert("hello");
  //   let tempFeaturedListingDetails = {
  //     listingId: featuredListingDetails.listingId,
  //     name: featuredListingDetails.name,
  //     price: featuredListingDetails.price,
  //     start_date: "20 July 2021",
  //     end_date: "20 Aug 2021",
  //   };

  //   history.push({
  //     pathname: `/provider/payment`,
  //     featuredListingDetails: tempFeaturedListingDetails,
  //   });
  // };
  const onChangeFeaturedDate = (dates) => {
    const [start, end] = dates;
    // setStartDate(start);
    // setEndDate(end);
    setFeaturedDate({
      startDate: start,
      endDate: end,
    });
  };
  const handleRegisterDateFetaured = () => {
    // alert("hello");
    if (featuredDate.endDate) {
      let tempFeaturedListingDetails = {
        listingId: featuredListingDetails.listingId,
        name: featuredListingDetails.name,
        price: featuredListingDetails.price,
        start_date: moment(featuredDate.startDate).format("DD MMMM YYYY"),
        end_date: moment(featuredDate.endDate).format("DD MMMM YYYY"),
      };
      // console.log(tempFeaturedListingDetails, "tempFeaturedListingDetails");

      history.push({
        pathname: `/provider/payment`,
        featuredListingDetails: tempFeaturedListingDetails,
      });
    } else {
      addToast("Please enter featured listing end date", {
        appearance: "warning",
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleForimkSubmit(values)}
      >
        <Form>
          <div className="w-100">
            <NavBar
              // handleWhitebtn={() => setConfirmModal(true)}
              whiteBtnText={"Cancel"}
              blueBtnText={"Save"}
              notificationSVG={<NotificationSVG />}
              navHeading={
                location?.data?.name ? location.data.name : createListing.name
              }
              subHeading="All Billable Item "
              subActiveHeading=" New Billable Item"
              // handleBlueBtn={() => handleBlueBtn(activeTab)}
              handleWhitebtn={() => handleWhiteBtn(activeTab)}
              showButton={true}
            />
            <div className="p-20 gridSection">
              <div className="d-flex w-100">
                <div>
                  <ul className=" progressbar">
                    {sideTabs?.map((tab) => (
                      <li
                        className={`billable_switchSideDom_item ${
                          tab.isActive && "active"
                        }
                    ${tab.name == "Configurations" && "activeTabTitle"}
                   `}
                      >
                        {tab.name == "Configurations" ? "Pricing" : tab.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="w-100">
                  <div className="card mx-3 mt-3">
                    <div className="tableActions_container p-20">
                      <Row>
                        <Col className="d-flex align-items-center">
                          <div>Pricing</div>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md="5">
                          <div className="inputContainer">
                            <FormikController
                              control="select"
                              type="text"
                              label="Site Available For"
                              name="availableFor"
                              options={availableFor}
                            />
                          </div>
                          
                        </Col>
                        
                        <Col md="5">
                          <div className="inputContainer">
                            <FormikController
                              control="select"
                              type="text"
                              label="Applicable During"
                              name="applicableDuring"
                              options={applicableDuringOptions}
                            />
                          </div>
                          {/* <SelectionComponent
                            name="applicableDuring"
                            label="Applicable During"
                            value={formik.values.applicableDuring}
                            option={applicableDuring}
                            handleOnchange={formik.handleChange}
                          /> */}
                        </Col>                       
                      </Row>
                      <Row className="my-4">
                        <Col md="5">
                          <div className="inputContainer">
                            <FormikController
                              control="select"
                              type="text"
                              label="Pricing Type"
                              name="pricingType"
                              options={pricingType}
                              disabled={"Differential"}
                              defaultValue={1}
                              disable={true}
                            />
                          </div>
                          {/* <SelectionComponent
                            name="pricingType"
                            label="Pricing Type"
                            option={pricingType}
                            value={formik.values.price}
                            handleOnchange={formik.handleChange}
                          /> */}
                        </Col>
                        <Col md="5">
                          <div className="inputContainer">
                            <FormikController
                              control="input"
                              type="text"
                              label="Price"
                              name="price"
                              //value={allConfiguration.pricing[0]?.amount}
                            />
                          </div>
                          {/* <InputField
                            name="price"
                            label="Price"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                          /> */}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      {/* new listing created modal */}
      {listingCreatedModal && (
        <NewListingConfirmModal
          setNewListingCreatedModal={setListingCreatedModal}
          newListingCreatedModal={listingCreatedModal}
          handleConfirmListCreatedModal={() => handleConfirmListCreatedModal()}
        />
      )}
      {/* featured Listing modal*/}
      {featuredListing && (
        <FeaturedListingModal
          setFeaturedListing={setFeaturedListing}
          featuredListing={featuredListing}
          setRegisterListingModal={setRegisterListingModal}
        />
      )}
      {/* Listing to be featured modal*/}
      {registerListingModal && (
        <OnBoardingFeatured
          setRegisterListingModal={setRegisterListingModal}
          handleRegisterDateFetaured={handleRegisterDateFetaured}
          onChangeFeaturedDate={onChangeFeaturedDate}
          featuredDate={featuredDate}
        />
      )}
    </>
  );
};

export default PricingPage;

import React from "react";
import Listings from ".";
import { Switch, Route } from "react-router-dom";
import SwitchCreateListingDOM from "./components/SwitchCreateListingDOM";
import SiteDetails from "./pages/SitesPage/components/SiteDetails";
import BundleDetailsPage from "./pages/BundlePage/components/BundleDetailsPage";
import ListingDetailsPage from "./components/ListingDetailsPage";

export const ListingRoutes = ({ ...rest }) => {
  return (
    <Switch>
      <Route
        path={`${rest.match.url}/`}
        exact
        render={(props) => <Listings {...props} />}
      />
      <Route
        path={`${rest.match.url}/information-details`}
        exact
        render={(props) => <ListingDetailsPage {...props} />}
      />
      <Route
        path={`${rest.match.url}/sign/information-details`}
        exact
        render={(props) => <SiteDetails {...props} />}
      />
      <Route
        path={`${rest.match.url}/bundle/information-details`}
        exact
        render={(props) => <BundleDetailsPage {...props} />}
      />
      <Route
        pathname={`${rest.match.url}/new-billable-item`}
        render={(props) => <SwitchCreateListingDOM {...props} />}
      />

      {/* <Route
        pathname={`${rest.match.url}/update`}
        render={(props) => <SwitchUpdateListingDOM {...props} />}
      /> */}

      {/* {console.log(rest.match.url, "rest.match.url")} */}
      <Route
        path={`${rest.match.url}/site-detail`}
        render={(props) => <SiteDetails {...props} />}
      />
    </Switch>
  );
};

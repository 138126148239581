import React from 'react';
import {Switch,Route}from "react-router-dom";
import Settings from '../Settings/Pages/Settings';
import Advertisers from './Components/Advertisers';
import { AllUsers } from './Components/AllUsers';
import Providers from './Components/Providers';
import Users from './Components/Users';
import InviteUser from './Modal/InviteUser';
import {EditUser}  from './Modal/EditUser';
export const UserManagementRoutes = ({...rest}) => {
  return (
    <Switch>
      <Route
        path={`${rest.match.url}/`}
        exact
        render={(props) => <AllUsers {...props} />}
      />
     <Route
        path={`${rest.match.url}/users`}
        render={(props) => <Users {...props} />}
      />
      <Route
        path={`${rest.match.url}/providers`}
        render={(props) => <Providers {...props} />}
      />
      <Route
        path={`${rest.match.url}/advertisers`}
        render={(props) => <Advertisers {...props} />}
      />
      <Route
        path={`/admin/allusers/invite-user`}
        render={(props) => <InviteUser {...props} />}
      />
      <Route
        path={`/admin/allusers/edit-user`}
        render={(props) => <EditUser {...props} />}
      />
      </Switch>
  )
}

import React, { useContext, useEffect, useState } from "react";
import { AuthService } from "../services/AuthService";
import { ApiService } from "../services/ApiService";
import { Button, Container } from "react-bootstrap";
import { SiteLogo } from "../assets/svgComponents/svg";
import Provider from "./app/Provider";
import { Customers } from "./app/Customers";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Admin from "./app/Admin";
import ProjectContext from "../context/ProjectContext";
import { getProvider } from "../services/Provider";
import {getUser,setUser} from "../services/reducers/user-reducer";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { currentUser } from "../services/Provider/UserManagement/UserManagement";

const WelcomePage = () => {
  const urlParams = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  // const { handleAuthToken, authToken } = useContext(ProjectContext);

  const {user,role} = useSelector((state)=>state.user); 
  const authService = new AuthService();
  const apiService = new ApiService();  
  const [shouldCancel, setShouldCancel] = useState(false);  

  const [curUser, setCurUser] = useState();
  useEffect(() => {      
    const getUser = () => {
      authService.getUser().then((user) => {
        console.log('auth service user',user);
        if (user) {
          
          setTimeout(currentUser().then((res) => {}), 3000);
          dispatch(setUser(user.profile));
          const token = localStorage.getItem("accessToken");
          localStorage.setItem("accessToken", user.access_token);
                localStorage.setItem("accessModules", user.profile.AccessModules);
                localStorage.setItem("userType", user.profile.UserType);          
          const redirecturl= localStorage.getItem("RedirectURL");
          if(redirecturl) {
            localStorage.removeItem("RedirectURL")
            window.location.replace(redirecturl)
          }

          if (!token) window.location.reload();
        } else {
          authService.login();
          dispatch(setUser(null));
        }
      });
    };
    getUser();
  }, [dispatch]);

   
  useEffect(() => {
    if (role == "Provider") {
      getProvider().then((res) => {
        if (res.status == 200) {        
          setCurUser(res.data);
          localStorage.setItem("onBoardingStepDone", res.data.onBoardingStepDone);
          if (res.data.onBoardingStepDone >= 6)
            history.push("/provider/dashboard");
          else history.push("/provider");          
        }
      });      
    } else if (role == "Customer" || role == "Temp") {
     // history.push("/customer");
    } else {
     // history.push("/admin/dashboard");
    }
  }, [role]);

  const login = () => {
    authService.login();
  };
  // useEffect(()=>{
  //   authService.login();
  // }, [])
  const logout = () => {
    authService.logout();
    localStorage.removeItem("accessToken");
  };
 
  if (role) { 
    if (role == "Provider") {      
      return <Provider logout={logout} userData={user} />;
    } else if (role == "Customer" || role == "Temp") {      
      return <Customers logout={logout} />;
    } else {      
      return <Admin logout={logout} />;
    }
  } else if(isMobile && window.location.href.indexOf("customer/extend-order-payment")>0){
     console.log('updating url schene to gopinit');
      let url = window.location.href;
      url = url.replace("https", "gopinit");
      url = url.replace("http", "gopinit");
      window.location.replace(url);
  }
  else { 
    return (
      <></>
      // <div className="d-flex flex-column vh-100">
      //   <Container className="welcomeheaderContainer">
      //     <div>
      //       <SiteLogo height="40px" width="33.12px" />
      //       <span className="title">GoPinlt</span>
      //     </div>
      //     <div>
      //       <Button variant="primary" onClick={login}>
      //         Login / Sign up
      //       </Button>
      //     </div>
      //   </Container>
      //   <div className="bodyContainer flex-grow-1 h-100 overflow-auto"></div>
      // </div>
    );
  }
};

export default WelcomePage;

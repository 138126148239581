
import { Route } from "react-router-dom";
import { Switch } from "react-router";
import { Dashboard } from "../views/app/Admin/pages/Dashboard";
import { OrdersIndexPage } from "../views/app/Admin/Orders/OrdersIndexPage";
import { UserManagementRoutes } from "../views/app/Admin/UserManagement/UserManagementRoutes";
import InviteUser from "../views/app/Admin/UserManagement/Modal/InviteUser";
import { EditUser } from "../views/app/Admin/UserManagement/Modal/EditUser";
import ChangePassword from "../views/app/Admin/Settings/Pages/ChangePassword";
import { ReviewsAndRatings } from "../views/app/Admin/ReviewsAndRatings/Pages/ReviewsAndRatings";
import { SettingsRoutes } from "../views/app/Admin/Settings/SettingsRoutes";
import { NotificationsPage } from "../views/app/Admin/Notifications/NotificationsPage";
import { SupportRequest } from "../views/app/Admin/SupportRequest/index";
import { ProviderDetails } from "../views/app/Admin/UserManagement/Components/ProviderDetails";


const AdminRoutes = () => {
    return (
      <>
        <Switch>
          {/* side bar route  */}    

           <Route
            path={`/admin/dashboard`}
            exact
            render={(props) => <Dashboard {...props} />}
          />
          

      
          <Route
            path={`/admin/orders`}
            component={OrdersIndexPage}
          />

         
          <Route
            path={`/admin/allusers`}
            exact
            render={(props) => <UserManagementRoutes {...props} />}
          />
          
          <Route
            path={`/admin/allusers/invite-user`}
            render={(props) => <InviteUser {...props} />}
          />
           <Route
            path={`/admin/allusers/edit-user`}
            render={(props) => <EditUser {...props} />}
          />
          <Route
            path={`/admin/settings`}
            exact
            render={(props) => <SettingsRoutes {...props} />}
          />
          <Route
            path={`/admin/settings/change-password`}
            render={(props) => <ChangePassword {...props} />}
          />
          <Route
            path={`/admin/reviews-ratings`}
            render={(props) => <ReviewsAndRatings {...props} />}
          />
          <Route
            path={`/admin/notifications`}
            component={NotificationsPage}
          />
          <Route
              path={`/admin/support`}
              component={SupportRequest}
            />
            <Route
              path={`/admin/allusers/provider-details`}
              component={ProviderDetails}
            />

          <Route
            exact={true}
            path='*'
            render={(props) => <Dashboard {...props} />}
          />

        </Switch>
      </>
    );
  };
  
  export default AdminRoutes;
import React from "react";

const TableCol = ({ item, accessorName, idx, configId }) => {
  return (
    <>
      {configId == 2 ? (
        <td>
          {accessorName[idx] == "location" ? (
            <div className="gridTableBody_td">
              {item[accessorName[idx]].googleAddress}
            </div>
          ) : accessorName[idx] == "environment" ? (
            <>
              <div className="gridTableBody_td x">
                {item[accessorName[idx]].name}
              </div>
            </>
          ) : (
            <>
              <div className="gridTableBody_td x">
                {item[accessorName[idx]]}
              </div>
            </>
          )}
        </td>
      ) : (
        <td>
          <div className="gridTableBody_td">{item[accessorName[idx]]}</div>
        </td>
      )}
    </>
  );
};

export default TableCol;

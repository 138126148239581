import React, { useState } from "react";
import { NotificationSVG } from "../../../../assets/svgComponents/table";
import ListingSearchBar from "./ListingSearchBar";
import SwitchTabDom from "./Tabs/SwitchTabDom";
import { FloatingLableSearchBar } from "../../../../components/SearchBar/FloatingLableSearchBar";
import {
  emptyMarkFavouriteResult,
  emptyListingState,
  setSelectedListingType,
  getLocationNameAsync,
  setSearchTerm,
  getListBySitesAsync,
  getListBySignsAsync,
  getListByProvidersAsync,
  getSelectedFilters,
  setLatitude,
  setLongitude,
  setSearchSection,
} from "../../../../services/reducers/listing-reducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { capitalize } from "../../../../services/utility";
import { Modal } from "react-bootstrap";
import UpdatesModal from "../../../../components/profile/Modal/UpdatesModal";

export const HomePage = () => {
  const tabs = ["BY LOCATIONS", "BY SIGNS", "BY PROVIDERS"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { portableSignsListing } = useSelector((state) => state.listing);
  const {
    media,
    environment,
    onlyFeatured,
    minCost,
    maxCost,
    fromDate,
    uptoDate,
    searchTerm,
    searchSection,
  } = useSelector((state) => getSelectedFilters(state.listing));

  const { markFavouriteResult } = useSelector((state) => state.listing);
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);

  useEffect(async () => {
    if (markFavouriteResult) {
      if (markFavouriteResult.status === 1) {
        let type = markFavouriteResult.type;
        if (type === "detailpage" || type === "providersite") type = "listing";
        type = capitalize(type);
        let msg = `${type} ${markFavouriteResult.title} marked as ${markFavouriteResult.action} !`;
        addToast(msg, { appearance: "success" });
      } else {
        let msg = `Unable to marked as ${markFavouriteResult.action} !`;
        addToast(msg, { appearance: "error" });
      }
      await dispatch(emptyMarkFavouriteResult());
    }
  }, [markFavouriteResult]);

  const handleSearchTextChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
  };

  const handelSearchSectionChange = (e) => {
    dispatch(setSearchSection(e));
  };

  useEffect(() => {
    dispatch(emptyListingState());
    const getMyLocation = () => {
      const location = window.navigator && window.navigator.geolocation;
      if (location) {
        location.getCurrentPosition((position) => {
          console.log("user location:", position.coords);
          dispatch(setLatitude(position.coords.latitude));
          dispatch(setLongitude(position.coords.longitude));
          dispatch(
            getLocationNameAsync({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              locationType: "listing",
            })
          );
        });
      }
    };
    getMyLocation();
  }, [dispatch]);

  useEffect(() => {
    if (portableSignsListing) {
      setActiveTab(tabs[1]);
    }
  }, [portableSignsListing]);

  const handleSearchClick = (e) => {
    const reqBody = {
      page: 0,
      pageSize: 10,
      orderBy: "",
      export: true,
      searchString: searchTerm,
      userLocation: "",
      costs: [],
      mediaTypes: media,
      environmentIds: environment,
      dates: [],
      onlyFeatured: onlyFeatured,
      providerId: "",
    };
    if (minCost != null && maxCost) {
      reqBody.costRange = { min: minCost, max: maxCost };
    }
    if (fromDate && uptoDate) {
      reqBody.dateRange = { fromDate, uptoDate };
    }

    if (activeTab === "BY LOCATIONS") {
      reqBody.listingType = 0;
      dispatch(getListBySitesAsync(reqBody));
      dispatch(setSelectedListingType(0));
    } else if (activeTab === "BY SIGNS") {
      reqBody.listingType = 1;
      dispatch(getListBySignsAsync(reqBody));
      dispatch(setSelectedListingType(1));
    } else if (activeTab === "BY PROVIDERS") {
      reqBody.listingType = 2;
      dispatch(getListByProvidersAsync(reqBody));
      dispatch(setSelectedListingType(2));
    }
  };
  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <>
      <ListingSearchBar
        isInputField={true}
        notificationSVG={<NotificationSVG />}
        navHeading="Home"
        subHeading=""
        handleSearchTextChange={handleSearchTextChange}
        handleSearchClick={handleSearchClick}
        searchTerm={searchTerm}
        searchSection={searchSection}
        handelSearchSectionChange={handelSearchSectionChange}
        handleNotificationSVG={handleNotificationSVG}
      />

      <SwitchTabDom
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
      />
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { NotificationSVG, ExportCSVSVG } from "../../../../assets/svgComponents/table";
import Grid from "../../../../components/grid/Grid";
import NavBar from "../../../../components/navBar";
import { deleteUser, updateUserStatus, userList } from "../../../../services/Provider/UserManagement/UserManagement";
import TableActions from "./components/TableActions";
import "./scss/userManagement.scss";
import DataTable from "react-data-table-component";
import { PassThroughSVG } from "../../../../assets/svgComponents/inventorySVG";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
// import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import UpdatesModal from "../../../../components/profile/Modal/UpdatesModal";
import { useToasts } from "react-toast-notifications";
import ConfirmModal from "../../../../components/confirmModal/ConfirmModal";
import { checkPermission } from "../../../../utils";
import { FloatingLableSearchBar } from "../../../../components/SearchBar/FloatingLableSearchBar";
import { SelectComponent } from "../../../../components/SelectionComponent/SelectComponent";


const UserManagement = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();
  const [activeScreen, setActiveScreen] = useState("UserManagement");
  const [listData, setListData] = useState();
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [searchString, setSearchString] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [activeInactiveModal, setActiveInactiveModal] = useState(false);

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    setSortColumn({ column: column, sortDirection: sortDirection });
  };

  const markActiveInactive = (item, active) => {
    setSelectedUser(item);
    console.log("selected user", item);
    setActiveInactiveModal(true);
  };

  const handleMarkActiveInactiveConfirm = () => {
    const reqBody = {
      userId: selectedUser.id,
      isDisabled: selectedUser.status,
    };
    console.log(reqBody);
    updateUserStatus(reqBody)
      .then(() => {
        setActiveInactiveModal(false);
        loadData();
        addToast("User status successfully updated.", {
          appearance: "success",
        });
        loadData();
      })
      .catch(() => {
        addToast("There is an error while updating status.", {
          appearance: "warning",
        });
        // toast.error("There is an error while updating status");
      });
  };
  const columns = [
    {
      name: "",
      selector: (row) => row.profilePicture,
      cell: (row) => {
        console.log(row, "row");
        return (
          row.profilePicture &&
          <span>
            <div className="logoContainer">
              <img src={`https://gopinit.blob.core.windows.net/image-container/${row.profilePicture}`} alt="logo" />
            </div>
          </span>
        );
      },
      sortable: false,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
    },
    {
      name: "Email Address",
      selector: (row) => row.emailId,
      sortable: true,
      sortField: "EmailId",
    },
    {
      name: "Access Type",
      selector: (row) => row.accessTypeName,
      sortable: true,
      sortField: "AccessType",
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <div>
            {row.status ? (
              <div className="gridTableBody_td active">Active </div>
            ) : (
              <div className="gridTableBody_td inActive">Inactive</div>
            )}
          </div>
        );
      },
      sortable: true,
      sortField: "Status",
    },
    {
      cell: (row) => (
        <span className="gridTableBody_td_passThrough">
          {checkPermission("UserManagement", "Full") && (
            <DropdownButton
              align="end"
              className="dropDownBtn"
              title={<PassThroughSVG />}
            >
              {row.status == 1 ? (
                <Dropdown.Item onClick={(e) => markActiveInactive(row, false)}>
                  Mark InActive
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={(e) => markActiveInactive(row, true)}>
                  Mark Active
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={(e) => handleEditGridCell(row)}>
                Edit{" "}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteGridCell(row)}>
                Delete
              </Dropdown.Item>
            </DropdownButton>
          )}
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const loadData = async () => {
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: searchString,
      status: statusFilter.id,
    };
    setLoader(true);
    await userList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          setListData(res.data.data);
          // console.log(res, "res");
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // fetch user data for listing in grid
  useEffect(async () => {
    loadData();
  }, [currentPage, pageSize, sortColumn, searchString, statusFilter]);

  useEffect(() => {
    loadData();
  }, []);

  // const customSort = (rows, selector, direction) => {
  //   return rows.sort((rowA, rowB) => {
  //     // use the selector function to resolve your field names by passing the sort comparitors
  //     const aField = selector(rowA);
  //     const bField = selector(rowB);

  //     let comparison = 0;

  //     if (aField > bField) {
  //       comparison = 1;
  //     } else if (aField < bField) {
  //       comparison = -1;
  //     }

  //     return direction === "desc" ? comparison * -1 : comparison;
  //   });
  // };

  const handleBlueBtn = () => {
    // alert("hello");
    history.push(`${location.pathname}/invite-user`);
  };

  const handleEditGridCell = (item) => {
    history.push({ pathname: `${location.pathname}/edit-user`, state: item });
  };

  const deleteCell = (item) => { };

  const handleDeleteGridCell = (item) => {
    setDeleteModel(true);
    setSelectedUser(item);
  };

  const deleteUserCall = async () => {
    await deleteUser(selectedUser.id)
      .then(() => {
        loadData();
        setDeleteModel(false);
        addToast("User deleted successfully.", {
          appearance: "success",
        });
        // toast.success("User successfully deleted.");
      })
      .catch(() => {
        setDeleteModel(false);
        addToast("There is an error while deleting the user.", {
          appearance: "warning",
        });
        // toast.error("There is an error while deleting the user.");
      });
  };

  const loadExportData = async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: "",
      status: 0,
    };
    setLoader(true);
    await userList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          setListData(res.data.data);
          // console.log(res, "res");
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };


  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array.records);
    if (csv == null) return;

    const filename = 'export-users.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <button
      className="blueBgButton"
      onClick={(e) => onExport(e.target.value)}
    >
      <span className="btnSvg"><ExportCSVSVG fill="#FFFFFF" /></span>
      Export CSV
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );

  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  const searchChange = (e) => {
    setSearchString(e.target.value);
  };

  return (
    <>
      <NavBar
        blueBtnText={`Send Invite's`}
        notificationSVG={<NotificationSVG />}
        navHeading="User Management"
        handleBlueBtn={handleBlueBtn}
        handleNotificationSVG={handleNotificationSVG}
        showButton={checkPermission("UserManagement", "Full")} 
      />

      {/* <div className="mainSection_container px-3">
        <TableActions />
        <Grid
          loader={loader}
          isActionBtn={true}
          isAvatar={true}
          tableHeading={sitesHeading}
          tableBody={listData}
          accessorName={listAccessorName}
        />
      </div> */}
      <div className="gridSection p-20">
        <div className="card mt-4 overflow-hidden customtable">
        <div className="d-flex justify-content-start p-2">
            <div className="d-flex serachFilterContainer me-3 w-auto">
              <FloatingLableSearchBar
                inputFieldSize="fieldSize"
                handleOnchange={searchChange}
                label="Search Name, email"
              />
            </div>
            <div className="d-flex serachFilterContainer w-auto listingFiltersRow">
              <div className="home_filter_cntr">
                <label
                  htmlFor="cost_ddl"
                  className="m-0 textGray2 text-sm home_lbl"
                >
                  Status
                </label>
                  <SelectComponent
                    className="customSelectDrop"
                    label="Status"
                    value={statusFilter}
                    defaultValue={statusFilter}
                    handleOnchange={(e) => {
                      setStatusFilter(e);
                    }}
                    option={[
                      { id: "", name: "All" },
                      { id: "1", name: "Active" },
                      { id: "0", name: "Inactive" },
                    ]}
                  ></SelectComponent>
              </div>
            </div>
          </div>
          {listData && listData.records && (
            <DataTable
              progressPending={loader}
              columns={columns}
              data={listData.records}
              allowOverflow={true}
              paginationDefaultPage={currentPage}
              pagination
              paginationServer
              paginationTotalRows={listData.totalRecords}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationPerPage={pageSize}
              //selectableRows
              dense
              onSort={handleSort}
              sortServer
              // fixedHeader={{
              //   fixedHeader: true,
              //   fixedHeaderScrollHeight: "300px",
              // }}
            />
          )}
        </div>
      </div>
      {deleteModel && (
        <ConfirmModal
          title="Delete User"
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          cancelText="No"
          handleClose={() => setDeleteModel(false)}
          handleConfirm={deleteUserCall}
          width="300px"
        >
          <div className="childs">
            <p>Are you sure you want to delete this user?</p>
          </div>
        </ConfirmModal>
      )}
      {activeInactiveModal && (
        <ConfirmModal
          title={
            selectedUser?.status === 1
              ? "Mark Inactive User"
              : "Mark Active User"
          }
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          cancelText="No"
          handleClose={() => setActiveInactiveModal(false)}
          handleConfirm={handleMarkActiveInactiveConfirm}
          width="300px"
        >
          <div className="childs">
            <p>
              Do you want to{" "}
              {selectedUser?.status === 1 ? "Inactive" : "Active"}{" "}
              {selectedUser.name}?{" "}
            </p>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default UserManagement;

import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import {
  DownloadSVG,
  Star,
} from "../../../../../../../assets/svgComponents/svg";
// import { toast } from 'react-toastify';

import { useToasts } from "react-toast-notifications";

export const OrderDetailPage = (props) => {
  const [order, setOrder] = useState(props.order);
  return (
    <>
      {order && (
        <div className="orderDetailsContainer siteOrderDetailsContainer bg-gray-800">
          <div className="detailsContainer">
            {/*      <div className="downloadInvoice">
            <DownloadSVG />
            <span>Download Invoice</span>
          </div> */}
            <div className="row containerDetails">
              <div className="section_4 col-md-6 ps-0">
                <p className="mb-0">PLACED ON</p>
                <span>{moment(order?.createdOn).format("DD MMMM YYYY")}</span>
              </div>
              <div className="section_4 col-md-6">
                <p className="mb-0">BOOKING DURATION</p>
                <span>
                  {moment(order?.bookingDurationStartDate).format(
                    "DD MMMM YYYY"
                  )}{" "}
                  -{" "}
                  {moment(order?.bookingDurationEndDate).format("DD MMMM YYYY")}
                </span>
              </div>
            </div>
            <div className="containerDetails row">
              <div className="col-md-6 ps-0">
                <p className="mb-0">BILLING ADDRESS</p>
                {order && order?.customer && (
                  <span>
                    {order?.customer?.firstName} {order?.customer?.lastName} |{" "}
                    {order?.customer?.contactNo} {order?.billingAddress},{" "}
                    {order?.billingCity}, {order?.billingState},{" "}
                    {order?.billingCountry} {order?.billingPincode}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="paymentMainContainer">
            <div className="paymentContainer row">
              <div className="section paymentDetailsContainer col-md-6">
                <h6 className="mb-0">Payment Details</h6>
                <div className="subSection">
                  {order && order?.details && (
                    <Row>
                      <Col className="col-md-7">
                        <ul className="p-0">
                          {order?.details.map((detail, index) => {
                            return (
                              <li key={"detail_name_" + index}>
                                {detail.name}
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                      <Col className="col-md-5">
                        <ul>
                          {order?.details.map((detail, index) => {
                            return (
                              <li key={"details_amount_" + index}>
                                {detail.amount}
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                    </Row>
                  )}

                  <hr />
                  <Row className="totalamt">
                    <Col className="col-md-7">
                      <ul className="p-0">
                        <li>Total Amount</li>
                      </ul>
                    </Col>
                    <Col className="col-md-5">
                      <ul>
                        <li style={{ color: "#F99300" }}>
                          ${Number(order?.totalOrderAmount).toFixed(2)}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="section paymentTermsContainer col-md-6">
                <h6 className="mb-0">Payment Terms</h6>
                <div className="subSection">
                  {order.paymentTerms &&
                    order.paymentTerms.map((pt, index) => (
                      <Row key={"pt_" + index}>
                        <Col className="col-md-7 left-section">
                          {pt.isDownPayment && (
                            <div className="mb-3">
                              <p>Downpayment</p>
                              <span style={{ color: "#58B85C" }}>
                                Paid on{" "}
                                {pt.transactionDate &&
                                  moment(pt.transactionDate).format(
                                    "DD MMMM YYYY"
                                  )}
                              </span>
                            </div>
                          )}
                          {!pt.isDownPayment && (
                            <div className="mb-3">
                              <p>{pt.installmentNo}st Payment</p>
                              <span style={{ color: "#CE193F" }}>
                                Due on {pt.installmentNo}
                              </span>
                            </div>
                          )}
                        </Col>
                        <Col className="col-md-5">
                          {pt.isDownPayment && (
                            <div className="mb-3">
                              <p>$ {Number(pt.amount).toFixed(2)}</p>
                            </div>
                          )}
                          {!pt.isDownPayment && (
                            <div className="mb-3">
                              <p>$ {Number(pt.amount).toFixed(2)}</p>
                            </div>
                          )}
                        </Col>
                      </Row>
                    ))}
                </div>
              </div>
            </div>
            <div className="booking-section mt-4">
              <h6 className="mb-4">Booking Details</h6>
              {order &&
                order?.details &&
                order?.details.map((detail, index) => {
                  return (
                    <div
                      className="bookingContainer border-bottom  paymentContainer "
                      key={"od_bd_" + index}
                    >
                      <Row className="w-100 ">
                        <Col className="col-md-7">
                          <div>
                            <p className="mb-0">
                              {detail.itemType === "SITE"
                                ? "LOCATION"
                                : detail.itemType}
                              :
                            </p>
                            <span>{detail.name}</span>
                          </div>
                          {detail.signDeliveryAddress ? (
                            <div>
                              <p className="mb-0">DELIVERY LOCATION:</p>
                              <span>
                                {detail.signDeliveryAddress.googleAddress}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div>
                            <p className="mb-0">AMOUNT:</p>
                            <span>$ {detail.amount}</span>
                          </div>
                        </Col>
                        <Col className="col-md-5 ps-1">
                          <div>
                            <p className="mb-0">
                              STATUS:
                              <span
                                style={{ color: "#F99300", marginLeft: "10px" }}
                              >
                                Update Status
                              </span>
                            </p>
                            <span>
                              Order Placed at{" "}
                              {detail.createdOn &&
                                moment(detail.createdOn).format("DD MMMM YYYY")}
                            </span>
                          </div>
                          {order.serviceNames ? (
                            <div>
                              <p className="mb-0">SERVICES:</p>
                              <span>Installation, Design</span>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  );
                })}
            </div>

            <div className="reviewContainer">
              <h6>Ratings & Reviews</h6>
              <span>
                <Star fill="#FEE164" />
              </span>
              <span>
                <Star fill="#FEE164" />
              </span>
              <span>
                <Star fill="#FEE164" />
              </span>
              <span>
                <Star fill="#FEE164" />
              </span>
              <span>
                <Star fill="#808080" />
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

import { http } from "../../httpService";

export function siteListings(reqBody) {
  return http.post(`api/Listing/SearchForProviderNew`, reqBody);
}

export function updateListing(ListingId) {
  return http.get(`/api/Listing/${ListingId}`);
}

export function signListings(reqBody) {
  return http.post(`api/Listing/SearchForProviderNew`, reqBody);
}

export function bundleListings(reqBody) {
  return http.post(`/api/Listing/SearchForProviderNew`, reqBody);
}

export function getSelectSite(data) {
  return http.post(`/api/Site/List`, data);
}
export function getSelectSign(reqBody) {
  return http.post(`/api/Sign/List`, reqBody);
}
export function getAddServices(reqBody) {
  return http.post(`/api/Service/Index`, reqBody);
}
export function getAddCharges(reqBody) {
  return http.post(`/api/Charge/List`, reqBody);
}
export function createSiteListing(reqBody) {
  return http.post(`/api/Listing`, reqBody);
}
export function updateCreatedListing(reqBody){
  return http.post(`/api/Listing/Update`,reqBody)
}
// fetch dropdown selection
export function getSelectionType(name) {
  return http.get(`/api/ListingPayment/${name}/Options`);
}

export function getOptionsType(name){
  return http.get(`/api/Sign/${name}/Options`)
}
export function getRefundTypesWithRules(){
  return http.get(`/api/Listing/GetRefundTypesWithRules`)
}
export function getListingEditData(ListingId){
  return http.get(`/api/Listing/${ListingId}`)
}
export function getListingDetails(ListingItemId){
  return http.get(`/api/Listing/${ListingItemId}/Details`)
}
export function getImageUrl(fileKey){
  return http.get(`/GetFileUrl/${fileKey}`)
}
export function getRecentTopFiveCard(){
  return http.get(`/api/User/RecentTopFivePaymentCard`)
}
export function updateDefaultAddress(AddressId){
  return http.post(`/api/User/setDefaultAddress/${AddressId}`)
}
export function updateDefaultCard(PaymentCardId){
  return http.post(`/api/User/SetDefaultPaymentCard/${PaymentCardId}`)
}
export function getDefaultAddress(AddressId){
  return http.get(`/api/User/getDefaultAddress`)
}
export function createListingPayment(reqBody){
  return http.post(`/api/ListingPayment`,reqBody)
}
export function deleteListings(id){
  return http.delete(`/api/Listing/${id}`)
}

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { DeleteSVG, SVGBack } from "../../../../../assets/svgComponents/svg";
import * as Yup from "yup";
import RangePicker from "react-range-picker";

import {
  NotificationSVG,
  PlusSVG,
} from "../../../../../assets/svgComponents/table";
import { InputField } from "../../../../../components/InputField";
import NavBar from "../../../../../components/navBar";
import { SelectionComponent } from "../../../../../components/SelectionComponent/SelectionComponent";
import "./offermanagement.scss";
import { SelectIdCard } from "./SelectIdCard";
import { SelectIdCardName } from "./SelectIdCardName";
import Grid from "../../../../../components/grid/Grid";
import { BtnSwitch } from "../../../../../components/BtnSwitch/BtnSwitch";
import { RenderField } from "./RenderField";
import { useHistory } from "react-router";
import {
  getEnvironmentType,
  getUpdateOnBoardingStepDone,
} from "../../../../../services/Provider";
import {
  addOffer,
  inventoryServices,
  inventorySite,
} from "../../../../../services/Provider/Configurator/Inventory";
import DataTable from "react-data-table-component";
import { CustomSort } from "../../../../../components/DataTable/CustomSort";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import DatePicker from "react-datepicker";
import { AiOutlinePercentage } from "react-icons/ai";

export const AddOffer = () => {
  const history = useHistory();
  const [selectedServiceId, setSelectedServiceId] = useState([]);
  const [selectService, setSelectService] = useState([]);
  const [site, setSite] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState([]);
  const [audienceDefOption, setAudienceDefOption] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [siteModal, setSiteModal] = useState(false);
  const [typeOffer, setTypeOfOffer] = useState("0");
  const [offerCondition, setOfferCondition] = useState();
  const [conditionOprator, setConditionOprator] = useState();
  const [servicesData, setServicesData] = useState();
  const [mediaTypes, setMediaTypes] = useState([]);
  const [sitesData, setSitesData] = useState([]);
  const [condOfferId, setCondOfferId] = useState("");
  const [unlimted, setUnlimted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [typeOfAud, setTypeOfAud] = useState();
  const [selMediaList, setSelMediaList] = useState([]);
  const [date, onChange] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [offerType, setOfferType] = useState();
  const [orderValue, setOrderValue] = useState({
    condition: "",
    operator: "",
    value: "",
  });
  const [totalSign, setTotalSign] = useState({
    condition: "",
    operator: "",
    value: "",
  });
  const [media, setMedia] = useState({
    condtion: "",
    operator: "",
    value: "",
  });
  const [selsite, setSelSite] = useState({
    condition: "",
    operator: "",
    value: "",
  });

  const [uwo, setUwo] = useState({
    audence: "",
    oprator: "",
    value: "",
  });
  const [uws, setUws] = useState({
    audence: "",
    oprator: "",
    value: "",
  });

  const [renderInput, setRenderInput] = useState([
    {
      id: 0,
      field1: "Order Value",
      fiel2: "select",
      field3: "Order Value",
    },
  ]);

  const [renderAudence, setRenderAudence] = useState([
    {
      id: 0,
      field1: "Order Value",
      fiel2: "select",
      field3: "Order Value",
    },
  ]);
  let tempStepCount = parseInt(localStorage.getItem("onBoardingStepDone"));

  const { addToast } = useToasts();
  useEffect(() => {
    getEnvironmentType("OfferTypes")
      .then((res) => {
        if (res.status === 200) {
          setOfferType(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    getEnvironmentType("MediaTypes")
      .then((res) => {
        if (res.status === 200) {
          setMediaTypes(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    getEnvironmentType("AudienceTypes")
      .then((res) => {
        if (res.status === 200) {
          setAudienceDefOption(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    getEnvironmentType("OfferConditionTargets")
      .then((res) => {
        if (res.status === 200) {
          setOfferCondition(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    getEnvironmentType("ConditionOperators")
      .then((res) => {
        if (res.status === 200) {
          setConditionOprator(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    const reqBody = {
      page: 1,
      pageSize: 10,
      orderBy: "",
      export: false,
      searchString: "",
      status: true,
    };
    inventoryServices(reqBody)
      .then((res) => {
        if (res.status == 201) {
          setServicesData(res.data.records);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(async () => {
    const reqBody = {
      page: 1,
      pageSize: 10,
      orderBy: "",
      export: false,
      searchString: "",
      status: true,
    };
    await inventorySite(reqBody)
      .then((res) => {
        if (res.status == 200) {
          setSitesData(res.data.data.records);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleUnlimitedTogal = () => {
    setUnlimted(!unlimted);
    setIsDisabled(!isDisabled);
  };
  const handleNumberOfSign = (e, index) => {
    let signValue = e.target.value;
    setTotalSign((totalSign) => ({ ...totalSign, value: signValue }));
  };

  const handleOrderValue = (e, index) => {
    let orValue = e.target.value;
    setOrderValue((orderValue) => ({ ...orderValue, value: orValue }));
  };

  const selectServices = () => {
    setServiceModal(true);
  };

  const handleSelectService = (state) => {
    setSelectService(state.selectedRows);
  };

  const handleAddService = () => {
    setServiceModal(false);
    let result = selectService.map((a) => a.id);
    setSelectedServiceId(result);
  };

  const removeService = (e, id) => {
    setSelectedServiceId(selectedServiceId.filter((i) => i != id));
    setSelectService(selectService.filter((elm) => elm.id != id));
  };

  const selectSite = () => {
    setSiteModal(true);
  };

  const handleSelectSite = (state) => {
    setSite(state.selectedRows);
  };
  const handleAddSite = () => {
    setSiteModal(false);
    let result = site.map((a) => a.id);
    setSelectedSiteId(result);
  };
  const removeSelectedSite = (e, id) => {
    setSelectedSiteId(selectedSiteId.filter((i) => i != id));
    setSite(site.filter((elm) => elm.id != id));
  };

  const handleOnCancel = () => {
    history.push("/provider/configurator/offer-management");
  };

  const addMoreMoreAudienceDef = () => {
    // setAudienceDef((audienceDef) => [...audienceDef, countAud]);
    let obj = {
      id: renderAudence.length,
      field1: "select",
      fiel2: "select",
      field3: "Order Value",
    };
    setRenderAudence([...renderAudence, obj]);
  };
  const removeAudienceDef = (item) => {
    setRenderAudence(renderAudence.filter((e) => e.id != item.id));
  };

  const addMoreCondition = () => {
    let obj = {
      id: renderInput.length,
      field1: "select",
      fiel2: "select",
      field3: "Order Value",
    };
    setRenderInput([...renderInput, obj]);
  };
  const removeCondition = (e, item, index) => {
    console.log("item", item);
    setRenderInput(renderInput.filter((elm) => elm.id != item.id));
  };

  const handleOfferType = (e) => {
    let offerType = e.target.value;
    setTypeOfOffer(offerType);
  };

  const hanleOfferCondition = (value, index) => {
    let offerId = value.target.value;
    setCondOfferId(offerId);
    let offer = offerCondition.filter((item) => {
      if (item.id == offerId) {
        return item;
      }
    });
    setRenderInput(
      renderInput.map((item) =>
        item.id === index ? { ...item, field3: offer && offer[0].name } : item
      )
    );
    // setOfferCondition(offerCondition.filter((elm) => elm.id != offerId));
    if (offerId == 0) {
      setOrderValue((orderValue) => ({
        ...orderValue,
        condition: offerId,
      }));
    } else if (offerId == 1) {
      setMedia((media) => ({
        ...media,
        condition: offerId,
      }));
    } else if (offerId == 2) {
      setTotalSign((totalSign) => ({
        ...totalSign,
        condition: offerId,
      }));
    } else {
      setSelSite((selSite) => ({
        ...selSite,
        condition: offerId,
      }));
    }
  };

  const hanleConditionOprator = (value, index) => {
    let oprator = value.target.value;
    if (condOfferId == 0) {
      setOrderValue((orderValue) => ({
        ...orderValue,
        operator: oprator,
      }));
    } else if (condOfferId == 1) {
      setMedia((media) => ({
        ...media,
        operator: oprator,
      }));
    } else if (condOfferId == 2) {
      setTotalSign((totalSign) => ({
        ...totalSign,
        operator: oprator,
      }));
    } else {
      setSelSite((selSite) => ({
        ...selSite,
        operator: oprator,
      }));
    }
  };

  const hanleAudience = (e) => {
    let audencetype = e.target.value;
    setTypeOfAud(audencetype);
    if (audencetype == 0) {
      setUwo((uwo) => ({ ...uwo, audence: audencetype }));
    } else {
      setUws((uws) => ({ ...uws, audence: audencetype }));
    }
  };

  const hanleAudienceOprator = (e) => {
    let oprator = e.target.value;
    if (typeOfAud == 0) {
      setUwo((uwo) => ({ ...uwo, oprator: oprator }));
    } else {
      setUws((uws) => ({ ...uws, oprator: oprator }));
    }
  };

  const handleAudence = (e) => {
    let value = e.target.value;
    if (typeOfAud == 0) {
      setUwo((uwo) => ({ ...uwo, value: value }));
    } else {
      setUws((uws) => ({ ...uws, value: value }));
    }
  };

  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "location",
      selector: (row) => row.locationId,
      sortable: true,
    },
    {
      name: "environment",
      selector: (row) => row.environmentId,
      sortable: true,
    },
    {
      name: "status",
      selector: (row) => row.statusName,
      sortable: true,
    },
  ];

  const siteColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "location",
      selector: (row) => row.locationId,
      sortable: true,
    },
    {
      name: "environment",
      selector: (row) => row.environmentId,
      sortable: true,
    },
    {
      name: "status",
      selector: (row) => row.statusName,
      sortable: true,
    },
  ];

  let validationShape = Yup.object().shape({
    name: Yup.string()
      .min(5, "Please enter title with five or more characters")
      .required("Title is required"),
    offerValue: Yup.string()
      .matches(new RegExp("[0-9]{2}"), "Please enter number")
      .required("Offer value is required"),
  });

  if (typeOffer == 2) {
    validationShape = Yup.object().shape({
      name: Yup.string()
        .min(5, "Please enter title with five or more characters")
        .required("Title is required"),
    });
  }
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      orderValue: "",
      audienceValue: "",
      userPerPerson: "",
      numberofuser: "",
      offerValue: "",
    },
    validationSchema: validationShape,
    onSubmit: (values) => {
      const siteObj = selectedSiteId.map((siteId) => ({ siteId: siteId }));
      const serviceObj = selectedServiceId.map((serviceId) => ({
        serviceId: serviceId,
      }));
      const mediaTypeId = selMediaList.map((id) => ({ mediaTypeId: id }));
      let conditionArr = [
        {
          conditionOn: orderValue.condition,
          operator: orderValue.operator,
          value: orderValue.value,
        },
        {
          conditionOn: totalSign.condition,
          operator: totalSign.operator,
          value: totalSign.value,
        },
        {
          conditionOn: media.condition,
          operator: media.operator,
          value: media.value,
        },
        {
          conditionOn: selsite.condition,
          operator: selsite.operator,
          value: "",
        },
      ];

      let audiencesArr = [
        {
          audienceType: uwo.audence,
          operator: uwo.operator,
          value: uwo.value,
        },
        {
          audienceType: uws.audence,
          operator: uws.operator,
          value: uws.value,
        },
      ];

      let condition = conditionArr.filter((elm) => elm.operator !== "");
      let audience = audiencesArr.filter((elm) => elm.audienceType != "");
      let formData = {
        code: "",
        name: values.name,
        details: values.description,
        image: "",
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        offerType: typeOffer,
        offerValue: values.offerValue,
        disabled: false,
        totalNumberOfUses: values.numberofuser ? values.numberofuser : "0",
        usesPerPerson: values.userPerPerson ? values.userPerPerson : "1",
        unlimited: unlimted,
        conditions: condition,
        services: serviceObj,
        sites: siteObj,
        audiences: audience,
        mediaTypes: mediaTypeId,
      };

      addOffer(formData)
        .then((res) => {
          if (res.status == 201) {
            if (tempStepCount == 4) {
              localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
              let bodyData = [
                {
                  path: "/onBoardingStepDone",
                  op: "add",
                  value: tempStepCount + 1,
                },
              ];
              getUpdateOnBoardingStepDone(bodyData)
                .then((res) => {
                  console.log("res", res);
                })
                .catch((err) => {
                  console.log("err", err);
                });
              history.push("/provider");
            } else {
              history.push("/provider/configurator/offer-management");
              addToast("Offer created successfully", {
                appearance: "success",
              });
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
          addToast(err.message, {
            appearance: "error",
          });
        });
    },
  });

  const titleError = formik.touched.name && formik.errors.name;
  const offerValueError = formik.touched.offerValue && formik.errors.offerValue;

  const onSelect = (selectedList, selectedItem) => {
    let ids = selectedList.map((elm) => elm.id);
    setSelMediaList(ids);
  };

  const onRemove = (selectedList, removedItem) => {
    let ids = selectedList.map((elm) => elm.id);
    setSelMediaList(ids);
  };

  const onDateChanges = (date1, date2) => {
    setStartDate(date1);
    setEndDate(date2);
  };

  return (
    <div className="main_SubSection">
      <Form onSubmit={formik.handleSubmit}>
        <NavBar
          blueBtnText="Save"
          whiteBtnText="Cancel"
          notificationSVG={<NotificationSVG />}
          navHeading="Add Offer"
          subHeading="Configurator • All Offers"
          subActiveHeading="Add Offer"
          type="submit"
          handleWhitebtn={handleOnCancel}
          showButton={true}
        />
        <div className="p-20">
          <div className="offer_main_container">
            <div className="offer_overview ps-3">
              <h6 className="mt-4">Offer Overview</h6>
              <div>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <InputField
                      type="text"
                      name="name"
                      value={formik.values.name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder=" Offer name"
                      label="Offer Name"
                      inputFieldSize="inputsize"
                    />
                    {titleError && (
                      <p
                        className="text-danger mt-1 ms-2"
                        style={{ fontSize: "14px" }}
                      >
                        {formik.errors.name}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <InputField
                      type="text"
                      name="description"
                      value={formik.values.description}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder=" Description"
                      label="Description"
                      inputFieldSize="inputsize"
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <SelectionComponent
                    label="Offer Type"
                    option={offerType}
                    inputsize={"inputsize col-md-6"}
                    handleOnchange={handleOfferType}
                  />
                  {typeOffer === "2" && (
                    <div className="selectContainer col-md-6">
                      <p>Select Service</p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="d-flex justify-content-start flex-wrap"
                          style={{ width: "75%" }}
                        >
                          {selectService.length > 0 ? (
                            selectService.slice(0, 3).map((elm) => {
                              return (
                                <SelectIdCardName
                                  id={elm.id}
                                  name={elm.name}
                                  onClick={removeService}
                                />
                              );
                            })
                          ) : (
                            <label style={{ color: "#808080" }}>
                              select services
                            </label>
                          )}
                          {selectService.length > 3 ? (
                            <div className="idbox me-1">
                              <h6
                                className="mt-2"
                                style={{ color: "#F99300", cursor: "pointer" }}
                                onClick={() => setShowMore(!showMore)}
                              >+{selectService.length - 3} more</h6>
                            </div>
                          ) : null}
                        </div>

                        <div onClick={selectServices} className="selectMore">
                          <PlusSVG fill="#F99300" />{" "}
                          <span style={{ color: "#F99300", cursor: "pointer" }}>
                            {selectedServiceId.length > 0
                              ? "Select more"
                              : "Select"}
                          </span>
                        </div>
                      </div>
                      {showMore && selectService.length > 3 ? (
                        <div
                          className="dropdownModal "
                          style={{ right: "47%" }}
                        >
                          {selectService.length > 0
                            ? selectService
                              .slice(3, selectService.length)
                              .map((elm) => {
                                return (
                                  <SelectIdCardName
                                    id={elm.id}
                                    name={elm.name}
                                    onClick={removeService}
                                  />
                                );
                              })
                            : null}
                        </div>
                      ) : null}
                    </div>
                  )}
                  {typeOffer == 0 && (
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        name="offerValue"
                        value={formik.values.offerValue}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder=" Value"
                        label="Value"
                        inputFieldSize="inputsize"
                      />
                      {offerValueError && (
                        <p
                          className="text-danger mt-1 ms-2"
                          style={{ fontSize: "14px" }}
                        >
                          {formik.errors.offerValue}
                        </p>
                      )}
                    </div>
                  )}
                  {typeOffer == 1 && (
                    <div className="col-md-6">
                      <div className="input-group pfield">
                        <InputField
                          type="text"
                          name="offerValue"
                          value={formik.values.offerValue}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder=" Value"
                          label="Value"
                          inputFieldSize="inputsize"
                        />
                        <button
                          type="button"
                          className="btn btn-outline-default"
                          style={{
                            backgroundColor: "white",
                            borderRight: "1px #efefef solid",
                            borderBottom: "1px #efefef solid",
                            borderTop: "1px #efefef solid",
                          }}
                        >
                          <AiOutlinePercentage />
                        </button>
                        {offerValueError && (
                          <p
                            className="text-danger mt-1 ms-2"
                            style={{ fontSize: "14px" }}
                          >
                            {formik.errors.offerValue}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  {/* typeOffer == 2 && (
                    <SelectionComponent
                      label="Offer Type"
                      option={offerType}
                      inputsize={"inputsize col-md-6"}
                      handleOnchange={handleOfferType}
                    />
                  ) */}
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <label className="offer_validit_lbl">Validity</label>
                    <DatePicker
                      placeholderText="Select validity dates"
                      selectsRange
                      showYearDropdown
                      isClearable={true}
                      monthsShown={2}
                      minDate={new Date()}
                      startDate={startDate ? new Date(startDate) : null}
                      endDate={endDate ? new Date(endDate) : null}
                      onChange={(update) => {
                        if (update && update.length > 0 && update[0])
                          setStartDate(new Date(update[0]));
                        else setStartDate(null);
                        if (update && update.length > 1 && update[1])
                          setEndDate(new Date(update[1]));
                        else setEndDate(null);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="offer_condition ps-3">
              <h6 className="mb-4 mt-5">Offer Conditions</h6>
              {renderInput.map((elm, index) => {
                return (
                  <>
                    <Row className="pb-3 ">
                      <Col md={3} className="">
                        <SelectionComponent
                          option={offerCondition}
                          handleOnchange={hanleOfferCondition}
                          inputsize={"inputsizeSelect"}
                          index={index}
                          selected="Offer Condition"
                        />
                      </Col>
                      <Col md={3}>
                        <SelectionComponent
                          option={conditionOprator}
                          handleOnchange={hanleConditionOprator}
                          inputsize={"inputsizeSelect"}
                          index={index}
                          selected="Offer Operator"
                        />
                      </Col>
                      <Col md={3}>
                        <RenderField
                          selection={elm.field3}
                          option={mediaTypes}
                          name1={"Value"}
                          name2={"Value"}
                          handleNumberOfSign={handleNumberOfSign}
                          handleOrderValue={handleOrderValue}
                          selectedSiteId={selectedSiteId}
                          selectedServiceId={selectedServiceId}
                          removeSelectedSite={removeSelectedSite}
                          selectSite={selectSite}
                          setSelMediaList={setSelMediaList}
                          selMediaList={selMediaList}
                          orderValue={orderValue}
                          numberOfSign={totalSign}
                          onSelect={onSelect}
                          onRemove={onRemove}
                          index={index}
                        />
                      </Col>
                      <Col md={3}>
                        <div
                          className="deleteSvgContainer"
                          onClick={(e) => removeCondition(e, elm, index)}
                        >
                          <DeleteSVG />
                        </div>
                      </Col>
                    </Row>
                  </>
                );
              })}

              <div
                className={`mt-1`}
                style={{
                  color: "#F99300",
                  cursor: "pointer",
                  display: "inline-block",
                }}
                onClick={addMoreCondition}
              >
                <PlusSVG fill="#F99300" /> <span>Add more</span>
              </div>
            </div>
            <div className="audience_Defination offer_condition ps-3">
              <h6 className="mt-5 mb-4">Audience Definition</h6>

              {renderAudence &&
                renderAudence.map((item, index) => {
                  return (
                    <Row className="pb-3 ">
                      <Col md={3}>
                        <SelectionComponent
                          option={audienceDefOption}
                          handleOnchange={hanleAudience}
                          inputsize={"inputsizeSelect"}
                          selected="Audience"
                        />
                      </Col>
                      <Col md={3}>
                        <SelectionComponent
                          option={conditionOprator}
                          handleOnchange={hanleAudienceOprator}
                          inputsize={"inputsizeSelect"}
                          selected="Audience"
                        />
                      </Col>
                      <Col md={3}>
                        <InputField
                          type="text"
                          name="audienceValue"
                          onChange={(e) => handleAudence(e)}
                          placeholder=" Value"
                          label="Value"
                          inputFieldSize="inputsizeSelect"
                        />
                      </Col>
                      <Col md={3}>
                        <div
                          className="deleteSvgContainer"
                          onClick={(e) => removeAudienceDef(item)}
                        >
                          <DeleteSVG />
                        </div>
                      </Col>
                    </Row>
                  );
                })}

              <div
                className="mt-1"
                style={{
                  color: "#F99300",
                  cursor: "pointer",
                  display: "inline-block",
                }}
                onClick={addMoreMoreAudienceDef}
              >
                <PlusSVG fill="#F99300" /> <span>Add more</span>
              </div>
            </div>
            <div className="usage_Container ps-3">
              <h6 className="mb-4 mt-5">Usage</h6>
              {!unlimted && (
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      type="text"
                      name="numberofuser"
                      value={formik.values.numberofuser}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder=" Total Number of Uses"
                      label="Total Number of Uses"
                      inputFieldSize="inputsize"
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      type="text"
                      name="userPerPerson"
                      value={formik.values.userPerPerson}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder=" Total Number of Uses"
                      label="Uses Per Person"
                      inputFieldSize="inputsize"
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              )}
              <div className="mt-4 mb-5 switchButton col-md-5">
                <BtnSwitch text={"Unlimited"} action={handleUnlimitedTogal} />
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Modal
        show={serviceModal}
        onHide={() => setServiceModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 bookingModal entitlementModal modalInnerScroll p-0"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modalTitlebox">
            <SVGBack />
            <h5>Select Service</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card mx-3 ">
            <DataTable
              columns={columns}
              data={servicesData}
              sortFunction={<CustomSort />}
              allowOverflow={true}
              selectableRows
              selectableRowsHighlight
              onSelectedRowsChange={handleSelectService}
              fixedHeader={{
                fixedHeader: true,
                fixedHeaderScrollHeight: "300px",
              }}
              pagination
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start ">
          <div className="d-flex ">
            <button
              className="blueBgButton px-5 py-2 "
              onClick={handleAddService}
            >
              Add
            </button>
            <span className="ms-2 mt-1">{`${selectService && selectService.length
              } Service Selected`}</span>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={siteModal}
        onHide={() => setSiteModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 bookingModal entitlementModal modalInnerScroll p-0"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modalTitlebox">
            <SVGBack />
            <h5>Select Service</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card mx-3 ">
            <DataTable
              columns={siteColumns}
              data={sitesData}
              sortFunction={<CustomSort />}
              allowOverflow={true}
              selectableRows
              selectableRowsHighlight
              onSelectedRowsChange={handleSelectSite}
              fixedHeader={{
                fixedHeader: true,
                fixedHeaderScrollHeight: "300px",
              }}
              pagination
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start ">
          <div className="d-flex ">
            <button className="blueBgButton px-5 py-2 " onClick={handleAddSite}>
              Add
            </button>
            {/* <span className="ms-2 mt-1">{`${selectService&&selectService.length} Service Selected`}</span> */}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, ListGroup, Badge } from "react-bootstrap";
import { CustomTile } from "../../../../../components/Tile/CustomTile";
import { LineChart } from "../../../../../components/Chart/LineChart";
import { SelectComponent } from "../../../../../components/SelectionComponent/SelectComponent";
import { getOfferAnalytics } from "../../../../../services/Admin/HomePage";
export const InsightPage = ({ offerDetails }) => {
  const offerId = offerDetails.id;
  const today = new Date();
  const curYear = today.getFullYear();
  const curMonth = today.getMonth() + 1;
  const curDate = today.getDay();
  const lastDate = daysInMonth(curMonth, curYear);
  const [orderPer, setOrderPer] = useState(0);
  const [couponRevenuePer, setCouponRevenuePer] = useState(0);
  const [couponDisPer, setCouponDisPer] = useState(0);
  const [repeatUserPer, setRepeatUserPer] = useState(0);
  const [adminDashBoardData, setAdminDashBoardData] = useState({});
  const [dashboardGraphBy, setDashboardGraphBy] = useState({
    id: "Year",
    name: "Year",
  });
  const [salesGraphData, setSalesGraphData] = useState([]);
  const [salesGraphDataTitle, setSalesGraphDataTitle] = useState([]);
  const [selectedInsight, setSelectedInsight] = useState({
    id: 5,
    name: "This Month",
  });
  const [salesGraphBy, setSalesGraphBy] = useState({
    id: "Year",
    name: "Year",
  });
  const [salesYear, setSalesYear] = useState({ id: curYear, name: curYear });
  const [salesMonth, setSalesMonth] = useState({ id: "01", name: "01" });
  const [salesDay, setSalesDay] = useState({ id: "01", name: "01" });
  const yearList = [];
  for (var y = curYear; y >= 2021; y--) {
    let obj = { id: y, name: y };
    yearList.push(obj);
  }

  const monthList = [];
  for (var m = 1; m <= 12; m++) {
    let mon = ("0" + m).slice(-2);
    let obj = { id: mon, name: mon };
    monthList.push(obj);
  }
  let temp = [];
  for (var d = 1; d <= 31; d++) {
    let day = ("0" + d).slice(-2);
    let obj = { id: day, name: day };
    temp.push(obj);
  }
  const [salesDayList, setSalesDayList] = useState(temp);

  const GraphByItem = [
    { id: "Year", name: "Year" },
    { id: "Month", name: "Month" },
    { id: "Day", name: "Day" },
  ];

  let FromDate = curYear + "-01-01 00:00:00";
  let ToDate = curYear + "-12-31 23:59:59";
  let reqBody =
    "?offerId=" +
    offerId +
    "&Type=Year&FromDate=" +
    FromDate +
    "&ToDate=" +
    ToDate;
  const [salesReqQuery, setSalesReqQuery] = useState(reqBody);
  // fetch data for by provider dashboard
  useEffect(async () => {
    getOfferAnalytics(salesReqQuery)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data, "admin Data");
          setAdminDashBoardData(res.data);
          let revenue = res.data.revenue;
          let lastRevenue = res.data.lastRevenue;
          let discountFromCoupon = res.data.discountFromCoupon;
          let lastDiscountFromCoupon = res.data.lastDiscountFromCoupon;
          let totalOrderWithCoupon = res.data.totalOrderWithCoupon;
          let lastTotalOrderWithCoupon = res.data.lastTotalOrderWithCoupon;
          let repeatUser = res.data.repeatUser;
          let lastRepeatUser = res.data.lastRepeatUser;   
          
          let diff = totalOrderWithCoupon-lastTotalOrderWithCoupon;
          let avg = (totalOrderWithCoupon+lastTotalOrderWithCoupon)/2;
          let per = ((diff/avg)*100).toFixed(2);
          setOrderPer(isNaN(per)?0:per);

          diff = revenue-lastRevenue;
          avg = (revenue+lastRevenue)/2;
          per = ((diff/avg)*100).toFixed(2);
          setCouponRevenuePer(isNaN(per)?0:per);

          diff = discountFromCoupon-lastDiscountFromCoupon;
          avg = (discountFromCoupon+lastDiscountFromCoupon)/2;
          per = ((diff/avg)*100).toFixed(2);
          setCouponDisPer(isNaN(per)?0:per);

          diff = repeatUser-lastRepeatUser;
          avg = (repeatUser+lastRepeatUser)/2;
          per = ((diff/avg)*100).toFixed(2);
          setRepeatUserPer(isNaN(per)?0:per);

          let temp1 = [];
          let temp2 = [];
          if (res.data.saleGraphData != null) {
            let xvalueStart = 0;
            let xvalueEnd = 0;
            if (dashboardGraphBy === "Year") {
              xvalueEnd = 12;
            } else if (dashboardGraphBy === "Month") {
              xvalueEnd = 31;
            } else {
              xvalueStart = 0;
              xvalueEnd = 23;
            }
            for (let i = xvalueStart; i <= xvalueEnd; i++) {
              temp2.push(i);
              temp1.push("0");
            }
            res.data.saleGraphData.map((spendData) => {
              temp1[spendData.xValue] = spendData.yValue;
              //temp1.push(salesData.yValue)
              //temp2.push(salesData.xValue)
            });
          }
          setSalesGraphData(temp1);
          setSalesGraphDataTitle(temp2);
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }, [salesReqQuery]);

  function daysInMonth(iMonth, iYear) {
    return new Date(iYear, iMonth, 0).getDate();
  }

  // sales graph start
  const SalesGraphSelectChange = (e) => {
    //e.preventDefault();
    //alert(e.target.value);
    //setSalesGraphBy(e.target.value);
    //e.preventDefault();
    setSalesGraphBy(e);
    let salesReqBody = "";
    if (e.id === "Year") {
      FromDate = salesYear.id + "-01-01 00:00:00";
      ToDate = salesYear.id + "-12-31 23:59:59";
      salesReqBody =
        "?offerId=" +
        offerId +
        "&Type=Year&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate;
    } else if (e.id === "Month") {
      let lastDate = daysInMonth(salesMonth.id, salesYear.id);
      FromDate = salesYear.id + "-" + salesMonth.id + "-01 00:00:00";
      ToDate =
        salesYear.id + "-" + salesMonth.id + "-" + lastDate + " 23:59:59";
      salesReqBody =
        "?offerId=" +
        offerId +
        "&Type=Month&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate;
    } else {
      FromDate =
        salesYear.id + "-" + salesMonth.id + "-" + salesDay.id + " 00:00:00";
      ToDate =
        salesYear.id + "-" + salesMonth.id + "-" + salesDay.id + " 23:59:59";
      salesReqBody =
        "?offerId=" +
        offerId +
        "&Type=Day&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate;
    }
    //setSalesReqQuery(reqBody);
    setSalesReqQuery(salesReqBody);
  };

  function SalesYearChange(e) {
    //alert(e.target.value);
    setSalesYear(e);
    let year = e.id;
    FromDate = year + "-01-01 00:00:00";
    ToDate = year + "-12-31 23:59:59";
    let salesReqBody =
      "?offerId=" +
      offerId +
      "&Type=" +
      salesGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setSalesReqQuery(salesReqBody);
  }
  function SalesMonthChange(e) {
    //alert(e.target.value);
    setSalesMonth(e);
    let month = e.id;
    let lastDate = daysInMonth(e.id, salesYear.id);
    //setCustomerDayList([]);
    let temp = [];
    for (var d = 1; d <= lastDate; d++) {
      let day = ("0" + d).slice(-2);
      let obj = { id: day, name: day };
      temp.push(obj);
    }
    setSalesDayList(temp);
    setSalesDay("01");

    FromDate = salesYear.id + "-" + month + "-01 00:00:00";
    ToDate = salesYear.id + "-" + month + "-" + lastDate + " 23:59:59";
    let salesReqBody =
      "?offerId=" +
      offerId +
      "&Type=" +
      salesGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setSalesReqQuery(salesReqBody);
  }

  function SalesDayChange(e) {
    //alert(e.target.value);
    setSalesDay(e);
    let day = e.id;
    FromDate = salesYear.id + "-" + salesMonth.id + "-" + day + " 00:00:00";
    ToDate = salesYear.id + "-" + salesMonth.id + "-" + day + " 23:59:59";
    let salesReqBody =
      "?offerId=" +
      offerId +
      "&Type=" +
      salesGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setSalesReqQuery(salesReqBody);
  }

  return (
    <Container fluid>
      <Row>
        <Col className="bg-base-100 dashboard-lside">
          <Row className="pt-4">
            <Col>
              <div className="d-flex align-items-center mb-3">
                <h1
                  className="d-inline float-start"
                  style={{
                    color: "#AFAFAF",
                    fontSize: "12px",
                    paddingRight: "10px",
                    marginBottom: "0",
                  }}
                >
                  PERFORMANCE
                </h1>
                <p className="d-flex float-start yr-summary">
                  <div className="me-1">
                    <SelectComponent
                      label=""
                      option={GraphByItem}
                      value={salesGraphBy}
                      defaultValue={salesGraphBy}
                      inputsize="form-control-sm rouned-3 sel-chart float-start me-2"
                      handleOnchange={SalesGraphSelectChange}
                    />
                  </div>
                  <div className="me-1">
                    <SelectComponent
                      label=""
                      option={yearList}
                      defaultValue={salesYear}
                      value={salesYear}
                      inputsize="form-control-sm rouned-3 sel-chart float-start"
                      handleOnchange={SalesYearChange}
                    />{" "}
                  </div>
                  {salesGraphBy.id === "Month" || salesGraphBy.id === "Day" ? (
                    <div className="me-1">
                      <SelectComponent
                        label=""
                        option={monthList}
                        value={salesMonth}
                        defaultValue={salesMonth}
                        inputsize="form-control-sm rouned-3 sel-chart float-start"
                        handleOnchange={SalesMonthChange}
                      />{" "}
                    </div>
                  ) : null}
                  {salesGraphBy.id === "Day" ? (
                    <SelectComponent
                      label=""
                      option={salesDayList}
                      value={salesDay}
                      defaultValue={salesDay}
                      inputsize="form-control-sm rouned-3 sel-chart float-start"
                      handleOnchange={SalesDayChange}
                    />
                  ) : null}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={3} className="dcard active">
              <div className="dashcard">
                <CustomTile
                  heading="Orders with coupon"
                  amount={"$ " + adminDashBoardData.totalOrderWithCoupon} // adminDashBoardData.revenue
                  description={
                    "+" +
                    orderPer +
                    "% than last month"
                  } // adminDashBoardData.lastRevenue
                  active="true"
                />
              </div>
            </Col>
            <Col xs={3} className="dcard">
              <div className="dashcard">
                <CustomTile
                  heading="Revenue from coupon"
                  amount={"$ " + adminDashBoardData.revenue} // adminDashBoardData.revenue
                  description={
                    "+" + couponRevenuePer + "% than last month"
                  } // adminDashBoardData.lastRevenue
                  active="true"
                />
              </div>
            </Col>
            <Col xs={3} className="dcard">
              <div className="dashcard">
                <CustomTile
                  heading="Discount from coupon"
                  amount={"$ " + adminDashBoardData.discountFromCoupon} // adminDashBoardData.revenue
                  description={
                    "+" + couponDisPer +
                    "% than last month"
                  } // adminDashBoardData.lastRevenue
                  active="true"
                />
              </div>
            </Col>
            <Col xs={3} className="dcard">
              <div className="dashcard">
                <CustomTile
                  heading="Repeat users"
                  amount={"$ " + adminDashBoardData.repeatUser} // adminDashBoardData.revenue
                  description={
                    "+" + repeatUserPer +
                    "% than last month"
                  } // adminDashBoardData.lastRevenue
                  active="true"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="bg-base-100 dashboard-lside">
          <Row>
            <Col>
              <div className="d-flex align-items-center mb-3">
                <h1
                  className="d-flex float-start"
                  style={{
                    color: "#AFAFAF",
                    fontSize: "12px",
                    paddingRight: "10px",
                    marginBottom: "0",
                  }}
                >
                  USAGE TREND
                </h1>
              </div>
              <div className="d-flex align-items-center mb-3">
                <h1
                  className="d-flex float-start"
                  style={{
                    color: "#AFAFAF",
                    fontSize: "12px",
                    paddingRight: "10px",
                    marginBottom: "0",
                  }}
                >
                  Uses ${adminDashBoardData.repeatUser}
                </h1>
                <Badge pill bg="success">
                  + {repeatUserPer} %
                </Badge>{" "}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <LineChart
                graphDataTitles={salesGraphDataTitle}
                graphData={salesGraphData}
                bgColor="#3734A9"
                hoverBgColor="#F9896B"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

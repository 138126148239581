import React from "react"
import { BySites } from '../BySites';
import { BySigns } from '../BySigns';
import { ByProvider } from '../ByProvider';

const Tabs = ["BY LOCATIONS", "BY SIGNS", "BY PROVIDERS"];

const SwitchTabDom = (props) => {
    switch (props.activeTab) {
        case Tabs[0]:
        return (
            <BySites
                activeTab={props.activeTab}
                setActiveTab={props.setActiveTab}
                tabs={props.tabs}
            />
        );
        case Tabs[1]:
        return (
            <BySigns
                activeTab={props.activeTab}
                setActiveTab={props.setActiveTab}
                tabs={props.tabs}
            />
        );
        case Tabs[2]:
        return (
            <ByProvider
                activeTab={props.activeTab}
                setActiveTab={props.setActiveTab}
                tabs={props.tabs}
            />
        );
        default:
        return <></>;
    }
};

export default SwitchTabDom;
import TableActions from "./TableActions";
import React from "react";
import SwitchTabDom from "./SwitchTabDom";
import { useContext, useState } from "react";
import { Badge, Col, Modal, Row } from "react-bootstrap";
import {
  ExportCSVSVG,
  NotificationSVG,
  PlusSVG,
} from "../../../../../assets/svgComponents/table";
import NavBar from "../../../../../components/navBar";
import { useHistory, useLocation } from "react-router";
import ProjectContext from "../../../../../context/ProjectContext";
import { useEffect } from "react";
import { InputField } from "../../../../../components/InputField";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import SiteFilters from "./modal/SiteFilters";
import { AddSitePage } from "./components/AddSitePage/AddSitePage";
import { AddServicePage } from "./components/AddServicePage/AddServicePage";
import { EditSitePage } from "./components/EditSitePage/EditSitePage";
import { ViewSiteInfo } from "./components/ViewSiteInfo/ViewSiteInfo";
import UpdatesModal from "../../../../../components/profile/Modal/UpdatesModal";

import { siteListings } from "../../../../../services/Provider/Configurator/Listings";
import {
  inventorySite,
  inventorySigns,
  inventoryServices,
  getEnvironment,
  inventoryCharges,
} from "../../../../../services/Provider/Configurator/Inventory";
import { getUpdateOnBoardingStepDone } from "../../../../../services/Provider";
import { checkPermission } from "../../../../../utils";

const tabs = ["LOCATIONS", "SIGNS", "SERVICES", "CHARGES"];
const listing = ["Commercial", "Residential", "Retail", "Transit"];

const Inventory = () => {
  const location = useLocation();
  const history = useHistory();
  // console.log('location', location)
  const { activeTabName } = useContext(ProjectContext);

  const [activeTab, setActiveTab] = useState(
    activeTabName ? activeTabName : tabs[0]
  );
  const [filtersModal, setFiltersModal] = useState(false);
  const [addNewTypeSiteModal, setAddNewTypeSiteModal] = useState(false);
  const [bulkUploadSitesModal, setBulkUploadSitesModal] = useState(false);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [girdActionPage, setGirdActionPage] = useState("viewSite");
  const [loader, setLoader] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);

  const onBoardingStepDone = localStorage.getItem("onBoardingStepDone");
  let tempStepCount = parseInt(localStorage.getItem("onBoardingStepDone"));
  // const [environment, setEnvironment] = useState();

  const handleSearchInput = (e) => {
    console.log("e.target.value", e.target.value);
    setSearchInput(e.target.value);
  };
  const AddSite = () => {
    // if (onBoardingStepDone == 6) {
    setAddNewTypeSiteModal(true);
    history.push(`${location.pathname}/add-site`);
    // } else history.push("/provider");
  };

  const AddSign = () => {
    history.push(`${location.pathname}/add-sign`);
  };

  const AddService = () => {
    history.push(`${location.pathname}/add-services`);
  };

  const AddCharge = () => {
    history.push(`${location.pathname}/add-charges`);
  };

  const handleBlueBtn = () => {
    switch (activeTab) {
      case "LOCATIONS":
        AddSite();
        break;
      case "SIGNS":
        AddSign();
        break;
      case "SERVICES":
        AddService();
        break;
      case "CHARGES":
        AddCharge();
        break;
      default:
        break;
    }
  };

  const renderScreen = (val) => {
    switch (val) {
      case "viewSite":
        return <ViewSiteInfo />;
      case "editSite":
        return <EditSitePage />;
      case "viewSign":
        return <EditSitePage />;
      case "ViewService":
        return <AddSitePage />;
      case "viewCharge":
        return <AddServicePage />;
      default:
        return "";
    }
  };

  // // fetch environment filter listing data
  // useEffect(async () => {
  //   setLoader(true);
  //   await getEnvironment()
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setLoader(false);
  //         setEnvironment(res.data);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const handleExportCsv = (e) => {
    console.log("e.target.value", e.target.value);
  };

  const handleBackToFTUE = () => {
    history.push("/provider");
    // if (tempStepCount == 2) {
    //   localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
    //   let bodyData = [
    //     {
    //       path: "/onBoardingStepDone",
    //       op: "add",
    //       value: tempStepCount + 1,
    //     },
    //   ];
    //   getUpdateOnBoardingStepDone(bodyData)
    //     .then((res) => {
    //       history.push("/provider");
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // }
  };

  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <>
      {isRouteChange ? (
        <>{renderScreen(girdActionPage)}</>
      ) : (
        <>
          <NavBar
            blueBtnText={`Add a ${
              activeTab.charAt(0).toUpperCase() +
              activeTab.slice(1, -1).toLowerCase()
            }`}
            blueBtnSVG={<PlusSVG />}
            whiteBtnText={tempStepCount == 2 ? "Go back to Account Setup" : ""} //Export CSV
            /* whiteBtnSVG={tempStepCount == 2 ? "" : <ExportCSVSVG />} */
            notificationSVG={<NotificationSVG />}
            navHeading="Inventory"
            subHeading="Configurator "
            subActiveHeading="Inventory"
            handleBlueBtn={handleBlueBtn}
            handleWhitebtn={(e) =>
              tempStepCount == 2 ? handleBackToFTUE() : handleExportCsv(e)
            }
            handleNotificationSVG={handleNotificationSVG}
            showButton={checkPermission("Configurator", "Full")}
          />

          <div className="gridSection p-20">
            <TableActions
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setFiltersModal={setFiltersModal}
              handleSearchInput={(e) => handleSearchInput(e)}
              searchInput={searchInput}
            />
            <div className="card mt-4 overflow-hidden customtable">
              <SwitchTabDom
                loader={loader}
                activeTab={activeTab}
                setIsRouteChange={setIsRouteChange}
                setGirdActionPage={setGirdActionPage}
                searchInput={searchInput}
              />
            </div>
          </div>
        </>
      )}
      {/* confirm modal add New Type of Site */}
      {addNewTypeSiteModal && (
        <ConfirmModal
          isModalHeader={true}
          title="Add New Type of Site / Environment"
          confirmText="Proceed"
          isFooterBtns={false}
          handleClose={() => setAddNewTypeSiteModal(false)}
          handleConfirm={() => history.push("/provider/addSite")}
        >
          <div className="px-3 py-4">
            <InputField placeholder="Name" label="Name" />
          </div>
        </ConfirmModal>
      )}

      {/* confirm modal add New Type of Site */}
      {bulkUploadSitesModal && (
        <ConfirmModal
          isModalHeader={true}
          title="Bulk Upload Sites"
          confirmText="Upload File"
          isFooterBtns={false}
          handleClose={() => setBulkUploadSitesModal(false)}
          // handleConfirm={() => }
        >
          <div className="py-3">
            {/* <InputField placeholder="Name" label="Name" /> */}
            <ol>
              <li className="confirm_li_desc01">
                Download <b className="active">Template.csv</b>
              </li>
              <li className="confirm_li_desc01">
                Update your data according to the template.
              </li>
              <li className="confirm_li_desc01">
                Upload the .csv file by clicking on the button below.
              </li>
            </ol>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

export default Inventory;

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import image from "../../../../../../../../assets/images/r1.png";
import { NotificationSVG } from "../../../../../../../../assets/svgComponents/table";
import NavBar from "../../../../../../../../components/navBar";

const BundleDetailsPage = (props) => {
  console.log(props.location.state, "selectedSite12345");
  const selectedSite = props.location.state;
  const tabs = ["INFORMATION", "SITES", "SIGNS", "BUNDLE"];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const columns = [
    {
      name: "QUANTITY".toUpperCase(),
      selector: (row) => row.QUANTITY,
      sortable: true,
    },
    {
      name: "PRICING".toUpperCase(),
      selector: (row) => row.PRICING,
      sortable: true,
    },
  ];

  const renderScreen = (activeTab) => {
    switch (activeTab) {
      case "INFORMATION":
        return (
          <>
            <div className="card_container mt-4">
              <Row>
                <Col md={2}>
                  <div className="billable_card_title">LISTING TYPE</div>
                  <div className="billable_card_discription">
                    {selectedSite.itemType}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="billable_card_title">AVAILABLE FOR</div>
                  <div className="billable_card_discription">
                    {selectedSite.availableFor}
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md={2}>
                  <div className="billable_card_title">PRICING TYPE</div>
                  <div className="billable_card_discription">
                    {selectedSite.periodType}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="billable_card_title">APPLICABLE DURING</div>
                  <div className="billable_card_discription">
                    {selectedSite.minBookingPeriod}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="billable_card_title">Price</div>
                  <div className="billable_card_discription">
                    {selectedSite.pricing}
                  </div>
                </Col>
              </Row>
            </div>
          </>
        );
      case "SITES":
        return (
          <>
            <Row className="mt-4">
              <Col md={5}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                    <img src={image} className="detail_image" />
                    <div className="ps-2">
                      <p className="image_heading">
                        <b>
                          <span className="light pe-2">#52RT</span>
                          Poster - King Size
                        </b>
                      </p>
                      <p className="qty">Qty. 3</p>
                    </div>
                  </div>
                  <div className="">
                    <Icon.ArrowRight />
                  </div>
                </div>
                <hr />
              </Col>
            </Row>
          </>
        );
      case "SIGNS":
        return (
          <>
            <div className="card_container mt-4">
              <div className="sign_heading">ST21/1</div>
              <Row className="mt-4">
                <Col md={2}>
                  <div className="billable_card_title">SIGN</div>
                  <div className="billable_card_discription">
                    {selectedSite.itemType}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="billable_card_title">LOCATION</div>
                  <div className="billable_card_discription">
                    {selectedSite.availableFor}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="billable_card_title">DIRECTION FACING</div>
                  <div className="billable_card_discription">
                    {selectedSite.availableFor}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={2}>
                  <div className="billable_card_title">DESCRIPTION</div>
                  <div className="billable_card_discription">
                    {selectedSite.availableFor}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={2}>
                  <div className="billable_card_title mb-2">IMAGES</div>
                  <div className="billable_card_discription d-flex">
                    <img src={image} className="sign_image pe-2" />
                    <img src={image} className="sign_image" />
                  </div>
                </Col>
              </Row>
            </div>
          </>
        );
      case "BUNDLE":
        return (
          <>
            <div className="card_container mt-4">
              <Row className="">
                <Col md={2}>
                  <div className="billable_card_title">
                    MINIMUM BOOKING PERIOD
                  </div>
                  <div className="billable_card_discription">
                    {selectedSite.minBookingPeriod}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="billable_card_title">TURN AROIND TIME</div>
                  <div className="billable_card_discription">
                    {selectedSite.availableFor}
                  </div>
                </Col>
              </Row>
              <div style={{ fontSize: "16px" }} className="mt-4">
                Pricing
              </div>
              <Row className="mt-4">
                <Col md={2}>
                  <div className="billable_card_title">PRICING TYPE</div>
                  <div className="billable_card_discription">
                    {selectedSite.availableFor}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="billable_card_title">APPLICABLE DURING</div>
                  <div className="billable_card_discription">
                    {selectedSite.availableFor}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={4}>
                  <div className="card  mt-3">
                    <DataTable
                      fixedHeader
                      columns={columns}
                      allowOverflow={true}
                      highlightOnHover
                      pointerOnHover
                      // selectableRows
                      pagination
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div style={{ fontSize: "16px" }} className="mt-4">
                    Summary of Charges
                  </div>
                  <div className="card  mt-3">
                    <DataTable
                      fixedHeader
                      columns={columns}
                      allowOverflow={true}
                      highlightOnHover
                      pointerOnHover
                      // selectableRows
                      pagination
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div style={{ fontSize: "16px" }} className="mt-4">
                    Refund Rules
                  </div>
                  <div className="card  mt-3">
                    <DataTable
                      fixedHeader
                      columns={columns}
                      allowOverflow={true}
                      highlightOnHover
                      pointerOnHover
                      // selectableRows
                      pagination
                    />
                  </div>
                </Col>
              </Row>
              <div style={{ fontSize: "16px" }} className="mt-4">
                Subscription
              </div>
              <Row className="mt-4">
                <Col md={2}>
                  <div className="billable_card_title">NUMBERS OF TERMS</div>
                  <div className="billable_card_discription">
                    {selectedSite.availableFor}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="billable_card_title">DOWNPAYMENT</div>
                  <div className="billable_card_discription">
                    {selectedSite.availableFor}
                  </div>
                </Col>
              </Row>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <NavBar
        blueBtnText={`Edit`}
        notificationSVG={<NotificationSVG />}
        navHeading="#B21 - Item 1"
        subHeading="Home • Sites • Bayward Market Square Area "
        subActiveHeading="Booking Details"
        searchBarlabel={"Search an item"}
        showButton={false}
        // handleBlueBtn={}
      />
      <div className="p-20">
        <div className="billable_tabs ">
          {tabs?.map((tab, index) => (
            <div
              key={index + "_sd_tab"}
              className={`billable_tab ${tab == activeTab && "active"}`}
              onClick={() => handleChangeTab(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
        {renderScreen(activeTab)}
      </div>
    </>
  );
};

export default BundleDetailsPage;

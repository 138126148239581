import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  CirclePlusSVG,
} from "../../../../../assets/svgComponents/svg";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import AddNewCardModal from "../../../../../components/profile/Modal/AddNewCardModal";
import { getRecentTopFivePaymentCard } from "../../../../../services/Common";
import { AtmCard } from "../../../../../components/AtmCard/AtmCard";
import { deletecard, makeDefoulPaymentCard } from "../../../../../services/Provider";
import UpdateCardModal from "../../../../../components/profile/Modal/UpdateCardModal";
import { setPaymentCardId } from "../../../../../services/reducers/listing-reducer";
import {useDispatch, useSelector} from "react-redux";


const PaymentModes = () => {
  const dispatch = useDispatch();
  const tabs = ["Credit/Debit Card", "Net Banking", "Subscription Plan"];
  const [activeTab, setActiveTab] = useState("Credit/Debit Card");
  const [addNewCardModal, setAddNewCardModal] = useState(false);
  const [cardList, setCardList] = useState();
  const [updateCardDetails, setUpdateCardDetails] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [selcardToUpdate, setSelcardToUpdate] = useState();
  const [selectCardId, setSelectCardId] = useState();
  const [defualtCardChagedOn, setDefualtCardChagedOn] = useState();

  useEffect(() => {
     getCards();
  }, [
    addNewCardModal,
    updateCardDetails,
    deleteConfirmModal,
    selectCardId,
    updateCardDetails,
    defualtCardChagedOn
  ]);
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleAddNewCard = () => {
    setAddNewCardModal(true);
  };
  const editCardDetails = (card) => {
    console.log("card", card);
    setSelcardToUpdate(card);
    setUpdateCardDetails(true);
  };
  const deteletePaymentCard = (id) => {
    console.log("id", id);
    setSelectCardId(id);
    setDeleteConfirmModal(true);
  };

  const confirmModalDelete = () => {
    deletecard(selectCardId);
    setDeleteConfirmModal(false);
    getCards();
  };
  const makeDefoultCard = (card) => {
    console.log("card", card);
    makeDefoulPaymentCard(card.id).then(r=>setDefualtCardChagedOn(new Date()));
    dispatch(setPaymentCardId(card.id));
    // setDefoultaddressConfirmModal(!defoultaddressConfirmModal);
  };

  const getCards = () =>{
    getRecentTopFivePaymentCard()
      .then((res) => {
        if (res.status === 200) {
          setCardList(res.data);
          var defaultCards = res.data.filter(r=>r.isDefault);          
          if(defaultCards && defaultCards.length>0){
            dispatch(setPaymentCardId(defaultCards[0].id));
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  const renderScreen = (tab) => {
    // eslint-disable-next-line default-case
    switch (tab) {
      case "Credit/Debit Card":
        return (
           <>
        
            <div className="cardContainer row">
            {cardList &&
                cardList.map((card, index) => {
                  return (
                    <AtmCard
                      key={index + '_pay_card'}
                      card={card}
                      editCardDetails={editCardDetails}
                      deteletePaymentCard={deteletePaymentCard}
                      index={index}
                      makeDefoultCard={makeDefoultCard}
                    />
                  );
                })} 
            </div>
            <div className="row">
            <div className="col-md-4" onClick={() => handleAddNewCard()}>
                <div className="card_booking_details px-3 py-5">
                <span className="d-flex justify-content-center">
                  <CirclePlusSVG />
                </span>
                <div className="d-flex justify-content-center add_new_card pt-3">
                  Add New Card
                </div></div>
             </div></div>
          </>
        );
      case "Net Banking":
        return <>Net Banking</>;
      case "Subscription Plan":
        return <>Subscription Plan</>;
    }
  };
  return (
    <>  
      <div className="site_booking_card_heading py-3">Choose Payment Mode</div>
      <Row className="py-1">
        {/* <Col md={4}>
          {tabs.map((tab, index) => (
            <div
              className={`p-3 payment_mode_tab ${
                activeTab === tab && "active_tab"
              }`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </div>
          ))}
        </Col> */}
        <Col className="">{renderScreen(activeTab)}</Col>
      </Row>

      
      
      {updateCardDetails && (
        <UpdateCardModal
          setShow={setUpdateCardDetails}
          title={"Update Card Details"}
          selcardToUpdate={selcardToUpdate}
        />
      )}
      {addNewCardModal && (
        <AddNewCardModal setShow={setAddNewCardModal} title={"Add Card"} />
      )}
      {deleteConfirmModal && (
        <ConfirmModal
          title="Delete Card"
          isModalHeader={true}
          isFooterBtns={true}
          cancelText="No"
          confirmText="Yes"
          handleClose={() => setDeleteConfirmModal(false)}
          handleConfirm={confirmModalDelete}
          width="400px"
        >
          <div>
            <p className="newListingCreated_description ms-4 py-3">
              Are you sure want to delete card
            </p>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default PaymentModes;

import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  InputGroup,
  Form,
  FloatingLabel,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import { InputField } from "../../../../../../../components/InputField";
import NavBar from "../../../../../../../components/navBar";
import { TextAria } from "../../../../../../../components/TextAria";

import ProjectContext from "../../../../../../../context/ProjectContext";
import { updateService } from "../../../../../../../services/Provider/Configurator/Inventory";
import { useToasts } from "react-toast-notifications";
import UpdatesModal from "../../../../../../../components/profile/Modal/UpdatesModal";
import { checkPermission } from "../../../../../../../utils";

export const EditServicePage = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { selectedService, handleActiveTab } = useContext(ProjectContext);
  const [desc, setDesc] = useState(selectedService.description);
  const [modalConfirmStatus, setModalConfirStatus] = useState(false);
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);

  console.log("selectedService", selectedService);

  const handleOnCancel = () => {
    handleActiveTab("SERVICE");
    //history.goBack();
    history.push("/provider/configurator/inventory");
  };

  const handleDescription = (e) => {
    setDesc(e.target.value);
  };
  const formik = useFormik({
    initialValues: {
      serviceName: selectedService.name,
    },
    validationSchema: Yup.object().shape({
      serviceName: Yup.string()
        .min(5, "Please enter title with five or more characters")
        .required("Service name is required"),
    }),
    onSubmit: (values) => {
      let reqBody = [
        {
          path: "/name",
          op: "add",
          value: values.serviceName,
        },
        {
          path: "/description",
          op: "add",
          value: desc,
        },
      ];
      let serviceId = selectedService.id;
      updateService(serviceId, reqBody)
        .then((res) => {
          if (res.status == 204) {
            handleActiveTab("SERVICE");
            //setModalConfirStatus(true);
            addToast("Service updated successfully.", {
              appearance: "success",
            });
            history.push("/provider/configurator/inventory");
          }
        })
        .catch((err) => console.log("err", err.data));
    },
  });

  const serviceNameError =
    formik.touched.serviceName && formik.errors.serviceName;

  const handleConfirmModale = () => {
    setModalConfirStatus(false);
    history.push("/provider/configurator/inventory");
  };
  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };
  return (
    <>
      <Form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <NavBar
          blueBtnText="Save"
          whiteBtnText="Cancel"
          notificationSVG={<NotificationSVG />}
          navHeading="Edit Service"
          subHeading="Configurator • Inventory"
          subActiveHeading="Edit Service"
          // handleBlueBtn={handleOnSavebtn}
          handleWhitebtn={handleOnCancel}
          type="submit"
          handleNotificationSVG={handleNotificationSVG}
          showButton={true}
        />
        <div className="girdMain gridSection">
          <Card className="gridCard" aria-labelledby="gridCard">
            <Card.Body className="gridCardBody p-0">
              <div className="formSection">
                <p className="mt-4 mx-4">Service Details</p>
                <div className="mx-4">
                  <div className="row my-4 py-2">
                    <div className="col-md-4">
                      <InputField
                        type="text"
                        name="serviceName"
                        value={formik.values.serviceName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="name@example.com"
                        label="Charge Name"
                        inputFieldSize="sizeOfInputField"
                      />
                      {serviceNameError && (
                        <p
                          className="text-danger mt-1 ms-2"
                          style={{ fontSize: "14px" }}
                        >
                          {formik.errors.serviceName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row w-100 fornSection2 sizeOfTextAria">
                    <div className="col-md-4">
                      <TextAria
                        type="text"
                        name="description"
                        value={desc}
                        onBlur={formik.handleBlur}
                        onChange={(e) => handleDescription(e)}
                        placeholder="Description"
                        label="Description"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Form>
      {modalConfirmStatus && (
        <ConfirmModal
          title={`Update Service`}
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Ok"
          handleConfirm={handleConfirmModale}
        >
          <div className="text-center">
            <p className="py-4 newListingCreated_description">
              Service updated successfully.
            </p>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthService } from "../../services/AuthService";



const initialState = {
    user:null,
    role:null,//localStorage.getItem("userType")
}

const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{
        setUser:(state,params)=>{    
            if(params && params.payload){
                state.user=params.payload;
                state.role=params.payload.UserType==null ? "Admin" : params.payload.UserType;                              
            }else{
                if(state.user!=null)
                    state.user=null;
                if(state.user!=null)
                    state.role=null;                              
            }            
        },
        getUser:(state)=>{
            const authService = new AuthService();
            authService.getUser().then((user) => {
                if (user) {
                  const token = localStorage.getItem("accessToken");
                  localStorage.setItem("accessToken", user.access_token);
                  localStorage.setItem("userType", user.profile.UserType);                  
                  localStorage.setItem("accessModules", user.profile.AccessModules);                  
                  state.user=user.profile;                  
                  state.role=user.profile.UserType==null ? "Admin" : user.profile.UserType;                  
                  if (!token) window.location.reload();
                } else {
                    state.user = null;
                    state.role = null;
                }
              });
        }
    } 
});
export const { getUser, setUser} = userSlice.actions;
export default userSlice.reducer;
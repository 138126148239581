import React from "react";
import { Form } from "react-bootstrap";
import "./BtnRadio.scss";

const BtnRadio = (props) => {
  const { label, name, status, disable, customClass, onChangeStatus, value } = props;
    console.log(status);
    return (
    status===true?(<>
      <input id="my-radio"
        type="radio"
        name={name}
        checked
        disabled={disable}
        className={`btnRadio_input ${customClass}`}
        onChange={(e)=>{onChangeStatus(e);}}
        value={value}
      />
      <span className="ps-2 btnRadio_label text-xs text-gray-1000">{label}</span>
      
    </>):(<>
      <input id="my-radio"
        type="radio"
        name={name}
        disabled={disable}
        className={`btnRadio_input ${customClass}`}
        onChange={(e)=>{onChangeStatus(e);}}
        value={value}
      />
      <span className="ps-2 btnRadio_label text-xs text-gray-1000">{label}</span>
      
    </>)
  );
};

export default BtnRadio;

import React, {useState} from "react";
import { OrdersPage } from "./OrdersPage";
import { TransactionsPage } from "./TransactionsPage";

export const SwitchOrdersPage = ({ activeTab, ordersData, onTabChange }) => {
  const [searchString, setSearchString] = useState("");
  switch (activeTab) {
    case "ORDERS":
      return <OrdersPage ordersData={ordersData} searchString={searchString} setSearchString={setSearchString} />;
    case "TRANSACTIONS":
      return <TransactionsPage tabChange={onTabChange} setSearchString={(term)=>setSearchString(term)} />;
    default:
      return <></>;
  }
};

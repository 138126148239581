import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

function SelectGroup({
  onChange,
  options,
  value,
  name,
  formGroupClassName,
  formControlClassName,
  formLabelClassName,
  formLabelText,
  onBlur,
  htmlFor,
  placeholder,
  disabled,
  fieldErrorBool,
  isErrorMsg,
  errorMsg,
  menuPlacement,
}) {
  const defaultValue = (options, value) =>
    options && options.length > 0
      ? options.find((option) => option.label === value)
      : "";
  return (
    <Form.Group
      className={` selectGroup ${
        value
          ? formGroupClassName + " selectGroupActive "
          : fieldErrorBool && " selectGroup selectGroupError"
      }`}
    >
      <Form.Label htmlFor={htmlFor} className={formLabelClassName}>
        {formLabelText}
      </Form.Label>
      <Select
        id={name}
        name={name}
        value={value ? defaultValue(options, value) : null}
        onChange={(value) => {
          onChange(value);
        }}
        menuPlacement={
          menuPlacement && menuPlacement.length > 0 ? "top" : "bottom"
        }
        options={options}
        className={formControlClassName}
        onBlur={onBlur}
        placeholder={placeholder}
        isDisabled={disabled}
        defaultValue={defaultValue}
      />
      {isErrorMsg && <Form.Text className="text-danger">{errorMsg}</Form.Text>}
    </Form.Group>
  );
}

export default SelectGroup;

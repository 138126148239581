import { Card } from "react-bootstrap";
import { NotificationSVG } from "../../../../../assets/svgComponents/table";
import NavBar from "../../../../../components/navBar";
import React, { useState } from "react";
import { useLocation, useHistory } from "react-router";
import AdminSideNav from "../../../../../components/sideNav/AdminSideNav";
import { useEffect } from "react";
import { currentUser } from "../../../../../services/Provider/UserManagement/UserManagement";

const Settings = () => {
  const location = useLocation();
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [openNav, setOpenNav] = useState(false);

  const changePassword = () => {
    history.push(`${location.pathname}/change-password`);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await currentUser()
      .then((res) => {
        if (res.status === 200) {
          setUserData(res.data);
          console.log(userData);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <NavBar
        notificationSVG={<NotificationSVG />}
        navHeading="Settings"
        subHeading=""
        showButton={false}
      />
      <div className="adminsettings p-20">
        {/* <div
          className="sideNav"
          style={openNav ? { width: "15%" } : { width: "6%" }}
        >
          <AdminSideNav setOpenNav={setOpenNav} openNav={openNav} />
        </div> */}
        <div className="row">
          <div className="col-4">
            <h2 className="text-xl mb-3 fw-normal">General Account Settings</h2>
            <Card>
              <div className="p-20 pt-4 d-flex align-items-center mb-4">
                <div>
                  <div className="imageBox setting_profile_image me-3">
                    {/* <img src={"https://gopinit.blob.core.windows.net/image-container/"+userData.profilePicture} alt="profileImag"/> */}

                    <img
                     
                      src={
                        userData.profilePicture
                          ? `https://gopinit.blob.core.windows.net/image-container/${userData.profilePicture}`
                          : "https://i.stack.imgur.com/l60Hf.png"
                      }
                      alt="profileImag"
                    />
                  </div>
                </div>
                <div>
                  <h3 className="text-xl mb-0">{userData.name}</h3>
                  <span className="text-sm text-gray-1400">
                    {userData.emailId}
                  </span>
                  {/* <h3>{userData.name}</h3> */}
                  {/* <span className="providerName">{userData.emailId}</span> */}
                </div>
              </div>

              <p className="p-20 pb-0 pt-4 borderTop">
                <a
                  className="btn-outline-gray-radius"
                  onClick={() => changePassword()}
                >
                  Change Password
                </a>
              </p>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;

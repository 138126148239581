import React from "react";
import Checkbox from "../../checkbox/Checkbox";

import TableSortIcon from "./TableSortIcon";

const TableHeader = (props) => {
  let allChecked = props.tableBody?.records.filter((r) => r.isChecked);
  allChecked = allChecked?.length == props.tableBody?.records.length;
  return (
    <>
      <thead>
        <tr className="gridTableHeader_tr ">
          {props.isCheckBox && (
            <th className="gridTableHeader_th">
              <Checkbox
                isChecked={allChecked}
                action={() => props.handleAllChecked()}
              />
            </th>
          )}
          {props.isAvatar && <th></th>}
          {props.tableHeading.map((item, index) => (
            <>
              <th className="gridTableHeader_th" key={index}>
                <div className="d-flex align-items-center">
                  {item.toUpperCase()}
                  <TableSortIcon />
                </div>
              </th>
            </>
          ))}
        </tr>
      </thead>
    </>
  );
};

export default TableHeader;

import React from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import DatePicker from "react-date-picker";
import { useToasts } from "react-toast-notifications";

import { InputDatePicker } from "../../../../../components/InputDatePicker/InputDatePicker";
import { SelectionComponent } from "../../../../../components/SelectionComponent/SelectionComponent";
import { updateOrderStatus } from "../../../../../services/Provider/OrderAndTransactions/OrderAndTransactions";
import { SVGCalendar } from "../../../../../assets/svgComponents/svg";

const StatusUpdateModal = ({
  title,
  footerText,
  handlefooterBtn,
  statusFilter,
  showUpdateStatusId,
  setShowUpdateStatus,
}) => {
  const { addToast } = useToasts();

  const formik = useFormik({
    initialValues: {
      selectStatus: "",
      enterDate: new Date(),
    },
    validationSchema: Yup.object().shape({
      // selectStatus: Yup.number().required("Please enter a vaild email."),
      // enterDate: Yup.string().required("Please enter a date."),
    }),
    onSubmit: (values) => {
      updateStatus(values);
    },
  });

  const updateStatus = async (val) => {
    const reqBody = {
      orderId: showUpdateStatusId,
      providerOrderStatus: val.selectStatus,
      updatedOn: moment(val.enterDate).format("DD MMM YYYY"),
    };
    // console.log(reqBody, "reqBody");
    await updateOrderStatus(reqBody)
      .then((res) => {
        if (res.status == 200) {
          addToast("Order status updated successfully", {
            appearance: "success",
          });
          setShowUpdateStatus(false);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="confirmModal">
      <Form onSubmit={formik.handleSubmit} className="confirmModalInner">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="w-25">
              <h5 style={{ fontSize: "18px", fontWeight: "300" }}>{title}</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectionComponent
            name="selectStatus"
            label={"Select Status"}
            option={statusFilter}
            value={formik.values.selectStatus}
            handleOnchange={formik.handleChange}
          />
          <InputDatePicker
            name="enterDate"
            label="Enter Date"
            value={formik.values.enterDate}
            onChange={(date) => formik.setFieldValue("enterDate", date)}
          />
        </Modal.Body>
        <Modal.Footer
          className=" justify-content-center confirmModalBottom p-0"
          // onClick={handlefooterBtn}
        >
          <button
            className="btn btn-success btn-block border-0  m-0 w-100 confirmModalConfirmBtn"
            type="submit"
            style={{ color: "#ffffff" }}
          >
            {footerText}
          </button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default StatusUpdateModal;

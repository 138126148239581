import React from "react";
import {useDispatch} from "react-redux";
import { filterCBChanged } from "../../../../services/reducers/filter-reducers";

const FilterCheckbox = ({ type, option }) => {
  const dispatch = useDispatch();
  return (
    <>
      <label className="checkBox">
        <input
          disabled={option.disabled}
          type="checkBox"          
          value={option.value}
          checked={option.isChecked}
          onChange={()=>dispatch(filterCBChanged({type,id:option.id}))}  
        />
        <span
          className={`${option.isChecked ? "checkBoxChecked" : "checkBoxCheck"}`}
        ></span>
        {option.name && (
          <span className={`checkBoxText ms-4 px-2 checkbox_title`}>
            {option.name}
          </span>
        )}
      </label>
    </>
  );
};

export default FilterCheckbox;

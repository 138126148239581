import React, { useEffect, useState, useRef } from "react";
import { Button, Row, Col } from "react-bootstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Invoice from "../../../../components/Invoice/Invoice";
import "./Invoice.scss";
import { Rating } from "react-simple-star-rating";
import { getImageUrl } from "../../../../services/utility";

import {
  getOrders,
  getOrdersAsync,
  refundAmountAsync,
  extendOrderAsync,
  getOrderPeriod,
  extendOrderAmountAsync,
  getOrder,
  releaseOrderPayoutAsync,
} from "../../../../services/reducers/orders-reducers";
import {
  DownloadSVG,
  FullStarSVG,
  Star,
} from "../../../../assets/svgComponents/svg";
import { CenterPopUpModal } from "../../Provider/OrderAndTransaction/Modal/CenterPopUpModal";
import { OrderRefundModal } from "./OrderRefundModal";
import {
  cancelOrder,
  getOrderRefundAmount,
  rejectRefund,
} from "../../../../services/Provider/OrderAndTransactions/OrderAndTransactions";
// import { toast } from 'react-toastify';
import Chat from "../../Shared/Chat/Chat";
import { ExtendOrderModal } from "./ExtendOrderModal";
import { useToasts } from "react-toast-notifications";
import gopoinitImage from "../../../../assets/images/gopinit.png";
import { NonceProvider } from "react-select";
import { UpdateOrderStatusModal } from "./UpdateOrderStatusModal";
import { RefundRequestModal } from "./RefundRequestModal";
import { AddReviewModal } from "./AddReviewModal";
import ConfirmModal from "../../../../components/confirmModal/ConfirmModal";
import { PortableSignLocationMap } from "../../Customers/pages/PortableSignLocationMap";
import { To2Decimal } from "../../../../services/utility";
import { checkPermission } from "../../../../utils";

export const OrderDetails = (props) => {
  const history = useHistory();
  const [showRefundAmount, setShowRefundAmount] = useState(false);
  const [showExtendOrder, setShowExtendOrder] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showRefundRequestModal, setShowRefundRequestModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [payoutConfirmModal, setPayoutConfirmModal] = useState(false);
  const {
    order,
    refundAmunt,
    extendOrderResult,
    extendOrderAmount,
    refundAmountResponse,
    orderPayoutResult,
  } = useSelector((state) => state.orders ?? {});
  const { role } = useSelector((state) => state.user ?? {});
  const orderPeriod = useSelector((state) => getOrderPeriod(state.orders));
  const [orderExtendFrom, setOrderExtendFrom] = useState(null);
  const [orderExtendUpto, setOrderExtendUpto] = useState(null);
  const [extendOrderOfferAmount, setExtendOrderOfferAmount] = useState(0);
  const [refundType, setRefundType] = useState("");
  const [refundTypeText, setRefundTypeText] = useState("");
  const [refundAmountText, setRefundAmountText] = useState(0);
  const [refundStatus, setRefundStatus] = useState("Approve");
  const [refundRejectionReason, setRefundRejectionReason] = useState("");
  const userType = localStorage.getItem("userType");
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const setExtendedDates = (date, date2) => {
    if (date) {
      setOrderExtendFrom(moment(date).format("DD-MMMM-YYYY"));
      //setOrderExtendFrom(date);
    }
    if (date2) {
      // setOrderExtendUpto(moment(date2).format("DD-MMMM-YYYY"));
      setOrderExtendUpto(moment(date2).format("DD-MMMM-YYYY"));
    }
  };

  const handleExtendOrderSubmit = () => {
    let fromDate = moment(orderPeriod.toDate).add(1, "d");
    if (moment(fromDate).isSame(orderExtendUpto)) {
      addToast("From and upto dates can not be same!", { appearance: "error" });
      return;
    }
    const reqBody = {
      orderId: order.id,
      extendFrom: moment(orderPeriod.toDate).add(1, "d").format("DD-MMM-YYYY"),
      extendTo: orderExtendUpto,
      offerAmount: extendOrderOfferAmount,
    };
    dispatch(extendOrderAsync(reqBody));
  };

  useEffect(() => {
    if (order) {
      clearStatesOfExtendOrder();
    }
  }, [order]);

  useEffect(async () => {
    if (orderExtendUpto) {
      const reqBody = {
        orderId: order.id,
        extendFrom: moment(orderPeriod.toDate)
          .add(1, "d")
          .format("DD-MMM-YYYY"),
        extendTo: moment(orderExtendUpto).format("DD-MMM-YYYY"),
        OfferAmount: extendOrderOfferAmount,
      };
      let res = dispatch(extendOrderAmountAsync(reqBody));
      console.log(res, "resss");
    }
  }, [orderExtendUpto, extendOrderOfferAmount]);

  useEffect(async () => {
    if (extendOrderResult && extendOrderResult.length > 0) {
      addToast(extendOrderResult[0], { appearance: "success" });
      clearStatesOfExtendOrder();
      dispatch(getOrdersAsync());
      setShowExtendOrder(false);
    }
  }, [extendOrderResult]);

  const handleRefundAmount = (id) => {
    getOrderRefundAmount(id).then((res) => {
      if (res.data) {
        setRefundType(res.data.data.refundType);
        setRefundTypeText(res.data.data.refundTypeText);
        setRefundAmountText(res.data.data.refundAmount);
        setShowRefundAmount(true);
      } else {
        addToast("Error while calculation refund!", { appearance: "error" });
      }
    });
  };

  const handleExtendOrder = () => {
    setShowExtendOrder(true);
  };

  const handleSubmitBtn = () => {
    setShowExtendOrder(false);
    setShowRefundAmount(false);
    setShowConfirmModal(true);
  };
  const handleOkayBtn = () => {
    setShowConfirmModal(false);
    if (refundStatus === "Approve")
      dispatch(
        refundAmountAsync({
          orderId: order.id,
          refundType: refundType,
          refundAmount: refundAmountText,
        })
      );
    else {
      const reqBody = {
        orderId: order.id,
        rejectReason: refundRejectionReason,
      };
      rejectRefund(reqBody).then((res) => {
        if (res.status === 200) {
          addToast("Refund rejected successfully", { appearance: "success" });
        }
      });
    }
  };

  useEffect(() => {
    if (refundAmountResponse) {
      if (refundAmountResponse.status === 1) {
        setShowRefundAmount(false);
        addToast(refundAmountResponse.message, { appearance: "success" });
      } else {
        addToast(refundAmountResponse.message, { appearance: "error" });
      }
    }
  }, [refundAmountResponse]);

  const handleCancelOrder = (id) => {
    cancelOrder(id)
      .then(() => {
        props.getOrders();
        addToast("This order has been cancelled.", { appearance: "success" });
      })
      .catch(() => {
        addToast("There is an error while cancelling order.", {
          appearance: "warning",
        });
        // toast.error("There is an error while cancelling order.")
      });
  };

  const [printIt, setPrintIt] = useState(false);
  const printRef = React.useRef();
  const handleDownloadPdf = async () => {
    setPrintIt(true);
    setTimeout(async () => {
      const element = printRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");

      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(data, "PNG", 0, 0, pdfWidth - 10, pdfHeight);
      pdf.save("print.pdf");
      setPrintIt(false);
    }, 999);
  };

  const clearStatesOfExtendOrder = () => {
    setOrderExtendFrom(null);
    setOrderExtendUpto(null);
    setExtendOrderOfferAmount(0);
  };

  useEffect(async () => {
    if (orderPayoutResult) {
      if (orderPayoutResult.status == 1) {
        addToast(orderPayoutResult.message, { appearance: "success" });
        await dispatch(getOrder(order.id));
        setPayoutConfirmModal(false);
      } else {
        addToast(orderPayoutResult.message, { appearance: "error" });
      }
    }
  }, [orderPayoutResult]);

  const releasePayout = async () => {
    const reqBody = {
      orderIds: [order.id],
    };
    await dispatch(releaseOrderPayoutAsync(reqBody));
  };

  return (
    <>
      {order && (
        <div className="orderDetailsContainer bg-gray-800">
          <Chat
            targetId={
              userType === "Provider" ? order.customerId : order.providerId
            }
            orderId={order?.id}
          />
          <div className="detailsContainer">
            {(checkPermission("OrdersAndTransactions", "Full") ||
              (role && role == "Customer")) && (
              <div className="buttonContainer_2 mt-4">
                {order.status !== 6 &&
                  role &&
                  role == "Customer" &&
                  order.refundRequestStatus == null &&
                  order.havingPendingInstallments && (
                    <button
                      className="blueBgButton me-3"
                      onClick={() => {
                        history.push("/order-payment/" + order.id);
                      }}
                    >
                      Pay Now
                    </button>
                  )}

                {order.status !== 6 &&
                  order.paidAmount != null &&
                  order.paidAmount > 0 &&
                  order.isRefundApplicable &&
                  role &&
                  role == "Customer" &&
                  order.refundRequestStatus == null && (
                    <button
                      className="blueBgButton me-3"
                      onClick={() => setShowRefundRequestModal(true)}
                    >
                      {" "}
                      Refund Request
                    </button>
                  )}

                {order.status !== 6 &&
                  (order.status == 3 ||
                    order.status == 4 ||
                    order.status == 5) &&
                  role &&
                  role == "Admin" &&
                  !order.payoutReleased && (
                    <button
                      className="whiteBgButton me-3"
                      onClick={() => {
                        setPayoutConfirmModal(true);
                      }}
                    >
                      Release Payout
                    </button>
                  )}
                {/* <button
                  className="whiteBgButton"
                  onClick={() => handleCancelOrder(order?.id)}
                >
                  Cancel Order {role}{" "}
                </button> */}
                {order.status !== 6 &&
                  role &&
                  role == "Provider" &&
                  order.parentOrderId == null &&
                  order.isExtend == false && (
                    <button
                      className="blueBgButton me-3"
                      onClick={handleExtendOrder}
                    >
                      {" "}
                      Extend Order{" "}
                    </button>
                  )}

                {role && role != "Customer" && order.payoutReleased == true ? (
                  <div>
                    <b>Payout Released </b>
                  </div>
                ) : null}
              </div>
            )}
            <div
              className="downloadInvoice"
              style={{ cursor: "pointer" }}
              onClick={handleDownloadPdf}
            >
              <DownloadSVG />
              <span>Download Invoice</span>
            </div>
            <div className="row containerDetails">
              <div className="section_4 col-md-12 ps-0 mb-4">
                <p className="mb-0">Order Id</p>
                <span>{order?.id}</span>
              </div>
              <div className="section_4 col-md-12 ps-0 mb-4">
                <p className="mb-0">PLACED ON</p>
                <span>{moment(order?.createdOn).format("DD MMMM YYYY")}</span>
              </div>
              <div className="section_4 col-md-12 ps-0 mb-4">
                <p className="mb-0">BOOKING DURATION</p>
                <span>
                  {moment(order?.bookingDurationStartDate).format(
                    "DD MMMM YYYY"
                  )}{" "}
                  -{" "}
                  {moment(order?.bookingDurationEndDate).format("DD MMMM YYYY")}
                </span>
              </div>
              {/* </div>
            <div className="containerDetails row"> */}
              <div className="col-md-12 ps-0">
                <p className="mb-0">BILLING ADDRESS</p>
                {order && order?.customer && (
                  <span>
                    {order?.customer?.firstName} {order?.customer?.lastName} |{" "}
                    {order?.customer?.contactNo} {order?.billingAddress},{" "}
                    {order?.billingCity}, {order?.billingState},{" "}
                    {order?.billingCountry} {order?.billingPincode}
                  </span>
                )}
              </div>
            </div>

            {order.refundRequestStatus != null && (
              <div className="row">
                <div className="col-md-7">
                  <div className="buttonContainer_2 mt-4">
                    <p> Refund Requested </p>
                    <span>
                      Status : <b>{order.refundRequestStatusText}</b>
                    </span>
                    <p>
                      <span>
                        Refund Reason : <b>{order.refundRequestReason}</b>
                      </span>
                    </p>
                    {order.refundRequestStatus == 2 && (
                      <p>
                        <span>
                          Rejection Reason : <b>{order.requestRespondNote}</b>
                        </span>
                      </p>
                    )}
                    {order.refundAmount != null && order.refundAmount > 0 && (
                      <span>
                        {" "}
                        Refunded Amount :{" "}
                        <b>{To2Decimal(order.refundAmount)}</b>
                      </span>
                    )}
                  </div>
                </div>
                {role && role == "Admin" && (
                  <div className="col-md-5">
                    {" "}
                    <button
                      disabled={
                        order.refundRequestStatus == 1 ||
                        order.refundRequestStatus == 2
                      }
                      className="blueBgButton me-3 mt-3"
                      onClick={() => handleRefundAmount(order?.id)}
                    >
                      {" "}
                      Refund Amount{" "}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="paymentMainContainer">
            <div className="paymentContainer row">
              <div className="section paymentDetailsContainer col-md-6">
                <h6 className="mb-0">Payment Details</h6>
                <div className="subSection">
                  {order && order?.details && (
                    <Row>
                      <Col className="col-md-7">
                        <ul className="p-0">
                          {order?.details.map((detail, index) => {
                            return (
                              <li key={"detail_name_" + index}>
                                {detail.name}
                              </li>
                            );
                          })}
                          {order && order?.rushFees>0 && (
                            <li key={"detail_name_rush"}>Rush Fees</li>)}
                          {order?.availedOfferAmount &&
                            order?.availedOfferAmount > 0 && (
                              <li key={"discount"}>Offer Discount</li>
                            )}
                          {order?.totalTax &&
                            order?.totalTax > 0 && (
                              <li key={"tax"}>Total Tax</li>
                            )}
                        </ul>
                      </Col>
                      <Col className="col-md-5">
                        <ul>
                          {order?.details.map((detail, index) => {
                            return (
                              <li
                                key={"details_amount_" + index}
                                style={{ textAlign: "right" }}
                              >
                                {To2Decimal(detail.amount)}
                              </li>
                            );
                          })}
                          {order && order?.rushFees>0 && (
                            <li key={"detail_name_rush"} style={{ textAlign: "right" }}>{To2Decimal(order?.rushFees)}</li>)}
                          {order?.availedOfferAmount &&
                            order?.availedOfferAmount > 0 && (
                              <li
                                key={"discountAmount"}
                                style={{ textAlign: "right" }}
                              >
                                -{To2Decimal(order?.availedOfferAmount)}
                              </li>
                            )}
                          {order?.totalTax &&
                            order?.totalTax > 0 && (
                              <li
                                key={"taxAmount"}
                                style={{ textAlign: "right" }}
                              >
                                {To2Decimal(order?.totalTax)}
                              </li>
                            )}
                        </ul>
                      </Col>
                    </Row>
                  )}

                  <hr />
                  <Row className="totalamt">
                    <Col className="col-md-7">
                      <ul className="p-0">
                        <li>Total Amount</li>
                      </ul>
                    </Col>
                    <Col className="col-md-5">
                      <ul>
                        <li style={{ color: "#F99300", textAlign: "right" }}>
                          ${To2Decimal(order?.totalOrderAmount)}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="section paymentTermsContainer col-md-6">
                <h6 className="mb-0">Payment Terms</h6>
                <div className="subSection">
                  {order.paymentTerms &&
                    order.paymentTerms.map((pt, index) => (
                      <Row key={"pt_" + index}>
                        <Col className="col-md-7 left-section">
                          {pt.isDownPayment && (
                            <div className="mb-3">
                              <p>Downpayment</p>
                              <span style={{ color: "#58B85C" }}>
                                Paid on{" "}
                                {pt.transactionDate &&
                                  moment(pt.transactionDate).format(
                                    "DD MMMM YYYY"
                                  )}
                              </span>
                            </div>
                          )}
                          {!pt.isDownPayment && (
                            <div className="mb-3">
                              <p>{pt.installmentNo}st Payment</p>
                              <span style={{ color: "#CE193F" }}>
                                Due on{" "}
                                {moment(pt.fromDate).format("DD-MMM-yyyy")}
                              </span>
                            </div>
                          )}
                        </Col>
                        <Col className="col-md-5">
                          {pt.isDownPayment && (
                            <div className="mb-3">
                              <p>$ {To2Decimal(pt.amount)}</p>
                            </div>
                          )}
                          {!pt.isDownPayment && (
                            <div className="mb-3">
                              <p>$ {To2Decimal(pt.amount)}</p>
                            </div>
                          )}
                        </Col>
                      </Row>
                    ))}
                </div>
              </div>
            </div>
            <div className="booking-section mt-4">
              <h6 className="mb-4">Booking Details</h6>
              {order &&
                order?.details &&
                order?.details.map((detail, index) => {
                  return (
                    <div
                      className="bookingContainer border-bottom  paymentContainer "
                      key={"od_bd_" + index}
                    >
                      <Row className="w-100 ">
                        <Col className="col-md-7">
                          <div>
                            <p className="mb-0">
                              {detail.itemType === "Site"
                                ? "LOCATION"
                                : detail.itemType}
                              :
                            </p>
                            <span>{detail.name}</span>
                          </div>
                          <div>
                            <p className="mb-0">AMOUNT:</p>
                            <span>$ {To2Decimal(detail.amount)}</span>
                          </div>
                          {detail.signDeliveryAddress ? (
                            <div>
                              <p className="mb-0">DELIVERY LOCATION:</p>
                              <span>
                                {detail.signDeliveryAddress.googleAddress}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Col>

                        <Col className="col-md-5 ps-1">
                          <div>
                            {role !== "Customer" && (
                              <p className="mb-0">
                                STATUS:
                                {checkPermission(
                                  "OrdersAndTransactions",
                                  "Full"
                                ) && order.orderStatusText !== "Order Canceled" ? (
                                  <Button
                                    className="blueBgButton me-3"
                                    style={{
                                      marginLeft: "5px",
                                      backgroundColor: "#3734a9",
                                      borderRadius: "10px",
                                    }}
                                    onClick={() => {
                                      setShowStatusModal(
                                        role === "Provider" ? true : false
                                      );
                                    }}
                                  >
                                    {order.orderStatusText}
                                  </Button>
                                ) : (
                                  <span>{order.orderStatusText}</span>
                                )}
                              </p>
                            )}
                            <span>
                              Order Placed at{" "}
                              {detail.createdOn &&
                                moment(detail.createdOn).format("DD MMMM YYYY")}
                            </span>
                          </div>
                          {order.serviceNames ? (
                            <div>
                              <p className="mb-0">SERVICES:</p>
                              <span>Installation, Design</span>
                            </div>
                          ) : null}
                        </Col>

                        {detail.portableSignBooking && (
                          <div className="booking-section mt-4">
                            <h6 className="mb-4">Portable Sign Details</h6>
                            <Col className="col-md-12">
                              {detail.portableSignBooking.landmark && (
                                <div>
                                  <p className="mb-0">
                                    landmark:
                                    <span>
                                      {" "}
                                      {detail.portableSignBooking.landmark}
                                    </span>
                                  </p>
                                </div>
                              )}
                              {detail.portableSignBooking
                                .locationFacingTowards && (
                                <div>
                                  <p className="mb-0">
                                    Location Facing:
                                    <span>
                                      {" "}
                                      {
                                        detail.portableSignBooking
                                          .locationFacingTowards
                                      }
                                    </span>
                                  </p>
                                </div>
                              )}
                              <div>
                                <p className="mb-0">
                                  Location Owned:
                                  <span>
                                    {" "}
                                    {detail.portableSignBooking.isLocationOwned
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </p>
                              </div>
                              {detail.portableSignBooking.image && (
                                <div className="imageCardContainer">
                                  <div
                                    className="imageCard"
                                    style={{ height: "70px" }}
                                  >
                                    <a
                                      download="image.jpg"
                                      href={getImageUrl(
                                        detail.portableSignBooking.image
                                      )}
                                      target="_blank"
                                    >
                                      <img
                                        src={getImageUrl(
                                          detail.portableSignBooking.image
                                        )}
                                        alt="img"
                                      />
                                    </a>
                                  </div>
                                </div>
                              )}
                            </Col>
                            <br />
                            <Col className="col-md-12">
                              {detail.signDeliveryAddress?.latitude &&
                                detail.signDeliveryAddress?.longitude && (
                                  <div className="mapBox">
                                    <PortableSignLocationMap
                                      address={
                                        detail.signDeliveryAddress.googleAddress
                                      }
                                      center={{
                                        lat: detail.signDeliveryAddress
                                          .latitude,
                                        lng: detail.signDeliveryAddress
                                          .longitude,
                                      }}
                                      zoom={31}
                                      readonly={true}
                                    />
                                  </div>
                                )}
                            </Col>
                          </div>
                        )}
                      </Row>
                    </div>
                  );
                })}
              {order && order?.rushFees>0 && (
              <div
                className="bookingContainer border-bottom  paymentContainer "
              >
                <Row className="w-100 ">
                  <Col className="col-md-7">
                    <div>
                      <p className="mb-0">
                        SERVICE
                        :
                      </p>
                      <span>Rush Fees</span>
                    </div>
                    <div>
                      <p className="mb-0">AMOUNT:</p>
                      <span>$ {To2Decimal(order.rushFees)}</span>
                    </div>
                  </Col>
                </Row>
              </div>
              )}
            </div>

            {userType === "Customer" && order.listingId && (
              <div className="reviewContainer">
                <h6>Ratings & Reviews</h6>
                <div className="d-flex justify-content-between align-items-center">
                  {order.orderReivewResponse &&
                  order.orderReivewResponse.myReviewId ? (
                    <>
                      <div className="review_card">
                        {order.orderReivewResponse.myRating > 0 && (
                          <Rating
                            ratingValue={
                              order.orderReivewResponse.myRating * 20
                            }
                          />
                        )}
                        <p className="siteDetails_desc">
                          {order.orderReivewResponse.myComment}
                        </p>
                      </div>
                      <button
                        className="blueBgButton me-3"
                        onClick={() => setShowReviewModal(true)}
                      >
                        {" "}
                        Edit Review{" "}
                      </button>
                    </>
                  ) : (
                    <>
                      <Rating readonly={true} ratingValue={0} />
                      <button
                        className="blueBgButton me-3"
                        onClick={() => setShowReviewModal(true)}
                      >
                        {" "}
                        Add Review{" "}
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          {printIt && (
            <div ref={printRef}>
              <div className="page_container">
                <table className="table">
                  <tr>
                    <td>
                      <img
                        style={{ width: "150px" }}
                        src={require("../../../../assets/images/gopinit.png")}
                        alt="GoPinit"
                      />
                    </td>
                    <td align="right">
                      <div className="top_amount_box">
                        Amount <br />
                        <h4>${To2Decimal(order.totalOrderAmount)}</h4>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <address>
                        <p style={{ fontFamily:'sans-serif' }}>
                          {order.provider.name}
                        </p>
                        <p style={{ fontFamily:'sans-serif' }}>
                          {order.provider.country}, {order.provider.state}
                        </p>
                        <p style={{fontFamily:'sans-serif' }}>
                          {order.provider.city}, {order.provider.pincode},{" "}
                        </p>
                        {/* <p>{order.provider.address1}, {order.provider.address2}</p> */}
                        <p style={{ fontFamily:'sans-serif' }}>
                          {order.provider.contactNo}
                        </p>
                      </address>
                    </td>
                    <td align="right">
                      Billing Address <br />
                      <address>
                        <p style={{ fontFamily:'sans-serif' }}>
                          {order.customer.name}
                        </p>
                        <p style={{ fontFamily:'sans-serif' }}>
                          {order.billingAddress}, {order.billingCity}
                        </p>
                        <p style={{fontFamily:'sans-serif' }}>
                          {order.billingState}, {order.billingCountry},{" "}
                          {order.billingPincode}
                        </p>
                        <p>{order.customer.contactNo}</p>
                      </address>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Tax Number</b> {order.provider.taxInfo}
                    </td>
                    <td align="right">
                      <b>Invoice Number</b> #{order.invoiceNo}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Order Number</b> #{order.orderNo}
                    </td>
                    <td align="right">
                      <b>Invoice Date</b>{" "}
                      {moment(order.createdOn).format("MMM DD, YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b> Order Date</b>{" "}
                      {moment(order.createdOn).format("MMM DD, YYYY")}
                    </td>
                    <td></td>
                  </tr>
                </table>
                <h5>Price Tiers</h5>
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <span>REF ID</span>
                      </th>
                      <th>
                        <span>AD FLIGHTS</span>
                      </th>
                      <th>
                        <span>LOCATION</span>
                      </th>
                      <th>
                        <span>SIZE</span>
                      </th>
                      <th>
                        <span>TYPE</span>
                      </th>
                      <th>
                        <span>PRICE</span>
                      </th>
                      <th>
                        <span>QTY.</span>
                      </th>
                      <th>
                        <span>TOTAL</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.details.map((element, index) => (
                      <tr key={"price_tier_" + index}>
                        <td>{element.refId}</td>
                        <td>{element.duration}</td>
                        <td>
                          {element.site != null
                            ? element.site.location.googleAddress
                            : element.signDeliveryAddress
                            ? element.signDeliveryAddress.googleAddress
                            : ""}
                        </td>
                        <td>{element.sign ? element.sign.sizeText : "-"}</td>
                        <td>
                          {element.sign
                            ? element.sign.mediaTypeText
                            : element.site
                            ? element.site.name
                            : ""}
                        </td>
                        <td>{To2Decimal(element.itemPrice)}</td>
                        <td>{To2Decimal(element.quantity)}</td>
                        <td>{To2Decimal(element.subAmount)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td colSpan={2}>Sub Total</td>
                      <td>{To2Decimal(order.subTotal)}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td colSpan={2}>Sales Tax</td>
                      <td>{To2Decimal(order.totalTax)}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td colSpan={2}>
                        <b>Grand Total</b>
                      </td>
                      <td>{To2Decimal(order.totalOrderAmount)}</td>
                    </tr>
                  </tfoot>
                </table>

                <aside>
                  {/* <h1><span contenteditable>Additional Notes</span></h1> */}
                  <p style={{fontFamily:'sans-serif' }}>
                    Taxes are subject to change taxes accordingly. based on
                    provincial and/or federal tax rulings. Invoices will reflect
                    all
                  </p>
                </aside>
                <aside>
                  <table className="table">
                    <tr>
                      <td>
                        <img
                          style={{ width: "100px" }}
                          src={require("../../../../assets/images/gopinit.png")}
                          alt="GoPinit"
                        />
                      </td>
                      <td align="right">
                        <p style={{ fontFamily:'sans-serif' }}>
                          35 Bayward Market Square Area,{" "}
                        </p>
                        <p style={{ fontFamily:'sans-serif' }}>Ottawa, Canada </p>
                      </td>
                    </tr>
                  </table>
                </aside>
              </div>
            </div>
          )}

          <ExtendOrderModal
            order={order}
            setShow={setShowExtendOrder}
            show={showExtendOrder}
            footerText="Submit"
            title="Extend Order"
            handlefooterBtn={handleExtendOrderSubmit}
            setExtendedDates={setExtendedDates}
            extendOrderAmount={extendOrderAmount}
            fromDate={
              orderPeriod && orderPeriod.toDate
                ? moment(orderPeriod.toDate).add(1, "d").format("DD-MMM-YYYY")
                : null
            }
            setOfferAmount={(amt) => setExtendOrderOfferAmount(amt)}
            offerAmount={extendOrderOfferAmount}
            uptoDate={
              orderExtendUpto
                ? new Date(moment(orderExtendUpto).format("DD-MMM-YYYY"))
                : null
            }
            key="ExtendOrderModal"
          />
          <OrderRefundModal
            setShow={setShowRefundAmount}
            show={showRefundAmount}
            footerText="Submit"
            title="Refund Request"
            handlefooterBtn={handleSubmitBtn}
            key="RefundOrderModal"
            refundTypeText={refundTypeText}
            refundAmountText={refundAmountText}
            status={refundStatus}
            setStatus={setRefundStatus}
            setRefundReason={setRefundRejectionReason}
          />
          <CenterPopUpModal
            datepicker={false}
            selection={false}
            setShow={setShowConfirmModal}
            show={showConfirmModal}
            footerText="Ok"
            title="Refund Confirmation"
            bodyText={`Are you sure to ${
              refundStatus === "Approve" ? "Approve" : "Reject"
            } this refund!`}
            handlefooterBtn={handleOkayBtn}
            key="Exo"
          />
          <UpdateOrderStatusModal
            setShow={setShowStatusModal}
            show={showStatusModal}
            order={order}
          />
          <RefundRequestModal
            setShow={setShowRefundRequestModal}
            show={showRefundRequestModal}
            order={order}
          />
          <AddReviewModal
            setShow={setShowReviewModal}
            show={showReviewModal}
            order={order}
          />
          {payoutConfirmModal && (
            <ConfirmModal
              title="Release Payout"
              isModalHeader={true}
              isFooterBtns={true}
              cancelText="No"
              confirmText="Yes"
              handleClose={() => setPayoutConfirmModal(false)}
              handleConfirm={() => releasePayout()}
              width="400px"
            >
              <div className="childs">
                <p className="newListingCreated_description ms-4 py-3">
                  Are you sure you want to release payout for this order?
                </p>
              </div>
            </ConfirmModal>
          )}
        </div>
      )}
    </>
  );
};
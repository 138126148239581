import React from "react";
import { CrossSVG } from "../../assets/svgComponents/svg";

function ConfirmModal(props) {
  const { isModalHeader, width, type } = props;
  return (
    <div className="confirmModal">
      <div
        className="confirmModalOver"
        // onClick={() => props.handleClose(false)}
      ></div>
      <div
        className="confirmModalInner modalbox"
        style={{ maxWidth: `${width}` }}
      >
        {isModalHeader && (
          <div className="confirmModalTop d-flex justify-content-between">
            <h3
              className={`font-weight-light mb-1 mw-100 p-4 ps-3 confirmModalTopTitle ${
                props.titleWidthClassName && props.titleWidthClassName
              }`}
            >
              {props.title}
            </h3>
            <span
              className="px-3 py-2 confirmModalTopCross"
              onClick={() => props.handleClose()}
            >
              <CrossSVG />
            </span>
          </div>
        )}
        <div className="modal-childs">{props.children}</div>
        <div className="confirmModalBottom border-top">
          <div className="row no-gutters g-0">
            {props.isFooterBtns ? (
              <>
                <div className="col" onClick={props.confirm && props.confirm}>
                  <button
                    className="btn btn-success btn-block border-0  w-100 confirmModalConfirmBtn"
                    disabled={props.disable}
                    type={type}
                    onClick={() => props.handleConfirm()}
                  >
                    <span className="text-md">{props.confirmText}</span>
                  </button>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-block border-0  w-100 confirmModalCancelBtn"
                    onClick={() => props.handleClose(false)}
                    disabled={props.disable}
                  >
                    <span className="text-md">{props.cancelText}</span>
                  </button>
                </div>
              </>
            ) : (
              props.confirmText && (
                <div className="col" onClick={props.confirm && props.confirm}>
                  <button
                    className="btn btn-success btn-block border-0  w-100 confirmModalConfirmBtn"
                    disabled={props.disable}
                    onClick={() => props.handleConfirm && props.handleConfirm()}
                    type={type}
                  >
                    <span className="text-md">{props.confirmText}</span>
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;

import React, { useEffect, useState } from "react";
import { FilterModal } from "./FilterModal";
import { UnionSVG } from "../../../../assets/svgComponents/table";
import { OrderCard } from "./OrderCard";
import { CenterPopUpModal } from "../../Provider/OrderAndTransaction/Modal/CenterPopUpModal";
import { SearchBox } from "./SearchBox";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrdersAsync,
  setSearchString,
} from "../../../../services/reducers/orders-reducers";
import { OrderDetails } from "./OrderDetails";
import {
  getFilterOptionsAsync,
  getSortingOptionsAsync,
  getSelectedOptions,
  getCustomFilterOptionsAsync,
  getSelectedSortBy,
} from "../../../../services/reducers/filter-reducers";
import { getProvidersForFilters } from "../../../../services/api/orders-store-api";
import { FloatingLableSearchBar } from "../../../../components/SearchBar/FloatingLableSearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import { SelectComponent } from "../../../../components/SelectionComponent/SelectComponent";
import moment from "moment";
import { useLocation } from "react-router";
import { getOrderAsync } from "../../../../services/reducers/orders-reducers";
export const OrdersPage = (props) => {
  const search = useLocation().search;
  const orderId = new URLSearchParams(search).get("orderId");
  const userType = localStorage.getItem("userType");
  const dt = new Date();
  dt.setMonth(dt.getMonth() - 1);
  const nxtDt = new Date();
  nxtDt.setDate(nxtDt.getDate() + 1);
  const [selectedMonth, setSelectedMonth] = useState({
    id: 1,
    name: "Current Month",
  });
  const [page, setPage] = useState(1);
  const { refreshOrdersOn, orders, order, searchString } = useSelector(
    (state) => state.orders
  );
  const checkBoxFilters = useSelector((state) =>
    state.filters.checkBoxFilters.filter((r) => r.page.indexOf("orders") >= 0)
  );
  const sortingOptions = useSelector((state) => state.filters.sortingOptions);
  //const {fromDate, toDate} = useSelector((state)=> state.orders);
  const [fromDate, setFromDate] = useState(dt);
  const [toDate, setToDate] = useState(nxtDt);
  const selectedOrderStatus = useSelector((state) =>
    getSelectedOptions(state, "orders", "OrderStatus")
  );
  const selectedProviders = useSelector((state) =>
    getSelectedOptions(state, "orders", "Providers")
  );
  const selectedMediaTypes = useSelector((state) =>
    getSelectedOptions(state, "orders", "MediaTypes")
  );
  const selectedSortBy = useSelector((state) =>
    getSelectedSortBy(state, "orders")
  );
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  //const [searchString, setSearchString] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const monthList = [
    { id: 1, name: "Current Month" },
    { id: 2, name: "Last 3 Months" },
    { id: 3, name: "Last 6 Months" },
    { id: 4, name: "Current Year" },
  ];
  useEffect(() => {
    if (orderId) {
      dispatch(getOrderAsync(orderId));
      setSelectedOrder(orderId);
    }
  }, [dispatch, orderId]);
  useEffect(async () => {
    setLoader(true);
    getOrders();
    dispatch(getFilterOptionsAsync("OrderStatus"));
    dispatch(getFilterOptionsAsync("MediaTypes"));
    dispatch(getSortingOptionsAsync());
    dispatch(
      getCustomFilterOptionsAsync({
        page: ["orders"],
        type: "Providers",
        apiFunc: getProvidersForFilters,
        params: {},
      })
    );

    setLoader(false);
    //  alert('test');
  }, [dispatch]);

  useEffect(() => {
    getOrders();
  }, [page]);

  useEffect(() => {
    if (refreshOrdersOn) {
      if (page !== 1) {
        setPage(1);
      } else {
        getOrders();
      }
    }
  }, [refreshOrdersOn]);

  const getOrders = () => {
    dispatch(
      getOrdersAsync({
        page: page,
        pageSize: 10,
        orderBy: "",
        export: false,
        searchString: searchString,
        fromDate:
          userType === "Customer"
            ? moment(fromDate).format("DD-MMM-yyyy")
            : null,
        toDate:
          userType === "Customer" ? moment(toDate).format("DD-MMM-yyyy") : null,
        statusIds: selectedOrderStatus,
        providerIds: selectedProviders,
        mediaTypeIds: selectedMediaTypes,
        sortBy: userType === "Customer" ? 2 : selectedSortBy,
      })
    ).then((res) => {
      if (res) {
        if (res.payload?.records.length > 0)
          setSelectedOrder(res.payload.records[0].id);
          dispatch(getOrderAsync(res.payload.records[0].id))
      }
    });
  };

  const handleApplyFilters = async () => {
    setShowFilterModal(false);
    if (page !== 1) {
      setPage(1);
    } else {
      getOrders();
    }
  };

  const handleMonthFilter = (e) => {
    setToDate(new Date());
    let td = new Date();
    setSelectedMonth(e);
    let value = e.id;
    if (value == 1) {
      td.setMonth(new Date().getMonth() - 1);
    } else if (value == 2) {
      td.setMonth(new Date().getMonth() - 3);
    } else if (value == 3) {
      td.setMonth(new Date().getMonth() - 6);
    } else if (value == 4) {
      td.setMonth(new Date().getMonth() - 12);
    }
    setFromDate(td);
  };

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      getOrders();
    }
  }, [fromDate]);

  const checkIfEmpty = (term) => {
    if (term == "") {
      getOrders();
    }
  };

  if (loader) {
    return null; //<div>loading...</div>;
  } else {
    return (
      <div className="informationMainContainer flex-grow-1 h-100 overflow-auto">
        <div className="row w-100 g-0 d-flex h-100 flex-column">
          <div
            id="pnl-list-orders"
            className={
              orders.totalRecords > 0
                ? "section_1 col-md-7 flex-grow-1 overflow-auto h-100 scrollOverBottom"
                : "section_1 col-md-7 flex-grow-1 overflow-auto h-100 scrollOverBottomDone"
            }
          >
            {userType === "Customer" ? (
              <div className="row w-97 g-0">
                <div className="col-md-12">
                  <div style={{ float: "right", marginBottom: "5px" }}>
                    <SelectComponent
                      label=""
                      option={monthList}
                      value={selectedMonth}
                      defaultValue={selectedMonth}
                      inputsize="form-control-sm rouned-3 sel-chart float-start border-0"
                      handleOnchange={handleMonthFilter}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <SearchBox
                    value={searchString}
                    label="Search Orders(Order Id, Customer, Provider Name)"
                    handleTermChange={(term) => {
                      dispatch(setSearchString(term));
                      checkIfEmpty(term); /*handleApplyFilters()*/
                    }}
                    onSearch={handleApplyFilters}
                    searchafter={"searchafter"}
                  />
                </div>
              </div>
            ) : (
              <div className="row w-97">
                <div className="col-md-9">
                  <SearchBox
                    value={searchString}
                    label="Search Orders(Order Id, Customer, Provider Name)"
                    handleTermChange={(term) => {
                      dispatch(setSearchString(term));
                      checkIfEmpty(term); /*handleApplyFilters()*/
                    }}
                    onSearch={handleApplyFilters}
                    searchafter={"searchafter"}
                  />
                </div>
                <div className="col-md-3 text-end pe-0">
                  <button
                    className="secondaryBtn d-flex justify-content-around"
                    onClick={() => setShowFilterModal(true)}
                  >
                    <span>Filter</span>
                    <span className="btnSvg mx-2">
                      <UnionSVG />
                    </span>
                  </button>
                </div>
              </div>
            )}
            <div className="ordercardConainer">
              {/* {ordersData?.records.map((order) => (
                <OrderCard active="active" item={order} key={order.id} />
              ))}  */}

              {orders && orders.records.length > 0 ? (
                <InfiniteScroll
                  dataLength={orders.records.length - 1}
                  hasMore={true}
                  next={() => {
                    setPage(page + 1);
                  }}
                  scrollableTarget="pnl-list-orders"
                  //loader={<h6>Loading...</h6>}
                  hasChildren={orders.records.length > 0}
                >
                  {orders.records.map((order) => (
                    <OrderCard
                      active={selectedOrder == order.id ? "active" : ""}
                      item={order}
                      key={order.id}
                      userType={userType}
                      setSelectedOrder={setSelectedOrder}
                    />
                  ))}
                </InfiniteScroll>
              ):<>No data available</>}
            </div>
          </div>

          {order && order.id && (
            <div className="col-md-5 flex-grow-1 h-100 overflow-auto">
              <OrderDetails getOrders={getOrders} />
            </div>
          )}

          <FilterModal
            show={showFilterModal}
            filters={checkBoxFilters}
            sorting={sortingOptions}
            applyFilters={handleApplyFilters}
            closeIt={() => setShowFilterModal(false)}
            key="OrdersFilterModal"
          />

          <CenterPopUpModal
            datepicker={true}
            selection={true}
            setShow={setShowUpdateStatus}
            show={showUpdateStatus}
            footerText="Add"
            title="Update Status"
            key="UpdateStatusModal"
          />
        </div>
      </div>
    );
  }
};

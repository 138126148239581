import { http } from "./httpService";

export const getFiltersObject = (_checkBoxFilters = null,_sortingOptions = null) =>{
    return {
        page : 0,
        pageSize : 10,
        export : false,
        searchString : '',
        checkBoxFilters : _checkBoxFilters ? _checkBoxFilters : [],
        sortingOptions : _sortingOptions ? { options : _sortingOptions } : { options:[]},
    };
} 

export const getOptions = (name) => {
    return http.get(`/api/Order/${name}/Options`);
}

export const processFilterOptions = (data) =>{
    return data.map((itm)=> { return { id:itm.id, name:itm.name, isChecked:false };});
 };

export const getAppliedFilters = (filters,name) => {
     let ids = [];
     if(filters && name){
         filters.filter(r=>r.title===name).forEach(r => { 
             r.options.forEach(opt=>{
                 if(opt.isChecked === true)ids.push(opt.id);
             }) 
         });
     }
     return ids;
 }

export const getAppliedSorting = (sorting) => {
     let ids = [];
     if(sorting){
         sorting.options.filter(r=>r.isChecked===true).forEach(r => { 
             r.options.forEach(opt=>{
                 ids.push(opt.id);
             }) 
         });
     }
     return ids;
 }

export const getAppliedSortingSingle = (sorting) => { 
     if(sorting){
         let selectedOptions = sorting.options.filter(r=>r.isChecked===true);
         if(selectedOptions && selectedOptions.length>0)return selectedOptions[0].id;
     }
     return null;
 }

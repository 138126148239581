import React from "react";
import { Modal } from "react-bootstrap";
import BtnRadio from "../../../../../components/BtnRadio/BtnRadio";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import CustomCheckbox from "../../../../../components/checkbox/CustomCheckbox";

export const FilterModal = ({
  show,
  statuslable,
  sortByLable,
  medialable,
  providerlable,
  handleProviderChange,
  handleApplyBtn,
  transactionLable,
  setShowTranFilterModal,
  accessTypeLable,
  sitelable,
  handleSortChange,
  selectedProviders,
  sortValue,
  handleClearAllFilter
}) => {
  return (
    <Modal
      show={show}
      onHide={() => setShowTranFilterModal(false)}
      dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
    >
      <Modal.Header closeButton>
        <h5 className="modaltitle">Filters</h5>{" "}
        <a onClick={handleClearAllFilter}>
            <span className="badgeMain ms-1" style={{cursor:'pointer'}}>Clear all</span>
          </a>
      </Modal.Header>
      <Modal.Body>
        {sitelable ? (
          <div className="modalStatus mt-4">
            <h6>Site</h6>
            {sitelable.map((item) => {
              return (
                <div className="mt-3">
                  <Checkbox text={item.name} />
                </div>
              );
            })}
          </div>
        ) : null}
        {providerlable ? (
          <div className="modalStatus mt-4">
            <h6>Provider</h6>
            {providerlable.map((item) => {
              if (
                selectedProviders.filter((list) => list === item.id).length > 0
              ) {
                return (
                  <div className="checkbox_container">
                    <CustomCheckbox
                      text={item.name}
                      value={item.id}
                      action={(e) => handleProviderChange(e)}
                      checked
                    />
                  </div>
                );
              } else {
                return (
                  <div className="checkbox_container">
                    <CustomCheckbox
                      text={item.name}
                      value={item.id}
                      action={(e) => handleProviderChange(e)}
                    />
                  </div>
                );
              }
            })}
          </div>
        ) : null}
        {statuslable ? (
          <div className="modalStatus mt-4">
            <h6>Status</h6>
            {statuslable.map((item) => {
              return (
                <div className="mt-3">
                  <CustomCheckbox text={item.name} />
                </div>
              );
            })}
          </div>
        ) : null}
        {sortByLable ? (
          <div className="modalStatus mt-4">
            <h6>Sort By</h6>
            {sortByLable.map((item) => {
              return (
                <div className="checkbox_container">
                  <BtnRadio
                    name="sort"
                    label={item.name}
                    value={item.value}
                    onChangeStatus={(e) => handleSortChange(e)}
                    status={sortValue === item.value ? true : false}
                  />
                </div>
              );
            })}
          </div>
        ) : null}

        {medialable ? (
          <div className="modalStatus mt-4">
            <h6>Media</h6>
            {medialable.map((item) => {
              return (
                <div className="mt-3">
                  <Checkbox text={item.name} />
                </div>
              );
            })}
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <div className="applyFilter">
          <button
            className="blueBgButton applyFilterBtn py-3 px-5"
            onClick={handleApplyBtn}
          >
            Apply
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

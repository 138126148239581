import React, {useContext, useState} from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  HelpIcon,
  LogoutIcon,
  OrderIcon,
  ProfileIcon,
} from "../../assets/svgComponents/inventorySVG";
import { GLogo } from "../../assets/svgComponents/inventorySVG";
import {
  SVGHome,
  SVGReports,
  SVGNotFavorite,
} from "../../assets/svgComponents/svg";
import ProjectContext from "../../context/ProjectContext";

const SideNav = (props) => { 
  
  const { activeMenu } = useContext(ProjectContext);
  const userType = localStorage.getItem("userType");
  const [activeTab, setActiveTab] = useState("Home");
  const [mouseOnLogo,setMouseOnLogo] = useState(false);
  const handleNavHoverOn = () => {
    setMouseOnLogo(true); 
    const WindowWidth = window.outerWidth;
    if (WindowWidth > 1199) {
      props.setOpenNav(true);
    }
  };
  const handleNavHoverOff = () => {
    setMouseOnLogo(false); 
    const WindowWidth = window.outerWidth;
    if (WindowWidth > 1199) {
      props.setOpenNav(false);
    }
  };

  useEffect(()=>{
    console.log('current path',window.location.pathname);
    if(activeMenu){
      setActiveTab(activeMenu);
    }
  },[activeMenu]);
  return (
    <>
      <div onMouseEnter={handleNavHoverOn} onMouseLeave={handleNavHoverOff}>
      {/* <div> */}
        <Row className="sidebar_main g-0 d-block">
          <Col className="sidebar_col01">
          <div className={ mouseOnLogo ? "sidebar_logo_main sidebar_align gopinit_logo_hover" : "sidebar_logo_main sidebar_align" }>
              {/* <Link to="/customer" className="sidebar_link"> */}
                {/* <span> */}
                  <GLogo />
                {/* </span> */}
              {/* </Link> */}
              {props.openNav ? <h3 className="m-0"> GoPinIt </h3> : ""}
            </div>
          </Col>
          <p
              className={
                props.openNav
                  ? "sidebar_heading01 sidebar_align m-0"
                  : "sidebar_icons_heading"
              }
            >
              MENU
            </p>
          <Col className="">
           
            <div className={(props.openNav ? "sidebar_tabs" : "sidebar_icon") + (activeTab === "Home" ? " sidebar_active_tab " : "")} 
            onClick={() => setActiveTab("Home")}>
              <Link
                to="/customer"
                className={props.openNav ? "sidebar_link" : ""}
              >
                <span className={(activeTab === "Home" ? " sideNavIcon " : "")}>
                  <SVGHome fill={activeTab === "Home" && "#F99300"}/>
                </span>
                {props.openNav ? (
                  <p className={props.openNav ? "sidebar_text" : ""}>Home</p>
                ) : (
                  ""
                )}
              </Link>
            </div>
            <div className={ (props.openNav ? "sidebar_tabs" : "sidebar_icon") + (activeTab === "Orders" ? " sidebar_active_tab " : "") }
            onClick={() => setActiveTab("Orders")}>
              <Link to="/customer/orders" className="sidebar_link">
                <span className={(activeTab === "Orders" ? " sideNavIcon " : "")}>
                  <OrderIcon fill={activeTab === "Orders" && "#F99300"}/>
                </span>
                {props.openNav ? <p className="sidebar_text">Orders</p> : ""}
              </Link>
            </div>
            {/* <div className={props.openNav ? "sidebar_tabs" : "sidebar_icon"}>
              <Link to="/order" className="sidebar_link">
                <span>
                  <OrderIcon />
                </span>
                {props.openNav ? <p className="sidebar_text">Orders</p> : ""}
              </Link>
            </div> */}
            <div className={(props.openNav ? "sidebar_tabs" : "sidebar_icon") + (activeTab === "Reports" ? " sidebar_active_tab " : "")}
            onClick={() => setActiveTab("Reports")}>
              <Link to="/customer/reports" className="sidebar_link">
                <span className={(activeTab === "Reports" ? " sideNavIcon " : "")}>
                  <SVGReports  fill={activeTab === "Reports" && "#F99300"}/>
                </span>
                {props.openNav ? <p className="sidebar_text">Reports</p> : ""}
              </Link>
            </div>
            {
              (userType ==="Temp"?'':(
                <div className={(props.openNav ? "sidebar_tabs" : "sidebar_icon") + (activeTab === "Help & Support" ? " sidebar_active_tab " : "")}
                onClick={() => setActiveTab("Help & Support")}>
                  <Link to="/customer/add-support-request" className="sidebar_link">
                    <span className={(activeTab === "Help & Support" ? " sideNavIcon " : "")}>
                      <HelpIcon fill={activeTab === "Help & Support" && "#F99300"}/>
                    </span>
                    {props.openNav ? (
                      <p className="sidebar_text">Help & Support</p>
                    ) : (
                      ""
                    )}
                  </Link>
                </div>
              )
              )
            }            
          </Col>
          <Col className="">
            <div className="sidebar_col03">
            <div
                className={
                  props.openNav
                    ? "ps-4 pt-4 sidebar_profile_main"
                    : " sidebar_profile_main"
                }
              >
                <p
                  className={props.openNav ? "pro01" : " sidebar_icons_heading"}
                >
                  ACCOUNT
                </p>
                {
                  (userType ==="Temp"?'':(
                    <div className={(props.openNav ? "pro021" : "sidebar_icon") + (activeTab === "Profile" ? " sidebar_active_tab " : "")}
                    onClick={() => setActiveTab("Profile")}>
                      <Link to="/customer/profile" className="sidebar_link">
                        <span className={(activeTab === "Profile" ? " sideNavIcon " : "")}>
                          <ProfileIcon fill={activeTab === "Profile" && "#F99300"}/>
                        </span>
                        {props.openNav ? (
                          <p className="sidebar_text">Profile</p>
                        ) : (
                          ""
                        )}
                      </Link>
                    </div>
                      )
                  )
                }                  
                <div className={(props.openNav ? "pro021" : "sidebar_icon") + (activeTab === "Favorites" ? " sidebar_active_tab " : "")}
                onClick={() => setActiveTab("Favorites")}>
                  <Link to="/customer/favorites" className="sidebar_link">
                    <span className={(activeTab === "Favorites" ? " sideNavIcon " : "")}>
                      <SVGNotFavorite fill={activeTab === "Favorites" && "#F99300"} />
                    </span>
                    {props.openNav ? (
                      <p className="sidebar_text">Favorites</p>
                    ) : (
                      ""
                    )}
                  </Link>
                </div>
                {
                  (userType ==="Temp"?'':(
                      <div className={props.openNav ? "pro03" : "sidebar_icon"}>
                          <Link to="" className="sidebar_link" onClick={props.logout}>
                            <span>
                              <LogoutIcon />
                            </span>
                            {props.openNav ? (
                              <p className="sidebar_text">Logout</p>
                            ) : (
                              ""
                            )}
                          </Link>
                        </div>
                    )
                  )
                }  
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SideNav;

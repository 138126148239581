import {
  createAsyncThunk,
  createSlice,
  current,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import {
  getListBySites,
  getAddressByLatLng,
  getListByType,
  getListItemDetails,
  submitOrder,
  orderPayment,
  getSimilarListing,
  getListingReviews,
  getAvailableOffersOnSite,
  getDefaultAddress,
  getCurrentUser,
  getEnvironments,
  getPriceRange,
  markAsFavorite,
  markAsUnFavorite,
  bookPortableSign,
  GetListingAvailability,
  getCustomerTotalOrderDetails,
  validateOffer,
  getAvailableOfferForProvider,
} from "../api/customer-store-api";
import { getOptions, processFilterOptions } from "../filters-base";
import { AxiosError } from "axios";
import { getImagesUrl, getImageUrl } from "../utility";
import { startCase } from "lodash";
import { GetOnlyAddressFromCompoundCode } from "../utility";

const initialState = {
  listBySites: { records: [], page: 1 },
  listBySigns: { records: [] },
  listByProviders: { records: [] },
  providerSites: { records: [] },
  listingItem: {},
  similarListing: { records: [] },
  listingReviews: { records: [] },
  availableOffers: { records: [] },
  selectedSigns: [],
  billingAddress: {},
  availedOffer: { offerId: null, offerAmount: 0, offerType: 0 },
  applyOfferResult: null,
  cart: [],
  order: { orderDetails: [] },
  availability: { fromDate: null, toDate: null },
  profile: {},
  orderId: null,
  orderAmount: null,
  searchTerm: "",
  searchSection: { id: "location", name: "Location" },
  listingFilterCost: null,
  listingFilterMedia: null,
  listingFilterEnvironment: null,
  listingFilterDates: null,
  listingFilterFromDate: null,
  listingFilterUptoDate: null,
  listingFilterMinCost: null,
  listingFilterMaxCost: null,
  latitude: null,
  longitude: null,
  locationName: null,
  mapZoom: 8,
  mapDistance: null,
  showOnlyFeatured: false,
  filterProviderId: null,
  costOptions: [],
  mediaTypeOptions: [],
  environmentOptions: [],
  datesOptions: [],
  subscriptionTermId: null,
  orderSubmitResult: null,
  orderSubmitError: null,
  orderPaymentError: null,
  paymentResult: null,
  paymentCardId: null,
  portableSign: null,
  portableSignInfo: null,
  portableSignLocation: null,
  portableSignRadiusFilter: null,
  signLocationName: null,
  portableSignsListing: false,
  oldOrdersInfo: { totalOrders: 0, totalSpend: 0 },
  listingPriceType: null,
  selectedListingType: 0,
  markFavouriteResult: null,
  portableSignId: null,
};

export const getSitesPageNumber = (state) => {
  return { page: state.listBySites.page };
};

export const getCanBook = (state) => {
  const { bookingDays, minimumBookingDays } = getBookingDays(state);
  if (bookingDays < minimumBookingDays)
    return { canBook: false, bookingDays, minimumBookingDays };
  if (
    state.listingItem.type === "Site" &&
    state.listingItem.siteAvailable == false
  ) {
    return { canBook: false, bookingDays, minimumBookingDays };
  } else if (
    state.listingItem.type === "Site" &&
    state.listingItem.signs &&
    state.listingItem.signs.length > 0 &&
    state.listingItem.signs.filter((r) => r.quantity > 0).length == 0
  ) {
    return { canBook: false, bookingDays, minimumBookingDays };
  } else if (
    state.listingItem.type === "Sign" &&
    (!state.listingItem.signs ||
      state.listingItem.signs.length === 0 ||
      state.listingItem.signs.filter((r) => r.available > 0).length === 0)
  ) {
    return { canBook: false, bookingDays, minimumBookingDays };
  } else if (
    state.listingItem.type === "Sign" &&
    state.listingItem.signs &&
    state.listingItem.signs.length > 0 &&
    state.listingItem.signs.filter((r) => r.quantity > 0).length == 0
  ) {
    return { canBook: false, bookingDays, minimumBookingDays };
  }
  return { canBook: true, bookingDays, minimumBookingDays };
};

export const getBookingDays = (state) => {
  const minimumBookingDays = getMinBookingPeriodInDays(state);
  if (
    state &&
    state.availability &&
    state.availability.fromDate &&
    state.availability.toDate
  ) {
    return {
      bookingDays: getDateDifference(
        state.availability.fromDate,
        state.availability.toDate
      ),
      minimumBookingDays,
    };
  }
  return { bookingDays: 0, minimumBookingDays };
};

export const isAvailableForBooking = (state) => {
  if (state.listingItem.type == "Sign") {
    if (state.listingItem.signs.length > 0) {
      return state.listingItem.signs[0].available > 0;
    }
    return false;
  } else return state.listingItem.siteAvailable;
};

export const getListingItemImage = (state) => {
  if (state.listingItem.type === "Sign") {
    return {
      imageUrl:
        state.listingItem && state.listingItem.image
          ? getImageUrl(state.listingItem.image)
          : null,
    };
  } else {
    return {
      imageUrl:
        state.listingItem && state.listingItem.images
          ? getImageUrl(state.listingItem.images[0])
          : null,
    };
  }
};

export const getListingItemImages = (state) => {
  // let images = [];
  // if (state.listingItem) {
  //   if (state.listingItem.type === "Sign") {
  //     if (state.listingItem.signImages) {
  //       state.listingItem.signImages.map((img) => {
  //         images.push(img);
  //       });
  //     }
  //     if (state.listingItem.sites) {
  //       state.listingItem.sites.map((site) => {
  //         site.images.map((img) => {
  //           images.push(img);
  //         });
  //       });
  //     }
  //   } else {
  //     if (state.listingItem.signImages) {
  //       state.listingItem.signImages.map((img) => {
  //         images.push(img);
  //       });
  //     }
  //     if (state.listingItem.images) {
  //       state.listingItem.images.map((img) => {
  //         images.push(img);
  //       });
  //     }
  //   }
  //   if (images.length > 0) return getImagesUrl(images);
  //   else return `../assets/images/no-image.png`;
  // }

  // if (state.listingItem.type === "Sign") {
  //   return {
  //     imageUrl:
  //       state.listingItem && state.listingItem.signImages
  //         ? getImagesUrl(state.listingItem.signImages)
  //         : null,
  //   };
  // } else {
    return {
      imageUrl:
        state.listingItem && state.listingItem.images
          ? getImagesUrl(state.listingItem.images)
          : null,
    };
  //}
};

export const getBookingDetails = (state) => {
  let site = null;
  let siteRent = null;
  let addOnServicePrice = 0;
  let addOnChargePrice = 0;
  let additionalCharges = 0;
  let offerDiscount = state.availedOffer ? state.availedOffer.offerAmount : 0;
  let sites = state.cart ? state.cart.filter((r) => r.siteId != null) : [];
  let bookingType = "site";
  if (sites && sites.length > 0) {
    site = sites[0].site;
  }
  if (state.listingItem && state.listingItem.type == "Sign") {
    bookingType = "sign";
  }
  let noOfServices = 0;
  let noOfCharges = 0;
  let noOfSigns = 0;
  let signAmount = 0;
  let totalTax = 0;
  let addOnServicePriceWithoutTax = 0;
  let addOnChargePriceWithoutTax = 0;
  let signAmountWithoutTax = 0;
  let siteRentWithoutTax = 0;
  if (state.cart) {
    state.cart.forEach((s) => {
      totalTax += s.taxAmount;
    });
    state.cart
      .filter((r) => r.serviceId != null)
      .forEach((s) => {
        addOnServicePrice += s.subAmount + s.taxAmount;
        addOnServicePriceWithoutTax += s.subAmount;
        noOfServices += 1;
      });
    state.cart
      .filter((r) => r.chargeId != null)
      .forEach((s) => {
        addOnChargePrice += s.subAmount + s.taxAmount;
        addOnChargePriceWithoutTax += s.subAmount;
        noOfCharges += 1;
      });
    state.cart
      .filter((r) => r.signId != null)
      .forEach((s) => {
        signAmount += s.subAmount + s.taxAmount;
        signAmountWithoutTax += s.subAmount;
        noOfSigns += s.quantity;
      });
    state.cart
      .filter((r) => r.siteId != null)
      .forEach((s) => {
        siteRent += s.subAmount + s.taxAmount;
        siteRentWithoutTax += s.subAmount;
      });
  }
  if (signAmount > 0) {
    siteRent = 0;
  }
  const res = {
    bookingType,
    site,
    signs: state.cart ? state.cart.filter((r) => r.signId != null) : [],
    services: state.cart ? state.cart.filter((r) => r.serviceId != null) : [],
    charges: state.cart ? state.cart.filter((r) => r.chargeId != null) : [],
    siteRent,
    addOnServicePrice,
    addOnChargePrice,
    offerDiscount,
    noOfSigns: noOfSigns,
    signAmount,
    //totalAmount : (siteRent + addOnServicePrice + signAmount - offerDiscount),
    totalAmount: addOnServicePrice + addOnChargePrice + signAmount + siteRent,
    netAmount: addOnServicePrice + addOnChargePrice + siteRent + signAmount - offerDiscount,
    fromDate: state.availability ? state.availability.fromDate : null,
    toDate: state.availability ? state.availability.toDate : null,
    noOfSelectedServices: noOfServices,
    noOfSelectedCharges: noOfCharges,
    additionalCharges,
    applicableAmount: siteRent + signAmount,
    applicableAmountWithoutTax: siteRentWithoutTax + signAmountWithoutTax,
    siteRentWithoutTax,
    signAmountWithoutTax,
    addOnServicePriceWithoutTax,
    addOnChargePriceWithoutTax,
    totalTax,
  };
  return res;
};

export const calcTax = (state, amount) => {
  let taxRate = 0;
  if (amount === 0) return 0;
  if (state.listingItem.stateTaxDetails) {
    taxRate = parseFloat(state.listingItem.stateTaxDetails.totalTaxRate);
    //return Math.round(amount * parseFloat(taxRate/100));
    return amount * parseFloat(taxRate / 100);
  }
  return 0;
};

export const calcServiceCharge = (state, service) => {
  const { summaryChargesItems } = state.listingItem;
  const { totalAmount, signAmount } = getBookingDetails(state);
  let OrderValue = totalAmount;
  let taxAmount = 0;
  let subAmount = 0;
  let summaryChargesItemId = null;
  summaryChargesItems
    .filter((r) => r.serviceId === service.serviceId)
    .forEach((scitem) => {
      summaryChargesItemId = scitem.id;
      if (scitem.chargesTypeId === 0) {
        //included type
        taxAmount = 0;
        subAmount = 0;
      } else if (scitem.chargesTypeId === 1 && scitem.priceTypeId === 0) {
        //addon and Fixed
        subAmount = scitem.priceValue;
      } else if (
        scitem.chargesTypeId === 1 &&
        scitem.priceTypeId === 1 &&
        OrderValue > 0
      ) {
        //add on and percentage
        //subAmount = Math.round(OrderValue * (scitem.priceValue / 100));
        subAmount = signAmount * (scitem.priceValue / 100);
      } else {
        taxAmount = 0;
        subAmount = 0;
      }

      if (subAmount > 0) {
      if (scitem.service) taxAmount = calcTax(state, subAmount); //calcApplicableTax(scitem.service,subAmount);

        // service.subAmount = subAmount;
        // service.taxAmount = taxAmount;
        // state  = {...state, listingItem:{...state.listingItem,services:[...state.listingItem.services.filter(r=>r.serviceId!==service.serviceId),{...service,price:(subAmount + taxAmount)}]}};
        //service.price = (subAmount + taxAmount);
      }
      // return taxAmount + subAmount;
      //console.log('service price ',service,summaryChargesItems,taxAmount,subAmount);
    });
  return {
    summaryChargesItemId,
    taxAmount,
    subAmount,
    price: taxAmount + subAmount,
  };
};

export const calcCharge = (state, charge) => {
  const { summaryChargesItems } = state.listingItem;
  const { totalAmount, signAmount } = getBookingDetails(state);
  let OrderValue = totalAmount;
  let taxAmount = 0;
  let subAmount = 0;
  let summaryChargesItemId = null;
  summaryChargesItems
    .filter((r) => r.chargeId === charge.chargeId)
    .forEach((scitem) => {
      summaryChargesItemId = scitem.id;
      if (scitem.chargesTypeId === 0) {
        //included type
        taxAmount = 0;
        subAmount = 0;
      } else if (scitem.chargesTypeId === 1 && scitem.priceTypeId === 0) {
        //addon and Fixed
        subAmount = scitem.priceValue;
      } else if (
        scitem.chargesTypeId === 1 &&
        scitem.priceTypeId === 1 &&
        OrderValue > 0
      ) {
        //add on and percentage
        //subAmount = Math.round(OrderValue * (scitem.priceValue / 100));
        subAmount = signAmount * (scitem.priceValue / 100);
      } else {
        taxAmount = 0;
        subAmount = 0;
      }

      if (subAmount > 0) {
        if (scitem.charge) taxAmount = calcTax(state, subAmount); //calcApplicableTax(scitem.service,subAmount);

        // service.subAmount = subAmount;
        // service.taxAmount = taxAmount;
        // state  = {...state, listingItem:{...state.listingItem,services:[...state.listingItem.services.filter(r=>r.serviceId!==service.serviceId),{...service,price:(subAmount + taxAmount)}]}};
        //service.price = (subAmount + taxAmount);
      }
      // return taxAmount + subAmount;
      //console.log('service price ',service,summaryChargesItems,taxAmount,subAmount);
    });
  return {
    summaryChargesItemId,
    taxAmount,
    subAmount,
    price: taxAmount + subAmount,
  };
};

export const getApplicableCharge = (state, orderDetail) => {
  const { summaryChargesItems } = state.listingItem;
  const { totalAmount } = getBookingDetails(state);
  let OrderValue = totalAmount;

  summaryChargesItems
    .filter(
      (r) =>
        r.serviceId === orderDetail.serviceId ||
        r.chargeId === orderDetail.chargeId
    )
    .forEach((scitem) => {
      let taxAmount = 0;
      let subAmount = 0;
      if (scitem.chargesTypeId === 0) {
        //included type
        taxAmount = 0;
        subAmount = 0;
      } else if (scitem.chargesTypeId === 1 && scitem.priceTypeId === 0) {
        //addon and Fixed
        subAmount = scitem.priceValue;
      } else if (
        scitem.chargesTypeId === 1 &&
        scitem.priceTypeId === 1 &&
        OrderValue > 0
      ) {
        //add on and percentage
        // subAmount = Math.round(OrderValue * (scitem.priceValue / 100));
        subAmount = OrderValue * (scitem.priceValue / 100);
      } else {
        taxAmount = 0;
        subAmount = 0;
      }

      if (subAmount > 0) {
        if (scitem.service) taxAmount = calcTax(state, subAmount); //calcApplicableTax(scitem.service);
        orderDetail.subAmount = subAmount;
        orderDetail.taxAmount = taxAmount;
      }
    });
};

export const getApplicablePrice = (state, quantity = 1) => {
  //const selectedDays = getDateDifference(state.availability.fromDate,state.availability.toDate) ?? 1;
  if (
    state.listingItem.priceTiers &&
    state.listingItem.priceTiers.length == 1 &&
    state.listingItem.priceTiers[0].priceType === 1
  ) {
    state = { ...state, listingPriceType: "Fixed" };
    return state.listingItem.priceTiers[0];
  } else if (
    state.listingItem.priceTiers &&
    state.listingItem.priceTiers.length > 0
  ) {
    state = {
      ...state,
      listingPriceType:
        state.listingItem.priceTiers.filter((r) => r.priceType === 0).length > 0
          ? "Differential"
          : "Fixed",
    };
    var applicablePriceTiers = state.listingItem.priceTiers.filter(
      (r) =>
        r.priceType === 1 ||
        (r.minimumQuantity <= quantity &&
          r.maximumQuantity >= quantity &&
          r.priceType === 0)
    );
    if (applicablePriceTiers && applicablePriceTiers.length > 0) {
      return applicablePriceTiers[0];
    }
  } else {
    state.listingPriceType = null;
    //return state.listingItem.price;
  }
  return null;
};

export const getMinBookingPeriodInDays = (state) => {
  let period = state.listingItem.minBookingPeriod;
  if (period == 0) period = 1;
  if (state.listingItem.periodType === 0) return period;
  else if (state.listingItem.periodType === 1) return period * 7;
  else if (state.listingItem.periodType === 2) return period * 30;
  return period * 7;
};

export const getItemPrice = (state, price, quantity) => {
  const days =
    getDateDifference(state.availability.fromDate, state.availability.toDate) ??
    1;
  let bookingPeriod = getMinBookingPeriodInDays(state);
  //let itemPrice = Math.round(((price/bookingPeriod)*days)*quantity);
  let itemPrice = (price / bookingPeriod) * days * quantity;
  return itemPrice;
};

export const calcPrice = (state, quantity) => {
  let price = state.listingItem.price;
  var applicablePrice = getApplicablePrice(state, quantity);
  if (applicablePrice) {
    price = applicablePrice.amount;
  }
  const amount = getItemPrice(state, price, quantity);
  return amount;
};

export const getDateDifference = (fromdate, todate) => {
  var dt1 = new Date(fromdate);
  var dt2 = new Date(todate);

  var date1 = new Date(
    dt1.getFullYear(),
    dt1.getMonth(),
    dt1.getDate(),
    0,
    0,
    0
  );
  var date2 = new Date(
    dt2.getFullYear(),
    dt2.getMonth(),
    dt2.getDate(),
    0,
    0,
    0
  );
  var Difference_In_Time = date2.getTime() - date1.getTime();
  return Difference_In_Time / (1000 * 3600 * 24) + 1;
};

export const getSelectedFilters = (state) => {
  const mediaIds = state.listingFilterMedia
    ? state.listingFilterMedia.map((r) => r.id)
    : [];
  const environmentIds = state.listingFilterEnvironment
    ? state.listingFilterEnvironment.map((r) => r.id)
    : [];
  return {
    cost: state.listingFilterCost ? state.listingFilterCost.id : null,
    media: mediaIds,
    environment: environmentIds,
    dates: state.listingFilterDates ? [state.listingFilterDates.id] : [],
    onlyFeatured: state.showOnlyFeatured,
    providerId: state.filterProviderId,
    // minCost:state.listingFilterMinCost,
    // maxCost:state.listingFilterMaxCost,
    minCost: state.listingFilterCost ? state.listingFilterCost.min : null,
    maxCost: state.listingFilterCost ? state.listingFilterCost.max : null,
    fromDate: state.listingFilterFromDate,
    uptoDate: state.listingFilterUptoDate,
    searchTerm: state.searchTerm,
    searchSection: state.searchSection,
    latitude: state.latitude,
    longitude: state.longitude,
    mapZoom: state.mapZoom,
    mapDistance: state.mapDistance,
  };
};

//async methods to pull data from api
export const getMediaFilterOptionsAsync = createAsyncThunk(
  "filters/getMediaFilterOptionsAsync",
  async () => {
    const response = await getOptions("MediaTypes");
    return response.data;
  }
);

export const getEnvironmentFilterOptionsAsync = createAsyncThunk(
  "filters/getEnvironmentFilterOptionsAsync",
  async () => {
    const response = await getEnvironments();
    return response.data;
  }
);

export const getListBySitesAsync = createAsyncThunk(
  "customer/getListBySitesAsync",
  async (request) => {
    request.listingType = 0;
    const response = await getListByType(request);
    return response.data;
  }
);

export const getListBySignsAsync = createAsyncThunk(
  "customer/getListBySignsAsync",
  async (request) => {
    request.listingType = 1;
    const response = await getListByType(request);
    return response.data;
  }
);

export const getListByProvidersAsync = createAsyncThunk(
  "customer/getListByProvidersAsync",
  async (request) => {
    const response = await getListBySites(request);
    return response.data;
  }
);

export const getProviderSitesAsync = createAsyncThunk(
  "listing/getProviderSitesAsync",
  async (request) => {
    request.listingType = 0;
    const response = await getListByType(request);
    return response.data;
  }
);

export const getListItemDetailsAsync = createAsyncThunk(
  "customer/getListItemDetailsAsync",
  async (listingItemId) => {
    const response = await getListItemDetails(listingItemId);
    return response.data;
  }
);

export const getSimilarListingAsync = createAsyncThunk(
  "customer/getSimilarListingAsync",
  async (listingItemId) => {
    const reqBody = {
      page: 0,
      pageSize: 10,
      orderBy: "",
      export: true,
      searchString: "",
      listingItemId: listingItemId,
    };
    const response = await getSimilarListing(reqBody);
    return response.data;
  }
);

export const getListingReviewsAsync = createAsyncThunk(
  "customer/getListingReviewsAsync",
  async (listingItemId) => {
    const response = await getListingReviews(listingItemId);
    return response.data;
  }
);

export const getAvailableOffersOnSiteAsync = createAsyncThunk(
  "listing/getAvailableOffersOnSiteAsync",
  async (listingItemId) => {
    const response = await getAvailableOffersOnSite(listingItemId);
    return response.data;
  }
);

export const getAvailableOffersForProviderAsync = createAsyncThunk(
  "listing/getAvailableOffersForProviderAsync",
  async (providerId) => {
    const response = await getAvailableOfferForProvider(providerId);
    return response.data;
  }
);

export const validateOfferAsync = createAsyncThunk(
  "listing/validateOfferAsync",
  async (request) => {
    const response = await validateOffer(request);
    return response.data;
  }
);

export const getDefaultAddressAsync = createAsyncThunk(
  "listing/getDefaultAddressAsync",
  async () => {
    const response = await getDefaultAddress();
    return response.data;
  }
);

export const getProfileAsync = createAsyncThunk(
  "listing/getProfileAsync",
  async () => {
    const response = await getCurrentUser();
    return response.data;
  }
);

export const getPriceRangeAsync = createAsyncThunk(
  "listing/getPriceRangeAsync",
  async () => {
    const response = await getPriceRange();
    return response.data;
  }
);

export const markAsFavoriteAsync = createAsyncThunk(
  "listing/markAsFavoriteAsync",
  async (reqBody) => {
    const response = await markAsFavorite(reqBody);
    return { request: reqBody, response: response.data };
  }
);

export const markAsUnFavoriteAsync = createAsyncThunk(
  "listing/markAsUnFavoriteAsync",
  async (reqBody) => {
    const response = await markAsUnFavorite(reqBody);
    return { request: reqBody, response: response.data };
  }
);

export const bookPortableSignAsync = createAsyncThunk(
  "listing/bookPortableSignAsync",
  async (portableSign) => {
    const response = await bookPortableSign(portableSign);
    return { response: response.data, portableSign };
  }
);

export const GetListingAvailabilityAsync = createAsyncThunk(
  "listing/GetListingAvailabilityAsync",
  async (reqBody) => {
    const response = await GetListingAvailability(reqBody);
    return response.data;
  }
);

export const submitOrderAsync = createAsyncThunk(
  "customer/submitOrderAsync",
  async (order, { rejectWithValue }) => {
    const response = await submitOrder(order).catch((err) => {
      if (err.response.data.error) {
        return rejectWithValue(err.response.data.error);
      } else if (err.response.data.errors) {
        let arr = [];
        for (var key in err.response.data.errors) {
          arr.push(err.response.data.errors[key][0]);
        }
        return rejectWithValue(arr.join(", "));
      } else {
        return rejectWithValue(err.response.data.title);
      }
      console.log("error=>", err.response.data.errors);
      return rejectWithValue("unable to process order");
    });
    return response;
  }
);

export const orderPaymentAsync = createAsyncThunk(
  "listing/orderPaymentAsync",
  async (reqBody, { rejectWithValue }) => {
    const response = await orderPayment(reqBody).catch((err) => {
      if (err.response.data.error) {
        return rejectWithValue(err.response.data.error);
      } else if (err.response.data.errors) {
        let arr = [];
        for (var key in err.response.data.errors) {
          arr.push(err.response.data.errors[key][0]);
        }
        return rejectWithValue(arr.join(", "));
      } else {
        return rejectWithValue(err.response.data.title);
      }
      console.log("error=>", err.response.data.errors);
      return rejectWithValue("unable to pay for the order");
    });
    return response;
  }
);

export const getCustomerTotalOrderDetailsAsync = createAsyncThunk(
  "listing/getCustomerTotalOrderDetailsAsync",
  async () => {
    const response = await getCustomerTotalOrderDetails();
    return response.data;
  }
);

export const getLocationNameAsync = createAsyncThunk(
  "listing/getLocationNameAsync",
  async (reqBody) => {
    const response = await getAddressByLatLng(reqBody.lat, reqBody.lng);
    return { data: response.data, locationType: reqBody.locationType };
  }
);

//helper functions
const initOrder = (state) => {
  let order = {
    providerId: state.listingItem.providerId,
    availedOfferId: state.availedOffer.offerId,
    availedOfferAmount: state.availedOffer.offerAmount,
    offerType: state.availedOffer.offerType,
    billingAddress: `${state.billingAddress.address1} ${state.billingAddress.address2}`,
    billingPincode: state.billingAddress.pincode,
    billingCity: state.billingAddress.city,
    billingState: state.billingAddress.state,
    billingCountry: state.billingAddress.country,
    orderDetails: [],
    paymentTerms: [],
    listingId: state.listingItem.listingId,
    stateTaxDetails: state.listingItem.stateTaxDetails,
  };
  return order;
};

const addUpdateSign = (state, sign) => {
  let cartItems = state.cart.filter((r) => r.siteId === sign.id);
  let cartItem = null;
  if (cartItems && cartItems.length > 0) {
    cartItem = cartItems[0];
  } else {
    cartItem = {
      sign: sign,
      quantity: sign.quantity,
      price: sign.price,
      serviceId: null,
      chargeId: null,
      siteId: null,
      signId: sign.id,
      priceId: null,
      summaryChargesItemId: null,
      amount: 0,
      subAmount: 0,
      taxAmount: 0,
      listingItemId: state.listingItem.listingItemId,
      subscriptionTermId: state.subscriptionTermId,
      fromDate: state.availability.fromDate,
      toDate: state.availability.toDate,
      googleAddress: null,
      latitude: null,
      longitude: null,
      signDirectionFacing: null,
      landmark: null,
      isLocationOwned: null,
      portableSignImage: null,
    };
    let price = calcPrice(state, sign.quantity);
    cartItem.quantity = sign.quantity;
    cartItem.amount = price;
    cartItem.subAmount = price;
    cartItem.taxAmount = calcTax(state, price);
    if (
      state.listingItem.isPortableSign &&
      (state.portableSignInfo || sign.portableDetails)
    ) {
      let portableDetails = sign.portableDetails
        ? sign.portableDetails
        : state.portableSignInfo;
      cartItem = applyPortableDetailsToCartItem(cartItem, portableDetails);
    }
    return cartItem;
  }
};

const applyPortableDetailsToCartItem = (cartItem, prtDtl) => {
  if (cartItem && prtDtl) {
    return {
      ...cartItem,
      googleAddress: prtDtl.address,
      latitude: prtDtl.latitude,
      longitude: prtDtl.longitude,
      signDirectionFacing: prtDtl.locationFacingTowards,
      landmark: prtDtl.landmark,
      isLocationOwned: prtDtl.isLocationOwned,
      portableSignImage: prtDtl.image,
    };
  } else {
    return cartItem;
  }
};

const addUpdateService = (state, service) => {
  var serviceCharge = calcServiceCharge(state, service);
  if (serviceCharge) {
    let cartItems = state.cart.filter((r) => r.serviceId === service.serviceId);
    if (cartItems && cartItems.length > 0) {
      let cartItem = cartItems[0];
      cartItem.amount = serviceCharge.subAmount;
      cartItem.subAmount = serviceCharge.subAmount;
      cartItem.taxAmount = serviceCharge.taxAmount;
      return cartItem;
    } else {
      let cartItem = {
        service: service,
        quantity: 1,
        price: service.price,
        serviceId: service.serviceId,
        chargeId: null,
        siteId: null,
        signId: null,
        priceId: service.priceId,
        summaryChargesItemId: serviceCharge.summaryChargesItemId,
        amount: serviceCharge.subAmount,
        taxAmount: serviceCharge.taxAmount,
        subAmount: serviceCharge.subAmount,
        listingItemId: state.listingItem.listingItemId,
        subscriptionTermId: state.subscriptionTermId,
        fromDate: state.availability.fromDate,
        toDate: state.availability.toDate,
        googleAddress: null,
        latitude: 0,
        longitude: 0,
      };
      return cartItem;
    }
  }
  return null;
};

const addUpdateCharge = (state, charge) => {
  var serviceCharge = calcCharge(state, charge);
  if (serviceCharge) {
    let cartItems = state.cart.filter((r) => r.chargeId === charge.chargeId);
    if (cartItems && cartItems.length > 0) {
      let cartItem = cartItems[0];
      cartItem.amount = serviceCharge.subAmount;
      cartItem.subAmount = serviceCharge.subAmount;
      cartItem.taxAmount = serviceCharge.taxAmount;
      return cartItem;
    } else {
      let cartItem = {
        service: state.cart.service,
        charge: charge,
        quantity: 1,
        price: state.cart.price,
        serviceId: state.cart.serviceId,
        chargeId: charge.chargeId,
        siteId: null,
        signId: null,
        priceId: state.cart.priceId,
        summaryChargesItemId: serviceCharge.summaryChargesItemId,
        amount: serviceCharge.subAmount,
        taxAmount: serviceCharge.taxAmount,
        subAmount: serviceCharge.subAmount,
        listingItemId: state.listingItem.listingItemId,
        subscriptionTermId: state.subscriptionTermId,
        fromDate: state.availability.fromDate,
        toDate: state.availability.toDate,
        googleAddress: null,
        latitude: 0,
        longitude: 0,
      };
      return cartItem;
    }
  }
  return null;
};

const addUpdateSite = (state, site) => {
  let cartItems = state.cart.filter((r) => r.siteId === site.id);
  let siteAmount = 0;
  if (state.listingItem.signs.length == 0) {
    siteAmount = calcPrice(state, 1);
  }
  if (cartItems && cartItems.length > 0) {
    let cartItem = cartItems[0];
    cartItem.amount = siteAmount;
    cartItem.subAmount = siteAmount;
    cartItem.taxAmount = calcTax(state, siteAmount);
    return cartItem;
  } else {
    let cartItem = {
      site: site,
      quantity: 1,
      price: site.price,
      serviceId: null,
      chargeId: null,
      siteId: site.id,
      signId: null,
      priceId: site.priceId,
      summaryChargesItemId: null,
      amount: siteAmount,
      subAmount: siteAmount,
      taxAmount: calcTax(state, siteAmount),
      listingItemId: state.listingItem.listingItemId,
      subscriptionTermId: state.subscriptionTermId,
      fromDate: state.availability.fromDate,
      toDate: state.availability.toDate,
      googleAddress: null,
      latitude: 0,
      longitude: 0,
    };
    return cartItem;
  }
};

const calcOfferAmount = (state, offer) => {
  let offerAmount = 0;
  let availedOffer = {};
  if (offer.status === "Active") {
    var bookingDetails = getBookingDetails(state);
    if (offer.conditions) {
      offer.conditions.forEach((condition) => {
        if (checkOfferCondition(condition, bookingDetails, offer) == false) {
          state.applyOfferResult = `Offer can not be applied, ${condition.conditionOnName}`;
          return offerAmount;
        }
      });
    }
    if (offer.audiences && offer.audiences.length > 0) {
      offer.audiences.forEach((audience) => {
        if (audience.audienceType === 0) {
          //UsersWhoseOrders
          if (
            audience.operator === 1 &&
            audience.value >= state.oldOrdersInfo.totalOrders
          ) {
            //IsGreaterThen
            state.applyOfferResult = `Offer can not be applied, Orders must be > ${audience.value}`;
            return offerAmount;
          } else if (
            audience.operator === 1 &&
            audience.value > state.oldOrdersInfo.totalOrders
          ) {
            //IsAre
            state.applyOfferResult = `Offer can not be applied, Orders must be equal to ${audience.value}`;
            return offerAmount;
          }
        } else if (audience.audienceType === 1) {
          //UsersWhoseSpendings
          if (
            audience.operator === 0 &&
            audience.value >= state.oldOrdersInfo.totalSpend
          ) {
            ////
            state.applyOfferResult = `Offer can not be applied, Total spending must be > ${audience.value}`;
            return offerAmount;
          } else if (
            audience.operator === 1 &&
            audience.value > state.oldOrdersInfo.totalSpend
          ) {
            //IsAre
            state.applyOfferResult = `Offer can not be applied, Orders must be = ${audience.value}`;
            return offerAmount;
          }
        }
      });
    }
    if (offer.offerType == 0) {
      //FixedDiscountOffer
      offerAmount = offer.offerValue;
    } else if (offer.offerType == 1 && offer.offerValue) {
      //PercentageDiscountOffer
      offerAmount = (offer.offerValue / 100) * bookingDetails.totalAmount;
    } else if (offer.offerType == 2) {
      //FreeServices
      let updatedServices = state.cart
        .filter((r) => r.serviceId)
        .map((r) => {
          return { ...r, taxAmount: 0, subAmount: 0 };
        });
      state.cart = [...state.cart.filter((r) => !r.serviceId), updatedServices];
      if (updatedServices && updatedServices.length > 0) {
        state.applyOfferResult = `Offer applied on selected services`;
      } else {
        state.applyOfferResult = `Offer can not be applied, No services are available in cart!`;
      }
      offerAmount = 0;
    }
  }
  return offerAmount;
};

const checkOfferCondition = (condition, bookingDetails, offer) => {
  switch (condition.conditionOn) {
    case 0: //OrderValue
      return bookingDetails.orderAmount > parseFloat(condition.value);
      break;
    case 1: //Media
      let mt = parseInt(condition.value);
      return (
        bookingDetails.signs &&
        bookingDetails.signs.filter((r) => r.mediaType == mt).length > 0
      );
      //return bookingDetails.signs && offer.mediaTypes && bookingDetails.signs.filter(r=> offer.mediaTypes.filter(s=>s.mediaTypeId==r.mediaType).length>0).length>0;
      break;
    case 2: //NumberOfSigns
      return bookingDetails.noOfSigns > parseInt(condition.value);
      break;
    case 3: //Site
      if (offer.sitesIncluded) {
        return (
          offer.sitesIncluded.filter((r) => r.siteId == bookingDetails.site.id)
            .length > 0
        );
      } else {
        return true;
      }
      break;
    default:
      return true;
  }
};

const updateCartAvailability = (state) => {
  const { fromDate, toDate } = state.availability;
  if (fromDate && toDate && state.cart && state.cart.length > 0) {
    const cartItems = state.cart.map((item) => {
      let priceAmount = calcPrice(state, item.quantity);
      if (item.siteId && state.listingItem.signs.length > 0) priceAmount = 0;
      return {
        ...item,
        fromDate: fromDate,
        toDate: toDate,
        amount: priceAmount,
        subAmount: priceAmount,
        taxAmount: calcTax(state, priceAmount),
      };
    });
    return cartItems;
  } else {
    return null;
  }
};

const mergeSignInfo = (sign, newSigns) => {
  if (sign && newSigns) {
    var filteredSigns = newSigns.filter((r) => r.id == sign.id);
    if (filteredSigns && filteredSigns.length > 0) {
      let newSign = filteredSigns[0];
      return { ...sign, available: newSign.available };
    }
  }
  return sign;
};

const listingSlice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    selectListingItem: (state, parameter) => {
      state.listingItemId = parameter.payload;
    },
    removeOffer: (state) => {
      return {
        ...state,
        availedOffer: {
          offerId: null,
          offerAmount: 0,
          offerType: null,
        },
        order: {
          ...state.order,
          availedOfferId: null,
          availedOfferAmount: 0,
          offerType: null,
        },
      };
    },
    selectService: (state, parameter) => {
      const { service } = parameter.payload;
      let filteredService = state.listingItem.services.filter(
        (r) => r.serviceId === service.serviceId
      );
      filteredService.forEach((r) => {
        r.isChecked = !r.isChecked;
        if (r.isChecked) {
          var newServiceItem = addUpdateService(state, service);
          if (newServiceItem) {
            //console.log('service',service);
            //state.listingItem  = {...state.listingItem,services:[...state.listingItem.services.filter(r=>r.serviceId!==service.serviceId),{...service,price:newServiceItem.amount,isChecked:r.isChecked}]};
            state.cart = [
              ...state.cart.filter((s) => s.serviceId !== service.serviceId),
              newServiceItem,
            ];
          }
        } else {
          state.cart = state.cart.filter(
            (s) => s.serviceId !== service.serviceId
          );
        }
      });
    },
    deselectService: (state, parameter) => {
      const { service } = parameter.payload;
      let filteredService = state.listingItem.services.filter(
        (r) => r.serviceId === service.serviceId
      );
      filteredService.forEach((r) => {
        r.isChecked = false;
          state.cart = state.cart.filter(
            (s) => s.serviceId !== service.serviceId
          );
      });
    },
    selectCharge: (state, parameter) => {
      const { charge } = parameter.payload;
      let filteredCharge = state.listingItem.summaryChargesItems.filter(
        (r) => r.chargeId === charge.chargeId
      );
      filteredCharge.forEach((r) => {
        r.isChecked = !r.isChecked;
        if (r.isChecked) {
          var newChargeItem = addUpdateCharge(state, charge);
          if (newChargeItem) {
            //console.log('service',service);
            //state.listingItem  = {...state.listingItem,services:[...state.listingItem.services.filter(r=>r.serviceId!==service.serviceId),{...service,price:newServiceItem.amount,isChecked:r.isChecked}]};
            state.cart = [
              ...state.cart.filter((c) => c.chargeId !== charge.chargeId),
              newChargeItem,
            ];
          }
        } else {
          state.cart = state.cart.filter((c) => c.chargeId !== charge.chargeId);
        }
      });
    },
    selectSign: (state, parameter) => {
      const { signId, quantity } = parameter.payload;
      let filteredSign = state.listingItem.signs.filter((r) => r.id === signId);
      filteredSign.forEach((r) => {
        r.quantity = parseInt(quantity);
        let signPrice = 0;
        if (r.quantity > 0) {
          const signCartItem = addUpdateSign(state, r);
          if (signCartItem) {
            signPrice = signCartItem.amount;
          }
          state.cart = [
            ...state.cart.filter((s) => s.signId !== signId),
            signCartItem,
          ];
        } else {
          state.cart = state.cart.filter((s) => s.signId !== signId);
        }
        state.listingItem = {
          ...state.listingItem,
          signs: [
            ...state.listingItem.signs.filter((r) => r.id != signId),
            { ...r, price: signPrice },
          ],
        };
      });
      // state.listingItem.services.forEach((service) => {
      //   const serviceCharge = calcServiceCharge(state, service);
      //   if (serviceCharge) {
      //     service.price = serviceCharge.price;
      //   } else {
      //     service.price = 0;
      //   }
      // });
    },
    bookSite: (state, parameter) => {
      const { siteId } = parameter.payload;
      let filteredSites = state.listingItem.sites.filter(
        (r) => r.siteId === siteId
      );
      if (filteredSites && filteredSites.length > 0) {
        let site = filteredSites[0];
        state.cart = [
          ...state.cart.filter((s) => s.siteId !== site.id),
          addUpdateSite(state, site),
        ];
      }
      state.listingItem.services.forEach((service) => {
        const serviceCharge = calcServiceCharge(state, service);
        if (serviceCharge) {
          service.price = serviceCharge.price;
        } else {
          service.price = 0;
        }
      });
    },
    bookSign: (state, parameter) => {
      const { signId } = parameter.payload;
      let filteredSigns = state.listingItem.signs.filter(
        (r) => r.id === signId
      );
      if (filteredSigns && filteredSigns.length > 0) {
        let sign = filteredSigns[0];
        state.cart = [
          ...state.cart.filter((s) => s.signId !== sign.id),
          addUpdateSign(state, sign),
        ];
      }
      state.listingItem.services.forEach((service) => {
        const serviceCharge = calcServiceCharge(state, service);
        if (serviceCharge) {
          service.price = serviceCharge.price;
        } else {
          service.price = 0;
        }
      });
    },
    prepareOrder: (state) => {
      // prepare order object
      let order = initOrder(state);
      //add cart items
      let subscriptionTermId = null;
      if (state.subscriptionTermId)
        subscriptionTermId = state.subscriptionTermId;
      else if (state.listingItem && state.listingItem.subscriptionTerms) {
        subscriptionTermId = state.listingItem.subscriptionTerms.id;
        state.subscriptionTermId = subscriptionTermId;
      }
      var bookingDetails = getBookingDetails(state);
      state.cart.forEach((item) => {
        let priceId = null;
        let priceAmount = 0;
        var priceApplicable = getApplicablePrice(state, item.quantity);
        if (priceApplicable) {
          priceId = priceApplicable.id;
          priceAmount = getItemPrice(
            state,
            priceApplicable.amount,
            item.quantity
          );
        } else {
          if (state.listingItem && state.listingItem.priceTiers.length > 0)
            priceId = state.listingItem.priceTiers[0].id;
        }
        if (item.siteId && bookingDetails.noOfSigns > 0) priceAmount = 0;

        let orderDetail = {
          serviceId: item.serviceId,
          chargeId: item.chargeId,
          siteId: item.siteId,
          signId: item.signId,
          priceId: item.priceId ? item.priceId : priceId,
          summaryChargesItemId: item.summaryChargesItemId,
          amount: 0,
          subAmount: item.subAmount, //priceAmount,
          taxAmount: item.taxAmount, //calcTax(priceAmount),
          listingItemId: item.listingItemId,
          subscriptionTermId: subscriptionTermId, //item.subscriptionTermId,
          fromDate: item.fromDate,
          toDate: item.toDate,
          quantity: item.quantity,
          signDeliveryAddress: {
            googleAddress: item.googleAddress,
            latitude: item.latitude,
            longitude: item.longitude,
          },
        };

        if (state.listingItem.isPortableSign) {
          orderDetail.signDeliveryAddress.signDirectionFacing =
            item.signDirectionFacing;
          orderDetail.signDeliveryAddress.landmark = item.landmark;
          orderDetail.signDeliveryAddress.isLocationOwned =
            item.isLocationOwned;
          orderDetail.signDeliveryAddress.portableSignImage =
            item.portableSignImage;
          orderDetail.portableSignId = state.portableSignId;
        }
        if (orderDetail.signDeliveryAddress.latitude == null) {
          orderDetail.signDeliveryAddress.latitude = 0;
        }
        if (orderDetail.signDeliveryAddress.longitude == null) {
          orderDetail.signDeliveryAddress.longitude = 0;
        }

        if (orderDetail.serviceId || orderDetail.chargeId) {
          //getApplicableCharge(state,orderDetail);
          orderDetail.subAmount = item.subAmount;
          orderDetail.taxAmount = item.taxAmount;
          orderDetail.amount = item.price;
        }
        order.orderDetails.push(orderDetail);
      });

      if (state.availedOffer && state.availedOffer.offerId) {
        order.availedOfferId = state.availedOffer.offerId;
        order.availedOfferAmount = state.availedOffer.offerAmount;
      }

      //add payment terms
      order.paymentTerms.push({
        isDownPayment: true,
        fromDate: state.availability.fromDate,
        toDate: state.availability.toDate,
        amount: bookingDetails.netAmount,
      });
      state.order = order;
    },
    setAvailabilityFrom: (state, parameter) => {
      state.availability.fromDate = parameter.payload;
    },
    setAvailabilityTo: (state, parameter) => {
      state.availability.toDate = parameter.payload;
      const cartItems = updateCartAvailability(state);
      if (cartItems) {
        state.cart = cartItems;
      }
    },
    setCostFilter: (state, parameter) => {
      state.listingFilterCost = parameter.payload;
    },
    setMediaTypeFilter: (state, parameter) => {
      state.listingFilterMedia = parameter.payload;
    },
    setEnvironmentFilter: (state, parameter) => {
      state.listingFilterEnvironment = parameter.payload;
    },
    setDateFilter: (state, parameter) => {
      state.listingFilterDates = parameter.payload;
    },
    setShowOnlyFeatured: (state, parameter) => {
      state.showOnlyFeatured = parameter.payload;
    },
    setFilterProviderId: (state, parameter) => {
      state.filterProviderId = parameter.payload;
    },
    setFromDateFilter: (state, parameter) => {
      state.listingFilterFromDate = parameter.payload;
    },
    setUptoDateFilter: (state, parameter) => {
      state.listingFilterUptoDate = parameter.payload;
    },
    setSearchTerm: (state, parameter) => {
      state.searchTerm = parameter.payload;
    },
    setSearchSection: (state, parameter) => {
      state.searchSection = parameter.payload;
    },
    updateBillingAddress: (state, parameter) => {
      const address = { ...parameter.payload.address };
      state.billingAddress = address;
    },
    setLongitude: (state, parameter) => {
      state.longitude = parameter.payload;
    },
    setLatitude: (state, parameter) => {
      state.latitude = parameter.payload;
    },
    setLocationName: (state, parameter) => {
      state.locationName = parameter.payload;
    },
    setMapZoom: (state, parameter) => {
      state.mapZoom = parameter.payload;
    },
    setMapDistance: (state, parameter) => {
      state.mapDistance = parameter.payload;
    },
    setPaymentCardId: (state, parameter) => {
      state.paymentCardId = parameter.payload;
    },
    setOrderAmount: (state, parameter) => {
      state.orderAmount = parameter.payload;
    },
    setListingItem: (state, parameter) => {
      state.listingItem = parameter.payload;
    },
    applyOffer: (state, parameter) => {
      let offer = parameter.payload;
      let offerAmount = calcOfferAmount(state, offer);
      if (offerAmount > 0) {
        state.availedOffer = {
          offerId: offer.id,
          offerAmount: offerAmount,
          offerType: offer.offerType,
        };
        state.availableOffers = [
          ...state.availableOffers.filter((r) => r.id != offer.id),
          { ...offer, applied: true },
        ];
      }
    },
    removeInvoiceOfferStatus: (state, invalidOffer) => {
      if (invalidOffer.payload) {
        let filteredOffers = state.availableOffers.filter(
          (r) => r.id == invalidOffer.payload.id
        );
        if (filteredOffers && filteredOffers.length > 0) {
          let offer = filteredOffers[0];

          return {
            ...state,
            availableOffers: [
              ...state.availableOffers.filter(
                (r) => r.id != invalidOffer.payload.id
              ),
              { ...offer, invalid: false },
            ],
          };
        }
      }
    },
    setPortableSignLocation: (state, parameter) => {
      state.portableSignLocation = parameter.payload;
    },
    setPortableSignInfo: (state, parameter) => {
      state.portableSignsListing = true;
      state.portableSignInfo = parameter.payload;
    },
    setSelectedListingType: (state, parameter) => {
      state.selectedListingType = parameter.payload;
    },
    applyPortableSignDetails: (state, parameter) => {
      const { portableDetails, sign } = parameter.payload;
      if (state.listingItem.signs) {
        if (sign) {
          state.listingItem = {
            ...state.listingItem,
            signs: [
              ...state.listingItem.signs.filter((r) => r.id != sign.id),
              { ...sign, portableDetails },
            ],
          };

          let filteredCart = state.cart.filter((r) => r.signId === sign.id);
          if (filteredCart && filteredCart.length > 0) {
            let cartItem = filteredCart[0];
            state.cart = [
              ...state.cart.filter((s) => s.signId !== sign.id),
              applyPortableDetailsToCartItem(cartItem, portableDetails),
            ];
          }
        } else {
          state.listingItem = {
            ...state.listingItem,
            signs: [
              ...state.listingItem.signs.map((sg) => ({
                ...sg,
                portableDetails,
              })),
            ],
          };

          if (state.cart.length > 0) {
            state.cart = state.cart.map((cartItem) =>
              applyPortableDetailsToCartItem(cartItem, portableDetails)
            );
          }
        }
      }
    },
    emptyListingState: (state) => {
      //state = {...initialState};
      return initialState;
    },
    emptyMarkFavouriteResult: (state) => {
      state.markFavouriteResult = null;
    },
    setPortableSignRadiusFilter: (state, parameter) => {
      state.portableSignRadiusFilter = parameter.payload;
    },
  },
  extraReducers: {
    [getListBySitesAsync.pending]: () => {},
    [getListBySitesAsync.fulfilled]: (state, { payload }) => {
      if (state.listBySites.page !== payload.page) {
        return {
          ...state,
          listBySites: {
            totalRecords: payload.totalRecords,
            page: payload.page,
            pageSize: payload.pageSize,
            records: [...state.listBySites.records, ...payload.records],
          },
        };
      } else {
        return {
          ...state,
          listBySites: {
            totalRecords: payload.totalRecords,
            page: payload.page,
            pageSize: payload.pageSize,
            records: payload.records,
          },
        };
      }
    },
    [getListBySitesAsync.rejected]: () => {},

    [getListBySignsAsync.pending]: () => {},
    [getListBySignsAsync.fulfilled]: (state, { payload }) => {
      if (!payload.ids && state.listBySigns.page !== payload.page) {
        return {
          ...state,
          listBySigns: {
            totalRecords: payload.totalRecords,
            page: payload.page,
            pageSize: payload.pageSize,
            records: [...state.listBySigns.records, ...payload.records],
          },
        };
      } else {
        return {
          ...state,
          listBySigns: {
            totalRecords: payload.totalRecords,
            page: payload.page,
            pageSize: payload.pageSize,
            records: payload.records,
          },
        };
      }
    },
    [getListBySignsAsync.rejected]: () => {},

    [getListByProvidersAsync.pending]: () => {},
    [getListByProvidersAsync.fulfilled]: (state, { payload }) => {
      if (state.listByProviders.page !== payload.page) {
        return {
          ...state,
          listByProviders: {
            totalRecords: payload.totalRecords,
            page: payload.page,
            pageSize: payload.pageSize,
            records: [...state.listByProviders.records, ...payload.records],
          },
        };
      } else {
        return {
          ...state,
          listByProviders: {
            totalRecords: payload.totalRecords,
            page: payload.page,
            pageSize: payload.pageSize,
            records: payload.records,
          },
        };
      }
    },
    [getListByProvidersAsync.rejected]: () => {},

    [getListItemDetailsAsync.pending]: () => {},
    [getListItemDetailsAsync.fulfilled]: (state, { payload }) => {
      return { ...state, listingItem: payload };
    },
    [getListItemDetailsAsync.rejected]: () => {},

    [getSimilarListingAsync.pending]: () => {},
    [getSimilarListingAsync.fulfilled]: (state, { payload }) => {
      return { ...state, similarListing: payload };
    },
    [getSimilarListingAsync.rejected]: () => {},

    [getListingReviewsAsync.pending]: () => {},
    [getListingReviewsAsync.fulfilled]: (state, { payload }) => {
      return { ...state, listingReviews: payload };
    },
    [getListingReviewsAsync.rejected]: () => {},

    [getAvailableOffersOnSiteAsync.pending]: () => {},
    [getAvailableOffersOnSiteAsync.fulfilled]: (state, { payload }) => {
      return { ...state, availableOffers: payload };
    },
    [getAvailableOffersOnSiteAsync.rejected]: () => {},

    [getAvailableOffersForProviderAsync.pending]: () => {},
    [getAvailableOffersForProviderAsync.fulfilled]: (state, { payload }) => {
      return { ...state, availableOffers: payload };
    },
    [getAvailableOffersForProviderAsync.rejected]: () => {},

    [validateOfferAsync.pending]: () => {},
    [validateOfferAsync.fulfilled]: (state, { payload }) => {
      const {
        freeService,
        isValid,
        offerAmount,
        offerId,
        offerType,
        offerTypetext,
      } = payload;
      let filteredOffers = state.availableOffers.filter((r) => r.id == offerId);
      if (filteredOffers && filteredOffers.length > 0) {
        let offer = filteredOffers[0];
        if (isValid && offerAmount > 0) {
          console.log("returning updated availed offer");
          return {
            ...state,
            availedOffer: {
              offerId: offerId,
              offerAmount: offerAmount,
              offerType: offerType,
            },
            order: {
              ...state.order,
              availedOfferId: offerId,
              availedOfferAmount: offerAmount,
              offerType: offerType,
            },
            availableOffers: [
              ...state.availableOffers,
            ],
          };
          // for testing invaild
          // return {
          //   ...state,
          //   availableOffers: [
          //     ...state.availableOffers.filter((r) => r.id != offerId),
          //     { ...offer, invalid: true },
          //   ],
          // };
        } else {
          return {
            ...state,
            availableOffers: [
              ...state.availableOffers.filter((r) => r.id != offerId),
              { ...offer, invalid: true },
            ],
          };
        }
      }
      return state;
    },
    [validateOfferAsync.rejected]: () => {},

    [getDefaultAddressAsync.pending]: () => {},
    [getDefaultAddressAsync.fulfilled]: (state, { payload }) => {
      return { ...state, billingAddress: payload };
    },
    [getDefaultAddressAsync.rejected]: () => {},

    [getProfileAsync.pending]: () => {},
    [getProfileAsync.fulfilled]: (state, { payload }) => {
      return { ...state, profile: payload };
    },
    [getProfileAsync.rejected]: () => {},

    [getMediaFilterOptionsAsync.pending]: () => {},
    [getMediaFilterOptionsAsync.fulfilled]: (state, { payload }) => {
      var items = payload;
      return { ...state, mediaTypeOptions: items };
    },
    [getMediaFilterOptionsAsync.rejected]: () => {},

    [getEnvironmentFilterOptionsAsync.pending]: () => {},
    [getEnvironmentFilterOptionsAsync.fulfilled]: (state, { payload }) => {
      return { ...state, environmentOptions: payload };
    },
    [getEnvironmentFilterOptionsAsync.rejected]: () => {
      console.log("get environment filter rejected");
    },

    [getProviderSitesAsync.pending]: () => {},
    [getProviderSitesAsync.fulfilled]: (state, { payload }) => {
      if (state.providerSites.page !== payload.page) {
        return {
          ...state,
          providerSites: {
            totalRecords: payload.totalRecords,
            page: payload.page,
            pageSize: payload.pageSize,
            records: [...state.providerSites.records, ...payload.records],
          },
        };
      } else {
        return {
          ...state,
          providerSites: {
            totalRecords: payload.totalRecords,
            page: payload.page,
            pageSize: payload.pageSize,
            records: payload.records,
          },
        };
      }
      return state;
    },
    [getProviderSitesAsync.rejected]: () => {
      console.log("get provider sites rejected");
    },

    [getPriceRangeAsync.pending]: () => {},
    [getPriceRangeAsync.fulfilled]: (state, { payload }) => {
      let items = payload;
      items.unshift({ id: null, name: "select" });
      state.costOptions = items;
      return state;
    },
    [getPriceRangeAsync.rejected]: () => {
      console.log("get price range rejected");
    },

    [markAsFavoriteAsync.pending]: () => {},
    [markAsFavoriteAsync.fulfilled]: (state, { payload }) => {
      console.log("marked as favorite", payload);
      if (payload.request) {
        const { listingItemId, type, providerId, title } = payload.request;
        const result = {
          title: title,
          action: "favourite",
          type: type,
          on: new Date().toString(),
          status: 1,
        };
        if (type === "listingitem") {
          return {
            ...state,
            markFavouriteResult: result,
            listBySites: {
              ...state.listBySites,
              records: state.listBySites.records.map((r) =>
                r.itemId === listingItemId ? { ...r, isFavorite: true } : r
              ),
            },
          };
        } else if (type === "site") {
          return {
            ...state,
            markFavouriteResult: result,
            listBySites: {
              ...state.listBySites,
              records: state.listBySites.records.map((r) =>
                r.itemId === listingItemId ? { ...r, isFavorite: true } : r
              ),
            },
          };
        } else if (type === "sign") {
          return {
            ...state,
            markFavouriteResult: result,
            listBySigns: {
              ...state.listBySigns,
              records: state.listBySigns.records.map((r) =>
                r.itemId === listingItemId ? { ...r, isFavorite: true } : r
              ),
            },
          };
        } else if (type === "provider") {
          return {
            ...state,
            markFavouriteResult: result,
            listByProviders: {
              ...state.listByProviders,
              records: state.listByProviders.records.map((r) =>
                r.id === providerId ? { ...r, isFavorite: true } : r
              ),
            },
          };
        } else if (type === "detailpage") {
          return {
            ...state,
            markFavouriteResult: result,
            listingItem: { ...state.listingItem, isFavorite: true },
          };
        } else if (type === "providersite") {
          return {
            ...state,
            markFavouriteResult: result,
            providerSites: {
              ...state.providerSites,
              records: state.providerSites.records.map((r) =>
                r.itemId === listingItemId ? { ...r, isFavorite: true } : r
              ),
            },
          };
        }
      }
      return state;
    },
    [markAsFavoriteAsync.rejected]: (state, { payload }) => {
      console.log("mark as favorite rejected");
      const result = {
        title: "",
        action: "favourite",
        type: "",
        on: new Date().toString(),
        status: 0,
      };
      return { ...state, markFavouriteResult: result };
    },

    [markAsUnFavoriteAsync.pending]: () => {},
    [markAsUnFavoriteAsync.fulfilled]: (state, { payload }) => {
      if (payload.request) {
        const { listingItemId, type, providerId, title } = payload.request;
        const result = {
          title: title,
          action: "unfavourite",
          type: type,
          on: new Date().toString(),
          status: 1,
        };
        if (type === "listingitem") {
          return {
            ...state,
            markFavouriteResult: result,
            listBySites: {
              ...state.listBySites,
              records: state.listBySites.records.map((r) =>
                r.itemId === listingItemId ? { ...r, isFavorite: false } : r
              ),
            },
          };
        } else if (type === "site") {
          return {
            ...state,
            markFavouriteResult: result,
            listBySites: {
              ...state.listBySites,
              records: state.listBySites.records.map((r) =>
                r.itemId === listingItemId ? { ...r, isFavorite: false } : r
              ),
            },
          };
        } else if (type === "sign") {
          return {
            ...state,
            markFavouriteResult: result,
            listBySigns: {
              ...state.listBySigns,
              records: state.listBySigns.records.map((r) =>
                r.itemId === listingItemId ? { ...r, isFavorite: false } : r
              ),
            },
          };
        } else if (type === "provider") {
          return {
            ...state,
            markFavouriteResult: result,
            listByProviders: {
              ...state.listByProviders,
              records: state.listByProviders.records.map((r) =>
                r.id === providerId ? { ...r, isFavorite: false } : r
              ),
            },
          };
        } else if (type === "detailpage") {
          return {
            ...state,
            markFavouriteResult: result,
            listingItem: { ...state.listingItem, isFavorite: false },
          };
        } else if (type === "providersite") {
          return {
            ...state,
            markFavouriteResult: result,
            providerSites: {
              ...state.providerSites,
              records: state.providerSites.records.map((r) =>
                r.itemId === listingItemId ? { ...r, isFavorite: false } : r
              ),
            },
          };
        }
      }
      return state;
    },
    [markAsUnFavoriteAsync.rejected]: (state, { payload }) => {
      console.log("mark as unfavorite rejected");
      const result = {
        title: "",
        action: "unfavourite",
        type: "",
        on: new Date().toString(),
        status: 0,
      };
      return { ...state, markFavouriteResult: result };
    },

    [bookPortableSignAsync.pending]: () => {},
    [bookPortableSignAsync.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        portableSignsListing: true,
        portableSign: payload.portableSign,
        portableSignId: payload.response,
      };
    },
    [bookPortableSignAsync.rejected]: () => {
      console.log("book portable sign rejected");
    },

    [GetListingAvailabilityAsync.pending]: () => {},
    [GetListingAvailabilityAsync.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        listingItem: {
          ...state.listingItem,
          signs: state.listingItem.signs.map((sign) =>
            mergeSignInfo(sign, payload.signs)
          ),
          siteAvailable: payload.siteAvailable,
        },
      };
    },
    [GetListingAvailabilityAsync.rejected]: () => {
      console.log("get listing sings by availability rejected");
    },

    [submitOrderAsync.pending]: () => {},
    [submitOrderAsync.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      if (payload.status == 200) {
        let result = {
          message: "Order is ready for payment!",
          receivedOn: new Date().toLocaleDateString(),
          data: payload.data,
        };
        const { orderAmount, orderId, paymentTermId } = payload.data;
        return {
          ...state,
          order: { orderDetails: [] },
          orderId: orderId,
          orderSubmitResult: result,
          paymentTermId: paymentTermId,
          orderAmount: orderAmount,
        };
      } else {
        return {
          ...state,
          order: { orderDetails: [] },
          orderSubmitResult: payload,
        };
      }
    },
    [submitOrderAsync.rejected]: (state, { payload }) => {
      console.log("submit order rejected", payload);
      let result = [payload, new Date().toLocaleDateString()];
      return { ...state, orderSubmitError: result };
    },

    [orderPaymentAsync.pending]: () => {},
    [orderPaymentAsync.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      if (payload.status == 200) {
        let result = [
          "Payment done successfully!",
          new Date().toLocaleDateString(),
        ];
        return {
          ...state,
          order: { orderDetails: [] },
          cart: [],
          orderSubmitResult: null,
          paymentResult: payload.data,
        };
      } else {
        return {
          ...state,
          order: { orderDetails: [] },
          cart: [],
          paymentResult: payload,
        };
      }
    },
    [orderPaymentAsync.rejected]: (state, { payload }) => {
      console.log("order payment rejected", payload);
      let result = [payload, new Date().toLocaleDateString()];
      return { ...state, orderPaymentError: result };
    },

    [getCustomerTotalOrderDetailsAsync.pending]: () => {},
    [getCustomerTotalOrderDetailsAsync.fulfilled]: (state, { payload }) => {
      const { totalOrders, totalSpend } = payload;
      return { ...state, oldOrdersInfo: { totalOrders, totalSpend } };
    },
    [getCustomerTotalOrderDetailsAsync.rejected]: () => {
      console.log("get customer total order details rejected");
    },

    [getLocationNameAsync.pending]: () => {},
    [getLocationNameAsync.fulfilled]: (state, { payload }) => {
      const { data, locationType } = payload;
      const { compound_code } = data.plus_code;
      const gAddress = GetOnlyAddressFromCompoundCode(compound_code);
      if (locationType === "sign") {
        if (state.portableSignInfo) {
          return {
            ...state,
            signLocationName: gAddress,
            portableSignInfo: { ...state.portableSignInfo, address: gAddress },
          };
        } else {
          return { ...state, signLocationName: gAddress };
        }
      } else {
        return { ...state, locationName: gAddress };
      }
    },
    [getLocationNameAsync.rejected]: () => {
      console.log("getLocationNameAsync rejected");
    },
  },
});
export const {
  selectListingItem,
  removeOffer,
  selectService,
  deselectService,
  selectCharge,
  selectSign,
  bookSite,
  bookSign,
  setAvailabilityFrom,
  setAvailabilityTo,
  prepareOrder,
  setCostFilter,
  setMediaTypeFilter,
  setEnvironmentFilter,
  setDateFilter,
  setFilterProviderId,
  setShowOnlyFeatured,
  setFromDateFilter,
  setUptoDateFilter,
  setSearchTerm,
  setSearchSection,
  updateBillingAddress,
  setLatitude,
  setLongitude,
  setMapZoom,
  setMapDistance,
  setPaymentCardId,
  setOrderAmount,
  setListingItem,
  applyOffer,
  removeInvoiceOfferStatus,
  setLocationName,
  setPortableSignLocation,
  setPortableSignInfo,
  setSelectedListingType,
  emptyListingState,
  applyPortableSignDetails,
  emptyMarkFavouriteResult,
  setPortableSignRadiusFilter,
} = listingSlice.actions;
export default listingSlice.reducer;

import { Route } from "react-router-dom";
import { Switch } from "react-router";
import { HomePage } from "../views/app/Customers/pages/HomePage";
import { Favorites } from "../views/app/Customers/pages/FavoritesPage/Favorites";
import { Reports } from "../views/app/Customers/pages/ReportPage/Reports";
import { Profile } from "../components/profile/Profile";
import { useState, useEffect } from "react";
import { currentUser, getAddressList } from "../services/Provider";
import { EditProfile } from "../components/profile/EditProfile";

import SiteDetails from "../views/app/Customers/pages/DetailsPage/SiteDetails";

import React from "react";
import SitesPayment from "../views/app/Customers/pages/SitesPayment";
import { AddSupportRequest } from "../views/app/Provider/SupportRequest/Add";
import ChangePassword from "../views/app/Admin/Settings/Pages/ChangePassword";
import { OrdersIndexPage } from "../views/app/Admin/Orders/OrdersIndexPage";
import SignDetails from "../views/app/Customers/pages/DetailsPage/SignDetails";
import { ExtendOrderPayment } from "../views/app/Customers/pages/ExtendOrderPayment";
 

const CustomerRoutes = ({ ...rest }) => {
  const [customer, setCustomer] = useState();
  const [addessList, setAddessList] = useState([]);
  useEffect(() => {
    currentUser()
      .then((res) => {
        if (res.status === 200) {
          setCustomer(res.data);
          let id = res.data.id;
          getAddressList(id)
            .then((res) => {
              if (res.status === 200) {
                setAddessList(res.data);
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [window.location.pathname]);

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${rest.match.url}/`}
          render={(props) => <HomePage {...props} />}
        />

        <Route
          path={`${rest.match.url}/extend-order-payment/:orderId`}
          render={(props) => <ExtendOrderPayment {...props} />}
        />

        <Route
          path={`${rest.match.url}/reports`}
          render={(props) => <Reports {...props} />}
        />

        <Route
          path={`${rest.match.url}/order`}
          render={(props) => <Reports {...props} />}
        />

        <Route
          path={`${rest.match.url}/favorites`}
          render={(props) => <Favorites {...props} />}
        />
        <Route
          path={`${rest.match.url}/profile`}
          render={(props) => (
            <Profile {...props} user={customer} addessList={addessList} userType={"Customer"}/>
          )}
        />
          <Route
            path={`/customer/orders`}
            component={OrdersIndexPage}
          />
            
        <Route
          path={`${rest.match.url}/editProfile`}
          render={(props) => (
            <EditProfile {...props} user={customer} addessList={addessList} />
          )}
        />
         <Route
          path={`${rest.match.url}/change-password`}
          render={(props) => (
            <ChangePassword {...props} />
          )}
        />
        <Route path="/customer/add-support-request" component={AddSupportRequest} />

        <Route
          path={`${rest.match.url}/site-detail`}
          render={(props) => (
            <SiteDetails
              {...props}
              // userProfile={userProfile}
            />
          )}
        />

        <Route
          path={`${rest.match.url}/sign-detail`}
          render={(props) => (
            <SignDetails
              {...props}
              // userProfile={userProfile}
            />
          )}
        />

        <Route
          path="/sites-payment"
          render={(props) => <SitesPayment {...props} />}
        />

        <Route
          exact={true}
          path='*'
          render={(props) => <HomePage {...props} />}
        />

      </Switch>
    </>
  );
};

export default CustomerRoutes;

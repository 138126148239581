import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { NotificationSVG } from "../../../../../../assets/svgComponents/table";
import NavBar from "../../../../../../components/navBar";
import Review from "./components/Review";
import "./SiteDetails.scss";
import SimpleImageSlider from "react-simple-image-slider";
import {
  HeartSVG,
  FullStarSVG,
  Eye,
  VectorSVG,
  ProfileSVG,
  GlobSVG,
  VideoPlayerSVG,
  SVGFavorite,
  SVGNotFavorite,
} from "../../../../../../assets/svgComponents/svg";
import SimilarListing from "./components/SimilarListing";
import r1 from "../../../../../../assets/images/r1.png";
import Availablity from "./components/Availablity";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import PriceTiers from "./components/PriceTiers";
import AvailableOffers from "./components/AvailableOffers";
import {
  emptyMarkFavouriteResult,
  markAsFavoriteAsync,
  markAsUnFavoriteAsync,
  bookSite,
  setAvailabilityFrom,
  setAvailabilityTo,
  getSelectedFilters,
  setSearchTerm,
  getListBySitesAsync,
  GetListingAvailabilityAsync,
  getListingItemImage,
  getMinBookingPeriodInDays,
  getCanBook,
  getListingItemImages,
} from "../../../../../../services/reducers/listing-reducer";
import { FloatingLabel } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { SVGCalendar } from "../../../../../../assets/svgComponents/svg";
import { useEffect } from "react";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { AuthService } from "../../../../../../services/AuthService";
import { capitalize } from "../../../../../../services/utility";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";

const SiteDetails = () => {
  const { addToast } = useToasts();
  const [bookedDates, setBookedDates] = useState([]);
  const userType = localStorage.getItem("userType");
  const {
    listingItem,
    listingReviews,
    similarListing,
    availableOffers,
    availability,
    listingPriceType,
  } = useSelector((state) => state.listing);
  const { canBook, bookingDays, minimumBookingDays } = useSelector((state) =>
    getCanBook(state.listing)
  );
  const { imageUrl } = useSelector((state) =>
    getListingItemImages(state.listing)
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const handleBook = () => {
    if (userType === "Temp") {
      setComfirmLoginModal(true);
    } else {
      dispatch(bookSite(listingItem.id));
      history.push(`/sites-booking/${listingItem.id}`);
    }
  };
  const [comfirmLoginModal, setComfirmLoginModal] = useState(false);
  const confirmModalLogin = () => {
    const authService = new AuthService();
    authService.logout();
    localStorage.removeItem("accessToken");
    authService.login();
  };

  const {
    media,
    environment,
    onlyFeatured,
    minCost,
    maxCost,
    fromDate,
    uptoDate,
    searchTerm,
  } = useSelector((state) => getSelectedFilters(state.listing));
  const minBookingPeriod = useSelector((state) =>
    getMinBookingPeriodInDays(state.listing)
  );

  const { markFavouriteResult } = useSelector((state) => state.listing);

  useEffect(async () => {
    if (markFavouriteResult) {
      if (markFavouriteResult.status === 1) {
        let msg = `Site ${markFavouriteResult.title} marked as ${markFavouriteResult.action} !`;
        addToast(msg, { appearance: "success" });
      } else {
        let msg = `Unable to marked as ${markFavouriteResult.action} !`;
        addToast(msg, { appearance: "error" });
      }
      await dispatch(emptyMarkFavouriteResult());
    }
  }, [markFavouriteResult]);

  const handleSearchTextChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
  };

  const handleSearchClick = (e) => {
    const reqBody = {
      page: 0,
      pageSize: 10,
      orderBy: "",
      export: true,
      searchString: searchTerm,
      userLocation: "",
      costs: [],
      mediaTypes: media,
      environmentIds: environment,
      dates: [],
      onlyFeatured: onlyFeatured,
      providerId: "",
      listingType: 0,
    };
    if (minCost != null && maxCost) {
      reqBody.costRange = { min: minCost, max: maxCost };
    }
    if (fromDate && uptoDate) {
      reqBody.dateRange = { fromDate, uptoDate };
    }
    dispatch(getListBySitesAsync(reqBody));
    history.push("/customer");
  };

  useEffect(async () => {
    if (availability && availability.fromDate && availability.toDate) {
      const reqBody = {
        listingId: listingItem.listingId,
        fromDate: availability.fromDate,
        toDate: availability.toDate,
      };
      dispatch(GetListingAvailabilityAsync(reqBody));
    }
  }, [dispatch, availability]);

  useEffect(() => {
    if (
      listingItem &&
      listingItem.bookedDates &&
      listingItem.bookedDates.length > 0
    ) {
      let dtArr = [];
      listingItem.bookedDates.forEach((element) => {
        dtArr.push(new Date(element));
      });
      setBookedDates(dtArr);
    } else {
      setBookedDates([]);
    }
  }, [listingItem]);

  useEffect(() => {
    if (bookingDays > 0 && bookingDays < minimumBookingDays) {
      addToast(`Booking period should be more than or equal to ${minimumBookingDays} days`, {
        appearance: "error",
      });
    }
  }, [bookingDays]);

  const markAsFavorite = () => {
    const reqBody = {
      siteId: null,
      signId: null,
      listingItemId: listingItem.listingItemId,
      providerId: null,
      type: "detailpage",
      title: listingItem.name,
    };
    if (listingItem.isFavorite) {
      dispatch(markAsUnFavoriteAsync(reqBody));
    } else {
      dispatch(markAsFavoriteAsync(reqBody));
    }
  };

  return (
    <>
      {listingItem && (
        <div>
          <NavBar
            navHeading="Home"
            subHeading="Home • Sites"
            subActiveHeading={listingItem.name}
            isInputField={false}
            notificationSVG={<NotificationSVG />}
            handleSearchTextChange={handleSearchTextChange}
            handleSearchClick={handleSearchClick}
            searchTerm={searchTerm}
            showButton={true}
          />
          <div className="p-20 bg-base-100">
            <div className="d-flex topsec-listing">
              <div className="siteDetails_img_container">
                {/* <span className="Vector_svg">
            <VectorSVG />
          </span> */}
                {/* <img className="siteDetails_img" src={imageUrl} /> */}
                {imageUrl && (
                  <SimpleImageSlider
                    width={454}
                    height={370}
                    images={imageUrl}
                    showBullets={true}
                    showNavs={true}
                  />
                )}
                {/* <div className="siteDetails_img_view">
            <span className="pe-1">
              <Eye />
            </span>
            Street View
          </div> */}

                {/* <div onclick="" className="bg-gray-1500 rounded-5 px-3 py-2 d-flex align-items-center text-gray-1000 position-absolute bottom-0 end-0 me-2 mb-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.09878 8.11335C0.967072 7.9553 0.967072 7.72572 1.09878 7.56766C4.69029 3.25786 11.3097 3.25786 14.9012 7.56766C15.0329 7.72572 15.0329 7.9553 14.9012 8.11335C11.3097 12.4232 4.69029 12.4232 1.09878 8.11335ZM0.330563 6.92748C4.32187 2.13792 11.6781 2.13792 15.6694 6.92748C16.1102 7.45638 16.1102 8.22464 15.6694 8.75354C11.6781 13.5431 4.32187 13.5431 0.330562 8.75354C-0.110188 8.22464 -0.110187 7.45638 0.330563 6.92748ZM9.52909 7.84051C9.52909 8.685 8.8445 9.3696 8 9.3696C7.15551 9.3696 6.47092 8.685 6.47092 7.84051C6.47092 6.99601 7.15551 6.31142 8 6.31142C8.8445 6.31142 9.52909 6.99601 9.52909 7.84051ZM10.5291 7.84051C10.5291 9.23728 9.39678 10.3696 8 10.3696C6.60323 10.3696 5.47091 9.23728 5.47091 7.84051C5.47091 6.44373 6.60323 5.31142 8 5.31142C9.39678 5.31142 10.5291 6.44373 10.5291 7.84051Z" fill="#201C1D" />
                  </svg>

                  <span className="ms-2 d-inline-block">Street View</span>
                </div> */}
              </div>
              <div className="flex-grow-1">
                <Row className="">
                  <Col
                    md={availableOffers && availableOffers.length > 0 ? 7 : 12}
                    className="toplisting"
                  >
                    <div className="px-4">
                      <div className="section">
                        <div className="d-flex justify-content-between">
                          <h4>{listingItem.listingName}</h4>
                          <span onClick={() => markAsFavorite()}>
                            {listingItem.isFavorite ? (
                              <SVGFavorite />
                            ) : (
                              <SVGNotFavorite />
                            )}
                          </span>
                        </div>
                        <div className="siteDetails_desc mb-2">
                          {listingItem.googleLocation}
                        </div>
                        <div className="siteDetails_desc">
                          <span className="pe-1">
                            <FullStarSVG />
                          </span>
                          <b>{listingItem.rating}</b> ({listingItem.reviews}{" "}
                          reviews)
                        </div>
                      </div>
                      {/* <hr /> */}
                      <div className="section">
                        {listingItem.signDescription == null ||
                          listingItem.signDescription === "" ? (
                          <p className="siteDetails_desc02">
                            {listingItem.description}
                          </p>
                        ) : (
                          <>
                            <p className="siteDetails_desc02">
                              Listing Description: <b>{listingItem.description}</b>
                            </p>
                            <p className="siteDetails_desc02">
                              Sign Details: <b>{listingItem.signDescription}</b>
                            </p>
                          </>
                        )}
                        <div className="d-flex justify-content-between mb-2 siteDetailList">
                          {listingItem.provider && (
                            <div className="siteDetails_label_heading">
                              <span className="pe-2 siteDetailListIcon">
                                <ProfileSVG />
                              </span>
                              <span className="pe-2">
                                {listingItem.provider}
                              </span>
                            </div>
                          )}
                          {listingItem.environment &&
                            listingItem.environment != "" && (
                              <div className="siteDetails_label_heading">
                                <span className="pe-2 siteDetailListIcon">
                                  <GlobSVG />
                                </span>
                                <span className="pe-2">
                                  {listingItem.environment}
                                </span>
                              </div>
                            )}
                          {listingItem.signTitle &&
                            listingItem.signTitle != "" && (
                              <div className="siteDetails_label_heading">
                                <span className="pe-2 siteDetailListIcon">
                                  <VideoPlayerSVG />
                                </span>
                                <span className="pe-2">
                                  {listingItem.signTitle}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      {/* <hr /> */}

                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="light_heading">Starting</div>
                          <div className="booking_peroid">
                            <b>${listingItem.minPrice}</b>
                            {listingItem.availableFor===1?<></>:
                            <><span className="px-1">/</span>{listingItem.minBookingPeriod}{" "}{listingItem.periodTypeText}</>}
                          </div>
                        </div>
                        <div className="booknow_btn_container">
                          {userType === "Temp" ? (
                            <button
                              className="btn btn-primary btn_sunrise btn_purple"
                              disabled
                              onClick={handleBook}
                            >
                              {" "}
                              Book Now
                            </button>
                          ) : (
                            <button
                              className={
                                listingItem.siteAvailable
                                  ? "booknow_btn "
                                  : "btn_sunrise btn_purple "
                              }
                              disabled={!canBook}
                              onClick={handleBook}
                            >
                              {" "}
                              Book Now
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                  {availableOffers && availableOffers.length > 0 && (
                    <Col md={5}>
                      <AvailableOffers
                        availableOffers={availableOffers}
                        key="available_offers"
                      />
                    </Col>
                  )}
                </Row>
              </div>
            </div>
            {listingPriceType &&
              listingPriceType === "Differential" &&
              listingItem.priceTiers && (
                <div className="price-tiers">
                  <div className="review_heading">Price Tiers</div>
                  <PriceTiers
                    priceTiers={listingItem.priceTiers}
                    key="priceTiers"
                  />
                </div>
              )}

            <div className="py-5 availabilty">
              <div className="review_heading">Availability</div>
              <Row className="d-flex align-items-center">
                <Col md={4}>
                  {/* <RangePicker defaultValue={ {startDate: availability.fromDate, endDate: availability.toDate} } onDateSelected={onDateChanges} />                */}
                  <div className="form-group">
                    <label className="dt_rang_lbl">
                      <DatePicker
                        placeholderText="Select booking dates"
                        selectsRange
                        startDate={
                          availability.fromDate
                            ? new Date(availability.fromDate)
                            : null
                        }
                        endDate={
                          availability.toDate
                            ? new Date(availability.toDate)
                            : null
                        }
                        // withPortal
                        showYearDropdown
                        isClearable={true}
                        monthsShown={2}
                        minDate={new Date()}
                        excludeDates={listingItem.signs > 1 && bookedDates}
                        onChange={(update) => {
                          if (update && update.length > 0 && update[0])
                            dispatch(
                              setAvailabilityFrom(
                                moment(update[0]).format("DD-MMMM-YYYY")
                              )
                            );
                          else dispatch(setAvailabilityFrom(null));

                          if (update && update.length > 1 && update[1])
                            dispatch(
                              setAvailabilityTo(
                                moment(update[1]).format("DD-MMMM-YYYY")
                              )
                            );
                          else dispatch(setAvailabilityTo(null));
                        }}
                      />
                      <div className="dt_icon">
                        <SVGCalendar fill="#AFAFAF" width="20" height="20" />
                      </div>
                    </label>
                  </div>
                </Col>
                <Col>
                  <p className="text-sm">
                    {" "}
                    Booking Period : {bookingDays} days
                  </p>
                </Col>
                <Col>
                  <p className="text-sm">
                    {" "}
                    Minimum Booking Period : {minBookingPeriod} days
                  </p>
                </Col>
              </Row>
              <Availablity
                handleBook={handleBook}
                item={listingItem}
                canBook={canBook}
                signs={listingItem.signs}
                services={listingItem.services}
                summaryOfCharges={listingItem.summaryChargesItems}
                availability={availability}
                setAvailabilityFrom={setAvailabilityFrom}
                setAvailabilityTo={setAvailabilityTo}
                bookingDays={bookingDays}
              />
            </div>

            {similarListing && similarListing.records && (
              <div className="similar-section">
                <div className="review_heading py-3">Similar Sites</div>
                <SimilarListing
                  currentListingId={listingItem.id}
                  similarListing={similarListing}
                  key="similar_listings"
                  type = {'Listing'}
                />
              </div>
            )}

            {listingReviews && (
              <div>
                <div className="d-flex  my-3">
                  <div className="review_heading">Review</div>
                  <span className="review_badges badges ms-2">
                    View all {listingItem.reviews} Reviews
                  </span>
                </div>
                <Row className="">
                  {listingReviews.length > 0 &&
                    listingReviews.map((review, index) => (
                      <Col md={6} key={index + "_review_col"}>
                        <Review review={review} />
                      </Col>
                    ))}
                </Row>
              </div>
            )}

            {comfirmLoginModal && (
              <ConfirmModal
                title="Login"
                isModalHeader={true}
                isFooterBtns={false}
                confirmText="Login"
                handleClose={() => setComfirmLoginModal(false)}
                handleConfirm={confirmModalLogin}
                width="400px"
              >
                <div className="childs">
                  <p className="newListingCreated_description ms-4 py-3">
                    For access to this page click Login.
                  </p>
                </div>
              </ConfirmModal>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SiteDetails;

import React, { useState } from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

const BySites = ({
  active,
  updateStatus,
  data,
  handleOrderCard,
  selectedOrdersCard,
  page,
  setPage,
}) => {
  if (data != null && data.length > 0) {
    return (
      <InfiniteScroll
        dataLength={data.length>3 ? data.length - 3 : data.length-1}
        hasMore={true}
        next={() => {
          setPage(page + 1);
          console.log('next page');
        }}
        scrollableTarget="ReviewPage"
        //loader={<h6>Loading...</h6>}
        hasChildren={true}
      >
        {data.map((item) => (
          <div onClick={() => handleOrderCard(item.id,item.listingId)}>
            <div
              className={`ordercardSection ${active} ${
                item.id == selectedOrdersCard ? "active" : ""
              }`}
            >
              <div className="orderCardMainContaner d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="review_profile_image">
                    <img
                      src={`https://gopinit.blob.core.windows.net/image-container/${item.image}`}
                      alt=""
                    />
                  </div>
                  <div className="orderCardSection_1">
                    <p className="m-0">{item.name}</p>
                    <span className="fw-bold">
                      on {moment(item.lastReviewDate).format("DD MMMM YYYY")}
                    </span>
                  </div>
                </div>

                {/* <button className="blueBgButton" style={{padding:'10px'}}>
                                        Block User
                                    </button> */}
              </div>
              <div className="orderCardFooter d-flex borderTop justify-content-between">
                <div className="Section_i">
                  <p className="mb-0">Provider</p>
                  <span>{item.providerName}</span>
                </div>
                <div className="Section_i">
                  <p className="mb-0">Ratings </p>
                  <span>{item.totalRating}</span>
                </div>
                <div className="Section_i">
                  <p className="mb-0">Avg Rating</p>
                  <span>{parseFloat(item.avgRating).toFixed(1)}/5.0</span>
                </div>
                <div className="Section_i">
                  <p className="mb-0">Reviews</p>
                  <span>{item.totalReview}</span>
                </div>
                <div className="Section_i">
                  <p className="mb-0">Last Received Review</p>
                  <span>
                    {moment(item.lastReviewDate).format("DD MMMM YYYY")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    );
  } else {
    return <></>;
  }
};

export default BySites;

import { createContext, Component } from "react";
import React from "react";

const Context = createContext();
export class ProjectContext extends Component {
  state = {
    activeMenu: "",
    activeTabName: "",
    selectedOffer: {},
    selectedSite: {},
    selectedSign: {},
    selectedService: {},
    selectedCharge: {},
    onBoardingStepCount: parseInt(localStorage.getItem("onBoardingStepDone")),
    selectedEntities: [
      {
        name: "Locations",
        isActive: false,
        isChecked: false,
        isCurrentTitle: false,
      },
      {
        name: "Signs",
        isActive: false,
        isChecked: false,
        isCurrentTitle: false,
      },
      {
        name: "Services",
        isActive: false,
        isChecked: false,
        isCurrentTitle: false,
      },
      {
        name: "Charges",
        isActive: false,
        isChecked: false,
        isCurrentTitle: false,
      },
      {
        name: "Configurations",
        isActive: false,
        isChecked: true,
        isCurrentTitle: false,
      },
    ],
    createListing: {},
    updateListing: {},
  };

  setSelectedOffer = (selectedOffer) => {
    this.setState({ selectedOffer });
  };
  setSelectedSite = (selectedSite) => {
    this.setState({ selectedSite });
  };
  setSelectedSign = (selectedSign) => {
    this.setState({ selectedSign });
  };
  setSelectedService = (selectedService) => {
    this.setState({ selectedService });
  };
  setSelectedCharge = (selectedCharge) => {
    this.setState({ selectedCharge });
  };

  setActiveTabName = (activeTabName) => {
    this.setState({ activeTabName });
  };
  setAddSite = (addSite) => {
    this.setState({ addSite });
  };
  setSelectedEntities = (selectedEntities) => {
    this.setState({ selectedEntities });
  };
  setCreateListing = (createListing) => {
    this.setState({ createListing });
  };
  setUpdateListing = (updateListing) => {
    this.setState({ updateListing });
  };
  setOnBoardingStepCount = (onBoardingStepCount) => {
    this.setState({ onBoardingStepCount });
  };
  setActiveMenu = (activeMenu) => {
    this.setState({activeMenu})
  };
  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          handleSelectedOffer: this.setSelectedOffer,
          handleSelectedSelSite: this.setSelectedSite,
          handleSelectedSelSign: this.setSelectedSign,
          handleSelectedSelService: this.setSelectedService,
          handleSelectedCharge: this.setSelectedCharge,
          handleActiveTab: this.setActiveTabName,
          handleAddSite: this.setAddSite,
          handleCreateListing: this.setCreateListing,
          handleUpdateListing: this.setUpdateListing,
          handleSelectedEntities: this.setSelectedEntities,
          handleOnBoardingStepCount: this.setOnBoardingStepCount,
          handleActiveMenu : this.setActiveMenu,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default Context;

import React, {useEffect} from "react";
import { Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { SelectionComponent } from "../../../../components/SelectionComponent/SelectionComponent";
import {useDispatch, useSelector} from "react-redux";
import { getRefundTypeOptionsAsync,setRefundAmount,setRefundType } from "../../../../services/reducers/orders-reducers";
import RangePicker from "react-range-picker";
import { InputDatePicker } from "../../../../components/InputDatePicker/InputDatePicker";
import { DatePickerWithFloatingLable } from "../../../../components/InputDatePicker/DatePickerWithFloatingLable";
import moment from "moment";
import { To2Decimal } from "../../../../services/utility";

export const ExtendOrderModal = ({
  show,
  setShow,
  title,
  order,
  fromDate,
  uptoDate,
  footerText,  
  handlefooterBtn, 
  setExtendedDates,
  extendOrderAmount,
  offerAmount,
  setOfferAmount,
}) => {
  
  // useEffect(()=>{
  //   setExtendedDates(fromDate,uptoDate);
  // },[])
   
   
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen="sm-down"
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"> 
            <h5 style={{ fontSize: "18px", fontWeight: "300" }}>{title}</h5> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>     

      <div className="d-flex justify-content-between">                   
       <DatePickerWithFloatingLable onChange={(dt)=>{
                  // if (dt && dt.length > 0 && dt[0])
                  console.log(`selected date : ${dt}`);
                  setExtendedDates(dt,null)
                  
       }

            // dispatch(
            //   setAvailabilityFrom(moment(update[0]).format("DD-MMMM-YYYY"))
            // );
        //   else dispatch(setAvailabilityFrom(null));
        // setExtendedDates(dt,null)
        } label="Extend From" value={new Date(fromDate)} inputsize={"inputsize col-md-12"}/>                        
      </div>
      <div className="d-flex justify-content-between">   
        <DatePickerWithFloatingLable minDate={new Date(fromDate)} onChange={(dt)=>{
          setExtendedDates(fromDate,dt)
          }} label="Extend Upto" value={uptoDate ? new Date(uptoDate) : uptoDate} inputsize={"inputsize col-md-12"}/>                        
      </div>

      <div className="d-flex justify-content-between">                   
        <InputField
          placeholder="offer amount"
          label="Offer Amount"
          name="offerAmount"
          value={offerAmount}
          onChange={(e)=>{setOfferAmount(e.target.value)}}
          labelClassName="inputsize col-md-12 mt-2"
          inputClassName="inputsize col-md-12"
          />
      </div>

       <div className="d-flex justify-content-between">   
          <label className="inputsize col-md-12 mt-3">Extend Order Amount:
          <span>{"  "}<b>$ {To2Decimal(extendOrderAmount)}</b></span>
          </label>
          
        </div>
       
      </Modal.Body>
      <Modal.Footer
        className="popModalfooter justify-content-center"
        onClick={handlefooterBtn}
      >
        <span style={{ color: "#ffffff" }}>{footerText}</span>
      </Modal.Footer>
    </Modal>
  );
};

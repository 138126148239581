import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router";
import { useToasts } from "react-toast-notifications";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import { CustomSort } from "../../../../../../../components/DataTable/CustomSort";
import Grid from "../../../../../../../components/grid/Grid";

import NavBar from "../../../../../../../components/navBar";
import ProjectContext from "../../../../../../../context/ProjectContext";
import {
  getAddCharges,
  getAddServices,
} from "../../../../../../../services/Provider/Configurator/Listings";
import TableActions from "../../components/TableActions";

// const columns = [
//   {
//     name: "id".toUpperCase(),
//     selector: (row) => row.id,
//     sortable: true,
//   },
//   {
//     name: "name".toUpperCase(),
//     selector: (row) => row.name,
//     sortable: true,
//   },
//   {
//     name: "description".toUpperCase(),
//     selector: (row) => row.description,
//     sortable: true,
//   },
// ];

const notiHeading = ["id", "name", "description"];
const accessorName = ["checkBox", "id", "name", "description"];
const AddChargesPage = ({ setActiveTab, sideTabs, activeTab, setSideTabs }) => {
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  const [chargessdata, setChargessdata] = useState();
  const [loader, setLoader] = useState(false);
  const [selectedCharges, setSelectedCharges] = useState([]);
  const { handleCreateListing, createListing } = useContext(ProjectContext);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //   fetch service listing data
  useEffect(async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: "",
      export: true,
      searchString: filterText,
    };
    setLoader(true);
    await getAddCharges(reqBody)
      .then((res) => {
        if (res.status == 200) {
          setLoader(false);
          // res.data?.records.forEach((item) => (item.isChecked = false));
          res.data.records.map((elem) => (elem.isChecked = false));
          location?.isUpdateListing &&
            res?.data.records.map(
              (elem, i) =>{
                //elem.id == location.data.chargeIds[i] && (elem.isChecked = true)
                  location.data.chargeIds.filter(element => {
                    element == elem.id && 
                      (elem.isChecked = true)
                  });

              });
          setChargessdata(res?.data);
          if (location?.isUpdateListing) {
            let tempData1 = res?.data?.records.filter((r) => r.isChecked);
            let tempRow = tempData1.map((item) => ({
              serviceOrChargeId: item.id,
              name: item.name,
            }));
            setSelectedCharges(tempRow);
          }

          // console.log(res.data, "res service");
        }
      })
      .catch((err) => console.log(err));
  }, [filterText]);

  // // handle for grid data selection
  // const handleGridCheckBox = (val) => {
  //   let tempData = chargessdata.records;
  //   // console.log(val, "item");
  //   console.log(tempData, "item");

  //   // tempData?.map((item) => {
  //   //   if (item.id == val.id) {
  //   //     item.isChecked = !val.isChecked;
  //   //   }
  //   // });
  //   // setServicesdata(() => tempData);
  // };

  // const handleGridAllCheckBox = () => {
  //   let tempData = servicesdata;
  //   tempData?.records?.forEach((item) => {
  //     item.isChecked = !item.isChecked;
  //   });
  //   setServicesdata(tempData);
  // };

  const handleChange = ({ selectedRows }) => {
    let tempRow = selectedRows.map((item) => ({
      serviceOrChargeId: item.id,
      name: item.name,
    }));
    setSelectedCharges(tempRow);
  };

  const handleWhiteBtn = () => {
    // console.log("white");
    history.push("/provider/configurator/listings");
  };
  // const filteredItems = chargessdata?.filter(
  //   (item) =>
  //     (item.name &&
  //       item.name.toLowerCase().includes(filterText.toLowerCase())) ||
  //     (item.id && item.id.toLowerCase().includes(filterText.toLowerCase()))
  // );

  // console.log(filteredItems, "filteredItems");

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <TableActions
          title="Add Charges"
          isInput={true}
          onSearchChange={(e) => setFilterText(e.target.value)}
          // isFilterBtn={true}
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const handleAllChecked = (row) => {
    let tempRowSelected = [...chargessdata.records];
    tempRowSelected.forEach((r) => (r.isChecked = !r.isChecked));
    setChargessdata((prev) => ({ ...prev, records: tempRowSelected }));
  };

  const handleChecked = (row) => {
    let tempRowSelected = [...chargessdata.records];
    tempRowSelected.forEach(
      (r) => r.id == row.id && (r.isChecked = !r.isChecked)
    );
    let tempData1 = tempRowSelected.filter((r) => r.isChecked);
    let tempRow = tempData1.map((item) => ({
      serviceOrChargeId: item.id,
      name: item.name,
    }));
    setSelectedCharges(tempRow);
    setChargessdata((prev) => ({ ...prev, records: tempRowSelected }));
  };

  const handleNext = (activeTab) => {
    // let tempData1 = chargessdata.records.filter((d) => d.isChecked);
    // let tempData2 = chargessdata.records.map((d) => d.isChecked && d);
    // console.log(tempData1, "temp");
    // console.log(tempData2, "temp");
    if (location.isUpdateListing) {
      let tempData1 = chargessdata.records.filter((r) => r.isChecked);
      let tempSelectedRow = tempData1.map((item) => ({
        serviceOrChargeId: item.id,
        name: item.name,
      }));
      // console.log(tempSelectedRow, "tempSelectedRow");
      setSelectedCharges(tempSelectedRow);
    }
    if(selectedCharges.length > 0) {
      selectedCharges.map((item) => (item.summaryChargesType = 1));
      handleCreateListing({ ...createListing, charges: selectedCharges });
      let tempSide = sideTabs.findIndex((elem) => elem.name == activeTab);
      let tempSide2 = [...sideTabs];
      tempSide2.forEach(
        (elem) => elem.name == activeTab && (elem.isActive = true)
      );
      // res.data.records.map(
      //   (elem, i) =>
      //     elem.id == location?.data.chargesId[i] && (elem.isChecked = true)
      // );

      // console.log(res.data.records, "res.data.records");
      setSideTabs(tempSide2);
      setActiveTab(sideTabs[tempSide + 1].name);
    } else {
      addToast("Please select atleast one charge from list", {
        appearance: "warning",
      });
    }
  };

  return (
    <>
      {console.log()}
      {/* {console.log(createListing, "create")} */}
      <div className="w-100">
        <NavBar
          whiteBtnText={"Cancel"}
          notificationSVG={<NotificationSVG />}
          navHeading={createListing?.name}
          subHeading="All Billable Item "
          subActiveHeading=" New Billable Item"
          handleWhitebtn={() => handleWhiteBtn(activeTab)}
          blueBtnText={"Next"}
          handleBlueBtn={() => handleNext(activeTab)}
          showButton={true}

        />
        <div className="page_container">
          <div className="d-flex w-100">
            <div>
              <ul className=" progressbar">
                {sideTabs?.map((tab) => (
                  <li
                    className={`billable_switchSideDom_item ${
                      tab.isActive && "active"
                    }
                    ${tab.isCurrentTitle && "activeTabTitle"}
                   `}
                  >
                    {tab.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-100 p-20">
              {subHeaderComponentMemo}
              {/* <TableActions
                title="Add Charges"
                isInput={true}
                isFilterBtn={false}
              /> */}

              <div className="card mt-3">
                <Grid
                  isCheckBox
                  tableHeading={notiHeading}
                  tableBody={chargessdata && chargessdata}
                  accessorName={accessorName}
                  handleAllChecked={handleAllChecked}
                  handleChecked={handleChecked}
                />
                {/* <DataTable
                  fixedHeader
                  progressPending={loader}
                  columns={columns}
                  data={filteredItems && filteredItems}
                  sortFunction={<CustomSort />}
                  allowOverflow={true}
                  // onRowClicked={handleSelectSignsRow}
                  onSelectedRowsChange={handleChange}
                  highlightOnHover
                  pointerOnHover
                  selectableRows
                  pagination
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddChargesPage;

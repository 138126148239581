import React, {useState, useEffect} from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { getProviderDashBoard } from "../../../../services/Provider";
import NavBar from "../../../../components/navBar";
import { LineChart } from "../../../../components/Chart/LineChart";
import { PieChart } from "../../../../components/Chart/PieChart";
import { DoughnutChart } from "../../../../components/Chart/DoughnutChart";
import { CustomListGroup } from "../../../../components/ListGroup/index";
import * as Icon from 'react-bootstrap-icons';
import { Review } from '../../../../components/reviews/Review';
import { SelectComponent  } from "../../../../components/SelectionComponent/SelectComponent";
import { NotificationSVG } from "../../../../assets/svgComponents/table";

export const Insights = () => {

    
    const [providerDashBoardData, setProviderDashBoardData] = useState({});

    const [salesGraphData, setSalesGraphData] = useState([]);
    const [salesGraphDataTitle, setSalesGraphDataTitle] = useState([]);    
    
  

    const [customersRatingsGraphData, setcustomersRatingsGraphData] = useState([]);
    const [customersRatingsGraphDataTitle, setcustomersRatingsGraphDataTitle] = useState([]);    
    const customersRatingsGraphColor = ["#FFE080","#3734A9"];
    
    const tabs = ["INFORMATION", "LISTINGS", "USAGE","INSIGHTS"];
    const [activeTab, setActiveTab] = useState(tabs[3]);

    const  GraphByItem = [
        {"id":"Year","name":"Year"},
        {"id":"Month","name":"Month"},
        {"id":"Day","name":"Day"},
      ];

    const today = new Date();    
    const curYear =  today.getFullYear();
    const curMonth =  today.getMonth() + 1;  
    const curDate =  today.getDay();  
    const lastDate =  daysInMonth(curMonth, curYear);

    const [salesGraphBy, setSalesGraphBy] = useState({id:"Year",name:"Year"});  
    const [salesYear, setSalesYear] = useState({id:curYear,name:curYear});
    //const [salesMonth, setSalesMonth] = useState(curMonth);
    //const [salesDay, setSalesDay] = useState(curDate);
    const [salesMonth, setSalesMonth] = useState({id:"01",name:"01"});
    const [salesDay, setSalesDay] = useState({id:"01",name:"01"});
    
    

    const [customerRatingsGraphBy, setCustomerRatingsGraphBy] = useState({id:"Year",name:"Year"});  
    const [customerRatingsYear, setCustomerRatingsYear] = useState({id:curYear,name:curYear});
    const [customerRatingsMonth, setCustomerRatingsMonth] = useState({id:"01",name:"01"});
    const [customerRatingsDay, setCustomerRatingsDay] = useState({id:"01",name:"01"});
    
     
    

    const supportRequests = [
        {"id":1,"heading":"#123","subheading":"01-03-2022"},
        {"id":2,"heading":"#456","subheading":"01-03-2022"},
        {"id":3,"heading":"#789","subheading":"05-04-2022"},
        {"id":4,"heading":"#101","subheading":"06-05-2022"},
        
      ];

    const verificationRequests = [
        {"id":1,"heading":"#123","subheading":"01-03-2022"},
        {"id":2,"heading":"#456","subheading":"01-03-2022"},
        {"id":3,"heading":"#789","subheading":"05-04-2022"},
        {"id":4,"heading":"#101","subheading":"06-05-2022"},      
      ];

      const reviews = [
            {"userImg":"https://placekitten.com/60/60","userName":"Aron Paul","reviewDate":"01-03-2022","reviewStar":4.5,"reviewDesc":"Review Description 1 Review Description 1."},
            {"userImg":"https://placekitten.com/60/60","userName":"Karlon Carter","reviewDate":"01-03-2022","reviewStar":3.5,"reviewDesc":"Review Description 1 Review Description 1."}
        ];
        

    let FromDate = curYear+'-01-01 00:00:00'; 
    let ToDate = curYear+'-12-31 23:59:59';  
    let reqBody = '?Type=Year&FromDate='+FromDate+'&ToDate='+ToDate;    
    //GetProviderDashBoard(reqBody);
    const [reqQuery, setReqQuery] = useState(reqBody);
    const [salesReqQuery, setSalesReqQuery] = useState("");  
    /*
    function GetProviderDashBoard(reqBody){
    }
    */
        // fetch data for by provider dashboard 
      useEffect(() => {
        //let reqBody = '?Type=Year&FromDate='+FromDate+'&ToDate='+ToDate;
        //let reqBody = "";
        //setLoader(true);
        getProviderDashBoard(reqQuery)
          .then((res) => {
            if (res.status === 200) {              
              //setLoader(false);
              setProviderDashBoardData(res.data);    
              let temp1 = [];
              let temp2 = [];  
              if(res.data.salesGraphData != null){              
                let xvalueStart = 0;
                let xvalueEnd = 0;
                if(salesGraphBy.id === "Year") {
                    xvalueEnd = 12;
                }else if(salesGraphBy.id === "Month") {
                    xvalueEnd = 31;
                }else{
                    xvalueStart = 0;
                    xvalueEnd = 23;
                }
  
                for(let i = xvalueStart; i <= xvalueEnd; i++){
                    temp2.push(i);  
                    temp1.push('0');                                         
                }  

                res.data.salesGraphData.forEach((salesData)=> {                             
                      temp1[salesData.xValue] = salesData.yValue;
                    //temp1.push(salesData.yValue)                        
                   //temp2.push(salesData.xValue)              
                });       
                setSalesGraphData(temp1);
                setSalesGraphDataTitle(temp2);
              }
              
              temp1 = [];
              temp2 = []; 
              if(res.data.customersRatioGraphData != null){
                res.data.customersRatioGraphData.forEach((customerData)=> {                             
                    temp1.push(customerData.yValue)                        
                    temp2.push(customerData.xValue)              
                });
              }              
              setcustomersRatingsGraphData(temp1);
              setcustomersRatingsGraphDataTitle(temp2);
                    
            }
          })
          .catch((err) => {
            // setLoader(false);
            console.log(err);
          });
      }, [reqQuery]);



    
   


 
    function daysInMonth(iMonth, iYear)
    {
        return new Date(iYear, iMonth, 0).getDate();
    }

    
    const yearList =  [];
    for(var y = curYear; y >= 2021; y--){
        let obj = {"id":y,"name":y};
        yearList.push(obj);
    }

    const monthList = [];
    for(var m = 1; m <= 12; m++){
        let mon = ('0' + m).slice(-2);
        let obj = {"id":mon,"name":mon};
        monthList.push(obj);
    }

    
    let temp = [];
    for(var d = 1; d <= 31; d++){
        let day = ('0' + d).slice(-2);
        let obj = {"id":day,"name":day};
        temp.push(obj);
    }
    

   const [salesDayList, setSalesDayList] = useState(temp);
   const [customerRatingsDayList, setCustomerRatingsDayList] = useState(temp);
    

    const customersRatingsGraphFooter =  customersRatingsGraphDataTitle.map((title,index) =>
        <span className="pr-1"><span className="border d-inline-block" style={{backgroundColor:customersRatingsGraphColor[index],width:'16px',height:'16px',borderRadius:'32px'}}></span>  {title}</span>                                                
    );


    
  // sales graph start
  const SalesGraphSelectChange = (e) => {      
    setSalesGraphBy(e);
    let salesReqBody = "";
    if(e.id === 'Year'){
      FromDate = salesYear.id+'-01-01 00:00:00'; 
      ToDate = salesYear.id+'-12-31 23:59:59';  
      salesReqBody = '?Type=Year&FromDate='+FromDate+'&ToDate='+ToDate;
    }else if(e.target.value === 'Month'){
      let lastDate = daysInMonth(salesMonth.id, salesYear.id);
      FromDate = salesYear.id+'-'+salesMonth.id+'-01 00:00:00'; 
      ToDate = salesYear.id+'-'+salesMonth.id+'-'+lastDate+' 23:59:59';  
      salesReqBody = '?Type=Month&FromDate='+FromDate+'&ToDate='+ToDate;
    }else{
      FromDate = salesYear.id+'-'+salesMonth.id+'-'+salesDay.id+' 00:00:00'; 
      ToDate = salesYear.id+'-'+salesMonth.id+'-'+salesDay.id+' 23:59:59';  
      salesReqBody = '?Type=Day&FromDate='+FromDate+'&ToDate='+ToDate;
    }
    //SalesDataChange(reqBody);
    setSalesReqQuery(salesReqBody);
}

function SalesYearChange(e){
  setSalesYear(e);
  let year = e.id;
  FromDate = year+'-01-01 00:00:00'; 
  ToDate = year+'-12-31 23:59:59';  
  let salesReqBody = '?Type='+salesGraphBy.id+'&FromDate='+FromDate+'&ToDate='+ToDate; 
  setSalesReqQuery(salesReqBody);

}
function SalesMonthChange(e){ 
  setSalesMonth(e)
  let month = e.id;
  let lastDate =  daysInMonth(e.id,salesYear.id);  
  let temp = [];
  for(var d = 1; d <= lastDate; d++){      
    let day = ('0' + d).slice(-2);
    let obj = {"id":day,"name":day};
    temp.push(obj);
  }
  setSalesDayList(temp);
  setSalesDay('01');   
  
  FromDate = salesYear.id+'-'+month+'-01 00:00:00'; 
  ToDate = salesYear.id+'-'+month+'-'+lastDate+' 23:59:59';  
  let salesReqBody = '?Type='+salesGraphBy.id+'&FromDate='+FromDate+'&ToDate='+ToDate;
  setSalesReqQuery(salesReqBody);
  
  
}

function SalesDayChange(e){ 
  setSalesDay(e);
  let day = e.id;
  FromDate = salesYear.id+'-'+salesMonth.id+'-'+day+' 00:00:00'; 
  ToDate = salesYear.id+'-'+salesMonth.id+'-'+day+' 23:59:59';  
  let salesReqBody = '?Type='+salesGraphBy.id+'&FromDate='+FromDate+'&ToDate='+ToDate;
  setSalesReqQuery(salesReqBody);  
}

/*
function SalesDataChange(reqBody){
}
*/
    useEffect(() => {
        if(salesReqQuery !== ""){
            getProviderDashBoard(salesReqQuery)
            .then((res) => {
            if (res.status === 200) {
                // console.log(res.data, "customer fav");
                //setLoader(false);
                // setProviderDashBoardData(res.data);    

                //console.log(providerDashBoardData,"provider Dashboard Data"); 
                
                let temp1 = [];
                let temp2 = [];  
            
                let xvalueStart = 0;
                let xvalueEnd = 0;
                if(salesGraphBy.id === "Year") {
                    xvalueEnd = 12;
                }else if(salesGraphBy.id === "Month") {
                    xvalueEnd = 31;
                }else{
                    xvalueStart = 0;
                    xvalueEnd = 23;
                }

                for(let i = xvalueStart; i <= xvalueEnd; i++){
                    temp2.push(i);  
                    temp1.push('0');                                         
                }  
                res.data.salesGraphData.forEach((salesData) => {                          
                    temp1[salesData.xValue] = salesData.yValue;
                    //temp1.push(salesData.yValue)                        
                    //temp2.push(salesData.xValue)  
                                
                });       
                setSalesGraphData(temp1);
                setSalesGraphDataTitle(temp2);          
                
            }
            })
            .catch((err) => {
            // setLoader(false);
            console.log(err);
            });
        }
    

    },[salesReqQuery])



// sales graph end




  
  // customer graph start
  
  function CustomerRatingsGraphSelectChange(e){ 
    setCustomerRatingsGraphBy(e);
    if(e.id === 'Year'){
      FromDate = customerRatingsYear.id+'-01-01 00:00:00'; 
      ToDate = customerRatingsYear.id+'-12-31 23:59:59';  
      reqBody = '?Type=Year&FromDate='+FromDate+'&ToDate='+ToDate;
    }else if(e.id === 'Month'){
      let lastDate = daysInMonth(customerRatingsMonth.id, customerRatingsYear.id);
      FromDate = customerRatingsYear.id+'-'+customerRatingsMonth.id+'-01 00:00:00'; 
      ToDate = customerRatingsYear.id+'-'+customerRatingsMonth.id+'-'+lastDate+' 23:59:59';  
      reqBody = '?Type=Month&FromDate='+FromDate+'&ToDate='+ToDate;
    }else{
      FromDate = customerRatingsYear.id+'-'+customerRatingsMonth.id+'-'+customerRatingsDay.id+' 00:00:00'; 
      ToDate = customerRatingsYear.id+'-'+customerRatingsMonth.id+'-'+customerRatingsDay.id+' 23:59:59';  
      reqBody = '?Type=Day&FromDate='+FromDate+'&ToDate='+ToDate;
    }
    CustomerRatingsDataChange(reqBody);
}


function CustomerRatingsYearChange(e){ 
  setCustomerRatingsYear(e);
  let year = e.id;
  FromDate = year+'-01-01 00:00:00'; 
  ToDate = year+'-12-31 23:59:59';  
  reqBody = '?Type='+customerRatingsGraphBy.id+'&FromDate='+FromDate+'&ToDate='+ToDate;
  CustomerRatingsDataChange(reqBody);

}
function CustomerRatingsMonthChange(e){ 
  setCustomerRatingsMonth(e)
  let month = e.id;
  let lastDate =  daysInMonth(e.id,customerRatingsYear.id);   
  let temp = [];
  for(var d = 1; d <= lastDate; d++){      
    let day = ('0' + d).slice(-2);
    let obj = {"id":day,"name":day};
    temp.push(obj);
  }
  setCustomerRatingsDayList(temp);
  setCustomerRatingsDay('01');   
  
  FromDate = customerRatingsDay.id+'-'+month+'-01 00:00:00'; 
  ToDate = customerRatingsDay.id+'-'+month+'-'+lastDate+' 23:59:59';  
  reqBody = '?Type='+customerRatingsGraphBy.id+'&FromDate='+FromDate+'&ToDate='+ToDate;
  CustomerRatingsDataChange(reqBody);
  
  
}

function CustomerDayChange(e){ 
  setCustomerRatingsDay(e);
  let day = e.id;
  FromDate = customerRatingsYear.id+'-'+customerRatingsMonth.id+'-'+day+' 00:00:00'; 
  ToDate = customerRatingsYear.id+'-'+customerRatingsMonth.id+'-'+day+' 23:59:59';  
  reqBody = '?Type='+customerRatingsGraphBy.id+'&FromDate='+FromDate+'&ToDate='+ToDate;
  CustomerRatingsDataChange(reqBody);

}

function CustomerRatingsDataChange(reqBody){
    getProviderDashBoard(reqBody)
    .then((res) => {
        if (res.status == 200) {
        // console.log(res.data, "customer fav");
        //setLoader(false);
        setProviderDashBoardData(res.data);    
        //console.log(providerDashBoardData,"provider Dashboard Data");  
        
        const temp1 = [];
        const temp2 = [];          
        res.data.customersRatioGraphData.map((customerData)=> {                             
            temp1.push(customerData.yValue)                        
            temp2.push(customerData.xValue)              
        });
        setcustomersRatingsGraphData(temp1);
        setcustomersRatingsGraphDataTitle(temp2);
        //console.log(customersGraphData,"Customer Graph Data");  
        
        }
    })
    .catch((err) => {
        // setLoader(false);
        console.log(err);
    });
}

// customer graph end

    return (
        <>
            <NavBar               
                notificationSVG={<NotificationSVG />}
                navHeading="Byward Market Square Area"
                subHeading="Configurator • Inventory • Site Details"
                showButton={true} 
        />
            <Container fluid className="mt-4">                
                <Row>            
                    <Col lg={12} md={12}>     
                        <Row>
                            <Col xs={12} className="">
                                <div className="gridSection-rm">
                                    <div className="tableActions_container mb-4">
                                        <Row>
                                            <Col className="d-flex ">
                                                <ul className="tableActions_tabs">
                                                    {tabs.map((item) =>
                                                    item === activeTab ? (
                                                        <li
                                                        className="tableActions_tab active"
                                                        onClick={() => {
                                                            setActiveTab(item);
                                                        }}
                                                        >
                                                        {item} <hr className="tableActions_tab_hr" />
                                                        </li>
                                                    ) : (
                                                        <li
                                                        className="tableActions_tab"
                                                        onClick={() => {
                                                            setActiveTab(item);
                                                        }}
                                                        >
                                                        {item}
                                                        </li>
                                                    )
                                                    )}
                                                </ul>
                                            </Col>                                          
                                        </Row>
                                    </div>
                                    <div>                                      
                                            {(() => {
                                                if (activeTab == "INFORMATION") {
                                                return (
                                                    <div className="mb-3">INFORMATION</div>
                                                )
                                                } else if (activeTab == "LISTINGS") {
                                                return (
                                                    <div className="mb-3">LISTINGS</div>
                                                )
                                                } else if (activeTab == "USAGE") {
                                                    return (
                                                        <div className="mb-3">USAGE</div>
                                                    )
                                                } else {
                                                    return (
                                                        <>
                                                            <div className="d-flex align-items-center mb-3">
                                                                <h1 className="d-inline float-start" style={{color:'#AFAFAF',fontSize:'15px',paddingRight:'10px 5px',marginRight:'10px',marginBottom:'0'}}>Sales</h1>
                                                                    <p className="d-inline float-start yr-summary">     
                                                                    <SelectComponent 
                                                                        label=""
                                                                        option={GraphByItem}  
                                                                        value={salesGraphBy}
                                                                        defaultValue={salesGraphBy}
                                                                        inputsize="form-control-sm rouned-3 sel-chart float-start"    
                                                                        handleOnchange={SalesGraphSelectChange}   
                                                                        />
                                                                        <SelectComponent 
                                                                        label=""
                                                                        option={yearList}  
                                                                        value={salesYear}
                                                                        defaultValue={salesYear}
                                                                        inputsize="form-control-sm rouned-3 sel-chart float-start"    
                                                                        handleOnchange={SalesYearChange}   
                                                                        /> 
                                                                        {(salesGraphBy.id === "Month" || salesGraphBy.id === "Day")?                         
                                                                            <SelectComponent 
                                                                            label=""
                                                                            option={monthList}  
                                                                            value={salesMonth}
                                                                            defaultValue={salesMonth}
                                                                            inputsize="form-control-sm rouned-3 sel-chart float-start"    
                                                                            handleOnchange={SalesMonthChange}   
                                                                        /> 
                                                                        :null}
                                                                        {salesGraphBy.id === "Day"?                         
                                                                        <SelectComponent 
                                                                            label=""
                                                                            option={salesDayList}  
                                                                            value={salesDay}
                                                                            defaultValue={salesDay}
                                                                            inputsize="form-control-sm rouned-3 sel-chart float-start"    
                                                                            handleOnchange={SalesDayChange}   
                                                                            /> 
                                                                        :null}
                                                                    </p>

                                                            </div>

                                                            
                                                            <LineChart graphDataTitles={salesGraphDataTitle}  graphData={salesGraphData} bgColor="#3734A9" hoverBgColor="#F9896B" />               
                                                        </>
                                                    )
                                                }
                                            })()}
                                    </div>
                                </div>   
                            </Col>
                        </Row>
                    </Col>
                    <Col  lg={5} md={5}>   
                        <Card className="text-left mb-5 chart">
                            <Card.Header>                
                                <p className="float-start">Customers Ratings</p>
                                <p className="float-end yr-summary">                              
                                    <SelectComponent 
                                        label=""
                                        option={GraphByItem}  
                                        value={customerRatingsGraphBy}
                                        defaultValue={customerRatingsGraphBy}
                                        inputsize="form-control-sm rouned-3 sel-chart float-start"    
                                        handleOnchange={CustomerRatingsGraphSelectChange}                                       />      
                                    <SelectComponent 
                                        label=""
                                        option={yearList}  
                                        value={customerRatingsYear}
                                        defaultValue={customerRatingsYear}
                                        inputsize="form-control-sm rouned-3 sel-chart float-start"    
                                        handleOnchange={CustomerRatingsYearChange}   
                                    />                                               
                                    {(customerRatingsGraphBy.id === "Month" || customerRatingsGraphBy.id === "Day")?                         
                                        <SelectComponent 
                                            label=""
                                            option={monthList}  
                                            value={customerRatingsMonth}
                                            defaultValue={customerRatingsMonth}
                                            inputsize="form-control-sm rouned-3 sel-chart float-start"    
                                            handleOnchange={CustomerRatingsMonthChange}   
                                        />    
                                    :null} 
                                    {customerRatingsGraphBy.id === "Day"?              
                                        <SelectComponent 
                                        label=""
                                        option={customerRatingsDayList}  
                                        value={customerRatingsDay}
                                        defaultValue={customerRatingsDay}
                                        inputsize="form-control-sm rouned-3 sel-chart float-start"    
                                        handleOnchange={CustomerRatingsDataChange}   
                                        />    
                                    :null}           
                                </p>
                            </Card.Header>
                            <Card.Body>                     
                                <PieChart graphDataTitles={customersRatingsGraphDataTitle}  graphData={customersRatingsGraphData} graphColors={customersRatingsGraphColor} />     
                            </Card.Body>
                            <Card.Footer className="text-left footer-details">                
                                {customersRatingsGraphFooter}
                            </Card.Footer>
                        </Card>                    
                    </Col>
                    <Col  lg={7} md={7}>  
                        <Card className="text-left mb-5 chart">
                            <Card.Header>                
                                <p>Reviews</p>                               
                            </Card.Header>
                            <Card.Body>
                                {
                                    reviews.map((item) =>
                                        <Review userImg={item.userImg} userName={item.userName} reviewDate={item.reviewDate} reviewStar={item.reviewStar} reviewDesc={item.reviewDesc} />   
                                    )
                                }
                                
                            </Card.Body>
                            <Card.Footer className="text-left d-none">                
                               
                            </Card.Footer>
                        </Card> 
                                            
                    </Col>
                    
                </Row>
            </Container>
            
        </>
    );
}

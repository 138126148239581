import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import AdminSideNav from "../../../components/sideNav/AdminSideNav";
import { Dashboard } from "./pages/Dashboard";
import AdminRoutes from "../../../routes/AdminRoutes";

// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const Admin = ({ logout }) => {
  const [openNav, setOpenNav] = useState(false);

  return (
    <>
      <div className="providerDashboard">
        <div
          className="sideNav"
          style={openNav ? { width: "217px" } : { width: "118px" }}
        >
          <AdminSideNav
            setOpenNav={setOpenNav}
            openNav={openNav}
            logout={logout}
          />
          {/* <ToastContainer /> */}
        </div>
        <div className="mainSection">
          <Switch>
            <Route
              path="/admin"
              render={(props) => <AdminRoutes {...props} />}
            />
            <Route
              exact={true}
              path='*'
              render={(props) => ( <AdminRoutes {...props} />
              )}
            />   
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Admin;

/*global google*/
import React, {useState, useEffect} from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
  LoadScript,  
  StandaloneSearchBox   
} from "@react-google-maps/api";
import mapStyles from "./MapStyles";
import markerImg from '../../../assets/images/markerImg.png';

let libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

/*
const center = {
  lat: 43.653225,
  lng: -79.383186,
};
*/


const options = {
  styles: mapStyles,
};


//let searchBox = null

export const AddSitePageMap = ({
     center,
     lat,
     lng,
     getMyLocation,
     handleSearchOnBlur,
     handleChange, 
     loc, 
     onPlacesChanged, 
     onMarkerDragEnd,
     readonly}) => {
  // console.log(process.env.NODE_ENV, "process.env.REACT_APP_GOOGLE_MAP_API_KEY");
  const [position, setPosition] = useState();
  const [refresh, setRefresh] = useState(false);
  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  //const [center, setCenter] = useState({lat: lat,lng: lng});   
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAKiVYj0Ytig8vYJS4ViTya12yJltyVo54",
    libraries,
  });
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map); 
    setRefresh(!refresh)       
  }, []);

  useEffect(() => {
    setTimeout(()=>{setPosition({lat:center.lat,lng:center.lng})},1500);    
  },[refresh])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  
  const onSBLoad = (searchData) => {
    console.log('searchBox: ', searchData)    
    //searchBox = searchData;
    setSearchBox(searchData);
  }
  /*
  const onPlacesChanged = () => {        
    const markerArray = [];   
    let results = searchBox.getPlaces();
    for (let i = 0; i < results.length; i++) {
      let place = results[i].geometry.location;
      markerArray.push(place);            
      
    }
    
    markerArray.map((mark, index) => (            
      setCenter(mark)
    ));    
    getMyLocation();
    
    //this.setState({ markers: markerArray });
    //console.log(markerArray.map(x => x.position.lat()),"lat");    
    //console.log(markerArray.map(x => x.position.long()),"long");
  };

  */
  /*
  const onMarkerDragEnd = (coord) => {    ;
    const { latLng } = coord;
    let lat = latLng.lat();
    let lng = latLng.lng();
    setCenter({ lat:lat, lng:lng });
    handleChange('siteLocation.latitude', lat);
    handleChange('siteLocation.longitude', lng);
    
    this.setState(prevState => {
      const markers = [...this.state.markers];
      markers[index] = { ...markers[index], position: { lat, lng } };
      return { markers };
    });
    
    getMyLocation();
  };

  */

  /*
  const onMarkerDragEnd = (coord) => {
    let newRegion = {
      latitude: parseFloat(coord.latitude),
      longitude: parseFloat(coord.longitude),
      latitudeDelta: 0.0522,
      longitudeDelta: 0.0321,
    };
    lat = coord.latitude;
    lng = coord.longitude;
    this.setState({
      region: newRegion,
    });    
  };   
  */

  const environments = { 
    marker: markerImg, 
  };
  
  

  

  if (loadError) return "Error Loading Maps";
  if (!isLoaded) return "Loading Maps";
  return (                
            <GoogleMap        
              mapContainerStyle={mapContainerStyle}
              zoom={8}
              center={position}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                streetViewControl: false,
              }}
            >
               {/*  <StandaloneSearchBox
                  onLoad={onSBLoad}              
                  onPlacesChanged={onPlacesChanged}              
                >
                <input
                    type="text"
                    placeholder="location"
                    onBlur={(e)=> handleSearchOnBlur(e)}
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: `240px`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: "absolute",
                      top: "10px",
                      left: "50%",
                      marginLeft: "-120px"
                    }}
                  />
                </StandaloneSearchBox> */}
                <Marker position={position} draggable={!readonly}  onDragEnd={(coord) => onMarkerDragEnd(coord)} icon={environments.marker} />          
            </GoogleMap>   
       
            
  );
};

import React, { useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  ApprovedSVG,
  PlusSVG,
  TagSVG,
} from "../../../../../assets/svgComponents/svg";
import { To2Decimal } from "../../../../../services/utility";
import { getBookingDetails, validateOfferAsync, removeInvoiceOfferStatus, removeOffer } from "../../../../../services/reducers/listing-reducer";
import { useEffect } from "react";

const PaymentDetails = ({ bookingDetails }) => {
  const [showInvalidOffer, setShowInvalidOffer] = useState(false);
  const [invalidOffer, setInvalidOffer] = useState();
  const dispatch = useDispatch();
  const { availableOffers, listingItem, availedOffer } = useSelector((state) => state.listing);
  const { totalAmount, noOfSigns, site, signs, services } = useSelector((state) => getBookingDetails(state.listing));

  useEffect(() => {
    if (availableOffers) {
      if (availableOffers.length > 0) {
        availableOffers.forEach((offer, index) => {
          if (offer.invalid) {
            setInvalidOffer(offer);
            setShowInvalidOffer(true);
          }
        });
      }
    }
  }, [availableOffers, invalidOffer])
  const deleteOffer = () => {
    dispatch(removeOffer());
  }
  const applyOffer = (offer) => {
    let mediaTypes = [];
    signs.forEach(r => { mediaTypes.push(r.sign.mediaType); });
    let serviceIds = [];
    services.forEach(r => serviceIds.push(r.serviceId));
    let reqBody = {
      offerId: offer.id,
      listingId: listingItem.listingId,
      orderAmount: totalAmount,
      numberOfSigns: noOfSigns,
      mediaTypes: mediaTypes,
      serviceIds: serviceIds,
      siteIds: [site?.id]
    };
    console.log('validating offer', reqBody);
    dispatch(validateOfferAsync(reqBody));
  }

  const handleCloseInvalidOffer = () => {
    dispatch(removeInvoiceOfferStatus(invalidOffer));
    setShowInvalidOffer(false);
  }



  return (
    <>
      <div className="site_booking_card_heading py-3">Payment Details</div>
      {bookingDetails.bookingType == 'site' && (
        <h5 className="site_booking_card_heading05">
          <Row className="py-1">
            <Col>Site Rent</Col>
            <Col className="d-flex justify-content-end">
              ${To2Decimal(bookingDetails.applicableAmountWithoutTax)}
            </Col>
          </Row>
        </h5>
      )}

      {bookingDetails.bookingType == 'sign' && (
        <h5 className="site_booking_card_heading05">
          <Row className="py-1">
            <Col>Sign Rent</Col>
            <Col className="d-flex justify-content-end">
              ${To2Decimal(bookingDetails.signAmountWithoutTax)}
            </Col>
          </Row>
        </h5>
      )}


      <h5 className="site_booking_card_heading05">
        <Row className="py-1">
          <Col>Add-On Services Price</Col>
          <Col className="d-flex justify-content-end">
            ${To2Decimal(bookingDetails.addOnServicePriceWithoutTax)}
          </Col>
        </Row>
      </h5>
      <h5 className="site_booking_card_heading05">
        <Row className="py-1">
          <Col>Add-On Charges Price</Col>
          <Col className="d-flex justify-content-end">
            ${To2Decimal(bookingDetails.addOnChargePriceWithoutTax)}
          </Col>
        </Row>
      </h5>
      <h5 className="site_booking_card_heading05">
        <hr />
        <Row className="py-1">
          <Col>Offer Discount</Col>
          <Col className="d-flex justify-content-end">
            -${To2Decimal(bookingDetails.offerDiscount)}
          </Col>
        </Row>
      </h5>
      {availableOffers && (
        <>
          {availableOffers.length > 0 && availableOffers.map((offer, index) => (
            <h5 className="site_booking_card_heading05" key={'offer_to_apply_' + index}>
              <Row>
                <Col className="d-flex align-items-center">
                  {" "}
                  <span className="pe-3">
                    <TagSVG />{" "}
                  </span>
                  <div>
                    <b className="">{offer.code}</b>
                    <p className="site_booking_card_desc py-2">
                      {offer.details}
                    </p>
                  </div>
                </Col>
                <Col className=" d-flex justify-content-end p-0">
                  {offer.id === availedOffer?.offerId ? (
                    <div className="applied" onClick={deleteOffer} style={{cursor:"pointer"}}>
                      <span>
                        <ApprovedSVG />{" "}
                      </span>
                      Applied
                    </div>
                  ): (
                      <div className="applicable_offer" onClick={(e) => applyOffer(offer)} style={{cursor:"pointer"}}>
                        <span>
                          <PlusSVG />{" "}
                        </span>
                        Apply
                      </div>
                    )
                  }
                </Col>
              </Row>
            </h5>
          ))
          }
          {/* <div className="more_offer">
            <span>
              <PlusSVG />{" "}
            </span>
            3 more
          </div> */}
          <hr />
        </>
      )}
      <h5 className="site_booking_card_heading05">
        <Row className="py-1">
          <Col>Total Tax</Col>
          <Col className="d-flex justify-content-end">
            ${To2Decimal(bookingDetails.totalTax)}
          </Col>
        </Row>
      </h5>
      <div className="site_booking_card_heading">
        <hr />
        <Row className="py-3">
          <Col>Total Amount</Col>
          <Col className="d-flex justify-content-end ">
            <b>${To2Decimal(bookingDetails.netAmount)}</b>
          </Col>
        </Row>
      </div>
      {/* <div className="site_booking_card_heading">
      <hr />
        <Row className="py-3">
          <Col>Net Amount</Col>
          <Col className="d-flex justify-content-end ">
            <b>${To2Decimal(bookingDetails.netAmount)}</b>
          </Col>
        </Row>
      </div>
       
      */}
      <Modal show={showInvalidOffer} onHide={() => handleCloseInvalidOffer()}>
        <Modal.Header closeButton>
          <Modal.Title>Invalid Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are not eligible for this offer.</Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default PaymentDetails;

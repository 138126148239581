import React from 'react'
import {Switch,Route}from "react-router-dom"
import ChangePassword from './Pages/ChangePassword'
import Settings from './Pages/Settings'
export const SettingsRoutes = ({...rest}) => {
  return (
    <Switch>
      <Route
        path={`${rest.match.url}/`}
        exact
        render={(props) => <Settings {...props} />}
      />
     <Route
        path={`${rest.match.url}/change-password`}
        render={(props) => <ChangePassword {...props} />}
      />
    </Switch>
  )
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFavoriteSitesAndSign,setUnfavorite } from "../api/customer-store-api";

const initialState = {
    favorites:{records:[],page:1},
    favGridHeading : [ "SIGN","MEDIA","LOCATION","Direction Facing","Select Quantity","PRICING",],   
    favSitesBody : ["#ST21/1-3","King Poster (170” x 35”)","35 Bayward Market Square Area, Ottawa, Canada","East","select","$36,000 $1,200 x 30 days",]
}

export const getFavoritesAsync = createAsyncThunk('customer/getFavoritesAsync',
    async(request) => {        
    const response = await getFavoriteSitesAndSign(request);    
    return response.data;
    }
)

export const unfavoriteAsync = createAsyncThunk('customer/unfavoriteAsync',
    async(request) => {
    const response = await setUnfavorite(request);
    return {data:response.data.data,listingItemId:request.listingItemId};
    }
)



const customerSlice = createSlice({
    name:"customers",
    initialState,
    reducers:{
    },
    extraReducers:{
        [getFavoritesAsync.pending] : () => {
            
        },
        [getFavoritesAsync.fulfilled] : (state,{payload}) => {              
            if(payload.page>1 && payload.page!=state.favorites.page){
                return {...state, favorites: {totalRecords:payload.totalRecords, page:payload.page, pageSize:payload.pageSize, records:[...state.favorites.records,...payload.records]}};
            }else{
                return {...state,favorites: {totalRecords:payload.totalRecords, page:payload.page, pageSize:payload.pageSize,records:payload.records}};
            }            
        },[getFavoritesAsync.rejected] : () => {
            console.log("favorites rejected");
        },
        [unfavoriteAsync.pending] : () => {
        },
        [unfavoriteAsync.fulfilled] : (state,{payload}) => {     
            const {listingItemId} = payload;      
            return {...state, favorites : {...state.favorites, records:[...state.favorites.records.filter(r=>r.listingItemId!=listingItemId)]}};
        },[unfavoriteAsync.rejected] : () => {
            console.log("rejected");
        },
    }
});

export default customerSlice.reducer;
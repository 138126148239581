import React, { useState } from "react";
import { Container, Col, Row, Modal} from "react-bootstrap";
import NavBar from "../../../../components/navBar";
import { NotificationSVG } from "../../../../assets/svgComponents/table";
import { Create } from "../../../../components/SupportRequest/Create";
import UpdatesModal from "../../../../components/profile/Modal/UpdatesModal";
export const AddSupportRequest = ()  => { 
    const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false); 
    const handleNotificationSVG = () => {
        setNavbarUpdatesModal(true);
      };
      
    return (
        <>
        <NavBar
            notificationSVG={<NotificationSVG />}
            navHeading="Support Request"
            subHeading=""
            handleNotificationSVG={handleNotificationSVG}
            showButton={true} 
        />
        <Container fluid className="px-0">        
            <Row className="g-0">
                <Col lg={9} md={9}>                        
                    <Create />         
                </Col>
            </Row>
        </Container>
        <Modal
      show={navbarUpdatesModal}
      onHide={() => setNavbarUpdatesModal(false)}
      dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
    >
      <UpdatesModal />
    </Modal>
        </>
    );
}
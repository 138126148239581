import React, { useState, useEffect } from "react";
import { Badge, Modal } from "react-bootstrap";
import {
  ExportCSVSVG,
  NotificationSVG,
  PlusSVG,
} from "../../../../assets/svgComponents/table";
import Button from "../../../../components/button/Button";
import { FloatingLableSearchBar } from "../../../../components/SearchBar/FloatingLableSearchBar";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import {
  getListBySitesAsync,
  getListItemDetailsAsync,
  getSimilarListingAsync,
  getListingReviewsAsync,
  getAvailableOffersOnSiteAsync,
  getSelectedFilters,
  setShowOnlyFeatured,
  setLatitude,
  setLongitude,
  setMapZoom,
  setSearchSection,
  setLocationName,
} from "../../../../services/reducers/listing-reducer";
import {
  getNotificationHistoryList,
  markAllNotificationRead,
  putNotificationMardRead,
} from "../../../../services/api/notifications-api";
import UpdatesModal from "../../../../components/profile/Modal/UpdatesModal";

import { InputGroup, FormControl, FloatingLabel } from "react-bootstrap";
import { SVGSearch } from "../../../../assets/svgComponents/svg";

const ListingSearchBar = (props) => {
  const dispatch = useDispatch();
  const {
    handleBlueBtn,
    handleWhitebtn,
    type,
    searchBarlabel,
    handleSearchTextChange,
    handleSearchClick,
    handelSearchSectionChange,
  } = props;

  const { searchSection, searchTerm } = useSelector((state) => state.listing);

  const [loc, setLoc] = useState("");
  const [unreadNotificationCount, setUnreadNotificationCount] = useState();
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);
  //const searchSectionOptions = [{id:'location',name:"Location"},{id:'provider', name:"Provider"},{id:'site', name:"Site"},{id:'sign',name:"Sign"}];
  const searchSectionOptions = [
    { id: "location", name: "Location" },
    { id: "all", name: "By Name" },
  ];

  useEffect(() => {
    const reqBody = {
      page: 1,
      pageSize: 10,
      orderBy: "",
      isOrderByDesc: true,
      export: false,
      searchString: "",
      fromDate: null,
      toDate: null,
    };
    getNotificationHistoryList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          console.log(res, "notification");
          //setNotifications(res.data.records);
          let unread = res.data.records.filter(
            (notification) => !notification.isRead
          );
          setUnreadNotificationCount(0);
          if (unread.length > 0) {
            setUnreadNotificationCount(unread.length);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [navbarUpdatesModal]);

  const handleNotification = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <>
      <div className="provider_navbar row p-20 py-0 m-0 g-0">
        <div className="navbarLeftSide col-4">
          <h3 className="pageHeading m-0">{props.navHeading}</h3>
          {props.subHeading && (
            <div className="d-flex">
              <p className="m-0 pageSubHeading">{props.subHeading}</p>
              <p className="m-0 pageSubActiveHeading">
                {props.subActiveHeading}
              </p>
            </div>
          )}
        </div>

        <div className="col-6">
          <div className="row d-flex align-items-center">
            <div className="col-md-2 pe-0">
              <Select
                name="searchSection"
                value={searchSection}
                onChange={async (val) => {
                  handelSearchSectionChange(val);
                }}
                menuPlacement={"top"}
                options={searchSectionOptions}
                className="customSelectDrop location"
                //placeholder="Select"
                defaultValue={searchSection}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                styles={{ zIndex: 9999 }}
              />
            </div>
            <div className="col-md-7 ps-0">


              {searchSection && searchSection.id === "location" && (
                <PlacesAutocomplete
                  value={loc}
                  onChange={(p) => {
                    setLoc(p);
                  }}
                  onSelect={(p) => {
                    setLoc(p);
                    dispatch(setLocationName(p));
                    //geocodeByAddress(p).then((results) => { getLatLng(results[0]); dispatch(setLocationName(p));}).then(({ lat, lng }) => { dispatch(setLatitude(lat)); dispatch(setLongitude(lng));});
                    geocodeByAddress(p)
                      .then((results) => getLatLng(results[0]))
                      .then(({ lat, lng }) => {
                        dispatch(setLatitude(lat));
                        dispatch(setLongitude(lng));
                      });
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="locationSearch">
                      {/* <input
                                {...getInputProps({
                                    placeholder: "Location",
                                    className:"inputField form-control location-search-input",
                                })}
                                /> */}
                      <FloatingLabel
                        label="Search Location"
                        className={`myinput`}
                      >
                        <FormControl
                          {...getInputProps({
                            placeholder: "Location",
                            className:
                              "inputField form-control location-search-input",
                            id: "globalSearchBox",
                          })}
                        />
                        <InputGroup.Text
                          className={`serchIcon`}
                          onClick={() => { }}
                        >
                          <SVGSearch />
                        </InputGroup.Text>
                      </FloatingLabel>

                      <div className="autocomplete-dropdown-container">
                        {/* {loading && <div className="loadingDiv">Loading...</div>} */}
                        {suggestions && suggestions.length > 0 && (
                          <div className="locationSearchResults">
                            {suggestions.map((suggestion, index) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                  backgroundColor: "#FAFAFA",
                                  cursor: "pointer",
                                }
                                : {
                                  backgroundColor: "#FFFFFF",
                                  cursor: "pointer",
                                };
                              return (
                                <div
                                  key={"loc_" + index}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )}

              {searchSection && searchSection.id !== "location" && (
                <FloatingLableSearchBar
                  label={searchBarlabel}
                  handleOnchange={handleSearchTextChange}
                  handleSearchClick={handleSearchClick}
                  searchTerm={searchTerm}
                  searchafter={"searchafter"}
                  className={"searchLocationByName"}
                />
              )}





            </div>
          </div>
        </div>
        <div className="col-md-2 float-end">
          {/* <Select
            name="searchSection"
            value={searchSection}
            onChange={async (val) => {
              handelSearchSectionChange(val);
            }}
            menuPlacement={"top"}
            options={searchSectionOptions}
            className="customSelectDrop"
            //placeholder="Select"
            defaultValue={searchSection}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            styles={{ zIndex: 9999 }}
          /> */}
          {props.notificationSVG && (
            <div className="navbarBtnContainer float-end">
              <span
                className="navbarNotification"
                onClick={handleNotification}
              >
                {unreadNotificationCount > 0 && (
                  <div
                    style={{
                      padding: "2px",
                      backgroundColor: "red",
                      borderRadius: "100%",
                      float: "right",
                      marginTop: "3px"
                    }}
                  ></div>
                )}
                {props.notificationSVG}
              </span>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

export default ListingSearchBar;

import React from "react";
import { Form } from "react-bootstrap";
import ConfirmModal from "../../confirmModal/ConfirmModal";
import { InputField } from "../../InputField";
import { SelectionComponent } from "../../SelectionComponent/SelectionComponent";
import * as Yup from "yup";
import { useFormik } from "formik";
import { updateAddress } from "../../../services/Provider";
import { useToasts } from "react-toast-notifications";



export const UpdateAddressModal = ({
  handleCityList,
  handleStateList,
  handleCountryList,
  selecltAddresscard,
  setUpdateAddressModal,
  selectedCountry,
  selectedState,
  selectedCity,
  cityList,
  statelist,
  countrylist,
  handleUpdatedAddress
}) => {
  const { addToast } = useToasts();


  console.log('selectedCity', selectedCity)
  const formik = useFormik({
    initialValues: {
      address:selecltAddresscard&&selecltAddresscard.address1,
      pincode: selecltAddresscard&&selecltAddresscard.pincode,
    },
    validationSchema: Yup.object().shape({
      address: Yup.string().required("Please enter your address!"),
      pincode: Yup.string()
        .min(5, "Postal code should be 5 digit")
        .max(6, "Postal code should be 6 digit")
        .typeError("Please enter a number.")
        .required("Please enter your Postal code!"),
    }),
    onSubmit: (values) => {
      console.log("valueFormikA", values);
      let formData = [
        {
          path: "/address1",
          op: "add",
          value: values.address,
        },
        {
          path: "/pincode",
          op: "add",
          value: values.pincode,
        },
        {
          path: "/city",
          op: "add",
          value: selectedCity && selectedCity[0].name,
        },
        {
          path: "/state",
          op: "add",
          value: selectedState && selectedState[0].name,
        },
        {
          path: "/country",
          op: "add",
          value:selectedCountry && selectedCountry[0].name,
        },
      ];
      // console.log("formData2", formData);
      let id = selecltAddresscard && selecltAddresscard.id;
      updateAddress(id, formData).then(r=>{
        addToast("Address updated sucessfully",{appearance:'success'})
        if(handleUpdatedAddress)handleUpdatedAddress();
      });
      setUpdateAddressModal(false);
    },
  });

  const addressError = formik.touched.address && formik.errors.address;
  const pincodeError = formik.touched.pincode && formik.errors.pincode;


  return (
    <>
      <Form className="py-4" onSubmit={formik.handleSubmit}>
        <ConfirmModal
          title="Update
          Address"
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Update Address"
          handleClose={() => setUpdateAddressModal(false)}
          type="submit"
          selecltAddresscard={selecltAddresscard}
          disable ={formik.isSubmitting}
        >
          <div className="childs">
            <InputField
              placeholder="Address"
              label="Address"
              name="address"
              value={formik.values.address}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />{" "}
            {addressError && (
              <p className="text-danger mt-1" style={{ fontSize: "14px" }}>
                {formik.errors.address}
              </p>
            )}
          </div>
          <div className="childs">
            <InputField
              placeholder="Postal code"
              label="Postal code"
              name="pincode"
              value={formik.values.pincode}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />{" "}
            {pincodeError && (
              <p className="text-danger mt-1" style={{ fontSize: "14px" }}>
                {formik.errors.pincode}
              </p>
            )}
          </div>
          <div className="childs">
            <SelectionComponent
              label="Country"
              option={countrylist}
              handleOnchange={handleCountryList}
              selected={selectedCountry.length>0 && selectedCountry[0].name}
            />{" "}
          </div>
          <div className="childs">
            <SelectionComponent
              label="State"
              option={statelist}
              handleOnchange={handleStateList}
              selected={selectedState.length>0&&selectedState[0].name}

            />{" "}
          </div>
          <div className="childs">
            <SelectionComponent
              label="City"
              option={cityList}
              handleOnchange={handleCityList}
              selected={selectedCity.length>0 && selectedCity[0].name}

            />{" "}
          </div>
        </ConfirmModal>
      </Form>
    </>
  );
};

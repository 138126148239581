import React, { useState } from "react";
import ConfirmModal from "../../../../../../../../components/confirmModal/ConfirmModal";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";

const OnBoardingFeatured = ({
  setRegisterListingModal,
  handleRegisterDateFetaured,
  onChangeFeaturedDate,
  featuredDate,
}) => {
  const history = useHistory();

  const handleCancel = () => {
    history.push("/provider/configurator/listings");
    setRegisterListingModal(false);
  };
  return (
    <>
      {console.log(featuredDate, "featuredDate")}{" "}
      {/* Listing to be featured modal*/}
      {/* {registerListingModal && ( */}
      <ConfirmModal
        title="When do want your listing to be featured?"
        confirmText="Proceed"
        isModalHeader={true}
        isFooterBtns={true}
        cancelText="Cancel"
        handleClose={() => handleCancel()}
        handleConfirm={() => handleRegisterDateFetaured()}
        titleWidthClassName={"w-50"}
      >
        <div className="pt-1">
          <div>
            <DatePicker
              selected={featuredDate.startDate}
              startDate={featuredDate.startDate}
              endDate={featuredDate.endDate}
              onChange={onChangeFeaturedDate}
              selectsRange
            />
            {/* <RangePicker
              defaultValue={{
                startDate: featuredTime.fromDate,
                endDate: featuredTime.toDate,
              }}
              onDateSelected={onDateChanges}
            /> */}
            {/* <DatePicker
              value={new Date()}
              startDate={new Date()}
              endDate={new Date()}
              calendarIcon={<SVGCalendar fill="#AFAFAF" />}
              format="MMM dd yyyy - MMM dd yyyy"
              // dateFormat="MMM dd yyyy - MMM dd yyyy"
              showDatePicker
            /> */}
          </div>
          <div className="confirmModal1 py-4 d-flex align-items-center">
            <span className="text-lg text-gray-900">Total Amount</span>{" "}
            <h1 className="ms-3 mb-0">$ 200</h1>
          </div>
        </div>
      </ConfirmModal>
    </>
  );
};

export default OnBoardingFeatured;

import React, { useState } from "react";
import CustomerSideNav from "../../../components/sideNav/CustomerSideNav";
import { Col, Row } from "react-bootstrap";
import CustomerRoutes from "../../../routes/CustomerRoutes";
import { Redirect, Route, Switch } from "react-router-dom";
import SitesBooking from "./pages/SitesBooking";
import Chat from "../Shared/Chat/Chat";
import SitesPayment from "./pages/SitesPayment";
import { AddSupportRequest } from "../Provider/SupportRequest/Add";

// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { ExtendOrderPayment } from "./pages/ExtendOrderPayment";
import { PayOrderInstallment } from "./pages/PayOrderInstallment";

export const Customers = ({ logout }) => {
  const [openNav, setOpenNav] = useState(false);

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="providerDashboard">
        <div
          className=" sideNav"
          style={{ width: openNav ? "217px" : "118px" }}
        >
          <CustomerSideNav
            setOpenNav={setOpenNav}
            openNav={openNav}
            logout={logout}
          />
        </div>
        <div className="mainSection ">
          <Switch>
            <Route
              path="/customer"
              render={(props) => <CustomerRoutes {...props} />}
            />
            <Route
              path="/sites-booking"
              render={(props) => <SitesBooking {...props} />}
            />
            <Route
              path="/sites-payment"
              render={(props) => <SitesPayment {...props} />}
            />
            <Route
              path="/customer/add-support-request"
              component={AddSupportRequest}
            />
            <Route
              path="/customer/add-support-request"
              component={AddSupportRequest}
            />
            <Route
              path="/order-payment/:orderId"
              render={(props) => <PayOrderInstallment {...props} />}
            />
            <Route
              exact={true}
              path="*"
              render={(props) => <CustomerRoutes {...props} />}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};

import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import profileImg from "../../../assets/images/r1.png";
import { UnionSVG } from "../../../assets/svgComponents/table";
import FiltersModal from "./FiltersModal";
import {
  getNotificationHistoryList,
  markAllNotificationRead,
  putNotificationMardRead,
} from "../../../services/api/notifications-api";
import moment from "moment";
import { OrderPage } from "../../../../src/views/app/Provider/OrderAndTransaction/Pages/OrderPage";

const UpdatesModal = () => {
  const history = useHistory();
  const userType = localStorage.getItem("userType");
  const [navbarFiltersModal, setNavbarFiltersModal] = useState(false);
  const [activeTab, setActiveTab] = useState("NOTIFICATIONS");
  const [notifications, setNotifications] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  useEffect(() => {
    const reqBody = {
      page: 1,
      pageSize: 10,
      orderBy: "",
      isOrderByDesc: true,
      export: false,
      searchString: "",
      fromDate: null,
      toDate: null,
    };
    getNotificationHistoryList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          console.log(res, "notification");
          setNotifications(res.data.records);
          let unread = res.data.records.filter(
            (notification) => !notification.isRead
          );
          setUnreadNotificationCount(unread.length);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [refresh]);

  const handleFilter = () => {
    setNavbarFiltersModal(true);
  };

  const notificationClick = (notification) => {
    putNotificationMardRead(notification.id)
      .then((res) => {
        if (res.status === 204) {
          let title = notification.title;
          //console.log(res,'notification');
          setRefresh(!refresh);
          if (userType === "Customer") {
            if (
              title === "New Order Placed" ||
              title === "Payment Failed" ||
              title === "Order Status Update" ||
              title === "New Message Received" ||
              title === "Refund Request Approved/Rejected" ||
              title === "Refund Processed" ||
              title === "Extend Order Offer" ||
              title === "Payment Due in 5 days"
            ) {
              history.push({
                pathname: "/customer/orders",
                search: `?orderId=${notification.targetId}`,
              });
            } else if (title === "Extend Order Offer") {
              // redirect to order detail
            } else if (title === "Payment Due in 5 days") {
              //  Redirect to payment screen
            }
          } else if (userType === "Provider") {
            if (title === "Organization Approved/Rejected") {
              history.push("/provider");
            } else if (
              title === "New Order Received" ||
              title === "New Message Received" ||
              title === "Refund Requested" ||
              title === "New Order Extended"
            ) {
              history.push({
                pathname: "/provider/orders",
                search: `?orderId=${notification.targetId}`,
              });
            } else if (title === "Payout Received") {
              history.push({
                pathname: "/provider/orders",
                search: `?transactionId=${notification.targetId}`,
              });
            }
          } else {
            if (title === "Featured Listing Order Placed" ||
              title === "Order Cancelled" ||
              title === "Refund Requested") {
              history.push({
                pathname: "/admin/orders",
                search: `?orderId=${notification.targetId}`,
              });
            } else if (title === "Payout Released") {
              history.push({
                pathname: "/admin/orders",
                search: `?transactionId=${notification.targetId}`,
              });
            } else if (title === "New Organization Set Up") {
              history.push({
                pathname: "/admin/allusers/provider-details",
                state: { 'id': notification.targetId },
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleTabChange = (tab) => {
    switch (tab) {
      case "MESSAGES": {
        setActiveTab("MESSAGES");
        break;
      }
      case "NOTIFICATIONS": {
        setActiveTab("NOTIFICATIONS");
        break;
      }
    }
  };
  const markAllRead = () => {
    markAllNotificationRead()
      .then((res) => {
        if (res.status === 204) {
          //console.log(res,'notification');
          setRefresh(!refresh);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="ps-2 modal_title">Updates</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex" style={{ width: "100%" }}>
            {/* <div
              className={
                activeTab == "MESSAGES"
                  ? "tabs active pt-4 pb-3"
                  : "tabs pt-4 pb-3"
              }
            >
              <b
                className={activeTab == "MESSAGES" ? "tab active" : "tab"}
                onClick={() => handleTabChange("MESSAGES")}
              >
                MESSAGES (1)
              </b>
            </div> */}
            <div
              className={
                activeTab == "NOTIFICATIONS"
                  ? "tabs active pt-4 pb-3 ps-3"
                  : "tabs pt-4 pb-3 ps-3"
              }
            >
              <b
                className={activeTab == "NOTIFICATIONS" ? "tab active" : "tab"}
                onClick={() => handleTabChange("NOTIFICATIONS")}
              >
                NOTIFICATIONS ({unreadNotificationCount})
              </b>
            </div>
            <div
              className="pt-4 pb-3 ps-3"
              style={{ textAlign: "right", width: "65%" }}
            >
              <button onClick={() => markAllRead()}>Mark all Read</button>
            </div>
          </div>
          {/* <div className="filter_btn" onClick={() => handleFilter()}>
            Filter{" "}
            <span className="ps-2">
              <UnionSVG />
            </span>
          </div> */}
        </div>
        <Card className="updates_card mt-2">
          {activeTab == "MESSAGES" && (
            <div className="d-flex">
              <div>
                <img className="update_profile_img" src={profileImg} />
              </div>
              <div className="ps-2">
                <b className="update_heading">Arron Paul</b>
                <p className="update_desc">
                  What is the status for the design? It's been 2 weeks now
                </p>
                <p className="update_desc02 m-0">5 mins ago</p>
              </div>
            </div>
          )}
          {activeTab == "NOTIFICATIONS" &&
            notifications &&
            notifications.map((notification) => {
              return (
                <>
                  <div
                    key={notification.id}
                    className={`d-flex ${notification.isRead ? "read" : "unread"
                      } `}
                    onClick={() => notificationClick(notification)}
                    style={{cursor:"pointer"}}
                  >
                    <div>
                      {/* <img className="update_profile_img" src={notification.imageUrl} /> */}
                    </div>
                    <div className="ps-2">
                      <b className="update_heading">{notification.title}</b>
                      <p className="update_desc">{notification.content}</p>
                      <p className="update_desc02 m-0">
                        {moment(notification.createdOn).format(
                          "DD MMM, yyyy hh:mm"
                        )}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
        </Card>
      </Modal.Body>

      {/* navbar filters modal  */}
      <Modal
        show={navbarFiltersModal}
        onHide={() => setNavbarFiltersModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <FiltersModal />
      </Modal>
    </>
  );
};

export default UpdatesModal;

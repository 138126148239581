/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Row } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NavBar from "../../../../../components/navBar";
import {
  getAccessTypes,
  updateInviteUser,
} from "../../../../../services/Provider/UserManagement/UserManagement";
import { useHistory } from "react-router";
import { MediaUpload } from "../../../../../components/UploadFile/MediaUpload";
import * as jsonpatch from "fast-json-patch/index.mjs";
import { SelectionComponent } from "../../../../../components/SelectionComponent/SelectionComponent";
import { uploadFileMethod } from "../../../../../services/Provider";
import { values } from "lodash";
import { useToasts } from "react-toast-notifications";

const EditUser = (props) => {
  const { id, name, emailId, accessTypeId, profilePicture } =
    props.location.state;
  const [showImageUploadOption, setShowImageUploadOption] = useState(
    profilePicture == null ? true : false
  );
  const [accessTypes, setAccessType] = useState([]);
  const history = useHistory();
  const { addToast } = useToasts();
  const [loader, setLoader] = useState(false);
  const userType = localStorage.getItem("userType");

  const ValidationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required."),
    emailId: Yup.string()
      .required("Email address is required.")
      .matches(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
        "Invalid email address."
      ),
    accessTypeId: Yup.string().required("Access type is required."),
  });

  useEffect(async () => {
    const reqBody = {
      page: 0,
      pageSize: 10,
      orderBy: "",
      export: false,
      searchString: "",
      status: 0,
    };
    await getAccessTypes(reqBody)
      .then((res) => {
        if (res.status === 200) {
          setAccessType(res.data.records);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleBlueBtn = () => {};

  const handleWhitebtn = () => {
    history.goBack();
  };

  const handleShowImageUploadBtn = (val) => {
    if (showImageUploadOption) {
      setShowImageUploadOption(false);
    } else {
      values.image = profilePicture;
      setShowImageUploadOption(true);
    }
  };

  return (
    <>
      <div className="mainSection_container-rm px-3-rm pb-3-rm pt-5-rm">
        <Formik
          initialValues={{
            emailId: emailId,
            accessTypeId: accessTypeId,
            fullName: name,
            image: profilePicture,
          }}
          validationSchema={ValidationSchema}
          onSubmit={async (values) => {
            var details = [
              {
                path: "/name",
                op: "add",
                value: values.fullName,
              },
              {
                path: "/accessTypeId",
                op: "add",
                value: values.accessTypeId,
              },
              {
                path: "/profilePicture",
                op: "add",
                value: values.image,
              },
            ];
            await updateInviteUser(id, details)
              .then((res) => {
                if (
                  res.status === 200 ||
                  res.status === 201 ||
                  res.status === 204
                ) {
                  setLoader(false);
                  addToast("User has been updated successfully.", {
                    appearance: "success",
                  });
                  if (userType==="Provider") {
                    history.push("/provider/usermanagement");
                  } else {
                    history.push("/admin/allusers");
                  }
                } else {
                  setLoader(false);
                  addToast("Error while updating user.", {
                    appearance: "error",
                  });
                }
              })
              .catch((err) => {
                addToast(err.response.data.error, { appearance: "error" });
              });
          }}
        >
          {({
            setFieldValue,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <NavBar
                blueBtnText={`Send`}
                navHeading="User Management"
                handleBlueBtn={handleBlueBtn}
                subHeading="User Management"
                subActiveHeading=" Invite User"
                whiteBtnText="Cancel"
                handleWhitebtn={handleWhitebtn}
                showButton={true} 
              />
              <div className="px-3 pb-3 pt-5">
                <Row className="pb-3">
                  <Col md={4}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label={"Full Name"}
                      className={`floatingLabel`}
                    >
                      <Field
                        name="fullName"
                        label="Full Name"
                        htmlFor="fullName"
                        placeholder="Full Name"
                        className="inputField form-control"
                      />
                      <ErrorMessage name={`fullName`} className="text-danger" />
                    </FloatingLabel>
                  </Col>
                  <Col md={4}>
                    <SelectionComponent
                      name="accessTypeId"
                      label="Access Type"
                      option={accessTypes}
                      //handleOnchange={(e) => handleUserChange(e, index)}
                      defaultValue={accessTypeId}
                      // onBlur={formik.handleBlur}
                      handleOnchange={(e) =>
                        setFieldValue("accessTypeId", e.target.value)
                      }
                    />
                    <div className="text-danger">
                                  <ErrorMessage
                                    name={`accessTypeId`}
                                  />
                                </div>
                    {/*  <FloatingLabel
                        controlId="floatingInput"
                        label="Access Type"
                        className={`floatingLabel`}
                      >
                        <Field
                          as="select"
                          name="accessTypeId"
                          label="Access Type"
                          placeholder="Access Type"
                          htmlFor="accessTypeId"
                          className="inputField form-control"
                        >
                          <option>--Select--</option>
                          {accessTypes?.map((accessType) => {
                            return (
                              <option value="{accessType.id}">
                                {accessType.name}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage
                          name={`accessTypeId`}
                          className="text-danger"
                        />
                      </FloatingLabel> */}
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md={2}>
                    {profilePicture && (
                      <>
                        <img
                          src={`https://gopinit.blob.core.windows.net/image-container/${profilePicture}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "acsolute",
                            border: "none",
                            borderRadius: "100px",
                          }}
                        />
                        <h6
                          onClick={() => handleShowImageUploadBtn(true)}
                          style={{
                            color: "#F99300",
                            // marginTop: "110px",
                            cursor: "pointer",
                          }}
                        >
                          {showImageUploadOption
                            ? "Cancel"
                            : "Update Profile Pic"}
                        </h6>
                      </>
                    )}
                  </Col>
                  <Col md={4}>
                    {showImageUploadOption && (
                      <Field
                        name="image"
                        component={MediaUpload}
                        onChange={(value) => setFieldValue("image", value)}
                        value={values.image}
                      />
                    )}
                  </Col>
                </Row>
              </div>
              {/* <Row>
                <Col md={12}>
                  <button type="submit" className="btn btn-outline-primary">
                    Submit
                  </button>
                </Col>
              </Row> */}
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};


export default EditUser;
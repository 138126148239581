import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "./TextError";

const Select = (props) => {
  const { label, name, options, defaultValue, disable, disabled, ...rest } = props;
  return (
    <>
      <div className="floatingLabel form-floating">
        <Field
          as="select"
          name={name}
          id={name}
          {...rest}
          className="select form-control "
          style={{ borderRadius: "10px" }}
          placeholder={label}
          disabled={disable}
        >
          <option value="" disabled hidden>
            Select an {label}
          </option>
          {options &&
            options.map((item) => {
              // console.log(item, "option item");
              return (
                <>
                  <option
                    key={item.id}
                    value={item.id}
                    disabled={item.name == disabled}
                  >
                    {item.name}
                  </option>
                </>
              );
            })}
        </Field>
        <label htmlFor={name}>{label}</label>
        <ErrorMessage name={name} component={TextError} />
      </div>
    </>
  );
};

export default Select;

import { Field } from "formik";
import React from "react";

const File = (props) => {
  const { label, name, ...rest } = props;
  return (
    <>
      <div className="floatingLabel form-floating">
        <label htmlFor={"floatingInput"}>{label}</label>
        <Field
          as="file"
          name={name}
          id={"floatingInput"}
          {...rest}
          className="file form-control"
        ></Field>
      </div>
    </>
  );
};

export default File;

import React from "react";
import { Modal } from "react-bootstrap";
import FilterCheckbox from "./FilterCheckbox";
import {useDispatch} from "react-redux";
import { clearAllCB } from "../../../../services/reducers/filter-reducers";
import FilterRadioButton from "./FilterRadioButton";


export const FilterModal = ({
  show, 
  applyFilters,  
  filters,
  sorting,
  clearAll,
  closeIt,
}) => {  
  const dispatch = useDispatch();
  return (
    <Modal
      show={show}
      onHide={closeIt}
      dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0" 
    >
      <Modal.Header closeButton>
        <h5 className="modaltitle">Filters</h5>{" "}
        <button className="clearAllButton" onClick={()=>dispatch(clearAllCB())}>clear All</button>
      </Modal.Header>
      <Modal.Body>
        {
          filters && filters.map((filter)=>{
            return (<div className="modalStatus mt-4">
                  <h6>{filter.title}</h6>
                  {filter.options.map((option) => {
                    return (
                      <div className="checkbox_container"> 
                        <FilterCheckbox option={option} type={filter.title} />
                      </div>
                    );
                  })}
                </div>)
          })
        }
        {
          sorting &&  <div className="modalStatus mt-4">
                  <h6>Sort By</h6>
                  {sorting.options.map((option) => {
                    return (
                      <div className="checkbox_container">
                        <FilterRadioButton option={option} type="sorting" name="sortBy" />
                      </div>
                    );
                  })}
                </div>
        } 
      </Modal.Body>
      <Modal.Footer>
        <div className="applyFilter">
          <button
            className="blueBgButton applyFilterBtn py-3 px-5"
            onClick={applyFilters}>
            Apply
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

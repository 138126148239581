import React, { useState } from 'react';
import {Stack} from "react-bootstrap"  
import { FiSend } from 'react-icons/fi';

const ChatInput = (props) => {
    const [user, setUser] = useState('');
    const [message, setMessage] = useState('');
    const onSubmit = (e) => {
        e.preventDefault();
        const isMessageProvided = message && message !== '';

        if (isMessageProvided) {
            props.sendMessage(message);
            setMessage('')
        } 
        else {
            alert('Please insert an user and a message.');
        }
    }

    const onUserUpdate = (e) => {
        setUser(e.target.value);
    }

    const onMessageUpdate = (e) => {
        setMessage(e.target.value);
    }

 

    return (
        <>
            <form onSubmit={onSubmit}>
                <Stack direction="horizontal" gap={2}>
                    <textarea 
                        style={{height:"24px"}}
                        type="text"
                        placeholder="Type your message here..."
                        autofocus 
                        defaultValue={""}
                        onChange={onMessageUpdate}
                        value={message}
                        onKeyPress={(e) => e.key === "Enter" && onSubmit(e)}
                          />                     
                    {/*  <UploadLogo/> */}
                    {/*   <UploadPlus /> */}               
                    <button><FiSend/></button>
                </Stack>
            </form>
        
         
        </>
    )
};

export default ChatInput;
import React from "react";
import { useLocation } from "react-router";
import { GotoSVG } from "../../../../../../../../../assets/svgComponents/inventorySVG";

const BookingDetails = ({ bookingDetails }) => {
  return (
    <>
      <div className="bookigDetailsSection pt-32 pb-32 p-20">
        <h6 className="mb-32">Booking Details</h6>
        <div className="mb-32">
          <p>FEATURED LISTING FOR</p>
          <span className="spantitle">{bookingDetails?.name}</span>
          {/* <GotoSVG /> */}
        </div>
        <p>DURATION</p>
        <span className="spantitle">
          {bookingDetails?.start_date}-{bookingDetails?.end_date}
        </span>
      </div>
    </>
  );
};

export default BookingDetails;

import React from "react";
import Providers from "./Providers";
import Users from "./Users";
import Advertisers from "./Advertisers";

export const SwitchPage = ({
  activeTab,
  userData,
  handleEditGridCell,
  handleDeleteGridCell,
  handlePerRowsChange,
  handlePageChange,
  currentPage,
  pageSize,
  loading,
  handleSort,
  markActiveInactive,
  searchString,
  statusFilter,
  loadExportData
}) => {
  switch (activeTab) {
    case "SYSTEM USERS":
      return (
        <Users
          userData={userData}
          handleEditGridCell={handleEditGridCell}
          handleDeleteGridCell={handleDeleteGridCell}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          handleSort={handleSort}
          markActiveInactive={markActiveInactive}
          loadExportData={loadExportData}
        />
      );
    case "PROVIDERS":
      return (
        <Providers
          userData={userData}
          handleEditGridCell={handleEditGridCell}
          handleDeleteGridCell={handleDeleteGridCell}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          handleSort={handleSort}
          markActiveInactive={markActiveInactive}
          searchString={searchString}
          statusFilter={statusFilter}
          loadExportData={loadExportData}
        />
      );
    case "ADVERTISERS":
      return (
        <Advertisers
          userData={userData}
          handleEditGridCell={handleEditGridCell}
          handleDeleteGridCell={handleDeleteGridCell}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          handleSort={handleSort}
          markActiveInactive={markActiveInactive}
          loadExportData={loadExportData}
        />
      );
    default:
      return <></>;
  }
};

import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import Grid from "../../../../../../../../components/grid/Grid";
import {
  getSelectionType,
  getSelectSign,
} from "../../../../../../../../services/Provider/Configurator/Listings";
import TableActions from "../../../components/TableActions";
import ProjectContext from "../../../../../../../../context/ProjectContext";
import EditSignDetailsPage from "./CreateListingSignsDetails";
import ConfirmModal from "../../../../../../../../components/confirmModal/ConfirmModal";
import { InputField } from "../../../../../../../../components/InputField";
import NavBar from "../../../../../../../../components/navBar";
import { NotificationSVG } from "../../../../../../../../assets/svgComponents/table";
import { CustomSort } from "../../../../../../../../components/DataTable/CustomSort";
import FormikController from "../../../../../../../../components/Formik/FormikController";

const notiHeading = ["id", "Media", "Site Name", "Dimension", "Width", "Height"];
const accessorName = ["checkBox", "id", "mediaType", "name", "dimension", "width", "height"];

const columns = [
  {
    name: "id".toUpperCase(),
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "media".toUpperCase(),
    selector: (row) => row.mediaType,
    sortable: true,
  },
  {
    name: "Sign name".toUpperCase(),
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "dimension".toUpperCase(),
    selector: (row) => row.dimension,
    sortable: true,
  },
  {
    name: "width".toUpperCase(),
    selector: (row) => row.width,
    sortable: true,
  },
  {
    name: "height".toUpperCase(),
    selector: (row) => row.height,
    sortable: true,
  },
];

const SelectSignsPage = ({
  setNavBarBtn,
  activeTab,
  sideTabs,
  setActiveTab,
  numberOfSigns,
  setNumberOfSigns,
  selectedSign,
  setSelectedSign,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  const [signData, setSignData] = useState();
  const [loader, setLoader] = useState(false);
  const [addSignModal, setAddSignModal] = useState(false);
  const { handleCreateListing, createListing, selectedEntities } =
    useContext(ProjectContext);
  const [addSigns, setAddSigns] = useState(null);
  const [signIds, setSignIds] = useState([]);
  const [isEditSignDOM, setIsEditSignDOM] = useState(false);
  const [directionFacingOptions, setDirectionFacingOptions] = useState();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedSigns, setSelectedSigns] = useState([]);

  useEffect(async () => {
    await getSelectionType("SignDirectionFacing")
      .then((res) => {
        if (res.status == 200) {
          setDirectionFacingOptions(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  //   fetch site listing data
  useEffect(async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: "",
      export: true,
      searchString: filterText,
    };
    setLoader(true);
    await getSelectSign(reqBody)
      .then((res) => {
        if (res.status == 200) {

          setLoader(false);
          // res.data?.records.forEach((item) => (item.isChecked = false));
          // console.log(res.data, "res service");
          res.data.data.records.map((elem) => (elem.isChecked = false));
          location?.isUpdateListing &&
            res?.data.data.records.map(
              (elem, i) => {
                //elem.id == location.data.serviceIds[i] && (elem.isChecked = true)
                location.data.signIds.filter(element => {
                  element == elem.id &&
                    (elem.isChecked = true)
                });
              });
          // console.log(res.data, "res.dataa");
          setSignData(res?.data?.data?.records);
          //setServicesdata


          if (location?.isUpdateListing) {
            let tempData1 = res?.data.data.records.filter((r) => r.isChecked);
            let tempSelectedRow = tempData1.map((item) => ({
              signId: item.id,
              name: item.name,
            }));
            setSelectedSigns(() => tempSelectedRow);
          }



          // setSignData(res.data?.data?.records);
          // selectedEntities.forEach(
          //   (item) => item.name == "Signs" && (item.isCurrentTitle = true)
          // );



        }
      })
      .catch((err) => console.log(err));
  }, [filterText]);

  const handleSelectSignsRow = (row) => {
    // item.selectedRows.map((i)=>{
    //   setSignIds([...signIds, i]);
    // })

    //setSignIds(row.selectedRows);

    handleCreateListing({ ...createListing, signIds: row.id, signInfo:{id:row.id,name:row.name, sizeText:row.sizeText} });
    setAddSignModal(true);
    setSelectedSign(row);

    /*

    let tempRowSelected = [...signData];
    tempRowSelected.forEach(
      (r) => r.id == row.id && (r.isChecked = !r.isChecked)
    );
    let tempData1 = tempRowSelected.filter((r) => r.isChecked);
    let tempRow = tempData1.map((item) => ({
      signId: item.id,
      name: item.name,
    }));
    setSelectedSign(tempRow);

    setSignData((prev) => ({ ...prev, records: tempRowSelected }));

    */

  };

  const handleChecked = (row) => {
    // console.log(row, "row");
    // console.log(servicesdata, "servicesdata");
    let tempRowSelected = [...signData.records];
    tempRowSelected.forEach(
      (r) => r.id == row.id && (r.isChecked = !r.isChecked)
    );
    let tempData1 = tempRowSelected.filter((r) => r.isChecked);
    let tempRow = tempData1.map((item) => ({
      signId: item.id,
      name: item.name,
    }));
    setSelectedSigns(tempRow);
    let tempRowId = tempData1.map((item) => (item.id));
    setSignIds(tempRowId);

    setSignData((prev) => ({ ...prev, records: tempRowSelected }));
    // console.log(tempRow);
  };

  const handleAddSignsModal = () => {
    if (!addSigns == null || !addSigns == undefined || !addSigns == "") {
      createListing.addSigns = addSigns;

      setAddSignModal(false);
      setIsEditSignDOM(true);

      setNavBarBtn({
        whiteBtnText: "Cancel",
        blueBtnText: "Next",
      });
    }
  };

  const handleWhiteBtn = () => {
    history.push("/provider/configurator/listings");
  };

  const handleBlueBtn = (activeTab) => {
    // let tempSide = sideTabs.findIndex((elem) => elem.name == activeTab);
    // setActiveTab(sideTabs[tempSide + 1].name);
    if (location.isUpdateListing) {
      let tempData1 = signData.records.filter((r) => r.isChecked);
      let tempSelectedRow = tempData1.map((item) => ({
        signId: item.id,
        name: item.name,
      }));
      let tempSelectedRowId = tempData1.map((item) => (item.id));
      console.log(tempSelectedRow, "tempSelectedRow");
      setSelectedSigns(() => tempSelectedRow);
    }
    if (selectedSigns.length > 0) {
      // signIds.map((item) => (item.summaryChargesType = 0));


      handleCreateListing({ ...createListing, signIds: signIds, signs: selectedSigns });

      setSelectedSigns(selectedSigns);
      /*
      let tempSide = sideTabs.findIndex((elem) => elem.name == activeTab);
      let tempSide2 = [...sideTabs];
      tempSide2.forEach(
        (elem) => elem.name == activeTab && (elem.isActive = true)
      );
      //setSideTabs(tempSide2);
      setActiveTab(sideTabs[tempSide + 1].name);

      */

      setAddSignModal(true);

    } else {
      addToast("Please select atleast one sign from list", {
        appearance: "warning",
      });
    }

  };

  const handleChangeSign = () => {
    setIsEditSignDOM(false);
  };

  const handleAllChecked = (row) => {
    let tempRowSelected = [...signData.records];
    tempRowSelected.forEach((r) => (r.isChecked = !r.isChecked));
    setSignData((prev) => ({ ...prev, records: tempRowSelected }));
    // let tempData = servicesdata;
    // tempData?.records?.forEach((item) => {
    //   item.isChecked = !item.isChecked;
    // });
    // setServicesdata(tempData);
  };

  console.log("location",location)
  const initialValues = {
    numberOfSign: location?.data?.numberofSigns
      ? location?.data.numberofSigns
      : null,
  };
  const validationSchema = Yup.object({
    numberOfSign: Yup.number().typeError('Please enter number of faces to be included').required(
      "Please enter number of faces to be included"
    ),
  });
  const handleFormikOnSubmit = (values) => {
    setNumberOfSigns(values.numberOfSign);
    setSelectedSigns(createListing.signs);
    // console.log(values.numberOfSign, "values.");
    handleCreateListing({
      ...createListing,
      numberOfSign: values.numberOfSign,
    });
    setNavBarBtn({
      whiteBtnText: "Cancel",
      blueBtnText: "Next",
    });

    setActiveTab("EditSigns");
    // setIsEditSignDOM(true);
    setAddSignModal(false);
  };

  const handleSubmitSiteDetails = (val) => {
    console.log(val);
    let tempData = {};
  };

  const filteredItems = signData?.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.id && item.id.toLowerCase().includes(filterText.toLowerCase()))
  );

  // console.log(filteredItems, "filteredItems");

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <TableActions
          title="Select Signs"
          isInput={true}
          onSearchChange={(e) => setFilterText(e.target.value)}
        // isFilterBtn={true}
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const conditionalRowStyles = [
    {
      when: (row) => row.id == location?.data?.signIds[0],
      style: {
        backgroundColor: "#FFF5E5",
      },
    },
  ];

  return (
    <>
      <div className="w-100">
        <NavBar
          whiteBtnText={"Cancel"}
          notificationSVG={<NotificationSVG />}
          navHeading={createListing.name}
          subHeading="All Billable Item "
          subActiveHeading="New Billable Item"
          handleWhitebtn={() => handleWhiteBtn(activeTab)}
          blueBtnText={"Next"}
          handleBlueBtn={() => handleBlueBtn(activeTab)}
          showButton={true}
        />
        <div className="gridSection p-20">
          <div className="d-flex h-100">
            <div>
              <ul className=" progressbar">
                {sideTabs?.map((tab) => (
                  <li
                    className={`billable_switchSideDom_item ${tab.isActive && "active"
                      }
                    ${tab.isCurrentTitle && "activeTabTitle"}
                   `}
                  >
                    {tab.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="d-flex flex-column h-100 flex-grow-1">
              {isEditSignDOM ? (
                <div className="pt-4">
                  <EditSignDetailsPage
                    handleChangeSign={handleChangeSign}
                    directionFacingOptions={directionFacingOptions}
                    numberOfSigns={numberOfSigns}
                    selectedSign={selectedSign}
                    handleSubmitSiteDetails={handleSubmitSiteDetails}
                  />
                </div>
              ) : (
                <>
                  {subHeaderComponentMemo}
                  {/* <TableActions
                  title="Select Signs"
                  isInput={true}
                  // isFilterBtn={true}
                /> */}
                  <div className="card customtable mt-3">
                    {/* <Grid
                      isCheckBox
                      tableHeading={notiHeading}
                      tableBody={signData && signData}
                      accessorName={accessorName}
                      handleAllChecked={handleAllChecked}
                      handleChecked={handleChecked}
                    /> */}
                    <DataTable
                      fixedHeader
                      progressPending={loader}
                      columns={columns}
                      data={filteredItems && filteredItems}
                      sortFunction={<CustomSort />}
                      allowOverflow={true}
                      onRowClicked={handleSelectSignsRow}
                      conditionalRowStyles={location?.isUpdateListing && conditionalRowStyles}
                      highlightOnHover
                      pointerOnHover
                      //selectableRows={signData}
                      pagination
                      //onSelectedRowsChange={(row) => handleSelectSignsRow(row)}
                      //selectableRowsHighlight
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {addSignModal && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleFormikOnSubmit(values)}
        >
          <Form>
            <ConfirmModal
              isModalHeader={true}
              setAddSignModal={setAddSignModal}
              confirm={handleAddSignsModal}
              handleClose={() => setAddSignModal(false)}
              title="Add Faces"
              confirmText="Proceed"
              cancelText="Cancel"
              isFooterBtns={true}
              type="submit"
            >
              <div className="pb-3 ">
                <FormikController
                  control="input"
                  type="number"
                  label="Enter number of faces to be included in one sign"
                  name="numberOfSign"
                />
                {/* <InputField
                  placeholder="Enter number of signs to be included"
                  label="Enter number of signs to be included"
                  onChange={(e) => setAddSigns(e.target.value)}
                /> */}
              </div>
            </ConfirmModal>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default SelectSignsPage;

import React, { useContext, useState } from "react";
import {
  NotificationSVG,
  PlusSVG,
} from "../../../../../assets/svgComponents/table";
import NavBar from "../../../../../components/navBar";
import TableActions from "../Inventory/components/TableActions";
import SwitchListingTab from "./SwitchTab";
import { Modal } from "react-bootstrap";
import NewListing from "./modal/NewListing";
import { useEffect } from "react";
import {
  bundleListings,
  signListings,
  siteListings,
} from "../../../../../services/Provider/Configurator/Listings";
import { useToasts } from "react-toast-notifications";
import { useHistory, useLocation } from "react-router";
import ProjectContext from "../../../../../context/ProjectContext";
import UpdatesModal from "../../../../../components/profile/Modal/UpdatesModal";
import { checkPermission } from "../../../../../utils";

const tabs = ["ALL", "LOCATIONS", "SIGNS", "BUNDLE"];
const billableTabs = [
  "Locations",
  "Signs",
  "Services",
  "Charges",
  "Configurations",
];
const Listings = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();
  const {
    handleSelectedEntities,
    selectedEntities,
    createListing,
    handleCreateListing,
    updateListing,
    handleUpdateListing,
  } = useContext(ProjectContext);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [activeBillableTab, setActiveBillableTab] = useState(billableTabs[1]);
  const [newListingModal, setNewListingModal] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState();
  const [newListingEntities, setNewListingEntities] = useState({
    sites: false,
    signs: false,
    services: false,
    charges: false,
  });

  const [isSiteDetails, setIsSiteDetails] = useState(false);
  const [listingName, setListingName] = useState();
  const [isListingNameChar, setIsListingNameChar] = useState(false);
  const [isTouched, setIsTouched] = useState({
    name: false,
    entitie: false,
  });
  const [searchTerm, SetSearchTerm] = useState("");
  const [searchToggle, SetSearchToggle] = useState(false);
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);
  const onBoardingStepDone = localStorage.getItem("onBoardingStepDone");

  // handle for selecting new list entities
  const handleSelectListing = (item) => {
    // debugger;
    // console.log(item.name);
    let tempSelected = [...selectedEntities];
    tempSelected.map((entitie) => {
       //console.log(entitie.name, "entity");
      if (entitie.name == item.name) {
        if (item.name == "Services") {
          if (tempSelected[0].isChecked || tempSelected[1].isChecked) {
            entitie.isChecked = !item.isChecked;
          }
        } else if (item.name == "Charges") {
          if (tempSelected[0].isChecked || tempSelected[1].isChecked) {
            entitie.isChecked = !item.isChecked;
          }
        } else {
          entitie.isChecked = !item.isChecked;
        }
      }
    });
    //console.log(tempSelected, "tempSelected");
    handleSelectedEntities(tempSelected);
  };

  const onChangeListName = (e) => {
    setListingName(e.target.value);
  };

  // handle for create new listing modal
  const handleCreateNewListing = () => {
    setListingName();
    let tempEntities = [...selectedEntities];
    tempEntities.forEach((item) => {
      item.isActive = false;
      item.isChecked = false;
      item.isCurrentTitle = false;
    });
    tempEntities.forEach((item) => {
      item.name == "Configurations" && (item.isChecked = true);
    });
    console.log(tempEntities, "tempEntities");
    handleSelectedEntities(tempEntities);
    // if (listingName == null) {
    setNewListingModal(true);
    // }
  };

  // handle for proceed selected
  const handleProceed = () => {
    //console.log(selectedEntities, "selectedEntities");
    let tempEntities = selectedEntities.filter((elem) => elem.isChecked);
    if (listingName && listingName.length >= 5 && tempEntities.length >= 2) {
      let tempData = selectedEntities.find((item) => item.isChecked);
      let tempSelected = [...selectedEntities];
      tempSelected.map((item) => {
        if (item.name == tempData.name) {
          item.isActive = true;
        }
      });
      //console.log(listingName, "listingName");
      handleCreateListing({ name: listingName });
      handleSelectedEntities(tempSelected);
      // console.log(tempData, "tempData");
      // console.log(selectedEntities, "selectedEntities");
      if (!isUpdateData) {
        history.push({
          pathname: `${location.pathname}/new-billable-item`,
          isUpdateListing: false,
        });
      } else {
        history.push({
          pathname: `${location.pathname}/new-billable-item`,
          isUpdateListing: true,
          data: isUpdateData,
        });
      }
    } else {
      if (!listingName) {
        setIsTouched((prev) => ({ ...prev, name: true }));
      } else if (listingName.length <= 5) {
        setIsListingNameChar(true);
      } else {
        setIsTouched((prev) => ({ ...prev, name: false }));
      }

      // console.log(tempEntities.length < 2);
      if (tempEntities.length < 2) {
        setIsTouched((prev) => ({ ...prev, entitie: true }));
      } else {
        setIsTouched((prev) => ({ ...prev, entitie: false }));
      }
    }
  };
  const handleSearchTextChange = (e) => {
    SetSearchTerm(e.target.value);
  };
  const handleSearchClick = () => {
    SetSearchToggle(!searchToggle);
  };
  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <>
      {isSiteDetails ? (
        <NavBar
          blueBtnText={`Edit`}
          notificationSVG={<NotificationSVG />}
          navHeading="#B21 - Item 1"
          subHeading="Home • Sites • Bayward Market Square Area • Booking Details "
          // subActiveHeading=""
          isInputField={false}
          searchBarlabel={"Search an item"}
          // handleBlueBtn={handleCreateNewListing}
          searchTerm={searchTerm}
          handleSearchTextChange={handleSearchTextChange}
          handleSearchClick={handleSearchClick}
          handleNotificationSVG={handleNotificationSVG}
          showButton={checkPermission("Listings", "Full")}
        />
      ) : (
        <NavBar
          blueBtnText={`New`}
          blueBtnSVG={<PlusSVG />}
          notificationSVG={<NotificationSVG />}
          navHeading="Listings"
          subHeading="Configurator "
          subActiveHeading="Listings"
          isInputField={false}
          searchBarlabel={"Search an item"}
          handleBlueBtn={handleCreateNewListing}
          searchTerm={searchTerm}
          handleSearchTextChange={handleSearchTextChange}
          handleSearchClick={handleSearchClick}
          handleNotificationSVG={handleNotificationSVG}
          showButton={checkPermission("Listings", "Full")}
        />
      )}
      <div className="p-20 gridSection">
        {!isSiteDetails && (
          <TableActions
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        <div className="card mt-4 overflow-hidden customtable">
          <SwitchListingTab
            activeTab={activeTab}
            isSiteDetails={isSiteDetails}
            setIsSiteDetails={setIsSiteDetails}
            setNewListingModal={setNewListingModal}
            setListingName={setListingName}
            setIsUpdateData={setIsUpdateData}
            searchTerm={searchTerm}
            searchToggle={searchToggle}
          />
        </div>
      </div>

      {/* new listing modal  */}
      <Modal
        show={newListingModal}
        onHide={() => {
          setNewListingModal(false);
          handleUpdateListing({});
        }}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <NewListing
          newListingEntities={newListingEntities}
          setNewListingEntities={setNewListingEntities}
          handleProceed={handleProceed}
          listingName={listingName}
          onChangeListName={onChangeListName}
          isTouched={isTouched}
          isListingNameChar={isListingNameChar}
          handleSelectListing={handleSelectListing}
          selectedEntities={selectedEntities}
          setNewListingModal={setNewListingModal}
        />
      </Modal>
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

export default Listings;

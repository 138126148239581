import React, { useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import mapStyles from "./MapStyles";
import { useEffect } from "react";
import { getImageUrl } from "../../services/utility";
import { useHistory } from "react-router-dom";
import {
  SVGAdd,
  SVGStarRating,
  SVGFavorite,
  SVGNotFavorite,
  SVGFavoriteGray,
  SVGFeatured,
  SVGMedia,
  InfoSVG,
} from "../../assets/svgComponents/svg";
import { useDispatch, useSelector } from "react-redux";
import {
  markAsFavoriteAsync,
  markAsUnFavoriteAsync, setPortableSignLocation, getLocationNameAsync
} from "../../services/reducers/listing-reducer";

import {
  faPlane,
  faCalendarCheck,
  faBusSimple,
  faMapMarker,
  faBuilding,
  faShoppingCart,
  faPersonBiking,
  faSignsPost,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import markerImg1 from "../../assets/images/markerImg1.png";
import markerImg2 from "../../assets/images/markerImg2.png";
import markerImg3 from "../../assets/images/markerImg3.png";
import markerImg4 from "../../assets/images/markerImg4.png";
import markerImg5 from "../../assets/images/markerImg5.png";
import { MapListingDetailsCard } from "./MapListingDetailsCard";

let libraries = ["places"];
const mapContainerStyle = {
  width: "36vw",
  height: "100vh",
};
const center = {
  lat: 43.653225,
  lng: -79.383186,
};
const options = {
  styles: mapStyles,
};

export const GoPinitMapsSites = ({
  page,
  markers,
  currentLocation,
  onMapDragged,
  setLat,
  setLng,
  setZoom,
  setDistance,
  handleDetailpage,
  initPortableSignBooking,
  handlePinItForPortableSign,
  handelSearchNewLocation,
  listingType,
}) => {

  const { portableSignInfo, signLocationName } = useSelector((state) => state.listing);

  const history = useHistory();
  const dispatch = useDispatch();
  if (page === "customerBySigns") {
  }
  if (currentLocation.lat == null || currentLocation.lng == null) {
    currentLocation.lat = 43.653225;
    currentLocation.lng = -79.383186;
  }
  const [mapPosition, setMapPosition] = useState(null);

  useEffect(() => {
    if (initPortableSignBooking) {
      setPortableSignLoc({ lat: currentLocation.lat, lng: currentLocation.lng });
      setPortableSignInfoVisible(true);
    } else {
      setPortableSignInfoVisible(false);
      setPortableSignLoc(null);
    }
  }, [initPortableSignBooking])

  useEffect(() => {
    setMapPosition(currentLocation);
  }, [currentLocation]);
  const [map, setMap] = React.useState(null);
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAKiVYj0Ytig8vYJS4ViTya12yJltyVo54",
    // libraries,
  });
  const [selPage, setSelPage] = useState(page);
  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [portableSignMarker, setPortableSignMarker] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);
  const [portableSignLoc, setPortableSignLoc] = useState(null);
  const [portableSignInfoVisible, setPortableSignInfoVisible] = useState(false);
  //const [environment, setEnvironment] = useState("retail");

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (loadError) return "Error Loading Maps";
  if (!isLoaded) return "Loading Maps";

  const handleMapMoved = () => {
    if(map!=null){
      setDistance(getDistance());
    }
    
    const loc = map?.getCenter();
    if (loc) {
      const coords = { lat: loc.lat(), lng: loc.lng() };
      console.log(coords);
      setLat(loc.lat());
      setLng(loc.lng());
      setMapPosition(coords);
    }
  };

  const handleZoom = () => {
    if(map){
      setDistance(getDistance());
      setZoom(map?.getZoom());
    }        
  };

  const getDistance = () => {
    var ne = map.getBounds().getNorthEast();
    var center = map.getCenter();

    var r = 3958.0;
    var lat1= center.lat() / 57.2958;
    var lon1= center.lng() / 57.2958;
    var lat2= ne.lat() / 57.2958;
    var lon2= ne.lng() / 57.2958;

    var dis = r * Math.acos(Math.sin(lat1)* Math.sin(lat2) + Math.cos(lat1)* Math.cos(lat2) * Math.cos(lon2-lon1));    
    return dis * (1609.34);
  }


  const environments = {
    airport: {
      path: faPlane.icon[4],
      fillColor: "#0000ff",
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: "#ffffff",
      scale: 0.04,
    },
    retail: {
      path: faShoppingCart.icon[4],
      fillColor: "#0000ff",
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: "#ffffff",
      scale: 0.04,
    },
    privateBuilding: {
      path: faBuilding.icon[4],
      fillColor: "#f99300",
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: "#ffffff",
      scale: 0.04,
    },
    vehicular: {
      path: faPersonBiking.icon[4],
      fillColor: "#0000ff",
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: "#ffffff",
      scale: 0.04,
    },
    roadSite: {
      path: faBusSimple.icon[4],
      fillColor: "#ffd700",
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: "#ffffff",
      scale: 0.04,
    },
    eventCenter: {
      path: faCalendarCheck.icon[4],
      fillColor: "#0000ff",
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: "#ffffff",
      scale: 0.04,
    },
    marker1: markerImg1,
    marker2: markerImg2,
    marker3: markerImg3,
    marker4: markerImg4,
    marker5: markerImg5,
    sign: {
      path: faSignsPost.icon[4],
      fillColor: "orange",
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: "#ffffff",
      scale: 0.06,
    },
  };




  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={
          currentLocation && currentLocation.zoom ? currentLocation.zoom : 8
        }
        center={mapPosition}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onZoomChanged={handleZoom}
        onDragEnd={handleMapMoved} 
        onClick={(e) => {  
          setPortableSignInfoVisible(false);
          setSelectedElement(-1);
          //infowindow.close();
          // if(listingType==="sign"){ 
          //   let loc = {lat:e.latLng.lat(),lng:e.latLng.lng()};
          //   setPortableSignLoc(loc); 
          //   dispatch(setPortableSignLocation(loc));
          //   dispatch(getLocationNameAsync({ lat: loc.lat, lng:loc.lng, locationType:'sign'}));  
          // }
        }}
        options={{
          fullscreenControl: false,
          mapTypeControl: true,
          fullscreenControlOptions: false,
          streetViewControl: false,
        }}
      >
        <>
          {portableSignLoc && (
            <Marker key={"portableSignMarker"} position={portableSignLoc} icon={environments.sign}
              onClick={(marker) => {
                setPortableSignMarker(marker);
                setPortableSignInfoVisible(true);
              }}
            >
              {portableSignInfoVisible == true && (
                <InfoWindow
                  key={"portableSignMarker_info"}
                  visible={portableSignInfoVisible}
                  position={portableSignLoc}
                  marker={portableSignMarker}
                  onCloseClick={() => {
                    setPortableSignInfoVisible(false);
                  }}
                >
                  <>
                    <div className="text-start">
                      {portableSignInfo && (
                        <>
                          <span>Portable Sign Location</span>
                          <div>
                            <p style={{ paddingBottom: "2px", fontWeight: 'bolder' }}>
                              {portableSignInfo.address}
                            </p>
                            <br />
                            <button
                              className="btn btn-outline-primary btn-sm"
                              style={{ padding: ".25rem .5rem !important" }}
                              type="submit"
                              onClick={() => handlePinItForPortableSign(portableSignLoc)}
                            >
                              Change Details
                            </button>
                          </div>
                        </>
                      )}
                      {!portableSignInfo && (
                        <>
                          <span>Pin Sign Location</span>
                          <div>
                            <p style={{ paddingBottom: "2px", fontWeight: 'bolder' }}>
                              Would you like to pin this location for portable sign?
                            </p>
                            <br />
                            <button
                              className="btn btn-outline-primary btn-sm"
                              style={{ padding: ".25rem .5rem !important" }}
                              type="submit"
                              onClick={() => handlePinItForPortableSign(portableSignLoc)}
                            >
                              Go Pin It
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                </InfoWindow>
              )}

            </Marker>
          )}
          {markers?.map((site, index) =>
            site.latitude && site.longitude ? (
              <Marker
                key={index}
                position={{ lat: site.latitude, lng: site.longitude }}
                onClick={(marker) => {
                  setSelectedElement(index);
                  setActiveMarker(marker);
                }}
                icon={(() => {
                  if (site.environment === "Airport") {
                    return environments.airport;
                  } else if (site.environment === "Retail") {
                    return environments.retail;
                  } else if (site.environment === "Private Building") {
                    return environments.privateBuilding;
                  } else if (site.environment === "Vehicular") {
                    return environments.vehicular;
                  } else if (site.environment === "Roadside") {
                    return environments.roadSite;
                  } else if (site.environment === "Event Centre") {
                    return environments.eventCenter;
                  } else if(site.mediaType === 1) {
                    return environments.marker2;
                  } else if(site.mediaType === 2 || site.mediaType === 3 || site.mediaType === 4) {
                    return environments.marker3;
                  } else if(site.mediaType === 5 || site.mediaType === 6 || site.mediaType === 7) {
                    return environments.marker4;
                  } else if(site.mediaType === 8 || site.mediaType === 9 || site.mediaType === 10) {
                    return environments.marker5;
                  } else {
                    return environments.marker1;
                  } 
                })()}
              >
                {selectedElement == index ? (
                  <InfoWindow
                    key={index}
                    visible={showInfoWindow}
                    marker={activeMarker}
                    onCloseClick={() => {
                      setSelectedElement(null);
                    }}
                  >
                    <MapListingDetailsCard page={page} cardDetails={site} handleDetailpage={handleDetailpage} />
                  </InfoWindow>
                ) : null}
              </Marker>
            ) : null
          )}
        </>
      </GoogleMap>
    </>
  );
};

import React from "react";
import Inventory from "./Inventory/index";
import { Switch, Route } from "react-router-dom";
import { InventoryRoutes } from "./Inventory/InventoryRoutes";
import { ListingRoutes } from "./Listings/Routes";
import { OfferManagement } from "./OfferManagement/OfferManagement";
import { OfferManagementRoutes } from "./OfferManagement/OfferManagementRoutes";

export const ConfiguratorRoutes = ({ ...rest }) => {
  return (
    <Switch>
      <Route
        path={`${rest.match.url}/inventory`}
        render={(props) => <InventoryRoutes {...props} />}
      />
      <Route
        path={`${rest.match.url}/listings`}
        render={(props) => <ListingRoutes {...props} />}
      />
      <Route
        path={`${rest.match.url}/offer-management`}
        render={(props) => <OfferManagementRoutes {...props} />}
      />
    </Switch>
  );
};

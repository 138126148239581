import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

const Input = (props) => {
  const { label, name, ...rest } = props;
  return (
    <>
      <div className="floatingLabel form-floating">
        <Field
          id={"floatingInput"}
          name={name}
          placeholder={label}
          {...rest}
          className="inputField form-control"
        />
        <label htmlFor={"floatingInput"}>{label}</label>
        <ErrorMessage name={name} component={TextError} />
      </div>
    </>
  );
};

export default Input;

import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  

export const BarChart = ({graphDataTitles, graphData, bgColor, hoverBgColor}) => {
    //console.log(graphDataTitles, graphData, 'Bar Chart');        
    
    const options = {
        responsive: true,
        plugins: {
        legend: {
            position: "top",
            display: false,
        },
        title: {
            display: false,
            text: 'chartName',            
        },
        },
    };

   
  
    
    const labels = graphDataTitles;
    const barData = graphData;
    

    /*
    const min = 1;
    const max = 1000;    
    for(let i = 1; i<=31; i++){
        labels.push(i);        
        const rand  = min + Math.random() * (max - min);
        barData.push(rand);
    }
    
   console.log(labels,'labels');
   console.log(barData,'bar data');
   */
   
   const data = {
        labels,    
        datasets: [
            {
                label: 'Sales',
                data: barData,
                backgroundColor: bgColor,
                borderRadius: 15,
                hoverBackgroundColor: hoverBgColor, 
                plugins: {
                    legend: {
                        display: true
                    }
                }               

            }
        ],
    };
  

    return (
        <>            
            <Bar options={options} data={data} className="mb-5" />
        </>
    );
};
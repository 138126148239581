import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { BtnSwitch } from '../../../../../components/BtnSwitch/BtnSwitch';

const Tabs = (props) => {
    return (
        <>
            <div className="tableActions_container">
                <Row className="g-0">
                    <Col className="col-7">
                        <ul className="tableActions_tabs">
                            {props.tabs.map((item, index) =>
                                item === props.activeTab ? (
                                    <li
                                        key={index}
                                        className="tableActions_tab active"
                                        onClick={() => props.setActiveTab(item)}
                                    >
                                        {item} <hr className="tableActions_tab_hr" />
                                    </li>
                                ) : (
                                    <li
                                        key={index}
                                        className="tableActions_tab"
                                        onClick={() => props.setActiveTab(item)}
                                    >
                                        {item}
                                    </li>
                                )
                            )}
                        </ul>
                    </Col>

                    {props.activeTab != "BY PROVIDERS" && (
                        <Col className="col-5 text-end px-0">
                            <Form.Group className="me-5 pe-3">
                                <Form.Label className="textGray3 text-xs fw-bold">
                                    Only Show Featured&nbsp;
                                </Form.Label>
                                <Form.Label className="textGray3 text-xs fw-bold">
                                    <BtnSwitch
                                        status={props.featured}
                                        action={() =>
                                            props.setFeatured(!props.featured)
                                        }
                                        className="textGray3"
                                        disable={false}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    )}
                </Row>
            </div>
        </>
    );
};

export default Tabs;
import React, {useEffect, useState} from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  HelpIcon,
  LogoutIcon,
  OrderIcon,
  UserMgmtIcon,
  ProfileIcon,
  SettingsIcon
} from "../../assets/svgComponents/inventorySVG";
import { GLogo } from "../../assets/svgComponents/inventorySVG";
import {
  SVGHome,
  SVGReports,
  SVGNotFavorite,Star,SVGStarRating,StarSVG,
} from "../../assets/svgComponents/svg";

const AdminSideNav = (props) => {
  
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [mouseOnLogo,setMouseOnLogo] = useState(false);
  const [accessModules, setAccessModules] = useState([]);
  useEffect(() => {
    let am = localStorage.getItem("accessModules");
    if(am!="undefined"){
    setAccessModules(JSON.parse(am))
    }
  }, []);
  const handleNavHoverOn = () => {
    setMouseOnLogo(true); 
    const WindowWidth = window.outerWidth;
    if (WindowWidth > 1199) {
      props.setOpenNav(true);
    }
  };
  const handleNavHoverOff = () => {
    setMouseOnLogo(false); 
    const WindowWidth = window.outerWidth;
    if (WindowWidth > 1199) {
      props.setOpenNav(false);
    }
  };
  const ifCanAccess = (claimtype) =>{
    if(accessModules.length>0){
      const skac = accessModules.filter(m=>m.Type===claimtype && m.Value!=='None');
      return skac.length>0;
      }
      else{
        return true;
      }
  }
  return (
    <>
      <div onMouseEnter={handleNavHoverOn} onMouseLeave={handleNavHoverOff}>
      {/* <div> */}
        <Row className="sidebar_main g-0 d-block">
          <Col className="sidebar_col01">
          <div className={ mouseOnLogo ? "sidebar_logo_main sidebar_align gopinit_logo_hover" : "sidebar_logo_main sidebar_align" }>
              {/* <Link to="/admin" className="sidebar_link"> */}
                {/* <span> */}
                  <GLogo />
                {/* </span> */}
              {/* </Link> */}
              {props.openNav ? <h3 className="m-0"> GoPinIt </h3> : ""}
            </div>
          </Col>
          <p
              className={
                props.openNav
                  ? "sidebar_heading01 sidebar_align m-0"
                  : "sidebar_icons_heading"
              }
            >
              MENU
            </p>
          <Col className="">
            
            <div className={(props.openNav ? "sidebar_tabs" : "sidebar_icon") + (activeTab === "Dashboard" ? " sidebar_active_tab " : "")} 
            onClick={() => setActiveTab("Dashboard")}>
              <Link
                to="/admin/dashboard"
                className={props.openNav ? "sidebar_link" : ""}
              >
                <span className={(activeTab === "Dashboard" ? " sideNavIcon " : "")}>
                  <SVGHome fill={activeTab === "Dashboard" && "#F99300"}/>
                </span>
                {props.openNav ? (
                  <p className={props.openNav ? "sidebar_text" : ""}>
                    Dashboard
                  </p>
                ) : (
                  ""
                )}
              </Link>
            </div>
            {ifCanAccess('UserManagement') && (
            <div className={(props.openNav ? "sidebar_tabs" : "sidebar_icon") + (activeTab === "User Management" ? " sidebar_active_tab " : "")} 
            onClick={() => setActiveTab("User Management")}>
              <Link to="/admin/allusers" className="sidebar_link">
                <span className={(activeTab === "User Management" ? " sideNavIcon " : "")}>
                  <UserMgmtIcon fill={activeTab === "User Management" && "#F99300"}/>
                </span>
                {props.openNav ? (
                  <p className="sidebar_text">User Management</p>
                ) : (
                  ""
                )}
              </Link>
            </div>
            )}
            {/* <div className={props.openNav ? "sidebar_tabs" : "sidebar_icon"}>
              <Link to="/admin/orders" className="sidebar_link">
                <span>
                  <OrderIcon />
                </span>
                {props.openNav ? <p className="sidebar_text">Locations</p> : ""}
              </Link>
            </div> */}
            {/* <div className={props.openNav ? "sidebar_tabs" : "sidebar_icon"}>
              <Link to="/admin/orders" className="sidebar_link">
                <span>
                  <OrderIcon />
                </span>
                {props.openNav ? (
                  <p className="sidebar_text">Alert & Notifications</p>
                ) : (
                  ""
                )}
              </Link>
            </div> */}
            {ifCanAccess('ReviewsAndRatings') && (
            <div className={(props.openNav ? "sidebar_tabs" : "sidebar_icon") + (activeTab === "Reviews and Ratings" ? " sidebar_active_tab " : "")} 
            onClick={() => setActiveTab("Reviews and Ratings")}>
              <Link to="/admin/reviews-ratings" className="sidebar_link">
                <span className={(activeTab === "Reviews and Ratings" ? " sideNavIcon " : "")}>
                  <StarSVG fill={activeTab === "Reviews and Ratings" && "#F99300"}/>
                </span>
                {props.openNav ? (
                  <p className="sidebar_text">Reviews and Ratings</p>
                ) : (
                  ""
                )}
              </Link>
            </div>
            )}
            {/* <div className={props.openNav ? "sidebar_tabs" : "sidebar_icon"}>
              <Link to="/admin/notifications" className="sidebar_link">
                <span>
                  <UserMgmtIcon />
                </span>
                {props.openNav ? (
                  <p className="sidebar_text">Notifications</p>
                ) : (
                  ""
                )}
              </Link>
            </div> */}
            {/* <div className={props.openNav ? "sidebar_tabs" : "sidebar_icon"}>
              <Link to="" className="sidebar_link">
                <span>
                  <OrderIcon />
                </span>
                {props.openNav ? (
                  <p className="sidebar_text">Reviews & Ratings</p>
                ) : (
                  ""
                )}
              </Link>
            </div> */}
            {ifCanAccess('OrdersAndTransactions') && (
            <div className={(props.openNav ? "sidebar_tabs" : "sidebar_icon") + (activeTab === "Orders & Transactions" ? " sidebar_active_tab " : "")} 
            onClick={() => setActiveTab("Orders & Transactions")}>
              <Link to="/admin/orders" className="sidebar_link">
                <span className={(activeTab === "Orders & Transactions" ? " sideNavIcon " : "")}>
                  <OrderIcon fill={activeTab === "Orders & Transactions" && "#F99300"}/>
                </span>
                {props.openNav ? (
                  <p className="sidebar_text">Orders & Transactions</p>
                ) : (
                  ""
                )}
              </Link>
            </div>
            )}
            {ifCanAccess('SupportRequests') && (
            <div className={(props.openNav ? "sidebar_tabs" : "sidebar_icon") + (activeTab === "Support Requests" ? " sidebar_active_tab " : "")} 
            onClick={() => setActiveTab("Support Requests")}>
              <Link to="/admin/support" className="sidebar_link">
                <span className={(activeTab === "Support Requests" ? " sideNavIcon " : "")}>
                  <HelpIcon fill={activeTab === "Support Requests" && "#F99300"}/>
                </span>
                {props.openNav ? (
                  <p className="sidebar_text">Support Requests</p>
                ) : (
                  ""
                )}
              </Link>
            </div>
            )}
          </Col>
          <Col className="">
            <div className="sidebar_col03">
              <div className={props.openNav ? "ps-4 pt-4 sidebar_profile_main" : " sidebar_profile_main"}>
                <p
                  className={props.openNav ? "pro01" : " sidebar_icons_heading"}
                >
                  ACCOUNT
                </p>
                <div className={(props.openNav ? "sidebar_tabs ps-0" : "sidebar_icon") + (activeTab === "Settings" ? " sidebar_active_tab " : "")} 
                  onClick={() => setActiveTab("Settings")}>
                  <Link to="/admin/settings" className="sidebar_link">
                    <span className={(activeTab === "Settings" ? " sideNavIcon " : "")}>
                      <SettingsIcon fill={activeTab === "Settings" && "#F99300"}/>
                    </span>
                    {props.openNav ? (
                      <p className="sidebar_text">Settings</p>
                    ) : (
                      ""
                    )}
                  </Link>
                </div>
                <div className={props.openNav ? "pro03" : "sidebar_icon"}>
                  <Link to="" className="sidebar_link" onClick={props.logout}>
                    <span>
                      <LogoutIcon />
                    </span>
                    {props.openNav ? (
                      <p className="sidebar_text">Logout</p>
                    ) : (
                      ""
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AdminSideNav;

import React from 'react';
import { Col, Row, Card } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import "./chart.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

export const DoughnutChart = ({graphDataTitles,graphData,graphColors}) => {
    //console.log(graphDataTitles, graphData, 'Doughnut Chart');    
    const data = {
        labels: graphDataTitles,
        datasets: [
            {
            label: '# of Votes',
            data: graphData,
            backgroundColor: graphColors,
            borderColor: graphColors,
            borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
          legend: {
            display: false,
          },
        },
      };

    
  return (        
        <Doughnut data={data} options={options} />        
    );        
};

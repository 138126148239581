import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import { getImageUrl } from "../../services/utility";

import {
  SVGAdd,
  SVGStarRating,
  SVGFavorite,
  SVGNotFavorite,
  SVGFavoriteGray,
  SVGFeatured,
  SVGMedia,
} from "../../assets/svgComponents/svg";

import { useDispatch, useSelector } from "react-redux";
import {
  markAsFavoriteAsync,
  markAsUnFavoriteAsync, setPortableSignLocation, getLocationNameAsync
} from "../../services/reducers/listing-reducer";

export const MapListingDetailsCard = ({
  page,
  cardDetails,
  handleDetailpage
}) => {

  const dispatch = useDispatch();
  const markAsFavorite = (props) => {
    const reqBody = {
      siteId: null,
      signId: null,
      listingItemId: null,
      providerId: null,
      type: props.type,
    };

    if (props.type === "provider") {
      reqBody.providerId = props.id;
    } else {
      reqBody.listingItemId = props.itemId;
    }

    if (props.isFavorite) {
      dispatch(markAsUnFavoriteAsync(reqBody));
    } else {
      dispatch(markAsFavoriteAsync(reqBody));
    }
  };

  return (
    <>

      <Card style={{ width: "270px" }}>
        {cardDetails.isFeatured ? (
          <div className="featuredImg">
            <div className="featured"
              style={{ position: "absolute", top: "5px", left: "5px", }}
            >
              <SVGFeatured />
            </div>
            <img
              style={{ height: "120px" }}
              src={getImageUrl(cardDetails.image)}
              alt=""
              className="homeCardImage"
            />
          </div>
        ) : (
          <div className="featuredImg d-flex">
            <div
              className="featured favorite"
              onClick={() => markAsFavorite(cardDetails)}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            >
              {cardDetails.isFavorite || page === "customerFavorites" ? (<SVGFavorite />) : (<SVGNotFavorite />)}
            </div>
            <img
              style={{ height: "120px", width: "100%", objectFit: "cover" }}
              src={getImageUrl(cardDetails.image)}
              alt=""
              className="homeCardImage"
            />
          </div>
        )}
        <Card.Body className="text-start p-20">
          <Card.Title className="text-lg text-gray-1000" style={{ cursor: 'pointer' }}
            onClick={() => {
              handleDetailpage(
                (page === "customerFavorites" ? cardDetails.listingItemId : cardDetails.itemId),
                cardDetails.listingId,
                (page === "customerFavorites" ? (cardDetails.siteId ? cardDetails.siteId : cardDetails.signId) : cardDetails.id),
                (cardDetails.siteId ? "site" : "sign"),
                cardDetails.providerId
              );
            }}
          >
            {cardDetails.listingName}
          </Card.Title>
          <Card.Text>
            {console.log(`card details :${cardDetails}`)}
            <div className="pb-3 text-xxs text-gray-1400 text-truncate">{cardDetails.name}</div>
            <div className="pb-3 text-xxs text-gray-1400 text-truncate">{cardDetails.location}</div>
            <div>
              <label className="label1 text-xxs">Starting</label>&nbsp;
              <label className="day-no text-lg text-gray-1000">
                <b style={{ fontWeight: "600" }}>{cardDetails.price ? cardDetails.price : 0}</b>
              </label>
              {cardDetails.availableFor===1?<></>:
              <label className=" text-xxs">&nbsp;/ {cardDetails.minBookingPeriod} {cardDetails.periodTypeText}</label>}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

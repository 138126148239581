import React, { useContext, useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import DataTable from "react-data-table-component";
import { PassThroughSVG } from "../../../../../../assets/svgComponents/inventorySVG";
import {
  deleteSite,
  inventorySite,
} from "../../../../../../services/Provider/Configurator/Inventory";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Col, Dropdown, DropdownButton, Modal, Spinner } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import ProjectContext from "../../../../../../context/ProjectContext";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import { InputField } from "../../../../../../components/InputField";
import Button from "../../../../../../components/button/Button";
import {
  UnionSVG,
  ExportCSVSVG,
} from "../../../../../../assets/svgComponents/table";
import SiteFilters from "../modal/SiteFilters";
import { checkPermission } from "../../../../../../utils";

const SitesPage = ({ searchInput }) => {
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const [sitesData, setSitesData] = useState();
  const [sitesExportData, setSitesExportData] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const { handleSelectedSelSite } = useContext(ProjectContext);
  const [deleteAbleRow, setDeleteAbleRow] = useState();
  const [filtersModal, setFiltersModal] = useState(false);
  const [environmentValues, setEnvironmentValues] = useState([]);
  const [statusValue, setStatusValue] = useState();
  const [locationValue, setLocationValue] = useState();
  const [loading, setLoading] = useState(false);

  const handleEnvironmentValues = (e) => {
    if (e.target.checked) {
      setEnvironmentValues([...environmentValues, e.target.value]);
    } else {
      setEnvironmentValues(
        environmentValues.filter(
          (environment) => environment !== e.target.value
        )
      );
    }
  };

  const handleStatusValues = (e) => {
    let status = e.target.value;
    if (status === "Active") setStatusValue(1);
    else if (status === "Inactive") setStatusValue(0);
    else setStatusValue(null);
    console.log(e.target.value, "title");
  };

  const handleLocationValue = (e) => {
    setLocationValue(e.target.value);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleClearAllFilter = () => {
    setStatusValue("");
    setLocationValue("");
    setEnvironmentValues([]);
    handleApplyFilter("", [], "");
  }

  const handleSort = async (column, sortDirection) => {
    setSortColumn({ column: column, sortDirection: sortDirection });
  };

  const loadData = async () => {
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: filterText,
      status: statusValue,
      environments: environmentValues,
      locationSearchText: locationValue
    };
    setLoading(true);
    await inventorySite(reqBody)
      .then((res) => {
        if (res.status == 200) {
          setLoading(false);
          setSitesData(res.data.data);
        }
      })
      .catch((err) => console.log(err));
    /*
  const exReqBody = {
    page: 0,
    pageSize: 0,
    orderBy: sortColumn.column.sortField,
    isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
    export: true,
    searchString: "",
    status: true,
  };
  await inventorySite(exReqBody)
    .then((res) => {
      if (res.status == 200) {
        setSitesExportData(res.data.data);
      }
    })
    .catch((err) => console.log(err));
    */
  };
  // fetch site listing data
  useEffect(async () => {
    loadData();
  }, [currentPage, pageSize, sortColumn, filterText]);

  const loadExportData = async () => {
    /*  
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: true,
      searchString: filterText,
      status: true,
    };
    */
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: true,
      searchString: filterText,
      status: statusValue,
      environments: environmentValues,
      locationSearchText: locationValue
    };

    await inventorySite(reqBody)
      .then((res) => {
        if (res.status == 200) {
          //setSitesExportData(res.data.data);
          //if(refreshKey !== 0)
          downloadCSV(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleApplyFilter = (statusFilter, EnvironmentFilter, locationFilter) => {
    setLoading(true);
    setCurrentPage(1);
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: filterText,
      status: statusFilter,
      environments: EnvironmentFilter,
      locationSearchText: locationFilter
    };
    setLoading(true);
    inventorySite(reqBody)
      .then((res) => {
        if (res.status == 200) {
          setLoading(false);
          setSitesData(res.data.data);
          setFiltersModal(false);
        }
      })
      .catch((err) => console.log(err));

  };


  const columns = [
    {
      name: "id".toUpperCase(),
      selector: (row) => row.id,
      sortable: true,
      sortField: "Id",
    },
    {
      name: "Name".toUpperCase(),
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
    },
    {
      name: "location".toUpperCase(),
      selector: (row) => row.location.googleAddress,
      sortable: true,
      sortField: "googleAddress",
    },
    {
      name: "environment".toUpperCase(),
      selector: (row) => row.environment.name,
      sortable: true,
      sortField: "environment",
    },
    {
      name: "status".toUpperCase(),
      selector: (row) => (
        <div>
          {row.status ? (
            <div className="gridTableBody_td active">{row.statusName}</div>
          ) : (
            <div className="gridTableBody_td inActive">{row.statusName}</div>
          )}
        </div>
      ),
      sortable: true,
      sortField: "StatusName",
    },
    {
      cell: (row) => (
        <span className="gridTableBody_td_passThrough">
          <DropdownButton
            align="end"
            className="dropDownBtn"
            title={<PassThroughSVG />}
          >
            <Dropdown.Item onClick={() => handleViewGridCell(row)}>
              View
            </Dropdown.Item>
            {checkPermission("Configurator", "Full") && (
              <Dropdown.Item onClick={(e) => handleEditGridCell(row)}>
                Edit{" "}
              </Dropdown.Item>
            )}
            {checkPermission("Configurator", "Full") && (
              <Dropdown.Item onClick={() => handleDeleteGridCell(row)}>
                Delete
              </Dropdown.Item>
            )}
          </DropdownButton>
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleEditGridCell = (site) => {
    handleSelectedSelSite(site);
    history.push(`${location.pathname}/edit-site`);
  };

  const handleViewGridCell = (site) => {
    handleSelectedSelSite(site);
    history.push({ pathname: `${location.pathname}/view-site`, state: site });
  };

  const handleConfirmModalDelete = async (item) => {
    await deleteSite(deleteAbleRow)
      .then(() => {
        loadData();
        addToast("Location deleted successfully.", {
          appearance: "success",
        });
        setDeleteConfirmModal(false);
      })
      .catch(() => {
        addToast("There is an error while deleting the sign.", {
          appearance: "warning",
        });
      });
  };

  const handleDeleteGridCell = (item) => {
    setDeleteAbleRow(item.id);
    setDeleteConfirmModal(true);
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let res = array.records.map((item) => {
      return {
        // Provider: item.provider?.name,
        Name: item.name,
        Description: item.description,
        EmailId: item.emailId,
        Location: '"' + item.location?.googleAddress + '"',
        Status: item.statusName,
        Environment: item.environment?.name,
        StateName: item.stateName,
        Image: item.images[0]?.image,
        Collaterals: item.collaterals[0]?.collateralInfo,
      }
    });

    let csv = convertArrayOfObjectsToCSV(res);
    if (csv == null) return;
    const filename = "export-sites.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <button className="blueBgButton" onClick={(e) => onExport(e.target.value)}>
      <span className="btnSvg">
        <ExportCSVSVG fill="#FFFFFF" />
      </span>
      Export CSV
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );

  const filteredItems = sitesData?.records.filter(
    (item) =>
      (item && item?.name?.toLowerCase().includes(filterText.toLowerCase())) ||
      item?.id?.toLowerCase().includes(filterText.toLowerCase()) ||
      item?.location?.googleAddress
        ?.toLowerCase()
        .includes(filterText.toLowerCase())
  );

  console.log(sitesData, "sitesData res");
  return (
    <>
      <Col className=" d-flex justify-content-start align-items-center">
        <div className="tableAction_input_container w-25 p-2">
          <InputField
            placeholder="Search by ID, Name, Location..."
            label={"Search by ID, Name, Location..."}
            onChange={(e) => setFilterText(e.target.value)}
          // value={searchInput}
          />
        </div>
        <div className="navbarBtnContainer p-2">
          <Button
            className="whiteBgButton02 "
            onClick={() => setFiltersModal(true)}
          >
            Filter
            <span className="btnSvg">
              <UnionSVG />
            </span>
          </Button>
        </div>
      </Col>{" "}

      <DataTable
        columns={columns}
        data={filteredItems}
        allowOverflow={true}
        paginationDefaultPage={currentPage}
        pagination
        paginationServer
        paginationTotalRows={sitesData?.totalRecords}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={pageSize}
        //selectableRows
        dense
        onSort={handleSort}
        sortServer
        fixedHeader={{
          fixedHeader: true,
          fixedHeaderScrollHeight: "300px",
        }}
        actions={actionsMemo}
      />
      {deleteConfirmModal && (
        <ConfirmModal
          title="Delete Location"
          isModalHeader={true}
          isFooterBtns={true}
          cancelText="No"
          confirmText="Yes"
          handleClose={() => setDeleteConfirmModal(false)}
          handleConfirm={() => handleConfirmModalDelete()}
          width="400px"
        >
          <div className="childs">
            <p className="newListingCreated_description ms-4 py-3">
              Are you sure you want to delete this location?
            </p>
          </div>
        </ConfirmModal>
      )}
      {/* {deleteConfirmModal && (
        <ConfirmModal
          title="Delete Records"
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Delete"
          cancelText="Cancel"
          handleClose={() => setDeleteConfirmModal(false)}
          type="submit"
        ></ConfirmModal>
      )} */}
      {/* filter modal for grid */}
      <Modal
        show={filtersModal}
        onHide={() => setFiltersModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <SiteFilters
          applyFilter={() => handleApplyFilter(statusValue, environmentValues, locationValue)}
          handleStatusValues={handleStatusValues}
          handleEnvironmentValues={handleEnvironmentValues}
          handleLocationValue={handleLocationValue}
          handleClearAllFilter={handleClearAllFilter}
          statusValue={statusValue}
          locationValue={locationValue}
          environmentValue={environmentValues}
        />
      </Modal>
    </>
  );
};

export default SitesPage;

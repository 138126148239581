import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import NavBar from "../../../../components/navBar";
import {
  ExportCSVSVG,
  NotificationSVG,
  UnionSVG,
} from "../../../../assets/svgComponents/table";
import DataTable from "react-data-table-component";
import { PassThroughSVG } from "../../../../assets/svgComponents/inventorySVG";
import {
  getTicketList,
  getTicketSearch,
  getValues,
} from "../../../../services/Admin/SupportRequest/SupportRequest";
import moment from "moment";
import { FloatingLableSearchBar } from "../../../../components/SearchBar/FloatingLableSearchBar";
import { SelectionComponent } from "../../../../components/SelectionComponent/SelectionComponent";
import { useHistory, useLocation } from "react-router";

export const SupportRequest = () => {
  const tabs = ["OPEN TICKETS", "CLOSED TICKETS"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [loader, setLoader] = useState(false);
  const [ticketListData, setTicketListData] = useState({});
  const [timeFilter, setTimeFilter] = useState("");
  const [searchString, setSearchString] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterValues, setFilterValues] = useState();
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const history = useHistory();
  const location = useLocation();

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    console.log(column, sortDirection);
    setSortColumn({ column: column, sortDirection: sortDirection });
  };

  const handleEditGridCell = (item) => {
    history.push({ pathname: `${location.pathname}/edit-user`, state: item });
  };

  const columns = [
    {
      name: "USER TYPE",
      selector: (row) => row.properties.userrole,
      sortable: false,
      // sortField: 'usertype',
    },
    {
      name: "NAME",
      selector: (row) => row.properties.username,
      sortable: false,
      // sortField: 'Id',
    },
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: false,
      // sortField: 'Id',
    },
    {
      name: "SUBJECT",
      selector: (row) => row.properties.subject,
      sortable: false,
    },
    {
      name: "QUERY",
      selector: (row) => row.properties.content,
      sortable: false,
    },
    {
      name: "Date & Time",
      selector: (row) => moment(row.properties.createdate).format("DD-MMM-YYYY hh:mm A"),
      sortable: true,
      sortField: "CreateDate",
    },
    // {
    //   cell: (row) => <span className="gridTableBody_td_passThrough">
    //     <DropdownButton
    //       align="end"
    //       className="dropDownBtn"
    //       title={<PassThroughSVG />}
    //     >
    //       <Dropdown.Item
    //         onClick={(e) => handleEditGridCell(row)}
    //       >
    //         Edit{" "}
    //       </Dropdown.Item>
    //     </DropdownButton>
    //   </span>,
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];

  const loadData = async () => {
    const reqBody = {
      searchText: searchString,
      dateFilter: timeFilter ? timeFilter : 0,
      isOpen: activeTab === "OPEN TICKETS" ? true : false,
      orderBy: {
        propertyName: "createdate",
        isDesc: true,
      },
    };
    setLoader(true);
    await getTicketSearch(reqBody, currentPage, pageSize)
      .then((res) => {
        if (res.status === 200) {
          if(res.data.records)
          {
            setTicketListData(res.data);
          }
          else{
            setTicketListData([]);
          }
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // fetch user data for listing in grid
  useEffect(async () => {
    loadData();
  }, [activeTab, searchString, timeFilter, currentPage, pageSize, sortColumn]);

  useEffect(async () => {
    await getValues("TicketDateFilter").then((res) => {
      if (res.status === 200) {
        setFilterValues(res.data);
      }
    });
  },[]);

  const loadExportData = async () => {
    const reqBody = [
      {
        propertyName: "hs_pipeline_stage",
        value: "1",
        operator: "EQ",
      },
    ];
    await getTicketList(reqBody)
      .then((res) => {
        if (res.status === 200) {
          ticketListData(res.data.records);
        }
      })
      .catch((err) => console.log(err));
  };

  const searchChange = (e) => {
    setSearchString(e.target.value);
    setTimeFilter("");
  };

  const TimeFilterChange = (e) => {
    setTimeFilter(e.target.value);
    setSearchString("");
  };

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array.records);
    if (csv == null) return;

    const filename =
      "export-" + activeTab.toLowerCase().split("").join("") + ".csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <button className="blueBgButton" onClick={(e) => onExport(e.target.value)}>
      <span className="btnSvg">
        <ExportCSVSVG fill="#FFFFFF" />
      </span>
      Export CSV
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );

  return (
    <>
      <NavBar
        isInputField={false}
        notificationSVG={<NotificationSVG />}
        navHeading="Support Requests"
        subHeading=""
        showButton={false}
      />

      <div className="gridSection p-20">
        <div className="tableActions_container mb-4">
          <Row className="d-flex align-items-center g-0">
            <Col className="d-flex">
              <ul className="tableActions_tabs">
                {tabs.map((item) =>
                  item === activeTab ? (
                    <li
                      className="tableActions_tab active"
                      onClick={() => {
                        setActiveTab(item);
                      }}
                    >
                      {item} <hr className="tableActions_tab_hr" />
                    </li>
                  ) : (
                    <li
                      className="tableActions_tab"
                      onClick={() => {
                        setActiveTab(item);
                      }}
                    >
                      {item}
                    </li>
                  )
                )}
              </ul>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="d-flex serachFilterContainer me-3 w-auto">
                <FloatingLableSearchBar
                  inputFieldSize="fieldSize"
                  handleOnchange={searchChange}
                  label="Search Subject, Username"
                  value={searchString}
                />
              </div>
              {/* <div className="d-flex serachFilterContainer w-auto">
                <SelectionComponent
                  label="Created On"
                  handleOnchange={TimeFilterChange}
                  option={filterValues}
                ></SelectionComponent>
              </div> */}
            </Col>
          </Row>
        </div>
        <div className="card overflow-hidden customtable">
          {loader ? null : (
            <DataTable
              columns={columns}
              data={ticketListData?.records}
              paginationDefaultPage={currentPage}
              progressPending={loader}
              pagination
              paginationServer
              paginationTotalRows={ticketListData?.totalRecords}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationPerPage={pageSize}
              // selectableRows
              dense
              onSort={handleSort}
              sortServer
            />
          )}
        </div>
      </div>
    </>
  );
};

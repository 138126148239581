import React from "react";
import { Collateral } from "../../../../../../../assets/svgComponents/inventorySVG";

export const ItemNotAvailable = ({title}) => {
  return (
    <div className="itemNotAvailbleContainer">
      <div className="mb-3">
        <Collateral fill="#F99300" />
      </div>
      <span>{title}</span>
    </div>
  );
};
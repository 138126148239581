import React, {useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import { InputField } from "../../../../../components/InputField";
import {useDispatch, useSelector} from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Rating } from 'react-simple-star-rating';
import { getProviderConnectAccountId, saveProviderConnectAccountId } from "../../../../../services/Provider/UserManagement/UserManagement";

export const ProviderConnectAccountModal = ({
  show,
  setShow,
  provider
}) => {
  
  const dispatch = useDispatch();
  const [connectAccountId,setConnectAccountId] = useState('');  
  const { addToast } = useToasts();

  useEffect( async()=>{ 
    if(provider){
       getProviderConnectAccountId(provider.id).then(r=>{ 
        setConnectAccountId(r.data.connectAccountId);
       });
    }
  },[provider]); 
  
  const handleOk = async() => { 
      let reqBody= {
          "providerId": provider.id,
          "connectAcccountId": connectAccountId, 
        };
       saveProviderConnectAccountId(reqBody).then(r=>{
        addToast("Provider connect account saved successfully.", { appearance: "success" });
        setShow(false);
       });
    }  
  
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen="sm-down"
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"> 
            <h5 style={{ fontSize: "18px", fontWeight: "300" }}>Provider{provider ? (" " + provider.name) : " "}'s Stripe Connect Account Id</h5> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
      <div className="d-flex justify-content-between mt-2"> 
         <InputField label={"Connect Account Id"} 
         defaultValue={connectAccountId} 
         labelClassName="w-100"
         placeholder="Connect Account Id"
         required={true}
         onChange={(e)=>setConnectAccountId(e.target.value)} />
      </div>
      </Modal.Body>
      <Modal.Footer
        className="popModalfooter justify-content-center"
        onClick={handleOk}      >
        <span style={{ color: "#ffffff" }}>Save</span>
      </Modal.Footer>
    </Modal>
  );
};

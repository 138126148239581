import React from "react";
import BySites from "../Pages/BySites";
import ByUsers from "../Pages/ByUsers";

export const SwitchPage = ({
  activeTab,
  data,
  handleOrderCard,
  selectedOrdersCard,
  setUserPage,
  setSitePage,
  userPage,
  sitePage,
  SetIsStatusChange
}) => {
  switch (activeTab) {
    case "BY SITES & SIGNS":
      return (
        <BySites
          data={data}
          handleOrderCard={handleOrderCard}
          selectedOrdersCard={selectedOrdersCard}
          page={sitePage}
          setPage={setSitePage}
          SetIsStatusChange={SetIsStatusChange}
        />
      );
    case "BY USERS":
      return (
        <ByUsers
          data={data}
          handleOrderCard={handleOrderCard}
          selectedOrdersCard={selectedOrdersCard}
          page={userPage}
          setPage={setUserPage}
          SetIsStatusChange={SetIsStatusChange}
        />
      );
    default:
      return <></>;
  }
};

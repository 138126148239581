import * as React from 'react';


// import Image from 'components/Image';
import { uploadFileMethod } from '../../services/Provider';

export const MediaUpload =  ({
    id,
    slug,
    value,
    onChange,
  })=> {
  const[fileState, setFileState]= React.useState({progress: -1,file: null,error: null});
  const[fileName, setFileName] = React.useState('No file attached');

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    let file = e.target.files[0];
    console.log(file)
    setFileState({...fileState, file: file });
    let data = new FormData();
    data.append('file', file);
    data.append('filename', file.name);

    setFileName(file.name);

    let config = {
      onUploadProgress: (p) => {
        setFileState({...fileState, progress: Math.round(p.loaded * 100 / p.total) });
      },
    };

    setFileState({...fileState,error: undefined, progress: 0 });
    uploadFileMethod(data).then((res)=>{
        setFileState({...fileState, progress: -1, error: undefined });
        onChange(res.data.fileKey);
    })
  }

  const handleRemoveImage = () => {
    onChange(id, '');
  }

    return (
      <div>
        {/* <div>
          {value !== '' &&
            fileState.progress === -1 &&
            <img src={"https://gopinit.blob.core.windows.net/image-container/"+value} alt={value} width='100px' />}
          {value &&
            <a
              style={{ marginTop: -40 }}
              className="button button--negative button--small button--secondary"
              role="button"
              onClick={handleRemoveImage}
            >
              Remove
            </a>}
        </div> */}
        <div className="uploadBox w-100 justify-content-between">
          <p className="text-sm text-gray-1400">{fileName ? fileName : 'No file attached'}</p>
          <label className="btn btnLable p-0">
          <div><svg width="19" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.18216 5.2881C4.36821 5.49249 4.66985 5.49249 4.8559 5.2881L8.00992 1.82314L8.01184 9.30108C8.01191 9.5773 8.23618 9.80116 8.51275 9.80107C8.78933 9.80098 9.01348 9.57699 9.01341 9.30077L9.0115 1.82327L12.1654 5.2881C12.3514 5.49249 12.6531 5.49249 12.8391 5.2881C13.0252 5.08371 13.0252 4.75233 12.8391 4.54794L8.84752 0.162813C8.66147 -0.0415756 8.35983 -0.0415758 8.17378 0.162813L4.18216 4.54794C3.99611 4.75233 3.99611 5.08371 4.18216 5.2881ZM1.5006 7.2379C1.5006 6.96167 1.27639 6.73775 0.999811 6.73775C0.723233 6.73775 0.499023 6.96167 0.499023 7.2379V8.48817C0.499023 12.6315 3.86218 15.9904 8.01084 15.9904H9.01441C13.1631 15.9904 16.5262 12.6315 16.5262 8.48817V7.2379C16.5262 6.96167 16.302 6.73775 16.0254 6.73775C15.7488 6.73775 15.5246 6.96167 15.5246 7.2379V8.48817C15.5246 12.0791 12.6099 14.9901 9.01441 14.9901H8.01084C4.41533 14.9901 1.5006 12.0791 1.5006 8.48817V7.2379Z" fill="white"></path></svg></div>
            <p>Upload image</p>
            
            <input
              className="visually-hidden"
              type="file"
              placeholder="No file"
              onChange={handleFileChange}
            />

          </label>




          
        </div>

      </div>
    );
  }
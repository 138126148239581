import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { PassThroughSVG } from "../../../../../../assets/svgComponents/inventorySVG";
import {
  bundleListings,
  deleteListings,
  getListingEditData,
  signListings,
  siteListings,
} from "../../../../../../services/Provider/Configurator/Listings";
import DataTable from "react-data-table-component";
import { CustomSort } from "../../../../../../components/DataTable/CustomSort";
import DropDownItem from "../../../../../../components/DataTable/DropDownItem";
import { Col, Dropdown, DropdownButton } from "react-bootstrap";
// import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useToasts } from "react-toast-notifications";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import ProjectContext from "../../../../../../context/ProjectContext";
import { ExportCSVSVG } from "../../../../../../assets/svgComponents/table";
import { InputField } from "../../../../../../components/InputField";
import { PlusSVG } from "../../../../../../assets/svgComponents/svg";
import { checkPermission } from "../../../../../../utils";

const AllListings = ({
  allListing,
  setNewListingModal,
  setListingName,
  setIsUpdateData,
  searchTerm,
  searchToggle,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  const { selectedEntities, handleSelectedEntities, handleUpdateListing } =
    useContext(ProjectContext);

  const [allData, setAllData] = useState();
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [selectedListRow, setSelectedListRow] = useState();
  const [filterText, setFilterText] = React.useState("");
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const [listingData, setlistingData] = React.useState([]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    console.log(column, sortDirection, "----------------------------");
    setSortColumn({ column: column, sortDirection: sortDirection });
    // loadData()
  };

  const columns = [
    {
      name: "id".toUpperCase(),
      selector: (row) => row.listingId,
      sortable: true,
      sortField: "Id",
    },
    {
      name: "Name".toUpperCase(),
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
    },
    {
      name: "media".toUpperCase(),
      selector: (row) => row.mediaName,
      sortable: true,
      sortField: "Media",
    },
    {
      name: "sign".toUpperCase(),
      selector: (row) => row.noOfSigns,
      sortable: true,
      sortField: "Id",
    },
    {
      name: "services".toUpperCase(),
      cell: (row) => {
        if (row.servicesDetails?.length == 1) {
          return (
            <div className="gridTableBody_td">
              {row.servicesDetails[0]?.serviceName}
            </div>
          );
        } else if (row.servicesDetails?.length > 1) {
          return (
            <div>
              {row.servicesDetails?.length > 0 ? (
                <div className="gridTableBody_td">
                  {row.servicesDetails[0]?.serviceName.split(" ")[0]}...{" "}
                  {row.servicesDetails?.length > 1 ? (
                    <span
                      title={row.servicesDetails
                        ?.map(function (a) {
                          return a.serviceName;
                        })
                        .join(", ")}
                      className="oneMore"
                      style={{
                        textAlign: "right",
                        cursor: "pointer",
                        color: "#F99300",
                      }}
                      //onClick={}
                    >
                      <PlusSVG fill="#F99300" />
                      {row.servicesDetails?.length - 1}
                    </span>
                  ) : (
                    <span></span>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          );
        } else {
          return <></>;
        }
      },
      sortable: true,
      sortField: "SignName",
    },
    {
      name: "charges".toUpperCase(),
      cell: (row) => {
        if (row.chargesDetails?.length == 1) {
          return (
            <div className="gridTableBody_td">
              {row.chargesDetails[0]?.chargeName}
            </div>
          );
        } else if (row.chargesDetails?.length > 1) {
          return (
            <div>
              {row.chargesDetails?.length > 0 ? (
                <div className="gridTableBody_td">
                  {row.chargesDetails[0]?.chargeName.split(" ")[0]}...{" "}
                  {row.chargesDetails?.length > 1 ? (
                    <span
                      title={row.chargesDetails
                        ?.map(function (a) {
                          return a.chargeName;
                        })
                        .join(", ")}
                      className="oneMore"
                      style={{
                        textAlign: "right",
                        cursor: "pointer",
                        color: "#F99300",
                      }}
                      //onClick={}
                    >
                      <PlusSVG fill="#F99300" />
                      {row.chargesDetails?.length - 1}
                    </span>
                  ) : (
                    <span></span>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          );
        } else {
          return <></>;
        }
      },
      sortable: true,
      sortField: "Charges",
    },
    {
      name: "Pricing".toUpperCase(),
      selector: (row) => row.pricing,
      sortable: true,
      sortField: "Pricing",
    },
    {
      name: "Featured".toUpperCase(),
      cell: (row) => {
        return (
          <div>
            {row.featured ? (
              <div
                className="gridTableBody_td active"
                title={row.featuredPeriod}
              >
                Yes{" "}
              </div>
            ) : (
              <div className="gridTableBody_td inActive">No</div>
            )}
          </div>
        );
      },
      sortable: true,
      sortField: "Featured",
    },
    {
      cell: (row) => (
        <span className="gridTableBody_td_passThrough">
          <DropdownButton
            align="end"
            className="dropDownBtn"
            title={<PassThroughSVG />}
          >
            <Dropdown.Item onClick={() => handleViewGridCell(row)}>
              View
            </Dropdown.Item>
            {checkPermission("Listings", "Full") && (
            <Dropdown.Item onClick={() => handleEditGridCell(row)}>
              Edit
            </Dropdown.Item>
            )}
            {checkPermission("Listings", "Full") && (
            <Dropdown.Item onClick={() => handleDeleteGridCell(row)}>
              Delete
            </Dropdown.Item>
            )}
          </DropdownButton>
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const loadData = async () => {
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField ? sortColumn.column.sortField : "",
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: filterText,
      listingType: 3,
    };

    setLoader(true);
    await signListings(reqBody)
      .then((res) => {
        setLoader(false);
        setAllData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const loadExportData = async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: true,
      searchString: filterText,
      listingType: 3,
    };
    await signListings(reqBody)
      .then((res) => {
        downloadCSV(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(async () => {
    loadData();
  }, [
    currentPage,
    pageSize,
    sortColumn,
    deleteConfirmModal,
    searchToggle,
    filterText,
  ]);

  const handleConfirmModalDelete = async () => {
    console.log(selectedListRow.listingId, "selectedListRow.listingId");
    await deleteListings(selectedListRow.listingId)
      .then((res) => {
        if (res.status == 204) {
          setDeleteConfirmModal(false);
          addToast("Listing deleted successfully", {
            appearance: "success",
          });
        }
      })
      .catch((err) => err);
  };

  // gird action handle for sites
  const handleViewGridCell = (row) => {
    if (row.itemType === "Bundle") {
      history.push({
        pathname: `${location.pathname}/information-details`,
        state: {
          selectedRow: row,
          tabs: ["INFORMATION", "LOCATIONS", "SIGNS", "REVIEWS & RATINGS"],
          isBundleInformation: true,
        },
      });
    } else if (row.itemType === "Site") {
      history.push({
        pathname: `${location.pathname}/information-details`,
        state: {
          selectedRow: row,
          tabs: ["INFORMATION", "LOCATIONS", "REVIEWS & RATINGS"],
          isBundleInformation: false,
        },
      });
      // console.log(row, "site");
    } else if (row.itemType === "Sign") {
      // console.log(row, "sign");
      history.push({
        pathname: `${location.pathname}/information-details`,
        state: {
          selectedRow: row,
          tabs: ["INFORMATION", "SIGNS", "REVIEWS & RATINGS"],
          isBundleInformation: false,
        },
      });
    }
  };

  const handleDeleteGridCell = (row) => {
    setDeleteConfirmModal(true);
    setSelectedListRow(row);
  };

  const handleEditGridCell = async (item) => {
    let tempEntities = [...selectedEntities];
    tempEntities.forEach((item) => {
      item.isActive = false;
    });
    // console.log(item, "item");
    if (item.listingId) {
      await getListingEditData(item.listingId).then((res) => {
        console.log(res.data, "res");
        if (res.status == 200) {
          if (res.data.siteIds.length > 0) {
            // console.log(res.data.signIds, "res");
            tempEntities.forEach((item) => {
              item.name == "Locations" && (item.isChecked = true);
            });
          } else {
            tempEntities.forEach((item) => {
              item.name == "Locations" && (item.isChecked = false);
            });
          }
          if (res.data.signIds.length > 0) {
            tempEntities.forEach((item) => {
              item.name == "Signs" && (item.isChecked = true);
            });
          } else {
            tempEntities.forEach((item) => {
              item.name == "Signs" && (item.isChecked = false);
            });
          }
          if (res.data.serviceIds.length > 0) {
            tempEntities.forEach((item) => {
              item.name == "Services" && (item.isChecked = true);
            });
          } else {
            tempEntities.forEach((item) => {
              item.name == "Services" && (item.isChecked = false);
            });
          }
          if (res.data.chargeIds.length > 0) {
            tempEntities.forEach((item) => {
              item.name == "Charges" && (item.isChecked = true);
            });
          } else {
            tempEntities.forEach((item) => {
              item.name == "Charges" && (item.isChecked = false);
            });
          }
          tempEntities[0].isActive = true;
          // tempEntities;
          setListingName(res.data.name);
          setIsUpdateData(res.data);
          // handleUpdateListing(res.data);
          handleSelectedEntities(tempEntities);
          setNewListingModal(true);
          // history.push({
          //   pathname: `${location.pathname}/new-billable-item`,
          //   data: res.data,
          //   isUpdateListing: true,
          // });
        }
      });
    }
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array.records);
    if (csv == null) return;

    const filename = "export-listings.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <button className="blueBgButton" onClick={(e) => onExport(e.target.value)}>
      <span className="btnSvg">
        <ExportCSVSVG fill="#FFFFFF" />
      </span>
      Export CSV
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );

  return (
    <>
      <Col className="d-flex justify-content-start align-items-center col-6 p-2">
        <div className="tableAction_input_container w-50">
          <InputField
            placeholder="Search Location, Sign, Bundles..."
            label={"Search Location, Sign, Bundles..."}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      </Col>{" "}
      <DataTable
        progressPending={loader}
        columns={columns}
        data={allData?.records}
        allowOverflow={true}
        paginationDefaultPage={currentPage}
        pagination
        paginationServer
        paginationTotalRows={allData?.totalRecords}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={pageSize}
        //selectableRows
        dense
        onSort={handleSort}
        sortServer
        fixedHeader={{
          fixedHeader: true,
          fixedHeaderScrollHeight: "300px",
        }}
        actions={actionsMemo}
      />
      {/* </div> */}
      {deleteConfirmModal && (
        <ConfirmModal
          title="Delete Listing"
          isModalHeader={true}
          isFooterBtns={true}
          cancelText="No"
          confirmText="Yes"
          handleClose={() => setDeleteConfirmModal(false)}
          handleConfirm={() => handleConfirmModalDelete()}
          width="400px"
        >
          <div className="childs">
            <p className="newListingCreated_description ms-4 py-3">
              Do you want to delete this listing?
            </p>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default AllListings;

import React from "react";
import { Form, FloatingLabel } from "react-bootstrap";

export const SelectionComponent = ({
  handleOnchange,
  label,
  option,
  inputsize,
  index,
  name,
  value,
  onBlur,
  selected,
  defaultValue,
  disabled,
  optionDisabled,
  type,
  placeholder,
}) => {
  return (
    <FloatingLabel
      controlId="floatingInput"
      label={label}
      className={inputsize}
    >
      <Form.Select
        aria-label="Floating label select example"
        // value={value}
        type={type}
        onChange={(e) => handleOnchange(e, index)}
        name={name}
        onBlur={onBlur}
        // onSelect={option && option[0].id}
        disabled={disabled}        
        defaultValue={defaultValue}
        
      >
        <option disabled selected>
          {selected ? selected : (placeholder ? placeholder : ("Select " + label))}
        </option>
        {option &&
          option.map((item) => {             
            return (
              <option value={item.id} selected={item.id === defaultValue} disabled={item.name == optionDisabled}>
                {item.name}
              </option>
            );
          })}
      </Form.Select>
    </FloatingLabel>
  );
};

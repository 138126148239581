/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Row } from "react-bootstrap";
import { FieldArray, Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  NotificationSVG,
  PlusSVG,
} from "../../../../../assets/svgComponents/table";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import NavBar from "../../../../../components/navBar";
import { UploadFile } from "../../../../../components/UploadFile/UploadFile";
import {
  addInviteUser,
  getAccessTypes,
  getUserExistance,
} from "../../../../../services/Provider/UserManagement/UserManagement";
import { ImageInput } from "formik-file-and-image-input/lib";
import { useHistory, useLocation } from "react-router";
import { uploadFileMethod } from "../../../../../services/Provider";
import { ActionType } from "../Components/actionType";
import { MediaUpload } from "../../../../../components/UploadFile/MediaUpload";
import { SelectionComponent } from "../../../../../components/SelectionComponent/SelectionComponent";
import { useToasts } from "react-toast-notifications";
import { values } from "lodash";
const InviteUser = () => {
  const [invitesSentModal, setInvitesSentModal] = useState(false);
  const [invitesErrorModal, setInvitesErrorModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [accessTypes, setAccessType] = useState([]);
  const history = useHistory();
  const [showTranFilterModal, setShowTranFilterModal] = useState(false);
  const { addToast } = useToasts();
  const [accessTypeAdded, setAccessTypeAdded] = useState(false);
  const userType = localStorage.getItem("userType");

  const ValidationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required."),
    emailId: Yup.string()
      .required("Email address is required.")
      .matches(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
        "Invalid email address."
      )
      .test("Unique Email", "Email already in use", function (value) {
        return new Promise((resolve, reject) => {
          getUserExistance(value).then((res) => {
            console.log(res.data);
            if (res.data === true) {
              resolve(false);
            } else {
              resolve(true);
            }
          });
        });
      }),
    accessTypeId: Yup.string().required("Access type is required."),
  });

  const handleWhiteBtn = () => {
    setShowTranFilterModal(true);
    setAccessTypeAdded(false);
  };

  const ApplyBtnForTran = () => {
    setShowTranFilterModal(false);
    setAccessTypeAdded(true);
  };
  useEffect(async () => {
    loadAccessTypes();
  }, [accessTypeAdded]);

  const loadAccessTypes = async () => {
    const reqBody = {
      page: 1,
      pageSize: 100,
      orderBy: "",
      export: true,
      searchString: "",
      status: 0,
    };
    await getAccessTypes(reqBody)
      .then((res) => {
        if (res.status === 200) {
          setAccessType(res.data.records);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleBlueBtn = () => {
    //setInvitesSentModal(true);
  };
  const CustomFileInputWrapper = ({ onClick, fileName }) => {
    return (
      <div>
        <button onClick={onClick}>Choose File</button>
        <p>{fileName}</p>
      </div>
    );
  };

  const CustomImageInputWrapper = ({ onClick, fileName, src }) => {
    return (
      <div onClick={onClick}>
        {!src && (
          <button type="button" className="primary" onClick={onClick}>
            Choose Image
          </button>
        )}
        <img src={src} width={"100px"} height={"100px"} />
        <p>{fileName}</p>
      </div>
    );
  };

  const fileFormats = ["application/pdf"];
  const imageFormats = ["image/png", "image/svg", "image/jpeg"];

  return (
    <>
      <div>
        <Formik
          initialValues={{
            users: [
              {
                fullName: "",
                emailId: "",
                accessTypeId: "",
                image: null,
              },
            ],
          }}
          validationSchema={Yup.object({
            users: Yup.array().of(ValidationSchema),
          })}
          // validationSchema={ValidationSchema}
          onSubmit={async (values) => {
            setLoader(true);
            // console.log(values.invites.profilePicture)
            // await uploadFileMethod(values.invites.profilePicture).then((res) => {
            //       if (res.status === 200) {
            //         setLoader(false);
            //         setInvitesSentModal(true);
            //       }
            //     })
            //     .catch((err) => console.log(err));
            await addInviteUser(values)
              .then((res) => {
                if (
                  res.status === 200 ||
                  res.status === 201 ||
                  res.status === 204
                ) {
                  setLoader(false);
                  addToast(
                    "Invites have been sent to the email ids that you entered.",
                    { appearance: "success" }
                  );
                  history.push("/admin/allusers");
                } else {
                  setLoader(false);
                  addToast("Error while creating one or more user.", {
                    appearance: "error",
                  });
                }
              })
              .catch((err) => {
                addToast(err.response.data.error, { appearance: "error" });
              });
          }}
          render={({ setFieldValue, values }) => (
            <Form>
              <NavBar
                blueBtnText={`Send`}
                notificationSVG={<NotificationSVG />}
                navHeading="User Management"
                handleBlueBtn={handleBlueBtn}
                subHeading="User Management"
                subActiveHeading=" Invite User"
                whiteBtnText="Add Access Type"
                handleWhitebtn={() => handleWhiteBtn()}
                showButton={true}
              />

              <FieldArray
                name="users"
                render={(arrayHelpers) => (
                  <div className="px-3">
                    {values.users.length > 0 &&
                      values.users.map((user, index) => (
                        <div className="row-rm" key={index}>
                          <Row className="pb-3 pt-3">
                            <Col md={4} className="">
                              <FloatingLabel
                                controlId="floatingInput"
                                label={"Full Name"}
                                className={`floatingLabel`}
                              >
                                <Field
                                  name={`users.${index}.fullName`}
                                  label="Full Name"
                                  htmlFor={`users.${index}.fullName`}
                                  placeholder="Full Name"
                                  className="inputField form-control"
                                />
                                <div className="text-danger">
                                  <ErrorMessage
                                    name={`users.${index}.fullName`}
                                  />
                                </div>
                              </FloatingLabel>
                            </Col>
                            <Col md={4} className="">
                              <FloatingLabel
                                controlId="floatingInput"
                                label={"Email Address"}
                                className={`floatingLabel`}
                              >
                                <Field
                                  name={`users.${index}.emailId`}
                                  label="Email Address"
                                  placeholder="Email Address"
                                  htmlFor={`users.${index}.emailId`}
                                  className="inputField form-control"
                                />
                                <div className="text-danger">
                                  <ErrorMessage
                                    name={`users.${index}.emailId`}
                                  />
                                </div>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <Row className="pb-3">
                            <Col md={4}>
                              <SelectionComponent
                                name={`users.${index}.accessTypeId`}
                                label="Access Type"
                                option={accessTypes}
                                //handleOnchange={(e) => handleUserChange(e, index)}
                                value={values.accessTypeId}
                                // onBlur={formik.handleBlur}
                                handleOnchange={(e) =>
                                  setFieldValue(
                                    `users.${index}.accessTypeId`,
                                    e.target.value
                                  )
                                }
                              />
                              <div className="text-danger">
                                <ErrorMessage
                                  name={`users.${index}.accessTypeId`}
                                />
                              </div>
                              {/* <FloatingLabel
                                controlId="floatingInput"
                                label={"Access Type"}
                                className={`floatingLabel`}
                              >
                                <Field
                                  as="select"
                                  name={`users.${index}.accessTypeId`}
                                  label="Access Type"
                                  placeholder="Access Type"
                                  htmlFor={`users.${index}.accessTypeId`}
                                  className="inputField form-control"
                                >
                                  <option value="">--Select--</option>
                                  {accessTypes?.map((accessType) => {
                                    return (
                                      <option value={accessType.id}>
                                        {accessType.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <ErrorMessage name={`users.${index}.accessTypeId`} />
                              </FloatingLabel> */}
                            </Col>
                            <Col md={4}>
                              <Field
                                name="image"
                                component={MediaUpload}
                                onChange={(value) =>
                                  setFieldValue(`users.${index}.image`, value)
                                }
                                value={user.image}
                              />
                              {/* <Field name="image" component={UploadFile}  placeholder={user.image ? user.image : "No file attached"} onChange={value=>setFieldValue(`users.${index}.image`, value)} value={user.image} /> */}

                              {/*   <UploadFile                                  
                                  name="image"                              
                                  value={user.image}
                                  placeholder={user.image ? user.image : "No file attached"}
                                  btnlable="image"
                                  uploadBoxSize="uploadBoxSize"                                                                                                      
                                  handelUploadLogo={(e) => handleUploadFile(e, index)}
                                  //handelUploadLogo={(e) => handleUploadFile(e, index)}
                                  // value={formik.values.uploadFile}
                                  // onBlur={formik.handleBlur}
                                  // handelUploadLogo={(e) => {
                                  //   const files = e.target.files;
                                  //   let myFiles = Array.from(files);
                                  //   formik.setFieldValue("uploadFile", myFiles);
                                  // }}
                                />  */}
                            </Col>
                            {index > 0 && (
                              <Col md={4} className="d-flex align-items-center">
                                <button
                                  className="whiteBgButton px-3 text-lg text-gray-1400 rounded-2"
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                >
                                  -
                                </button>
                              </Col>
                            )}
                          </Row>

                          {/* <button
                            type="button"
                            onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                          >
                            +
                          </button> */}
                        </div>
                      ))}
                    <a
                      className="user_link"
                      onClick={() =>
                        arrayHelpers.push({
                          fullName: "",
                          emailId: "",
                          accessTypeId: "",
                          image: null,
                        })
                      }
                    >
                      <span className="pe-1">
                        <PlusSVG fill="#F99300" />
                      </span>
                      <b>Add Another User</b>
                    </a>
                    <div>
                      {/* <button
                        type="submit"
                        className="blueBgButton applyFilterBtn py-3 px-5"
                        onClick={handleBlueBtn}
                      > 
                        Submit
                      </button> */}
                    </div>
                  </div>
                )}
              />
            </Form>
          )}
        />
      </div>
      {/* new listing created modal */}
      {invitesSentModal && (
        <ConfirmModal
          // setConfirmModal={setAddSignModal}
          //   confirm={handleAddSignsModal}
          title="Invites
          Sent"
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Close"
          handleClose={() => history.goBack()}
          handleConfirm={() => {
            history.goBack();
          }}
        >
          <div className="">
            <p className="py-3 ps-3 newListingCreated_description text-left">
              Invites have been sent to the email ids <br /> that you entered.
            </p>
          </div>
        </ConfirmModal>
      )}
      {invitesSentModal && (
        <ConfirmModal
          // setConfirmModal={setAddSignModal}
          //   confirm={handleAddSignsModal}
          title="Invites Error"
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Close"
          handleClose={() => setInvitesErrorModal(false)}
          handleConfirm={() => {
            setInvitesErrorModal(false);
          }}
        >
          <div className="">
            <p className="py-3 ps-3 newListingCreated_description text-left">
              Error creating one or more user.
            </p>
          </div>
        </ConfirmModal>
      )}
      <ActionType
        show={showTranFilterModal}
        accessTypeLable={accessTypes}
        handleApplyBtn={ApplyBtnForTran}
        accessTypeAdded={accessTypeAdded}
        setAccessTypeAdded={setAccessTypeAdded}
        setShowFilterModal={setShowTranFilterModal}
      />
    </>
  );
};

export default InviteUser;

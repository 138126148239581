import React from "react";
import { InputGroup, FormControl, FloatingLabel } from "react-bootstrap";
import { SVGSearch } from "../../../../assets/svgComponents/svg";  

export const SearchBox = (props) => {
  return (
      <FloatingLabel controlId="floatingInput" label={props.label} className="fieldSize w-100">
        <FormControl 
          defaultValue={props.value ? props.value : ""}
          placeholder="Enter to Search"
          className="serchInput"
          onChange={(e) => props.handleTermChange(e.target.value)}
        />
        <InputGroup.Text className={`serchIcon ${props.searchafter?props.searchafter:""}`} onClick={props.onSearch}>
          <SVGSearch />
        </InputGroup.Text>
      </FloatingLabel>
  );
};

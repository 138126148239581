import React from "react";
import { Card } from "react-bootstrap";
import visa from "../../assets/images/visa.png";
import { MasterCardSVG, VisaCardSVG } from "../../assets/svgComponents/svg";
import RadioButton2 from "../RadioButton/RadioButton2";

export const AtmCard = ({
  card,
  deteletePaymentCard,
  editCardDetails,
  index,
  makeDefoultCard,
  sectionClassName,
  pt32ClassName,
}) => {
  return (
    <div className={` ${sectionClassName ? sectionClassName : "col-md-4"}`}>
      <div className="col-md-12 card1 mb-3">
        <div className="cardBodyContainer">
          <div className="checkBoxContainer mt-3 ms-3">
            <RadioButton2
              isDefaultAtm={card && card.isDefault}
              onChange={() => makeDefoultCard(card)}
              index={index}
            />
          </div>
          <div className="nameAndLogBox mt-0">
            <span>{card && card.name}</span>
            {card.brand == "visa" ? (
              <VisaCardSVG></VisaCardSVG>
            ) : (card.brand == "master" ? (
              <MasterCardSVG></MasterCardSVG>
            ) : (
              <></>
            ))}
          </div>
          <div className={`cardNumber mt-0 ${pt32ClassName}`}>
            <span>{`XXXX XXXX XXXX ${card && card.last4}`} </span>
          </div>
          <div className="expireDate">
            <span>
              Expire on{" "}
              <b>{`${card && card.exp_month}/${card && card.exp_year}`}</b>
            </span>
          </div>
        </div>
        {editCardDetails && deteletePaymentCard && (
          <div className="cardFooterContainer bg-base-100">
            <div
              className="editBox"
              onClick={() => editCardDetails(card)}
              style={{ cursor: "pointer" }}
            >
              <p>Edit</p>
            </div>
            <div
              className="deleteBox"
              onClick={() => deteletePaymentCard(card && card.id)}
              style={{ cursor: "pointer" }}
            >
              <p>Delete</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

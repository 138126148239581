import React, { useContext, useEffect, useState } from "react";
import Grid from "../../../../../../../components/grid/Grid";
import DataTable from "react-data-table-component";
// import { bundleListings } from "../../../../../../services/Provider/Configurator/Listings";
import { PassThroughSVG } from "../../../../../../../assets/svgComponents/inventorySVG";
import { Col, Dropdown, DropdownButton } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
// import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  deleteListings,
  getListingEditData,
  signListings,
} from "../../../../../../../services/Provider/Configurator/Listings";
import ProjectContext from "../../../../../../../context/ProjectContext";
import { useToasts } from "react-toast-notifications";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import { ExportCSVSVG } from "../../../../../../../assets/svgComponents/table";
import { InputField } from "../../../../../../../components/InputField";
import { checkPermission } from "../../../../../../../utils";

const BundlePage = ({
  setListingName,
  setNewListingModal,
  setIsUpdateData,
  searchTerm,
  searchToggle
}) => {
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  const [bundledata, setbundleData] = useState();
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const [bundleDetails, setBundleDetails] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [selectedListRow, setSelectedListRow] = useState();
  const { selectedEntities, handleSelectedEntities, handleUpdateListing } =
    useContext(ProjectContext);

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    setSortColumn({ column: column, sortDirection: sortDirection });
  };

  const columns = [
    {
      name: "id".toUpperCase(),
      selector: (row) => row.listingId,
      sortable: true,
      sortField: "Id",
    },
    {
      name: "Name".toUpperCase(),
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
    },
    {
      name: "media".toUpperCase(),
      selector: (row) => row.mediaName,
      sortable: true,
      sortField: "Media",
    },
    {
      name: "Available For".toUpperCase(),
      selector: (row) => row.availableForText,
      sortable: true,
      sortField: "AvailableFor",
    },
    {
      name: "Pricing".toUpperCase(),
      selector: (row) => row.pricing,
      sortable: true,
      sortField: "Pricing",
    },
    {
      name: "Featured".toUpperCase(),
      cell: (row) => {
        return (
          <div>
            {row.featured ? (
              <div className="gridTableBody_td active" title={row.featuredPeriod}>Active </div>
            ) : (
              <div className="gridTableBody_td inActive">Inactive</div>
            )}
          </div>
        );
      },
      sortable: true,
      sortField: "Featured",
    },
    {
      cell: (row) => (
        <span className="gridTableBody_td_passThrough">
          <DropdownButton
            align="end"
            className="dropDownBtn"
            title={<PassThroughSVG />}
          >
            <Dropdown.Item onClick={() => handleViewGridCell(row)}>
              View
            </Dropdown.Item>
            {checkPermission("Listings", "Full") && (

              <Dropdown.Item onClick={() => handleEditGridCell(row)}>
                Edit
              </Dropdown.Item>
            )}
            {checkPermission("Listings", "Full") && (

              <Dropdown.Item onClick={() => handleDeleteGridCell(row)}>
                Delete
              </Dropdown.Item>
            )}
          </DropdownButton>
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const loadData = async () => {
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: true,
      searchString: filterText,// searchTerm,
      listingType: 2,
    };
    setLoader(true);
    await signListings(reqBody)
      .then((res) => {
        setLoader(false);
        setbundleData(res.data);
      })
      .catch((err) => console.log(err));
    // const reqBody = {
    //   page: currentPage,
    //   pageSize: pageSize,
    //   orderBy: sortColumn.column.sortField,
    //   isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
    //   export: true,
    //   searchString: "",
    //   listingType: 1,
    // };
    // setLoader(true);
    // await bundleListings(reqBody)
    //   .then((res) => {
    //     setLoader(false);
    //     setbundleData(res.data);
    //   })
    //   .catch((err) => console.log(err));
  };

  const loadExportData = async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: true,
      searchString: filterText,// searchTerm,
      listingType: 2,
    };
    await signListings(reqBody)
      .then((res) => {
        downloadCSV(res.data);
      })
      .catch((err) => console.log(err));
  };

  // fetch sign listing data
  useEffect(async () => {
    loadData();
  }, [currentPage, pageSize, sortColumn, deleteConfirmModal, searchToggle, filterText]);

  useEffect(async () => {
    loadData();
  }, []);

  const handleViewGridCell = (row) => {
    history.push({
      pathname: `${location.pathname}/information-details`,
      state: {
        selectedRow: row,
        tabs: ["INFORMATION", "SITES", "SIGNS", "REVIEWS & RATINGS"],
        isBundleInformation: true,
      },
    });
  };

  // handle for selected list update
  const handleEditGridCell = async (item) => {
    let tempEntities = [...selectedEntities];
    tempEntities.forEach((item) => {
      item.isActive = false;
    });
    // console.log(item, "item");
    if (item.listingId) {
      await getListingEditData(item.listingId).then((res) => {
        console.log(res.data, "res");
        if (res.status == 200) {
          if (res.data.siteIds.length > 0) {
            // console.log(res.data.signIds, "res");
            tempEntities.forEach((item) => {
              item.name == "Sites" && (item.isChecked = true);
            });
          } else {
            tempEntities.forEach((item) => {
              item.name == "Sites" && (item.isChecked = false);
            });
          }
          if (res.data.signIds.length > 0) {
            tempEntities.forEach((item) => {
              item.name == "Signs" && (item.isChecked = true);
            });
          } else {
            tempEntities.forEach((item) => {
              item.name == "Signs" && (item.isChecked = false);
            });
          }
          if (res.data.serviceIds.length > 0) {
            tempEntities.forEach((item) => {
              item.name == "Services" && (item.isChecked = true);
            });
          } else {
            tempEntities.forEach((item) => {
              item.name == "Services" && (item.isChecked = false);
            });
          }
          if (res.data.chargeIds.length > 0) {
            tempEntities.forEach((item) => {
              item.name == "Charges" && (item.isChecked = true);
            });
          } else {
            tempEntities.forEach((item) => {
              item.name == "Charges" && (item.isChecked = false);
            });
          }
          tempEntities[0].isActive = true;
          // tempEntities;
          // handleSelectedEntities(tempEntities);
          setListingName(res.data.name);
          setIsUpdateData(res.data);
          // handleUpdateListing(res.data);
          handleSelectedEntities(tempEntities);
          setNewListingModal(true);
          // history.push({
          //   pathname: `${location.pathname}/new-billable-item`,
          //   data: res.data,
          //   isUpdateListing: true,
          // });
        }
      });
    }
    // console.log(tempEntities, "tempEntities");
  };
  const handleDeleteGridCell = (row) => {
    setDeleteConfirmModal(true);
    setSelectedListRow(row);
  };
  const handleConfirmModalDelete = async () => {
    console.log(selectedListRow.listingId, "selectedListRow.listingId");
    await deleteListings(selectedListRow.listingId)
      .then((res) => {
        if (res.status == 204) {
          setDeleteConfirmModal(false);
          addToast("Listing deleted successfully", {
            appearance: "success",
          });
        }
      })
      .catch((err) => err);
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array.records);
    if (csv == null) return;

    const filename = "export-bundles.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <button
      className="blueBgButton"
      onClick={(e) => onExport(e.target.value)}
    >
      <span className="btnSvg"><ExportCSVSVG fill="#FFFFFF" /></span>
      Export CSV
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );

  return (
    <>
      <Col className="d-flex justify-content-start align-items-center col-6 p-2">
        <div className="tableAction_input_container w-50">
          <InputField
            placeholder="Search Bundles..."
            label={"Search Bundles..."}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      </Col>{" "}
      <DataTable
        progressPending={loader}
        columns={columns}
        data={bundledata?.records}
        allowOverflow={true}
        paginationDefaultPage={currentPage}
        pagination
        paginationServer
        paginationTotalRows={bundledata?.totalRecords}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={pageSize}
        selectableRows
        dense
        onSort={handleSort}
        sortServer
        fixedHeader={{
          fixedHeader: true,
          fixedHeaderScrollHeight: "300px",
        }}
        actions={actionsMemo}
      />

      {deleteConfirmModal && (
        <ConfirmModal
          title="Delete Bundle listing"
          isModalHeader={true}
          isFooterBtns={true}
          cancelText="No"
          confirmText="Yes"
          handleClose={() => setDeleteConfirmModal(false)}
          handleConfirm={() => handleConfirmModalDelete()}
          width="400px"
        >
          <div className="childs">
            <p className="newListingCreated_description ms-4 py-3">
              Are you sure want to delete bundle list
            </p>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default BundlePage;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  getSiteAnalytics,
  getReviewBySite,
} from "../../../../../../../services/Provider";
import NavBar from "../../../../../../../components/navBar";
import { LineChart } from "../../../../../../../components/Chart/LineChart";
import { PieChart } from "../../../../../../../components/Chart/PieChart";
import { DoughnutChart } from "../../../../../../../components/Chart/DoughnutChart";
import { CustomListGroup } from "../../../../../../../components/ListGroup/index";
import * as Icon from "react-bootstrap-icons";
import { Review } from '../../../../../../../components/reviews/Review';
import { SelectComponent  } from "../../../../../../../components/SelectionComponent/SelectComponent";
import { SelectionComponent  } from "../../../../../../../components/SelectionComponent/SelectionComponent";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";

export const InsightsPage = (siteDetails) => {
  console.log(siteDetails, "siteDetails");

  const [providerDashBoardData, setProviderDashBoardData] = useState({});

  const [salesGraphData, setSalesGraphData] = useState([]);
  const [salesGraphDataTitle, setSalesGraphDataTitle] = useState([]);
  const [siteReviews, setSiteReviews] = useState([]);

  const [siteId, setSiteId] = useState(siteDetails.siteDetails.id);

  const [customersRatingsGraphData, setcustomersRatingsGraphData] = useState(
    []
  );
  const [customersRatingsGraphDataTitle, setcustomersRatingsGraphDataTitle] =
    useState([]);
  const customersRatingsGraphColor = ["#FFE080", "#3734A9"];

  const tabs = ["INFORMATION", "LISTINGS", "USAGE", "INSIGHTS"];
  const [activeTab, setActiveTab] = useState(tabs[3]);

  const GraphByItem = [
    { id: "Year", name: "Year" },
    { id: "Month", name: "Month" },
    { id: "Day", name: "Day" },
  ];

  const today = new Date();
  const curYear = today.getFullYear();
  const curMonth = today.getMonth() + 1;
  const curDate = today.getDay();
  const lastDate = daysInMonth(curMonth, curYear);

  const [salesGraphBy, setSalesGraphBy] = useState("Year");
  const [salesYear, setSalesYear] = useState(curYear);
  //const [salesMonth, setSalesMonth] = useState(curMonth);
  //const [salesDay, setSalesDay] = useState(curDate);
  const [salesMonth, setSalesMonth] = useState("01");
  const [salesDay, setSalesDay] = useState("01");

  const [customerRatingsGraphBy, setCustomerRatingsGraphBy] = useState("Year");
  const [customerRatingsYear, setCustomerRatingsYear] = useState(curYear);
  const [customerRatingsMonth, setCustomerRatingsMonth] = useState("01");
  const [customerRatingsDay, setCustomerRatingsDay] = useState("01");

  const supportRequests = [
    { id: 1, heading: "#123", subheading: "01-03-2022" },
    { id: 2, heading: "#456", subheading: "01-03-2022" },
    { id: 3, heading: "#789", subheading: "05-04-2022" },
    { id: 4, heading: "#101", subheading: "06-05-2022" },
  ];

  const verificationRequests = [
    { id: 1, heading: "#123", subheading: "01-03-2022" },
    { id: 2, heading: "#456", subheading: "01-03-2022" },
    { id: 3, heading: "#789", subheading: "05-04-2022" },
    { id: 4, heading: "#101", subheading: "06-05-2022" },
  ];

  const reviews = [
    {
      userImg: "https://placekitten.com/60/60",
      userName: "Aron Paul",
      reviewDate: "01-03-2022",
      reviewStar: 4.5,
      reviewDesc: "Review Description 1 Review Description 1.",
    },
    {
      userImg: "https://placekitten.com/60/60",
      userName: "Karlon Carter",
      reviewDate: "01-03-2022",
      reviewStar: 3.5,
      reviewDesc: "Review Description 1 Review Description 1.",
    },
  ];

  let FromDate = curYear + "-01-01 00:00:00";
  let ToDate = curYear + "-12-31 23:59:59";
  let reqBody =
    "?SiteId=" +
    siteId +
    "&Type=Year&FromDate=" +
    FromDate +
    "&ToDate=" +
    ToDate;
  //getSiteAnalytics(reqBody);
  const [reqQuery, setReqQuery] = useState(reqBody);
  const [salesReqQuery, setSalesReqQuery] = useState("");
  /*
    function getSiteAnalytics(reqBody){
    }
    */
  // fetch data for by provider dashboard
  useEffect(() => {
    //let reqBody = '?Type=Year&FromDate='+FromDate+'&ToDate='+ToDate;
    //let reqBody = "";
    //setLoader(true);
    getSiteAnalytics(reqQuery)
      .then((res) => {
        if (res.status === 200) {
          //setLoader(false);
          console.log(res.data, "siteAnalytics");
          setProviderDashBoardData(res.data);
          let temp1 = [];
          let temp2 = [];
          if (res.data.saleGraphData != null) {
            let xvalueStart = 0;
            let xvalueEnd = 0;
            if (salesGraphBy === "Year") {
              xvalueEnd = 12;
            } else if (salesGraphBy === "Month") {
              xvalueEnd = 31;
            } else {
              xvalueStart = 0;
              xvalueEnd = 23;
            }

            for (let i = xvalueStart; i <= xvalueEnd; i++) {
              temp2.push(i);
              temp1.push("0");
            }

            res.data.saleGraphData.forEach((salesData) => {
              temp1[salesData.xValue] = salesData.yValue;
              //temp1.push(salesData.yValue)
              //temp2.push(salesData.xValue)
            });
            setSalesGraphData(temp1);
            setSalesGraphDataTitle(temp2);
          }

          temp1 = [];
          temp2 = [];
          if (res.data.reviewGraphData != null) {
            res.data.reviewGraphData.forEach((customerData) => {
              temp1.push(customerData.yValue);
              temp2.push(customerData.xValue);
            });
          }
          setcustomersRatingsGraphData(temp1);
          setcustomersRatingsGraphDataTitle(temp2);
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }, [reqQuery]);

  useEffect(() => {
    //setLoader(true);

    getReviewBySite(siteId)
      .then((res) => {
        if (res.status === 200) {
          //setLoader(false);
          console.log(res.data, "ReviewBySite");

          setSiteReviews(res.data);
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }, []);

  function daysInMonth(iMonth, iYear) {
    return new Date(iYear, iMonth, 0).getDate();
  }

  const yearList = [];
  for (var y = curYear; y >= 2021; y--) {
    let obj = { id: y, name: y };
    yearList.push(obj);
  }

  const monthList = [];
  for (var m = 1; m <= 12; m++) {
    let mon = ("0" + m).slice(-2);
    let obj = { id: mon, name: mon };
    monthList.push(obj);
  }

  let temp = [];
  for (var d = 1; d <= 31; d++) {
    let day = ("0" + d).slice(-2);
    let obj = { id: day, name: day };
    temp.push(obj);
  }

  const [salesDayList, setSalesDayList] = useState(temp);
  const [customerRatingsDayList, setCustomerRatingsDayList] = useState(temp);

  const customersRatingsGraphFooter = customersRatingsGraphDataTitle.map(
    (title, index) => (
      <span className="pr-1">
        <span
          className="border d-inline-block"
          style={{
            backgroundColor: customersRatingsGraphColor[index],
            width: "16px",
            height: "16px",
            borderRadius: "32px",
          }}
        ></span>{" "}
        {title}
      </span>
    )
  );

  // sales graph start
  const SalesGraphSelectChange = (e) => {
    setSalesGraphBy(e.id);
    let salesReqBody = "";
    if (e.id === "Year") {
      FromDate = salesYear + "-01-01 00:00:00";
      ToDate = salesYear + "-12-31 23:59:59";
      salesReqBody =
        "?SiteId=" +
        siteId +
        "&Type=Year&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate;
    } else if (e.id === "Month") {
      let lastDate = daysInMonth(salesMonth, salesYear);
      FromDate = salesYear + "-" + salesMonth + "-01 00:00:00";
      ToDate = salesYear + "-" + salesMonth + "-" + lastDate + " 23:59:59";
      salesReqBody =
        "?SiteId=" +
        siteId +
        "&Type=Month&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate;
    } else {
      FromDate = salesYear + "-" + salesMonth + "-" + salesDay + " 00:00:00";
      ToDate = salesYear + "-" + salesMonth + "-" + salesDay + " 23:59:59";
      salesReqBody =
        "?SiteId=" +
        siteId +
        "&Type=Day&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate;
    }
    //SalesDataChange(reqBody);
    setSalesReqQuery(salesReqBody);
  };

  function SalesYearChange(e) {
    setSalesYear(e.id);
    let year = e.id;
    FromDate = year + "-01-01 00:00:00";
    ToDate = year + "-12-31 23:59:59";
    let salesReqBody =
      "?SiteId=" +
      siteId +
      "&Type=" +
      salesGraphBy +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    //SalesDataChange(reqBody);
    setSalesReqQuery(salesReqBody);
  }
  function SalesMonthChange(e) {
    setSalesMonth(e.id);
    let month = e.id;
    let lastDate = daysInMonth(e.id, salesYear);
    //setCustomerDayList([]);
    let temp = [];
    for (var d = 1; d <= lastDate; d++) {
      let day = ("0" + d).slice(-2);
      let obj = { id: day, name: day };
      temp.push(obj);
    }
    setSalesDayList(temp);
    setSalesDay("01");

    FromDate = salesYear + "-" + month + "-01 00:00:00";
    ToDate = salesYear + "-" + month + "-" + lastDate + " 23:59:59";
    let salesReqBody =
      "?SiteId=" +
      siteId +
      "&Type=" +
      salesGraphBy +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    //SalesDataChange(reqBody);
    setSalesReqQuery(salesReqBody);
  }

  function SalesDayChange(e) {
    setSalesDay(e.id);
    let day = e.id;
    FromDate = salesYear + "-" + salesMonth + "-" + day + " 00:00:00";
    ToDate = salesYear + "-" + salesMonth + "-" + day + " 23:59:59";
    let salesReqBody =
      "?SiteId=" +
      siteId +
      "&Type=" +
      salesGraphBy +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setSalesReqQuery(salesReqBody);
  }

  /*
function SalesDataChange(reqBody){
}
*/
  useEffect(() => {
    if (salesReqQuery !== "") {
      getSiteAnalytics(salesReqQuery)
        .then((res) => {
          if (res.status === 200) {
            let temp1 = [];
            let temp2 = [];
            let xvalueStart = 0;
            let xvalueEnd = 0;
            if (salesGraphBy === "Year") {
              xvalueEnd = 12;
            } else if (salesGraphBy === "Month") {
              xvalueEnd = 31;
            } else {
              xvalueStart = 0;
              xvalueEnd = 23;
            }

            for (let i = xvalueStart; i <= xvalueEnd; i++) {
              temp2.push(i);
              temp1.push("0");
            }
            res.data.saleGraphData.forEach((salesData) => {
              temp1[salesData.xValue] = salesData.yValue;
            });
            setSalesGraphData(temp1);
            setSalesGraphDataTitle(temp2);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [salesReqQuery, FromDate, ToDate]);

  // sales graph end

  // customer graph start

  function CustomerRatingsGraphSelectChange(e) {
    setCustomerRatingsGraphBy(e.id);
    if (e.id === "Year") {
      FromDate = customerRatingsYear + "-01-01 00:00:00";
      ToDate = customerRatingsYear + "-12-31 23:59:59";
      reqBody =
        "?SiteId=" +
        siteId +
        "&Type=Year&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate;
    } else if (e.id === "Month") {
      let lastDate = daysInMonth(customerRatingsMonth, customerRatingsYear);
      FromDate =
        customerRatingsYear + "-" + customerRatingsMonth + "-01 00:00:00";
      ToDate =
        customerRatingsYear +
        "-" +
        customerRatingsMonth +
        "-" +
        lastDate +
        " 23:59:59";
      reqBody =
        "?SiteId=" +
        siteId +
        "&Type=Month&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate;
    } else {
      FromDate =
        customerRatingsYear +
        "-" +
        customerRatingsMonth +
        "-" +
        customerRatingsDay +
        " 00:00:00";
      ToDate =
        customerRatingsYear +
        "-" +
        customerRatingsMonth +
        "-" +
        customerRatingsDay +
        " 23:59:59";
      reqBody =
        "?SiteId=" +
        siteId +
        "&Type=Day&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate;
    }
    CustomerRatingsDataChange(reqBody);
  }

  function CustomerRatingsYearChange(e) {
    setCustomerRatingsYear(e.id);
    let year = e.id;
    FromDate = year + "-01-01 00:00:00";
    ToDate = year + "-12-31 23:59:59";
    reqBody =
      "?SiteId=" +
      siteId +
      "&Type=" +
      customerRatingsGraphBy +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    CustomerRatingsDataChange(reqBody);
  }
  function CustomerRatingsMonthChange(e) {
    setCustomerRatingsMonth(e.id);
    let month = e.id;
    let lastDate = daysInMonth(e.id, customerRatingsYear);
    let temp = [];
    for (var d = 1; d <= lastDate; d++) {
      let day = ("0" + d).slice(-2);
      let obj = { id: day, name: day };
      temp.push(obj);
    }
    setCustomerRatingsDayList(temp);
    setCustomerRatingsDay("01");

    FromDate = customerRatingsDay + "-" + month + "-01 00:00:00";
    ToDate = customerRatingsDay + "-" + month + "-" + lastDate + " 23:59:59";
    reqBody =
      "?SiteId=" +
      siteId +
      "&Type=" +
      customerRatingsGraphBy +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    CustomerRatingsDataChange(reqBody);
  }

  function CustomerDayChange(e) {
    setCustomerRatingsDay(e.id);
    let day = e.id;
    FromDate =
      customerRatingsYear +
      "-" +
      customerRatingsMonth +
      "-" +
      day +
      " 00:00:00";
    ToDate =
      customerRatingsYear +
      "-" +
      customerRatingsMonth +
      "-" +
      day +
      " 23:59:59";
    reqBody =
      "?SiteId=" +
      siteId +
      "&Type=" +
      customerRatingsGraphBy +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    CustomerRatingsDataChange(reqBody);
  }

  function CustomerRatingsDataChange(reqBody) {
    getSiteAnalytics(reqBody)
      .then((res) => {
        if (res.status == 200) {
          setProviderDashBoardData(res.data);

          const temp1 = [];
          const temp2 = [];
          res.data.customersRatioGraphData.map((customerData) => {
            temp1.push(customerData.yValue);
            temp2.push(customerData.xValue);
          });
          setcustomersRatingsGraphData(temp1);
          setcustomersRatingsGraphDataTitle(temp2);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // customer graph end

  return (
    <Container fluid className="mt-4">
      <Row>
        <Col lg={12} md={12}>
          <Row>
            <Col xs={12} className="">
              <div className="gridSection-rm">
                <div>
                  <div className="d-flex align-items-center mb-3">
                    <h1
                      className="d-inline float-start"
                      style={{
                        color: "#AFAFAF",
                        fontSize: "15px",
                        paddingRight: "10px 5px",
                        marginRight: "10px",
                        marginBottom: "0",
                      }}
                    >
                      Sales
                    </h1>
                    <p className="d-flex float-start yr-summary">
                      <div className="me-2">
                        <SelectComponent
                          label=""
                          option={GraphByItem}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={SalesGraphSelectChange}
                          value={salesGraphBy}
                        />
                      </div>
                      <div className="me-2">
                        <SelectComponent
                          label=""
                          option={yearList}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={SalesYearChange}
                        />{" "}
                      </div>
                      {salesGraphBy === "Month" || salesGraphBy === "Day" ? (
                        <SelectComponent
                          label=""
                          option={monthList}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={SalesMonthChange}
                          value={salesMonth}
                        />
                      ) : null}
                      {salesGraphBy === "Day" ? (
                        <SelectComponent
                          label=""
                          option={salesDayList}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={SalesDayChange}
                          value={salesDay}
                        />
                      ) : null}
                    </p>
                  </div>
                  <LineChart
                    graphDataTitles={salesGraphDataTitle}
                    graphData={salesGraphData}
                    bgColor="#3734A9"
                    hoverBgColor="#F9896B"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={5} md={5}>
          <Card className="text-left mb-5 chart">
            <Card.Header>
              <p className="float-start">Customers Ratings</p>
              <p className="d-flex float-end yr-summary">
                <div className="me-2">
                  <SelectComponent
                    label=""
                    option={GraphByItem}
                    inputsize="form-control-sm rouned-3 sel-chart float-start"
                    handleOnchange={CustomerRatingsGraphSelectChange}
                  />
                </div>
                <div className="me-2">
                  <SelectComponent
                    label=""
                    option={yearList}
                    inputsize="form-control-sm rouned-3 sel-chart float-start"
                    handleOnchange={CustomerRatingsYearChange}
                  />{" "}
                </div>
                {customerRatingsGraphBy === "Month" ||
                customerRatingsGraphBy === "Day" ? (
                  <SelectComponent
                    label=""
                    option={monthList}
                    inputsize="form-control-sm rouned-3 sel-chart float-start"
                    handleOnchange={CustomerRatingsMonthChange}
                  />
                ) : null}
                {customerRatingsGraphBy === "Day" ? (
                  <SelectComponent
                    label=""
                    option={customerRatingsDayList}
                    inputsize="form-control-sm rouned-3 sel-chart float-start"
                    handleOnchange={CustomerRatingsDataChange}
                  />
                ) : null}
              </p>
            </Card.Header>
            <Card.Body>
              <PieChart
                graphDataTitles={customersRatingsGraphDataTitle}
                graphData={customersRatingsGraphData}
                graphColors={customersRatingsGraphColor}
              />
            </Card.Body>
            <Card.Footer className="text-left footer-details">
              {customersRatingsGraphFooter}
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={7} md={7}>
          <Card className="text-left mb-5 chart">
            <Card.Header>
              <p>Reviews</p>
            </Card.Header>
            <Card.Body>
              {siteReviews.map((item) => (
                <Review
                  userImg={item.profilePicture}
                  userName={item.name}
                  reviewDate={item.createdOn}
                  reviewStar={item.rating}
                  reviewDesc={item.comment}
                />
              ))}
            </Card.Body>
            <Card.Footer className="text-left d-none"></Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

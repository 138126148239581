import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, ListGroup, Modal } from "react-bootstrap";
import { getCustomerDashBoard } from "../../../../../services/Customers/HomePage";
import NavBar from "../../../../../components/navBar";
import { NotificationSVG } from "../../../../../assets/svgComponents/table";
import { LineChart } from "../../../../../components/Chart/LineChart";
import { PieChart } from "../../../../../components/Chart/PieChart";
import { DoughnutChart } from "../../../../../components/Chart/DoughnutChart";
import { CustomListGroup } from "../../../../../components/ListGroup/index";

import { SelectComponent } from "../../../../../components/SelectionComponent/SelectComponent";
import { LoginModal } from "../../../../../components/Modals/LoginModal";
import { getSiteListingInfo } from "../../../../../services/api/customer-store-api";
import {
  getAvailableOffersOnSiteAsync,
  getListingReviewsAsync,
  getSimilarListingAsync,
  setListingItem,
} from "../../../../../services/reducers/listing-reducer";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import UpdatesModal from "../../../../../components/profile/Modal/UpdatesModal";
import { lastCurrentPerCalulate } from "../../../../../utils";

export const Reports = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [customerDashBoardData, setCustomerDashBoardData] = useState({});
  const [amountSpendGraphData, setAmountSpendGraphData] = useState([]);
  const [amountSpendGraphDataTitle, setAmountSpendGraphDataTitle] = useState(
    []
  );
  const [amountSpent, setAmountSpent] = useState(0);
  const [amountSpentPer, setAmountSpentPer] = useState(0);
  const [preferredSites, setPreferredSites] = useState({});
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);

  const [preferredMediaGraphData, setPreferredMediaGraphData] = useState([]);
  const [preferredMediaGraphDataTitle, setPreferredMediaGraphDataTitle] =
    useState([]);
  const preferredMediaGraphColor = [
    "#FFB141",
    "#3734A9",
    "#e6194b",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#46f0f0",
    "#f032e6",
    "#bcf60c",
    "#fabebe",
    "#008080",
    "#e6beff",
    "#9a6324",
    "#fffac8",
    "#800000",
    "#aaffc3",
    "#808000",
    "#ffd8b1",
    "#000075",
    "#808080",
    "#ffffff",
  ];
  const [preferredProviderGraphData, setPreferredProviderGraphData] = useState(
    []
  );
  const [preferredProviderGraphDataTitle, setPreferredProviderGraphDataTitle] =
    useState([]);
  const preferredProviderGraphColor = [
    "#FFB141",
    "#3734A9",
    "#e6194b",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#46f0f0",
    "#f032e6",
    "#bcf60c",
    "#fabebe",
    "#008080",
    "#e6beff",
    "#9a6324",
    "#fffac8",
    "#800000",
    "#aaffc3",
    "#808000",
    "#ffd8b1",
    "#000075",
    "#808080",
    "#ffffff",
  ];

  const today = new Date();
  const curYear = today.getFullYear();
  const curMonth = today.getMonth() + 1;
  const curDate = today.getDay();
  const lastDate = daysInMonth(curMonth, curYear);

  const [amtSpendGraphBy, setAmtSpendGraphBy] = useState({
    id: "Year",
    name: "Year",
  });
  const [amtSpendYear, setAmtSpendYear] = useState({
    id: curYear,
    name: curYear,
  });
  const [amtSpendMonth, setAmtSpendMonth] = useState({ id: "01", name: "01" });
  const [amtSpendDay, setAmtSpendDay] = useState({ id: "01", name: "01" });

  const [preferMediaGraphBy, setPreferMediaGraphBy] = useState({
    id: "Year",
    name: "Year",
  });
  const [preferMediaYear, setPreferMediaYear] = useState({
    id: curYear,
    name: curYear,
  });
  const [preferMediaMonth, setPreferMediaMonth] = useState({
    id: "01",
    name: "01",
  });
  const [preferMediaDay, setPreferMediaDay] = useState({
    id: "01",
    name: "01",
  });

  const [preferProviderGraphBy, setPreferProviderGraphBy] = useState({
    id: "Year",
    name: "Year",
  });
  const [preferProviderYear, setPreferProviderYear] = useState({
    id: curYear,
    name: curYear,
  });
  const [preferProviderMonth, sePreferProviderMonth] = useState({
    id: "01",
    name: "01",
  });
  const [preferProviderDay, setPreferProviderDay] = useState({
    id: "01",
    name: "01",
  });

  let FromDate = curYear + "-01-01 00:00:00";
  let ToDate = curYear + "-12-31 23:59:59";
  let reqBody = "?Type=Year&FromDate=" + FromDate + "&ToDate=" + ToDate;

  const [reqQuery, setReqQuery] = useState(reqBody);
  const [amtSpendReqQuery, setAmtSpendReqQuery] = useState("");

  // fetch data for by provider dashboard
  useEffect(() => {
    //let reqBody = '?Type=Year&FromDate='+FromDate+'&ToDate='+ToDate;
    //let reqBody = "";
    //setLoader(true);
    getCustomerDashBoard(reqQuery)
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data, "Customer Data");
          //setLoader(false);

          setCustomerDashBoardData(res.data);

          let temp1 = [];
          let temp2 = [];
          if (res.data.amountSpendGraphData != null) {
            let xvalueStart = 0;
            let xvalueEnd = 0;
            if (amtSpendGraphBy === "Year") {
              xvalueEnd = 12;
            } else if (amtSpendGraphBy === "Month") {
              xvalueEnd = 31;
            } else {
              xvalueStart = 0;
              xvalueEnd = 23;
            }

            for (let i = xvalueStart; i <= xvalueEnd; i++) {
              temp2.push(i);
              temp1.push("0");
            }

            res.data.amountSpendGraphData.map((spendData) => {
              temp1[spendData.xValue] = spendData.yValue;
              //temp1.push(salesData.yValue)
              //temp2.push(salesData.xValue)
            });
          }
          setAmountSpendGraphData(temp1);
          setAmountSpendGraphDataTitle(temp2);

          temp1 = [];
          temp2 = [];
          if (res.data.mediaTypeGraphData != null) {
            res.data.mediaTypeGraphData.map((preferredMediaData) => {
              temp1.push(preferredMediaData.yValue);
              temp2.push(preferredMediaData.xValue);
            });
          }
          setPreferredMediaGraphData(temp1);
          setPreferredMediaGraphDataTitle(temp2);

          temp1 = [];
          temp2 = [];
          if (res.data.preferredProviderGraphData != null) {
            res.data.preferredProviderGraphData.map((preferredProviderData) => {
              temp1.push(preferredProviderData.yValue);
              temp2.push(preferredProviderData.xValue);
            });
          }
          setPreferredProviderGraphData(temp1);
          setPreferredProviderGraphDataTitle(temp2);

          if (res.data.totalSpend != null) {
            setAmountSpent(res.data.totalSpend);
          }
          if (res.data.lastTotalSpend) {
            setAmountSpentPer(
              lastCurrentPerCalulate(
                res.data.totalSpend,
                res.data.lastTotalSpend
              )
            );
          }
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }, [reqQuery]);

  function PreferredProviderSelectChange(e) {
    e.preventDefault();
    if (e.target.value == "Year") {
      FromDate = curYear + "-01-01 00:00:00";
      ToDate = curYear + "-12-31 23:59:59";
      reqBody = "?Type=Year&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else if (e.target.value == "Month") {
      FromDate = curYear + "-" + curMonth + "-01 00:00:00";
      ToDate = curYear + "-" + curMonth + "-" + lastDate + " 23:59:59";
      reqBody = "?Type=Month&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else {
      FromDate = curYear + "-" + curMonth + "-" + curDate + " 00:00:00";
      ToDate = curYear + "-" + curMonth + "-" + curDate + " 23:59:59";
      reqBody = "?Type=Day&FromDate=" + FromDate + "&ToDate=" + ToDate;
    }
    getCustomerDashBoard(reqBody)
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data, "customer fav");
          //setLoader(false);

          //console.log(providerDashBoardData,"provider Dashboard Data");

          let temp1 = [];
          let temp2 = [];
          if (res.data.mediaTypeGraphData != null) {
            res.data.mediaTypeGraphData.map((mediaTypeGraphData) => {
              temp1.push(mediaTypeGraphData.yValue);
              temp2.push(mediaTypeGraphData.xValue);
            });
          }
          setPreferredProviderGraphData(temp1);
          setPreferredProviderGraphDataTitle(temp2);

          if (res.data.totalSpend != null) {
            setAmountSpent(res.data.totalSpend);
          }
          if (res.data.lastTotalSpend) {
            setAmountSpentPer(
              lastCurrentPerCalulate(
                res.data.totalSpend,
                res.data.lastTotalSpend
              )
            );
          }
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }

  const GraphByItem = [
    { id: "Year", name: "Year" },
    { id: "Month", name: "Month" },
    { id: "Day", name: "Day" },
  ];

  function daysInMonth(iMonth, iYear) {
    return new Date(iYear, iMonth, 0).getDate();
  }

  const preferredMediaGraphFooter = preferredMediaGraphDataTitle.map(
    (title, index) => (
      <span className="pr-1 d-flex align-items-center">
        <span
          className="border d-inline-block"
          style={{
            backgroundColor: preferredMediaGraphColor[index],
            width: "16px",
            height: "16px",
            borderRadius: "32px",
          }}
        ></span>{" "}
        <span>{title}</span>
      </span>
    )
  );
  const preferredProviderGraphFooter = preferredProviderGraphDataTitle.map(
    (title, index) => (
      <span className="pr-1 d-flex align-items-center">
        <span
          className="border d-inline-block"
          style={{
            backgroundColor: preferredProviderGraphColor[index],
            width: "16px",
            height: "16px",
            borderRadius: "32px",
          }}
        ></span>{" "}
        <span>{title}</span>
      </span>
    )
  );

  const yearList = [];
  for (var y = curYear; y >= 2021; y--) {
    let obj = { id: y, name: y };
    yearList.push(obj);
  }

  const monthList = [];
  for (var m = 1; m <= 12; m++) {
    let mon = ("0" + m).slice(-2);
    let obj = { id: mon, name: mon };
    monthList.push(obj);
  }

  let temp = [];
  for (var d = 1; d <= 31; d++) {
    let day = ("0" + d).slice(-2);
    let obj = { id: day, name: day };
    temp.push(obj);
  }

  const [amtSpendDayList, setAmtSpendDayList] = useState(temp);
  const [preferMediaDayList, setPreferMediaDayList] = useState(temp);
  const [preferProviderDayList, setPreferProviderDayList] = useState(temp);

  // Amount Spend graph start

  const AmtSpendGraphSelectChange = (e) => {
    setAmtSpendGraphBy(e);
    let amtSpendReqBody = "";
    if (e.id === "Year") {
      FromDate = amtSpendYear.id + "-01-01 00:00:00";
      ToDate = amtSpendYear.id + "-12-31 23:59:59";
      amtSpendReqBody = "?Type=Year&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else if (e.id === "Month") {
      let lastDate = daysInMonth(amtSpendMonth.id, amtSpendYear.id);
      FromDate = amtSpendYear.id + "-" + amtSpendMonth.id + "-01 00:00:00";
      ToDate =
        amtSpendYear.id + "-" + amtSpendMonth.id + "-" + lastDate + " 23:59:59";
      amtSpendReqBody =
        "?Type=Month&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else {
      FromDate =
        amtSpendYear.id +
        "-" +
        amtSpendMonth.id +
        "-" +
        amtSpendDay.id +
        " 00:00:00";
      ToDate =
        amtSpendYear.id +
        "-" +
        amtSpendMonth.id +
        "-" +
        amtSpendDay.id +
        " 23:59:59";
      amtSpendReqBody = "?Type=Day&FromDate=" + FromDate + "&ToDate=" + ToDate;
    }
    //AmtSpendDataChange(reqBody);
    setAmtSpendReqQuery(amtSpendReqBody);
  };

  function AmtSpendYearChange(e) {
    //alert(e.target.value);
    setAmtSpendYear(e);
    let year = e.id;
    FromDate = year + "-01-01 00:00:00";
    ToDate = year + "-12-31 23:59:59";
    let amtSpendReqBody =
      "?Type=" +
      amtSpendGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    //AmtSpendDataChange(reqBody);
    setAmtSpendReqQuery(amtSpendReqBody);
  }

  function AmtSpendMonthChange(e) {
    //alert(e.target.value);
    setAmtSpendMonth(e);
    let month = e.id;
    let lastDate = daysInMonth(e.id, amtSpendYear.id);
    //setCustomerDayList([]);
    let temp = [];
    for (var d = 1; d <= lastDate; d++) {
      let day = ("0" + d).slice(-2);
      let obj = { id: day, name: day };
      temp.push(obj);
    }
    setAmtSpendDayList(temp);
    setAmtSpendDay("01");

    FromDate = amtSpendYear.id + "-" + month + "-01 00:00:00";
    ToDate = amtSpendYear.id + "-" + month + "-" + lastDate + " 23:59:59";
    let amtSpendReqBody =
      "?Type=" +
      amtSpendGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    //AmtSpendDataChange(reqBody);
    setAmtSpendReqQuery(amtSpendReqBody);
  }

  function AmtSpendDayChange(e) {
    //alert(e.target.value);
    setAmtSpendDay(e);
    let day = e.id;
    FromDate =
      amtSpendYear.id + "-" + amtSpendMonth.id + "-" + day + " 00:00:00";
    ToDate = amtSpendYear.id + "-" + amtSpendMonth.id + "-" + day + " 23:59:59";
    let amtSpendReqBody =
      "?Type=" +
      amtSpendGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    //AmtSpendDataChange(reqBody);
    setAmtSpendReqQuery(amtSpendReqBody);
  }
  /*
    function AmtSpendDataChange(reqBody){
    }
    */
  useEffect(() => {
    getCustomerDashBoard(amtSpendReqQuery)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data, "Customer Data");
          //setLoader(false);
          setCustomerDashBoardData(res.data);
          let temp1 = [];
          let temp2 = [];
          if (res.data.amountSpendGraphData != null) {
            for (let i = 1; i <= 31; i++) {
              temp2.push(i);
              temp1.push("0");
            }
            res.data.amountSpendGraphData.forEach((spendData) => {
              temp1[spendData.xValue] = spendData.yValue;
              //temp1.push(salesData.yValue)
              //temp2.push(salesData.xValue)
            });
          }
          setAmountSpendGraphData(temp1);
          setAmountSpendGraphDataTitle(temp2);

          if (res.data.totalSpend != null) {
            setAmountSpent(res.data.totalSpend);
          }
          if (res.data.lastTotalSpend) {
            setAmountSpentPer(
              lastCurrentPerCalulate(
                res.data.totalSpend,
                res.data.lastTotalSpend
              )
            );
          }
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }, [amtSpendReqQuery]);

  // Amount Spend end

  // Your Preferred Media start

  const PreferMediaGraphSelectChange = (e) => {
    //  e.preventDefault();
    //alert(e.target.value);
    setPreferMediaGraphBy(e);
    if (e.id === "Year") {
      FromDate = amtSpendYear.id + "-01-01 00:00:00";
      ToDate = amtSpendYear.id + "-12-31 23:59:59";
      reqBody = "?Type=Year&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else if (e.id === "Month") {
      let lastDate = daysInMonth(amtSpendMonth.id, amtSpendYear.id);
      FromDate = amtSpendYear.id + "-" + amtSpendMonth.id + "-01 00:00:00";
      ToDate =
        amtSpendYear.id + "-" + amtSpendMonth.id + "-" + lastDate + " 23:59:59";
      reqBody = "?Type=Month&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else {
      FromDate =
        amtSpendYear.id +
        "-" +
        amtSpendMonth.id +
        "-" +
        amtSpendDay.id +
        " 00:00:00";
      ToDate =
        amtSpendYear.id +
        "-" +
        amtSpendMonth.id +
        "-" +
        amtSpendDay.id +
        " 23:59:59";
      reqBody = "?Type=Day&FromDate=" + FromDate + "&ToDate=" + ToDate;
    }
    PreferMediaDataChange(reqBody);
  };

  function PreferMediaYearChange(e) {
    //alert(e.target.value);
    setPreferMediaYear(e);
    let year = e.id;
    FromDate = year + "-01-01 00:00:00";
    ToDate = year + "-12-31 23:59:59";
    reqBody =
      "?Type=" +
      preferMediaGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    PreferMediaDataChange(reqBody);
  }
  function PreferMediaMonthChange(e) {
    //alert(e.target.value);
    setPreferMediaMonth(e);
    let month = e.id;
    let lastDate = daysInMonth(e.id, preferMediaYear.id);
    //setCustomerDayList([]);
    let temp = [];
    for (var d = 1; d <= lastDate; d++) {
      let day = ("0" + d).slice(-2);
      let obj = { id: day, name: day };
      temp.push(obj);
    }
    setPreferMediaDayList(temp);
    setPreferMediaDay("01");

    FromDate = preferMediaYear.id + "-" + month + "-01 00:00:00";
    ToDate = preferMediaYear.id + "-" + month + "-" + lastDate + " 23:59:59";
    reqBody =
      "?Type=" +
      preferMediaGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    PreferMediaDataChange(reqBody);
  }

  function PreferMediaDayChange(e) {
    //alert(e.target.value);
    setPreferMediaDay(e);
    let day = e.id;
    FromDate =
      preferMediaYear.id + "-" + preferMediaMonth.id + "-" + day + " 00:00:00";
    ToDate =
      preferMediaYear.id + "-" + preferMediaMonth.id + "-" + day + " 23:59:59";
    reqBody =
      "?Type=" +
      preferMediaGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    PreferMediaDataChange(reqBody);
  }

  function PreferMediaDataChange(reqBody) {
    getCustomerDashBoard(reqBody)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data, "Customer Data");
          //setLoader(false);
          setCustomerDashBoardData(res.data);
          let temp1 = [];
          let temp2 = [];

          if (res.data.mediaTypeGraphData != null) {
            res.data.mediaTypeGraphData.map((preferredMediaData) => {
              temp1.push(preferredMediaData.yValue);
              temp2.push(preferredMediaData.xValue);
            });
          }
          setPreferredMediaGraphData(temp1);
          setPreferredMediaGraphDataTitle(temp2);

          if (res.data.totalSpend != null) {
            setAmountSpent(res.data.totalSpend);
          }
          if (res.data.lastTotalSpend) {
            setAmountSpentPer(
              lastCurrentPerCalulate(
                res.data.totalSpend,
                res.data.lastTotalSpend
              )
            );
          }
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }

  // Your Preferred Provider start

  const PreferProviderGraphSelectChange = (e) => {
    //e.preventDefault();
    //alert(e.target.value);
    setPreferProviderGraphBy(e);
    if (e.id === "Year") {
      FromDate = preferProviderYear.id + "-01-01 00:00:00";
      ToDate = preferProviderYear.id + "-12-31 23:59:59";
      reqBody = "?Type=Year&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else if (e.target.value === "Month") {
      let lastDate = daysInMonth(preferProviderMonth.id, preferProviderYear.id);
      FromDate =
        preferProviderYear.id + "-" + preferProviderMonth.id + "-01 00:00:00";
      ToDate =
        preferProviderYear.id +
        "-" +
        preferProviderMonth.id +
        "-" +
        lastDate +
        " 23:59:59";
      reqBody = "?Type=Month&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else {
      FromDate =
        preferProviderYear.id +
        "-" +
        preferProviderMonth.id +
        "-" +
        preferProviderDay.id +
        " 00:00:00";
      ToDate =
        preferProviderYear.id +
        "-" +
        preferProviderMonth.id +
        "-" +
        preferProviderDay.id +
        " 23:59:59";
      reqBody = "?Type=Day&FromDate=" + FromDate + "&ToDate=" + ToDate;
    }
    PreferProviderDataChange(reqBody);
  };

  function PreferProviderYearChange(e) {
    //alert(e.target.value);
    setPreferProviderYear(e);
    let year = e.id;
    FromDate = year + "-01-01 00:00:00";
    ToDate = year + "-12-31 23:59:59";
    reqBody =
      "?Type=" +
      preferProviderGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    PreferProviderDataChange(reqBody);
  }
  function PreferProviderMonthChange(e) {
    //alert(e.target.value);
    sePreferProviderMonth(e);
    let month = e.id;
    let lastDate = daysInMonth(e.id, preferProviderYear.id);
    //setCustomerDayList([]);
    let temp = [];
    for (var d = 1; d <= lastDate; d++) {
      let day = ("0" + d).slice(-2);
      let obj = { id: day, name: day };
      temp.push(obj);
    }
    setPreferMediaDayList(temp);
    setPreferMediaDay("01");

    FromDate = preferProviderYear.id + "-" + month + "-01 00:00:00";
    ToDate = preferProviderYear.id + "-" + month + "-" + lastDate + " 23:59:59";
    reqBody =
      "?Type=" +
      preferProviderGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    PreferProviderDataChange(reqBody);
  }

  function PreferProviderDayChange(e) {
    //alert(e.target.value);
    setPreferProviderDay(e);
    let day = e.id;
    FromDate =
      preferProviderYear.id +
      "-" +
      preferProviderMonth.id +
      "-" +
      day +
      " 00:00:00";
    ToDate =
      preferProviderYear.id +
      "-" +
      preferProviderMonth.id +
      "-" +
      day +
      " 23:59:59";
    reqBody =
      "?Type=" +
      preferProviderGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    PreferProviderDataChange(reqBody);
  }

  function PreferProviderDataChange(reqBody) {
    getCustomerDashBoard(reqBody)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data, "Customer Data");
          //setLoader(false);
          //setCustomerDashBoardData(res.data);
          let temp1 = [];
          let temp2 = [];
          if (res.data.preferredProviderGraphData != null) {
            res.data.preferredProviderGraphData.map((preferredProviderData) => {
              temp1.push(preferredProviderData.yValue);
              temp2.push(preferredProviderData.xValue);
            });
          }
          setPreferredProviderGraphData(temp1);
          setPreferredProviderGraphDataTitle(temp2);
          if (res.data.totalSpend != null) {
            setAmountSpent(res.data.totalSpend);
          }
          if (res.data.lastTotalSpend) {
            setAmountSpentPer(
              lastCurrentPerCalulate(
                res.data.totalSpend,
                res.data.lastTotalSpend
              )
            );
          }
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }

  function GoToSiteListingDetails(siteId) {
    getSiteListingInfo(siteId).then((result) => {
      dispatch(setListingItem(result.data));
      dispatch(getSimilarListingAsync(result.data.listingItemId));
      dispatch(getListingReviewsAsync(result.data.listingId));
      dispatch(getAvailableOffersOnSiteAsync(siteId));
    });
    history.push("/customer/site-detail");
    // history.push({ pathname: `/provider/configurator/inventory/view-site`, state: { id: siteId } });
  }
  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  // Your Preferred Provider end
  const userType = localStorage.getItem("userType");
  let comp;
  if (userType === "Temp") {
    comp = <LoginModal />;
  } else {
    comp = (
      <Container fluid className="p-0">
        <Row className="g-0 p-20 py-0">
          <Col lg={9} md={9} className="bg-base-100 pt-4 dashboard-lside">
            <div className="d-flex align-items-center mb-3">
              <h1
                className="d-inline float-start"
                style={{
                  color: "#AFAFAF",
                  fontSize: "12px",
                  paddingRight: "10px",
                  marginBottom: "0",
                }}
              >
                TOTAL AMOUNT SPEND{" "}
              </h1>
              <p className="d-flex float-start yr-summary">
                <div className="me-1">
                  <SelectComponent
                    label=""
                    option={GraphByItem}
                    value={amtSpendGraphBy}
                    defaultValue={amtSpendGraphBy}
                    inputsize="form-control-sm rouned-3 sel-chart float-start"
                    handleOnchange={AmtSpendGraphSelectChange}
                  />
                </div>
                <div className="me-1">
                  <SelectComponent
                    label=""
                    option={yearList}
                    value={amtSpendYear}
                    defaultValue={amtSpendYear}
                    inputsize="form-control-sm rouned-3 sel-chart float-start"
                    handleOnchange={AmtSpendYearChange}
                  />{" "}
                </div>
                {amtSpendGraphBy.id === "Month" ||
                amtSpendGraphBy.id === "Day" ? (
                  <div className="me-1">
                    <SelectComponent
                      label=""
                      option={monthList}
                      value={amtSpendMonth}
                      defaultValue={amtSpendMonth}
                      inputsize="form-control-sm rouned-3 sel-chart float-start"
                      handleOnchange={AmtSpendMonthChange}
                    />{" "}
                  </div>
                ) : null}
                {amtSpendGraphBy.id === "Day" ? (
                  <SelectComponent
                    label=""
                    option={amtSpendDayList}
                    value={amtSpendDay}
                    defaultValue={amtSpendDay}
                    inputsize="form-control-sm rouned-3 sel-chart float-start"
                    handleOnchange={AmtSpendDayChange}
                  />
                ) : null}
              </p>
            </div>
            <div>
              <h4>
                ${amountSpent}{" "}
                <small className="mark bg-success text-white rounded-pill">
                  {" "}
                  {amountSpentPer} %
                </small>
              </h4>
            </div>
            <LineChart
              graphDataTitles={amountSpendGraphDataTitle}
              graphData={amountSpendGraphData}
              bgColor="#3734A9"
              hoverBgColor="#F9896B"
            />
            <Row>
              <Col lg={6} md={6}>
                <Card className="text-left mb-5 chart">
                  <Card.Header>
                    <p className="float-start">Your Preferred Media</p>
                    <p className="float-end d-flex float-end yr-summary">
                      <div className="me-1">
                        <SelectComponent
                          label=""
                          option={GraphByItem}
                          value={preferMediaGraphBy}
                          defaultValue={preferMediaGraphBy}
                          inputsize="form-control-sm rouned-3 sel-chart float-start me-2"
                          handleOnchange={PreferMediaGraphSelectChange}
                        />
                      </div>
                      <div className="me-1">
                        <SelectComponent
                          label=""
                          option={yearList}
                          value={preferMediaYear}
                          defaultValue={preferMediaYear}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={PreferMediaYearChange}
                        />{" "}
                      </div>
                      {preferMediaGraphBy.id === "Month" ||
                      preferMediaGraphBy.id === "Day" ? (
                        <div className="me-1">
                          <SelectComponent
                            label=""
                            option={monthList}
                            value={preferMediaMonth}
                            defaultValue={preferMediaMonth}
                            inputsize="form-control-sm rouned-3 sel-chart float-start"
                            handleOnchange={PreferMediaMonthChange}
                          />{" "}
                        </div>
                      ) : null}
                      {preferMediaGraphBy.id === "Day" ? (
                        <SelectComponent
                          label=""
                          option={preferMediaDayList}
                          value={preferMediaDay}
                          defaultValue={preferMediaDay}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={PreferMediaDayChange}
                        />
                      ) : null}
                    </p>
                  </Card.Header>
                  <Card.Body>
                    {preferredMediaGraphData ?
                    <PieChart
                      graphDataTitles={preferredMediaGraphDataTitle}
                      graphData={preferredMediaGraphData}
                      graphColors={preferredMediaGraphColor}
                    />:<>No data available</>
                    }
                  </Card.Body>
                  <Card.Footer className="text-left footer-details">
                    {preferredMediaGraphFooter}
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg={6} md={6}>
                <Card className="text-left mb-5 chart">
                  <Card.Header>
                    <p className="float-start">Your Preferred Providers</p>
                    <p className="d-flex float-end yr-summary">
                      <div className="me-1">
                        <SelectComponent
                          label=""
                          option={GraphByItem}
                          value={preferProviderGraphBy}
                          defaultValue={preferProviderGraphBy}
                          inputsize="form-control-sm rouned-3 sel-chart float-start me-2"
                          handleOnchange={PreferProviderGraphSelectChange}
                        />
                      </div>
                      <div className="me-1">
                        <SelectComponent
                          label=""
                          option={yearList}
                          value={preferProviderYear}
                          defaultValue={preferProviderYear}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={PreferProviderYearChange}
                        />{" "}
                      </div>
                      {preferProviderGraphBy.id === "Month" ||
                      preferProviderGraphBy.id === "Day" ? (
                        <div className="me-1">
                          <SelectComponent
                            label=""
                            option={monthList}
                            value={preferProviderMonth}
                            defaultValue={preferProviderMonth}
                            inputsize="form-control-sm rouned-3 sel-chart float-start"
                            handleOnchange={PreferProviderMonthChange}
                          />{" "}
                        </div>
                      ) : null}
                      {preferProviderGraphBy.id === "Day" ? (
                        <SelectComponent
                          label=""
                          option={preferProviderDayList}
                          value={preferProviderDay}
                          defaultValue={preferProviderDay}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={PreferProviderDayChange}
                        />
                      ) : null}
                    </p>
                  </Card.Header>
                  <Card.Body>
                    {preferredProviderGraphData?
                    <DoughnutChart
                      graphDataTitles={preferredProviderGraphDataTitle}
                      graphData={preferredProviderGraphData}
                      graphColors={preferredProviderGraphColor}
                    />:<>No data available</>
                  }
                  </Card.Body>
                  <Card.Footer className="text-left footer-details">
                    {preferredProviderGraphFooter}
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg={3} md={3} className="dashboard-rside p-0">
            <div className="mt-4">
              <h5 className="mb-3">Your Preferred Locations </h5>
              {customerDashBoardData.preferredSites?
              <ListGroup as="ol" style={{ cursor: "pointer" }}>
                {customerDashBoardData.preferredSites?.map((c) => (
                  <ListGroup.Item
                    key={c.siteId}
                    as="li"
                    id={"siteId_" + c.siteId}
                    className="d-flex justify-content-between align-items-center border-0 border-bottom"
                    variant="light"
                  >
                    {/* <CustomListGroup
                      id={c.siteId}
                      heading={c.name}
                      subheading={c.address}
                      link={c.siteId}
                      handleViewBTN={(id, link) => {
                        GoToSiteListingDetails(id);
                      }}
                    /> */}
                    <CustomListGroup
                      id={c.siteId}
                      heading={c.name}
                      subheading={c.address}
                      handleViewBTN={() => GoToSiteListingDetails(c.siteId)}
                      link={true}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
              :<ListGroup><>No data available</></ListGroup>}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <NavBar
        notificationSVG={<NotificationSVG />}
        navHeading="Reporting"
        subHeading=""
        handleNotificationSVG={handleNotificationSVG}
        showButton={true}
      />
      {comp}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};



export const getImageUrl = (url) => {
    if(url)
        return `https://gopinit.blob.core.windows.net/image-container/${url}`;
    else
        return `../assets/images/no-image.png`;
}

export const getImagesUrl = (urls) => {
    if(urls?.length>0){
        let tempUrl=[];
        urls.map((url)=>{
            tempUrl.push(`https://gopinit.blob.core.windows.net/image-container/${url}`);
       })
    return tempUrl;
    }
    else
        return [`../assets/images/no-image.png`];
}

export const getRadiusOfOperation = (lat,zoom) => {
    // let metersPerPx = 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom);
    // return metersPerPx;
    let level = parseFloat(zoom);
    let result = 591657550.500000 / Math.pow( 2, level-1);
    //let result = 591657550.500000 / Math.pow( 2, level);
    return result
}

export const getRadiusOfOperationForSign = (lat,zoom) => {
    let level = parseFloat(zoom);
    let result = 591657550.500000 / Math.pow( 2, level);
    return result
}

export const capitalize = (input) => {  
    var words = input.split(' ');  
    var CapitalizedWords = [];  
    words.forEach(element => {  
        CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));  
    });  
    return CapitalizedWords.join(' ');  
}  

export const To2Decimal = (value) => {
    if(value)
        return parseFloat(value).toFixed(2);
    else 
        return value;
}

export const GetOnlyAddressFromCompoundCode = (compound_code) => {
    if(compound_code){
        return compound_code.substring(compound_code.indexOf(' '));
    return compound_code;
    }
}

export const getDistance = (latlng1, latlng2) => { 
    var r = 3963.0;
    var lat1= latlng1.lat / 57.2958;
    var lon1= latlng1.lng / 57.2958;
    var lat2= latlng2.lat / 57.2958;
    var lon2= latlng2.lng / 57.2958;

    var dis = r * Math.acos(Math.sin(lat1)* Math.sin(lat2) + Math.cos(lat1)* Math.cos(lat2) * Math.cos(lon2-lon1));    
    //return dis * (1609.34);
    return dis  * 1.6;
  }
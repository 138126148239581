import React from "react";
import {useDispatch} from "react-redux";
import { filterCBChanged } from "../../../../services/reducers/filter-reducers";

const FilterRadioButton = ({ type, option, name }) => {
  const dispatch = useDispatch();
  return (
    <>
      <label className="radio radio-gradient d-flex align-items-center">
        <input
          disabled={option.disabled}
          type="radio"          
          name={name}
          value={option.value}
          checked={option.isChecked}
          onChange={(e)=>dispatch(filterCBChanged({type,id:option.id,checked:e.target.checked}))}  
        />
        <span className={`${option.isChecked ? "checkBoxChecked" : "checkBoxCheck"}`}>
        </span>
        {option.name && (
          <span className={` ms-4 px-2 checkbox_title`} style={{marginLeft:'0 !important', paddingLeft:'0 !important'}}>
            {option.name}
          </span>
        )}
      </label>
    </>
  );
};

export default FilterRadioButton;

import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import BtnRadio from "../../../../../../components/BtnRadio/BtnRadio";
import BtnBlue from "../../../../../../components/button/BtnBlue";
import Checkbox from "../../../../../../components/checkbox/Checkbox";
import { InputField } from "../../../../../../components/InputField";
import { getEnvironment } from "../../../../../../services/Provider";
import CustomCheckbox from "../../../../../../components/checkbox/CustomCheckbox";

const SiteFilters = ({
  handleStatusValues,
  handleLocationValue,
  handleEnvironmentValues,
  applyFilter,
  handleClearAllFilter,
  statusValue,
  locationValue,
  environmentValue,
}) => {
  const [environment, setEnvironment] = useState();
  const [loader, setLoader] = useState(false);

  // fetch environment filter listing data
  useEffect(async () => {
    setLoader(true);
    await getEnvironment()
      .then((res) => {
        if (res.status == 200) {
          setLoader(false);
          setEnvironment(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <a onClick={handleClearAllFilter} style={{cursor: 'pointer'}}>
            Filters <span className="badgeMain ms-1">Clear all</span>
          </a>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="list_title pt-4 pb-3">
          <b>Status</b>
        </div>
        <div className="d-flex pb-4">
          <div className="pe-4">
            <BtnRadio
              name="Status"
              label="Active"
              value="Active"
              onChangeStatus={(e) => handleStatusValues(e)}
              status={statusValue == "1" ? true : false}
            />
          </div>
          <div className="pe-4">
            <BtnRadio
              name="Status"
              label="Inactive"
              value="Inactive"
              onChangeStatus={(e) => handleStatusValues(e)}
              status={statusValue == "0" ? true : false}
            />
          </div>
          <div className="pe-4">
            <BtnRadio
              name="Status"
              label="Both"
              value="Both"
              onChangeStatus={(e) => handleStatusValues(e)}
              status={statusValue === null ? true : false}
            />
          </div>
        </div>
        <div className="list_title pb-3">
          <b>Location</b>
        </div>
        <div className="pb-4">
          <InputField
            label="Search City, Area, Postal code"
            placeholder="Search City, Area, Postal code"
            onChange={(e) => handleLocationValue(e)}
            value={locationValue}
          />
        </div>
        <div className="list_title ">
          <b>Environment / Type of Site</b>
        </div>
        {environment?.map((list) => {
          if (environmentValue.filter((item) => item === list.id).length > 0) {
            return (
              <Row className="checkbox_container">
                <Col md={12}>
                  <CustomCheckbox
                    text={list.name}
                    value={list.id}
                    action={(e) => handleEnvironmentValues(e)}
                    checked
                  />
                </Col>
              </Row>
            );
          } else {
            return (
              <Row className="checkbox_container">
                <Col md={12}>
                  <CustomCheckbox
                    text={list.name}
                    value={list.id}
                    action={handleEnvironmentValues}
                  />
                </Col>
              </Row>
            );
          }
        })}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <BtnBlue onClick={() => applyFilter()}>Apply</BtnBlue>
        </div>
      </Modal.Footer>
    </>
  );
};

export default SiteFilters;

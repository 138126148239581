import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../api/notifications-api";
import { getOptions, processFilterOptions } from '../filters-base';

const initialState = {
    notifications:{ records:[]},
    notiHeading : ["ID", "DATE", "TITLE", "MESSAGE", "STATUS"],
    notiAccessorName : [ "id","scheduleDateTime","title","message","status",],
    page : 0,
    pageSize : 10,
    export : false,
    searchString : '',
}

export const getNotificationsAsync = createAsyncThunk('transactions/getNotificationsAsync',
    async(request) => {
    const response = await getNotifications(request);
    return response.data.data;
    }
)

export const getTransactionTypeOptionsAsync = createAsyncThunk('filters/getMediaOptionsAsync',
    async() => {
    const response = await getOptions('TransactionTypes');
    return processFilterOptions(response.data);
    }
)

export const getSortingOptionsAsync = createAsyncThunk('filters/getSortingOptionsAsync',
    async() => {
    const response = await getOptions('DatePriceSorting');
    return {options:processFilterOptions(response.data)};
    }
)

const notificationsSlice = createSlice({
    name:"transactions",
    initialState,
    reducers:{
        getNotifications: (state,payload)=>{
            state.notifications = payload;
        }, 
    },
    extraReducers:{
        [getNotificationsAsync.pending] : () => {
             
        },
        [getNotificationsAsync.fulfilled] : (state,{payload}) => {
            console.log("full filled",payload);
            return {...state, notifications : payload}
        },[getNotificationsAsync.rejected] : () => {
            console.log("rejected");
        }, 
    }
}); 
export default notificationsSlice.reducer;

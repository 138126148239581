import React from "react";
import { Pagination } from "react-bootstrap";

const TableFooter = (props) => {
  // console.log(props, "tablefooter");

  return (
    <>
      <div className="gridTableFooter pt-3">
        {props.isRecordShow && (
          <div className="gridTableRowShowing">
            Showing{" "}
            {props.tableBody?.records ? props.tableBody?.records.length : 0}
            &nbsp;-&nbsp;
            {props.tableBody?.pageSize ? props.tableBody?.pageSize : 0}&nbsp;out
            of {props.tableBody?.totalRecords}
          </div>
        )}
        {props.isPagination && (
          <div>
            <Pagination>
              <Pagination.Prev />
              <Pagination.Item className="">{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>

              <Pagination.Next className="active" />
            </Pagination>
          </div>
        )}
      </div>
    </>
  );
};

export default TableFooter;

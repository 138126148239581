import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Inventory from ".";
import { AddChargePage } from "./components/AddChargePage/AddChargePage";
import { AddServicePage } from "./components/AddServicePage/AddServicePage";
import { AddSignPage } from "./components/AddSignPage/AddSignPage";
import { AddSitePage } from "./components/AddSitePage/AddSitePage";
import { ViewChargesInfo } from "./components/ViewChargesInfo/ViewChargesInfo";
import { ViewServiceInfo } from "./components/ViewServiceInfo/ViewServiceInfo";
import { ViewSignInfo } from "./components/ViewSignInfo/ViewSignInfo";
import { ViewSiteInfo } from "./components/ViewSiteInfo/ViewSiteInfo";
import { useHistory } from "react-router-dom";
import { EditSignPage } from "./components/EditSignPage/EditSignPage";
import { EditServicePage } from "./components/EditServicePage/EditServicePage";
import { EditChargePage } from "./components/EditChargePage/EditChargePage";
import {
  getSiteDetails,
  inventorySignDetails,
} from "../../../../../services/Provider/Configurator/Inventory";
import { EditSitePage } from "./components/EditSitePage/EditSitePage";
export const InventoryRoutes = ({ ...rest }) => {
  const [signsData, setSignsData] = useState();
  const [siteData, setSiteData] = useState();

  const history = useHistory();
  const handleSiteSave = () => {
    history.goBack("/provider/configurator/Inventory");
  };
  const handleSiteCancel = () => {
    history.goBack("/provider/configurator/Inventory");
  };

  useEffect(async () => {
    const reqBody = {
      page: 1,
      pageSize: 10,
      orderBy: "",
      export: true,
      searchString: "",
      status: true,
    };
    await inventorySignDetails("6ae9c60f-9d30-4d7d-4a0a-08da151e159f", reqBody)
      .then((res) => {
        if (res.status == 200) {
          // console.log('res.data', res.data)
          setSignsData(res.data);
        }
      })
      .catch((err) => console.log(err));

    await getSiteDetails("bde553a6-22e3-488c-5be9-08da0ca17101")
      .then((res) => {
        if (res.status == 200) {
          // console.log('res.data', res.data)
          setSiteData(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Switch>
      <Route
        path={`${rest.match.url}/`}
        exact
        render={(props) => <Inventory {...props} />}
      />
      <Route
        path={`${rest.match.url}/add-site`}
        render={(props) => (
          <AddSitePage
            {...props}
            handleOnSavebtn={handleSiteSave}
            handleOnCancel={handleSiteCancel}
          />
        )}
      />
      <Route
        path={`${rest.match.url}/add-sign`}
        render={(props) => <AddSignPage {...props} />}
      />
      <Route
        path={`${rest.match.url}/add-services`}
        render={(props) => <AddServicePage {...props} />}
      />
      <Route
        path={`${rest.match.url}/add-charges`}
        render={(props) => <AddChargePage {...props} />}
      />

      {/* View Info routes */}

      <Route
        path={`${rest.match.url}/view-site`}
        render={(props) => <ViewSiteInfo {...props} siteData={siteData} />}
      />
      <Route
        path={`${rest.match.url}/view-sign`}
        render={(props) => <ViewSignInfo {...props} signsData={signsData} />}
      />
      <Route
        path={`${rest.match.url}/view-services`}
        render={(props) => <ViewServiceInfo {...props} />}
      />
      <Route
        path={`${rest.match.url}/view-charges`}
        render={(props) => <ViewChargesInfo {...props} />}
      />

      {/* Edit info routes */}
      <Route
        path={`${rest.match.url}/edit-site`}
        render={(props) => <EditSitePage {...props} siteData={siteData} />}
      />
      <Route
        path={`${rest.match.url}/edit-sign`}
        render={(props) => <EditSignPage {...props} signsData={signsData} />}
      />
      <Route
        path={`${rest.match.url}/edit-services`}
        render={(props) => <EditServicePage {...props} />}
      />
      <Route
        path={`${rest.match.url}/edit-charges`}
        render={(props) => <EditChargePage {...props} />}
      />
    </Switch>
  );
};

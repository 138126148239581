/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { NotificationSVG } from "../../../../../assets/svgComponents/table";
import NavBar from "../../../../../components/navBar";
import {
  deleteBySign,
  getReviewByListing,
  getReviewBySign,
  getReviewByUser,
} from "../../../../../services/Admin/RatingAndReview";
import { getDetailSiteList } from "../../../../../services/Provider";
import {
  userList,
  userListReview,
  updateUserStatus,
} from "../../../../../services/Provider/UserManagement/UserManagement";
import { SwitchPage } from "../Components/SwitchPage";
import { FilterModal } from "../Modals/FilterModal";
import { Reviews } from "./Reviews";
// import { toast } from "react-toastify";
import { SearchBox } from "../../Orders/SearchBox";
import { UnionSVG } from "../../../../../assets/svgComponents/table";
import { useDispatch, useSelector } from "react-redux";
import { getProvidersForFilters } from "../../../../../services/api/orders-store-api";
import { getSiteList } from "../../../../../services/Provider";
import { useToasts } from "react-toast-notifications";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";

//import { getAllOrders } from "../../../../services/Provider/OrderAndTransactions/OrderAndTransactions";
export const ReviewsAndRatings = () => {
  const { addToast } = useToasts();
  const tabs = ["BY SITES & SIGNS", "BY USERS"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const checkBoxFilters = useSelector((state) =>
    state.filters.checkBoxFilters.filter((r) => r.page == "orders")
  );
  const sortingOptions = useSelector((state) => state.filters.sortingOptions);
  const [showTranFilterModal, setShowTranFilterModal] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecordsInData,setTotalRecordsInData] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [selectedOrdersCard, setSelectedOrdersCard] = useState("");
  const [selectedListingId, setSelectedListingId] = useState("");
  const transactionLable = ["Bank Transfer", "Bank Transfer", "Bank Transfer"];
  const [refreshKey, setRefreshKey] = useState(0);
  const [loader, setLoader] = useState(false);
  const [siteSearchString, setSiteSearchString] = useState("");
  const [userSearchString, setUserSearchString] = useState("");
  const [selProviders, setSelProviders] = useState([]);
  const [sortValue, setSortValue] = useState("");
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteReviewId, setDeleteReviewId] = useState(null);
  const [siteReviewPage, SetSiteReviewPage] = useState(1);
  const [userReviewPage, SetUserReviewPage] = useState(1);
  const [isStatusChange, SetIsStatusChange] = useState(false);

  const sortByLable = [
    {
      name: "Date - Latest to Oldest",
      value: "true"
    },
    {
      name: "Date - Oldest to Latest",
      value: "false"
    },
  ];
  const [providerData, setProviderData] = useState();
  const [siteData, setSiteData] = useState();

  const handleOrderCard = (id, listingId) => {
    setSelectedOrdersCard(id);
    setSelectedListingId(listingId);
  };

  useEffect(async () => {
    SetIsStatusChange(false);
    setLoader(true);
    if (activeTab == "BY USERS") {
      const reqBody = {
        page: 1,
        pageSize: 10,
        orderBy: "",
        isOrderByDesc: true,
        export: false,
        searchString: "",
      };
      await userListReview(reqBody)
        .then((res) => {
          setLoader(false);
          if (res.status === 200) {
            setData(res.data.records);
            setTotalRecordsInData(res.data.records.length);
            if (res.data.records.length > 0)
              setSelectedOrdersCard(res.data.records[0].id);
          }
        })
        .catch((err) => console.log(err));
    } else {
      const reqBody = {
        page: 1,
        pageSize: 10,
        isOrderByDesc: true,
        export: true,
        searchString: "",
        providerIds: selProviders,
      };

      await getDetailSiteList(reqBody)
        .then((res) => {
          setLoader(false);
          if (res.status === 200) {
            setData(res.data.records);
            setTotalRecordsInData(res.data.records.length);
            if (res.data.records.length > 0){
              setSelectedOrdersCard(res.data.records[0].id);
              setSelectedListingId(res.data.records[0].listingId);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [activeTab, refreshKey, isStatusChange]);

  const searchInSitePage = async(sortFilter, selProviderFilter) => {
    SetSiteReviewPage(1);
    const reqBody = {
      page: 1,
      pageSize: 10,
      isOrderByDesc: sortFilter,
      export: true,
      searchString: siteSearchString,
      providerIds: selProviderFilter,
    };

    await getDetailSiteList(reqBody)
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          setData(res.data.records);
          setTotalRecordsInData(res.data.records.length);
            if (res.data.records.length > 0)
              setSelectedOrdersCard(res.data.records[0].id);
              setSelectedListingId(res.data.records[0].listingId);
              setShowTranFilterModal(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const searchInUserPage = async() => {
    SetUserReviewPage(1);
    const reqBody = {
      page: 1,
      pageSize: 10,
      orderBy: "",
      isOrderByDesc: true,
      export: false,
      searchString: userSearchString,
    };
    await userListReview(reqBody)
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          setData(res.data.records);
          setTotalRecordsInData(res.data.records.length);
          if (res.data.records.length > 0)
            setSelectedOrdersCard(res.data.records[0].id);
            else
            setSelectedOrdersCard("");
        }
      })
      .catch((err) => console.log(err));
  };

  const updateSiteReviewList = async (pageNo1) => {
    SetSiteReviewPage(pageNo1);
    const reqBody = {
      page: pageNo1,
      pageSize: 10,
      orderBy: "",
      isOrderByDesc: sortValue,
      export: true,
      searchString: siteSearchString,
      providerIds: selProviders,
    };

    await getDetailSiteList(reqBody)
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          let tempData = data.concat(res.data.records);
          setData(tempData);
          setTotalRecordsInData(res.data.records.length);
        }
      })
      .catch((err) => console.log(err));
  };

  const updateUserReviewList = async (pageNo) => {
    SetUserReviewPage(pageNo);
    const reqBody = {
      page: pageNo,
      pageSize: 10,
      orderBy: "",
      isOrderByDesc: true,
      export: false,
      searchString: userSearchString,
    };
    await userListReview(reqBody)
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          let tempData = data.concat(res.data.records);
          setData(tempData);
          setTotalRecordsInData(res.data.records.length);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(async () => {
    const reqBody = {
      page: 0,
      pageSize: 10,
      orderBy: "",
      isOrderByDesc: true,
      export: true,
      searchString: "",
      notVerified: true,
    };

    await getProvidersForFilters(reqBody)
      .then((res) => {
        if (res.status === 200) {
          setProviderData(res.data.records);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  
  useEffect(async () => {
    if(activeTab=="BY USERS")
    loadReviews(selectedOrdersCard, activeTab);
    else
    loadReviews(selectedListingId, activeTab);
  }, [selectedOrdersCard, activeTab, selectedListingId]);

  const loadReviews = async (id, tbval) => {
    if (tbval == "BY USERS") {
      await getReviewByUser(id)
        .then((res) => {
          if (res.status === 200) {
            setReviews(res.data);
          }
        })
        .catch((err) => console.log(err));
    } else {
      await getReviewByListing(id)
        .then((res) => {
          if (res.status === 200) {
            setReviews(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const deleteReviewShowConfimation = (id) => {
    setDeleteConfirmModal(true);
    setDeleteReviewId(id);
  };

  const deleteReview = async () => {
    await deleteBySign(deleteReviewId)
      .then((res) => {
        if (res.status === 204||res.status === 200) {
          if(activeTab=="BY USERS")
            loadReviews(selectedOrdersCard, activeTab);
          else
            loadReviews(selectedListingId, activeTab);
          addToast("Review deleted successfully.", { appearance: "success" });
          setDeleteConfirmModal(false);
          setDeleteReviewId(null);
        }
      })
      .catch((err) => {
        addToast("There is an error while deleting the review.", {
          appearance: "warning",
        });
      });
  };

  const blockReview = async (tbval, userId) => {
    if (tbval === tabs[0]) {
    } else {
      const reqBody = {
        userId: userId,
        isDisabled: 1,
      };
      updateUserStatus(reqBody)
        .then(() => {
          addToast("User status successfully updated.", {
            appearance: "success",
          });
          // toast.success("User status successfully updated.")
          setRefreshKey((refreshKey) => refreshKey + 1);
        })
        .catch(() => {
          addToast("There is an error while updating status", {
            appearance: "warning",
          });
          // toast.error("There is an error while updating status");
        });
    }
  };

  const handleProviderChange = (e) => {
    if (e.target.checked) {
      setSelProviders([...selProviders, e.target.value]);
    } else {
      setSelProviders(
        selProviders.filter((provider) => provider !== e.target.value)
      );
    }
  };

  const handleSortChange = (e) => {
    if (e.target.checked) {
      setSortValue(e.target.value);
    }
  };

  const handleClearAllFilter =() =>{
    setSortValue("");
    setSelProviders([]);
    searchInSitePage(true, []);
  }

  return (
    <>
      <NavBar
        notificationSVG={<NotificationSVG />}
        navHeading="Reviews & Ratings"
        subHeading=""
        showButton={false}
      />
      <div className="gridSection p-20 bg-base-100 pe-0 row">
        <div className="col-md-12 pe-0">
          <div className="tableActions_container mb-4">
            <Row className="">
              <Col className="d-flex ">
                <ul className="tableActions_tabs">
                  {tabs.map((item) =>
                    item === activeTab ? (
                      <li
                        className="tableActions_tab active"
                        onClick={() => {
                          setActiveTab(item);
                        }}
                      >
                        {item} <hr className="tableActions_tab_hr" />
                      </li>
                    ) : (
                      <li
                        className="tableActions_tab"
                        onClick={() => {
                          setActiveTab(item);
                        }}
                      >
                        {item}
                      </li>
                    )
                  )}
                </ul>
              </Col>
              <FilterModal
                show={showTranFilterModal}
                setShowTranFilterModal={setShowTranFilterModal}
                transactionLable={transactionLable}
                sortByLable={sortByLable}
                handleSortChange={(e) => handleSortChange(e)}
                providerlable={providerData}
                handleProviderChange={(e) => handleProviderChange(e)}
                sitelable={siteData}
                handleApplyBtn={()=>searchInSitePage(sortValue, selProviders)}
                selectedProviders={selProviders}
                sortValue={sortValue}
                handleClearAllFilter={handleClearAllFilter}
              />
            </Row>
          </div>
          <div className="row">
            <div className="col-6">
              {loader ? (
                null //<>Loading......!</>
              ) : (
                <div className="informationMainContainer">
                  <div className=" row w-100 g-0">
                    <div className="section_1 col-md-12">
                      <div className="row">
                        {(() => {
                          if (activeTab === tabs[0]) {
                            return (
                              <>
                                <div className="col-md-9">
                                  <SearchBox
                                    label="Search Location & Sign...."
                                    handleTermChange={(term) => {
                                      setSiteSearchString(term);
                                    }}
                                    onSearch={()=>searchInSitePage(true,[])}
                                    searchafter={"searchafter"}
                                  />
                                </div>
                                <div className="col-md-3 text-end pe-0">
                                  <button
                                    className="secondaryBtn d-flex justify-content-around"
                                    onClick={() => setShowTranFilterModal(true)}
                                  >
                                    <span>Filter</span>
                                    <span className="btnSvg mx-2">
                                      <UnionSVG />
                                    </span>
                                  </button>
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <div className="col-md-11">
                                <SearchBox
                                  label="Search Users"
                                  handleTermChange={(term) => {
                                    setUserSearchString(term);
                                  }}
                                  onSearch={searchInUserPage}
                                  searchafter={"searchafter"}
                                />
                              </div>
                            );
                          }
                        })()}
                        <div
                        
                          className={totalRecordsInData > 0 ? "ordercardConainer scrollOverBottom" : "ordercardConainer scrollOverBottomDone" }
                          id="ReviewPage"
                          style={{ height:"calc(100vh - 240px)", overflowY: "auto" }}
                        >
                          <SwitchPage
                            activeTab={activeTab}
                            data={data}
                            handleOrderCard={handleOrderCard}
                            selectedOrdersCard={selectedOrdersCard}
                            setUserPage={(pageNo) =>
                              updateUserReviewList(pageNo)
                            }
                            setSitePage={(pageNo1) =>
                              updateSiteReviewList(pageNo1)
                            }
                            userPage={userReviewPage}
                            sitePage={siteReviewPage}
                            SetIsStatusChange={SetIsStatusChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-6">
              <div
                className="allreviews bg-gray-800"
                style={{ height:"calc(100vh - 65px)", overflowY: "auto" }}
              >
                <Reviews
                  reviews={reviews}
                  activeTab={activeTab}
                  blockReview={blockReview}
                  loadReviews={loadReviews}
                  deleteReview={deleteReviewShowConfimation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteConfirmModal && (
        <ConfirmModal
          title="Delete Review"
          isModalHeader={true}
          isFooterBtns={true}
          cancelText="No"
          confirmText="Yes"
          handleClose={() => setDeleteConfirmModal(false)}
          handleConfirm={() => deleteReview()}
          width="400px"
        >
          <div className="childs">
            <p className="newListingCreated_description ms-4 py-3">
              Are you sure you want to delete this review?
            </p>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { NotificationSVG } from "../../../../../assets/svgComponents/table";
import { BtnSwitch } from "../../../../../components/BtnSwitch/BtnSwitch";
import NavBar from "../../../../../components/navBar";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { GoPinitMapsSites } from "../../../../../components/Maps/GoPinitMapsSites";
import { getFavoritesAsync } from "../../../../../services/reducers/customer-reducer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ListingItemCard } from "../ListingItemCard";
import {
  setLatitude,
  setLongitude,
  setMapZoom,
  getAvailableOffersOnSiteAsync,
  getListingReviewsAsync,
  getListItemDetailsAsync,
  getSimilarListingAsync,
  setListingItem,
  setMapDistance,
  emptyMarkFavouriteResult,

} from "../../../../../services/reducers/listing-reducer";
import {
  getSiteListingInfo,
  getSignListingInfo,
} from "../../../../../services/api/customer-store-api";
import { LoginModal } from "../../../../../components/Modals/LoginModal";
import InfiniteScroll from "react-infinite-scroll-component";
import UpdatesModal from "../../../../../components/profile/Modal/UpdatesModal";
import { useToasts } from "react-toast-notifications";
import { capitalize } from "../../../../../services/utility";

export const Favorites = () => {
  const dispatch = useDispatch();
  const { markFavouriteResult } = useSelector((state) => state.listing);
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);
  const { latitude, longitude } = useSelector((state) => state.listing);
  const favorites = useSelector((state) => state.customer.favorites);
  const [loader, setLoader] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [page, setPage] = useState(1);
  const [favType, setFavType] = useState(0);
  const history = useHistory();
  const { addToast } = useToasts();


  useEffect(async () => {
    getFavorites();
  }, [page]);

  useEffect(async () => {
    if (page !== 1) {
      setPage(1);
    } else {
      getFavorites();
    }
  }, [favType]);

  useEffect(async () => {
    console.log(`fav ${markFavouriteResult}`);
    if (markFavouriteResult) {
      if (markFavouriteResult.status === 1) {
        let type = markFavouriteResult.type;
        if (type === "detailpage" || type === "providersite") type = "listing";
        type = capitalize(type);
        let msg = `${type} ${markFavouriteResult.title} marked as ${markFavouriteResult.action} !`;
        addToast(msg, { appearance: "success" });
      } else {
        let msg = `Unable to marked as ${markFavouriteResult.action} !`;
        addToast(msg, { appearance: "error" });
      }
      await dispatch(emptyMarkFavouriteResult());
    }
  }, [markFavouriteResult]);

  const getFavorites = () => {
    setLoader(true);
    let reqBody = {
      page: page,
      pageSize: 10,
      detailsType: favType,
    };
    dispatch(getFavoritesAsync(reqBody));
    setLoader(false);
  };

  const handleListing = (card) => {};
  const handleDetailpage = (type, id, itemId, listingId) => {
    console.log("listing details:", type, id, itemId, listingId);
    if (type == "listing" && itemId) {
      dispatch(getListItemDetailsAsync(itemId));
      dispatch(getSimilarListingAsync(itemId));
      dispatch(getListingReviewsAsync(listingId));
      dispatch(getAvailableOffersOnSiteAsync(id));
      history.push("/customer/site-detail");
    } else if (type === "site") {
      getSiteListingInfo(id).then((result) => {
        dispatch(setListingItem(result.data));
        dispatch(getSimilarListingAsync(result.data.listingItemId));
        dispatch(getListingReviewsAsync(result.data.listingId));
        dispatch(getAvailableOffersOnSiteAsync(id));
      });
      history.push("/customer/site-detail");
    } else if (type === "sign") {
      getSignListingInfo(id).then((result) => {
        dispatch(setListingItem(result.data));
        dispatch(getSimilarListingAsync(result.data.listingItemId));
        dispatch(getListingReviewsAsync(result.data.listingId));
        dispatch(getAvailableOffersOnSiteAsync(result.data.siteId));
      });
      history.push("/customer/site-detail");
    }
  };
  const userType = localStorage.getItem("userType");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  useEffect(() => {
    if (favorites && favorites.length > 0) {
      if (favorites[0]?.latitude != "" && favorites[0]?.longitude != "") {
        setLat(favorites[0]?.latitude);
        setLng(favorites[0]?.longitude);
        console.log(favorites[0]?.latitude, favorites[0]?.longitude, "latlag");
      }
    } else {
      const location = window.navigator && window.navigator.geolocation;
      if (location) {
        location.getCurrentPosition((position) => {
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        });
      }
    }
  }, [favorites]);

  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  let comp;
  if (userType === "Temp") {
    comp = <LoginModal />;
  } else {
    if (loader) {
      comp = null; //<div>Loading...</div>;
    } else {
      comp = (
        <>
          <NavBar
            notificationSVG={<NotificationSVG />}
            navHeading="Favorites"
            subHeading=""
            handleNotificationSVG={handleNotificationSVG}
            showButton={true}
          />
          <Row className="home-container gridSection g-0">
            <Col
              id="pnl-list-favourites"
              className={
                favorites.totalRecords > 0
                  ? "home-left col-6 flex-grow-1 h-100 overflow-auto scrollOverBottom"
                  : "home-left col-6 flex-grow-1 h-100 overflow-auto scrollOverBottomDone"
              }
            >
              <div className="headingToggleContainer my-4 ms-2">
                {/* <p>1 sites shown out of 1</p> */}

                <div className="toggle">
                  <BtnSwitch
                    text={"Only Show Portable Signs"}
                    action={(e) => {
                      if (e.target.checked) {
                        setFavType(3);
                      } else {
                        setFavType(0);
                      }
                    }}
                  />
                </div>
              </div>
              {favorites && favorites.records.length > 0 ? (
                <InfiniteScroll
                  dataLength={favorites.records.length - 1}
                  hasMore={true}
                  next={() => setPage(page + 1)}
                  scrollableTarget="pnl-list-favourites"
                  // loader={<h6>Loading...</h6>}
                  hasChildren={favorites.records.length > 0}
                >
                  {favorites.records.map((favorite, index) => (
                    <ListingItemCard
                      key={"fav_" + index + "_" + favorite.listingItemId}
                      id={favorite.siteId ? favorite.siteId : favorite.signId}
                      itemId={favorite.listingItemId}
                      listingId={favorite.listingId}
                      type={favorite.siteId ? "site" : "sign"}
                      title={
                        favorite.listingName
                          ? favorite.listingName
                          : favorite.name
                      }
                      subtitle={favorite.googleAddress}
                      text={favorite.description}
                      startingPrice={favorite.price}
                      rating={favorite.rating}
                      reviews={favorite.reviews}
                      isFavorite={true}
                      image={favorite.image}
                      moreBillboards={favorite.moreBillboards}
                      isFeatured={favorite.featured}
                      formats={favorite.formats}
                      isProvider={false}
                      providerName={favorite.provider}
                      handleListing={handleListing}
                      handleDetailpage={(itemId, listingId, id, type) => {
                        handleDetailpage(type, id, itemId, listingId);
                      }}
                      card={favorite}
                      cartType={"favorite"}
                      minBookingPeriod={favorite.minBookingPeriod}
                      periodTypeText={favorite.periodTypeText}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                <p>No data available</p>
              )}
            </Col>
            <Col className="home-right col-6 flex-grow-1 h-100 overflow-auto">
              <GoPinitMapsSites
                page="customerFavorites"
                markers={favorites.records}
                currentLocation={{ lat: latitude, lng: longitude }}
                setLat={(param) => dispatch(setLatitude(param))}
                setLng={(param) => dispatch(setLongitude(param))}
                setZoom={(param) => dispatch(setMapZoom(param))}
                setDistance={(param)=> dispatch(setMapDistance(param))}
                handleDetailpage={(itemId, listingId, id, type) => {
                  handleDetailpage(type, id, itemId, listingId);
                }}
                // setZoom={(param)=>{}}
              />
            </Col>
          </Row>
          <Modal
            show={navbarUpdatesModal}
            onHide={() => setNavbarUpdatesModal(false)}
            dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
          >
            <UpdatesModal />
          </Modal>
        </>
      );
    }
  }

  return <>{comp}</>;
};


import React from "react"
const BtnWhite = (props) => {
  const { whiteBtnSVG, onClick, width, lineHeight } = props;
  return (
    <>
      <button
        className={`whiteButton `}
        style={{ width, lineHeight }}
        onClick={onClick}
      >
        {/* {whiteBtnSVG && <span className="btnSvg">{whiteBtnSVG}</span>} */}
        {props.children}
      </button>
    </>
  );
};

export default BtnWhite;

import React from "react"

const BtnBlue = (props) => {
  const { blueBtnSVG, onClick, btnText } = props;
  return (
    <>
      <button className="blueButton" onClick={onClick}>
        {/* {blueBtnSVG && <span className="btnSvg">{blueBtnSVG}</span>} */}
        {props.children}
      </button>
    </>
  );
};

export default BtnBlue;

import React from "react";
import { NameFirstCharactorLogo } from "../../Provider/OrderAndTransaction/NameFirstCharactorLogo";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getOrderAsync } from "../../../../services/reducers/orders-reducers";
import { To2Decimal } from "../../../../services/utility";

export const OrderCard = ({ active, updateStatus, item, userType, setSelectedOrder }) => {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.user ?? {});

  const getOrderDetails = () => {
    dispatch(getOrderAsync(item.id));
    setSelectedOrder(item.id);
  }

  return (
    <div className={`ordercardSection ${active}`}>
      <div className="orderCardMainContaner d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <NameFirstCharactorLogo text={item.customer.firstName.slice(0, 1)} />
          <div className="orderCardSection_1" style={{ cursor: 'pointer' }} onClick={() => getOrderDetails()}>
            <p className="m-0">{item.id}</p>
            <span>
              {item.orderStatusText} on{" "}
              {moment(item.createdOn).format("DD MMMM YYYY")}
            </span>
          </div>
        </div>
        {/* {
          item.type == "Provider" ? (<button className="blueBgButton" style={{ padding: '10px' }}>
            Provider
          </button>) : (<button className="blueBgButton" style={{ padding: '10px' }}>
            Advertiser
          </button>)
        } */}
      </div>
      <div className="orderCardFooter d-flex borderTop justify-content-between">
        <div className="Section_i">
          <p className="mb-0">Ordered by</p>
          <span>
            {item.customer.firstName} {item.customer.lastName}
          </span>
        </div>
        {
          userType != "Provider" && item.provider && (
            <div className="Section_i">
              <p className="mb-0">Provided by</p>
              <span>
                {item.provider.name}
              </span>
            </div>
          )
        }

        <div className="Section_i">
          <p className="mb-0">Bill Amount </p>
          <span>$ {To2Decimal(item.totalOrderAmount)}</span>
        </div>
        <div className="Section_i">
          <p className="mb-0">Amount Paid</p>
          <span>$ {To2Decimal(item.amountPaid)}</span>
        </div>
        <div className="Section_i">
          <p className="mb-0">Payout Relesed</p>
          <span>{item.payoutReleased ? (<span>Yes</span>) : (<span>No</span>)}</span>
        </div>

      </div>
    </div>
  );
};

import React from "react";
import ConfirmModal from "../../../../../../../../components/confirmModal/ConfirmModal";
import featureImage from "../../../../../../../../assets/images/Rectangle_1443.png";
import { useHistory } from "react-router";

const FeaturedListingModal = ({
  setFeaturedListing,
  setRegisterListingModal,
}) => {
  const history = useHistory();
  const handleCancel = () => {
    history.push("/provider/configurator/listings");
    setFeaturedListing(false);
  };

  return (
    <>
      <ConfirmModal
        isModalHeader={false}
        isFooterBtns={true}
        confirmText="Proceed"
        cancelText="Cancel"
        handleClose={() => handleCancel()}
        handleConfirm={() => {
          setRegisterListingModal(true);
          setFeaturedListing(false);
        }}
      >
        <img src={featureImage} className="w-100" />
        <div className="px-3 ">
          <h4 className="text-center feature02 m-0">Featured Listing</h4>
          <p className="text-center desc02 w-75 m-auto">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
            tincidunt, purus eget blandit tempus, enim lacus placerat lectus, at
            rhoncus elit urna sit amet eros. Etiam scelerisque, neque convallis
            porta venenatis, augue urna molestie elit, nec malesuada est justo
            sit amet dui. Donec turpis nisi, cursus eget nisl quis, sollicitudin
            volutpat dui.
          </p>
          <h1 className="ps-2 m-0 text-center py-2">$ 200 / mo</h1>
          <div className="text-center pb-4 py-1 desc03">per listing</div>
        </div>
      </ConfirmModal>
    </>
  );
};

export default FeaturedListingModal;

import React, { useEffect, useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { NotificationSVG } from "../../../../assets/svgComponents/table";
import NavBar from "../../../../components/navBar";
import { SwitchOrdersPage } from "./SwitchOrdersPage";
import { LoginModal } from "../../../../components/Modals/LoginModal";
import { BtnSwitch } from "../../../../components/BtnSwitch/BtnSwitch";
import { UnionSVG } from "../../../../assets/svgComponents/table";
import { SearchBox } from "./SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionsAsync } from "../../../../services/reducers/transaction-reducers";
import {
  getFilterOptionsAsync,
  getSortingOptionsAsync,
  getSelectedOptions,
  getSelectedSortBy,
  filterCBChanged,
  clearAllCB,
} from "../../../../services/reducers/filter-reducers";
import { FilterModal } from "./FilterModal";
import { OrdersPage } from "./OrdersPage";
import { refreshOrders } from "../../../../services/reducers/orders-reducers";
import { useLocation } from "react-router";
import UpdatesModal from "../../../../components/profile/Modal/UpdatesModal";

export const OrdersIndexPage = (props) => {
  const search = useLocation().search;
  const transactionId = new URLSearchParams(search).get("transactionId");
  const dispatch = useDispatch();
  const userType = localStorage.getItem("userType");
  const tabs = ["ORDERS", "TRANSACTIONS"];
  const orderStatustabs = ["ALL", "OPEN", "FULFILLED", "CANCELED"];
  const [activeTab, setActiveTab] = useState(
    userType === "Customer" ? orderStatustabs[0] : tabs[0]
  );
  const checkBoxFilters = useSelector((state) =>
    state.filters.checkBoxFilters.filter(
      (r) => r.page.indexOf("transactions") >= 0
    )
  );
  const sortingOptions = useSelector((state) => state.filters.sortingOptions);
  const [showTranFilterModal, setShowTranFilterModal] = useState(false);
  const selectedtransactionTypes = useSelector((state) =>
    getSelectedOptions(state, "transactions", "TransactionTypes")
  );
  const selectedOrderTypes = useSelector((state) =>
    getSelectedOptions(state, "transactions", "OrderType")
  );
  const selectedSortBy = useSelector((state) =>
    getSelectedSortBy(state, "transactions")
  );
  const { isExport } = useSelector((state) => state.orders);
  const [searchString, setSearchString] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const [groupByOrderId, setGroupByOrderId] = useState(false);
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (transactionId) {
      setActiveTab(tabs[1]);
    }
  }, [transactionId])

  const ApplyBtnForTran = () => {
    setShowTranFilterModal(false);
    getTransactions();
  };

  const getTransactions = () => {
    let reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: isExport,
      searchString: searchString,
      orderId: "",
      transactionTypes: selectedtransactionTypes,
      sortingBy: selectedSortBy,
      groupByOrderId: groupByOrderId,
      orderTypes: selectedOrderTypes,
    };
    dispatch(getTransactionsAsync(reqBody));
  };

  useEffect(async () => {
    if (userType !== "Customer") {
      getTransactions();
      dispatch(getFilterOptionsAsync("TransactionTypes"));
      dispatch(getSortingOptionsAsync());
    }
  }, [dispatch, currentPage, pageSize, sortColumn, groupByOrderId]);

  const filterOrderStatus = async (selectedTab) => {
    await dispatch(clearAllCB(null));
    if (selectedTab === "ALL") {
    } else if (selectedTab === "OPEN") {
      await dispatch(filterCBChanged({ type: "Status", id: 0 }));
      await dispatch(filterCBChanged({ type: "Status", id: 2 }));
      await dispatch(filterCBChanged({ type: "Status", id: 3 }));
      await dispatch(filterCBChanged({ type: "Status", id: 4 }));
    } else if (selectedTab === "FULFILLED") {
      await dispatch(filterCBChanged({ type: "Status", id: 5 }));
    } else if (selectedTab === "CANCELED") {
      await dispatch(filterCBChanged({ type: "Status", id: 6 }));
    }
    await dispatch(refreshOrders(new Date().toString()));
  };

  const exportCsv = () => { };

  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  const checkIfEmpty = (term) => {
    if (term == "") {
      getTransactions();
    }
  };
  let comp;
  if (userType === "Temp") {
    comp = <LoginModal />;
  } else if (userType === "Customer") {
    comp = (
      <div className="gridSection p-20 bg-base-100">
        <div className="tableActions_container mb-2">
          <Row className="g-0">
            <Col className="d-flex col-md-6 align-items-center">
              <ul className="tableActions_tabs">
                {orderStatustabs.map((item) =>
                  item === activeTab ? (
                    <li
                      className="tableActions_tab active"
                      onClick={() => {
                        filterOrderStatus(item);
                        setActiveTab(orderStatustabs[item]);
                      }}
                    >
                      {item} <hr className="tableActions_tab_hr" />
                    </li>
                  ) : (
                    <li
                      className="tableActions_tab"
                      onClick={() => {
                        filterOrderStatus(item);
                        setActiveTab(item);
                      }}
                    >
                      {item}
                    </li>
                  )
                )}
              </ul>
            </Col>
          </Row>
        </div>
        <OrdersPage />
        {/* <SwitchOrdersPage activeTab={activeTab} /> */}
      </div>
    );
  } else {
    comp = (
      <div className="gridSection p-20 bg-base-100">
        <div className="card mt-4 overflow-hidden customtable">
          <div className="tableActions_container mb-4">
            <Row className="g-0">
              <Col className="d-flex col-md-4 align-items-center">
                <ul className="tableActions_tabs">
                  {tabs.map((item) =>
                    item === activeTab ? (
                      <li
                        className="tableActions_tab active"
                        onClick={() => {
                          setActiveTab(item);
                        }}
                      >
                        {item} <hr className="tableActions_tab_hr" />
                      </li>
                    ) : (
                      <li
                        className="tableActions_tab"
                        onClick={() => {
                          setActiveTab(item);
                        }}
                      >
                        {item}
                      </li>
                    )
                  )}
                </ul>
              </Col>
              {activeTab === "TRANSACTIONS" ? (
                <div className="d-flex justify-content-start p-2">
                  <div className="d-flex align-items-center">
                    {/* <div className="col-md-3 d-flex justify-content-start align-items-center p-0"> */}

                    {/* </div> */}
                    {/* <div className="col-md-6 d-flex justify-content-between"> */}
                    <div className="toggle" style={{ width: "465px" }}>
                      <BtnSwitch
                        text={"Group By Order Id"}
                        action={(e) => {
                          if (e.target.checked) {
                            setGroupByOrderId(true);
                          } else {
                            setGroupByOrderId(false);
                          }
                        }}
                      />
                    </div>

                    <SearchBox
                      handleTermChange={(term) => {
                        setSearchString(term);
                        checkIfEmpty(term);
                      }}
                      onSearch={() => dispatch(getTransactions())}
                      label="Search by customer, provider, transaction or order id"
                    ></SearchBox>
                    {/* </div>  */}
                    {/* <div className="col-md-3"> */}
                    <button
                      className=" ms-3 whiteBgButton02 d-flex justify-content-around "
                      onClick={() => setShowTranFilterModal(true)}
                    >
                      <span>Filter</span>
                      <span className="btnSvg mx-2">
                        <UnionSVG />
                      </span>
                    </button>
                    {/* </div> */}
                  </div>

                  <FilterModal
                    show={showTranFilterModal}
                    filters={checkBoxFilters}
                    sorting={sortingOptions}
                    applyFilters={ApplyBtnForTran}
                    closeIt={() => setShowTranFilterModal(false)}
                    key="TransactionsFilterModal"
                  />
                </div>
              ) : null}
            </Row>
          </div>
          <SwitchOrdersPage
            activeTab={activeTab}
            onTabChange={(tab) => {
              setActiveTab(tab);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <NavBar
        notificationSVG={<NotificationSVG />}
        navHeading={
          userType && userType !== "Customer"
            ? "Orders & Transactions"
            : "Orders"
        }
        subHeading=""
        /*    blueBtnText={activeTab === "TRANSACTIONS" ? `Export CSV` : null}
        blueBtnSVG={<ExportCSVSVG fill="#FFFFFF" />}
        handleBlueBtn={exportCsv} */
        handleNotificationSVG={handleNotificationSVG}
        showButton={false}
      />
      {comp}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

import React from "react";

import { CaretDownSVG, CaretUpSVG } from "../../../assets/svgComponents/table";

const TableSortIcon = () => {
  return (
    <>
      <div className="d-flex flex-column ta-sort-icon">
        <span>
          <CaretUpSVG />
        </span>
        <span>
          <CaretDownSVG />
        </span>
      </div>
    </>
  );
};

export default TableSortIcon;

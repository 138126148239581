import React, {useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { SelectionComponent } from "../../../../components/SelectionComponent/SelectionComponent";
import {useDispatch, useSelector} from "react-redux";
import { getRefundTypeOptionsAsync,setRefundAmount,setRefundType, updateOrderStatusAsync,getOrderAsync,clearOrderStatusUpdateResult } from "../../../../services/reducers/orders-reducers";
import RangePicker from "react-range-picker";
import { DatePickerWithFloatingLable } from "../../../../components/InputDatePicker/DatePickerWithFloatingLable";
import { getOrderStatusTypesAsync } from "../../../../services/reducers/orders-reducers";
import { useToasts } from "react-toast-notifications";

export const UpdateOrderStatusModal = ({
  show,
  setShow,
  order, 
}) => { 

const dispatch = useDispatch();
const [status, setStatus] = useState(order.status);
const [updateDate,setUpdateDate] = useState(new Date());
const { statusTypes,updateOrderStatusResponse } = useSelector((state)=> state.orders);
const { addToast } = useToasts();

useEffect(()=>{
    dispatch(getOrderStatusTypesAsync());
},[dispatch]);

useEffect( async()=>{
  if(updateOrderStatusResponse){
    if(updateOrderStatusResponse.status===1){
      //await dispatch(getOrderAsync(order.id));
      setShow(false);
      addToast(updateOrderStatusResponse.message,{ appearance: "success"});  
       dispatch(clearOrderStatusUpdateResult());
    }else{
      addToast(updateOrderStatusResponse.message,{ appearance: "error"});  
    }
  }
},[updateOrderStatusResponse]);

const handleOk = (e)=>{
  dispatch(updateOrderStatusAsync({
    orderId: order.id,
    providerOrderStatus: status,
    updatedOn: updateDate
  }));
};

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen="sm-down"
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"> 
            <h5 style={{ fontSize: "18px", fontWeight: "300" }}>Update Order Status</h5> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
        <div className="d-flex justify-content-between">   
            <SelectionComponent label={"Status"} option={statusTypes} name={"orderStatus"} 
            defaultValue={order.status}            
            inputsize={"inputsize col-md-12"} value={status} handleOnchange={(e)=> { setStatus(e.target.value);}}/>                 
        </div>
        <div className="d-flex justify-content-between">                   
            <DatePickerWithFloatingLable onChange={(dt)=>setUpdateDate(dt)} label="Date" 
            value={updateDate} inputsize={"inputsize col-md-12"}/>
        </div> 
      </Modal.Body>
      <Modal.Footer
        className="popModalfooter justify-content-center"
        onClick={handleOk}
      >
           <span style={{ color: "#ffffff" }}>Update</span>
      </Modal.Footer>
    </Modal>
  );
};

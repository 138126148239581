import React, { useState, useEffect, useRef } from 'react';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import ChatInput from './ChatInput';
import ChatWindow from './ChatWindow';
import { ChatPopHead } from '../../../../assets/svgComponents/chatPopHead';
import { messageList } from '../../../../services/Shared/MessageService';
//import { UploadLogo } from "../../../../assets/svgComponents/svg";
//import { UploadPlus } from "../../../../assets/svgComponents/inventorySVG";
import { RiAttachmentLine } from 'react-icons/ri';
import { AiOutlineDownload } from 'react-icons/ai';
import { BsCamera } from 'react-icons/bs';
import { uploadFileMethod } from "../../../../services/Provider";
import { MaximizeSVG, MinimizeSVG, CloseSVG } from '../../../../assets/svgComponents/inventorySVG';



const Chat = ({targetId, orderId}) => {
    const [ connection, setConnection ] = useState(null);
    const [ chat, setChat ] = useState([]);
    const [ isView, setIsView ] = useState(false);
    const [largeView, setLargeView] = useState(false);
    const latestChat = useRef(null);
    const messageRef = useRef();
    const inputFile = useRef(null) 
    useEffect(()=>{
      if (messageRef.current) {
          messageRef.current.scrollTo(0, messageRef.current.scrollHeight);
      }
    })
    useEffect(() => {
      const req = {
        "page": 0,
        "pageSize": 1000,
        "orderBy": "CreatedOn"
      }
    messageList(req, targetId, orderId).then((res)=>{
        setChat(res.data.records.reverse())
      })
    }, [targetId, orderId])
    
    useEffect(()=>{
      latestChat.current = chat;
    }, [chat])
    useEffect(() => {
        let accessToken = localStorage.getItem("accessToken")
        const newConnection = new HubConnectionBuilder()
        .withUrl(`https://chat.gopinitpartner.com/chatHub?access_token=${accessToken}&order_id=${orderId}`,{withCredentials: false, transport: HttpTransportType.WebSockets})
            .configureLogging(LogLevel.Trace)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    connection.on('newMessage', message => {
                        const updatedChat = [...latestChat.current];
                        updatedChat.push(message);
                        setChat(updatedChat);
                    });
                    connection.on('onError', message => {
                        console.log(message);
                    });
                }).then(()=>{
                    // await connection.invoke({"protocol":"json","version":1});
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    const sendMessage = async (message) => {
            try {
                await connection.send('SendPrivate', targetId, orderId, message);
                // loadChats();
            }
            catch(e) {
                console.log(e);
            }
    }

    const onAttachmentClick = () => {
      // `current` points to the mounted file input element
      inputFile.current.click();     
     
    };

    const handelAttachmentFile = (e) => {
      let data = e.target.files[0];
      let image = new FormData();
      image.append("file", data);
      uploadFileMethod(image)
        .then((res) => {
          if (res.status == 200) {
            //setUploadImage(res.data);
            const element = `&&&${res.data.fileKey}&&&`;
            sendMessage(element);
            console.log(element, 'chat file upload');
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    };

    return (
        <div>
        <button className="chatbox-open" onClick={()=>setIsView(isView===true?false:true)}>
          <ChatPopHead />
        </button>
        {isView==true?
        <section className={largeView ? "chatbox-popup chatbox-largepopup" : "chatbox-popup"}>
          <header className="chatbox-popup__header">
            <aside style={{flex: 3}}>
              Message
            </aside>
            <aside style={{flex: 1,textAlign: "right"}}>
              {/* <FontAwesomeIcon icon="coffee" /> */}
            </aside>
            {
              largeView ? 
              (<button type="button" className="close" onClick={()=>setLargeView(false)}><MinimizeSVG/></button>) 
              : (<button type="button" className="close" onClick={()=>setLargeView(true)}><MaximizeSVG/></button>)
            }
            &nbsp;<button type="button" className="close" onClick={()=>setIsView(false)}><CloseSVG/></button>
          </header>
          <main className="chatbox-popup__main" ref={messageRef}>
            <ChatWindow chat={chat} targetId={targetId}/>
          </main>
          <footer className="chatbox-popup__footer">
            {/* <aside style={{flex: 1, color: '#888', textAlign: 'center'}}>
              <BsCamera/>
            </aside> */}
            <aside style={{flex: 10}}>
                <ChatInput sendMessage={sendMessage} />
            </aside>
            <aside style={{flex: 1, color: '#888', textAlign: 'center'}}>
              {/* <i className="fa fa-paper-plane" aria-hidden="true" /> */}
              <input type='file' id='file' ref={inputFile} onChange={handelAttachmentFile} style={{display: 'none'}}/> 
              <b><RiAttachmentLine onClick={onAttachmentClick}  /></b>
            </aside>
          </footer>
        </section>
        : <></>
        }
      </div>
    );
};

export default Chat;
// import React from "react";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";

// export const MultiSelection = ({options,values,onChange}) => {
//     const animatedComponents = makeAnimated();
//     let selectOption=options.map(({ id, name }) => ({ value: id, label: name }))
//   return (
//     <>
//       <Select options={selectOption} onChange={onChange} components={animatedComponents} isMulti={true} placeholder="Select Media" value={values}/>
//     </>
//   );
// };
// let selectOption=options.map(({ id, name }) => ({ value: id, label: name }))

import Multiselect from "multiselect-react-dropdown";
import React from "react";

export const MultiSelection = ({
  options,
  selValue,
  onRemove,
  onSelect,
}) => {
console.log('selValue', selValue)
// let data=[{name: 'Option 1️⃣', id: 1},{name: 'Option 2️⃣', id: 2}]
  return (
    <>
      <Multiselect
        options={options}
        selectedValues={selValue}
        onSelect={onSelect}
        onRemove={onRemove}
        displayValue="name"
      />
    </>
  );
};

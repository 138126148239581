import React, { useEffect, useState } from "react";
import { GotoSVG } from "../../../../../../../assets/svgComponents/inventorySVG";
import { ExportCSVSVG } from "../../../../../../../assets/svgComponents/table";
import { getSiteUsage } from "../../../../../../../services/Provider/Configurator/Inventory";
import { Table, Button } from "react-bootstrap";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import { getOrderDetails } from "../../../../../../../services/api/orders-store-api";
import { OrderDetailPage } from "./OrderDetailPage";
import moment from "moment";
import Moment from "react-moment";

export const UsagePage = ({ siteDetails }) => {
  const [orderId, setOrderId] = useState("");
  const [usageData, setusageData] = useState();
  const [order, setOrder] = useState();

  useEffect(async () => {
    await getSiteUsage(siteDetails?.id)
      .then((res) => {
        if (res.status === 200) {
          console.log("site Usage Data", res.data.data);
          setusageData(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(async () => {
    if (orderId != "") {
      await getOrderDetails(orderId)
        .then((res) => {
          if (res.status === 200) {
            console.log("getOrderDetails", res);
            setOrder(res.data);
            setOrderModel(true);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [orderId]);

  const getOderDetail = (orderId) => {
    setOrderId(orderId);
  };

  const [orderModel, setOrderModel] = useState(false);

  const handleOrderModel = (state) => {
    setOrderModel(state);
  };
  return (
    <div className="informationMainContainer flex-grow-1 overflow-auto">
      <div className="informationContainer bg-base-100 w-100">
        {/* <div className=" d-flex justify-content-between informationRow">
        {usageData &&
            usageData.map((item) => {
              return (
                <div>
                  <p className="mb-0">{item}</p>
                  <span>#DT89D</span>
                </div>
              );
            })}

          <div>
          <span className="goToOrderDetails" onClick={goToOrderDetails}>
            <GotoSVG/> Order Details
          </span>
          </div> 

         </div> */}

        <Table responsive>
          <tbody>
            {usageData && usageData.length > 0 ? (
              usageData.map((item) => {
                return (
                  <tr>
                    <td>
                      <p>DURATION</p>
                      <label>
                        {new moment(item.fromDate).format("DD MMM yyyy")} -{" "}
                        {new moment(item.toDate).format("DD MMM yyyy")}
                      </label>
                    </td>
                    <td>
                      <p>BILL AMOUNT</p>
                      <label>{item.billAmount}</label>
                    </td>
                    <td>
                      <p>PAYMENT TERM</p>
                      <label>{item.paymentTerm}</label>
                    </td>
                    <td>
                      <p>ORDERED BY</p>
                      <label>{item.userName}</label>
                    </td>
                    <td>
                      <p>EMAIL</p>
                      <label>{item.orderByEmail}</label>
                    </td>
                    <td>
                      <a
                        className="order-details"
                        onClick={() => getOderDetail(item.orderId)}
                      >
                        <BsBoxArrowInUpRight /> Order Details
                      </a>
                    </td>
                  </tr>
                );
              })
            ) : (
              <h5>No Record Found!</h5>
            )}
          </tbody>
        </Table>

        {orderModel && (
          <ConfirmModal
            title="Order Detail"
            isModalHeader={true}
            isFooterBtns={false}
            confirmText="Ok"
            handleConfirm={() => handleOrderModel(false)}
            handleClose={() => handleOrderModel(false)}
          >
            <div style={{ height: "500px" }}>
              {/* siteDetails.location &&
              <AddSitePageMap center={{lat: siteDetails.location.latitude, lng:siteDetails.location.longitude}}  lat={siteDetails.location.latitude} lng={siteDetails.location.longitude} getMyLocation={() => {}} handleChange={()=>{}} onPlacesChanged={() => {}}  onMarkerDragEnd={()=> {}} />
             */}
              {order && <OrderDetailPage order={order} />}
            </div>
          </ConfirmModal>
        )}
      </div>
    </div>
  );
};

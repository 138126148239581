import React from "react";

function RadioButton({
  value,
  onBlur,
  onChange,
  disabled,
  isDefault,
  index,
  onClick,
}) {
  return (
    <>
      <div className="radio-before">
        <label className="radio radio-gradient d-flex align-items-center mr-3">
          <span className="radio__input">
            <input
              type="radio"
              id="radio1"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              onClick={onClick}
              disabled={disabled}
              checked={isDefault}
            />
            <span className="radio__control"></span>
          </span>
          <span className="radio__label" for="radio1">
            {isDefault ? "Default Address" : `Address ${index + 1}`}
          </span>
        </label>
      </div>
    </>
  );
}

export default RadioButton;

import React from "react";
import { ListGroup, Badge } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useHistory, useLocation } from "react-router";

export const CustomListGroup = ({
  id,
  heading,
  subheading,
  image,
  link,
  handleViewBTN,
}) => {
  const history = useHistory();

  const handleViewSite = (link) => {
    console.log(link);
    history.push(link);
  };

  return (
    <>
      {link == true ? (
        <>
          <div className="me-auto d-flex justify-content-between" onClick={() => handleViewBTN(id, link)}>
            {image && (
              <img
                style={{ height: 50, width: 50 }}
                src={`https://gopinit.blob.core.windows.net/image-container/${image}`}
                alt="img"
              />
            )}
            <div className="fw-bold heading">
              {heading}
              <span>{subheading}</span>
            </div>
          </div>
          <Badge className="mt-1 float-right" bg="tranparent" onClick={() => handleViewBTN(id, link)}>
            <Icon.ChevronCompactRight color="black" size={16} />
          </Badge>
          </>
      ) : (
        <div className="me-auto d-flex justify-content-between">
          {image && (
            <img
              style={{ height: 50, width: 50 }}
              src={`https://gopinit.blob.core.windows.net/image-container/${image}`}
              alt="img"
            />
          )}
          <div className="fw-bold heading">
            {heading}
            <span>{subheading}</span>
          </div>
        </div>
      )}
    </>
  );
};

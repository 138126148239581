import React, { useEffect, useState } from "react";
import Grid from "../../../../../components/grid/Grid";
import { siteListings } from "../../../../../services/Provider/Configurator/Listings";
import { getSiteList } from "../../../../../services/Provider";
import SitesPage from "./pages/SitesPage";
import SignsPage from "./pages/SignsPage";
import AllListings from "./pages/AllListings";
import BundlePage from "./pages/BundlePage";

const SwitchListingTab = (props) => {
  switch (props.activeTab) {
    case "ALL":
      return (
        <AllListings
          setNewListingModal={props.setNewListingModal}
          setListingName={props.setListingName}
          setIsUpdateData={props.setIsUpdateData}
          searchTerm={props.searchTerm}
          searchToggle={props.searchToggle}
        />
      );
    case "LOCATIONS":
      return (
        <SitesPage
          isSiteDetails={props.isSiteDetails}
          setIsSiteDetails={props.setIsSiteDetails}
          setNewListingModal={props.setNewListingModal}
          setListingName={props.setListingName}
          setIsUpdateData={props.setIsUpdateData}
          searchTerm={props.searchTerm}
          searchToggle={props.searchToggle}
          
        />
      );
    case "SIGNS":
      return (
        <SignsPage
          setNewListingModal={props.setNewListingModal}
          setListingName={props.setListingName}
          setIsUpdateData={props.setIsUpdateData}
          searchTerm={props.searchTerm}
          searchToggle={props.searchToggle}
        />
      );
    case "BUNDLE":
      return (
        <BundlePage
          setNewListingModal={props.setNewListingModal}
          setListingName={props.setListingName}
          setIsUpdateData={props.setIsUpdateData}
          searchTerm={props.searchTerm}
          searchToggle={props.searchToggle}
        />
      );
    default:
      return <></>;
  }
};

export default SwitchListingTab;

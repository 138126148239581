import React from "react";
import * as Icon from 'react-bootstrap-icons';
import StarRatings from 'react-star-ratings';

export const Review = ({userImg, userName, reviewDate, reviewStar, reviewDesc}) => {
    return (
        <>
            <div style={{padding:"10px 0"}}>
                <div className=" review-user-image float-start">   
                    <img src={userImg}  />                                
                </div>
                <div className="review-user-desc" style={{paddingLeft:"70px"}}>   
                    <p>{userName}</p>
                    <div>
                        <p className="float-start" style={{paddingRight:"20px"}}>{reviewDate}</p>
                        <p>                             
                            <StarRatings
                                rating={reviewStar}
                                starRatedColor="#FFB141"
                                numberOfStars={5}
                                name='rating'
                                starDimension="16px"
                                starSpacing="2px"
                                starHoverColor="#FFB141"
                            />
                        </p>
                    </div>
                    <p>{reviewDesc}</p>                                
                </div>  
            </div>      
        </>
    );
}
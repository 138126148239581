import React, { useContext, useEffect, useState } from "react";
import { Container, Col, Row, Card, ListGroup, Modal } from "react-bootstrap";
import SideNav from "../../../../components/sideNav/SideNav";
import { Route, Routes } from "react-router-dom";
import NavBar from "../../../../components/navBar";
import { Link } from "react-router-dom";
import {
  NotificationSVG,
  PlusSVG,
} from "../../../../assets/svgComponents/table";
import {
  getProviderDashBoard,
  getOrderList,
  getLeastBookedSite,
  getLatestOrders,
  getLatestPayments,
} from "../../../../services/Provider";
import { LineChart } from "../../../../components/Chart/LineChart";
import { BarChart } from "../../../../components/Chart/BarChart";
import { PieChart } from "../../../../components/Chart/PieChart";
import { DoughnutChart } from "../../../../components/Chart/DoughnutChart";
import { CustomListGroup } from "../../../../components/ListGroup/index";
import { CustomTile } from "../../../../components/Tile/CustomTile";
import { SelectComponent } from "../../../../components/SelectionComponent/SelectComponent";
import UpdatesModal from "../../../../components/profile/Modal/UpdatesModal";
import allSiteImg from "../../../../assets/images/allSiteImg.png";
import bookedImg from "../../../../assets/images/bookedImg.png";
import idleImg from "../../../../assets/images/idleImg.png";

import { SitesMap } from "../../../../components/Maps/SitesMap";
import moment from "moment";
import {
  DashboardIcon,
  HelpIcon,
  LogoutIcon,
  OrderIcon,
  ProfileIcon,
  SettingsIcon,
  UserMgmtIcon,
} from "../../../../assets/svgComponents/inventorySVG";
import { getOrderDetails } from "../../../../services/api/orders-store-api";
import ConfirmModal from "../../../../components/confirmModal/ConfirmModal";
import { OrderDetailPage } from "../ConfigratorPage/Inventory/components/ViewSiteInfo/OrderDetailPage";
import { useHistory } from "react-router";
import { ProjectContext } from "../../../../context/ProjectContext";
import { getSiteDetails } from "../../../../services/Provider/Configurator/Inventory";
import { useLocation } from "react-router";
import { lastCurrentPerCalulate } from "../../../../utils";

export const Dashboard = () => {
  const history = useHistory();
  const location = useLocation();
  const [providerDashBoardData, setProviderDashBoardData] = useState({});
  const [orderListData, setOrderListData] = useState({});
  const [leastBookedSiteData, setLeastBookedSiteData] = useState({});
  const [salesGraphData, setSalesGraphData] = useState([]);
  const [salesGraphDataTitle, setSalesGraphDataTitle] = useState([]);
  const [customersGraphData, setCustomersGraphData] = useState([]);
  const [customersGraphDataTitle, setCustomersGraphDataTitle] = useState([]);
  const customerGraphColor = ["#FFE080", "#3734A9"];
  const [vouchersGraphData, setVouchersGraphData] = useState([]);
  const [vouchersGraphDataTitle, setVouchersGraphDataTitle] = useState([]);
  const vouchersGraphColor = [
    "#FFE080",
    "#3734A9",
    "#65D1D3",
    "#FFB141",
    "#FFF5E5",
  ];
  const [latestPayments, setLatestPayments] = useState([]);
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);

  const GraphByItem = [
    { id: "Year", name: "Year" },
    { id: "Month", name: "Month" },
    { id: "Day", name: "Day" },
  ];

  const leastBookSitesArr = [
    { name: "This Year", id: 1 },
    { name: "This Month", id: 5 },
    { name: "Today", id: 7 },
  ];

  const [selectedLeastBookSite, setSelectedLeastBookSite] = useState({
    id: 5,
    name: "This Month",
  });

  const today = new Date();
  const curYear = today.getFullYear();
  const curMonth = today.getMonth() + 1;
  const curDate = today.getDay();
  const lastDate = daysInMonth(curMonth, curYear);
  const [salesGraphBy, setSalesGraphBy] = useState({
    id: "Year",
    name: "Year",
  });
  const [salesYear, setSalesYear] = useState({ id: curYear, name: curYear });
  const [salesMonth, setSalesMonth] = useState({ id: "01", name: "01" });
  const [salesDay, setSalesDay] = useState({ id: "01", name: "01" });
  const [customerGraphBy, setCustomerGraphBy] = useState({
    id: "Year",
    name: "Year",
  });
  const [customerYear, setCustomerYear] = useState({
    id: curYear,
    name: curYear,
  });
  const [customerMonth, setCustomerMonth] = useState({ id: "01", name: "01" });
  const [customerDay, setCustomerDay] = useState({ id: "01", name: "01" });
  const [voucherGraphBy, setVoucherGraphBy] = useState({
    id: "Year",
    name: "Year",
  });
  const [voucherYear, setVoucherYear] = useState({
    id: curYear,
    name: curYear,
  });
  const [voucherMonth, setVoucherMonth] = useState({ id: "01", name: "01" });
  const [voucherDay, setVoucherDay] = useState({ id: "01", name: "01" });

  let FromDate = curYear + "-01-01 00:00:00";
  let ToDate = curYear + "-12-31 23:59:59";
  let reqBody = "?Type=Year&FromDate=" + FromDate + "&ToDate=" + ToDate;
  const [reqQuery, setReqQuery] = useState(reqBody);
  const [salesReqQuery, setSalesReqQuery] = useState("");
  const [customerReqQuery, setCustomerReqQuery] = useState("");
  const [voucherReqQuery, setVoucherReqQuery] = useState("");

  useEffect(() => {
    getProviderDashBoard(reqQuery)
      .then((res) => {
        if (res.status === 200) {
          //setLoader(false);
          setProviderDashBoardData(res.data);
          let temp1 = [];
          let temp2 = [];
          let xvalueStart = 0;
          let xvalueEnd = 0;
          if (salesGraphBy.id === "Year") {
            xvalueEnd = 12;
          } else if (salesGraphBy.id === "Month") {
            xvalueEnd = salesDayList.length;
          } else {
            xvalueStart = 0;
            xvalueEnd = salesDayList.length;
          }

          for (let i = xvalueStart; i <= xvalueEnd; i++) {
            temp2.push(i);
            temp1.push("0");
          }
          res.data.salesGraphData.forEach((salesData) => {
            temp1[salesData.xValue] = salesData.yValue;
            //temp1.push(salesData.yValue)
            //temp2.push(salesData.xValue)
          });
          setSalesGraphData(temp1);
          setSalesGraphDataTitle(temp2);

          temp1 = [];
          temp2 = [];
          res.data.customersRatioGraphData.forEach((customerData) => {
            temp1.push(customerData.yValue);
            temp2.push(customerData.xValue);
          });
          setCustomersGraphData(temp1);
          setCustomersGraphDataTitle(temp2);

          temp1 = [];
          temp2 = [];
          res.data.voucherReedemedGraphData.forEach((voucherData) => {
            temp1.push(voucherData.yValue);
            temp2.push(voucherData.xValue);
          });
          setVouchersGraphData(temp1);
          setVouchersGraphDataTitle(temp2);
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }, [reqQuery]);

  useEffect(() => {
    getLatestOrders()
      .then((res) => {
        if (res.status === 200) {
          //setLoader(false);
          setOrderListData(res.data.data);

          //console.log(orderListData,'order list');
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
    getLatestPayments()
      .then((res) => {
        if (res.status === 200) {
          //setLoader(false);
          setLatestPayments(res.data);

          //console.log(orderListData,'order list');
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getLeastBookedSite(selectedLeastBookSite.id)
      .then((res) => {
        if (res.status === 200) {
          //setLoader(false);
          setLeastBookedSiteData(res);

          //console.log(res,'least site list');
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });
  }, [selectedLeastBookSite]);

  const handleLeastBookSiteList = (e) => {
    setSelectedLeastBookSite(e);
  };

  const customerGraphFooter = customersGraphDataTitle.map((title, index) => (
    <span className="pr-1 d-flex align-items-center">
      <span
        className="border d-inline-block"
        style={{
          backgroundColor: customerGraphColor[index],
          width: "16px",
          height: "16px",
          borderRadius: "32px",
        }}
      ></span>{" "}
      <span>{title}</span>
    </span>
  ));

  const voucherGraphFooter = vouchersGraphDataTitle.map((title, index) => (
    <span className="pr-1 flex align-items-center">
      <span
        className="border d-inline-block"
        style={{
          backgroundColor: customerGraphColor[index],
          width: "16px",
          height: "16px",
          borderRadius: "32px",
        }}
      ></span>{" "}
      <span>{title}</span>
    </span>
  ));

  function daysInMonth(iMonth, iYear) {
    return new Date(iYear, iMonth, 0).getDate();
  }

  const yearList = [];
  for (var y = curYear; y >= 2021; y--) {
    let obj = { id: y, name: y };
    yearList.push(obj);
  }

  const monthList = [];
  for (var m = 1; m <= 12; m++) {
    let mon = ("0" + m).slice(-2);
    let obj = { id: mon, name: mon };
    monthList.push(obj);
  }

  let temp = [];
  for (var d = 1; d <= 31; d++) {
    let day = ("0" + d).slice(-2);
    let obj = { id: day, name: day };
    temp.push(obj);
  }

  const [salesDayList, setSalesDayList] = useState(temp);
  const [customerDayList, setCustomerDayList] = useState(temp);
  const [voucherDayList, setVoucherDayList] = useState(temp);
  const [orderView, setOrderView] = useState(false);
  const [order, setOrder] = useState();
  const [site, setSite] = useState();
  //const { handleSelectedSelSite } = useContext(ProjectContext);

  // sales graph start
  const SalesGraphSelectChange = (e) => {
    //e.preventDefault();
    //alert(e.target.value);
    //setSalesGraphBy(e.target.value);
    //e.preventDefault();
    setSalesGraphBy(e);
    let salesReqBody = "";
    if (e.id === "Year") {
      FromDate = salesYear.id + "-01-01 00:00:00";
      ToDate = salesYear.id + "-12-31 23:59:59";
      salesReqBody = "?Type=Year&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else if (e.id === "Month") {
      let lastDate = daysInMonth(salesMonth.id, salesYear.id);
      FromDate = salesYear.id + "-" + salesMonth.id + "-01 00:00:00";
      ToDate =
        salesYear.id + "-" + salesMonth.id + "-" + lastDate + " 23:59:59";
      salesReqBody = "?Type=Month&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else {
      FromDate =
        salesYear.id + "-" + salesMonth.id + "-" + salesDay.id + " 00:00:00";
      ToDate =
        salesYear.id + "-" + salesMonth.id + "-" + salesDay.id + " 23:59:59";
      salesReqBody = "?Type=Day&FromDate=" + FromDate + "&ToDate=" + ToDate;
    }
    //setSalesReqQuery(reqBody);
    setSalesReqQuery(salesReqBody);
  };

  function SalesYearChange(e) {
    //alert(e.target.value);
    setSalesYear(e);
    let year = e.id;
    FromDate = year + "-01-01 00:00:00";
    ToDate = year + "-12-31 23:59:59";
    let salesReqBody =
      "?Type=" +
      salesGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setSalesReqQuery(salesReqBody);
  }
  function SalesMonthChange(e) {
    //alert(e.target.value);
    setSalesMonth(e);
    let month = e.id;
    let lastDate = daysInMonth(e.id, salesYear.id);
    //setCustomerDayList([]);
    let temp = [];
    for (var d = 1; d <= lastDate; d++) {
      let day = ("0" + d).slice(-2);
      let obj = { id: day, name: day };
      temp.push(obj);
    }
    setSalesDayList(temp);
    setSalesDay("01");

    FromDate = salesYear.id + "-" + month + "-01 00:00:00";
    ToDate = salesYear.id + "-" + month + "-" + lastDate + " 23:59:59";
    let salesReqBody =
      "?Type=" +
      salesGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setSalesReqQuery(salesReqBody);
  }

  function SalesDayChange(e) {
    //alert(e.target.value);
    setSalesDay(e);
    let day = e.id;
    FromDate = salesYear.id + "-" + salesMonth.id + "-" + day + " 00:00:00";
    ToDate = salesYear.id + "-" + salesMonth.id + "-" + day + " 23:59:59";
    let salesReqBody =
      "?Type=" +
      salesGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setSalesReqQuery(salesReqBody);
  }

  useEffect(() => {
    if (salesReqQuery !== "") {
      getProviderDashBoard(salesReqQuery)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data, "customer fav");
            //setLoader(false);
            setProviderDashBoardData(res.data);
            //console.log(providerDashBoardData,"provider Dashboard Data");

            let temp1 = [];
            let temp2 = [];
            let xvalueStart = 0;
            let xvalueEnd = 0;
            if (salesGraphBy.id === "Year") {
              xvalueEnd = 12;
            } else if (salesGraphBy.id === "Month") {
              xvalueEnd = salesDayList.length;
            } else {
              xvalueStart = 0;
              xvalueEnd = salesDayList.length;
            }
            for (let i = xvalueStart; i <= xvalueEnd; i++) {
              temp2.push(i);
              temp1.push("0");
            }

            res.data.salesGraphData.forEach((salesData) => {
              temp1[salesData.xValue] = salesData.yValue;
              //temp1.push(salesData.yValue)
              //temp2.push(salesData.xValue)
            });
            setSalesGraphData(temp1);
            setSalesGraphDataTitle(temp2);
          }
        })
        .catch((err) => {
          // setLoader(false);
          console.log(err);
        });
    }
  }, [salesReqQuery]);

  const handleOrderView = (orderId) => {
    setOrderView(true);
    getOrderDetails(orderId)
      .then((res) => {
        if (res.status === 200) {
          console.log("getOrderDetails", res);
          setOrder(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleLeastBookSiteView = (siteId) => {
    history.push({
      pathname: `/provider/configurator/inventory/view-site`,
      state: { id: siteId },
    });
  };

  function handleOrderModel(state) {
    setOrderView(state);
    setOrder("");
  }
  // sales graph end

  // customer graph start

  function CustomerGraphSelectChange(e) {
    //e.preventDefault();
    //alert(e.target.value);
    setCustomerGraphBy(e);
    if (e.id === "Year") {
      FromDate = customerYear.id + "-01-01 00:00:00";
      ToDate = customerYear.id + "-12-31 23:59:59";
      reqBody = "?Type=Year&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else if (e.id === "Month") {
      let lastDate = daysInMonth(customerMonth.id, customerYear.id);
      FromDate = customerYear.id + "-" + customerMonth.id + "-01 00:00:00";
      ToDate =
        customerYear.id + "-" + customerMonth.id + "-" + lastDate + " 23:59:59";
      reqBody = "?Type=Month&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else {
      FromDate =
        customerYear.id +
        "-" +
        customerMonth.id +
        "-" +
        customerDay.id +
        " 00:00:00";
      ToDate =
        customerYear.id +
        "-" +
        customerMonth.id +
        "-" +
        customerDay.id +
        " 23:59:59";
      reqBody = "?Type=Day&FromDate=" + FromDate + "&ToDate=" + ToDate;
    }
    setCustomerReqQuery(reqBody);
  }

  function CustomerYearChange(e) {
    //alert(e.target.value);
    setCustomerYear(e);
    let year = e.id;
    FromDate = year + "-01-01 00:00:00";
    ToDate = year + "-12-31 23:59:59";
    reqBody =
      "?Type=" +
      customerGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setCustomerReqQuery(reqBody);
  }
  function CustomerMonthChange(e) {
    //alert(e.target.value);
    setCustomerMonth(e);
    let month = e.id;
    let lastDate = daysInMonth(e.id, customerYear.id);
    //setCustomerDayList([]);
    let temp = [];
    for (var d = 1; d <= lastDate; d++) {
      let day = ("0" + d).slice(-2);
      let obj = { id: day, name: day };
      temp.push(obj);
    }
    setCustomerDayList(temp);
    setCustomerDay("01");

    FromDate = customerYear.id + "-" + month + "-01 00:00:00";
    ToDate = customerYear.id + "-" + month + "-" + lastDate + " 23:59:59";
    reqBody =
      "?Type=" +
      customerGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setCustomerReqQuery(reqBody);
  }

  function CustomerDayChange(e) {
    //alert(e.target.value);
    setCustomerDay(e);
    let day = e.id;
    FromDate =
      customerYear.id + "-" + customerMonth.id + "-" + day + " 00:00:00";
    ToDate = customerYear.id + "-" + customerMonth.id + "-" + day + " 23:59:59";
    reqBody =
      "?Type=" +
      customerGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setCustomerReqQuery(reqBody);
  }

  useEffect(() => {
    if (customerReqQuery !== "") {
      getProviderDashBoard(customerReqQuery)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data, "customer fav");
            //setLoader(false);
            setProviderDashBoardData(res.data);
            //console.log(providerDashBoardData,"provider Dashboard Data");

            const temp1 = [];
            const temp2 = [];
            res.data.customersRatioGraphData.map((customerData) => {
              temp1.push(customerData.yValue);
              temp2.push(customerData.xValue);
            });
            setCustomersGraphData(temp1);
            setCustomersGraphDataTitle(temp2);
            //console.log(customersGraphData,"Customer Graph Data");
          }
        })
        .catch((err) => {
          // setLoader(false);
          console.log(err);
        });
    }
  }, [customerReqQuery]);

  // customer graph end

  // voucher graph start

  function VoucherRedemptionGraphSelectChange(e) {
    // e.preventDefault();
    setVoucherGraphBy(e);

    if (e.id === "Year") {
      FromDate = customerYear + "-01-01 00:00:00";
      ToDate = customerYear + "-12-31 23:59:59";
      reqBody = "?Type=Year&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else if (e.id === "Month") {
      let lastDate = daysInMonth(customerMonth, customerYear);
      FromDate = customerYear + "-" + customerMonth + "-01 00:00:00";
      ToDate =
        customerYear + "-" + customerMonth + "-" + lastDate + " 23:59:59";
      reqBody = "?Type=Month&FromDate=" + FromDate + "&ToDate=" + ToDate;
    } else {
      FromDate =
        customerYear + "-" + customerMonth + "-" + customerDay + " 00:00:00";
      ToDate =
        customerYear + "-" + customerMonth + "-" + customerDay + " 23:59:59";
      reqBody = "?Type=Day&FromDate=" + FromDate + "&ToDate=" + ToDate;
    }
    setVoucherReqQuery(reqBody);
  }

  function VoucherYearChange(e) {
    //alert(e.target.value);
    setVoucherYear(e);
    let year = e.id;
    FromDate = year + "-01-01 00:00:00";
    ToDate = year + "-12-31 23:59:59";
    reqBody =
      "?Type=" +
      customerGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setVoucherReqQuery(reqBody);
  }
  function VoucherMonthChange(e) {
    //alert(e.target.value);
    setVoucherMonth(e);
    let month = e.id;
    let lastDate = daysInMonth(voucherMonth.id, voucherYear.id);
    let temp = [];
    for (var d = 1; d <= lastDate; d++) {
      let day = ("0" + d).slice(-2);
      let obj = { id: day, name: day };
      temp.push(obj);
    }
    setVoucherDayList(temp);
    setVoucherDay("01");

    FromDate = voucherYear.id + "-" + month + "-01 00:00:00";
    ToDate = voucherYear.id + "-" + month + "-" + lastDate + " 23:59:59";
    reqBody =
      "?Type=" +
      voucherGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setVoucherReqQuery(reqBody);
  }

  function VoucherDayChange(e) {
    //alert(e.target.value);
    setVoucherDay(e);
    let day = e.id;
    FromDate = voucherYear.id + "-" + voucherMonth.id + "-" + day + " 00:00:00";
    ToDate = voucherYear.id + "-" + voucherMonth.id + "-" + day + " 23:59:59";
    reqBody =
      "?Type=" +
      customerGraphBy.id +
      "&FromDate=" +
      FromDate +
      "&ToDate=" +
      ToDate;
    setVoucherReqQuery(reqBody);
  }

  useEffect(() => {
    if (voucherReqQuery !== "") {
      getProviderDashBoard(voucherReqQuery)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data, "customer fav");
            //setLoader(false);
            setProviderDashBoardData(res.data);
            //console.log(providerDashBoardData,"provider Dashboard Data");

            let temp1 = [];
            let temp2 = [];
            res.data.voucherReedemedGraphData.forEach((voucherData) => {
              temp1.push(voucherData.yValue);
              temp2.push(voucherData.xValue);
            });
            setVouchersGraphData(temp1);
            setVouchersGraphDataTitle(temp2);
          }
        })
        .catch((err) => {
          // setLoader(false);
          console.log(err);
        });
    }
  }, [voucherReqQuery]);

  return (
    <>
      <NavBar
        notificationSVG={<NotificationSVG />}
        navHeading="Dashboard"
        subHeading=""
        showButton={true}
      />
      <Container fluid>
        <Row>
          <Col lg={9} md={9} className="bg-base-100 dashboard-lside">
            <Row className="pt-3">
              <Col>
                <div className="d-flex align-items-center mb-3">
                  <h1
                    className="d-inline float-start"
                    style={{
                      color: "#AFAFAF",
                      fontSize: "12px",
                      paddingRight: "10px",
                      marginBottom: "0",
                    }}
                  >
                    SUMMARY
                  </h1>
                  <p className="d-flex float-start yr-summary">
                    <div className="me-1">
                      <SelectComponent
                        label=""
                        option={GraphByItem}
                        value={salesGraphBy}
                        defaultValue={salesGraphBy}
                        inputsize="form-control-sm rouned-3 sel-chart float-start me-2"
                        handleOnchange={SalesGraphSelectChange}
                      />
                    </div>
                    <div className="me-1">
                      <SelectComponent
                        label=""
                        option={yearList}
                        defaultValue={salesYear}
                        value={salesYear}
                        inputsize="form-control-sm rouned-3 sel-chart float-start"
                        handleOnchange={SalesYearChange}
                      />{" "}
                    </div>
                    {salesGraphBy.id === "Month" ||
                    salesGraphBy.id === "Day" ? (
                      <div className="me-1">
                        <SelectComponent
                          label=""
                          option={monthList}
                          value={salesMonth}
                          defaultValue={salesMonth}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={SalesMonthChange}
                        />{" "}
                      </div>
                    ) : null}
                    {salesGraphBy.id === "Day" ? (
                      <SelectComponent
                        label=""
                        option={salesDayList}
                        value={salesDay}
                        defaultValue={salesDay}
                        inputsize="form-control-sm rouned-3 sel-chart float-start"
                        handleOnchange={SalesDayChange}
                      />
                    ) : null}
                  </p>
                </div>
                <div>
                  <h4>
                    ${providerDashBoardData.revenue}{" "}
                    <small className="mark bg-success text-white rounded-pill">
                      {" "}
                      {lastCurrentPerCalulate(
                        providerDashBoardData.revenue,
                        providerDashBoardData.lastRevenue
                      )}{" "}
                      %
                    </small>
                  </h4>
                </div>
                <h1
                  className="d-inline float-start"
                  style={{
                    color: "#AFAFAF",
                    fontSize: "12px",
                    paddingRight: "10px",
                    marginBottom: "0",
                  }}
                >
                  SALES
                </h1>
                  <BarChart
                    graphDataTitles={salesGraphDataTitle}
                    graphData={salesGraphData}
                    bgColor="#3734A9"
                    hoverBgColor="#F9896B"
                  />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6}>
                <Card className="text-left mb-5 chart">
                  <Card.Header>
                    <p className="float-start">Customer</p>
                    <p className="d-flex float-end yr-summary">
                      <div className="me-1">
                        <SelectComponent
                          label=""
                          option={GraphByItem}
                          value={customerGraphBy}
                          defaultValue={customerGraphBy}
                          inputsize="form-control-sm rouned-3 sel-chart float-start me-2"
                          handleOnchange={CustomerGraphSelectChange}
                        />
                      </div>
                      <div className="me-1">
                        <SelectComponent
                          label=""
                          option={yearList}
                          value={customerYear}
                          defaultValue={customerYear}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={CustomerYearChange}
                        />
                      </div>
                      {customerGraphBy.id === "Month" ||
                      customerGraphBy.id === "Day" ? (
                        <div className="me-1">
                          <SelectComponent
                            label=""
                            option={monthList}
                            value={customerMonth}
                            defaultValue={customerMonth}
                            inputsize="form-control-sm rouned-3 sel-chart float-start"
                            handleOnchange={CustomerMonthChange}
                          />{" "}
                        </div>
                      ) : null}
                      {customerGraphBy.id === "Day" ? (
                        <SelectComponent
                          label=""
                          option={customerDayList}
                          value={customerDay}
                          defaultValue={customerDay}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={CustomerDayChange}
                        />
                      ) : null}
                    </p>
                  </Card.Header>
                  <Card.Body>
                    <PieChart
                      graphDataTitles={customersGraphDataTitle}
                      graphData={customersGraphData}
                      graphColors={customerGraphColor}
                    />
                  </Card.Body>
                  <Card.Footer className="text-left footer-details d-flex justify-content-start">
                    {customerGraphFooter}
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg={6} md={6}>
                <Card className="text-left mb-5 chart">
                  <Card.Header>
                    <p className="float-start">Voucher Redemption</p>
                    <p className="d-flex float-end yr-summary">
                      <div className="me-1">
                        <SelectComponent
                          label=""
                          option={GraphByItem}
                          value={voucherGraphBy}
                          defaultValue={voucherGraphBy}
                          inputsize="form-control-sm rouned-3 sel-chart float-start me-2"
                          handleOnchange={VoucherRedemptionGraphSelectChange}
                        />{" "}
                      </div>
                      <div className="me-1">
                        <SelectComponent
                          label=""
                          option={yearList}
                          value={voucherYear}
                          defaultValue={voucherYear}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={VoucherYearChange}
                        />{" "}
                      </div>
                      {voucherGraphBy.id === "Month" ||
                      voucherGraphBy.id === "Day" ? (
                        <div className="me-1">
                          <SelectComponent
                            label=""
                            option={monthList}
                            value={voucherMonth}
                            defaultValue={voucherMonth}
                            inputsize="form-control-sm rouned-3 sel-chart float-start"
                            handleOnchange={VoucherMonthChange}
                          />{" "}
                        </div>
                      ) : null}
                      {voucherGraphBy.id === "Day" ? (
                        <SelectComponent
                          label=""
                          option={voucherDayList}
                          value={voucherDay}
                          defaultValue={voucherDay}
                          inputsize="form-control-sm rouned-3 sel-chart float-start"
                          handleOnchange={VoucherDayChange}
                        />
                      ) : null}
                    </p>
                  </Card.Header>
                  <Card.Body>
                    <DoughnutChart
                      graphDataTitles={vouchersGraphDataTitle}
                      graphData={vouchersGraphData}
                      graphColors={vouchersGraphColor}
                    />
                  </Card.Body>
                  <Card.Footer className="text-left footer-details">
                    {voucherGraphFooter}
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 mb-4">
                <SitesMap markers={providerDashBoardData.siteStatusData} />
              </Col>
            </Row>
          </Col>
          <Col lg={3} md={3} className="dashboard-rside p-0">
            <div className="mt-5">
              <h5 className="mb-3">New Orders </h5>

              <ListGroup as="ol" style={{ cursor: "pointer" }}>
                {orderListData.records?.map((c) => (
                  <ListGroup.Item
                    key={c.id}
                    as="li"
                    className="d-flex justify-content-between align-items-center border-0 border-bottom"
                    variant="light"
                  >
                    <CustomListGroup
                      id={c.id}
                      heading={c.id}
                      subheading={new moment(c.createdOn).format(
                        "DD MMM, yyyy"
                      )}
                      handleViewBTN={() => handleOrderView(c.id)}
                      link={true}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
              {!orderListData.records && orderListData.records?.length <= 0 ? (
                <h5 className="mb-3">No new orders </h5>
              ) : (
                ""
              )}
              <div
                className="oneMore"
                style={{
                  textAlign: "right",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              >
                <a
                  className="hover-item"
                  onClick={() => {
                    history.push({
                      pathname: "/provider/orders",
                      state: { prevLoc: location.pathname },
                    });
                  }}
                >
                  <PlusSVG fill="#F99300" />
                  <span style={{ color: "#F99300" }}>Show more</span>
                </a>
              </div>
            </div>
            <div className="mt-5 mb-5">
              <h5 className="mb-3">New Payments</h5>
              <ListGroup as="ol" style={{ cursor: "pointer" }}>
                {latestPayments.data?.slice(0, 5).map((c) => (
                  <ListGroup.Item
                    key={c.siteId}
                    as="li"
                    className="d-flex justify-content-between align-items-center border-0 border-bottom"
                    variant="light"
                  >
                    <CustomListGroup
                      id={c.siteId}
                      heading={c.amount}
                      subheading={`Order payment for ${c.orderId}`}
                      handleViewBTN={() => handleOrderView(c.orderId)}
                      link={true}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <div
                className="oneMore"
                style={{
                  textAlign: "right",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              >
                <a
                  className="hover-item"
                  onClick={() => {
                    history.push("/provider/orders");
                  }}
                >
                  <PlusSVG fill="#F99300" />
                  <span style={{ color: "#F99300" }}>Show more</span>
                </a>
              </div>
            </div>
            <div className="mb-5">
              <div className="d-flex justify-content-between w-100">
                <h5 className="float-start pt-2">Least Booked Locations</h5>
                <div className="me-2 pt-1">
                  <SelectComponent
                    label=""
                    option={leastBookSitesArr}
                    value={selectedLeastBookSite}
                    defaultValue={selectedLeastBookSite}
                    inputsize="rouned-3 sel-chart float-end sel-least-booked-sites"
                    handleOnchange={handleLeastBookSiteList}
                    size="sm"
                  />{" "}
                </div>
              </div>
              <ListGroup as="ol" style={{ cursor: "pointer" }}>
                {leastBookedSiteData.data?.slice(0, 5).map((c) => (
                  <ListGroup.Item
                    key={c.siteId}
                    as="li"
                    className="d-flex justify-content-between align-items-center border-0 border-bottom"
                    variant="light"
                  >
                    <CustomListGroup
                      id={c.siteId}
                      heading={c.siteName}
                      subheading={c.siteAddress}
                      handleViewBTN={() => handleLeastBookSiteView(c.siteId)}
                      link={true}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </Col>
        </Row>
      </Container>
      {/* navbar filters modal  */}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
      {orderView && (
        <ConfirmModal
          title="Order Detail"
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Ok"
          handleConfirm={() => handleOrderModel(false)}
          handleClose={() => handleOrderModel(false)}
        >
          <div className="text-center" style={{ height: "500px" }}>
            {/* siteDetails.location &&
              <AddSitePageMap center={{lat: siteDetails.location.latitude, lng:siteDetails.location.longitude}}  lat={siteDetails.location.latitude} lng={siteDetails.location.longitude} getMyLocation={() => {}} handleChange={()=>{}} onPlacesChanged={() => {}}  onMarkerDragEnd={()=> {}} />
             */}
            {order && <OrderDetailPage order={order} />}
          </div>
        </ConfirmModal>
      )}{" "}
      {/* <div className="providerDashboard">
        <div
          className="sideNav"
          style={openNav ? { width: "15%" } : { width: "6%" }}
        >
          <SideNav setOpenNav={setOpenNav} openNav={openNav} />
        </div>
        <div className="mainSection01"> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

//export default Dashboard;

import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { CrossSVG } from "../../../assets/svgComponents/svg";
import { addCard } from "../../../services/Common";
import { InputDatePicker } from "../../InputDatePicker/InputDatePicker";
import { InputField } from "../../InputField";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { SelectionComponent } from "../../SelectionComponent/SelectionComponent";

function AddNewCardModal({
  setShow,
  title,
  selcardToUpdate,
  setIsCardDetailsPost,
  isCardDetailsPost,
}) {
  const { addToast } = useToasts();
  const [expiryDate, setExpiryDate] = useState(new Date());

  const today = new Date();
  const curYear = today.getFullYear();
  let endYear = curYear + 8;
  const yearList = [];
  for (var y = curYear; y <= endYear; y++) {
    let obj = { id: y, name: y };
    yearList.push(obj);
  }

  const monthList = [];
  for (var m = 1; m <= 12; m++) {
    let mon = ("0" + m).slice(-2);
    let obj = { id: mon, name: mon };
    monthList.push(obj);
  }

  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      cardHolderName: "",
      month: "",
      year: "",
      cvc: "",
    },
    validationSchema: Yup.object().shape({
      cardNumber: Yup.string()
        .matches(new RegExp("[0-9]{7}"), "Please enter valid card number")
        .min(16, "Card number should be 16 digit")
        .max(16, "Card number should be 16 digit")
        .required("Card Number is required"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
      // console.log("year", values.expiryDate.getFullYear());
      // console.log("month", values.expiryDate.getMonth());
      let formData = {
        CardHolderName: values.cardHolderName,
        cardNumber: values.cardNumber,
        CVC: values.cvc,
        expiryMonth: values.month,
        expiryYear: values.year,
        cardType: "visa",
        paymentCardOtherDetails: "",
      };
      console.log("formData", formData);
      addCard(formData)
        .then((res) => {
          if (res.status === 201) {

            //setIsCardDetailsPost(true);

            addToast("New card added successfully", {
              appearance: "success",
            });
            console.log("addcard", res);
            setShow(false);
          }
        })
        .catch((err) => {

          //setIsCardDetailsPost(false);

          console.log("err", err);
        });
    },
  });

  const cardNumberError = formik.touched.cardNumber && formik.errors.cardNumber;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="confirmModal">
        <div className="confirmModalOver" onClick={() => setShow(false)}></div>
        <div
          className="confirmModalInner modalbox"
          //   style={{maxWidth:`${width}`}}
        >
          <div className="confirmModalTop d-flex justify-content-between">
            <h3 className="font-weight-light mb-1 mw-100 p-4 ps-3 confirmModalTopTitle">
              {title}
            </h3>
            <span
              className="px-3 py-2 confirmModalTopCross"
              onClick={() => setShow(false)}
            >
              <CrossSVG />
            </span>
          </div>
          <div className="modal-childs">
            <div className="childs">
              <InputField
                placeholder="Card Number"
                label="Card Number"
                name="cardNumber"
                value={formik.values.cardNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                max={16}
              />
              {cardNumberError && (
                <p className="text-danger mt-1" style={{ fontSize: "14px" }}>
                  {formik.errors.cardNumber}
                </p>
              )}
            </div>
            <div className="childs">
              <InputField
                placeholder="Name on Card"
                label="Name on Card"
                name="cardHolderName"
                value={formik.values.cardHolderName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />{" "}
            </div>

            <div className="childs row">
              <div className="col-md-4">
                <SelectionComponent
                  label="Expiry Month"
                  placeholder="Select Month"
                  name="month"
                  option={monthList}
                  handleOnchange={formik.handleChange}
                />
                {/*  <InputField
                  placeholder="Month"
                  label="Expire Month"
                  name="month"
                  value={formik.values.month}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  inputFieldSize="sizeInput"
                  max={2}
                /> */}{" "}
              </div>
              <div className="col-md-4">
                <SelectionComponent
                  label="Expiry Year"
                  placeholder="Select Year"
                  name="year"
                  option={yearList}
                  handleOnchange={formik.handleChange}
                />
                {/*  <InputField
                  placeholder="Year"
                  label="Expire Year"
                  name="year"
                  value={formik.values.year}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  inputFieldSize="sizeInput"
                  max={4}
                /> */}{" "}
              </div>
              <div className="col-md-4">
                <InputField
                  placeholder="cvc"
                  label="CVC"
                  name="cvc"
                  value={formik.values.cvc}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  inputFieldSize="sizeInput"
                  max={3}
                />{" "}
              </div>
            </div>
          </div>
          <div className="confirmModalBottom border-top">
            <div className="row no-gutters g-0">
              {/* <div className="col" onClick={props.confirm}> */}
              <button
                className="btn btn-success btn-block border-0  w-100 confirmModalConfirmBtn"
                //   disabled={props.disable}
                // onClick={() => props.handleConfirm()}
                type="submit"
                disabled={formik.isSubmitting}
              >
                <span className="text-md">Add card</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Form>
  );
}

export default AddNewCardModal;

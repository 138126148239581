import React from "react";
import { Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { PassThroughSVG } from "../../../../../assets/svgComponents/inventorySVG";
import { ExportCSVSVG } from "../../../../../assets/svgComponents/table";
import { checkPermission } from "../../../../../utils";

const Advertisers = ({
  userData,
  handleEditGridCell,
  handleDeleteGridCell,
  handlePerRowsChange,
  handlePageChange,
  currentPage,
  pageSize,
  loading,
  handleSort,
  markActiveInactive,
  loadExportData
}) => {
  const columns = [
    {
      name: "",
      sortable: false,
      cell: (row) => {
        return (
          row.profilePicture &&
          <span>
            <div className="logoContainer">
              <img src={`https://gopinit.blob.core.windows.net/image-container/${row.profilePicture}`} alt="logo" />
            </div>
          </span>
        );
      }
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
    },
    {
      name: "Email Address",
      selector: (row) => row.emailId,
      sortable: true,
      sortField: "EmailId",
    },
    {
      name: "Access Type",
      selector: (row) => row.accessTypeName,
      sortable: false,
      sortField: "AccessTypeId",
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <div>
            {row.status ? (
              <div className="gridTableBody_td active">Active </div>
            ) : (
              <div className="gridTableBody_td inActive">InActive</div>
            )}
          </div>
        );
      },
      sortable: false,
      sortField: "Status",
    },
    {
      cell: (row) => (
        <span className="gridTableBody_td_passThrough">
          {checkPermission("UserManagement", "Full") && (
            <DropdownButton
              align="end"
              className="dropDownBtn"
              title={<PassThroughSVG />}
            >
              {row.status == 1 ? (
                <Dropdown.Item onClick={(e) => markActiveInactive(row, false)}>
                  Mark Inactive
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={(e) => markActiveInactive(row, true)}>
                  Mark Active
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={(e) => handleEditGridCell(row)}>
                Edit{" "}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteGridCell(row)}>
                Delete
              </Dropdown.Item>
            </DropdownButton>
          )}
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const Export = ({ onExport }) => (
    <button
      className="blueBgButton"
      onClick={(e) => onExport(e.target.value)}
    >
      <span className="btnSvg"><ExportCSVSVG fill="#FFFFFF" /></span>
      Export CSV
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );
  if (userData?.records) {
    return (
      <>
        {loading ? (null
          //   <Spinner animation="border" role="status" style={{marginTop: "20%",
          //   marginLeft: "50%",
          //   width: "50px",
          //   height: "50px",
          //   color: "#F99300"}}>
          // </Spinner>
        ) : (
          <DataTable
            // title="SysUsers"
            columns={columns}
            data={userData.records}
            progressPending={loading}
            paginationDefaultPage={currentPage}
            pagination
            paginationServer
            paginationTotalRows={userData.totalRecords}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationPerPage={pageSize}
            selectableRows
            dense
            onSort={handleSort}
            sortServer
            actions={actionsMemo}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default Advertisers;

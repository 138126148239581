import {useState} from 'react'
import { Button, Modal } from 'react-bootstrap';
import { AuthService } from '../../services/AuthService';
export const LoginModal = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => {
    const authService = new AuthService();
        
    authService.logout();
    localStorage.removeItem("accessToken");
    authService.login();
    };
    const handleShow  = () => setShow(true);
    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static"  keyboard={false} className="skiploginmodal border" >
                <Modal.Header className="border-bottom p-0">
                    <Modal.Title className="d-flex justify-content-between w-100"><h3>Login</h3>
                    
                    <span className="px-3 py-2 confirmModalTopCross" onClick={()=>setShow(false)}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M14.9764 15.6745C15.1717 15.8697 15.4884 15.8697 15.6837 15.6745C15.879 15.4793 15.879 15.1629 15.6837 14.9677L8.71129 7.99957L15.6834 1.0211C15.8786 0.825751 15.8786 0.509025 15.6834 0.313675C15.4883 0.118324 15.1718 0.118324 14.9767 0.313675L8.00396 7.29269L1.01983 0.312905C0.824512 0.117704 0.507831 0.117703 0.312508 0.312905C0.117185 0.508106 0.117185 0.82459 0.312508 1.01979L7.29718 8.00011L0.323982 14.9796C0.128808 15.175 0.128808 15.4917 0.323982 15.687C0.519155 15.8824 0.835595 15.8824 1.03077 15.687L8.00451 8.707L14.9764 15.6745Z" fill="#AFAFAF"></path></svg></span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><h3>For access to this page click Login.</h3>
                
               </Modal.Body>
                <Modal.Footer className="border-0 p-0">                    
                    <Button variant="primary" onClick={handleClose} className="m-0 rounded-0 w-100">
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  InputGroup,
  Form,
  FloatingLabel,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import { InputField } from "../../../../../../../components/InputField";
import NavBar from "../../../../../../../components/navBar";
import { TextAria } from "../../../../../../../components/TextAria";
import ProjectContext from "../../../../../../../context/ProjectContext";
import { getUpdateOnBoardingStepDone } from "../../../../../../../services/Provider";
import { addCharges } from "../../../../../../../services/Provider/Configurator/Inventory";
import { useToasts } from "react-toast-notifications";
import UpdatesModal from "../../../../../../../components/profile/Modal/UpdatesModal";
import { checkPermission } from "../../../../../../../utils";

export const AddChargePage = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { handleActiveTab } = useContext(ProjectContext);
  const [chargeConfirmModal, setChargeConfirmModal] = useState(false);
  let tempStepCount = parseInt(localStorage.getItem("onBoardingStepDone"));
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);

  const handleOnCancel = () => {
    if (tempStepCount == 2) {
      localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
      let bodyData = [
        {
          path: "/onBoardingStepDone",
          op: "add",
          value: tempStepCount + 1,
        },
      ];
      getUpdateOnBoardingStepDone(bodyData)
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
      history.push("/provider");
    } else {
      history.goBack();
    }
    // history.push("/provider/configurator/inventory");
  };

  const formik = useFormik({
    initialValues: {
      chargeName: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      chargeName: Yup.string()
        .min(5, "Please enter title with five or more characters")
        .required("Charge name is required"),
    }),
    onSubmit: (values) => {
      console.log(values, "values");
      let formData = {
        name: values.chargeName,
        description: values.description,
      };
      addCharges(formData)
        .then((res) => {
          // alert(tempStepCount);
          console.log("res", res);
          if (res.status == 201) {
            if (tempStepCount == 2) {
              localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
              let bodyData = [
                {
                  path: "/onBoardingStepDone",
                  op: "add",
                  value: tempStepCount + 1,
                },
              ];
              getUpdateOnBoardingStepDone(bodyData)
                .then((res) => {
                  console.log("res", res);
                })
                .catch((err) => {
                  console.log("err", err);
                });
            }
            // if (tempStepCount == 2) {
            //   localStorage.setItem("onBoardingStepDone", tempStepCount + 1);
            //   let bodyData = [
            //     {
            //       path: "/onBoardingStepDone",
            //       op: "add",
            //       value: tempStepCount + 1,
            //     },
            //   ];
            //   getUpdateOnBoardingStepDone(bodyData)
            //     .then((res) => {
            //       if (!chargeConfirmModal) {
            //         history.push("/provider");
            //       }
            //     })
            //     .catch((err) => {
            //       console.log("err", err);
            //     });
            // } else {
            handleActiveTab("CHARGES");
            //setChargeConfirmModal(true);
            addToast("Charge added successfully.", { appearance: "success" });
            history.push("/provider/configurator/inventory");
            // }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  });
  const chargeNameError = formik.touched.chargeName && formik.errors.chargeName;

  const handleChargeConfirmModal = () => {
    setChargeConfirmModal(false);
    history.push("/provider/configurator/inventory");
  };
  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };
  return (
    <>
      <Form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <NavBar
          blueBtnText="Save"
          whiteBtnText={"Cancel"}
          notificationSVG={<NotificationSVG />}
          navHeading="Add Charge"
          subHeading="Configurator • All Charges"
          subActiveHeading="Add Charge"
          // handleBlueBtn={handleOnSavebtn}
          handleWhitebtn={handleOnCancel}
          type="submit"
          handleNotificationSVG={handleNotificationSVG}
          showButton={true}
        />
        <div className="girdMain gridSection">
          <Card className="gridCard" aria-labelledby="gridCard">
            <Card.Body className="gridCardBody p-0">
              <div className="formSection">
                <p className="mt-4 mx-4">Charge Details</p>
                <div className="mx-4">
                  <div className="row my-4">
                    <div className="col-md-5">
                      <InputField
                        type="text"
                        name="chargeName"
                        value={formik.values.chargeName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="name@example.com"
                        label="Charge Name"
                        inputFieldSize="sizeOfInputField"
                      />
                      {chargeNameError && (
                        <p
                          className="text-danger mt-1 ms-2"
                          style={{ fontSize: "14px" }}
                        >
                          {formik.errors.chargeName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form-floating">
                        <textarea
                          type="text"
                          name="description"
                          value={formik.values.description}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="Description"
                          label="Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Form>

      {chargeConfirmModal && (
        <ConfirmModal
          title={`Add Charge`}
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Ok"
          handleConfirm={handleChargeConfirmModal}
        >
          <div className="text-center childs">
            <p className="newListingCreated_description">
              Charge added successfully.
            </p>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

import React, { useState } from 'react';
import { Col, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getMediaFilterOptionsAsync, getEnvironmentFilterOptionsAsync, setCostFilter, setMediaTypeFilter, setEnvironmentFilter, setFromDateFilter, setUptoDateFilter, getPriceRangeAsync } from "../../../../services/reducers/listing-reducer";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { SVGCalendar } from '../../../../assets/svgComponents/svg';
import moment from "moment";


export const ListingFilters = ({ menuPlacement, isMedaiTypeNotAvailable=false }) => {
    const dispatch = useDispatch();
    const cost = null;
    const { listingFilterCost, listingFilterMedia, listingFilterEnvironment, listingFilterDates } = useSelector((state) => state.listing);
    const { costOptions, mediaTypeOptions, environmentOptions, datesOptions, minCost, maxCost, listingFilterFromDate, listingFilterUptoDate } = useSelector((state) => state.listing);

    const onDateChanges = (date, date2) => {
        if (date && date2) {
            if (date) {
                dispatch(setFromDateFilter(date));
            }
            if (date2) {
                dispatch(setUptoDateFilter(date2));
            }
        } else {
            dispatch(setFromDateFilter(null));
            dispatch(setUptoDateFilter(null));
        }

    }

    const ValueContainer = ({ children, ...props }) => {
        let [values, input] = children;

        if (Array.isArray(values)) {
            const plural = values.length === 1 ? "" : "s";
            values = `${values.length} item${plural}`;
        }

        return (
            <components.ValueContainer {...props}>
                {values}
                {input}
            </components.ValueContainer>
        );
    };

    useEffect(async () => {
        dispatch(getPriceRangeAsync());
        dispatch(getMediaFilterOptionsAsync());
        dispatch(getEnvironmentFilterOptionsAsync());
    }, [dispatch]);

    return (
        <>
            <Col className='p-2' xs={3}>
                <div className="home_filter_cntr">
                    <label htmlFor="cost_ddl" className="m-0 textGray2 text-sm home_lbl">Cost</label>
                    <Select
                        id="cost_ddl"
                        name="Cost"
                        value={listingFilterCost}
                        onChange={async (val) => { await dispatch(setCostFilter(val)); }}
                        menuPlacement={menuPlacement && menuPlacement.length > 0 ? "top" : "bottom"}
                        options={costOptions}
                        className="customSelectDrop"
                        placeholder="select"
                        defaultValue={listingFilterCost}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        components={{ ValueContainer }}
                       // menuIsOpen={true}
                    />
                </div>
            </Col>
            {!isMedaiTypeNotAvailable &&
            <Col className='p-2' xs={3}>
                <div className="home_filter_cntr">
                    <label htmlFor="mediatypes_ddl" className="m-0 textGray2 text-sm home_lbl">Media</label>
                    <Select
                        id="mediatypes_ddl"
                        name="MediaTypes"
                        isMulti
                        value={listingFilterMedia}
                        onChange={(val) => { dispatch(setMediaTypeFilter(val)); }}
                        menuPlacement={menuPlacement && menuPlacement.length > 0 ? "top" : "bottom"}
                        options={mediaTypeOptions}
                        className="customSelectDrop"
                        placeholder="select"
                        defaultValue={listingFilterMedia}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        hideSelectedOptions={false}
                        components={{ ValueContainer }}
                    />
                </div>
            </Col>
            }
            <Col className='p-2' xs={3}>
                <div className="home_filter_cntr">
                    <label htmlFor="environment_ddl" className="m-0 textGray2 text-sm home_lbl">Environment</label>
                    <Select
                        id="environment_ddl"
                        name="Environments"
                        isMulti
                        value={listingFilterEnvironment}
                        onChange={(val) => { dispatch(setEnvironmentFilter(val)); }}
                        menuPlacement={menuPlacement && menuPlacement.length > 0 ? "top" : "bottom"}
                        options={environmentOptions}
                        className="customSelectDrop"
                        placeholder="select"
                        defaultValue={listingFilterEnvironment}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        hideSelectedOptions={false}
                        // components={{ ValueContainer }}
                    />
                </div>
            </Col>
            <Col className='p-2 home_filters' xs={3}>
                <div className="home_filter_cntr">
                    <label className="m-0 textGray2 text-sm home_lbl">Dates</label>
                    <DatePicker
                        isClearable
                        placeholderText="Select"
                        selectsRange
                        startDate={listingFilterFromDate ? new Date(listingFilterFromDate) : null}
                        endDate={listingFilterUptoDate ? new Date(listingFilterUptoDate) : null}
                        monthsShown={2}
                        minDate={new Date()}
                        onChange={(update) => {

                            if (update && update.length > 0 && update[0])
                                dispatch(setFromDateFilter(moment(update[0]).format("DD-MMMM-YYYY")));
                            else
                                dispatch(setFromDateFilter(null));

                            if (update && update.length > 1 && update[1])
                                dispatch(setUptoDateFilter(moment(update[1]).format("DD-MMMM-YYYY")));
                            else
                                dispatch(setUptoDateFilter(null));

                        }}
                        style={{ padding: 12 }}
                    />
                </div>
            </Col>
        </>
    )
};

import React, { useEffect, useState } from "react";
import {
  Collateral,
  MapMarkerSVG,
} from "../../../../../../../assets/svgComponents/inventorySVG";
import { MiniCard } from "./MiniCard";
import { ItemNotAvailable } from "./ItemNotAvailable";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import { AddSitePageMap } from "../../../../../../../components/Maps/sitePage/AddSitePageMap";
import { ListGroup } from "react-bootstrap";
import { CustomListGroup } from "../../../../../../../components/ListGroup";
import { DownloadSVG } from "../../../../../../../assets/svgComponents/svg";

export const InformationPage = ({ siteDetails, siteListingDetails }) => {
  const [avalSign, setAvalSign] = useState([]);
  const [avalService, setAvalService] = useState([]);
  const [availCharge, setAvailCharge] = useState([]);
  const [mapModel, setmapModel] = useState(false);

  const handleMapModel = (state) => {
    setmapModel(state);
  };

  if (siteDetails) {
    return (
      <div className="informationMainContainer">
        <div className="informationContainer bg-base-100 mb-4 h-100 flex-grow-1 overflow-auto">
          <div className="informaton_Section d-flex align-items-start">
            <div className="lable1 id">
              <p className="mb-0">ID</p>
              <span>#{siteDetails.id.substring(0, 5).toUpperCase()}</span>
            </div>
            <div className="lable2 location">
              <p className="mb-0">LOCATION</p>
              <span>{siteDetails.location.googleAddress}</span>
              
            </div>
            <div className="lable2">
              <p className="mb-0">RADIUS OF OPERATION</p>
              <span>{siteDetails.location.radiusOfOperation}</span>
            </div>
          </div>
          
          <div className="informaton_Section d-flex align-items-start">
          
            <div className="lable1">
              <p className="mb-0">ENVIRONMENT</p>
              <span>{siteDetails.environment.name}</span>
            </div>
            <div className="lable2">
              <p className="mb-0">STATUS</p>
              <span>{siteDetails.statusName}</span>
            </div>
            <div className="lable2">
              <p className="mb-0">State Taxation</p>
              <span>{siteDetails.stateName}</span>
            </div>
          </div>

          <div className="image_Section">
            <p>IMAGES</p>
            <div className="imageCardContainer">
              {siteDetails.images &&
                siteDetails.images.map((item) => {
                  return (
                    <>
                      <div className="imageCard">
                        <a
                          download="image.jpg"
                          href={`https://gopinit.blob.core.windows.net/image-container/${item.image}`}
                          target="_blank"
                        >
                          <img
                            src={`https://gopinit.blob.core.windows.net/image-container/${item.image}`}
                            alt="img"
                          />
                        </a>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <div className="collateralContainer">
            <p className="mb-3">COLLATERAL</p>
            {siteDetails.collaterals &&
              siteDetails.collaterals.map((item) => {
                return (
                  <>
                    <span>
                        <a
                          className="btn btn-light"
                          download={item.collateralInfo}
                          href={`https://gopinit.blob.core.windows.net/image-container/${item.collateral}`}
                          target="_blank"
                        >
                          <p>
                              {/* <Collateral fill="#58B85C" /> */}
                            <DownloadSVG fill="#58B85C"  />
                          </p>
                          </a>
                      </span>
                  </>
                );
              })}
          </div>
          <div className="descriptionContainer mb-5">
            <p className="mb-0">DESCRIPTION</p>
            <span>{siteDetails.description}</span>
          </div>
          <div className="descriptionContainer mb-5">
            <p className="mb-0">MAP</p>
            <div className="text-right pt-4 pb-4" style={{ height: "300px" }}>
              {siteDetails.location && (
                <AddSitePageMap
                  center={{
                    lat: siteDetails.location.latitude,
                    lng: siteDetails.location.longitude,
                  }}
                  lat={siteDetails.location.latitude}
                  lng={siteDetails.location.longitude}
                  getMyLocation={() => {}}
                  handleChange={() => {}}
                  onPlacesChanged={() => {}}
                  onMarkerDragEnd={() => {}}
                  readonly={true}
                />
              )}
              </div>
          </div>
        </div>
        <div className="informatinListContainer h-100 flex-grow-1 overflow-auto borderAll rounded-10">
          <p>
            {`${
              siteListingDetails?.signDetails &&
              siteListingDetails?.signDetails.length > 0
                ? siteListingDetails?.signDetails.length
                : ""
            } Signs available at this site`}
          </p>
          <div className="dashboard-rside p-0">
            {siteListingDetails?.signDetails &&
            siteListingDetails?.signDetails.length > 0 ? (
              <ListGroup as="ol">
                {siteListingDetails.signDetails?.map((c) => (
                  <ListGroup.Item
                    key={c.id}
                    as="li"
                    className="d-flex justify-content-between align-items-center border-0 border-bottom"
                    variant="light"
                  >
                    <CustomListGroup
                      id={c.id}
                      heading={c.name}
                      subheading={c.description}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <ItemNotAvailable title="No Signs available with this site"/>
            )}
            {/* <div className="oneMore">
            <PlusSVG fill="#F99300" />
            <span>1 more</span>
          </div> */}
          </div>
          <div className="dashboard-rside p-0">
            <p>
              {`${
                siteListingDetails?.serviceDetails &&
                siteListingDetails?.serviceDetails.length > 0
                  ? siteListingDetails?.serviceDetails.length
                  : ""
              } Service available at this site`}
            </p>
            {siteListingDetails?.serviceDetails &&
            siteListingDetails?.serviceDetails.length > 0 ? (
              <ListGroup as="ol">
                {siteListingDetails.serviceDetails?.map((c) => (
                  <ListGroup.Item
                    key={c.id}
                    as="li"
                    className="d-flex justify-content-between align-items-center border-0 border-bottom"
                    variant="light"
                  >
                    <CustomListGroup
                      id={c.id}
                      heading={c.name}
                      subheading={c.description}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <ItemNotAvailable title="No Services available with this site"/>
            )}
          </div>
          <div className="dashboard-rside p-0">
            <p>
              {`${
                siteListingDetails?.chargesDetails &&
                siteListingDetails?.chargesDetails.length > 0
                  ? siteListingDetails?.chargesDetails.length
                  : ""
              } Charges applicable at this site`}
            </p>
            {siteListingDetails?.chargesDetails &&
            siteListingDetails?.chargesDetails.length > 0 ? (
              <ListGroup as="ol">
                {siteListingDetails.chargesDetails?.map((c) => (
                  <ListGroup.Item
                    key={c.id}
                    as="li"
                    className="d-flex justify-content-between align-items-center border-0 border-bottom"
                    variant="light"
                  >
                    <CustomListGroup
                      id={c.id}
                      heading={c.name}
                      subheading={c.description}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <ItemNotAvailable title="No Charges available with this site"/>
            )}
          </div>
        </div>

        {mapModel && (
          <ConfirmModal
            title="Google Map"
            isModalHeader={true}
            isFooterBtns={false}
            confirmText="Ok"
            handleConfirm={() => handleMapModel(false)}
            handleClose={() => handleMapModel(false)}
          >
            <div className="text-center pt-4 pb-4" style={{ height: "400px" }}>
              {siteDetails.location && (
                <AddSitePageMap
                  center={{
                    lat: siteDetails.location.latitude,
                    lng: siteDetails.location.longitude,
                  }}
                  lat={siteDetails.location.latitude}
                  lng={siteDetails.location.longitude}
                  getMyLocation={() => {}}
                  handleChange={() => {}}
                  onPlacesChanged={() => {}}
                  onMarkerDragEnd={() => {}}
                  readonly={true}
                />
              )}
            </div>
          </ConfirmModal>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

import React, { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { PassThroughSVG } from "../../../../../../assets/svgComponents/inventorySVG";
import { Col, Dropdown, DropdownButton } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
// import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  deleteCharges,
  inventoryCharges,
} from "../../../../../../services/Provider/Configurator/Inventory";
import ProjectContext from "../../../../../../context/ProjectContext";
import { useToasts } from "react-toast-notifications";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import { UnionSVG, ExportCSVSVG } from "../../../../../../assets/svgComponents/table";
import { InputField } from "../../../../../../components/InputField";
import Button from "../../../../../../components/button/Button";
import { checkPermission } from "../../../../../../utils";

const ChargesPage = ({ activeTab, searchInput }) => {
  const { addToast } = useToasts();
  const { handleSelectedCharge, selectedCharge } = useContext(ProjectContext);
  const [loader, setLoader] = useState(false);
  const [chargesData, setChargesData] = useState();
  const [searchString, setSearchString] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [deleteAbleRow, setDeleteAbleRow] = useState();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const history = useHistory();
  const location = useLocation();

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setPageSize(newPerPage);
  };

  const handlePageChange = (page, perPage) => {
    setCurrentPage(page);
  };
  const searchChange = (e) => {
    setSearchString(e.target.value);
  };
  const handleSort = async (column, sortDirection) => {
    setSortColumn({ column: column, sortDirection: sortDirection });
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      sortField: "Id",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
    },
    {
      name: "description",
      selector: (row) => row.description,
      sortable: false,
    },
    {
      cell: (row) => (
        <span className="gridTableBody_td_passThrough">
          <DropdownButton
            align="end"
            className="dropDownBtn"
            title={<PassThroughSVG />}
          >
            <Dropdown.Item onClick={() => handleViewGridCell(row)}>
              View
            </Dropdown.Item>
            {checkPermission("Configurator", "Full") && (
              <Dropdown.Item onClick={(e) => handleEditGridCell(row)}>
                Edit{" "}
              </Dropdown.Item>
            )}
            {checkPermission("Configurator", "Full") && (
              <Dropdown.Item onClick={() => handleDeleteGridCell(row)}>
                Delete
              </Dropdown.Item>
            )}
          </DropdownButton>
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const loadData = async () => {
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: filterText,
      status: true,
    };
    setLoader(true);
    await inventoryCharges(reqBody)
      .then((res) => {
        if (res.status == 200) {
          setLoader(false);
          // console.log(res.data, "res evir");
          setChargesData(res.data);
          console.log("data", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const loadExportData = async () => {
    const reqBody = {
      page: 0,
      pageSize: 0,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      export: false,
      searchString: filterText,
      status: true,
    };
    await inventoryCharges(reqBody)
      .then((res) => {
        if (res.status == 200) {
          downloadCSV(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  // fetch charges listing data
  useEffect(async () => {
    loadData();
  }, [filterText, currentPage, pageSize, sortColumn]);

  const handleEditGridCell = (charge) => {
    handleSelectedCharge(charge);

    history.push(`${location.pathname}/edit-charges`);
  };

  const deleteCell = async (item) => {
    let chargeId = item.id;
    await deleteCharges(chargeId)
      .then((res) => {
        loadData();
        if (res.status == 204) {
          addToast("User deleted successfully.", {
            appearance: "success",
          });
          // toast("User successfully deleted.");
        }
      })
      .catch((err) => console.log("err", err));
  };

  const handleDeleteGridCell = (item) => {
    setDeleteAbleRow(item.id);
    setDeleteConfirmModal(true);
  };

  // const handleDeleteGridCell = (item) => {
  //   const options = {
  //     title: 'Delete',
  //     message: 'Do you want to delete?',
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => deleteCell(item)
  //       },
  //       {
  //         label: 'No',
  //         onClick: () => { }
  //       }
  //     ],
  //     childrenElement: () => <div />,
  //     closeOnEscape: true,
  //     closeOnClickOutside: true,
  //     keyCodeForClose: [8, 32],
  //     willUnmount: () => { },
  //     afterClose: () => { },
  //     onClickOutside: () => { },
  //     onKeypressEscape: () => { },
  //     overlayClassName: "overlay-custom-class-name"
  //   };
  //   confirmAlert(options);
  // };

  const handleConfirmModalDelete = async () => {
    console.log(deleteAbleRow);
    await deleteCharges(deleteAbleRow)
      .then(() => {
        loadData();
        addToast("Charge deleted successfully.", {
          appearance: "success",
        });
        setDeleteConfirmModal(false);
      })
      .catch(() => {
        addToast("There is an error while deleting the charge.", {
          appearance: "warning",
        });
      });
  };

  const handleViewGridCell = (charge) => {
    handleSelectedCharge(charge);
    history.push({
      pathname: `${location.pathname}/view-charges`,
      state: charge,
    });
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let res = array.records.map((item) => {
      return {
        Id: item.id,
        Name: item.name,
        Description: item.description,
      }
    });
    let csv = convertArrayOfObjectsToCSV(res);
    if (csv == null) return;

    const filename = "export-charges.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const Export = ({ onExport }) => (
    <button
      className="blueBgButton"
      onClick={(e) => onExport(e.target.value)}
    >
      <span className="btnSvg"><ExportCSVSVG fill="#FFFFFF" /></span>
      Export CSV
    </button>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => loadExportData()} />,
    []
  );

  const filteredItems = chargesData?.records.filter(
    (item) =>
      (item && item?.id?.toLowerCase().includes(filterText.toLowerCase())) ||
      item?.name?.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <>
      <Col className=" d-flex justify-content-start col-6 p-2">
        <div className="tableAction_input_container w-50">
          <InputField
            placeholder="Search Id, Name..."
            label={"Search ID, Search Name..."}
            onChange={(e) => setFilterText(e.target.value)}
          // value={searchInput}
          />
        </div>
      </Col>{" "}
      {/* {" "}
      <Grid
        loader={props.loader}
        tableHeading={chargesHeading}
        // tableBody={props.chargesData}
        // accessorName={servicesAccosserName}
      /> */}
      <DataTable
        progressPending={loader}
        columns={columns}
        data={filteredItems}
        allowOverflow={true}
        paginationDefaultPage={currentPage}
        pagination
        paginationServer
        paginationTotalRows={chargesData?.totalRecords}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={pageSize}
        //selectableRows
        dense
        onSort={handleSort}
        sortServer
        fixedHeader={{
          fixedHeader: true,
          fixedHeaderScrollHeight: "300px",
        }}
        actions={actionsMemo}
      />
      {deleteConfirmModal && (
        <ConfirmModal
          title="Delete Charge"
          isModalHeader={true}
          isFooterBtns={true}
          cancelText="No"
          confirmText="Yes"
          handleClose={() => setDeleteConfirmModal(false)}
          handleConfirm={() => handleConfirmModalDelete()}
          width="400px"
        >
          <div className="childs">
            <p className="newListingCreated_description ms-4 py-3">
              Do you want to delete the charge?
            </p>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default ChargesPage;

import React from "react";

function CustomRadioButton({
  value,
  onBlur,
  onChange,
  disabled,
  name,
  isDefault,
  index,
  text,
  onClick,
}) {
  return (
    <>
      <div className="radio-before">
        <label className="radio radio-gradient mr-3">
          <span className="radio__input">
            <input
              type="radio"
              name={name ? name : "radio"}
              id="radio1"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              onClick={onClick}
              disabled={disabled}
              checked={isDefault === value}
            />
            <span className="radio__control"></span>
          </span>
          <span className="radio__label" for="radio1">
            {text}
          </span>
        </label>
      </div>
    </>
  );
}

export default CustomRadioButton;

import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { CrossSVG } from "../../../assets/svgComponents/svg";
import { addCard } from "../../../services/Common";
import { updatePaymentCardDetails } from "../../../services/Provider";
import { InputDatePicker } from "../../InputDatePicker/InputDatePicker";
import { InputField } from "../../InputField";
import { SelectionComponent } from "../../SelectionComponent/SelectionComponent";
import { useToasts } from "react-toast-notifications";

function UpdateCardModal({ setShow, title, selcardToUpdate }) {
  const { addToast } = useToasts();
  const today = new Date();
  const curYear = today.getFullYear();
  let endYear = curYear + 8;
  const yearList = [];
  for (var y = curYear; y <= endYear; y++) {
    let obj = { id: y, name: y };
    yearList.push(obj);
  }

  const monthList = [];
  for (var m = 1; m <= 12; m++) {
    let mon = ("0" + m).slice(-2);
    let obj = { id: m, name: mon };
    monthList.push(obj);
  }

  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      cardHolderName: selcardToUpdate && selcardToUpdate.name,
      month: selcardToUpdate && selcardToUpdate.exp_month,
      year: selcardToUpdate && selcardToUpdate.exp_year,
      cvc: "",
    },
    // validationSchema: Yup.object().shape({
    //   media: Yup.string().required("email address is required"),
    // }),
    onSubmit: (values) => {
      let formData = {
        CardHolderName: values.cardHolderName,
        cardNumber: values.cardNumber,
        CVC: values.cvc,
        expiryMonth: values.month,
        expiryYear: values.year,
        cardType: "visa",
        cardId: selcardToUpdate && selcardToUpdate.id,
        paymentCardOtherDetails: "",
      };
      try {
        updatePaymentCardDetails(formData).then((r) => {
          addToast("Card details updated successfully", {
            appearance: "success",
          });
          setShow(false);
        });
      } catch (err) {
        console.log("err", err);
      }
    },
  });
  return (
    <Form className="py-4" onSubmit={formik.handleSubmit}>
      <div className="confirmModal">
        <div className="confirmModalOver" onClick={() => setShow(false)}></div>
        <div
          className="confirmModalInner modalbox"
          //   style={{maxWidth:`${width}`}}
        >
          <div className="confirmModalTop d-flex justify-content-between">
            <h3 className="font-weight-light mb-1 mw-100 p-4 ps-3 confirmModalTopTitle">
              {title}
            </h3>
            <span
              className="px-3 py-2 confirmModalTopCross"
              onClick={() => setShow(false)}
            >
              <CrossSVG />
            </span>
          </div>
          <div className="modal-childs">
            <div className="childs">
              <InputField
                placeholder="Name on Card"
                label="Name on Card"
                name="cardHolderName"
                value={formik.values.cardHolderName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled
              />{" "}
            </div>

            <div className="row childs">
              {/* <InputDatePicker
             date={expiryDate}
             setDate={setExpiryDate}
            />{" "} */}
              <div className="col-md-6">
                <SelectionComponent
                  label="Expire Month"
                  name="month"
                  option={monthList}
                  handleOnchange={formik.handleChange}
                  defaultValue={formik.values.month}
                />
                {/* <InputField
              placeholder="Month"
              label="Month"
              name="month"
              value={formik.values.month}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              inputFieldSize={`sizeOfInput`}
              max={2}
              
            /> */}{" "}
              </div>
              <div className="col-md-6">
                <SelectionComponent
                  label="Expire Year"
                  name="year"
                  option={yearList}
                  handleOnchange={formik.handleChange}
                  defaultValue={formik.values.year}
                />
                {/*  <InputField
              placeholder="Year"
              label="Year"
              name="year"
              value={formik.values.year}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              inputFieldSize={`sizeOfInput`}
              max={4}

            /> */}{" "}
              </div>
            </div>
          </div>
          <div className="confirmModalBottom border-top">
            <div className="row no-gutters g-0">
              {/* <div className="col" onClick={props.confirm}> */}
              <button
                className="btn btn-success btn-block border-0  w-100 confirmModalConfirmBtn"
                //   disabled={props.disable}
                // onClick={() => props.handleConfirm()}
                type="submit"
              >
                <span className="text-md">Update card</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Form>
  );
}

export default UpdateCardModal;

import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";

import { SpringSVG } from "../../../../../../../../assets/svgComponents/svg";
import ConfirmModal from "../../../../../../../../components/confirmModal/ConfirmModal";

import ProjectContext from "../../../../../../../../context/ProjectContext";
import { createSiteListing } from "../../../../../../../../services/Provider/Configurator/Listings";
import FeaturedListingModal from "./FeaturedListingModal";

const NewListingConfirmModal = ({
  title,
  newListingCreatedModal,
  setNewListingCreatedModal,
  ListingValue,
  handleConfirmListCreatedModal,
}) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { createListing } = useContext(ProjectContext);
  const handleCancel = () => {
    history.push("/provider/configurator/listings");
    setNewListingCreatedModal(false);
  };

  return (
    <>
      {/* new listing created modal */}
      {/* {newListingCreatedModal && ( */}
      <ConfirmModal
        // setConfirmModal={setAddSignModal}
        //   confirm={handleAddSignsModal}
        title={title?title:"New Listing Created"}
        isModalHeader={true}
        isFooterBtns={true}
        confirmText="Yes"
        cancelText="No"
        handleClose={() => handleCancel()}
        handleConfirm={() => handleConfirmListCreatedModal()}
      >
        <div className="text-center pt-4 ">
          <span className="">
            <SpringSVG />
          </span>
          <p className="py-4 newListingCreated_description">
            Would you like to feature this billable item?
            <br />
            Featured Listings are a great way to shine...{" "}
            {/* <b className="active">Learn More</b> */}
          </p>
        </div>
      </ConfirmModal>
    </>
  );
};

export default NewListingConfirmModal;

import React from "react";
import moment from "moment";

const BookingDetails = ({ data }) => {
  return (
    <>
      {console.log(data, "data")}
      <h4 className="site_booking_card_heading">Booking Details</h4>
      <div className="pt-2">
        <div className="site_booking_card_sub_heading pb-1">DURATION</div>
        <h5 className="site_booking_card_heading05">
          <span>{moment(data.fromDate).format("DD MMMM YYYY")}</span> -{" "}
          <span>{moment(data.toDate).format("DD MMMM YYYY")}</span>
        </h5>
      </div>
      <hr className="hr_line" />

      {data.site && (
        <div className="pb-2">
          <div className="site_booking_card_sub_heading pb-1">LOCATION</div>
          <h5 className="site_booking_card_heading05">{data.site.name}</h5>
          <p className="site_booking_card_desc">{data.site.googleAddress}</p>
        </div>
      )}

      {data.signs && (
        <div className="py-2">
          <div className="site_booking_card_sub_heading pb-1">SIGN’S</div>
          {data.signs.map((signItem, index) => (
            <h5
              className="site_booking_card_heading05"
              key={"sign_item_" + index}
            >
              {signItem.sign.name}
            </h5>
          ))}
        </div>
      )}

      {data.services && (
        <div className="py-2">
          <div className="site_booking_card_sub_heading pb-1">SERVICES</div>
          {data.services.map((serviceItem, index) => (
            <h5
              className="site_booking_card_heading05"
              key={"service_item_" + index}
            >
              {serviceItem.service.name}
            </h5>
          ))}
        </div>
      )}

      {data.charges && (
        <div className="py-2">
          <div className="site_booking_card_sub_heading pb-1">CHARGES</div>
          {data.charges.map((chargeItem, index) => (
            <h5
              className="site_booking_card_heading05"
              key={"charge_item_" + index}
            >
              {chargeItem.charge.charge.name}
            </h5>
          ))}
        </div>
      )}
    </>
  );
};

export default BookingDetails;

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  DashboardIcon,
  HelpIcon,
  LogoutIcon,
  OrderIcon,
  ProfileIcon,
  SettingsIcon,
  UserMgmtIcon,
} from "../../assets/svgComponents/inventorySVG";
import { GLogo } from "../../assets/svgComponents/inventorySVG";
import {
  ArrowDownSVG,
  CaretDownSVG,
  CaretDownSVG02,
  CaretUpSVG02,
} from "../../assets/svgComponents/table";
import { getProvider } from "../../services/Provider";
import ConfirmModal from "../confirmModal/ConfirmModal";

const SideNav = (props) => {
  const [isConfiguratorDown, setIsConfiguratorDown] = useState(false);
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [curUser, setCurUser] = useState();
  const [onBoardingStepDone, setOnBoardingStepDone] = useState(0);
  const [comfirmSidebarModal, setComfirmSidebarModal] = useState(false);
  const [mouseOnLogo,setMouseOnLogo] = useState(false);
  const [accessModules, setAccessModules] = useState([]);

  const ifCanAccess = (claimtype) =>{
    if(accessModules.length>0){
    const skac = accessModules.filter(m=>m.Type===claimtype && m.Value!=='None');
    return skac.length>0;
    }
    else{
      return true;
    }
    // return true;
  }
  useEffect(() => {
    getProvider().then((res) => {
      if (res.status == 200) {
        setCurUser(res.data);
      }
    });
    let am = localStorage.getItem("accessModules");
    if(am!="undefined"){
      setAccessModules(JSON.parse(am))
    }
  }, []);
  const handleNavHoverOn = () => {
    setMouseOnLogo(true); 
    let temp = localStorage.getItem("onBoardingStepDone");
    if(temp){
      setOnBoardingStepDone(parseInt(temp));
    }
    const WindowWidth = window.outerWidth;
    if (WindowWidth > 1199) {
      props.setOpenNav(true);
    }
  };
  const handleNavHoverOff = () => {
    setMouseOnLogo(false); 
    const WindowWidth = window.outerWidth;
    if (WindowWidth > 1199) {
      props.setOpenNav(false);
    }
  };
  const { addToast } = useToasts();
  const sideMenuClick = () => {
    if(onBoardingStepDone < 1){
      setComfirmSidebarModal(true);
    }

  }

  const confirmModalSidebar = () => {
    setComfirmSidebarModal(false);
  }

  return (
    <>
      <div onMouseEnter={handleNavHoverOn} onMouseLeave={handleNavHoverOff}>
        {/* <div> */}
        <Row className="sidebar_main g-0 d-block">
          <Col className="sidebar_col01">
            <div className={ mouseOnLogo ? "sidebar_logo_main sidebar_align gopinit_logo_hover" : "sidebar_logo_main sidebar_align" }>
              {/* <Link to="" className="sidebar_link"> */}
              {/* <span> */}
              <GLogo />
              {/* </span> */}
              {/* </Link> */}
              {props.openNav ? <h3 className="m-0"> GoPinIt </h3> : ""}
            </div>
          </Col>
          <Col className="">
            <p
              className={
                props.openNav
                  ? "sidebar_heading01 sidebar_align m-0"
                  : "sidebar_icons_heading"
              }
            >
              MENU
            </p>
            <div
              className={
                props.openNav &&
                activeTab === "Dashboard" &&
                "sidebar_active_tab"
              }
              onClick={() => {sideMenuClick();setActiveTab("Dashboard")}}
            >
              <div className={props.openNav ? "sidebar_tabs" : "sidebar_icon"}>
                <Link
                  to={
                    onBoardingStepDone < 5 ? "/provider" : "/provider/dashboard"
                  }
                  className={props.openNav ? "sidebar_link" : ""}                  
                >
                  <span className={(activeTab === "Dashboard" ? " sideNavIcon " : "")}>
                    <DashboardIcon
                      fill={activeTab === "Dashboard" && "#F99300"}
                    />
                  </span>
                  {props.openNav ? (
                    <p className={props.openNav ? "sidebar_text" : ""}>
                      {onBoardingStepDone <= 5 ?'Account Setup':'Dashboard'}
                    </p>
                  ) : (
                    ""
                  )}
                </Link>
              </div>
            </div>
            {ifCanAccess('OrdersAndTransactions') && (

            <div
              className={
                props.openNav &&
                activeTab === "OrdersAndTransactions" &&
                "sidebar_active_tab"
              }
              onClick={() => {sideMenuClick();setActiveTab("OrdersAndTransactions")}}
            >
              <div className={props.openNav ? "sidebar_tabs" : "sidebar_icon"}>
                <Link
                  to={
                    onBoardingStepDone < 1
                      ? "/provider"
                      : `/provider/orders`
                  }
                  className="sidebar_link"                  
                >
                  <span className={(activeTab === "OrdersAndTransactions" ? " sideNavIcon " : "")}>
                    <OrderIcon
                      fill={activeTab === "OrdersAndTransactions" && "#F99300"}
                    />
                  </span>
                  {props.openNav ? (
                    <p className="sidebar_text">Orders & Transactions</p>
                  ) : (
                    ""
                  )}
                </Link>
              </div>
            </div>
            )}
            {ifCanAccess('UserManagement') && (
            <div
              className={
                props.openNav &&
                activeTab === "UserManagement" &&
                "sidebar_active_tab"  
              }
              onClick={() => {sideMenuClick();setActiveTab("UserManagement")}}
            >
              <div className={props.openNav ? "sidebar_tabs" : "sidebar_icon"}>
                <Link
                  to={
                    onBoardingStepDone < 1
                      ? "/provider"
                      : `/provider/usermanagement`
                  }
                  className="sidebar_link"
                >
                  <span className={(activeTab === "UserManagement" ? " sideNavIcon " : "")}>
                    <UserMgmtIcon
                      fill={activeTab === "UserManagement" && "#F99300"}
                    />
                  </span>
                  {props.openNav ? (
                    <p className="sidebar_text">User Management</p>
                  ) : (
                    ""
                  )}
                </Link>
              </div>
            </div>
            )}
            {ifCanAccess('Configurator') && (
            <div
              className={
                props.openNav ? "sidebar_tabs pe-0 ps-0" : "sidebar_icon"
              }
            >
              {props.openNav ? (
                <>
                  <div className="sidebar_dropdown_container w-100">
                    <div
                      className={
                        props.openNav &&
                        activeTab === "Configurator" &&
                        "sidebar_active_tab"
                      }
                      onClick={() => {sideMenuClick();setActiveTab("Configurator")}}
                    >
                      <div className="sidebar_dropdown w-100 ps-4">
                        <span className="icon_container"
                          onClick={() =>
                            setIsConfiguratorDown(!isConfiguratorDown)
                          }
                        >
                          <SettingsIcon
                            fill={activeTab === "Configurator" && "#F99300"}
                          />
                        </span>
                        <div className="d-flex justify-content-between right_container"
                        onClick={() =>
                          setIsConfiguratorDown(!isConfiguratorDown)
                        }>
                          <p className="sidebar_text ps-0 me-6">
                            Configurator
                          </p>
                          <span className="sidebar_dropdown_icon pe-2">
                            {isConfiguratorDown ? (
                              <CaretUpSVG02 />
                            ) : (
                              <CaretDownSVG02 />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {isConfiguratorDown && (
                      <ul className="sidebar_content bg-gray-800 pt-2 mt-3 mb-2">
                        <li className="sidebar_text02 ms-1 ps-4">
                          <Link
                            to={
                              onBoardingStepDone < 1
                                ? "/provider"
                                : "/provider/configurator/inventory"
                            }
                            className="sidebar_link"
                            onClick={() => sideMenuClick()}
                          >
                            Inventory{" "}
                          </Link>
                        </li>
                        <Link
                          to={
                            onBoardingStepDone < 1
                              ? "/provider"
                              : "/provider/configurator/listings"
                          }
                          className="sidebar_link"
                          onClick={() => sideMenuClick()}
                        >
                          <li className="sidebar_text02 ms-1 ps-4">Listings</li>
                        </Link>

                        <Link
                          to={
                            onBoardingStepDone < 1
                              ? "/provider"
                              : "/provider/configurator/offer-management"
                          }
                          className="sidebar_link"
                          onClick={() => sideMenuClick()}
                        >
                          <li className="sidebar_text02 ms-1 ps-4">
                            Offer Management
                          </li>
                        </Link>
                        {/* <Link
                          to="/provider/configurator/notifications"
                          className="sidebar_link"
                        >
                          <li className="sidebar_text02">Notifications</li>
                        </Link> */}
                      </ul>
                    )}
                  </div>
                </>
              ) : (
                <span className={(activeTab === "Configurator" ? " sideNavIcon " : "")}>
                  <SettingsIcon
                    fill={activeTab === "Configurator" && "#F99300"}
                  />
                </span>
              )}
            </div>
            )}
            {ifCanAccess('SupportRequests') && (
            
            <div
              className={
                props.openNav && activeTab === "Support" && "sidebar_active_tab"
              }
              onClick={() => {sideMenuClick();setActiveTab("Support")}}
            >              
              <div className={props.openNav ? "sidebar_tabs" : "sidebar_icon"}>
                <Link
                  to="/provider/add-support-request"
                  className="sidebar_link"
                >
                  <span className={(activeTab === "Support" ? " sideNavIcon " : "")}>
                    <HelpIcon fill={activeTab === "Support" && "#F99300"} />
                  </span>
                  {props.openNav ? <p className="sidebar_text">Support</p> : ""}
                </Link>
              </div>
            </div>
            )}
          </Col>
          <Col className="">
            <div className="sidebar_col03">
              <div
                className={
                  props.openNav
                    ? "ps-4 pt-4 sidebar_profile_main"
                    : " sidebar_profile_main"
                }
              >
                <p
                  // className="pro01 "
                  className={props.openNav ? "pro01" : " sidebar_icons_heading"}
                >
                  ACCOUNT
                </p>
                <div
                  className={
                    props.openNav &&
                    activeTab === "Profile" &&
                    "sidebar_active_tab"
                  }
                  onClick={() => setActiveTab("Profile")}
                >
                  <div className={props.openNav ? "pro021" : "sidebar_icon"}>
                    <Link to="/provider/profile" className="sidebar_link">
                      <span className={(activeTab === "Profile" ? " sideNavIcon " : "")}>
                        <ProfileIcon
                          fill={activeTab === "Profile" && "#F99300"}
                        />
                      </span>
                      {props.openNav ? (
                        <p className="sidebar_text">Profile</p>
                      ) : (
                        ""
                      )}
                    </Link>
                  </div>
                </div>
                <div
                  className={
                    props.openNav &&
                    activeTab === "Logout" &&
                    "sidebar_active_tab"
                  }
                  onClick={() => setActiveTab("Logout")}
                >
                  <div
                    // className="pro03"
                    className={props.openNav ? "pro021" : "sidebar_icon"}
                  >
                    <Link to="" className="sidebar_link" onClick={props.logout}>
                      <span>
                        <LogoutIcon
                          fill={activeTab === "Logout" && "#F99300"}
                        />
                      </span>
                      {props.openNav ? (
                        <p className="sidebar_text">Logout</p>
                      ) : (
                        ""
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

          {comfirmSidebarModal && (
            <ConfirmModal
              title=""
              isModalHeader={true}
              isFooterBtns={false}              
              confirmText="Ok"
              handleClose={() => setComfirmSidebarModal(false)}
              handleConfirm={confirmModalSidebar}
              width="400px"
            >
              <div className="childs">
                <p className="newListingCreated_description ms-4 py-3">
                  Please complete your Account Setup step first.
                </p>
              </div>
            </ConfirmModal>
          )}
      </div>
    </>
  );
};

export default SideNav;

import React, { useContext, useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import NavBar from "../../../../../../../components/navBar";
import ProjectContext from "../../../../../../../context/ProjectContext";
import {
  deleteCharges,
  getChargeDetails,
} from "../../../../../../../services/Provider/Configurator/Inventory";
import UpdatesModal from "../../../../../../../components/profile/Modal/UpdatesModal";
import { checkPermission } from "../../../../../../../utils";

export const ViewChargesInfo = (props) => {
  const history = useHistory();
  const [selectedCharge, setSelectedCharge] = useState();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);
  const { id } = props.location.state;

  useEffect(async () => {
    await getChargeDetails(id)
      .then((res) => {
        if (res.status === 200) {
          console.log("site Usage Data", res.data);
          setSelectedCharge(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleOnDelete = () => {
    setDeleteConfirmModal(true);
  };
  const handleOnEditbtn = () => {
    history.push(`/provider/configurator/inventory/edit-charges`);
  };

  let chargeId = selectedCharge?.id;
  const handleDeleteSignConfirm = () => {
    deleteCharges(chargeId)
      .then((res) => {
        if (res.status == 204) {
          setDeleteConfirmModal(false);
          history.push(`/provider/configurator/inventory`);
        }
      })
      .catch((err) => console.log("err", err));
  };
  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <>
      <NavBar
        blueBtnText="Edit Details"
        whiteBtnText="Delete Charge"
        notificationSVG={<NotificationSVG />}
        navHeading="Charges Details"
        subHeading="Configurator • All Charges "
        subActiveHeading="Charge Details"
        handleBlueBtn={handleOnEditbtn}
        handleWhitebtn={handleOnDelete}
        handleNotificationSVG={handleNotificationSVG}
        showButton={checkPermission("Configurator", "Full")}

      />
      <div className="girdMain gridSection">
        <Card className="gridCard" aria-labelledby="gridCard">
          <Card.Body className="gridCardBody p-0">
            <div className="formSection">
              <p className="mt-4 mx-4">Charges Information</p>
              <div className="d-flex justify-content-between w-75 mx-4">
                <span>
                  <h6>ID</h6>
                  <p style={{ fontSize: "13px" }}>
                    {selectedCharge?.id?.substring(0, 5)}
                  </p>
                </span>
              </div>
              <div className="mt-4 mx-4 w-75">
                <h6>DESCRIPTION</h6>
                <p style={{ fontSize: "13px" }}>
                  {selectedCharge?.description}
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      {deleteConfirmModal && (
        <ConfirmModal
          title={selectedCharge?.name}
          isModalHeader={true}
          isFooterBtns={true}
          confirmText="Yes"
          cancelText="No"
          handleClose={() => setDeleteConfirmModal(false)}
          handleConfirm={handleDeleteSignConfirm}
        >
          <div className="text-center">
            <p className="py-4 newListingCreated_description">
              Would you like to delete this charges?
            </p>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

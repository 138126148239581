import { useFormik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  InputGroup,
  Form,
  FloatingLabel,
  Dropdown,
  Modal,
  Tooltip,
  OverlayTrigger,
  Button
} from "react-bootstrap";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { InputField } from "../../../../../../../components/InputField";
import NavBar from "../../../../../../../components/navBar";
import { TextAria } from "../../../../../../../components/TextAria";
import { UploadFile } from "../../../../../../../components/UploadFile/UploadFile";
import { NotificationSVG } from "../../../../../../../assets/svgComponents/table";
import { useToasts } from "react-toast-notifications";
import {
  addSign,
  getEnvironmentType,
  uploadFileMethod,
} from "../../../../../../../services/Provider";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  inventorySignDetails,
  signDetailsUpdate,
} from "../../../../../../../services/Provider/Configurator/Inventory";
import ProjectContext from "../../../../../../../context/ProjectContext";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import { getAllTaxation } from "../../../../../../../services/Provider/StateTaxation/StateTaxation";
import UpdatesModal from "../../../../../../../components/profile/Modal/UpdatesModal";
import { InfoSVG } from "../../../../../../../assets/svgComponents/svg";
import { checkPermission } from "../../../../../../../utils";

export const EditSignPage = ({ signsData }) => {
  const { selectedSign, handleActiveTab } = useContext(ProjectContext);
  console.log(selectedSign, "selectedSign");
  const [size, setSize] = useState();
  const [taxation, setTaxation] = useState([]);
  const [taxationId, setTaxationId] = useState(selectedSign.stateName);
  const [description, setDescription] = useState(selectedSign.description);
  const [uploadFile, setUploadFile] = useState({ fileKey: selectedSign.image });
  const [dimensions, setDimensions] = useState();
  const [measureUnit, setMeasureUnit] = useState(selectedSign.dimension);
  const [mediaTypeArray, setMediaTypeArray] = useState();
  const [mediaTypes, setMediaTypes] = useState(selectedSign.mediaType);
  const [selectedSize, setSelectedSize] = useState(selectedSign.size);
  const [modalConfirmStatus, setModalConfirStatus] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [navbarUpdatesModal, setNavbarUpdatesModal] = useState(false);
  const [showRadius, setShowRadius] = useState(false);
  const [radiusError, setRadiusError] = useState("");
  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();

  const handleOnCancel = () => {
    handleActiveTab("SIGN");
    history.push("/provider/configurator/inventory");
  };



  useEffect(() => {
    console.log(selectedSign);
    getEnvironmentType("MediaTypes")
      .then((res) => {
        if (res.status === 200) {
          setMediaTypeArray(res.data);
          setMediaTypes(res.data[0].id);
          // console.log("mediatype", res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    getEnvironmentType("SignSizes").then((res) => {
      if (res.status === 200) {
        setSize(res.data);
        setMeasureUnit(res.data[0].id);
        console.log("res.data", res.data);
      }
    });

    getEnvironmentType("SignDimensions")
      .then((res) => {
        if (res.status === 200) {
          setDimensions(res.data);
          setMeasureUnit(res.data[0].id);
          // console.log("res.data", res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    getAllTaxation()
      .then((res) => {
        if (res.status === 200) {
          setTaxation(res.data);
          console.log("taxation data", taxation);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    if (selectedSign.mediaTypeText.indexOf("Portable Sign") >= 0) {
      setShowRadius(true);
    } else {
      setShowRadius(false);
    }

  }, []);

  const renderTooltip = props => (
    <Tooltip {...props}>Radius of operation is the area serviceable by the portable sign. A customer can only book inside your radius of operation.</Tooltip>
  );

  const handelUploadFile = (e) => {
    let data = e.target.files[0];
    let image = new FormData();
    image.append("file", data);
    uploadFileMethod(image)
      .then((res) => {
        if (res.status == 200) {
          setUploadFile(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleUnitSelection = (e) => {
    setMeasureUnit(e.target.value);
  };

  const handleTaxation = (e) => {
    let id = e.target.value;
    setTaxationId(id);
  };

  const handleMediaSelection = (e) => {
    setMediaTypes(e.target.value);
    if (mediaTypeArray[parseInt(e.target.value)].name.indexOf("Portable Sign") >= 0) {
      setShowRadius(true);
    } else {
      setShowRadius(false);
    }
  };

  const handleSizeSelection = (e) => {
    setSelectedSize(e.target.value);
  };

  const formik = useFormik({
    initialValues: {
      name: selectedSign.name,
      description: selectedSign.description,
      width: selectedSign.width,
      height: selectedSign.height,
      stateName: selectedSign.stateName,
      radius: selectedSign.radius,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(5, "Please enter title with five or more characters")
        .required("Name is required!"),
      width: Yup.number()
        .positive("Please enter number greater than 0")
        .required("Width is required!"),
      height: Yup.number()
        .positive("Please enter number greater than 0")
        .required("Height is required!"),
    }),

    onSubmit: (values) => {
      console.log("values", values);
      let errorFlag = false;
      let radius = 0;
      if (showRadius) {
        radius = values.radius;
        if (radius === "") {
          errorFlag = true;
          setRadiusError("Please enter radius!");
        }
      }
      if (!description) {
        errorFlag = true;
        setDescriptionError("Description is required!");
      }
      if (!errorFlag) {
        let formData = [
          {
            path: "/name",
            op: "add",
            value: values.name,
          },
          {
            path: "/radius",
            op: "add",
            value: radius,
          },
          {
            path: "/mediaType",
            op: "add",
            value: mediaTypes,
          },
          {
            path: "/dimension",
            op: "add",
            value: measureUnit,
          },
          {
            path: "/width",
            op: "add",
            value: values.width,
          },
          {
            path: "/height",
            op: "add",
            value: values.height,
          },
          {
            path: "/size",
            op: "add",
            value: selectedSize,
          },
          {
            path: "/image",
            op: "add",
            value: uploadFile ? uploadFile.fileKey : signsData.image,
          },
          {
            path: "/stateName",
            op: "add",
            value: taxationId,
          },
        ];
        console.log("formData", formData);
        let signId = selectedSign.id;
        signDetailsUpdate(signId, formData)
          .then((res) => {
            if (res.status === 204) {
              handleActiveTab("SIGN");
              addToast("Sign updated successfully", {
                appearance: "success",
              });
            }
          })
          .catch((err) => {
            console.log("err", err);
            handleActiveTab("SIGN");
            addToast("Error while updating sign.", {
              appearance: "error",
            });
          });
        history.push("/provider/configurator/inventory");
      }
    },
  });

  const nameError = formik.touched.name && formik.errors.name;
  const widthError = formik.touched.width && formik.errors.width;
  const heightError = formik.touched.height && formik.errors.height;

  const handleDescription = (e) => {
    let des = e.target.value;
    setDescription(des);
    setDescriptionError("");
    if (!des) {
      setDescriptionError("Description is required!");
    }
  };

  const handleConfirmModale = () => {
    setModalConfirStatus(false);
    history.push("/provider/configurator/inventory");
  };

  const handleNotificationSVG = () => {
    setNavbarUpdatesModal(true);
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <NavBar
          blueBtnText="Save"
          whiteBtnText="Cancel"
          notificationSVG={<NotificationSVG />}
          navHeading="Edit Sign"
          subHeading="Configurator • All Signs "
          subActiveHeading="Edit Sign"
          // handleBlueBtn={handleOnSavebtn}
          type="submit"
          handleWhitebtn={handleOnCancel}
          handleNotificationSVG={handleNotificationSVG}
          showButton={true}
        />
        <div className="girdMain gridSection ">
          <Card className="gridCard" aria-labelledby="gridCard">
            <Card.Body className="gridCardBody p-0">
              <div className="formSection ">
                <p className="mt-4 mx-4">Sign Details</p>
                <div className="mx-4">
                  <div className="row my-4 fornSection2">
                    <div className="col-md-6">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Media typer"
                        className="mb-3"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          // className="selecContainer"
                          onChange={(e) => handleMediaSelection(e)}
                        >
                          {/* <option value="">{selectedSign.mediaTypeText}</option> */}
                          {mediaTypeArray &&
                            mediaTypeArray.map((item) => {
                              return (
                                <option
                                  value={item.id}
                                  selected={
                                    item.name == selectedSign.mediaTypeText
                                  }
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </FloatingLabel>
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="Sign Name"
                        label="Sign Name"
                        inputFieldSize="sizeOfInputField"
                      />
                      {nameError && (
                        <p
                          className="text-danger mt-1 ms-4"
                          style={{ fontSize: "14px" }}
                        >
                          {formik.errors.name}
                        </p>
                      )}
                    </div>
                  </div>
                  {showRadius && (
                    <div className="row my-4 fornSection2">
                    <div className="col-md-8 d-flex justify-content-between" >
                      <InputField
                        type="number"
                        name="radius"
                        value={formik.values.radius}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="Radius"
                        label="Radius of Operation(in KMs)"
                        inputFieldSize="sizeOfInputField"
                        style={{width:"520px"}}
                      />
                      {radiusError && (
                        <p
                          className="text-danger mt-1 ms-4"
                          style={{ fontSize: "14px" }}
                        >
                          {radiusError}
                        </p>
                      )}
                      <OverlayTrigger
                        placement="right"
                        overlay={renderTooltip}
                      >
                        <Button style={{backgroundColor:"white", borderColor:"white"}}><InfoSVG /></Button>
                      </OverlayTrigger>
                    </div>
                    
                  </div>
                  )}
                  <div className="row fornSection2">
                    <div className="col-md-6">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Unit of Measurement"
                        className="mb-3"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          // className="selecContainer"
                          onChange={(e) => handleUnitSelection(e)}
                        >
                          {/* <option value="">{selectedSign.dimensionText}</option> */}

                          {dimensions &&
                            dimensions.map((item) => {
                              return (
                                <option
                                  value={item.id}
                                  selected={
                                    item.name == selectedSign.dimensionText
                                  }
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </FloatingLabel>
                    </div>
                    <div className="col-md-6">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Size"
                        className="mb-3"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          // className="selecContainer"
                          onChange={(e) => handleSizeSelection(e)}
                          value={selectedSize}
                        >
                          {/* <option value="">{selectedSign.sizeText}</option> */}
                          {size &&
                            size.map((item) => {
                              return (
                                <option
                                  value={item.id}
                                  selected={item.name == selectedSign.sizeText}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row fornSection2 my-4 ">
                    <div className="col-md-6">
                      <InputField
                        type="number"
                        name="width"
                        value={formik.values.width}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="name@example.com"
                        label="Width"
                        inputFieldSize="sizeOfInputField"
                      />
                      {widthError && (
                        <p
                          className="text-danger mt-1 ms-4"
                          style={{ fontSize: "14px" }}
                        >
                          {formik.errors.width}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="number"
                        name="height"
                        value={formik.values.height}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="name@example.com"
                        label="Height"
                        inputFieldSize="sizeOfInputField"
                      />
                      {heightError && (
                        <p
                          className="text-danger mt-1 ms-4"
                          style={{ fontSize: "14px" }}
                        >
                          {formik.errors.height}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row fornSection2">
                    <div className="col-md-12">
                      <TextAria
                        type="text"
                        name="des"
                        value={description}
                        onChange={(e) => handleDescription(e)}
                        placeholder="Description"
                        label="Description"
                      />
                      {descriptionError && (
                        <p
                          className="text-danger mt-1 ms-4"
                          style={{ fontSize: "14px" }}
                        >
                          {descriptionError}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="my-3">
                        <UploadFile
                          id="image"
                          uploadBoxSize="sizeOfInputField"
                          btnlable="Image"
                          handelUploadLogo={handelUploadFile}
                          placeholder={
                            uploadFile ? uploadFile.fileName : "Update Image"
                          }
                          allowedFiles="image/png, image/gif, image/jpeg"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 my-3">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="State Taxation"
                      >
                        <Form.Select
                          placeholder="Select Taxation"
                          onChange={(e) => handleTaxation(e)}
                          value={taxationId}
                        >
                          {taxation &&
                            taxation?.map((item) => {
                              return (
                                <option
                                  value={item.stateName}
                                  key={item.id}
                                  selected={
                                    item.stateName == selectedSign.stateName
                                  }
                                >
                                  {item.stateName}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </FloatingLabel>
                    </div>
                    <div className="col-md-12">
                      <div className="image_Section">
                        <p>Image</p>
                        <div className="imageCardContainer">
                                  <div
                                    className="imageCard"
                                    style={{ height: "70px" }}
                                  >
                                    <a
                                      download="image.jpg"
                                      href={`https://gopinit.blob.core.windows.net/image-container/${selectedSign && selectedSign.image
                                    }`}
                                      target="_blank"
                                    >
                                      <img
                                        src={`https://gopinit.blob.core.windows.net/image-container/${selectedSign && selectedSign.image
                                      }`}
                                        alt="img"
                                      />
                                    </a>
                                  </div>
                            
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Form>

      {modalConfirmStatus && (
        <ConfirmModal
          title={`Update Sign`}
          isModalHeader={true}
          isFooterBtns={false}
          confirmText="Ok"
          handleConfirm={handleConfirmModale}
        >
          <div className="text-center">
            <p className="py-4 newListingCreated_description">
              Sign updated successfully.
            </p>
          </div>
        </ConfirmModal>
      )}
      <Modal
        show={navbarUpdatesModal}
        onHide={() => setNavbarUpdatesModal(false)}
        dialogClassName="m-0 ml-sm-auto mr-auto mr-sm-0 d-flex vh-100 entitlementModal modalInnerScroll pl-0 ml-0 pr-0"
      >
        <UpdatesModal />
      </Modal>
    </>
  );
};

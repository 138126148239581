import { http } from "./httpService";

export function getCountryList(){
    return http.get(`/api/Utility/Countries`)
}
export function getStateList(countryId){
    return http.get(`/api/Utility/States${countryId}`)
}
export function getCityList(stateId){
    return http.get(`/api/Utility/Cities${stateId}`)
}

export function addAddress(formData){
    return http.post(`/api/User/BillingAddressDetails`,formData)
}
export function addCard(formData){
    return http.post(`/api/User/PaymentCardDetails`,formData)
}

export function getCardDetails(){
    return http.get(`/api/User/PaymentCardDetails`)
}
export function getRecentTopFivePaymentCard(){
    return http.get(`/api/User/RecentTopFivePaymentCard`)
}

export function addSupportRequest(formData){
    return http.post(`/api/Ticket/Create`,formData)
}